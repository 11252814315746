import React from 'react';
import PropTypes from 'prop-types';

function SearchBar({ onSearch }) {
  return <input type="text" className="form-control me-2" placeholder="Search..." onChange={onSearch} />;
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchBar;
