import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

class PlanChangeButton extends React.Component {
  _buttonText = (selectedPlan) => {
    const { isProcessing } = this.props;

    if (isProcessing) {
      return 'Processing Account Change...';
    }
    if (selectedPlan === 'FREE') {
      return 'Downgrade to the Basic plan & lose features';
    }
    if (selectedPlan === 'PAID') {
      return 'Downgrade to the Plus plan & lose features';
    }
    if (selectedPlan === 'PR_TEAM' || selectedPlan === 'CLIENT_FIRM') {
      return 'Confirm Change';
    }
  };

  render() {
    const {
      selectedPlan,
      onClick,
      isProcessing,
      disabled,
    } = this.props;

    return (
      <div className="d-grid">
        <button
          type="submit"
          className="btn btn-primary btn-lg mb-3"
          disabled={disabled}
          onClick={onClick}
        >
          <Spinner
            color="light"
            className="me-2"
            style={{
              width: '1.5rem',
              height: '1.5rem',
              display: `${isProcessing ? 'inline-block' : 'none'}`,
            }}
          />
          <span className="ms-1">
            {this._buttonText(selectedPlan)}
          </span>
        </button>
      </div>
    );
  }
}

PlanChangeButton.propTypes = {
  selectedPlan: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PlanChangeButton;
