import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/avatar';
import InviteSourceToPitchButton from './invite_source_to_pitch_button';
import vahoy from '../../../javascript/vahoy';
import helper from '../../../javascript/frontend/helpers/helper';

function PotentialSources({
                            hashtags,
                            num_sources: numSources,
                            source_request_id: sourceRequestId,
                            source_requests: sourceRequests,
                          }) {
  const [currentTries, setCurrentTries] = useState(0);
  const [isPolling, setIsPolling] = useState(false);
  const [sources, setSources] = useState([]);
  const [dataSource, setDataSource] = useState('');
  const renderNotFoundReported = useRef(false);
  const intervalSec = 1;
  const maxWaitingSec = 15;
  const maxTries = maxWaitingSec / intervalSec;

  useEffect(() => {
    vahoy.track('PotentialSources#constructor');
  }, []);

  useEffect(() => {
    if (currentTries < maxTries && !sources.length) {
      const poll = () => {
        if (isPolling) return;

        setIsPolling(true);

        fetch(`/api/internal/source_requests/${sourceRequestId}/potential_sources.json?num_sources=${numSources}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json',
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setSources(data.data);
            setDataSource(data.data_source);
            setCurrentTries((prevTries) => prevTries + 1);
            setIsPolling(false);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error.toString());
          });
      };

      poll();
      const interval = setInterval(poll, intervalSec * 1000);

      return () => clearInterval(interval);
    }
  }, [currentTries, isPolling, maxTries, sourceRequestId, sources.length, numSources]);

  const sortSourceHashtags = (sourceHashtags, limit) => {
    const sortedArray = [];

    sourceHashtags.some((sourceHashtag) => {
      const match = hashtags.find((hashtag) => hashtag.id === sourceHashtag.id);

      if (match) {
        sortedArray.push(match);
      }

      return sortedArray.length === limit;
    });

    for (let i = 0; i < limit - sortedArray.length; i += 1) {
      const sortedIds = sortedArray.map((hashtag) => hashtag.id);
      const match = sourceHashtags.find((hashtag) => !sortedIds.includes(hashtag.id));

      if (match) {
        sortedArray.push(match);
      }
    }

    return sortedArray;
  };

  const trackClicks = (clickType) => {
    vahoy.track(`PotentialSources#clickLink_${clickType}`);
  };

  const renderSources = () => {
    vahoy.track('PotentialSources#renderSources', { sources_found: sources.length });

    return (
      <div>
        <div className="row">
          {sources.map((source) => (
            <div key={source.id} className="col-12 col-md-4 mb-2">
              <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className="card mb-2 px-3 py-2" style={{ flexGrow: 1 }}>
                  <div className="card-body" style={{ minHeight: '120px' }}>
                    <h6 className="fw-bold">{source.company_name}</h6>
                    <div className="mb-2" />
                    <Avatar
                      avatarUrl={source.avatar?.image_cloudinary?.url}
                      avatarLetters={source.initials}
                      className="rounded-circle ms-auto me-auto"
                    />
                    <div className="mb-3" />
                    <h5 className="mb-1 fw-bold">
                      <a href={source.source_url} onClick={() => trackClicks('source')}>
                        {source.full_name}
                      </a>
                    </h5>
                    <div className="card-grid-subtitle fw-bold font-size-12px">
                      {source.employments_string_without_company}
                    </div>
                    <div className="mb-2" />
                    <div>
                      {sortSourceHashtags(source.hashtags, 5).map((hashtag) => (
                        <a
                          href={hashtag.url}
                          key={hashtag.id}
                          onClick={() => trackClicks('hashtag')}
                          className="p-1 mb-1 text-dark badge bg-light text-dark font-size-10px"
                        >
                          {hashtag.hashtag}
                        </a>
                      ))}
                    </div>
                    <div className="mb-2" />
                    <div className="font-size-14px">{helper.beautifulTruncateStr(source.bio, 250)}</div>
                  </div>
                  <div className="d-grid">
                    <InviteSourceToPitchButton
                      hashtags={hashtags}
                      origin_type="potential_sources"
                      source_id={source.id}
                      source_name={source.full_name}
                      source_request_id={sourceRequestId}
                      source_requests={sourceRequests}
                    />
                  </div>
                  <div className="mb-2" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div style={{ fontSize: '10px' }}>Data source: {dataSource}</div>
      </div>
    );
  };

  const renderWaiting = () => (
    <div>
      <i className={`fa-solid fa-fw ${window.SITE_DEFAULT_ANIMATED_SPINNER}`} /> Looking for the best sources...
    </div>
  );

  const renderNotFound = () => {
    if (!renderNotFoundReported.current) {
      vahoy.track('PotentialSources#constructor#renderNotFound');
      renderNotFoundReported.current = true;
    }
    return (
      <div>
        We haven't found the perfect source yet, but we'll get your question out to our community of experts right away.
        Expect to start receiving pitches soon.
      </div>
    );
  };

  let block;
  if (currentTries >= maxTries) {
    block = renderNotFound();
  } else if (!sources.length) {
    block = renderWaiting();
  } else {
    block = renderSources();
  }

  return <div className="inline-block">{block}</div>;
}

PotentialSources.propTypes = {
  hashtags: PropTypes.arrayOf(PropTypes.object).isRequired,
  num_sources: PropTypes.number.isRequired,
  source_request_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  source_requests: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PotentialSources;
