// A PromoEntity is a "thing" that can be/is being pitched.
//
// Previously the only possible "thing" was a Source, but now we also have
// introduced the ability to pitch a Product (and, some support was previously
// added for pitching a Company, but that was never completed).
//
// So, although we don't have an abstract PromoEntity model, you can think of it as:
//
// PromoEntity
//  |
//  +-- Source
//  |
//  +-- Product
//  |
//  +-- (Company)

import _ from 'lodash';
import axios from 'axios';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {

  createPromoEntity(entityType, attributes) {
    vlog.info('api#createEntity');

    switch (entityType) {
      case 'Product':
        return Promise.resolve(this.createProduct(attributes));
      case 'Company':
        return Promise.resolve(this.createCompany(attributes));
      case 'Source':
        return Promise.resolve(this.createSource(attributes));
      default:
        throw new Error(`Unknown entityType: ${entityType}`);
    }
  },

  deletePromoEntity(entityId) {
    vlog.info('api#deletePromoEntity');

    api.configure();

    const url = `/api/internal/jsonapi/pitched-entities/${entityId}`;

    return axios.delete(url, api.axiosFix);
  },
});
