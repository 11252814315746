import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

function NumberOfPitches({ sourceRequest }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const pitches = sourceRequest.number_of_pitches;
  const pitchesTooltip = `# of submitted pitches: ${pitches}`;
  const targetSpan = useRef();
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  if (pitches === 0 || pitches == null) return null;

  return (
    <div
      className="position-absolute"
      style={{
        top: '-10px',
        right: '-12px',
      }}
    >
      <span
        className="badge rounded-pill bg-primary"
        style={{
          fontSize: '16px',
          cursor: 'pointer',
        }}
        ref={targetSpan}
      >
        <strong>{pitches}</strong>
      </span>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={targetSpan}
        toggle={toggleTooltip}
      >
        {pitchesTooltip}
      </Tooltip>
    </div>
  );
}

NumberOfPitches.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object).isRequired,
};

export default NumberOfPitches;
