import _ from 'lodash';
import api from './api';

_.extend(api, {
  createRepresentation(promoEntityId, promoEntityType, userId) {
    return api[`create${promoEntityType}Representation`](promoEntityId, userId);
  },

  deleteRepresentation(representationId, promoEntityType) {
    return api[`delete${promoEntityType}Representation`](representationId);
  },

  getRepresentingUsers(promoEntityId, promoEntityType) {
    return api[`get${promoEntityType}RepresentingUsers`](promoEntityId);
  },
});
