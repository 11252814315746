document.addEventListener('turbolinks:load', () => {
  $('.btn-confirm')
    .click((event) => {
      if ($(event.target)
        .hasClass('disabled') || $(event.target)
        .parents('.disabled').length > 0) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
      let confirm = $(event.target)
        .data('confirm-message');
      if (!confirm) {
        confirm = 'Are you sure?';
      }
      if (!window.confirm(confirm)) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
});
