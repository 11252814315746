import React, { useState, useEffect, useContext } from 'react';
import { Spinner, ModalBody, ModalFooter } from 'reactstrap';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import { CompanyContext } from '../data/company_context_management';
import { sortSourcesByName } from '../../utilities';
import ExpertForm from './expert_form';

function ExpertsForm() {
  const perPage = 9999;
  const [sources, setSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [asyncError, setAsyncError] = useState();

  const {
    companyId,
    setOpenEditModal,
    setGeneralError,
    isUpdating,
    setDoTopLevelFetch,
  } = useContext(CompanyContext);

  // Set Modal fixed height to stay within viewport in order to show modal footer without having to scroll
  useEffect(() => {
    const setModalHeight = () => {
      document.querySelector('.modal-content').style.maxHeight = '90vh';
    };
    setModalHeight();
  }, []);

  useEffect(() => {
    // Alphabetize list of experts
    const fetchSources = async () => {
      try {
        if (companyId) {
          setIsLoading(true);
          vahoy.track('ExpertsForm#fetchSources');

          const params = {
            page: 1,
            per_page: perPage,
            alphabetized: true,
          };

          const response = await vapi.getCompanySources(params, companyId);
          if (response.status === 200) {
            const sourceResults = response.data;

            if (sourceResults.data && sourceResults.data.length > 0) {
              setSources((prevRecords) => sortSourcesByName([...prevRecords, ...sourceResults.data]));
            }
          }

          setIsLoading(false);
        }
      } catch (error) {
        setAsyncError(error);
      }
    };

    fetchSources();
  }, [companyId]);

  const renderExperts = () => {
    if (!sources.length) return null;
    const sourcesResult = [...sources];

    if (searchTerm == '') {
      return sourcesResult.map((source) => (
        <ExpertForm
          key={source.id}
          source={source}
          sources={sources}
          setSources={setSources}
          setDoTopLevelFetch={setDoTopLevelFetch}
        />
      ));
    }

    return sourcesResult
      .filter((src) => {
        const sourceName = src.attributes.full_name.toLowerCase();
        const searchedName = searchTerm.toLowerCase();
        return sourceName.includes(searchedName);
      })
      .map((source) => (
        <ExpertForm
          key={source.id}
          source={source}
          sources={sources}
          setSources={setSources}
          setDoTopLevelFetch={setDoTopLevelFetch}
        />
      ));
  };

  const closeModal = () => {
    setOpenEditModal(null);
    setGeneralError(null);
  };

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  return (
    <>
      <ModalBody style={{ overflowY: 'scroll' }}>
        {!isLoading && (
          <div className="row justify-content-between px-3 mb-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by name"
              value={searchTerm}
            />
          </div>
        )}

        {renderExperts()}

        {isLoading && (
          <div className="row d-flex justify-content-center">
            <Spinner
              color="dark"
              className="me-2 mb-3"
              style={{
                width: '2.5rem',
                height: '2.5rem',
              }}
            />
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeModal}
          disabled={isUpdating}
        >
          Close
        </button>
      </ModalFooter>
    </>
  );
}

export default ExpertsForm;
