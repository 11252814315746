import React from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch, SearchBox,
} from 'react-instantsearch-dom';
import MenuBar from '../../directory/MenuBar';
import InfiniteHits from './infinite_hits';
import ClearRefinementsButton from '../../algolia_search/clear_refinements_button';
import PriceFilterDropdown from './price_filter_dropdown';
import ProductCategoriesPanel from './product_categories_panel';
import SamplesAvailablePanel from './samples_available_panel';
import SmartStats from '../../algolia_search/smart_stats';

function ProductsSearch({
                         view, query, algoliaAppId, algoliaSearchKey, indexName, displayContactAndInfo,
                       }) {
  const smallMode = true;

  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

  return (
    <div className="ais-InstantSearch mt-3">
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <MenuBar view={view}>
          {{
            searchForm: (
              <>
                <SearchBox
                  autoFocus
                  translations={{
                    placeholder: 'Search',
                  }}
                  searchAsYouType={true}
                  defaultRefinement={query}
                  className="d-flex me-3"
                />
                <ClearRefinementsButton
                  buttonText="Clear"
                  clearsQuery={true}
                  bootstrapClasses="px-3 btn btn-sm btn-outline-info text-nowrap"
                />
              </>
            ),
          }}
        </MenuBar>

        <div className="row mb-2">
          <div className="col pe-4 text-end">
            <SmartStats />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-3">
            <SamplesAvailablePanel />
            <ProductCategoriesPanel />
            <PriceFilterDropdown />
          </div>
          <div className="col">
            <InfiniteHits smallMode={smallMode} displayContactAndInfo={displayContactAndInfo} query={query} />
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

ProductsSearch.propTypes = {
  view: PropTypes.string,
  query: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  displayContactAndInfo: PropTypes.bool,
};

ProductsSearch.defaultProps = {
  view: 'products',
  query: '',
  displayContactAndInfo: true,
};

export default ProductsSearch;
