import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  createBillingAccount(delegateUserId, params) {
    vlog.info('api#createBillingAccount');
    api.configure();

    const url = '/api/internal/billing_accounts';

    return axios.post(url, { delegate_user_id: delegateUserId, billing_account: params }, api.axiosFix);
  },
  updateBillingAccount(id, params) {
    vlog.info('api#updateBillingAccount');
    api.configure();

    const url = `/api/internal/billing_accounts/${id}'`;

    return axios.patch(url, { billing_account: params }, api.axiosFix);
  },
  cleanupAccount(accountCode) {
    vlog.info('api#cleanupAccount');
    api.configure();

    const url = '/api/internal/billing_accounts/cleanup';

    return axios.post(url, { account_code: accountCode }, api.axiosFix);
  },
  finalizeAccount(accountCode) {
    vlog.info('api#finalizeAccount');
    api.configure();

    const url = '/api/internal/billing_accounts/finalize';

    return axios.post(url, { account_code: accountCode }, api.axiosFix);
  },
  calculateUpgradeProration(accountCode, planCode, interval) {
    vlog.info('api#calculateUpgradeProration');
    api.configure();

    const url = '/api/internal/billing_accounts/calculate_upgrade_proration';

    return axios.post(url, { account_code: accountCode, plan_code: planCode, interval }, api.axiosFix);
  },
  updatePlan(accountCode, planCode, interval) {
    vlog.info('api#updatePlan');
    api.configure();

    const url = '/api/internal/billing_accounts/update_plan';

    return axios.post(url, { account_code: accountCode, plan_code: planCode, interval }, api.axiosFix);
  },
  finalizePlanUpdate(invoiceCode) {
    vlog.info('api#finalizePlanUpdate');
    api.configure();

    const url = '/api/internal/billing_accounts/finalize_plan_update';

    return axios.post(url, { invoice_code: invoiceCode }, api.axiosFix);
  },
  cancelPlanUpdate(invoiceCode) {
    vlog.info('api#cancelPlanUpdate');
    api.configure();

    const url = '/api/internal/billing_accounts/cancel_plan_update';

    return axios.post(url, { invoice_code: invoiceCode }, api.axiosFix);
  },
});
