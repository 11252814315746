import React from 'react';
import {
  FormGroup, Modal, ModalHeader, ModalBody, Label, Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';
import 'select2';
import Avatar from '../../avatar/avatar';
import vahoy from '../../../javascript/vahoy';

class ContactSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: props.showModal,
      possibleContacts: {},
    };

    this.fetchPossibleContacts = this.fetchPossibleContacts.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.setupSelect2 = this.setupSelect2.bind(this);
  }

  componentDidMount() {
    this.fetchPossibleContacts();
  }

  // eslint-disable-next-line react/sort-comp
  fetchPossibleContacts() {
    const { organization } = this.props;

    if (organization) {
      vapi.getOrganizationContacts(organization.id)
        .then((response) => {
          this.setState({
            possibleContacts: response.data.data,
          });
        });
    }
  }

  formatContacts(contact) {
    const attrs = contact.attributes;

    if (contact.loading) {
      return contact.text;
    }

    let row = `${attrs.full_name}`;
    if (attrs.email) {
      row += `, ${attrs.email}`;
    }

    return row;
  }

  formatNoResults() {
    return (
      <span>
        ...nothing found:
        <button type="button" id="new-contact-btn" className="btn btn-outline-secondary">New contact</button>
      </span>
    );
  }

  setupSelect2() {
    const that = this;
    const $contactSearchModal = $('#contact-search-modal');
    const $eventSelect = $('#searchContactName').select2({
      ajax: {
        url: '/api/internal/jsonapi/contacts',
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            filter: {
              name: params.term,
            },
          };
        },
        processResults(response) {
          return {
            results: response.data,
          };
        },
      },
      dropdownParent: $contactSearchModal.parent(), // https://github.com/select2/select2/issues/4217#issuecomment-277799699
      escapeMarkup(markup) {
        return markup;
      },
      minimumInputLength: 1,
      templateResult: this.formatContacts,
      language: {
        inputTooShort() {
          return '';
        },
        noResults: this.formatNoResults,
      },
    });

    $contactSearchModal.on('click', '#new-contact-btn', () => {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.onNew();
      vahoy.track('Opportunity/Contact/Search#clickNewButton');
    });

    $eventSelect.on('select2:select', (e) => {
      that.onSelect(e.params.data.id, e.params.data.attributes);
    });
  }

  onSelect(contactId) {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onSubmit(contactId);

    vahoy.track('Opportunity/Contact/ContactSearch#selectContact');
  }

  onOpened = () => {
    this.setupSelect2();
  };

  render() {
    const { showModal, possibleContacts } = this.state;
    const { onHide, organization } = this.props;

    return (
      <Modal
        id="contact-search-modal"
        className="modal-lg"
        isOpen={showModal}
        toggle={onHide}
        onOpened={this.onOpened}
      >
        <ModalHeader toggle={onHide}>
          Search for contact
        </ModalHeader>

        <ModalBody>

          <FormGroup>
            <Label for="searchContactName">First, try searching:</Label>
            <Input id="searchContactName" type="select" name="search_contact_name" />
          </FormGroup>

          {possibleContacts && possibleContacts.length > 0
            && (
              <div className="clearfix pt-2 pb-2">
                <div>
                  <label>... also, {organization.attributes.name} has these existing
                    contacts:
                  </label>
                </div>

                <div className="container">
                  <div className="row">
                    {possibleContacts.map((item) => (
                      <div
                        key={item.id}
                        style={{ cursor: 'pointer' }}
                        className="contact-image col-4 ps-0 mb-3"
                      >
                        <div
                          style={{ cursor: 'pointer' }}
                          role="button"
                          tabIndex="-1"
                          onClick={(e) => {
                            e.preventDefault();
                            this.onSelect(item.id);
                          }}
                          onKeyUp={(evt) => evt.keyCode == 13 && this.onSelect(item.id)}
                        >
                          <Avatar
                            avatarUrl={item.attributes.avatar_cloudinary_url}
                            avatarLetters={item.attributes.initials}
                            sizeClass="avatar-sm"
                          />
                          {$.trim(item.attributes.full_name).length > 0
                            && (
                              <div>
                                {item.attributes.full_name}
                                <br />
                              </div>
                            )}

                          {item.attributes.email.length > 0
                            && (
                              <div>
                                {item.attributes.email}
                                <br />
                              </div>
                            )}

                          {item.attributes.title}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="clearfix" />
              </div>
            )}

          <div>
            <strong>
              If you can't find an existing contact:
            </strong>
            &nbsp;&nbsp;
            <button id="new-contact-btn" className="btn btn-outline-secondary" type="button">New contact</button>
          </div>

          <div className="clearfix" />
        </ModalBody>
      </Modal>
    );
  }
}

ContactSearch.propTypes = {
  showModal: PropTypes.bool,
  organization: PropTypes.instanceOf(Object),
  onHide: PropTypes.func.isRequired,
  onNew: PropTypes.func,
  onSubmit: PropTypes.func,
};

ContactSearch.defaultProps = {
  showModal: undefined,
  organization: undefined,
  onNew: undefined,
  onSubmit: undefined,
};

export default ContactSearch;
