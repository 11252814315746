import React from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import vahoy from '../javascript/vahoy';

class ActionEmailPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
    };
  }

  close = () => {
    this.setState({ showPopup: false });
  };

  showPopup = () => {
    this.setState({ showPopup: true });
    vahoy.track('ActionEmailPopup#showPopup');
  };

  trackClicks = () => {
    vahoy.track('ActionEmailPopup#clickOpenInEmail');
  };

  render() {
    const { body, destination_email: destinationEmail, subject } = this.props;
    const { button_text: buttonText, button_icon: buttonIcon } = this.props;
    const { showPopup } = this.state;
    const bodySubNewLinesWithEscapeChar = body.replace(/\r\n|\r|\n/g, '%0D%0A');
    const mailToHref = `mailto:${destinationEmail}?subject=${subject}&body=${bodySubNewLinesWithEscapeChar}`;

    return (
      <span>
        {buttonText
          && (
            <button type="button" className="btn btn-outline-primary mb-1" onClick={this.showPopup}>
              {buttonText}
            </button>
          )}

        {buttonIcon
          && (
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm mb-1"
              onClick={this.showPopup}
              style={{ borderRadius: '0.2rem', height: '1.88rem', width: '2.1rem' }}
            >
              <i className="fa-solid fa-envelope" />
            </button>
          )}

        <Modal className="modal-lg" isOpen={showPopup} toggle={this.close}>
          <ModalHeader toggle={this.close}>
            {buttonText}
          </ModalHeader>

          <ModalBody>
            <div className="row">

              <p className="col-12">
                Click&nbsp;
                <Button
                  className="btn-sm"
                  href={mailToHref}
                  onClick={this.trackClicks}
                >
                  Open in email
                </Button>
                &nbsp;to open in your email client. Alternatively, copy and paste this into an email.
              </p>

              <hr className="col-12" />

              <label className="col-2 col-form-label">
                To
              </label>
              <div className="col-10 mb-1">
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail3"
                  readOnly
                  value={destinationEmail}
                />
              </div>

              <label className="col-2 col-form-label">
                Subject
              </label>
              <div className="col-10 mb-3">
                <input
                  type="subject"
                  className="form-control"
                  id="inputSubject3"
                  readOnly
                  value={subject}
                />
              </div>

              <div className="col-12">
                <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'keep-all' }}>
                  {body}
                </pre>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" href={mailToHref} onClick={this.trackClicks}>Open in email</Button>
            <Button onClick={this.close}>Close</Button>
          </ModalFooter>

        </Modal>
      </span>
    );
  }
}

ActionEmailPopup.propTypes = {
  body: PropTypes.string.isRequired,
  destination_email: PropTypes.string,
  subject: PropTypes.string,
  button_text: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  button_icon: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

ActionEmailPopup.defaultProps = {
  destination_email: undefined,
  subject: undefined,
  button_text: undefined,
  button_icon: undefined,
};

export default ActionEmailPopup;
