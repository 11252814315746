import React from 'react';
import FollowupButton from './follow_up/followup_button';

function QuickReply() {
  const fillTextArea = (message) => {
    jQuery('#message_body').val(message);
  };

  return (
    <div className="follow-up">
      <span className="ps-1 float-start">
        <FollowupButton
          buttonText="Let's talk!"
          clickEvent={() => fillTextArea("Hi, thanks for reaching out. We'd be delighted to help. When is a good time to talk?")}
          className="btn btn-outline-primary mb-1"
        />
      </span>
      <span className="ps-1 float-start">
        <FollowupButton
          buttonText="Sorry, can't help ..."
          clickEvent={() => fillTextArea("Hi! I'm afraid we can't help at this time but thank you for reaching out and do keep us in mind for the future!")}
          className="btn btn-outline-primary mb-1"
        />
      </span>
    </div>
  );
}

export default QuickReply;
