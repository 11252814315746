import React from 'react';
import _ from 'lodash';
import loadingImageUrl from '../assets/images/loading.svg';

function LoadingImage(props) {
  const imgProps = _.assign({}, props);
  imgProps.src = imgProps.src || loadingImageUrl;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <img {...imgProps} alt={imgProps.alt || ''} />
  );
}

export default LoadingImage;
