import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import vahoy from '../../../javascript/vahoy';
import SmartHighlight from '../../algolia_search/smart_highlight';
import SmartSnippet from '../../algolia_search/smart_snippet';

function SourceRequestBody({ sourceRequest, smallMode }) {
  const trackClicks = () => {
    vahoy.track('SourceRequests/SourceRequestCard#clickSourceRequestLink');
  };
  const exposure = sourceRequest.request_type_text === 'exposure';
  const sharedArticle = sourceRequest.shared_article;
  const [tabTooltipOpen, setTabTooltipOpen] = useState(false);
  const tabTooltipTargetRef = useRef();

  return (
    <>
      {!exposure
        && (
          <div className="mb-0 text-uppercase text-secondary font-size-12px mt-3">
            {sourceRequest.request_sub_type_text && (`${sourceRequest.request_sub_type_text} `)}
            {sourceRequest.request_type_text} request
            {sourceRequest.paid && (
              <strong className="text-danger"> (FEE-BASED OPPORTUNITY)</strong>
            )}
          </div>
        )}
        {(!exposure || (sharedArticle && !sharedArticle.title)) && (
          <>
            <a
              href={sourceRequest.source_request_path}
              onClick={trackClicks}
            >
              <SmartHighlight attribute="name" hit={sourceRequest} />
            </a>
            &nbsp;
            <a
              className="font-size-10px"
              href={sourceRequest.source_request_path}
              onClick={trackClicks}
              rel="noreferrer"
              target="_blank"
            >
              <i className="ps-2 fa-xl fa-solid fa-up-right-from-square" ref={tabTooltipTargetRef} />
            </a>
            <Tooltip
              placement="top"
              isOpen={tabTooltipOpen}
              target={tabTooltipTargetRef}
              toggle={() => setTabTooltipOpen(!tabTooltipOpen)}
            >
              Open in new tab
            </Tooltip>
          </>
        )}
        {!smallMode && !exposure && (
          <p className="my-2 small">
            <SmartSnippet attribute="details" hit={sourceRequest} />
          </p>
        )}
    </>
  );
}

SourceRequestBody.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object).isRequired,
  smallMode: PropTypes.bool,
};

SourceRequestBody.defaultProps = {
  smallMode: false,
};

export default SourceRequestBody;
