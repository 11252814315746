import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import LoadingImage from '../loading_image';
import vahoy from '../../javascript/vahoy';

class AiDetectorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbsClicked: false, // To track if thumbs up/down have been clicked
    };
  }

  close = () => {
    this.props.onClose();
  };

  handleThumbsClick = (text, thumbType) => {
    // Handle the thumbs click and make them unclickable
    this.setState(() => ({
      thumbsClicked: true,
      clickedThumb: thumbType, // Track which thumb was clicked
    }));

    vahoy.track(`AiDetectorModal#${thumbType}`, { text, object_gid: this.props.ahoyObjectGid });
  };

  render() {
    const {
            text, showModal, aiResult, loading,
          } = this.props;
    const {
            thumbsClicked, clickedThumb,
          } = this.state;

    const colorMapping = {
      human: '#28a745',
      mixed: '#6f42c1',
      ai: '#d39e00',
    };

    const predictedClass = aiResult?.predictedClass || 'unknown';
    const classProbability = aiResult?.classProbability || {};
    const circleColor = colorMapping[predictedClass] || '#ccc';

    // Circle properties
    const circleRadius = 70; // Radius of the circle
    const circleCircumference = 2 * Math.PI * circleRadius; // Circumference of the circle

    const circleContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    };

    return (
      <Modal className="modal-lg" isOpen={showModal} toggle={this.close}>
        <ModalHeader toggle={this.close}>
          <div className="h3 mb-0">Check for AI</div>
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <div className="row ai-detector-response">
              <div className="col-12">
                <div className="mt-4 mb-4 text-center">
                  <LoadingImage height="50" />
                </div>
              </div>
            </div>
          ) : (
            <div className="row ai-detector-response">
              <div className="col-md-6" style={circleContainerStyle}>
                <div style={{ position: 'relative', width: '160px', height: '160px' }}>
                  {/* SVG Progress Circle */}
                  <svg
                    width="160"
                    height="160"
                    viewBox="0 0 160 160"
                    style={{ position: 'absolute', top: 0, left: 0 }}
                  >
                    {/* Background circle (mixed) */}
                    <circle
                      cx="80"
                      cy="80"
                      r={circleRadius}
                      stroke={colorMapping.mixed}
                      strokeWidth="10"
                      fill="none"
                    />
                    {/* Foreground circle (human) */}
                    <circle
                      cx="80"
                      cy="80"
                      r={circleRadius}
                      stroke={colorMapping.human}
                      strokeWidth="10"
                      fill="none"
                      strokeDasharray={`${(classProbability.human || 0) * circleCircumference} ${circleCircumference}`}
                      strokeDashoffset="0"
                      strokeLinecap="butt"
                      style={{
                        transform: 'rotate(-90deg)',
                        transformOrigin: 'center',
                        transition: 'stroke-dashoffset 0.5s ease-in-out',
                      }}
                    />
                    {/* Foreground circle (AI) */}
                    <circle
                      cx="80"
                      cy="80"
                      r={circleRadius}
                      stroke={colorMapping.ai}
                      strokeWidth="10"
                      fill="none"
                      strokeDasharray={`${(classProbability.ai || 0) * circleCircumference} ${circleCircumference}`}
                      strokeDashoffset={`-${(classProbability.human || 0) * circleCircumference}`}
                      strokeLinecap="butt"
                      style={{
                        transform: 'rotate(-90deg)',
                        transformOrigin: 'center',
                        transition: 'stroke-dashoffset 0.5s ease-in-out',
                      }}
                    />
                  </svg>
                  {/* Text inside the circle */}
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span style={{ color: circleColor, fontWeight: 'bold', fontSize: '20px' }}>
                      {predictedClass.toUpperCase()}
                    </span>
                  </div>
                </div>
                <div className="mt-3 text-center">
                  <div className="percentage" style={{ color: colorMapping.human }}>
                    {Math.round(classProbability.human * 100)}% Human
                  </div>
                  <div className="percentage" style={{ color: colorMapping.mixed }}>
                    {Math.round(classProbability.mixed * 100)}% Mixed
                  </div>
                  <div className="percentage" style={{ color: colorMapping.ai }}>
                    {Math.round(classProbability.ai * 100)}% AI
                  </div>
                </div>
                <div className="mt-3 text-center">
                  <span className="text-muted">How did GPTZero do?</span>
                  <button
                    type="button"
                    onClick={() => this.handleThumbsClick(text, 'thumbsUp')}
                    disabled={thumbsClicked}
                    style={{ background: 'none', border: 'none', cursor: thumbsClicked ? 'not-allowed' : 'pointer' }}
                  >
                    <i
                      className="fa-solid fa-thumbs-up"
                      style={{ color: thumbsClicked && clickedThumb === 'thumbsUp' ? 'black' : 'gray' }}
                    />
                  </button>
                  <button
                    type="button"
                    onClick={() => this.handleThumbsClick(text, 'thumbsDown')}
                    disabled={thumbsClicked}
                    style={{ background: 'none', border: 'none', cursor: thumbsClicked ? 'not-allowed' : 'pointer' }}
                  >
                    <i
                      className="fa-solid fa-thumbs-down"
                      style={{ color: thumbsClicked && clickedThumb === 'thumbsDown' ? 'black' : 'gray' }}
                    />
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <p className="text-muted">Powered by © GPTZero</p>
                <p>
                  <a href="https://gptzero.me/" className="text-primary" target="_blank" rel="noreferrer">GPTZero</a> is a leading tool for spotting whether a document was written by AI like ChatGPT.
                </p>
                <p>
                  Note: This tool is designed to provide helpful insights, but it's not perfect and accuracy may vary.
                  We encourage you to use your judgment and discretion when reviewing the results.
                </p>
                <p className="fst-italic">
                  <a href="https://www.qwoted.com/combating-disinformation-in-the-age-of-artificial-intelligence-2/" target="_blank" rel="noreferrer">Read more</a> about Qwoted's efforts to combat spam pitching and disinformation.
                </p>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

AiDetectorModal.propTypes = {
  text: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  aiResult: PropTypes.shape({
    predictedClass: PropTypes.string,
    classProbability: PropTypes.shape({
      ai: PropTypes.number,
      human: PropTypes.number,
      mixed: PropTypes.number,
    }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  ahoyObjectGid: PropTypes.string,
};

AiDetectorModal.defaultProps = {
  ahoyObjectGid: undefined,
};

export default AiDetectorModal;
