// vapi = 'Vested Api'

import api from './api';

// alphabetized
import './article';
import './avatar';
import './billing_account';
import './company';
import './company_product';
import './contact';
import './contact_employment';
import './contact_info';
import './favorite';
import './flag';
import './hashtag';
import './job_posting';
import './messaging';
import './opportunity';
import './opportunity_contact';
import './opportunity_organization';
import './organization';
import './organization_logo';
import './pitch';
import './pr_user';
import './product';
import './product_image';
import './promo_entity';
import './publication';
import './report_abuse';
import './reporter';
import './reporter_employment';
import './reporter_mute';
import './representation';
import './represented_product';
import './source';
import './source_employment';
import './source_headshot';
import './source_represented_source';
import './source_request';
import './source_request_comment';
import './user';
import './user_search';

const vapi = api;

export default vapi;
