import React from 'react';
import {
  Panel,
  RefinementList,
  ToggleRefinement,
} from 'react-instantsearch-dom';

function PublicationFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by publication">
          <ToggleRefinement
            attribute="publication.top_publication"
            label="Top publications only"
            value={true}
            defaultRefinement={false}
          />
          <Panel header="Publications" className="mt-1">
            <RefinementList
              attribute="publication.name"
              limit={6}
              showMore={true}
              showMoreLimit={15}
              searchable={true}
            />
          </Panel>
        </Panel>
      </div>
    </div>
  );
}

export default PublicationFilterPanel;
