import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  reportAbuse(params) {
    vlog.info('api#reportAbuse');

    api.configure();
    axios.defaults.headers.common.Accept = 'application/json';

    const url = '/api/internal/report_abuses';

    return axios.post(url, { abuse_report: params }, api.axiosFix);
  },
});
