import helper from '../../../helpers/helper';
import select2Helpers from '../../../helpers/select2_helpers';

document.addEventListener('turbolinks:load', () => {
  const $prUserEmploymentCompanyId = $('#pr_user_employment_company_id:visible');

  if (
    helper.notPresent($prUserEmploymentCompanyId)
  ) {
    return;
  }

  select2Helpers.setupSelect2($prUserEmploymentCompanyId, '/api/internal/jsonapi/companies?policy_used=associable', select2Helpers.formatCompanies);
});
