import React, { useState, useContext } from 'react';
import {
  ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import { CompanyContext } from '../../data/company_context_management';

function AddSectionButton() {
  const {
    hiddenSectionsAvailable,
    aboutSectionHidden,
    contactSectionHidden,
    hashtagsSectionHidden,
    twitterFeedSectionHidden,
    prUsersSectionHidden,
    prAgenciesSectionHidden,
    setOpenEditModal,
    company,
  } = useContext(CompanyContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  if (!hiddenSectionsAvailable) return null;

  const openModal = (modalName) => {
    setOpenEditModal(modalName);
  };

  return (
    <ButtonDropdown isOpen={showDropdown} toggle={toggleDropdown}>
      <DropdownToggle caret size="sm" outline className="mb-2 me-1" style={{ width: 'auto', maxHeight: '2rem' }}>
        <i className="fa-solid fa-plus cursor-pointer" />
        &nbsp;
        Add a section
      </DropdownToggle>
      <DropdownMenu>
        {aboutSectionHidden && (
          <DropdownItem onClick={() => openModal('MAIN')}>About Us</DropdownItem>
        )}
        {contactSectionHidden && (
          <DropdownItem onClick={() => openModal('CONTACT_INFO')}>Contact Info</DropdownItem>
        )}
        {hashtagsSectionHidden && (
          <DropdownItem onClick={() => openModal('HASHTAGS')}>Expertise</DropdownItem>
        )}
        {company.display_as_pr_firm && !company.show_brands && (
          <DropdownItem onClick={() => openModal('MAIN')}>Our Brands</DropdownItem>
        )}
        {!company.display_as_pr_firm && prAgenciesSectionHidden && (
          <DropdownItem onClick={() => openModal('PR_AGENCIES')}>Our PR Agencies</DropdownItem>
        )}
        {/* The "Our PR Team" display is controlled in two different places based on PR Firm view or not */}
        {!company.display_as_pr_firm && prUsersSectionHidden && (
          <DropdownItem onClick={() => openModal('PR_USERS')}>Our PR Team</DropdownItem>
        )}
        {company.display_as_pr_firm && !company.show_pr_users && (
          <DropdownItem onClick={() => openModal('MAIN')}>Our PR Team</DropdownItem>
        )}
        {twitterFeedSectionHidden && (
          <DropdownItem onClick={() => openModal('TWITTER_FEED')}>Twitter Feed</DropdownItem>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export default AddSectionButton;
