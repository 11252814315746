import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import AttachedExistingEntity from './AttachedExistingEntity';
import AttachedNewEntity from './AttachedNewEntity';
import AiDetectorButton from '../../pitch/ai_detector_button';

function PitchTextEditor({
                           pitchText,
                           setPitchText,
                           pitchTextEdited,
                           attachedEntities,
                           deleteExistingEntityFromPitch,
                           deleteNewEntity,
                           requestId,
                         }) {
  const handlePitchTextChange = (event) => {
    setPitchText(event.target.value);
  };

  const textAreaClasses = attachedEntities.length > 0 ? 'border-bottom' : '';

  return (
    <div
      className="pitch-text-editor bg-light d-flex flex-column border rounded mb-3 w-100 position-relative"
    >
      {pitchTextEdited && (
        <div className="position-absolute small bottom-0 end-0 p-2 text-secondary">
          <i className="fa-solid fa-circle" /> Edited
        </div>
      )}
      <TextareaAutosize
        className={`form-control bg-transparent border-0 rounded-0 ${textAreaClasses} shadow-none`}
        cols={50}
        id="pitch_text"
        onChange={handlePitchTextChange}
        placeholder="Type your pitch here..."
        required
        rows={15}
        value={pitchText}
      />
      <div className="attachments d-flex align-items-center m-2">
        {attachedEntities.map((entity) => (
          <React.Fragment key={entity.existing?.localId || entity.new?.localId}>
            {entity.existing && (
              <AttachedExistingEntity
                key={entity.existing.pitchedEntityId}
                name={entity.existing.name}
                profileImageUrl={entity.existing.profileImageUrl}
                promoEntityId={entity.existing.id}
                removeCallback={deleteExistingEntityFromPitch}
                subheading={entity.existing.subheading}
              />
            )}
            {entity.new && (
              <AttachedNewEntity
                key={entity.new.localId}
                localId={entity.new.localId}
                name={entity.new.name}
                removeCallback={deleteNewEntity}
                subheading={entity.new.subheading}
                type={entity.new.type}
              />
            )}
          </React.Fragment>
        ))}
        <div className="ms-auto">
          <AiDetectorButton text={pitchText} ahoyObjectGid={`gid://vested/SourceRequest/${requestId}`} />
        </div>
      </div>
    </div>
  );
}

PitchTextEditor.propTypes = {
  pitchText: PropTypes.string,
  setPitchText: PropTypes.func.isRequired,
  pitchTextEdited: PropTypes.bool.isRequired,
  attachedEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteExistingEntityFromPitch: PropTypes.func.isRequired,
  deleteNewEntity: PropTypes.func.isRequired,
  requestId: PropTypes.number.isRequired,
};

PitchTextEditor.defaultProps = {
  pitchText: undefined,
};

export default PitchTextEditor;
