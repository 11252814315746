import vahoy from '../../../javascript/vahoy';
import vapi from '../../../javascript/frontend/api/vapi';

const useCompanyUpdateApi = (
  {
    setIsUpdating,
    setGeneralError,
    setAsyncError,
    company,
    setOpenEditModal,
    setDoTopLevelFetch,
  },
) => {
  const updateCompany = async (data) => {
    const cleanData = () => {
      const newData = {};
      Object.keys(data).forEach((key) => {
        if (data[key]?.length === 0) {
          newData[key] = null;
        } else {
          newData[key] = data[key];
        }
      });

      return newData;
    };

    try {
      setIsUpdating(true);
      setGeneralError(null);

      vahoy.track('Companies#updateCompany');

      const params = {
        id: company.objectID,
        ...cleanData(),
      };

      const response = await vapi.updateCompany(params);

      if (response.status === 200) {
        setOpenEditModal(null);
        setDoTopLevelFetch(true);
      } else {
        setGeneralError(`Received response code ${response.status} while performing the update.`);
      }
    } catch (error) {
      setAsyncError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return { updateCompany };
};

export default useCompanyUpdateApi;
