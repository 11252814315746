import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';
import PropTypes from 'prop-types';
import helper from '../../javascript/frontend/helpers/helper';
import LoadingImage from '../loading_image';

const invalidErrorText = 'Invalid value';
const requiredErrorText = 'This field is required';

class ContactInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {};
  }

  getFormErrors() {
    const val = this.inputInstance.value;
    const { infoType } = this.props;
    let fn;
    const errors = {};

    if (infoType === 'email') {
      fn = helper.isValidEmail;
    } else if (infoType === 'phone') {
      fn = helper.isValidPhone;
    } else if (infoType === 'twitter') {
      fn = helper.isValidTwitter;
    } else if (infoType === 'facebook') {
      fn = helper.isValidFacebook;
    } else if (infoType === 'linkedin') {
      fn = helper.isValidLinkedin;
    }

    if (fn) {
      if (!fn(val)) {
        errors.value = invalidErrorText;
      }
    } else if (!val) {
      errors.value = requiredErrorText;
    }

    return errors;
  }

  submit = (evt) => {
    evt && evt.preventDefault();
    const errors = this.getFormErrors();
    const val = this.inputInstance.value;
    const { onSave } = this.props;

    if (_.isEmpty(errors)) {
      this.setState({ displayErrors: false });
      onSave && onSave(val, () => {
        this.setState({ displayErrors: true });
      });
    } else {
      this.setState({ displayErrors: true });
    }
  };

  renderError(errors, field) {
    const errorText = errors[field];

    if (!errorText) {
      return;
    }

    return (
      <small className="form-text d-block text-danger">
        {errorText}
      </small>
    );
  }

  render() {
    const {
      isSaving, isOpening, placeholder, label, onCancel, headerContent, value, responseError,
    } = this.props;
    const { isRemoveable, remove } = this.props;
    let saveButton;
    let removeButton;
    const { displayErrors } = this.state;

    // NOTE: Jsonapi-resources returns errors in array
    const serverErrors = (responseError || {}).errors || [];
    const error = serverErrors[0] || {};
    const localErrors = displayErrors ? this.getFormErrors() : {};
    const errors = {
      value: localErrors.value || error.detail,
    };

    if (isSaving) {
      saveButton = (
        <LoadingImage height="30px" />
      );
    } else {
      saveButton = (<button type="submit" className="btn btn-primary mb-2">Save</button>);
    }

    if (isRemoveable) {
      removeButton = (
        <button type="button" className="btn btn-danger mb-2 ms-2 btn-remove" onClick={remove}>
          <i className="fa fa-trash-can" />
        </button>
      );
    }

    return (
      <Modal isOpen={isOpening} className="contact-info-modal">
        <ModalHeader toggle={() => onCancel && onCancel()}>{headerContent}</ModalHeader>
        <ModalBody>
          <form className="" onSubmit={this.submit}>
            <div className="d-flex flex-row required w-100">
              <div className="mx-sm-3 mb-2" style={{ flex: 1 }}>
                <label className="visually-hidden">{label}</label>
                <input
                  type="text"
                  className="form-control contact-info-input w-100"
                  defaultValue={value}
                  placeholder={placeholder || label}
                  ref={(instance) => {
                    this.inputInstance = instance;
                  }}
                  required
                />
              </div>
              {saveButton}
              {removeButton}
            </div>
            <div className="mx-sm-3">{this.renderError(errors, 'value')}</div>
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

ContactInfoModal.propTypes = {
  infoType: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  isOpening: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  value: PropTypes.string,
  responseError: PropTypes.instanceOf(Object),
  isRemoveable: PropTypes.bool,
  remove: PropTypes.func,
};

ContactInfoModal.defaultProps = {
  isSaving: undefined,
  isOpening: undefined,
  placeholder: undefined,
  label: undefined,
  headerContent: undefined,
  value: undefined,
  responseError: undefined,
  isRemoveable: undefined,
  remove: undefined,
};

export default ContactInfoModal;
