import axios from 'axios';
import _ from 'lodash';
import api from './api';
import helper from '../helpers/helper';
import vlog from '../../vlog';

_.extend(api, {
  getCompanies(params) {
    api.configure();
    const options = { params };

    return axios.get('/api/internal/companies', options, api.axiosFix);
  },

  getCompany(params) {
    api.configure();
    const { id } = params;

    return axios.get(`/api/internal/companies/${id}`, null, api.axiosFix);
  },

  createCompany(params) {
    api.configure();

    return axios.post('/api/internal/companies', { company: params }, api.axiosFix);
  },

  // updateCompany: Updates only non-file attributes
  // For updating files (like images) use updateCompanyUsingFormData below
  updateCompany(params) {
    api.configure();
    const { id, ...company } = params;

    return axios.patch(
      `/api/internal/companies/${id}`,
      { company },
      api.axiosFix,
    );
  },

  // updateCompanyUsingFormData: Use when saving files
  // to the company object (e.g. image files like logo image)
  updateCompanyUsingFormData(companyId, company) {
    const url = `/api/internal/companies/${companyId}`;
    const params = { company };
    const formData = helper.objectToFormData(params);

    return axios.put(url, formData, api.axiosFix);
  },

  deleteCompany(params) {
    api.configure();
    const { objectID } = params;

    const url = `/api/internal/companies/${objectID}`;

    return axios.delete(url, api.axiosFix);
  },

  connectWithCompany(companyId, attrs) {
    api.configure();

    return axios.post(
      `/api/internal/companies/${companyId}/connect`,
      { contact: attrs },
      api.axiosFix,
    );
  },

  searchCompanies(params) {
    api.configure();
    const options = { params };

    return axios.get('/api/internal/jsonapi/companies', options, api.axiosFix);
  },

  getCompanyAhoyEvents(companyId, page, limit) {
    vlog.info('api#getCompanyAhoyEvents');

    api.configure();
    const url = `/api/internal/companies/${companyId}/ahoy_events?page=${page}&limit=${limit}`;

    return axios.get(url, {}, api.axiosFix);
  },
});
