import React from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../javascript/vahoy';

class TweetButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  trackClicks = () => {
    // eslint-disable-next-line react/destructuring-assignment
    vahoy.track('TweetButton#click', { object_gid: this.props.ahoyObjectGid });
  };

  render() {
    const {
      text, url, label, target = 'self', className = '',
    } = this.props;
    const tweetUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
    const script = {
      __html: '!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],p=/^http:/.test(d.location)?"http":"https";if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src=p+"://platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js,fjs);}}(document, "script", "twitter-wjs");',
    };

    /* eslint-disable react/no-danger */
    return (
      <div className="inline-block">
        <a
          href={tweetUrl}
          className={`twitter-share-button btn ${className}`}
          style={{ borderRadius: '1.5em', backgroundColor: '#009EF6', color: 'white' }}
          data-size="large"
          onClick={this.trackClicks}
          target={target}
          rel={target == '_blank' ? 'noreferrer noopener' : ''}
        >
          <i className="fa-brands fa-x-twitter me-2" />
          {label || 'Tweet'}
        </a>
        <script
          dangerouslySetInnerHTML={script}
        />
      </div>
    );
    /* eslint-enable react/no-danger */
  }
}

TweetButton.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
  ahoyObjectGid: PropTypes.string,
  label: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
};

TweetButton.defaultProps = {
  url: '',
  ahoyObjectGid: undefined,
  label: undefined,
  target: undefined,
  className: undefined,
};

export default TweetButton;
