import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/avatar';
import vahoy from '../../../javascript/vahoy';

function ContactInfo(props) {
  const {
    contact,
    contact_employment: contactEmployment,
    hide_view_reporter_details_page: hideViewReporterDetailsPage,
    organization,
    hide_contact_info_details: hideContactInfoDetails,
  } = props;
  const contactEmpty = _.isEmpty(contact);

  if (contactEmpty) {
    return null;
  }

  const contactAttrs = contact.attributes;
  const contactLinks = contact.links;
  const trackClicks = (clickType) => {
    vahoy.track(`Opportunity/Contact/ContactInfo#clickLink_${clickType}`);
  };

  let contactUrl = null;

  if (!hideViewReporterDetailsPage) {
    if (contactAttrs.reporter === 'true') {
      contactUrl = contactLinks.frontend_reporter;
    } else {
      contactUrl = contactLinks.frontend;
    }
  }

  let avatarCloudinaryUrl = null;
  if (contactAttrs) {
    avatarCloudinaryUrl = contactAttrs.avatar_cloudinary_url;
  }

  let title = null;
  if (contactEmployment) {
    title = contactEmployment.attributes.title;
  }

  let organizationName;
  let organizationLink;
  let organizationLinkJsx;
  if (organization) {
    const organizationAttrs = organization.attributes;
    const organizationLinks = organization.links;

    organizationName = organizationAttrs.name;
    organizationLink = organizationLinks.frontend;
    organizationLinkJsx = <a href={organizationLink}>{organizationName}</a>;
  }

  return (
    <div>

      <div className="float-start me-2 mb-1">
        <Avatar
          avatarUrl={avatarCloudinaryUrl}
          avatarLetters={contactAttrs.initials}
          sizeClass="avatar-sm"
        />
      </div>

      {(!_.isEmpty(contactAttrs.full_name)
        && (
          <div>
            {contactUrl
              && (
                <a
                  href={contactUrl}
                  onClick={() => trackClicks('contact')}
                  className="fw-bold mb-1"
                >{contactAttrs.full_name}
                </a>
              )}

            {!contactUrl
              && <span className="fw-bold">{contactAttrs.full_name}</span>}

            {contactEmployment
              && (
                <div className="font-size-12px fw-bold">
                  {title} at {organizationLinkJsx}
                </div>
              )}
          </div>
        )
      )}

      <div className="clearfix mb-2" />

      {(!hideContactInfoDetails

        && (
          <ul className="list-group list-group-borderless list-group-xs font-size-14px">
            {contactAttrs.email
              && (
                <li className="list-group-item text-nowrap auto-truncate">
                  <a
                    href={`mailto:${contactAttrs.email}`}
                    onClick={() => trackClicks('email')}
                  ><i
                    className="fa-solid fa-envelope me-2"
                  />{contactAttrs.email}
                  </a>
                </li>
              )}

            {contactAttrs.phone
              && (
                <li className="list-group-item text-nowrap auto-truncate">
                  <a
                    href={`tel:${contactAttrs.phone}`}
                    onClick={() => trackClicks('phone')}
                  ><i
                    className="fa-solid fa-phone me-2"
                  />{contactAttrs.phone}
                  </a>
                </li>
              )}

            {contactAttrs.url
              && (
                <li className="list-group-item text-nowrap auto-truncate">
                  <a
                    href={contactAttrs.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackClicks('url')}
                  ><i className="fa-solid fa-link me-2" />{contactAttrs.url}
                  </a>
                </li>
              )}

            {contactAttrs.twitter
              && (
                <li className="list-group-item text-nowrap auto-truncate">
                  <a
                    href={`https://twitter.com/${contactAttrs.twitter}`}
                    onClick={() => trackClicks('twitter')}
                    target="_blank"
                    rel="noopener noreferrer"
                  ><i
                    className="fa-brands fa-x-twitter me-2"
                  />{contactAttrs.twitter}
                  </a>
                </li>
              )}

            {contactAttrs.facebook
              && (
                <li className="list-group-item text-nowrap auto-truncate">
                  <a
                    href={`https://facebook.com/${contactAttrs.facebook}`}
                    onClick={() => trackClicks('facebook')}
                    target="_blank"
                    rel="noopener noreferrer"
                  ><i
                    className="fa-brands fa-facebook me-2"
                  />{contactAttrs.facebook}
                  </a>
                </li>
              )}
          </ul>
        )
      )}

    </div>
  );
}

ContactInfo.propTypes = {
  contact: PropTypes.instanceOf(Object),
  contact_employment: PropTypes.instanceOf(Object),
  hide_view_reporter_details_page: PropTypes.bool,
  hide_contact_info_details: PropTypes.bool,
  organization: PropTypes.instanceOf(Object),
};

ContactInfo.defaultProps = {
  contact: undefined,
  contact_employment: undefined,
  hide_view_reporter_details_page: undefined,
  hide_contact_info_details: undefined,
  organization: undefined,
};

export default ContactInfo;
