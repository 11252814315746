import React from 'react';
import PropTypes from 'prop-types';
import avatarHelpers from '../../javascript/frontend/helpers/avatar_helpers';

function Logo({
                logoUrl,
                orgName,
                className,
                style,
              }) {
  const colorClass = avatarHelpers.colorTextClassFromName(orgName);

  return (
    <div className="flex-shrink-0">
      {logoUrl && (
        <img alt={orgName} src={logoUrl} style={style} className={`${className}`} />
      )}
      {!logoUrl && (
        <i
          className={`fa-solid fa-city ${colorClass} ${className || ''}`}
          style={{
            width: '3.75rem',
            height: '3.75rem',
            fontSize: '2.9375rem',
          }}
        />
      )}
    </div>
  );
}

Logo.propTypes = {
  logoUrl: PropTypes.string,
  orgName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};

Logo.defaultProps = {
  logoUrl: undefined,
  orgName: undefined,
  className: undefined,
  style: {},
};

export default Logo;
