import React from 'react';
import CompanyFilterPanel from './filters/company_filter_panel';
import HashtagFilterPanel from './filters/hashtag_filter_panel';
import LastVisitFilterPanel from './filters/last_visit_filter_panel';
import SourceProfileCompletionFilterPanel from './filters/source_profile_completion_filter_panel';

function Filters() {
  return (
    <div>
      <HashtagFilterPanel />
      <CompanyFilterPanel />
      <LastVisitFilterPanel />
      <SourceProfileCompletionFilterPanel />
    </div>
  );
}

export default Filters;
