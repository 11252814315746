import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';

function StaffTopAgenciesCurationIndex({ api_name: apiName }) {
  const [topAgencies, setTopAgencies] = useState([]);
  const [loadingIds, setLoadingIds] = useState([]);

  useEffect(() => {
    axios.get(`/api/internal/top_agencies.json?api_name=${apiName}`)
      .then((response) => {
        setTopAgencies(response.data);
      })
      .catch((error) => {
        alert('Error fetching Top Agencies:', error);
      });
  }, [apiName]);

  const toggleIneligible = (id, currentState) => {
    setLoadingIds([...loadingIds, id]);
    axios.put(`/api/internal/top_agencies/${id}?api_name=${apiName}`, {
      top_agencies_ineligible: !currentState,
    })
      .then((response) => {
        setLoadingIds(loadingIds.filter((loadingId) => loadingId !== id));
        setTopAgencies(topAgencies.map((agency) => (agency.agency_id === id ? response.data : agency)));
      })
      .catch((error) => {
        setLoadingIds(loadingIds.filter((loadingId) => loadingId !== id));
        alert('Error updating:', error);
      });
  };

  return (
    <div>
      {topAgencies.length === 0 ? (
        <Spinner color="dark" />
      ) : (
        <table className="table">
          <thead>
          <tr>
            <th>Agency Name</th>
            <th>Eligible</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          {topAgencies.map((agency, index) => (
            <tr key={index}>
              <td><a href={`/companies/${agency.agency_id}`}>{agency.agency_name}</a></td>
              <td>
                {!agency.top_agencies_ineligible && (<span>✅</span>)}
                {agency.top_agencies_ineligible && (<span>❌</span>)}
              </td>
              <td>
                {loadingIds.includes(agency.agency_id) ? (
                  <div>
                    <Spinner
                      color="dark"
                      className="me-2"
                      style={{
                        width: '1.25rem',
                        height: '1.25rem',
                      }}
                    />
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={() => toggleIneligible(agency.agency_id, agency.top_agencies_ineligible)}
                  >
                    Toggle
                  </button>
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

StaffTopAgenciesCurationIndex.propTypes = {
  api_name: PropTypes.string.isRequired,
};

export default StaffTopAgenciesCurationIndex;
