import helper from '../../helpers/helper';

document.addEventListener('turbolinks:load', () => {
  if (helper.notPresent($('[data-js="pitch-show-more-button"]'))) {
    return;
  }

  function expandAllPitches() {
    $('[data-js="pitch-show-more-button"]')
      .filter('.collapsed')
      .click();

    $('#expando-collapso-button')
      .html('Collapse all');
    $('#expando-collapso-button')
      .removeClass('collapsed');
    $('#expando-collapso-button')
      .addClass('expanded');
  }

  function collapseAllPitches() {
    $('[data-js="pitch-show-more-button"]')
      .filter('.expanded')
      .click();

    $('#expando-collapso-button')
      .html('Expand all');
    $('#expando-collapso-button')
      .removeClass('expanded');
    $('#expando-collapso-button')
      .addClass('collapsed');
  }

  $('#pills-views-tab')
    .on('click', () => {
      window.location = `${window.location.pathname}?tabname=views`;
    });

  $('#expando-collapso-button')
    .on('click', () => {
      if ($('#expando-collapso-button')
        .hasClass('collapsed')) {
        expandAllPitches();
      } else {
        collapseAllPitches();
      }
    });

  $('#selected-pitches-form').submit(() => {
    const checked = $('#selected-pitches-form input:checked').length > 0;
    if (!checked) {
      return false;
    }
  });
});
