import React from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../javascript/vahoy';

class HashtagsEditableList extends React.Component {
  trackClicks = () => {
    vahoy.track('hashtag/HashtagsEditableList#clickHashtag');
  };

  renderHashtag = (hashtag) => {
    const hideFromUsers = hashtag.attributes.hide_from_users
      && hashtag.attributes.hide_from_users === 'true';

    const doNotCrawl = hashtag.attributes.do_not_crawl
      && hashtag.attributes.do_not_crawl === 'true';

    const manuallyRemoved = hashtag.attributes.manually_removed
      && hashtag.attributes.manually_removed === 'true';

    const manuallyAdded = hashtag.attributes.manually_added
      && hashtag.attributes.manually_added === 'true';

    let additionalClasses = '';
    let titleString = '';

    if (doNotCrawl) {
      additionalClasses += ' bg-danger';
      titleString += 'Do not crawl ';
    } else if (hideFromUsers) {
      additionalClasses += ' bg-warning';
      titleString += 'Hidden ';
    }

    if (manuallyRemoved) {
      additionalClasses += ' opacity-50';
      titleString += 'Manually removed';
    } else if (manuallyAdded && window.CURRENT_USER_INTERNAL) {
      additionalClasses += ' bg-success';
      titleString += 'Manually added';
    }

    const hashtagHref = hashtag.links.frontend;

    return (
      <a
        className={`p-2 me-2 mb-2 badge bg-secondary ${additionalClasses}`}
        href={hashtagHref}
        key={hashtag.id}
        title={titleString}
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        onClick={this.trackClicks}
        style={{ letterSpacing: '0.05em' }}
      >
        {hashtag.attributes.hashtag}
      </a>
    );
  };

  render() {
    const { max_hashtags: numberOfHashtagsToDisplayInList, hashtags } = this.props;
    const displayHashtags = _.take(hashtags, numberOfHashtagsToDisplayInList);

    return (
      <div>
        {displayHashtags.map((hashtag) => this.renderHashtag(hashtag))}
      </div>
    );
  }
}

HashtagsEditableList.propTypes = {
  max_hashtags: PropTypes.number,
  hashtags: PropTypes.arrayOf(Object).isRequired,
};

HashtagsEditableList.defaultProps = {
  max_hashtags: undefined,
};

export default HashtagsEditableList;
