import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {

  createSource(source) {
    vlog.info('api#createSource');

    api.configure();

    const url = '/api/internal/sources';

    return axios.post(url, { source }, api.axiosFix)
      .then((result) => result)
      .catch((errorResponse) => ({
        responseStatus: 'error',
        responseError: errorResponse,
      }));
  },

  // TODO establish naming convention?
  //  This makes a JSONAPI call and is modeled after loadReporters.
  //  getSources by contrast calls the internal RPC API
  loadSources(params) {
    vlog.info('api#loadSources');

    api.configure();

    const url = '/api/internal/jsonapi/sources';

    return axios.get(url, { params }, api.axiosFix);
  },

  getSource(id, params) {
    api.configure();
    const options = { params };
    return axios.get(`/api/internal/jsonapi/sources/${id}.json`, options, api.axiosFix);
  },

  getSources(params) {
    api.configure();
    const options = { params };

    return axios.get('/api/internal/sources', options, api.axiosFix);
  },

  getSourcesJsonApi(params) {
    api.configure();
    const options = { params };

    return axios.get('/api/internal/jsonapi/sources', options, api.axiosFix);
  },

  getCompanySources(params, companyId) {
    api.configure();
    const options = { params };

    return axios.get(`/api/internal/companies/${companyId}/sources`, options, api.axiosFix);
  },

  getAlgoliaAttribsForSourceIds(ids) {
    api.configure();
    const idStr = ids.join('&ids[]=');

    return axios.get(`/api/internal/sources/algolia_attribs?ids[]=${idStr}`, {}, api.axiosFix);
  },

});
