import React from 'react';
import _ from 'lodash';
import linkifyHtml from 'linkifyjs/html';
import PropTypes from 'prop-types';
import vapi from '../../javascript/frontend/api/vapi';
import Avatar from '../avatar/avatar';
import AvatarUploader from '../avatar/avatar_uploader';
import BioModal from './bio_modal';
import ConfirmModal from '../confirm_modal';
import ContactInfoModal from './contact_info_modal';
import ContactInfos from './contact_infos';
import ContentTabs from './content_tabs';
import EditIcon from './edit_icon';
import ExpertOn from './expert_on';
import HashtagsEditableForm from '../hashtag/_form';
import helper from '../../javascript/frontend/helpers/helper';
import LoadingImage from '../loading_image';
import LocationModal from './location_modal';
import PeopleAlsoViewedReporters from './people_also_viewed_reporters';
import Publications from './publications';
import ReporterModal from './reporter_modal';
import TwitterFeed from '../twitter_feed';
import WordsCloud from '../reporter_stats/word_cloud';
import WorkExperienceFormModal from './work_experience_modal';
import dateHelpers from '../../javascript/frontend/helpers/date_helpers';
import vahoy from '../../javascript/vahoy';

const maxNumberOfDisplayedPublications = 7;
const maxNumberOfExpertOnHashtags = 5;
const bannerMinWidth = 893;
const bannerMinHeight = 300;

class FullProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return { isLoadingReporter: true };
  }

  componentDidMount() {
    this.loadReporter();
    this.loadContactInfos();
    this.loadReporterEmployments();
    this.loadUser();
    // this.loadManagingReporterUser();

    jQuery(window)
      .resize(() => {
        if (jQuery('.banner-image-container img').length > 0) {
          const bannerWidth = jQuery('.banner-image-container')
            .outerWidth();
          const bannerHeight = (bannerMinHeight / bannerMinWidth) * bannerWidth;

          jQuery('.banner-image-container')
            .css('height', `${bannerHeight}px`);
        }
      });
  }

  // eslint-disable-next-line react/sort-comp
  saveContactInfo = (infoType, contactInfoId, value) => {
    const { reporter } = this.state;

    if (infoType === 'full_name') {
      const model = _.cloneDeep(reporter);
      model.attributes.full_name = value;
      this.setState({ isSavingContactInfo: true });

      vapi.updateReporter(model)
        .then(() => {
          this.setState({
            isSavingContactInfo: false,
            addingOrEditingContactInfo: false,
          });
          this.loadReporter();
        });

      vahoy.track('Reporter/FullProfile#saveReporterFullName');
    } else if (contactInfoId) {
      const { contactInfos } = this.state;
      const contactInfo = _.cloneDeep(contactInfos.find((ci) => ci.id === contactInfoId));

      contactInfo.attributes.info_type = infoType;
      contactInfo.attributes.value = value;

      this.setState({ isSavingContactInfo: true });
      vapi.updateContactInfo(
        contactInfo,
        contactInfo.attributes.contactable_id,
        contactInfo.attributes.contactable_type,
      )
        .then((response) => {
          if (response.message && /Request failed/i.test(response.message)) {
            const responseError = response.response;
            let message;
            const states = { isSavingContactInfo: false };

            if (responseError && responseError.data) {
              const responseData = responseError.data;
              if (responseData.errors && responseData.errors.length > 0) {
                const error = responseData.errors[0];

                states.contactInfoResponseError = responseData;
                message = error.detail;
              } else {
                message = responseData.message || responseData.error || 'Something went wrong, please try again later.';
              }
            } else {
              message = 'Something went wrong, please try again later.';
            }

            this.setState(states);
            alert(message);
          } else {
            this.setState({
              isSavingContactInfo: false,
              addingOrEditingContactInfo: false,
            });
            this.loadContactInfos();

            if (infoType === 'url') {
              this.loadReporter();
            }
          }
        });

      vahoy.track(`Reporter/FullProfile#updateContactInfo_${infoType}`);
    } else {
      this.setState({ isSavingContactInfo: true });
      const data = {
        attributes: {
          info_type: infoType,
          value,
        },
      };

      vapi.createContactInfo(data, reporter.id, 'Reporter')
        .then((response) => {
          if (response.message && /Request failed/i.test(response.message)) {
            const responseError = response.response;
            let message;
            const states = { isSavingContactInfo: false };

            if (responseError && responseError.data) {
              const responseData = responseError.data;
              if (responseData.errors && responseData.errors.length > 0) {
                const error = responseData.errors[0];

                states.contactInfoResponseError = responseData;
                message = error.detail;
              } else {
                message = responseData.message || responseData.error || 'Something went wrong, please try again later.';
              }
            } else {
              message = 'Something went wrong, please try again later.';
            }

            this.setState(states);
            alert(message);
          } else {
            this.setState({
              isSavingContactInfo: false,
              addingOrEditingContactInfo: false,
            });
            this.loadContactInfos();

            if (infoType === 'url') {
              this.loadReporter();
            }
          }
        });

      vahoy.track(`Reporter/FullProfile#createContactInfo_${infoType}`);
    }
  };

  // eslint-disable-next-line react/sort-comp
  removeContactInfo = () => {
    const { infoModel } = this.state;

    if (infoModel) {
      const { contactInfos } = this.state;
      const contactInfo = _.cloneDeep(contactInfos.find((ci) => ci.id === infoModel.id));

      this.setState({ isRemovingContactInfo: true });
      vapi.deleteContactInfo(contactInfo)
        .then(() => {
          this.setState({
            isRemovingContactInfo: false,
            confirmRemovingContactInfo: false,
          });
          this.loadContactInfos();
        })
        .catch((xhr) => {
          let message;
          const states = { isRemovingContactInfo: false };

          if (xhr && xhr.response && xhr.response.data) {
            const responseData = xhr.response.data;
            if (responseData.errors) {
              const responseError = xhr.response.data;
              const { errors } = responseError;

              message = helper.getMessagesFromErrors(errors)
                .join('. ');
            } else {
              message = responseData.message || responseData.error || 'Something went wrong, please try again later.';
            }
          } else {
            message = 'Something went wrong, please try again later.';
          }

          this.setState(states);
          alert(message);
        });

      vahoy.track('Reporter/FullProfile#removeContactInfo');
    }
  };

  // eslint-disable-next-line react/sort-comp
  saveAddress = (obj) => {
    let attributes;
    if (obj) {
      attributes = {
        skip_geocoding: true,
        location_geocoded_at: Date.now(),
        location_latitude: obj.lat,
        location_longitude: obj.lng,
        location_string: obj.fullAddress,
      };
    } else {
      attributes = {
        skip_geocoding: false,
        location_string: '',
      };
    }

    const { reporterId } = this.props;
    this.setState({ isSavingAddress: true });

    vapi.updateReporterUsingFormData(reporterId, attributes)
      .then(() => {
        this.toggleAddOrEditAddress();
        this.setState({
          isSavingAddress: false,
          confirmRemovingLocation: false,
        });
        this.loadReporter();
      })
      .catch((xhr) => {
        let message;
        const states = { isSavingAddress: false };

        if (xhr && xhr.response && xhr.response.data) {
          const responseData = xhr.response.data;
          if (responseData.errors) {
            const responseError = xhr.response.data;
            const { errors } = responseError;

            states.addressResponseError = responseError;
            message = helper.getMessagesFromErrors(errors)
              .join('. ');
          } else {
            message = responseData.message || responseData.error || 'Something went wrong, please try again later.';
          }
        } else {
          message = 'Something went wrong, please try again later.';
        }

        this.setState(states);
        alert(message);
      });

    vahoy.track('Reporter/FullProfile#updateReporterAddress');
  };

  saveWorkExperience = (form, callback) => {
    const { reporter } = this.state;
    const data = { title: form.title };

    if (form.start_date_month && form.start_date_year) {
      data.start_date = `${form.start_date_year}-${form.start_date_month}-01`;
    }

    data.featured = form.featured || false;

    if (form.end_date_month && form.end_date_year) {
      data.end_date = `${form.end_date_year}-${form.end_date_month}-01`;
    }

    if (form.publication_id && form.publication_id !== 'new') {
      data.publication_id = form.publication_id;
    } else {
      data.publication_name = form.publication_name;
    }

    this.setState({ isSavingWorkExperience: true });
    vapi.createReporterWorkExperience(reporter.id, data)
      .then(() => {
        callback && callback();
        this.setState({
          isSavingWorkExperience: false,
          addingWorkExperience: false,
        });
        this.loadReporter();
        this.loadReporterEmployments();
      })
      .catch((xhr) => {
        let message;
        const states = { isSavingWorkExperience: false };

        if (xhr && xhr.response && xhr.response.data) {
          const responseData = xhr.response.data;
          if (responseData.errors) {
            const responseError = xhr.response.data;
            const { errors } = responseError;

            states.workExperienceResponseError = responseError;
            message = helper.getMessagesFromErrors(errors)
              .join('. ');
          } else {
            message = responseData.message || responseData.error || 'Something went wrong, please try again later.';
          }
        } else {
          message = 'Something went wrong, please try again later.';
        }

        this.setState(states);
        alert(message);
      });

    vahoy.track('Reporter/FullProfile#createReporterWorkExperience');
  };

  saveEditingWorkExperience = (form, callback) => {
    const {
      reporter,
      editingReporterEmployment,
    } = this.state;
    const data = { title: form.title };

    if (form.start_date_month && form.start_date_year) {
      data.start_date = `${form.start_date_year}-${form.start_date_month}-01`;
    }

    data.featured = form.featured || false;

    if (form.end_date_month && form.end_date_year) {
      data.end_date = `${form.end_date_year}-${form.end_date_month}-01`;
    }

    if (form.publication_id && form.publication_id !== 'new') {
      data.publication_id = form.publication_id;
    } else {
      data.publication_name = form.publication_name;
    }

    this.setState({ isSavingWorkExperience: true });
    vapi.updateReporterWorkExperience(reporter.id, editingReporterEmployment.id, data)
      .then(() => {
        callback && callback();
        this.setState({
          isSavingWorkExperience: false,
          editingReporterEmployment: undefined,
        });
        this.loadReporter();
        this.loadReporterEmployments();
      })
      .catch((xhr) => {
        let message;
        const states = { isSavingWorkExperience: false };

        if (xhr && xhr.response && xhr.response.data) {
          const responseData = xhr.response.data;
          if (responseData.errors) {
            const responseError = xhr.response.data;
            const { errors } = responseError;

            states.workExperienceResponseError = responseError;
            message = helper.getMessagesFromErrors(errors)
              .join('. ');
          } else {
            message = responseData.message || responseData.error || 'Something went wrong, please try again later.';
          }
        } else {
          message = 'Something went wrong, please try again later.';
        }

        this.setState(states);
        callback && callback();
        alert(message);
      });

    vahoy.track('Reporter/FullProfile#updateReporterWorkExperience');
  };

  saveReporter = (attributes, callback) => {
    const { reporterId } = this.props;
    this.setState({ isSavingReporter: true });

    vapi.updateReporterUsingFormData(reporterId, attributes)
      .then(() => {
        this.toggleEditReporter();
        this.setState({ isSavingReporter: false });
        this.loadReporter();
        this.loadContactInfos();
      })
      .catch((xhr) => {
        let message;
        const states = { isSavingReporter: false };

        if (xhr && xhr.response && xhr.response.data) {
          const responseData = xhr.response.data;
          if (responseData.errors) {
            const responseError = xhr.response.data;
            const { errors } = responseError;

            states.reporterResponseError = responseError;
            message = helper.getMessagesFromErrors(errors)
              .join('. ');
          } else {
            message = responseData.message || responseData.error || 'Something went wrong, please try again later.';
          }
        } else {
          message = 'Something went wrong, please try again later.';
        }

        this.setState(states);
        callback && callback();
        alert(message);
      });

    vahoy.track('Reporter/FullProfile#updateReporter');
  };

  saveBio = (bio, callback) => {
    const { reporterId } = this.props;
    this.setState({ isSavingBio: true });

    vapi.updateReporterUsingFormData(reporterId, { bio })
      .then(() => {
        this.toggleAddOrEditBio();
        this.loadReporter();
        this.setState({
          isSavingBio: false,
          confirmRemovingBio: false,
        });
      })
      .catch((xhr) => {
        let message;
        const states = { isSavingBio: false };

        if (xhr && xhr.response && xhr.response.data) {
          const responseData = xhr.response.data;
          if (responseData.errors) {
            const responseError = xhr.response.data;
            const { errors } = responseError;

            states.bioResponseError = responseError;
            message = helper.getMessagesFromErrors(errors)
              .join('. ');
          } else {
            message = responseData.message || responseData.error || 'Something went wrong, please try again later.';
          }
        } else {
          message = 'Something went wrong, please try again later.';
        }

        this.setState(states);
        callback && callback();
        alert(message);
      });

    vahoy.track('Reporter/FullProfile#saveBio');
  };

  // NOTE: We already use vahoy.track on the onSortEnd, so no need to track here.
  saveReporterHashtagOrders = (orders) => {
    const { reporter } = this.state;
    vapi.saveReporterHashtagOrders(reporter.id, orders);
  };

  removeReporterEmployment = () => {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.removingReporterEmployment) {
      return;
    }

    const {
      reporterEmployments,
      confirmRemovingReporterEmployment: reporterEmployment,
    } = this.state;

    this.setState({ removingReporterEmployment: true });
    vapi.deleteReporterEmployment(reporterEmployment)
      .then((response) => {
        if (response.responseStatus) {
          const responseError = ((response.responseError || {}).response || {}).data;
          this.setState({ removingReporterEmployment: false });
          alert('Error deleting employment ');
          window.Rollbar && window.Rollbar.error('Failed to remove reporter employment', responseError);
        } else {
          const employmentIndex = reporterEmployments.indexOf(reporterEmployment);
          reporterEmployments.splice(employmentIndex, 1);
          this.setState({
            reporterEmployments,
            removingReporterEmployment: false,
            confirmRemovingReporterEmployment: undefined,
          });

          if (reporterEmployment.attributes.featured) {
            this.loadReporter();
          }
        }
      });

    vahoy.track('Reporter/FullProfile#deleteReporterEmployment');
  };

  loadReporter(params) {
    const { reporterId } = this.props;
    const requestParams = params || { hashtag_frequency: true };
    requestParams.include = requestParams.include || 'avatar';

    vapi.loadReporter(reporterId, requestParams)
      .then((response) => {
        const responseData = response.data;
        const states = { isLoadingReporter: false };
        const included = responseData.included || [];
        const reporter = responseData.data;
        const avatar = included.find((rc) => rc.type === 'avatars');

        reporter.avatar = avatar;
        states.reporter = reporter;
        this.setState(states);

        // eslint-disable-next-line react/destructuring-assignment
        if (!this.state.hashtagTags) {
          this.loadHashtagTags(reporter);
        }

        if (reporter.attributes.banner_image_cloudinary) {
          // NOTE: To resize banner
          setTimeout(() => {
            jQuery(window)
              .resize();
          });
        } else {
          jQuery('.banner-image-container')
            .css('height', '');
        }
      })
      .catch(() => {
        const states = { isLoadingReporter: false };

        states.loadingReporterError = true;
        this.setState(states);
      });
  }

  // loadManagingReporterUser() {
  //   const { reporterId } = this.props;

  //   vapi.loadManagingReporterUser(reporterId)
  //     .then((response) => {
  //       const responseData = response.data;
  //       const states = {};

  //       states.managingReporterUser = responseData.data;
  //       this.setState(states);
  //     });
  // }

  loadContactInfos() {
    const { reporterId } = this.props;

    vapi.loadReporterContactInfos(reporterId, { 'page[limit]': 50 })
      .then((response) => {
        const responseData = response.data;
        const states = {};

        states.contactInfos = responseData.data;
        this.setState(states);
      });
  }

  loadReporterEmployments() {
    const { reporterId } = this.props;
    const params = {};
    params.include = 'publication';
    params['page[limit]'] = 50;

    vapi.getReporterEmployments(reporterId, params)
      .then((response) => {
        const responseData = response.data;
        const states = {};

        states.reporterEmployments = responseData.data;
        states.publications = (responseData.included || []).filter((record) => record.type === 'publications');

        this.setState(states);
      });
  }

  loadHashtagTags = (reporter, page) => {
    const {
      reporter: stateReporter,
      isLoadingHashtagTags,
      hashtagTags: stateHashtagTags,
    } = this.state;

    // eslint-disable-next-line no-param-reassign
    reporter = reporter || stateReporter;

    // eslint-disable-next-line no-param-reassign
    page = page || 1;

    if (isLoadingHashtagTags) {
      return;
    }

    const limit = 50;
    const url = `/api/internal/jsonapi/reporters/${reporter.id}/derived-hashtag-tags?page[offset]=${(page - 1) * limit}&page[limit]=${limit}`;
    this.setState({ isLoadingHashtagTags: true });

    vapi.getHashtags(url)
      .then((response) => {
        let hashtagTags = response.data.data;
        const states = { isLoadingHashtagTags: false };

        if (page > 1) {
          hashtagTags = stateHashtagTags.concat(hashtagTags);
        }

        states.hashtagTags = hashtagTags;
        this.setState(states);
      })
      .catch(() => {
        this.setState({ isLoadingHashtagTags: false });
      });
  };

  loadUser() {
    const { userId } = this.props;

    if (userId) {
      vapi.getUser(userId)
        .then((response) => {
          const responseData = response.data;
          const states = {};

          states.currentUser = responseData.data;
          this.setState(states);
        });
    }
  }

  onClickEditIcon = (infoType, model) => () => {
    if (infoType === 'reporter') {
      this.toggleEditReporter();
    } else if (infoType === 'bio') {
      this.toggleAddOrEditBio();
    } else if (infoType === 'address') {
      this.toggleAddOrEditAddress();
    } else if (infoType === 'hashtagTags') {
      this.toggleAddOrEditHashtagTags();
    } else if (infoType === 'publication') {
      this.toggleAddWorkExperience();
    } else {
      this.toggleAddOrEditContactInfo(infoType, model);
    }
  };

  onBannerChange = (evt) => {
    const { files = [] } = evt.target;

    if (files.length > 0) {
      const file = files[0];
      const { reporterId } = this.props;
      const attributes = {
        banner_image_cloudinary: file,
      };
      this.setState({ isSavingBannerImage: true });

      vapi.updateReporterUsingFormData(reporterId, attributes)
        .then(() => {
          this.setState({ isSavingBannerImage: false });
          this.loadReporter();
        })
        .catch(() => {
          this.setState({ isSavingBannerImage: false });
          alert('Something went wrong, please try again later');
        });

      vahoy.track('Reporter/FullProfile#uploadBannerImage');
    }
  };

  toggleRemovingBannerImageModal = () => {
    this.setState((prevState) => ({
      removingBannerImage: !prevState.removingBannerImage,
    }));
  };

  removeBannerImage = () => {
    const { reporterId } = this.props;
    const attributes = {
      remove_banner_image_cloudinary: true,
    };
    this.setState({
      isRemovingBannerImage: true,
      removingBannerImage: false,
    });

    vapi.updateReporterUsingFormData(reporterId, attributes)
      .then(() => {
        this.setState({ isRemovingBannerImage: false });
        this.loadReporter();
      })
      .catch(() => {
        this.setState({ isRemovingBannerImage: false });
        alert('Something went wrong, please try again later');
      });

    vahoy.track('Reporter/FullProfile#removeBannerImage');
  };

  toggleAddOrEditContactInfo = (infoType, infoModel) => {
    const { addingOrEditingContactInfo } = this.state;
    this.setState({
      addingOrEditingContactInfo: !addingOrEditingContactInfo,
      contactInfoResponseError: undefined,
      infoType,
      infoModel,
    });
  };

  toggleAddWorkExperience = (isAddingPublication) => {
    const { addingWorkExperience } = this.state;
    this.setState({
      addingWorkExperience: !addingWorkExperience,
      workExperienceResponseError: undefined,
      isAddingPublication,
    });
  };

  toggleEditReporterEmployment = (reporterEmployment, isFromPublication) => {
    const fromPublication = reporterEmployment ? isFromPublication : false;
    this.setState({
      editingReporterEmployment: reporterEmployment,
      fromPublication,
    });
  };

  toggleEditReporter = () => {
    const { editingReporter } = this.state;

    this.setState({
      editingReporter: !editingReporter,
      reporterResponseError: undefined,
    });
  };

  toggleAddOrEditBio = () => {
    this.setState((prevState) => ({
      addingOrEditingBio: !prevState.addingOrEditingBio,
    }));
  };

  toggleAddOrEditAddress = () => {
    this.setState((prevState) => ({
      addingOrEditingLocation: !prevState.addingOrEditingLocation,
    }));
  };

  toggleAddOrEditHashtagTags = (e) => {
    e && e.preventDefault();
    this.setState((prevState) => ({
      addingOrEditingHashtagTags: !prevState.addingOrEditingHashtagTags,
    }));
  };

  toggleConfirmRemovingReporterEmployment = (reporterEmployment) => {
    this.setState({ confirmRemovingReporterEmployment: reporterEmployment });
  };

  toggleConfirmRemovingContactInfo = (infoType, infoModel) => {
    this.setState({
      confirmRemovingContactInfo: (!!infoModel),
      infoType,
      infoModel,
      addingOrEditingContactInfo: false,
    });
  };

  toggleConfirmRemovingLocation = () => {
    this.setState((prevState) => ({
      confirmRemovingLocation: !prevState.confirmRemovingLocation,
    }));
  };

  toggleConfirmRemovingBio = () => {
    this.setState((prevState) => ({
      confirmRemovingBio: !prevState.confirmRemovingBio,
    }));
  };

  getCurrentEmployments() {
    const {
      reporterEmployments,
      publications = [],
    } = this.state;
    return (reporterEmployments || []).filter((ce) => {
      const isCurrent = !ce.attributes.end_date || dateHelpers.isFutureDatetime(ce.end_date);

      // eslint-disable-next-line arrow-parens, arrow-body-style
      return isCurrent && !!publications.find(pub => {
        return pub.id === ce.attributes.publication_id && (pub.attributes.name || pub.attributes.root_domain);
      });
    });
  }

  renderEmploymentTitle() {
    const { reporter } = this.state;

    if (reporter) {
      const title = reporter.attributes.reporter_employments_string;
      const currentEmployments = this.getCurrentEmployments();
      const reporterEmployment = currentEmployments.find((ce) => ce.attributes.featured === 'true');

      if (title) {
        return (
          <h4 className="fw-bold">
            {title} {reporterEmployment && this.renderEditIcon(() => this.toggleEditReporterEmployment(reporterEmployment), 'ms-2 p-0 btn-edit-title')}
          </h4>
        );
      }
    }
  }

  renderBioText() {
    const { reporter } = this.state;
    const { isEditable } = this.props;

    if (reporter) {
      if (reporter.attributes.bio) {
        const html = helper.simpleFormat(linkifyHtml(reporter.attributes.bio));
        /* eslint-disable react/no-danger */
        return (
          <div className="d-flex">
            <div className="d-inline-block w-100" dangerouslySetInnerHTML={{ __html: html }} />
            <span
              className=""
              style={{
                right: '-20px',
                top: 0,
                flex: 1,
              }}
            >
              {this.renderEditIcon(this.onClickEditIcon('bio'), 'btn-sm pt-0 pe-0 btn-edit-bio')}
            </span>
          </div>
        );
        /* eslint-enable react/no-danger */
      }
      if (isEditable) {
        return (
          <div>
            <span
              className="cursor-pointer fst-italic fw-lighter edit-bio"
              role="button"
              tabIndex="-1"
              onClick={this.toggleAddOrEditBio}
              onKeyUp={(evt) => evt.keyCode === 13 && this.toggleAddOrEditBio()}
            >
              Update bio <i className="fa-solid fa-pencil" />
            </span>
          </div>
        );
      }
    }
  }

  renderTwitterFeed() {
    // eslint-disable-next-line react/destructuring-assignment
    const contactInfos = this.state.contactInfos || [];
    let twitter = contactInfos.find((ci) => {
      const r = ci.attributes.info_type === 'twitter' && ci.attributes.primary === 'true';
      return r;
    });
    twitter = twitter || contactInfos.find((ci) => ci.attributes.info_type === 'twitter');

    if (twitter) {
      return (
        <div className="twitter-feed shadow-sm mt-3">
          <div className="card mb-3">
            <div className="card-header">
              Twitter Feed
            </div>

            <div className="card-body p-2">
              <TwitterFeed screenName={twitter.attributes.value} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <></>
    );
  }

  renderPublications() {
    const { isEditable } = this.props;
    const {
      publications = [],
      reporterEmployments = [],
    } = this.state;
    const publishedEmployments = reporterEmployments.filter((e) => {
      const pub = publications.find((p) => p.id === e.attributes.publication_id);
      return pub && (pub.attributes.name || pub.attributes.root_domain);
    });
    const currentEmployments = _.sortBy(publishedEmployments, (e) => 1 * e.attributes.articles_count)
      .reverse();

    return (
      <Publications
        isEditable={isEditable}
        publications={publications}
        currentEmployments={currentEmployments}
        maxNumberOfDisplayedPublications={maxNumberOfDisplayedPublications}
        toggleConfirmRemovingReporterEmployment={this.toggleConfirmRemovingReporterEmployment}
        toggleEditReporterEmployment={(ce) => this.toggleEditReporterEmployment(ce, true)}
        addReporterEmployment={this.toggleAddWorkExperience}
      />
    );
  }

  renderWritesMostOn() {
    const { reporter } = this.state;

    if (reporter && reporter.attributes) {
      const { hashtags_by_frequency: hashtagsByFrequency } = reporter.attributes;

      if ((hashtagsByFrequency || []).length > 0) {
        const words = (hashtagsByFrequency || []).map((tp) => ({
          text: tp.label,
          count: tp.count,
        }));

        return (
          <div className="mb-3 writes-most-on">
            <h4 className="bg-white shadow-sm p-3 fw-bold mb-0">Writes Most On</h4>
            <div className="bg-white shadow-sm p-3" style={{ marginTop: '1px' }}>
              <WordsCloud words={words} />
            </div>
          </div>
        );
      }
    }
  }

  renderPeopleAlsoViewed() {
    return (
      // eslint-disable-next-line react/destructuring-assignment
      <PeopleAlsoViewedReporters reporterId={this.props.reporterId} />
    );
  }

  renderModal() {
    const {
      addingOrEditingContactInfo,
      infoType,
      infoModel,
      addingWorkExperience,
      editingReporterEmployment,
      reporter,
      editingReporter,
      confirmRemovingReporterEmployment,
      addingOrEditingBio,
      addingOrEditingLocation,
      addingOrEditingHashtagTags,
      removingBannerImage,
      confirmRemovingContactInfo,
      confirmRemovingLocation,
      confirmRemovingBio,
      // managingReporterUser,
    } = this.state;

    if (addingOrEditingContactInfo) {
      let value;
      const cancelFn = this.toggleAddOrEditContactInfo;
      let label;
      let placeholder;
      let headerContent;
      let contactInfoId;
      const {
        isSavingContactInfo,
        contactInfoResponseError,
      } = this.state;

      if (infoType === 'twitter') {
        const twitter = infoModel;
        value = twitter && twitter.attributes.value;
        label = 'Twitter';
        contactInfoId = twitter && twitter.id;
        placeholder = 'Ex: @qwoted';

        headerContent = <div className="h4">{value ? 'Edit Twitter' : 'Add Twitter'}</div>;
      } else if (infoType === 'email') {
        const email = infoModel;
        value = email && email.attributes.value;
        label = 'Email';
        contactInfoId = email && email.id;
        placeholder = 'Ex: example@qwoted';

        headerContent = <div className="h4">{value ? 'Edit Email' : 'Add Email'}</div>;
      } else if (infoType === 'full_name') {
        value = reporter.attributes.full_name;
        label = 'Full name';
        contactInfoId = reporter.id;

        headerContent = <div className="h4">Change Name</div>;
      } else if (infoType === 'phone') {
        const phone = infoModel;
        value = phone && phone.attributes.value;
        label = 'Phone';
        contactInfoId = phone && phone.id;
        placeholder = 'Ex: 917.765.9411';

        headerContent = <div className="h4">{value ? 'Edit Phone' : 'Add Phone'}</div>;
      } else if (infoType === 'facebook') {
        const facebook = infoModel;
        value = facebook && facebook.attributes.value;
        label = 'Facebook';
        contactInfoId = facebook && facebook.id;
        placeholder = 'Ex: https://facebook.com/qwoted';

        headerContent = <div className="h4">{value ? 'Edit Facebook' : 'Add Facebook'}</div>;
      } else if (infoType === 'linkedin') {
        const linkedin = infoModel;
        value = linkedin && linkedin.attributes.value;
        label = 'Linkedin';
        contactInfoId = linkedin && linkedin.id;
        placeholder = 'Ex: https://linkedin.com/company/qwoted';

        headerContent = <div className="h4">{value ? 'Edit Linkedin' : 'Add Linkedin'}</div>;
      } else if (infoType === 'url') {
        value = infoModel && infoModel.attributes.value;
        label = 'Url';
        contactInfoId = infoModel && infoModel.id;
        placeholder = 'Ex: https://app.qwoted.com';

        headerContent = <div className="h4">{value ? 'Edit Url' : 'Add Url'}</div>;
      } else {
        throw new Error('Unimplemented');
      }

      return (
        <ContactInfoModal
          value={value}
          onSave={(val) => this.saveContactInfo(infoType, contactInfoId, val)}
          onCancel={cancelFn}
          label={label}
          isSaving={isSavingContactInfo}
          headerContent={headerContent}
          placeholder={placeholder}
          isOpening={addingOrEditingContactInfo}
          infoType={infoType}
          responseError={contactInfoResponseError}
          isRemoveable={['email', 'full_name'].indexOf(infoType) === -1}
          remove={() => this.toggleConfirmRemovingContactInfo(infoType, infoModel)}
        />
      );
    }

    if (addingOrEditingLocation && !confirmRemovingLocation) {
      const addressValue = reporter.attributes.location_string;
      const label = 'Location';
      const placeholder = 'New York, NY';
      const headerContent = <div className="h4">{addressValue ? 'Edit your location' : 'Add your location'}</div>;
      const {
        isSavingAddress,
        addressResponseError,
      } = this.state;

      return (
        <LocationModal
          value={addressValue}
          onSave={this.saveAddress}
          onCancel={this.toggleAddOrEditAddress}
          label={label}
          isSaving={isSavingAddress}
          headerContent={headerContent}
          placeholder={placeholder}
          isOpening={addingOrEditingLocation}
          responseError={addressResponseError}
          onRemove={this.toggleConfirmRemovingLocation}
        />
      );
    }

    if (addingWorkExperience) {
      const {
        isSavingWorkExperience,
        workExperienceResponseError,
      } = this.state;
      return (
        <WorkExperienceFormModal
          onSave={this.saveWorkExperience}
          onCancel={this.toggleAddWorkExperience}
          isSaving={isSavingWorkExperience}
          responseError={workExperienceResponseError}
          isOpening={addingWorkExperience}
          fromPublication
        />
      );
    }

    if (editingReporterEmployment) {
      const workExperience = _.cloneDeep(editingReporterEmployment.attributes);
      const {
        publications = [],
        fromPublication,
      } = this.state;
      const {
        isAddingPublication,
        isSavingWorkExperience,
        workExperienceResponseError,
      } = this.state;
      const pub = publications.find((p) => p.id === workExperience.publication_id);
      workExperience.publication_name = pub && (pub.attributes.name || pub.attributes.root_domain);
      workExperience.featured = workExperience.featured === 'true';

      // NOTE: its format is similar to 2010-01-01T05:00:00Z
      const startDateComps = workExperience.start_date.split('-');
      workExperience.start_date_year = 1 * startDateComps[0] || '';
      workExperience.start_date_month = 1 * startDateComps[1] || '';

      if (workExperience.end_date) {
        const endDateComps = workExperience.end_date.split('-');
        workExperience.end_date_year = 1 * endDateComps[0] || '';
        workExperience.end_date_month = 1 * endDateComps[1] || '';
      } else if (workExperience.start_date) {
        workExperience.is_current_company = true;
      }

      return (
        <WorkExperienceFormModal
          onSave={this.saveEditingWorkExperience}
          onCancel={this.toggleEditReporterEmployment}
          isSaving={isSavingWorkExperience}
          responseError={workExperienceResponseError}
          isOpening={!!editingReporterEmployment}
          isAddingPublication={isAddingPublication}
          workExperience={workExperience}
          fromPublication={fromPublication}
        />
      );
    }

    if (editingReporter) {
      const { contactInfos = [] } = this.state;
      const {
        isSavingReporter,
        reporterResponseError,
      } = this.state;
      const r = _.assign({}, reporter, { contact_infos: contactInfos });

      return (
        <ReporterModal
          reporter={r}
          onSave={this.saveReporter}
          onCancel={this.toggleEditReporter}
          isSaving={isSavingReporter}
          responseError={reporterResponseError}
          isOpening={editingReporter}
        />
      );
    }

    if (confirmRemovingReporterEmployment) {
      const { removingReporterEmployment } = this.state;

      return (
        <ConfirmModal
          confirmYesText="Yes"
          confirmNoText="Cancel"
          confirmContent="Are you sure you want to remove this publication?"
          confirmYes={this.removeReporterEmployment}
          confirmNo={() => this.toggleConfirmRemovingReporterEmployment()}
          isOpening
          className="modal-sm p-3 confirm-remove-publication-modal"
          confirmNoButtonClassName="shadow-none"
          isSubmitting={removingReporterEmployment}
        />
      );
    }

    if (addingOrEditingBio && !confirmRemovingBio) {
      const { isSavingBio } = this.state;

      return (
        <BioModal
          value={reporter.attributes.bio}
          onSave={this.saveBio}
          onCancel={this.toggleAddOrEditBio}
          isSaving={isSavingBio}
          isOpening
          onRemove={this.toggleConfirmRemovingBio}
        />
      );
    }

    if (addingOrEditingHashtagTags) {
      const { hashtagTags = [] } = this.state;
      const { isEditable } = this.props;
      const sortableHashtagTags = hashtagTags.filter((tp) => {
        const { display_order: displayOrder } = tp.attributes;
        return displayOrder !== null && displayOrder !== undefined && displayOrder !== '';
      });

      const unsortableHashtagTags = hashtagTags.filter((tp) => {
        const { display_order: displayOrder } = tp.attributes;
        return displayOrder === null || displayOrder === undefined || displayOrder === '';
      });

      const sortedHashtagTags = _.sortBy(sortableHashtagTags, (tp) => 1 * tp.attributes.order)
        .concat(_.sortBy(unsortableHashtagTags, (tp) => 1 * tp.id));
      const taggedJson = {
        id: reporter.id,
        type: 'reporters',
      };

      return (
        <HashtagsEditableForm
          can_edit={isEditable}
          closeEditingModal={() => {
            this.toggleAddOrEditHashtagTags();
            this.loadHashtagTags();
          }}
          tagged_json={taggedJson}
          refreshParent={this.loadHashtagTags}
          saveOrders={this.saveReporterHashtagOrders}
          max_hashtags={50}
          maxFeaturedHashtags={maxNumberOfExpertOnHashtags}
          hashtagType="derived_hashtag_tags"
          hashtagTags={sortedHashtagTags}
        />
      );
    }

    if (removingBannerImage) {
      const { isRemovingBannerImage } = this.state;

      return (
        <ConfirmModal
          confirmYesText="Yes"
          confirmNoText="No"
          confirmContent="Are you sure you want to remove this banner image?"
          confirmYes={this.removeBannerImage}
          confirmNo={() => this.toggleRemovingBannerImageModal()}
          isOpening
          className="modal-sm p-3 confirm-remove-banner-image-modal"
          confirmNoButtonClassName="shadow-none"
          isSubmitting={isRemovingBannerImage}
        />
      );
    }

    if (confirmRemovingContactInfo) {
      const { isRemovingContactInfo } = this.state;
      return (
        <ConfirmModal
          confirmYesText="Yes"
          confirmNoText="No"
          confirmContent="Are you sure you want to remove this info?"
          confirmYes={this.removeContactInfo}
          confirmNo={() => this.toggleConfirmRemovingContactInfo()}
          isOpening
          className="modal-sm p-3 confirm-remove-contact-info-modal"
          confirmNoButtonClassName="shadow-none"
          isSubmitting={isRemovingContactInfo}
        />
      );
    }

    if (confirmRemovingLocation) {
      const { isSavingAddress } = this.state;
      return (
        <ConfirmModal
          confirmYesText="Yes"
          confirmNoText="No"
          confirmContent="Are you sure you want to remove this info?"
          confirmYes={() => this.saveAddress()}
          confirmNo={this.toggleConfirmRemovingLocation}
          isOpening
          className="modal-sm p-3 confirm-remove-contact-info-modal"
          confirmNoButtonClassName="shadow-none"
          isSubmitting={isSavingAddress}
        />
      );
    }

    if (confirmRemovingBio) {
      const { isSavingBio } = this.state;
      return (
        <ConfirmModal
          confirmYesText="Yes"
          confirmNoText="No"
          confirmContent="Are you sure you want to remove this info?"
          confirmYes={() => this.saveBio('')}
          confirmNo={this.toggleConfirmRemovingBio}
          isOpening
          className="modal-sm p-3 confirm-remove-contact-info-modal"
          confirmNoButtonClassName="shadow-none"
          isSubmitting={isSavingBio}
        />
      );
    }

    return <></>;
  }

  renderEditIcon(onClickFn, additionalClasses, optionalText = null) {
    const { isEditable } = this.props;

    return (
      <EditIcon
        isEditable={isEditable}
        optionalText={optionalText}
        onClickFn={onClickFn}
        className={additionalClasses}
      />
    );
  }

  render() {
    const {
      isEditable,
      userId,
      reporterId,
      canViewArticles,
      canViewSourceRequests,
      canViewStats,
    } = this.props;
    const {
      reporter,
      isLoadingReporter,
      loadingReporterError,
      currentUser,
      isLoadingHashtagTags,
      contactInfos = [],
      isSavingBannerImage,
      isRemovingBannerImage,
      hashtagTags,
      publications,
    } = this.state;

    if (loadingReporterError) {
      return (
        <div className="">
          <h4 className="text-danger text-center">Oops, something went wrong.</h4>
        </div>
      );
    }

    const bannerImageUrl = ((reporter || {}).attributes || {}).banner_image_cloudinary;

    if (isLoadingReporter) {
      return (
        <div className="main-profile-container">
          <div className="mt-4 mb-4 text-center">
            <LoadingImage />
          </div>
        </div>
      );
    }

    let avatarComp;
    const avatar = reporter.avatar || {};
    const avatarUrl = (avatar.attributes || {}).cloudinary_url;
    const identifierHumanized = _.trim(reporter.attributes.identifier_humanized);
    const initials = reporter.attributes.initials
      || helper.getInitialLettersFromString(identifierHumanized);
    const sizeClass = 'avatar-lg avatar-framed';

    const addressValue = reporter.attributes.location_string;

    if (isEditable) {
      avatarComp = (
        <AvatarUploader
          avatarJson={reporter.avatar}
          avatarLetters={initials}
          userId={userId} // TODO: This looks like it can be removed
          sizeClass={sizeClass}
          uploaderId={reporter.id}
          uploaderType="Reporter"
        />
      );
    } else {
      avatarComp = (
        <div className="avatar-container">
          <Avatar
            avatarUrl={avatarUrl}
            sizeClass={sizeClass}
            avatarLetters={initials}
            className={sizeClass}
          />
        </div>
      );
    }

    // eslint-disable-next-line no-unused-vars
    let email = contactInfos.find((ci) => {
      const r = ci.attributes.info_type === 'email' && ci.attributes.primary === 'true';
      return r;
    });
    // eslint-disable-next-line
    email = email || contactInfos.find((ci) => ci.attributes.info_type === 'email');

    let primaryButton;

    if (isEditable) {
      primaryButton = this.renderEditIcon(this.onClickEditIcon('reporter', reporter), 'btn btn-primary position-absolute right-0 edit-full-name-btn', 'Edit profile');
    }

    const bannerContainerStyle = {
      height: '130px',
      minHeight: '100px',
      overflow: 'hidden',
    };

    const banner = bannerImageUrl && (
      <img
        src={bannerImageUrl}
        style={{
          minWidth: '100%',
          minHeight: '100%',
          width: '100%',
        }}
        alt=""
      />
    );

    let editBannerButton;
    let removeBannerButton;

    if (isEditable) {
      if (isSavingBannerImage) {
        editBannerButton = (
          <LoadingImage className="me-2" height="24" />
        );
      } else {
        editBannerButton = (
          <div className="d-inline-block border-0 p-0 bg-transparent me-2">
            <label className="m-0 cursor-pointer btn bg-white text-primary" style={{ opacity: 0.7 }}>
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .svg"
                className=""
                onChange={this.onBannerChange}
                style={{ display: 'none' }}
                id="banner_image_input"
              />
              <span>{banner ? 'Change Cover Photo' : 'Upload Cover Photo'} (1200x400px)</span>
            </label>
          </div>
        );
      }

      if (banner) {
        if (isRemovingBannerImage) {
          removeBannerButton = (
            <button
              className="btn bg-white text-primary btn-remote-banner-image me-2"
              type="button"
              style={{ opacity: '0.5' }}
            >
              <LoadingImage className="" height="24" />
            </button>
          );
        } else {
          removeBannerButton = (
            <button
              className="btn bg-white text-primary btn-remote-banner-image me-2"
              type="button"
              onClick={this.toggleRemovingBannerImageModal}
              style={{ opacity: '0.5' }}
            >
              <i className="fa fa-trash-can" />
            </button>
          );
        }
      }
    }

    return (
      <div className="main-profile-container reporter-profile-container">
        <div className="row">
          <div className="col-lg-9">
            <div className="card">
              <div
                className="card-header banner-image-container bg-light position-relative"
                style={bannerContainerStyle}
              >
                {banner}
                <div
                  className="position-absolute"
                  style={{
                    bottom: '10px',
                    right: '0',
                  }}
                >
                  {removeBannerButton}
                  {editBannerButton}
                </div>
              </div>
              <div
                className="p-4 pt-4 bg-white shadow-sm"
              >
                <div className="avatar-info position-relative row">
                  <div className="col-md-9">
                    {avatarComp}
                    <h2 className="position-relative">{identifierHumanized}</h2>
                  </div>
                  <div className="col-md-3 d-flex justify-content-start" style={{ minHeight: '40px' }}>
                    {primaryButton}
                  </div>
                </div>
                <div className="personal-info row pe-3 mt-4 mb-4 border-end border-light">
                  <div className="col-md-8">
                    {this.renderEmploymentTitle()}
                    {this.renderBioText()}
                    <ContactInfos
                      userId={userId}
                      addressValue={addressValue}
                      contactInfos={contactInfos}
                      isEditable={isEditable}
                      toggleAddOrEditAddress={this.toggleAddOrEditAddress}
                      toggleAddOrEditContactInfo={this.toggleAddOrEditContactInfo}
                    />
                  </div>
                  <div className="col-md-4 expert-on">
                    <ExpertOn
                      isEditable={isEditable}
                      isLoadingHashtagTags={isLoadingHashtagTags}
                      maxNumberOfExpertOnHashtags={maxNumberOfExpertOnHashtags}
                      hashtagTags={hashtagTags}
                      toggleAddOrEditHashtagTags={this.toggleAddOrEditHashtagTags}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-4 mb-3">
                {this.renderPublications()}
                {this.renderWritesMostOn()}
              </div>
              <ContentTabs
                canViewArticles={canViewArticles}
                canViewSourceRequests={canViewSourceRequests}
                canViewStats={canViewStats}
                className="col-md-8 mb-3"
                currentEmployments={this.getCurrentEmployments()}
                currentUser={currentUser}
                publications={publications}
                reporter={reporter}
                reporterId={reporterId}
                userId={userId}
              />
            </div>
            {this.renderModal()}
          </div>
          <div className="col-lg-3">
            {this.renderPeopleAlsoViewed()}
            {this.renderTwitterFeed()}
          </div>
        </div>
      </div>
    );
  }
}

FullProfile.propTypes = {
  canViewArticles: PropTypes.bool,
  canViewSourceRequests: PropTypes.bool,
  canViewStats: PropTypes.bool,
  isEditable: PropTypes.bool,
  reporterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FullProfile.defaultProps = {
  canViewArticles: false,
  canViewSourceRequests: false,
  canViewStats: false,
  isEditable: undefined,
  userId: undefined,
};

export default FullProfile;
