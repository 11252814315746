import _ from 'lodash';
import React from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import LoadingImage from '../../loading_image';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import SourceRequestCard from '../card/source_request_card';

class SourceRequestCardsSmaller extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const {
      defaultFilteringOption: selectedFilteringOption,
      filteringOptions,
    } = this.props;

    return {
      selectedFilteringOption, filteringOptions,
    };
  }

  componentDidMount() {
    this.loadSourceRequests();
  }

  loadSourceRequests = () => {
    const { limit = 6, query } = this.props;

    const params = {
      page: 1,
      per_page: limit,
      rel_v_rec: this.state.selectedFilteringOption,
      query,
    };

    this.setState({ isLoadingSourceRequests: true });
    vapi.getSourceRequests(params).then((response) => {
      const responseData = response.data;
      const states = { isLoadingSourceRequests: false };

      states.sourceRequests = responseData.data || [];
      states.sourceRequestsCount = (responseData.meta || {}).total;
      this.setState(states);
    });
  };

  customSelectOnChangeFunc = (event) => {
    this.setState(
      {
        selectedFilteringOption: event.target.value,
      },
      this.loadSourceRequests,
    );

    vahoy.track('SourceRequestsSmaller/SourceRequestCardsSmaller#selectOnChange');
  };

  trackClicks = () => {
    vahoy.track('SourceRequestsSmaller/SourceRequestCardsSmaller#clickSeeMoreLink');
  };

  render() {
    const { listTitle = 'Opportunities', seeMorePath = '/source_requests' } = this.props;
    const {
      sourceRequests, isLoadingSourceRequests, selectedFilteringOption, filteringOptions,
    } = this.state;

    if (sourceRequests && sourceRequests.length > 0) {
      return (
        <>
          <h2 className="mb-3">
            {listTitle}

            <Input
              type="select"
              name="select"
              id="exampleSelect"
              className="form-select form-select-sm ms-3 d-inline-flex"
              onChange={this.customSelectOnChangeFunc}
              value={selectedFilteringOption}
              style={{ width: '180px' }}
            >
              {filteringOptions.map((option) => (
                <option
                  value={option}
                  key={option}
                >{_.startCase(option)}
                </option>
              ))}
            </Input>
          </h2>

          <div className="row gx-2 test-main_source_requests_smaller">
            {sourceRequests.map((sourceRequest) => (
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-6 col-xxl-4 col-xxxxl-3 pe-2 mb-3" key={sourceRequest.id}>
                <SourceRequestCard sourceRequest={sourceRequest.attributes} smallMode={true} />
              </div>
            ))}
          </div>

          <div className="float-start">
            <a href={seeMorePath} className="pe-2" onClick={this.trackClicks}>
              See more in <u>{listTitle.toUpperCase()}</u>
            </a>
          </div>

          <div className="clearfix" />
        </>
      );
    }

    if (isLoadingSourceRequests) {
      return (
        <div className="mt-4 mb-4 text-center"><LoadingImage /></div>
      );
    }

    return (<div />);
  }
}

SourceRequestCardsSmaller.propTypes = {
  defaultFilteringOption: PropTypes.string,
  filteringOptions: PropTypes.arrayOf(String),
  limit: PropTypes.number,
  query: PropTypes.string,
  listTitle: PropTypes.string,
  seeMorePath: PropTypes.string,
};

SourceRequestCardsSmaller.defaultProps = {
  defaultFilteringOption: undefined,
  filteringOptions: undefined,
  limit: undefined,
  query: undefined,
  listTitle: undefined,
  seeMorePath: undefined,
};

export default SourceRequestCardsSmaller;
