import React from 'react';
import { FormGroup, Button, Input } from 'reactstrap';
import PropTypes from 'prop-types';

import ContactInfosReactFields from '../contact_info/contact_infos_react_fields';
import vahoy from '../../../javascript/vahoy';

class ContactContactInfoFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactInfos: props.contactInfos,
      selectedNewInfoType: 'email',
    };
  }

  changeSelectedNewInfoType = (e) => {
    this.setState({ selectedNewInfoType: e.target.value.toLowerCase() });
    vahoy.track('Opportunity/Contact/ContactInfoFields#changeSelectedNewInfoType');
  };

  addContactInfo = () => {
    const { contactInfos } = this.state;
    contactInfos.push({
      __private_id: Date.now(),
      attributes: { info_type: this.state.selectedNewInfoType, primary: false },
    });

    this.setState({ contactInfos });
    vahoy.track('Opportunity/Contact/ContactInfoFields#addContactInfo');
  };

  render() {
    const { contact, onChange, refreshOpportunityContactEmployment } = this.props;
    const { contactInfos } = this.state;

    return (
      <div>
        <FormGroup id="contactFormEmail">
          <ContactInfosReactFields
            contact_infos={contactInfos.filter((info) => info.attributes.info_type == 'email')}
            refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
            contactable={contact}
            contactableType="Contact"
            placeholder="contactemail@gmail.com"
            info_type="email"
            onChange={onChange}
          />
        </FormGroup>
        <FormGroup>
          <ContactInfosReactFields
            contact_infos={contactInfos.filter((info) => info.attributes.info_type == 'phone')}
            refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
            contactable={contact}
            contactableType="Contact"
            placeholder="+1 800 123 4567 (international numbers OK too)"
            info_type="phone"
            onChange={onChange}
          />
        </FormGroup>
        <FormGroup>
          <ContactInfosReactFields
            contact_infos={contactInfos.filter((info) => info.attributes.info_type == 'url')}
            refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
            contactable={contact}
            contactableType="Contact"
            placeholder="http://www.contactswebsite.com/my-special-page"
            info_type="url"
            onChange={onChange}
          />
        </FormGroup>
        <FormGroup>
          <ContactInfosReactFields
            contact_infos={contactInfos.filter((info) => info.attributes.info_type == 'twitter')}
            refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
            contactable={contact}
            contactableType="Contact"
            placeholder="@twitterhandle"
            info_type="twitter"
            onChange={onChange}
          />
        </FormGroup>
        <FormGroup>
          <div className="row">
            <div className="col-4">
              <Input defaultValue="email" onChange={this.changeSelectedNewInfoType} type="select">
                <option name="email" id="info-type-email">Email</option>
                <option name="phone" id="info-type-phone">Phone</option>
                <option name="url" id="info-type-url">Url</option>
                <option name="twitter" id="info-type-twitter">Twitter</option>
              </Input>
            </div>
            <div className="col-4">
              <Button
                type="button"
                onClick={this.addContactInfo}
              ><i className="fa-solid fa-plus me-2" />Add
                new
              </Button>
            </div>
          </div>
        </FormGroup>
      </div>
    );
  }
}

ContactContactInfoFields.propTypes = {
  contact: PropTypes.instanceOf(Object).isRequired,
  contactInfos: PropTypes.arrayOf(Object),
  onChange: PropTypes.func,
  refreshOpportunityContactEmployment: PropTypes.func,
};

ContactContactInfoFields.defaultProps = {
  contactInfos: [],
  onChange: undefined,
  refreshOpportunityContactEmployment: undefined,
};

export default ContactContactInfoFields;
