import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Snippet } from 'react-instantsearch-dom';
import helper from '../../javascript/frontend/helpers/helper';

function SmartSnippet(props) {
  const { attribute, hit, truncateLength } = props;

  if (Object.prototype.hasOwnProperty.call(hit, '_snippetResult')) {
    const snippetAttribute = (_.get(hit._snippetResult, attribute) || {}).value;
    const snippetText = (snippetAttribute || '').replace(/<[^>]*>/g, '');
    const originalText = (_.get(hit, attribute) || '').replace(/<[^>]*>/g, '');
    const showOpeningEllipsis = snippetText.substring(0, 10) !== originalText.substring(0, 10);

    return (
      <>
        {showOpeningEllipsis && <span>...</span>}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Snippet {...props} />
        <span>...</span>
      </>
    );
  }

  return helper.beautifulTruncateStr(hit[attribute], truncateLength);
}

SmartSnippet.propTypes = {
  attribute: PropTypes.string.isRequired,
  hit: PropTypes.instanceOf(Object).isRequired,
  tagName: PropTypes.string,
  nonHighlightedTagName: PropTypes.string,
  separator: PropTypes.node,
  truncateLength: PropTypes.number,
};

SmartSnippet.defaultProps = {
  tagName: undefined,
  nonHighlightedTagName: undefined,
  separator: undefined,
  truncateLength: 150,
};

export default SmartSnippet;
