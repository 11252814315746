import React from 'react';
import Skeleton from 'react-loading-skeleton';

function ProductCardSkeleton() {
  return (
    <div className="card h-100 product-card animated faster fadeIn">
      <div className="card-body">
        <Skeleton className="w-100 my-2" style={{ aspectRatio: '1/1' }} />

        {/* Company name */}
        <Skeleton height={15} width={100} className="mt-2 mb-2 d-block" />

        {/* Product name */}
        <Skeleton height={24} width={200} className="d-block mb-2" />

        {/* Price */}
        <Skeleton height={18} width={60} className="d-block mb-3" />

        {/* Hashtags */}
        <div className="mt-2">
          <Skeleton height={10} width={80} className="me-1" />
          <Skeleton height={10} width={120} className="me-1" />
          <Skeleton height={10} width={100} className="me-1" />
        </div>
      </div>

      <div className="card-footer border-0 bg-white pt-2 pb-3 d-flex">
        <div className="me-2">
          <Skeleton height={28} width={70} />
        </div>
        <div className="me-2">
          <Skeleton height={28} width={70} />
        </div>

        <div className="ms-auto">
          <Skeleton height={28} width={90} />
        </div>
      </div>
    </div>
  );
}

export default ProductCardSkeleton;
