import React from 'react';
import PropTypes from 'prop-types';
import avatarHelpers from '../javascript/frontend/helpers/avatar_helpers';

function ResponsiveAvatar(props) {
  const {
    useInitials, avatarUrl, avatarLetters, className,
  } = props;
  const clazz = className || '';
  const colorClass = avatarHelpers.colorClassFromInitials(avatarLetters);
  return (
    <>
      {avatarUrl && (
        <img
          src={avatarUrl}
          alt={avatarLetters}
          className={`avatar-responsive ${clazz}`}
        />
      )}
      {!avatarUrl && (
        <div className="avatar-responsive-container">
          {useInitials && (
            <div className={`avatar-responsive-empty ${colorClass} ${clazz}`}>
              {avatarLetters}
            </div>
          )}
          {!useInitials && (
            <div className={`avatar-responsive-empty bg-gray-300 ${clazz}`}>
              <i className="fa fa-user fa-3x d-block" />
            </div>
          )}
        </div>
      )}
    </>
  );
}

ResponsiveAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  avatarLetters: PropTypes.string.isRequired,
  className: PropTypes.string,
  useInitials: PropTypes.bool,
};

ResponsiveAvatar.defaultProps = {
  avatarUrl: undefined,
  className: undefined,
  useInitials: false,
};

export default ResponsiveAvatar;
