import React, { useState } from 'react';
import {
  Configure,
  Panel,
  ToggleRefinement,
  connectPagination,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

function StatusFilterPanel({ refine, currentRefinement }) {
  const [isRecent, setIsRecent] = useState(false);
  const [recentCutoffTimestamp, setRecentCutoffTimestamp] = useState();
  const recentDays = 2;

  const getFilters = () => {
    let filters = '';

    if (isRecent) {
      filters = `published_at_int > ${recentCutoffTimestamp}`;
    }

    return filters;
  };

  const handleChange = () => {
    setIsRecent(!isRecent);
    setRecentCutoffTimestamp(Math.floor(Date.now() / 1000) - (60 * 60 * 24 * recentDays));

    if (currentRefinement !== 0) {
      refine(1); // set the page back to 0
    }
  };

  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by status">
          <ToggleRefinement
            attribute="source_request_free_to_pitch"
            label="Free to pitch"
            value={true}
            defaultRefinement={false}
          />
          <label>
            <input
              type="checkbox"
              checked={isRecent}
              onChange={handleChange}
            />
            &nbsp;Most Recent (48 hours)
          </label>
          <Configure numericFilters={getFilters()} />
          <ToggleRefinement
            attribute="easy_win"
            label="Easy win"
            value={true}
            defaultRefinement={false}
          />
          <ToggleRefinement
            attribute="no_deadline"
            label="No deadline"
            value={true}
            defaultRefinement={false}
          />
          <ToggleRefinement
            attribute="deadline_approaching"
            label="Deadline approaching"
            value={true}
            defaultRefinement={false}
          />
          <ToggleRefinement
            attribute="paid"
            label="Fee-Based Opportunities"
            value={true}
          />
        </Panel>
      </div>
    </div>
  );
}

StatusFilterPanel.propTypes = {
  refine: PropTypes.func.isRequired,
  currentRefinement: PropTypes.number,
};

StatusFilterPanel.defaultProps = {
  currentRefinement: 1,
};

export default connectPagination(StatusFilterPanel);
