import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../../../javascript/vahoy';
import vapi from '../../../../javascript/frontend/api/vapi';
import Logo from '../../../logo';
import SidebarSkeletonList from './sidebar_skeleton_list';
import { CompanyContext } from '../../data/company_context_management';

function PrAgencyList({ previewMode }) {
  const {
    companyId,
    company,
    setPrAgenciesExist,
    setOpenEditModal,
  } = useContext(CompanyContext);
  const [isLoading, setIsLoading] = useState(true);
  const [prAgencies, setPrAgencies] = useState([]);
  const [asyncError, setAsyncError] = useState();

  // Retrieve the pr_users list from the API
  useEffect(() => {
    let componentIsMounted = true;
    const fetchPrAgencies = async () => {
      try {
        if (companyId) {
          setIsLoading(true);
          vahoy.track('Companies#getPrAgencies');

          const params = {
            company_id: companyId,
          };

          const response = await vapi.getCompanies(params);
          if (componentIsMounted && response.status === 200) {
            const prAgencyResults = response.data;
            if (prAgencyResults.data) {
              if (previewMode) {
                // Limit to 3 records if we're in preview mode
                setPrAgencies(prAgencyResults.data.slice(0, 3));
              } else {
                setPrAgencies(prAgencyResults.data);
              }
            }
          }
          if (componentIsMounted) setIsLoading(false);
        }
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchPrAgencies();

    // Cleanup function to avoid the "Can't perform state update on unmounted component" error
    return () => {
      componentIsMounted = false;
    };
  }, [companyId, previewMode]);

  // Update company context to indicate PR agency records exist
  useEffect(() => {
    setPrAgenciesExist(prAgencies.length > 0);
  }, [prAgencies, setPrAgenciesExist]);

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  if (!isLoading && prAgencies.length === 0) return null;
  if (!company.show_pr_agencies && !previewMode) return null;

  return (
    <div>
      <div className="card mb-3 animated faster fadeIn">
        <div className="card-body pb-2">
          <div className="fw-bold mb-3 pb-2 text-uppercase small border-bottom d-flex align-items-center">
            Our PR Agencies
            {!previewMode && company.editable && (
              <button
                className="btn btn-primary btn-sm ms-auto"
                style={{
                  maxHeight: '2rem',
                  maxWidth: '2rem',
                }}
                onClick={() => setOpenEditModal('PR_AGENCIES')}
                type="button"
              >
                <i className="fa-solid fa-pencil cursor-pointer" />
              </button>
            )}
          </div>
          {isLoading && (
            <SidebarSkeletonList numberOfLines={2} />
          )}
          {!isLoading && prAgencies.map((agency) => (
            <div className="d-flex ms-1 mb-2 align-items-center animated faster fadeIn" key={agency.id}>
              <Logo
                logoUrl={agency.attributes.logo_url}
                className="rounded"
                orgName={agency.attributes.name}
                style={{ maxWidth: '3.75rem' }}
              />
              <div className="flex-grow-1">
                <a href={agency.attributes.company_path} style={{ fontWeight: 'bold' }}>
                  {agency.attributes.name}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PrAgencyList;

PrAgencyList.propTypes = {
  previewMode: PropTypes.bool,
};

PrAgencyList.defaultProps = {
  previewMode: false,
};
