import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';
import ExpertCard from './expert_card';

function SendTo({
                  initialSourceId,
                  sendToSources,
                  setSendToSources,
                  handleRemoveSourceClick,
                  sendToError,
                }) {
  useEffect(() => {
    const fetchSendToSources = async () => {
      if (initialSourceId) {
        const response = await vapi.getSource(initialSourceId);

        if (response.status === 200) {
          const results = response.data;
          if (results.data) {
            setSendToSources([results.data]);
          }
        } else {
          // TODO form validation
        }
      }
    };

    fetchSendToSources();
  }, [initialSourceId, setSendToSources]);

  const sourceCardMode = sendToSources.length < 4 ? 'card' : 'nameChip';

  return (
    <div className="mb-3">
      <label className="fw-bold">
        Send to <span style={{ color: 'red' }}>*</span>
      </label>
      <br />
      <div style={{ minHeight: '115px' }} className="d-flex flex-row flex-wrap border rounded p-2">
        {sendToSources?.map((source) => (
          <div
            className="me-2 mb-2"
            key={source.id}
          >
            <ExpertCard
              clickMode="remove"
              source={source}
              sourceCardMode={sourceCardMode}
              onClick={() => {
                handleRemoveSourceClick(source);
              }}
            />
          </div>
        ))}
      </div>
      {sendToError && (
        <div className="form-text text-danger">
          {sendToError}
        </div>
      )}
    </div>
  );
}

SendTo.propTypes = {
  initialSourceId: PropTypes.number,
  sendToError: PropTypes.string,
  sendToSources: PropTypes.array,
  setSendToSources: PropTypes.func,
  handleRemoveSourceClick: PropTypes.func,
};

SendTo.defaultProps = {
  initialSourceId: undefined,
  sendToError: undefined,
  sendToSources: undefined,
  setSendToSources: undefined,
  handleRemoveSourceClick: undefined,
};

export default SendTo;
