import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import SourceHit from './source_hit';
import PublicPitchHit from './public_pitch_hit';
import PressReleaseHit from './press_release_hit';
import ProductHit from './product_hit';
import SourceRequestSkeletonPage from '../../source_requests/index/source_request_skeleton_page';
import SearchLoadingCards from '../../source_requests/search/search_loading_cards';

function CombinedHits({ hits, hasMore, refineNext }) {
  const query = hits.find((hit) => hit.type === 'query')?.query || '';
  const observer = useRef();

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefineNext = useCallback(debounce(refineNext, 100), [refineNext, hits]);

  useEffect(() => {
    const loadMoreTrigger = document.querySelector('#load-more-trigger');
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        debouncedRefineNext();
      }
    });

    if (loadMoreTrigger) observer.current.observe(loadMoreTrigger);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [hasMore, debouncedRefineNext]);

  return (
    <div>
      <div className="row">
    { hits && hits.length > 0 && (
      <>
        <SearchLoadingCards />
        { hits.filter((hit) => hit.type !== 'query').map((hit, index) => (
          <div key={index} className="storybuilder-card col-12 col-sm-6 col-md-3 mb-4">
            {hit.type === 'expert' && <SourceHit hit={hit} currentQuery={query} />}
            {hit.type === 'pitch' && <PublicPitchHit hit={hit} currentQuery={query} />}
            {hit.type === 'product' && <ProductHit hit={hit} currentQuery={query} />}
            {hit.type === 'press_release' && <PressReleaseHit hit={hit} currentQuery={query} />}
          </div>
        ))}
      </>
    )}
      </div>
      {hasMore && (
        <>
          <div id="load-more-trigger" className={`row row-cols-1 row-cols-sm-1 ${'row-cols-md-2 row-cols-lg-4'} px-1`}>
            <SourceRequestSkeletonPage numberOfCards={12} smallMode={false} />
          </div>
        </>
      )}
      {!hasMore && (
        <div className="text-center mt-4">
          <p>No more results</p>
        </div>
      )}
    </div>
  );
}

CombinedHits.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired,
};

export default CombinedHits;
