import React from 'react';
import PropTypes from 'prop-types';

function ActionButtons({ onClick, onDiscard }) {
  return (
    <div className="action-buttons">
      <button className="btn btn-primary btn-lg no-border ps-5 pe-5 me-2 my-2" type="button" onClick={() => onClick()}>
        Submit
      </button>
      <button className="btn btn-outline-secondary btn-lg ps-5 pe-5 me-2 my-2" type="button" onClick={() => onDiscard()}>
        Discard
      </button>
    </div>
  );
}

ActionButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
};

export default ActionButtons;
