import { decodeToken } from 'react-jwt';

const useClientFeaturesToken = (token, feature) => {
  try {
    const payload = decodeToken(token);
    const clientSession = window.CURRENT_USER_CLIENT_SESSION;

    return payload.auth_list.includes(feature) && clientSession === payload.user_key;
  } catch {
    return false;
  }
};

export default useClientFeaturesToken;
