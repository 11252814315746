import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../../javascript/vahoy';
import vapi from '../../../javascript/frontend/api/vapi';
import ProductCard from '../../products/card/product_card';
import CompanyProductsSkeletonPage from './company_products_skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import { CompanyContext } from '../data/company_context_management';
import CompanyFeaturedProduct from './company_featured_product';

function CompanyProducts({ prFirmView }) {
  const perPage = 12;
  const {
    companyId,
    company,
    setOpenEditModal,
    topLevelIsLoading,
  } = useContext(CompanyContext);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [asyncError, setAsyncError] = useState();

  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });

  // Effect to track infinite scrolling intersection observer event, which increments page
  useEffect(() => {
    if (lastTriggeredAt > 0) setPage((prevPage) => prevPage + 1);
  }, [lastTriggeredAt]);

  // Retrieve a company's products from the API and track the page number
  useEffect(() => {
    let componentIsMounted = true;

    const fetchProducts = async () => {
      try {
        if (companyId) {
          if (page === 1) setIsLoading(true);
          vahoy.track('CompanyProducts#fetchProducts');

          let params = {
            page,
            per_page: perPage,
          };

          if (prFirmView) {
            params = {
              clients_view: 'y',
              ...params,
            };
          } else {
            params = {
              not_hidden: true,
              not_featured: true,
              ...params,
            };
          }

          const response = await vapi.getCompanyProducts(params, companyId);

          if (componentIsMounted && response.status === 200) {
            const results = response.data;
            if (results.data && results.data.length > 0) {
              setProducts((prevRecords) => [...prevRecords, ...results.data]);
            }
            setHasMore(results.data.length >= perPage);
          }
          if (componentIsMounted) setIsLoading(false);
        }
      } catch (error) {
        setAsyncError(error);
      }
    };

    fetchProducts();

    // Cleanup function to avoid the "Can't perform a React state update on an unmounted component"/memory leak problem
    return () => {
      componentIsMounted = false;
    };
  }, [companyId, page, perPage, prFirmView, topLevelIsLoading]);

  useEffect(() => {
    let componentIsMounted = true;

    const fetchFeaturedProducts = async () => {
      try {
        if (companyId) {
          setIsLoading(true);
          vahoy.track('CompanyProducts#fetchFeaturedProducts');

          // there is no pagination on the featured products
          const params = {
            featured: true,
            page: 1,
            per_page: 9999,
          };

          const response = await vapi.getCompanyProducts(params, companyId);

          if (componentIsMounted && response.status === 200) {
            const results = response.data;
            if (results.data && results.data.length > 0) {
              setFeaturedProducts(results.data);
            }
          }
          if (componentIsMounted) setIsLoading(false);
        }
      } catch (error) {
        setAsyncError(error);
      }
    };

    fetchFeaturedProducts();

    // Cleanup function to avoid the "Can't perform a React state update on an unmounted component"/memory leak problem
    return () => {
      componentIsMounted = false;
    };
  }, [companyId, topLevelIsLoading]);

  const renderFeaturedProducts = () => {
    if (!featuredProducts.length) return null;

    return featuredProducts.map((product) => (
      <CompanyFeaturedProduct product={product} key={product.id} />
    ));
  };

  const renderModalButton = (tab) => {
    if (!company) return null;

    return (
      <button
        type="button"
        className="btn btn-primary btn-sm"
        style={{
          maxHeight: '2rem',
          maxWidth: '2rem',
          position: 'absolute',
          right: '0',
        }}
        onClick={() => setOpenEditModal(tab)}
      >
        <i className="fa-solid fa-pencil cursor-pointer" />
        &nbsp;
      </button>
    );
  };

  const renderProductsTitle = () => {
    if (featuredProducts.length) {
      return <h3 className="fw-bold mx-3 mb-4 mt-4">All Products</h3>;
    }

    return (
      <div className="d-flex flex-row align-items-start w-100">
        <h3 className="fw-bold mx-3 mb-4 mt-4">All Products</h3>
      </div>
    );
  };

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  // From `company_experts.jsx`, I assume this applies to `Products` as well:
  // KT July 16 2021 : For the “Experts Form” I am going to hide this when the prFirmView is true. There is no place to
  // persist the hidden and featured booleans in this case. It would take a new concept and significant additional
  // effort to make this work and, we do not anticipate PR agencies as the audience for selling Expert Hub.
  const renderEditButton = !prFirmView && company?.editable;

  return (
    <>
      {renderEditButton && renderModalButton('PRODUCTS')}

      {featuredProducts.length ? (
        <>
          <div className="d-flex flex-row align-items-start w-100">
            <h3 className="fw-bold mx-3 mb-4 mt-4">
              Featured Products
            </h3>
          </div>

          <div className="row mb-4 h-100 w-100">{renderFeaturedProducts()}</div>
        </>
      ) : null}

      <div className="px-0">
        {!isLoading && products.length > 0 && renderProductsTitle()}

        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3">
          {isLoading && <CompanyProductsSkeletonPage numberOfCards={6} />}

          {!isLoading
            && products.map((product) => (
              <div className="col mb-3" key={product.id}>
                <ProductCard product={product.attributes} />
              </div>
            ))}
        </div>
        {hasMore && (
          <div
            ref={scrollTriggerRef}
            className="row row-cols-1 row-cols-md-2 row-cols-xl-3"
          >
            <CompanyProductsSkeletonPage numberOfCards={3} singleRow />
          </div>
        )}
      </div>
    </>
  );
}

CompanyProducts.propTypes = {
  prFirmView: PropTypes.bool,
};

CompanyProducts.defaultProps = {
  prFirmView: false,
};

export default CompanyProducts;
