import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

function AdminModal({
 children, hashtags, isOpen, onCancel, pitchId, pitchRequestId, pitchText,
}) {
  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader toggle={onCancel}>Public Pitch Details</ModalHeader>
      <ModalBody>
        <div>{children}</div>
        <div className="alert alert-info mt-3">
          <small>
            {[...hashtags]
              .sort((a, b) => a.hashtag.toLowerCase().localeCompare(b.hashtag.toLowerCase()))
              .map((hashtag) => (
                <React.Fragment key={hashtag.url}>
                  <a href={hashtag.url} className="text-secondary" target="_blank" rel="noreferrer">
                    {hashtag.hashtag}
                  </a>{' '}
                </React.Fragment>
              ))}
          </small>
        </div>

        <h4 className="fw-bold">Original Pitch Text</h4>
        <div className="alert alert-info mt-3">
          <small>
            <pre className="fst-italic public-pitch-admin-text">{pitchText}</pre>
          </small>
        </div>

        <h4 className="fw-bold">Details</h4>
        {pitchId !== null && (
          <p>
            <b>Original pitch id</b>: {pitchId}
          </p>
        )}
        {pitchRequestId !== null && (
          <p className="mb-0">
            <a target="_blank" href={`/source_requests/${pitchRequestId}`} rel="noreferrer">
              View within request (click to open Reporter's view)
            </a>
          </p>
        )}
      </ModalBody>
    </Modal>
  );
}

AdminModal.propTypes = {
  children: PropTypes.node,
  hashtags: PropTypes.arrayOf(
    PropTypes.shape({
      hashtag: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  pitchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pitchRequestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pitchText: PropTypes.string,
};

AdminModal.defaultProps = {
  children: null,
  hashtags: [],
  pitchId: null,
  pitchRequestId: null,
  pitchText: null,
};

export default AdminModal;
