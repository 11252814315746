import React, { useState, useEffect, useContext } from 'react';
import { Spinner, ModalBody, ModalFooter } from 'reactstrap';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import { CompanyContext } from '../data/company_context_management';
import { sortByName } from '../../utilities';
import ProductForm from './product_form';

function ProductsForm() {
  const perPage = 9999;
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [asyncError, setAsyncError] = useState();

  const {
    companyId,
    setOpenEditModal,
    setGeneralError,
    isUpdating,
    setDoTopLevelFetch,
  } = useContext(CompanyContext);

  // Set Modal fixed height to stay within viewport in order to show modal footer without having to scroll
  useEffect(() => {
    const setModalHeight = () => {
      document.querySelector('.modal-content').style.maxHeight = '90vh';
    };
    setModalHeight();
  }, []);

  useEffect(() => {
    // Alphabetize list of products
    const fetchProducts = async () => {
      try {
        if (companyId) {
          setIsLoading(true);
          vahoy.track('ProductsForm#fetchProducts');

          const params = {
            page: 1,
            per_page: perPage,
            alphabetized: true,
          };

          const response = await vapi.getCompanyProducts(params, companyId);
          if (response.status === 200) {
            const productResults = response.data;

            if (productResults.data && productResults.data.length > 0) {
              setProducts((prevRecords) => sortByName([...prevRecords, ...productResults.data]));
            }
          }

          setIsLoading(false);
        }
      } catch (error) {
        setAsyncError(error);
      }
    };

    fetchProducts();
  }, [companyId]);

  const renderProducts = () => {
    if (!products.length) return null;
    const productsResult = [...products];

    if (searchTerm == '') {
      return productsResult.map((product) => (
        <ProductForm
          key={product.id}
          product={product}
          products={products}
          setProducts={setProducts}
          setDoTopLevelFetch={setDoTopLevelFetch}
        />
      ));
    }

    return productsResult
      .filter((src) => {
        const productName = src.attributes.name.toLowerCase();
        const searchedName = searchTerm.toLowerCase();
        return productName.includes(searchedName);
      })
      .map((product) => (
        <ProductForm
          key={product.id}
          product={product}
          products={products}
          setProducts={setProducts}
          setDoTopLevelFetch={setDoTopLevelFetch}
        />
      ));
  };

  const closeModal = () => {
    setOpenEditModal(null);
    setGeneralError(null);
  };

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  return (
    <>
      <ModalBody style={{ overflowY: 'scroll' }}>
        {!isLoading && (
          <div className="row justify-content-between px-3 mb-3">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by name"
              value={searchTerm}
            />
          </div>
        )}

        {renderProducts()}

        {isLoading && (
          <div className="row d-flex justify-content-center">
            <Spinner
              color="dark"
              className="me-2 mb-3"
              style={{
                width: '2.5rem',
                height: '2.5rem',
              }}
            />
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeModal}
          disabled={isUpdating}
        >
          Close
        </button>
      </ModalFooter>
    </>
  );
}

export default ProductsForm;
