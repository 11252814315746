import React from 'react';
import {
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';
import { orderBy } from 'lodash';

function PitchFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by # of pitches">
          <RefinementList
            attribute="pitch_count_category"
            limit={5}
            showMore={false}
            searchable={false}
            transformItems={(items) => orderBy(items, 'label', 'asc').map((i) => Object.assign(i, { label: i.label.slice(2) }))}
          />
        </Panel>
      </div>
    </div>
  );
}

export default PitchFilterPanel;
