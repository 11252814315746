import React from 'react';
import PropTypes from 'prop-types';
import { SearchBox } from 'react-instantsearch-dom';

function SearchFilterPanel({ query }) {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <SearchBox
          autoFocus
          translations={{ placeholder: 'Search' }}
          searchAsYouType={true}
          defaultRefinement={query}
        />
      </div>
    </div>
  );
}

SearchFilterPanel.propTypes = {
  query: PropTypes.string,
};

SearchFilterPanel.defaultProps = {
  query: '',
};

export default SearchFilterPanel;
