// Begin /users/sign_up page

jQuery(document).on('click', '.js-btn-signup-twitter', (evt) => {
  window.gaTracking && window.gaTracking.trackSignUp('SignUp', 'clickSignUpWithTwitter', evt.target.innerText, evt.target.value);
});

jQuery(document).on('click', '.js-password-signup-form .js-btn-continue', (evt) => {
  window.gaTracking && window.gaTracking.trackSignUp('SignUp', 'clickContinue', evt.target.innerText, evt.target.value);
});

jQuery(document).on('click', '.js-or-create-an-account', (event) => {
  window.gaTracking && window.gaTracking.trackSignUp('SignUp', 'clickSignUpWithEmail', event.target.innerText, event.target.value);
});

// End /users/sign_up page

// Begin /onboarding/new page

jQuery(document).on('click', '.post-signup-capture-interests .js-btn-finish', (evt) => {
  window.gaTracking && window.gaTracking.trackSignUp('Onboarding', 'clickContinue', evt.target.innerText, evt.target.value);
});

// End /onboarding/new page
