import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import JobPostingsSearch from './search/job_postings_search';

function TopLevelSearch({
                          query, audience, mostRecent, approval, location, publication, company,
                          algoliaAppId, algoliaSearchKey, indexName,
                          userInternal, userType,
                        }) {
  return (
    <ErrorBoundary>
      <JobPostingsSearch
        query={query}
        audience={audience}
        mostRecent={mostRecent}
        approval={approval}
        location={location}
        publication={publication}
        company={company}
        algoliaAppId={algoliaAppId}
        algoliaSearchKey={algoliaSearchKey}
        indexName={indexName}
        userInternal={userInternal}
        userType={userType}
      />
    </ErrorBoundary>
  );
}

TopLevelSearch.propTypes = {
  query: PropTypes.string,
  audience: PropTypes.string,
  mostRecent: PropTypes.bool,
  approval: PropTypes.string,
  location: PropTypes.string,
  publication: PropTypes.string,
  company: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  userInternal: PropTypes.bool,
  userType: PropTypes.string,
};

TopLevelSearch.defaultProps = {
  query: '',
  audience: '',
  mostRecent: false,
  approval: '',
  location: '',
  publication: '',
  company: '',
  userInternal: undefined,
  userType: undefined,
};

export default TopLevelSearch;
