import helper from '../../helpers/helper';

document.addEventListener('turbolinks:load', () => {
  const $select2 = $('select#email_types').select2();
  $select2.on('select2:select', (event) => {
    const value = $(event.target).val();
    const params = helper.urlParamsHash() || {};

    params.email_type = value;
    window.location.href = `${window.location.pathname}?${$.param(params)}`;
  });
});
