import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes';
import 'chartjs-plugin-labels';

Chart.defaults.global.plugins.labels = false;

class ChartJS extends React.Component {
  constructor(props) {
    super(props);

    const { config } = props;
    config.options = config.options || {};
    config.options.plugins = config.options.plugins || {};
    config.options.plugins.colorschemes = config.options.plugins.colorschemes || {};
    config.options.plugins.colorschemes.scheme = config.options.plugins.colorschemes.scheme || 'tableau.Tableau10';

    this.state = {
      config,
    };
  }

  componentDidMount() {
    this.setupChartJS();
  }

  setupChartJS() {
    const ctx = this.chartJSInstance.getContext('2d');

    // eslint-disable-next-line
    const chart = new Chart(ctx, this.state.config);
  }

  render() {
    return (
      <canvas ref={(instance) => {
        this.chartJSInstance = instance;
      }}
      />
    );
  }
}

ChartJS.propTypes = {
  config: PropTypes.instanceOf(Object),
};

ChartJS.defaultProps = {
  config: undefined,
};

export default ChartJS;
