import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';

class AccountDisabledModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: props.showPopup,
    };
  }

  openIntercom = () => {
    const disabledMessage = 'Account Temporarily Disabled';
    if (typeof window.Intercom !== 'undefined') {
      window.Intercom('showNewMessage', disabledMessage);
    } else {
      alert('In production, opens Intercom.');
    }
  };

  close = () => {
    this.setState({
      showPopup: false,
    });

    this.openIntercom();
  };

  render() {
    const { showPopup } = this.state;

    return (
      <Modal className="modal-lg" isOpen={showPopup} toggle={this.close}>
        <ModalHeader className="bg-warning" toggle={this.close}>
          <div className="h3 text-white mb-0">Account temporarily disabled</div>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <p>
                There are a few reasons why your account may be disabled.
                Please reach out to our support team for assistance.
              </p>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={this.close}>Chat with Support</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

AccountDisabledModal.propTypes = {
  showPopup: PropTypes.bool,
};

AccountDisabledModal.defaultProps = {
  showPopup: undefined,
};

export default AccountDisabledModal;
