import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {

  createAvatar(avatar) {
    vlog.info('api#createAvatar');

    api.configure();

    const url = '/api/internal/jsonapi/avatars';

    const data = {
      attributes: avatar,
      type: 'avatars',
    };

    return axios.post(url, { data }, api.axiosFix);
  },

  updateAvatar(avatar) {
    vlog.info('api#updateAvatar');

    api.configure();

    const url = avatar.links.self;

    const data = { ...avatar };

    // do not try to re-post the "METHOD_ATTRS"
    delete data.attributes.cloudinary_url;

    return axios.put(url, { data }, api.axiosFix);
  },

  deleteAvatar(avatar) {
    vlog.info('api#deleteAvatar');
    api.configure();

    const url = avatar.links.self;

    return axios.delete(url, api.axiosFix);
  },

});
