import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select2Ajax from '../../../../select2_ajax';
import vahoy from '../../../../../javascript/vahoy';

function Form({ onSubmit, onCancel }) {
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [error, setError] = useState({ name: '', productUrl: '', company: '' });
  const [name, setName] = useState('');
  const [productUrl, setProductUrl] = useState('');

  const validateForm = () => {
    const newError = { name: '', productUrl: '', company: '' };
    let isValid = true;

    if (!name) {
      newError.name = 'Product Name is required.';
      isValid = false;
    } else if (name.length < 2) {
      newError.name = 'Product Name must be at least 2 characters long.';
      isValid = false;
    }

    if (!productUrl) {
      newError.productUrl = 'Product URL is required.';
      isValid = false;
    }

    if (!(companyId || companyName)) {
      newError.company = 'Company is is required.';
      isValid = false;
    }

    setError(newError);
    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission

    if (validateForm()) {
      onSubmit('Product', {
        companyId, companyName, name, productUrl,
      });
    }
  };

  // TODO: duplicated in both source and product form. Could extract.
  const prepareCompaniesApiResponseForSelect2 = (response) => {
    const results = (response.data || []).map((result) => {
      const r = { ...result };
      r.text = result.attributes.name;
      return r;
    });

    return {
      results,
      pagination: {
        more: !!(response.links || {}).next,
      },
    };
  };

  // TODO: duplicated in both source and product form. Could extract.
  const handleCompanyChange = (e) => {
    const selectedCompanyId = jQuery(e.target).val();
    setCompanyId(selectedCompanyId);

    const enteredOrSelectedcompanyName = jQuery(e.target).find('option:selected').text();
    setCompanyName(enteredOrSelectedcompanyName);

    vahoy.track('product/Form#handleCompanyChange');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="required row mb-3">
        <label className="col-12 col-md-3 col-form-label" htmlFor="entity_name">
          Product Name
        </label>
        <div className="col-12 col-md-9">
          <input
            className={`form-control ${error.name ? 'is-invalid' : ''}`}
            id="entity_name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Product name"
            required={true}
            type="text"
            value={name}
          />
          {error.name && <div className="text-danger small">{error.name}</div>}
        </div>
      </div>

      <div className="required row mb-3">
        <label className="col-12 col-md-3 col-form-label" htmlFor="entity_name">
          Product URL
        </label>
        <div className="col-12 col-md-9">
          <input
            className={`form-control ${error.productUrl ? 'is-invalid' : ''}`}
            id="product_url"
            onChange={(e) => setProductUrl(e.target.value)}
            placeholder="Product URL"
            required={true}
            type="url"
            value={productUrl}
          />
          {error.productUrl && <div className="text-danger small">{error.productUrl}</div>}
        </div>
      </div>

      <div className="required mb-3 row">
        <label className="col-12 col-md-3 col-form-label" htmlFor="entity_company">
          Company
        </label>
        <div className="col-12 col-md-9">
          <Select2Ajax
            ajaxProcessResults={prepareCompaniesApiResponseForSelect2}
            ajaxUrl="/api/internal/jsonapi/companies?policy_used=associable"
            className="form-select"
            existingOnly={false}
            onChange={handleCompanyChange}
            placeholderText="Company name (type to search...)" // TODO: port over these UI details
          />
          {error.company && <div className="text-danger small">{error.company}</div>}
        </div>
      </div>

      <div className="mt-3">
        <button type="submit" className="btn btn-primary me-2">Add Product</button>
        <button type="button" className="btn btn-outline-danger" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </form>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

Form.defaultProps = {
  onSubmit: () => {
  },
  onCancel: () => {
  },
};

export default Form;
