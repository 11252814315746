import React from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'react-twitter-widgets';

function TwitterFeed({ screenName }) {
  let normalizedScreenName = screenName;

  if (screenName && screenName.includes('@')) {
    normalizedScreenName = screenName.replace('@', '');
  }

  return (
    <Timeline
      dataSource={{
        sourceType: 'profile',
        screenName: normalizedScreenName,
      }}
      options={{
        height: '600',
      }}
    />
  );
}

TwitterFeed.propTypes = {
  screenName: PropTypes.string.isRequired,
};

export default TwitterFeed;
