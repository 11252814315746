import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {

  getHashtags(url) {
    vlog.info('api#getHashtags');

    api.configure();

    return axios.get(url, api.axiosFix);
  },

  createHashtag(hashtag) {
    vlog.info('api#createHashtag');

    api.configure();

    const data = {
      attributes: hashtag.attributes,
      type: 'hashtags',
    };

    return axios.post('/api/internal/jsonapi/hashtags', { data }, api.axiosFix);
  },

  createHashtagTag(hashtag, taggedId, taggedType, hashtagType) {
    api.configure();
    const urlHashtagType = hashtagType.replace(/_/g, '-');
    const url = `/api/internal/jsonapi/${urlHashtagType}`;

    const data = {
      type: hashtagType,
      attributes: {
        manually_added: true,
        hashtag_id: hashtag.id,
      },
      relationships: {
        hashtag_tagged: {
          data: {
            type: taggedType,
            id: taggedId,
          },
        },
      },
    };

    vlog.info(`api#createHashtagTag: ${url}`);
    return axios.post(url, { data }, api.axiosFix);
  },

  createMultipleHashtagTag(hashtag, taggedId, taggedType, hashtagType) {
    api.configure();

    const urlHashtagType = hashtagType.replace(/_/g, '-');
    const url = `/api/internal/jsonapi/${urlHashtagType}`;

    return axios.get(`/api/internal/jsonapi/hashtags?filter[name]=${hashtag}`, null, api.axiosFix)
      .then((response) => {
        const row = response.data.data[0];

        if (!row) {
          return {
            response: {
              data: {
                errors: [
                  {
                    title: `${hashtag}`,
                    detail: 'does not exist',
                    status: '422',
                  },
                ],
              },
            },
          };
        }

        const data = {
          type: hashtagType,
          attributes: {
            manually_added: true,
            hashtag_id: row.id,
          },
          relationships: {
            hashtag_tagged: {
              data: {
                type: taggedType,
                id: taggedId,
              },
            },
          },
        };

        vlog.info(`api#createMultipleHashtagTag: ${url}`);
        return axios.post(url, { data }, api.axiosFix);
      })
    .catch(() => ({
      response: {
        data: {
          errors: [
            {
              title: `${hashtag}`,
              detail: 'has already been taken',
              status: '422',
            },
          ],
        },
      },
    }));
  },

  retrieveHashtagTagFromHashtagId(taggedId, taggedType, hashtagId, hashtagType) {
    api.configure();
    const urlHashtagType = hashtagType.replace(/_/g, '-');
    const urlTaggedType = taggedType.replace(/_/g, '-');
    const url = `/api/internal/jsonapi/${urlTaggedType}/${taggedId}/${urlHashtagType}?filter[hashtag_id]=${hashtagId}`;

    vlog.info(`api#retrieveHashtagTag: ${url}`);
    return axios.get(url, api.axiosFix);
  },

  deleteHashtagTag(hashtagTagId, hashtagType) {
    api.configure();
    const urlHashtagType = hashtagType.replace(/_/g, '-');
    const url = `/api/internal/jsonapi/${urlHashtagType}/${hashtagTagId}`;

    vlog.info(`api#deleteHashtagTag: ${url}`);
    return axios.delete(url, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },

  removeHashtagTag(hashtagTagId, hashtagType) {
    api.configure();
    const urlHashtagType = hashtagType.replace(/_/g, '-');
    const url = `/api/internal/jsonapi/${urlHashtagType}/${hashtagTagId}`;
    const data = {
      type: hashtagType,
      id: hashtagTagId,
      attributes: { manually_removed: true },
    };

    vlog.info(`api#removeHashtag: ${url}`);
    return axios.patch(url, { data }, api.axiosFix).catch((errorResponse) => errorResponse);
  },

  restoreHashtagTag(hashtagTagId, hashtagType) {
    api.configure();
    const urlHashtagType = hashtagType.replace(/_/g, '-');
    const url = `/api/internal/jsonapi/${urlHashtagType}/${hashtagTagId}`;
    const data = {
      type: hashtagType,
      id: hashtagTagId,
      attributes: {
        manually_removed: false,
        manually_added: true,
      },
    };

    vlog.info(`api#restoreHashtag: ${url}`);
    return axios.patch(url, { data }, api.axiosFix).catch((errorResponse) => errorResponse);
  },
});
