import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../javascript/vahoy';
import AiDetectorModal from './ai_detector_modal';
import vlog from '../../javascript/vlog';

function AiDetectorButton({ text, ahoyObjectGid }) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [passedHealthCheck, setPassedHealthCheck] = useState(false);
  const [aiResult, setAiResult] = useState({
    predictedClass: '',
    classProbability: { ai: 0, human: 0, mixed: 0 },
  });

  useEffect(() => {
    const v2HealthCheck = async () => {
      const passed = await window.v2HealthCheck();
      if (passed) {
        setPassedHealthCheck(true);
      } else {
        vlog.debug('Component not ready: V2 health check did not pass.');
      }
    };

    v2HealthCheck();
  }, []);

  const handleClick = async () => {
    setShowModal(true);
    setLoading(true);
    vahoy.track('AiDetectorButton#showModal', { text, object_gid: ahoyObjectGid });

    try {
      const token = await window.supabaseToken();

      const response = await fetch(`${window.V2_URL}/match_feed/api/ai_text_detector`, {
        method: 'POST',
        headers: {
          apikey: window.SUPABASE_ANON_KEY,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error('Failed to analyze text');
      }

      const data = await response.json();
      const document = data.documents[0];
      const predictedClass = document.predicted_class;
      const classProbability = {
                                ai: parseFloat(document.class_probabilities.ai),
                                human: parseFloat(document.class_probabilities.human),
                                mixed: parseFloat(document.class_probabilities.mixed),
                              };

      setAiResult({
        predictedClass,
        classProbability,
      });
    } catch (error) {
      setAiResult({
        predictedClass: '',
        classProbability: { ai: 0, human: 0, mixed: 0 },
      });
    } finally {
      setLoading(false); // Set loading to false after the API call finishes
    }
  };

  const handleClose = () => {
    if (!text) return;
    setShowModal(false);
    setAiResult({
      predictedClass: '',
      classProbability: { ai: 0, human: 0, mixed: 0 },
    });
    setLoading(true); // Reset loading for the next API call
  };

  if (!passedHealthCheck) return null; // comment to test locally

  return (
    <>
      <button
        className="btn ai-detector-button"
        onClick={handleClick}
        type="button"
        disabled={!text}
      >
        <i className="fa-solid fa-sparkles" /> Check for AI
      </button>
      <AiDetectorModal
        text={text}
        showModal={showModal}
        onClose={handleClose}
        aiResult={aiResult}
        loading={loading}
        ahoyObjectGid={ahoyObjectGid}
      />
    </>
  );
}

AiDetectorButton.propTypes = {
  text: PropTypes.string,
  ahoyObjectGid: PropTypes.string,
};

AiDetectorButton.defaultProps = {
  text: undefined,
  ahoyObjectGid: undefined,
};

export default AiDetectorButton;
