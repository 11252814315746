import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import RepsSearch from './search/reps_search';
import InviteButton from './invite_button';

function TopLevelSearch({
                          promoEntityId,
                          promoEntityType,
                          clientFirmName,
                          algoliaAppId,
                          algoliaSearchKey,
                          indexName,
                          representingUserIds,
                          inviteUrl,
                        }) {
  return (
    <ErrorBoundary>
      <div className="d-flex flex-column">
        <span className="align-self-end me-4">
          <InviteButton inviteUrl={inviteUrl} />
        </span>
        <br className="clearfix" />
        <RepsSearch
          promoEntityId={promoEntityId}
          promoEntityType={promoEntityType}
          clientFirmName={clientFirmName}
          algoliaAppId={algoliaAppId}
          algoliaSearchKey={algoliaSearchKey}
          indexName={indexName}
          representingUserIds={representingUserIds}
          inviteUrl={inviteUrl}
        />
      </div>
    </ErrorBoundary>
  );
}

TopLevelSearch.propTypes = {
  clientFirmName: PropTypes.string,
  promoEntityId: PropTypes.number.isRequired,
  promoEntityType: PropTypes.string.isRequired,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  representingUserIds: PropTypes.array,
  inviteUrl: PropTypes.string,
};

TopLevelSearch.defaultProps = {
  clientFirmName: null,
  representingUserIds: [],
  inviteUrl: '',
};

export default TopLevelSearch;
