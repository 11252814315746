import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

function AutocompletePlaceInput({ defaultValue, onChange, clearGeoSearch }) {
  const [placeValue, setPlaceValue] = useState();
  const [latLng, setLatLng] = useState({});
  const [aroundRadius, setAroundRadius] = useState('all');
  const [locationParams, setLocationParams] = useState({});

  const placeInputRef = useRef();
  const submitIconPath = 'M7 0C3.13 0 0 3.13 0 7c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5C5.62 9.5 4.5 8.38 4.5 7S5.62 4.5 7 4.5 9.5 5.62 9.5 7 8.38 9.5 7 9.5z';
  const resetIconPath = 'M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z';

  // Set up the Google autocomplete event listener
  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef.current,
      { types: ['(cities)'] },
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const latLngResponse = {};

      if (lat && lng) {
        setPlaceValue(placeInputRef.current.value);
        latLngResponse.lat = lat;
        latLngResponse.lng = lng;
      }

      setLatLng(latLngResponse);
    });
  }, []);

  // Set all of the location params when the location changes
  const processPlaceChange = useCallback(() => {
    const inputValue = _.trim(placeInputRef.current.value || '');
    const originalValue = _.trim(placeValue || '');

    if (inputValue !== originalValue || (!inputValue && !originalValue)) {
      setLatLng({});
      onChange();
    }
  }, [onChange, placeValue]);

  // Clear the location params and inputs
  const processGeoSearchClear = useCallback(() => {
    placeInputRef.current.value = '';
    setPlaceValue(null);
    setLatLng({});
    setAroundRadius('all');
    processPlaceChange();
  }, [processPlaceChange]);

  // Trigger the location clear when the clearGeoSearch prop changes to true
  useEffect(() => {
    if (clearGeoSearch) processGeoSearchClear();
  }, [clearGeoSearch, processGeoSearchClear]);

  // Update location params on blur
  const handleBlur = () => {
    processPlaceChange();
  };

  // Clear the location when the X is clicked within the place box
  const handleClearClick = () => {
    processGeoSearchClear();
  };

  // Save the around radius when an distance is changed in select
  const handleAroundRadiusChange = (e) => {
    setAroundRadius(e.target.value);
  };

  // Update the location params in state if either the lat/lng or radius change
  useEffect(() => {
    const params = {};
    params.aroundLatLng = latLng;
    params.aroundRadius = aroundRadius;
    setLocationParams(params);
  }, [latLng, aroundRadius]);

  // Call the onChange function when the any of the location params changes
  useEffect(() => {
    onChange(locationParams);
  }, [locationParams, onChange]);

  return (
    <div className="ais-SearchBox">
      <form
        noValidate=""
        className="ais-SearchBox-form"
        action=""
        role="search"
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          type="search"
          placeholder="Search near…"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          className="ais-SearchBox-input autocomplete-place-input"
          onBlur={handleBlur}
          defaultValue={defaultValue}
          ref={placeInputRef}
        />
        <button
          type="button"
          title="Submit your search query."
          className="ais-SearchBox-submit"
        >
          <svg
            className="ais-SearchBox-submitIcon"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 14 20"
          >
            <path d={submitIconPath} />
          </svg>
        </button>
        {placeValue && (
          <button
            type="button"
            title="Clear the search query."
            className="ais-SearchBox-reset"
            onClick={handleClearClick}
            hidden=""
          >
            <svg
              className="ais-SearchBox-resetIcon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              width="10"
              height="10"
            >
              <path d={resetIconPath} />
            </svg>
          </button>
        )}
      </form>
      <div className="input-group mt-3">
        <label
          className="input-group-text mb-0"
          htmlFor="near_within_distance"
        >
          Within
        </label>
        <select
          name="near_within_distance"
          id="near_within_distance"
          value={aroundRadius}
          onChange={handleAroundRadiusChange}
          className="form-select"
        >
          {/* Value in meters */}
          <option value="all">{placeValue ? 'Any distance' : ''}</option>
          <option value="8047">5 miles</option>
          <option value="16093">10 miles</option>
          <option value="40234">25 miles</option>
          <option value="80467">50 miles</option>
          <option value="160934">100 miles</option>
          <option value="402336">250 miles</option>
        </select>
      </div>
    </div>
  );
}

AutocompletePlaceInput.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  clearGeoSearch: PropTypes.bool.isRequired,
};

AutocompletePlaceInput.defaultProps = {
  defaultValue: '',
  onChange: () => {
  },
};

export default AutocompletePlaceInput;
