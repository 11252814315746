import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import vapi from '../../javascript/frontend/api/vapi';
import opportunityHelpers from '../../javascript/frontend/helpers/opportunity_helpers';
import vahoy from '../../javascript/vahoy';

class UpcomingEconomicCalendarOpportunities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opportunities: [],
    };
  }

  componentDidMount() {
    const after = moment().subtract(1, 'd').toISOString();
    const before = moment().add(1, 'w').toISOString();
    const type = 'Opportunity::CalendarItem';
    const sort = 'opportunity_date';
    vapi.getOpportunities(after, before, type, sort)
      .then(({ data }) => {
        this.setState({ opportunities: data.data });
      });
  }

  trackClicks = () => {
    vahoy.track('UpcomingEconomicCalendarOpportunities#clickOpportunityLink');
  };

  render() {
    const { opportunities } = this.state;
    const { heading = 'Economic Calendar' } = this.props;
    const { max_content_items: maxContentItems = 12 } = this.props;
    const limitedOpportunities = opportunities.slice(0, maxContentItems);

    return limitedOpportunities.length > 0 && (
      <div>
        <h3>{heading}</h3>
        <ul className="list-unstyled fa-ul ms-0 mb-0 test-sidebar_economic_calendar">
          {limitedOpportunities.map((opportunity) => {
            const { id } = opportunity;
            const {
              name,
              opportunity_date: date,
              opportunity_type: type,
            } = opportunity.attributes;
            const link = opportunity.links.frontend;
            return (
              <li className="d-flex mb-1" key={id}>
                <i className={opportunityHelpers.opportunityTypeIconClasses(type, false)} />
                <a href={link} onClick={this.trackClicks} className="ms-2">
                  {moment(date).format('ddd MM/DD')}: {name}&nbsp;
                  <small>{`(${moment(date).fromNow()})`}</small>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

UpcomingEconomicCalendarOpportunities.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  max_content_items: PropTypes.number,
};

UpcomingEconomicCalendarOpportunities.defaultProps = {
  heading: undefined,
  max_content_items: undefined,
};

export default UpcomingEconomicCalendarOpportunities;
