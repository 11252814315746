import React from 'react';
import PropTypes from 'prop-types';
import { connectStateResults } from 'react-instantsearch-dom';
import PrUserSkeletonPage from '../index/pr_user_skeleton_page';

function SearchLoadingCards({ searchResults, smallMode }) {
  const isLoading = !searchResults;

  if (!isLoading) return null;

  return (
    <PrUserSkeletonPage numberOfCards={12} smallMode={smallMode} />
  );
}

SearchLoadingCards.propTypes = {
  searchResults: PropTypes.instanceOf(Object),
  smallMode: PropTypes.bool,
};

SearchLoadingCards.defaultProps = {
  searchResults: undefined,
  smallMode: false,
};

export default connectStateResults(SearchLoadingCards);
