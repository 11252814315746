import React from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../../javascript/vahoy';

function OrganizationInfo(props) {
  const { organization } = props;
  const organizationEmpty = _.isEmpty(organization);

  if (organizationEmpty) {
    return null;
  }

  const organizationAttrs = organization.attributes;
  const organizationLinks = organization.links;
  const trackClicks = () => {
    vahoy.track('Opportunity/Organization/Info#clickOrganizationLink');
  };

  const link = organizationLinks.frontend;

  return (
    <div className="organization-card">
      {organizationAttrs.name
        && (
          <div>
            {organizationAttrs.image_cloudinary_url
              && (
                <img
                  src={organizationAttrs.image_cloudinary_url}
                  className="float-start me-2 mb-1 w-33"
                  alt=""
                />
              )}

            {link
              && (
                <a
                  className="fw-bold"
                  href={link}
                  onClick={trackClicks}
                >{organizationAttrs.name}
                </a>
              )}

            {!link
              && <span className="fw-bold">{organizationAttrs.name}</span>}

          </div>
        )}
    </div>
  );
}

OrganizationInfo.propTypes = {
  organization: PropTypes.instanceOf(Object),
};

OrganizationInfo.defaultProps = {
  organization: undefined,
};

export default OrganizationInfo;
