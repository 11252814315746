import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import MediaMatchesIndex from './index/media_matches_index';

function TopLevelIndex({
                          query,
                          hiddenSearchString,
                          featuresToken,
                          algoliaAppId,
                          algoliaSearchKey,
                          indexName,
                          canPitchMediaMatch,
                        }) {
  return (
    <ErrorBoundary>
      <MediaMatchesIndex
        query={query}
        featuresToken={featuresToken}
        algoliaAppId={algoliaAppId}
        algoliaSearchKey={algoliaSearchKey}
        indexName={indexName}
        hiddenSearchString={hiddenSearchString}
        canPitchMediaMatch={canPitchMediaMatch}
      />
    </ErrorBoundary>
  );
}

TopLevelIndex.propTypes = {
  query: PropTypes.string,
  hiddenSearchString: PropTypes.string,
  featuresToken: PropTypes.string.isRequired,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  canPitchMediaMatch: PropTypes.bool,
};

TopLevelIndex.defaultProps = {
  query: undefined,
  hiddenSearchString: undefined,
  canPitchMediaMatch: true,
};

export default TopLevelIndex;
