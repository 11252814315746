import React, { useState } from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../javascript/vahoy';
import ReportModal from './report_modal';
import vapi from '../../javascript/frontend/api/vapi';

function ReportButton(props) {
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState('');
  const [behaviorTypes, setBehaviorTypes] = useState();

  const closeModal = () => {
    setShowModal(false);
  };

  const handleClick = () => {
    setShowModal(true);
    vahoy.track('ReportButton#showModal');
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const submitReport = () => {
    const reportedBehaviorsKeyArray = behaviorTypes || [];

    const params = {
      // alphabetized
      report_description: description,
      reported_behavior_types: reportedBehaviorsKeyArray,
      reported_id: props.reported_id,
      reported_text: props.reported_text,
      reported_type: props.reported_type,
      reported_url: props.reported_url,
      reported_user_id: props.reported_user_id,
      reporting_user_id: props.reporting_user_id,
    };

    vapi.reportAbuse(params)
      .then(() => {
        closeModal();
      }).catch((error) => {
      alert('An error occurred while submitting the report. Please try again later.');
      throw error; // Rethrow the error
    });

    vahoy.track('ReportModal#reportAbuse');
  };

  const renderButton = () => (
    <>
      <button
        className="btn btn-small btn-outline-danger"
        onClick={handleClick}
        type="button"
      >
        <i className="fa-solid fa-triangle-exclamation" />&nbsp;Report Pitch
      </button>
      <ReportModal
        abusive_behavior_types={props.abusive_behavior_types}
        behavior_types={behaviorTypes}
        close_modal={closeModal}
        handle_description_change={handleDescriptionChange}
        is_opening={showModal}
        set_behavior_types={setBehaviorTypes}
        show_modal={showModal}
        submit_report={submitReport}
      />
    </>
  );

  return renderButton();
}

ReportButton.propTypes = {
  reported_id: PropTypes.number,
  reported_text: PropTypes.string,
  reported_type: PropTypes.string,
  reported_url: PropTypes.string,
  reported_user_id: PropTypes.number,
  user_id: PropTypes.number,
};

ReportButton.defaultProps = {
  reported_id: undefined,
  reported_text: undefined,
  reported_type: undefined,
  reported_url: undefined,
  reported_user_id: undefined,
  user_id: undefined,
};

export default ReportButton;
