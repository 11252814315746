import React, { useEffect, useState } from 'react';
import vahoy from '../javascript/vahoy';
import vlog from '../javascript/vlog';

function WebPushManageSubscription() {
  const [activeTab, setActiveTab] = useState('Summary');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [optedIn, setOptedIn] = useState(null);
  const [permission, setPermission] = useState(null);
  const [permissionStatus, setPermissionStatus] = useState(null);

  useEffect(() => {
    const v2HealthCheck = async () => {
      const passed = await window.v2HealthCheck();
      if (passed) {
        vlog.debug('Component not ready: V2 health check did not pass.');
      } else {
        setLoading(false);
        setError(true);
      }
    };

    v2HealthCheck();
  }, []);

  useEffect(() => {
    vlog.debug('Component mounted. Initializing subscription status...');

    const updateStatus = async () => {
      if (!window.OneSignal || !window.OneSignal.context) {
        vlog.warn('OneSignal or OneSignal.context is not defined. Aborting updateStatus.');
        return;
      }

      try {
        vlog.debug('Fetching subscription status...');
        const isOptedIn = await window.OneSignal.User.PushSubscription.optedIn;
        const perm = await window.OneSignal.Notifications.permission;
        const permStatus = await window.OneSignal.context.permissionManager.getPermissionStatus();

        vlog.trace('Subscription status fetched', { isOptedIn, perm, permStatus });

        setOptedIn(isOptedIn);
        setPermission(perm);
        setPermissionStatus(permStatus);
        setLoading(false);

        vlog.debug('Subscription status updated.');
      } catch (err) {
        vlog.error('Error fetching push subscription status:', err);
        setLoading(false);
      }
    };

    const onLogin = async () => {
      if (!window.OneSignal) return;

      vlog.debug('Setting up OneSignal subscription change listener.');
      window.OneSignal.User.PushSubscription.addEventListener('change', updateStatus);

      updateStatus();
    };

    vlog.debug('Setting up event listener for `qwotedOneSignalLoggedIn`...');
    window.addEventListener('qwotedOneSignalLoggedIn', onLogin);
    onLogin();

    return () => {
      vlog.debug('Cleaning up event listener for `qwotedOneSignalLoggedIn`...');
      window.removeEventListener('turbolinks:load', onLogin);
      window.OneSignal.User.PushSubscription.removeEventListener('change', updateStatus);
    };
  }, []);

  const handleOptIn = async () => {
    vlog.info('User clicked Opt In.');
    vahoy.track('WebPushManageSubscription#handleOptIn');
    let perm = await window.OneSignal.Notifications.permission;
    if (!perm) {
      vlog.debug('Requesting notification permission...');
      await window.OneSignal.Notifications.requestPermission();
      perm = await window.OneSignal.Notifications.permission;
      setPermission(perm);
      vlog.trace('Notification permission updated', { permission: perm });
    }

    if (perm) {
      vlog.debug('Opting user in...');
      await window.OneSignal.User.PushSubscription.optIn();
      vlog.info('User successfully opted in.');
    } else {
      vlog.warn('Permission not granted. User cannot opt in.');
    }
  };

  const handleOptOut = async () => {
    vlog.info('User clicked Opt Out.');
    vahoy.track('WebPushManageSubscription#handleOptOut');
    try {
      await window.OneSignal.User.PushSubscription.optOut();
      vlog.info('User successfully opted out.');
    } catch (err) {
      vlog.error('Error opting out:', err);
    }
  };

  const renderSubscriptionTab = () => {
    vlog.trace('Rendering Subscription Tab...');
    return (
      <div>
        {optedIn ? (
          <p className="p-1">
            <i className="fa-solid fa-check-circle text-success fa-xl" /> Web push notifications are enabled!
          </p>
        ) : (
          <p>
            <i className="fa-solid fa-x text-danger fa-xl" /> Web push notifications aren&apos;t enabled. See the Details
            tab for help.
          </p>
        )}
      </div>
    );
  };

  const renderDetailsTab = () => {
    vlog.trace('Rendering Details Tab...');
    return (
      <div>
        <div className="alert alert-info">
          <p>To receive web push notifications, ensure the following steps are completed:</p>
          <ol>
            <li>
              <strong>Browser Permissions:</strong> Allow notifications in your browser settings. Learn more
              <a
                href="https://www.wpbeginner.com/beginners-guide/what-are-web-push-notifications-and-how-do-they-work-explained/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                here
              </a>
              .
            </li>
            <li>
              <strong>Opted In:</strong> Opt-in through our service using the &ldquo;Opt In&rdquo; button below.
            </li>
            <li>
              <strong>Operating System Settings:</strong> Make sure notifications are enabled, for your browser, in your
              OS settings.
            </li>
          </ol>
          <p>If issues persist, please contact our support team.</p>
        </div>

        <table className="table table-bordered table-striped">
          <thead className="table-light">
          <tr>
            <th>Opt-In Type</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Browser Permission</td>
            <td>
              {permissionStatus === 'denied' && 'Blocked: enable in your browser search bar.'}
              {permissionStatus !== 'denied'
                && (permission ? (
                  <i className="fa-solid fa-check-circle text-success" />
                ) : (
                  <i className="fa-solid fa-x text-danger" />
                ))}
            </td>
          </tr>
          <tr>
            <td>Opted In</td>
            <td>
              {optedIn ? (
                <i className="fa-solid fa-check-circle text-success" />
              ) : (
                <i className="fa-solid fa-x text-danger" />
              )}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  };

  if (loading) {
    vlog.debug('Component is loading. Returning null.');
    return null;
  }

  if (error) {
    vlog.debug('Component has error. Returning null.');
    return null;
  }

  vlog.debug('Rendering Web Push Manage Subscription component.');
  return (
    <div className="mt-4">
      <h4 className="fw-bold">Web Push</h4>
      <p className="text-muted">Get immediate updates</p>

      <div className="mt-3">
        {/* Tabs */}
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'Summary' ? 'active' : ''}`}
              onClick={() => {
                vlog.debug('Switching to Summary tab.');
                setActiveTab('Summary');
              }}
              type="button"
            >
              Summary
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'Details' ? 'active' : ''}`}
              onClick={() => {
                vlog.debug('Switching to Details tab.');
                setActiveTab('Details');
              }}
              type="button"
            >
              Details
            </button>
          </li>
        </ul>

        {/* Tab Content */}
        <div className="tab-content mt-3">
          {activeTab === 'Summary' && renderSubscriptionTab()}
          {activeTab === 'Details' && renderDetailsTab()}
        </div>
      </div>

      {/* Actions */}
      <div className="mt-3">
        {!permission && (
          <p className="text-danger">
            Browser permissions are not granted. Please enable notifications in your browser settings.
          </p>
        )}
        <button onClick={handleOptIn} className="btn btn-primary me-2" disabled={optedIn} type="button">
          Opt In
        </button>
        <button onClick={handleOptOut} className="btn btn-secondary" disabled={!optedIn} type="button">
          Opt Out
        </button>
      </div>
    </div>
  );
}

export default WebPushManageSubscription;
