/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { CompanyContext } from '../data/company_context_management';
import useCompanyUpdateApi from '../data/use_company_update_api';
import SaveSpinnerButton from '../../save_spinner_button';
import PrAgencyList from '../show/sidebar/pr_agency_list';

function PrAgenciesForm() {
  const context = useContext(CompanyContext);
  const {
    company,
    isUpdating,
    setOpenEditModal,
    setGeneralError,
  } = context;
  const { updateCompany } = useCompanyUpdateApi({ ...context });
  const [showValue, setShowValue] = useState(company.show_pr_agencies);

  const closeModal = () => {
    setOpenEditModal(null);
    setGeneralError(null);
  };

  const handleShowChange = (e) => {
    const newValue = e.target.checked;
    setShowValue(newValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      show_pr_agencies: showValue,
    };

    updateCompany(data);
  };

  return (
    <>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="form-check form-switch">
            <input
              type="checkbox"
              className="form-check-input"
              checked={showValue}
              onChange={handleShowChange}
              disabled={isUpdating}
              id="show_pr_agencies"
            />
            <label
              className="form-input-label"
              htmlFor="show_pr_agencies"
            >
              Show this section <small>(It's automatically generated)</small>
            </label>
          </div>
        </form>
        <div
          className="w-50 mx-auto my-3"
          style={{ opacity: showValue ? '100%' : '30%', transition: 'opacity 1s' }}
        >
          <PrAgencyList previewMode={true} />
        </div>
      </ModalBody>
      <ModalFooter>
        <SaveSpinnerButton
          inactiveText="Save Changes"
          activeText="Saving..."
          isSaving={isUpdating}
          className="btn btn-primary"
          onClick={handleSubmit}
        />
        <button
          type="button"
          className="btn text-danger"
          onClick={closeModal}
          disabled={isUpdating}
        >
          Cancel
        </button>
      </ModalFooter>
    </>
  );
}

export default PrAgenciesForm;
