import React from 'react';
import PropTypes from 'prop-types';
import { useRefinementList } from 'react-instantsearch-core';

function RefinementListWithoutCounts({
  attribute, operator, transformItems,
}) {
  const {
    items,
    refine,
    searchForItems,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
  } = useRefinementList({
    attribute, operator, transformItems,
  });

  return (
    <>
      <input
        type="search"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck={false}
        maxLength={512}
        onChange={(event) => searchForItems(event.currentTarget.value)}
      />
      <ul>
        {items.map((item) => (
          <li key={item.label}>
            <label>
              <input
                type="checkbox"
                checked={item.isRefined}
                onChange={() => refine(item.value)}
              />
              <span>{item.label}</span>
              <span>({item.count})</span>
            </label>
          </li>
        ))}
      </ul>
      <button type="button" onClick={toggleShowMore} disabled={!canToggleShowMore}>
        {isShowingMore ? 'Show less' : 'Show more'}
      </button>
    </>
  );
}

RefinementListWithoutCounts.propTypes = {
  attribute: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  transformItems: PropTypes.func,
};

RefinementListWithoutCounts.defaultProps = {
  transformItems: undefined,
};

export default RefinementListWithoutCounts;
