import React from 'react';
import {
  FormGroup, Modal, ModalHeader, ModalBody, Label, Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';
import 'select2';
import vahoy from '../../../javascript/vahoy';

class OrganizationSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organization: props.organization,
      showModal: props.showModal,
    };

    this.fetchPossibleOrganizations = this.fetchPossibleOrganizations.bind(this);
    this.setupSelect2 = this.setupSelect2.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchPossibleOrganizations();
  }

  // eslint-disable-next-line react/sort-comp
  fetchPossibleOrganizations() {
    const { contact } = this.props;

    if (contact) {
      vapi.getContactOrganizations(contact.id)
        .then((response) => {
          this.setState({
            possibleOrganizations: response.data.data,
          });
        });
    }
  }

  formatOrganizations(organization) {
    const attrs = organization.attributes;

    if (organization.loading) {
      return organization.text;
    }
    const row = `${attrs.name}`;
    return row;
  }

  formatNoResults() {
    return '... nothing found: <button id="new-organization-btn" class="btn btn-outline-secondary">New organization</button>';
  }

  setupSelect2() {
    const that = this;
    const $organizationSearchModal = $('#organization-search-modal');
    const $eventSelect = $('#searchOrganizationName').select2({
      ajax: {
        url: '/api/internal/jsonapi/organizations',
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            filter: {
              name: params.term,
            },
            page: {
              limit: 50,
            },
          };
        },
        processResults(response) {
          return {
            results: response.data,
          };
        },
      },
      dropdownParent: $organizationSearchModal.parent(), // https://github.com/select2/select2/issues/4217#issuecomment-277799699
      escapeMarkup(markup) {
        return markup;
      },
      minimumInputLength: 1,
      templateResult: this.formatOrganizations,
      language: {
        inputTooShort() {
          return '';
        },
        noResults: this.formatNoResults,
      },
    });

    $organizationSearchModal.on('click', '#new-organization-btn', () => {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.onNew();
      vahoy.track('Opportunity/Organization/Search#clickNewButton');
    });

    $eventSelect.on('select2:select', (e) => {
      that.onSelect(e.params.data.id, e.params.data.attributes);
    });
  }

  handleSubmit() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onSubmit(this.state.organization_id, this.state.organization);
  }

  onSelect(orgId) {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onSubmit(orgId);
    vahoy.track('Opportunity/Organization/Search#selectOrganization');
  }

  onOpened = () => {
    this.setupSelect2();
  };

  render() {
    const { showModal, possibleOrganizations } = this.state;
    const { onHide, contact } = this.props;

    return (
      <Modal
        id="organization-search-modal"
        className="modal-lg"
        isOpen={showModal}
        toggle={onHide}
        onOpened={this.onOpened}
      >
        <ModalHeader toggle={onHide}>
          Search for organization
        </ModalHeader>

        <ModalBody>

          <FormGroup>
            <Label for="searchOrganizationName">First, try searching:</Label>
            <Input id="searchOrganizationName" type="select" name="search_organization_name" />
          </FormGroup>

          {possibleOrganizations && possibleOrganizations.length > 0
            && (
              <div className="clearfix pt-3 pb-3">
                <div>
                  <label>
                    ... also, {contact.attributes.full_name} is already
                    associated with these existing organizations:
                  </label>
                </div>
                {possibleOrganizations.map((item) => (
                  <div key={item.id} style={{ cursor: 'pointer' }} className="col-4 ps-0 mb-2">
                    <div
                      role="button"
                      tabIndex="-1"
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.onSelect(item.id, item.attributes);
                      }}
                      onKeyUp={(evt) => evt.keyCode === 13 && this.onSelect(item.id, item.attributes)}
                    >
                      {item.attributes.name}
                    </div>
                  </div>
                ))}
                <div className="clearfix" />
              </div>
            )}

          <div>
            <strong>
              Finally, if you can't find an existing organization:
            </strong>
            &nbsp;&nbsp;
            <button id="new-organization-btn" className="btn btn-outline-secondary" type="button">New
              organization
            </button>
          </div>

          <div className="clearfix" />
        </ModalBody>
      </Modal>
    );
  }
}

OrganizationSearch.propTypes = {
  contact: PropTypes.instanceOf(Object),
  organization: PropTypes.instanceOf(Object),
  showModal: PropTypes.bool,
  onNew: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

OrganizationSearch.defaultProps = {
  contact: undefined,
  organization: undefined,
  showModal: undefined,
  onNew: undefined,
};

export default OrganizationSearch;
