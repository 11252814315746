/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Hashtags from '../../hashtags';
import ResponsiveAvatar from '../../responsive_avatar';

function ReporterCard({
                          reporter, updates,
                        }) {
  if (updates) Object.assign(reporter, updates.attributes);

  let titleHtml = null;
  if (reporter.employments) {
    const employments = reporter.employments.split(' and ');
    titleHtml = (
      <div className="card-grid-subtitle font-size-12px">
        <span>
          {employments[0]}
        </span>
      </div>
    );
  }

  return (
    <div className="card h-100 animated faster fadeIn">
      <div className="card-body position-relative">
        <ResponsiveAvatar
          avatarUrl={reporter.avatar_url}
          avatarLetters={reporter.initials}
          className="mb-2"
        />

        <a href={reporter.reporter_path} className="font-weight-bold cursor-pointer">
          {reporter.full_name}
        </a>

        <div className="mb-2">
          {titleHtml}
        </div>

        <Hashtags
          hashtagTarget={reporter}
          maxHashtags={3}
          title={null}
          clickEventName="Sources/SourceCard#clickHashtag"
        />

        <div className="clearfix" style={{ minHeight: '2.0rem' }} />
        <div className="mt-4 mb-2 row w-100" style={{ position: 'absolute', bottom: '0' }}>
          <div className="col" />
          <div className="d-flex align-items-end mr-3">
            {reporter.managing_reporter_user && (
              <div className="p-0 m-0">
                <a className="btn btn-sm btn-primary" href={`/users/${reporter.managing_reporter_user.slug}/dm/new`}>
                  <i className="float-left fa fa-paper-plane text-white me-1" />
                  <span className="ms-2">message {reporter.managing_reporter_user.first_name}</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ReporterCard.propTypes = {
  reporter: PropTypes.instanceOf(Object),
  updates: PropTypes.instanceOf(Object),
};

ReporterCard.defaultProps = {
  reporter: undefined,
  updates: undefined,
};

export default ReporterCard;
