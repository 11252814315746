// Prefer writing route paths in this file and importing the route into your component as
//   import { newUserRegistrationPath } from './routes'
// To have a single place of definition, in case route paths change

const newUserRegistrationPath = '/users/sign_up'; // Rails route: new_user_registration_path
export default newUserRegistrationPath;

export function sourcePath(slug) {
  return `/sources/${slug}`;
}

export function userPath(slug) {
  return `/users/${slug}`;
}

export function prUserPath(slug) {
  return `/pr_users/${slug}`;
}
