import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { connectRefinementList } from 'react-instantsearch-dom';

/* eslint-disable no-use-before-define, no-shadow, no-restricted-globals,
no-param-reassign, react/no-unused-prop-types */

function ChordDiagram({
  items,
  query,
  width = 900,
  height = 200,
  labelFontSize = 20,
  enablePopoverTooltip = true,
}) {
  const divRef = useRef(null);
  const baseColor = 'rgb(217,64,40)';
  const hoverColor = 'rgb(113,96,233)';
  const darkGray = 'gray';

  useEffect(() => {
    if (items.length === 0) return;

    const div = d3.select(divRef.current);
    div.selectAll('*').remove();

    const outerRadius = Math.min(width, height) * 0.4 - 100;
    const innerRadius = outerRadius - 2;

    const chord = d3.chord().padAngle(0.01).sortSubgroups(d3.descending);

    const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

    const ribbon = d3.ribbon().radius(innerRadius).padAngle(0.05);

    const color = d3.scaleOrdinal([baseColor]);

    const svg = div
      .append('svg')
      .attr('viewBox', [-width / 2, -height / 3.5, width, height / 1.67])
      .attr('width', '100%')
      .attr('style', 'margin-left: 0%;');

    function generateMatrix(items) {
      const { length } = items;
      const matrix = Array.from({ length }, () => Array(length).fill(0));

      items.forEach((item, i) => {
        items.forEach((innerItem, j) => {
          if (i !== j) {
            matrix[i][j] = Math.floor(Math.random() * 100);
          }
        });
      });

      return matrix;
    }

    const matrix = generateMatrix(items);

    const chords = chord(matrix);

    const group = svg
      .append('g')
      .selectAll('g')
      .data(chords.groups)
      .enter()
      .append('g');

    group
      .append('path')
      .attr('fill', (d) => color(d.index))
      .attr('stroke', (d) => color(d.index))
      .attr('d', arc)
      .attr('class', 'arc-path');

    const handleBarClick = (value) => {
      const url = new URL(window.location.href);
      const cleanedValue = value.replace('#', '');
      url.searchParams.set('query', cleanedValue);
      window.location.href = url.toString();
    };

    const handleMouseOver = (event, d) => {
      if (d && typeof d.index !== 'undefined') {
        svg
          .selectAll('path')
          .filter((p) => p.source?.index === d.index || p.target?.index === d.index)
          .attr('fill-opacity', 1)
          .attr('fill', hoverColor);

        svg
          .selectAll('path.arc-path')
          .filter((p) => p.index === d.index)
          .attr('fill', hoverColor)
          .attr('stroke', hoverColor);

        d3.select(event.target).attr('fill', hoverColor);
      }
    };

    const handleMouseOut = () => {
      svg.selectAll('path').attr('fill-opacity', 0.67).attr('fill', baseColor);
      svg.selectAll('path.arc-path').attr('fill', baseColor).attr('stroke', baseColor);
      d3.select(event.target).attr('fill', darkGray);
    };

    group
      .append('text')
      .each((d) => {
        d.angle = (d.startAngle + d.endAngle) / 2;
      })
      .attr('dy', '.35em')
      .attr('transform', (d) => `
        rotate(${(d.angle * 180) / Math.PI - 90})
        translate(${innerRadius + 10})
        ${d.angle > Math.PI ? 'rotate(180)' : ''}
      `)
      .attr('text-anchor', (d) => (d.angle > Math.PI ? 'end' : null))
      .attr('font-size', `${labelFontSize}px`)
      .attr('font-weight', 'bold')
      .attr('fill', darkGray)
      .attr('cursor', 'pointer')
      .text((d, i) => items[i]?.label.replace('#', ''))
      .on('click', (event, d) => {
        if (items[d.index]) {
          handleBarClick(items[d.index].value[0]);
        }
      })
      .on('mouseover', (event, d) => {
        handleMouseOver(event, d);
        if (enablePopoverTooltip) {
          tippy(event.target, {
            allowHTML: true,
            content: `<div class="bg-white p-2 rounded border">${items[d.index]?.label.replace('#', '')}</div>`,
          });
        }
      })
      .on('mouseout', handleMouseOut)
      .attr('class', 'term-text');

    svg
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('text-anchor', 'middle')
      .attr('font-size', `${labelFontSize * 1.75}px`)
      .attr('font-weight', 'bold')
      .attr('fill', darkGray)
      .text(query);

    svg
      .append('g')
      .attr('fill-opacity', 0.67)
      .selectAll('path')
      .data(chords)
      .enter()
      .append('path')
      .attr('fill', (d) => color(d.target.index))
      .attr('d', ribbon)
      .attr('stroke-width', 0.2)
      .append('title')
      .text((d) => `${items[d.source.index]?.label.replace('#', '')} → ${items[d.target.index]?.label.replace('#', '')}`);
  }, [items, query, width, height, labelFontSize, enablePopoverTooltip]);

  return <div ref={divRef} style={{ width: '100%', height: '100%' }} />;
}

ChordDiagram.propTypes = {
  query: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
  currentRefinement: PropTypes.arrayOf(PropTypes.string).isRequired,
  refine: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  labelFontSize: PropTypes.number,
  enablePopoverTooltip: PropTypes.bool,
};

ChordDiagram.defaultProps = {
  query: '',
  width: 400,
  height: 400,
  labelFontSize: 20,
  enablePopoverTooltip: true,
};

const ConnectedChordDiagram = connectRefinementList(ChordDiagram, { limit: 20 });
export default ConnectedChordDiagram;
