import { Tooltip } from 'bootstrap';

document.addEventListener('turbolinks:load', () => {
  // {animation: false} to fix "#2459 Error: Tooltip is transitioning"
  // https://rollbar.com/vested/vested/items/2459/
  // https://github.com/twbs/bootstrap/issues/21607
  // Can be removed when upgrading past Bootstrap v4.0.0-alpha.6
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
});
