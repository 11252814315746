import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  createFavorite(favoriteableId, favoriteableType, userId) {
    vlog.info('api#createFavorite');
    api.configure();

    const url = '/api/internal/jsonapi/favorites';

    const data = {
      type: 'favorites',
      attributes: {
        favoriteable_id: favoriteableId,
        favoriteable_type: favoriteableType,
        user_id: userId,
      },
    };

    return axios.post(url, { data }, api.axiosFix);
  },

  deleteFavorite(favoriteId) {
    vlog.info('api#deleteFavorite');

    api.configure();

    const url = `/api/internal/jsonapi/favorites/${favoriteId}`;

    return axios.delete(url, api.axiosFix);
  },
});
