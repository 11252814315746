import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import NoResultsMessage from '../../algolia_search/no_results_message';
import SearchLoadingCards from './search_loading_cards';
import JobPostingCard from '../card/job_posting_card';
import JobPostingSkeletonPage from '../index/job_posting_skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import EmptyResults from './empty_results';

function InfiniteHits({
                        hasMore, refine, hits, userType,
                      }) {
  // Ignore scrollContainerRef since we want to use root as the scrolling container
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });

  // Effect to refine results when infinite scroll triggers
  useEffect(() => {
    if (lastTriggeredAt > 0) refine();
  }, [lastTriggeredAt, refine]);

  const customMessage = window.CURRENT_USER_CLIENT_SESSION ? <EmptyResults /> : undefined;

  return (
    <div className="container-fluid">
      <NoResultsMessage customMessage={customMessage} />
      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 px-1">
        <SearchLoadingCards />
        {hits.map((jobPosting) => (
          <React.Fragment key={jobPosting.objectID}>
            <div className="col px-2 pb-3 pt-0">
              <JobPostingCard
                jobPosting={jobPosting}
                userType={userType}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
      {hasMore && (
        <div
          ref={scrollTriggerRef}
          className="row row-cols-1 row-cols-md-2 row-cols-xl-3 px-1"
        >
          <JobPostingSkeletonPage numberOfCards={3} singleRow />
        </div>
      )}
    </div>
  );
}

InfiniteHits.propTypes = {
  hasMore: PropTypes.bool,
  refine: PropTypes.func,
  hits: PropTypes.arrayOf(Object),
  userType: PropTypes.string,
};

InfiniteHits.defaultProps = {
  hasMore: false,
  refine: undefined,
  hits: [],
  userType: undefined,
};

export default connectInfiniteHits(InfiniteHits);
