import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  getMoreJobPostings(jobPostingId) {
    vlog.info('api#getMoreJobPostings');

    api.configure();
    const url = `/api/internal/job_postings/${jobPostingId}/get_more_job_postings`;

    return axios.get(url, {}, api.axiosFix);
  },
});
