import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  getSourceRequestComments(params) {
    vlog.info('api#getSourceRequestComments');

    api.configure();

    const url = '/api/internal/source_request_comments';

    return axios.get(url, { params }, api.axiosFix);
  },

  getSourceRequestLatestComment(params) {
    vlog.info('api#getSourceRequestLatestComment');

    api.configure();

    const url = '/api/internal/source_request_comments/latest_comment';

    return axios.get(url, { params }, api.axiosFix);
  },

  createSourceRequestComment(params) {
    vlog.info('api#createSourceRequestComment');

    api.configure();
    axios.defaults.headers.common.Accept = 'application/json';

    const url = '/api/internal/source_request_comments';

    return axios.post(url, { source_request_comment: params }, api.axiosFix);
  },

  updateSourceRequestComment(sourceRequestCommentId, params) {
    vlog.info('api#updateSourceRequestComment');

    api.configure();
    axios.defaults.headers.common.Accept = 'application/json';

    const url = `/api/internal/source_request_comments/${sourceRequestCommentId}`;

    return axios.put(url, { source_request_comment: params }, api.axiosFix);
  },

  deleteSourceRequestComment(sourceRequestCommentId) {
    vlog.info('api#updateSourceRequestComment');

    api.configure();

    const url = `/api/internal/source_request_comments/${sourceRequestCommentId}`;

    return axios.delete(url, api.axiosFix);
  },
});
