const dateHelpers = {
  // humanizationLevel: 0 (less), 1 (more)
  formattedDate(date, humanizationLevel = '0') {
    let fd;
    const td = moment(date);

    if (date) {
      switch (true) {
        // see: https://github.com/moment/moment/issues/1048 to make this more sophisticated
        case (this.isWithin2WeeksOfPresent(date) && humanizationLevel >= 1):
          fd = moment().to(td);
          break;
        case (moment().isSame(date, 'year')):
          // eslint-disable-next-line no-undef
          fd = td.format(DATE_FORMATS.defaultCurrentYear);
          break;
        default:
          // eslint-disable-next-line no-undef
          fd = td.format(DATE_FORMATS.default);
          break;
      }
    } else {
      fd = '';
    }

    return fd;
  },

  formatDatetime(date, format) {
    const defaultWithTimezoneFormat = 'D MMM YYYY ha z';
    let fmt;

    if (format === 'default_with_timezone') {
      fmt = defaultWithTimezoneFormat;
    }

    fmt = format || defaultWithTimezoneFormat;
    return moment(date).format(fmt);
  },

  timeAgoInWords(date) {
    return moment(date).fromNow();
  },

  isWithinDurationOfPresent(dateMoment, momentDuration) {
    const now = moment();
    const diffDurationMs = Math.abs(now.diff(dateMoment));
    return diffDurationMs < momentDuration.asMilliseconds();
  },

  isWithin2WeeksOfPresent(dateMoment) {
    return this.isWithinDurationOfPresent(dateMoment, moment.duration(2, 'week'));
  },

  isFutureDatetime(date) {
    return moment(date).isAfter();
  },
};

export default dateHelpers;
