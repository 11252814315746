import React from 'react';
import PropTypes from 'prop-types';

function Source({
                  source,
                }) {
  let titleHtml = null;
  if (source.current_positions && source.current_positions[0]) {
    titleHtml = (
      <div className="card-grid-subtitle fw-bold font-size-12px">
        {source.current_positions[0].title} at {
        source.current_positions[0].company_show && (
          <a href={source.current_positions[0].company_path}>
            {source.current_positions[0].company}
          </a>
        )
      }
        {!source.current_positions[0].company_show && source.current_positions[0].company}
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-start mb-2">
      <div className="pe-2">
        {source.avatar_url && (
          <img className="avatar" key={source.avatar_url} alt={source.name} src={source.avatar_url} />
        )}
      </div>
      <div>
        <a href={source.source_path} className="fw-bold">{source.name}</a> {titleHtml}
      </div>

      <div className="clearfix mb-2" />
    </div>
  );
}

Source.propTypes = {
  source: PropTypes.instanceOf(Object).isRequired,
};

export default Source;
