import React from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import vapi from '../../javascript/frontend/api/vapi';
import loadingImageUrl from '../../assets/images/loading.svg';
import avatarHelpers from '../../javascript/frontend/helpers/avatar_helpers';
import vlog from '../../javascript/vlog';
import vahoy from '../../javascript/vahoy';

class AvatarUploader extends React.Component {
  constructor(props) {
    super(props);

    this.genericErrorMessage = 'Something went wrong: please try again.';
    this.toggle = this.toggle.bind(this);
    this.state = this.getInitialState(props);
  }

  getInitialState(props) {
    return {
      avatarJson: props.avatarJson,
      popoverOpen: false,
    };
  }

  handleFileChange = (e) => {
    vlog.debug('AvatarUploader#handleFileChange called');

    this.setState({ popoverOpen: false });

    const that = this;

    const { files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const { avatarJson } = that.state;
        const base64File = reader.result;

        if (avatarJson) {
          vlog.info('AvatarUploader#uploadAvatar: updating existing avatar');
          that.updateAvatar(base64File);
        } else {
          vlog.info('AvatarUploader#uploadAvatar: creating new avatar');
          that.createAvatar(base64File);
        }
      };

      reader.onerror = (error) => {
        alert(error); // TODO friendly errors
        throw new Error(`AvatarUploader#uploadAvatar reader.onerror: ${error}`);
      };
    }
  };

  // eslint-disable-next-line react/sort-comp
  handleErrors(xhr) {
    vlog.debug('AvatarUploader#handleErrors called');

    let message = '';

    if (xhr && xhr.response && xhr.response.data && xhr.response.data.errors) {
      const { errors } = xhr.response.data;
      const errorTexts = [];

      for (const field in errors) {
        if ({}.hasOwnProperty.call(errors, field)) {
          const fieldErrors = errors[field];
          errorTexts.push(fieldErrors.join(', '));
        }
      }

      message = errorTexts.join('. ');
    } else {
      message = this.genericErrorMessage;
    }

    alert(message);
    throw new Error(`AvatarUploader#handleErrors: ${message}`);
  }

  onImageError = (e) => {
    if (window.Rollbar) {
      const src = e && e.target && e.target.src;
      window.Rollbar.error(`Error loading avatar image: ${src}`);
    }
  };

  deleteAvatar = () => {
    const { avatarJson } = this.state;
    this.setState({ uploading: true });

    vapi.deleteAvatar(avatarJson).then(() => {
      this.setState({
        avatarJson: null,
        popoverOpen: false,
        uploading: false,
      });
    }).catch((error) => {
      this.setState({ uploading: false });
      alert(error);
      throw new Error(`AvatarUploader#deleteAvatar deleteAvatar error: ${error}`);
    });

    vahoy.track('AvatarUploader#deleteAvatar');
  };

  // eslint-disable-next-line
  createAvatar(base64File) {
    const { uploaderId, uploaderType } = this.props;
    const json = {
      image_cloudinary: base64File,
      uploader_id: uploaderId,
      uploader_type: uploaderType,
    };

    this.setState({ uploading: true });

    vapi.createAvatar(json).then((responseData) => {
      const avatarJson = responseData.data.data;

      this.setState({
        avatarJson,
        popoverOpen: false,
        uploading: false,
      });
    }).catch((xhr) => {
      this.handleErrors(xhr);
      this.setState({ uploading: false });
    });

    vahoy.track('AvatarUplaoder#createAvatar');
  }

  // eslint-disable-next-line
  updateAvatar(base64File) {
    const { avatarJson } = this.state;
    avatarJson.attributes.image_cloudinary = base64File;

    this.setState({ uploading: true });

    vapi.updateAvatar(avatarJson).then((responseData) => {
      const updatedAvatarJson = responseData.data.data;
      this.setState({
        avatarJson: updatedAvatarJson,
        popoverOpen: false,
        uploading: false,
      });
    }).catch((xhr) => {
      this.handleErrors(xhr);
      this.setState({ uploading: false });
    });

    vahoy.track('AvatarUploader#updateAvatar');
  }

  toggle() {
    this.setState((prevState) => ({
      popoverOpen: !prevState.popoverOpen,
    }));
  }

  popover() {
    const { avatarJson, popoverOpen, uploading } = this.state;

    const fileInput = (
      <div className="mb-2">
        <input
          accept=".png, .jpg, .jpeg, .svg"
          className="form-control form-control-sm"
          id="avatar-fileupload"
          onChange={this.handleFileChange}
          type="file"
        />
      </div>
    );

    let avatarButton = null;
    if (!uploading && avatarJson) {
      avatarButton = (
        <button
          type="button"
          className="btn btn-sm btn-danger p-1 mb-2"
          onClick={this.deleteAvatar}
        >
          Remove Avatar
        </button>
      );
    }

    return (
      <Popover
        isOpen={popoverOpen}
        placement="bottom"
        target="avatar-popover-target"
        toggle={this.toggle}
      >
        <PopoverHeader>
          <button type="button" onClick={this.toggle} className="btn p-0 float-end" style={{ lineHeight: 0 }}>
            <i className="fa-solid fa-xmark" aria-hidden="true" />
          </button>

          <p className="mb-0">Change Avatar</p>

          <div className="clearfix" />
        </PopoverHeader>
        <PopoverBody>
          {fileInput}
          {avatarButton}
        </PopoverBody>
      </Popover>
    );
  }

  render() {
    const { avatarLetters, sizeClass, fallbackImageUrl } = this.props;
    const { avatarJson, uploading } = this.state;

    const { cloudinary_url: avatarUrl } = (avatarJson && avatarJson.attributes) || {};
    const colorClass = avatarHelpers.colorClassFromInitials(avatarLetters);

    let avatarContent;

    if (fallbackImageUrl) {
      avatarContent = (<img src={fallbackImageUrl} alt={avatarLetters} className="avatar-image" />);
    } else {
      avatarContent = (<span className="initials">{avatarLetters}</span>);
    }
    if (uploading) {
      avatarContent = (<span className="initials"><img src={loadingImageUrl} alt="Loading" /></span>);
    } else if (avatarJson) {
      avatarContent = (
        <img
          src={avatarUrl}
          alt={avatarLetters}
          className="avatar-image"
          onError={this.onImageError}
        />
      );
    }

    return (
      <div
        className="avatar-container"
        ref={(instance) => {
          // eslint-disable-next-line
          this.root = instance;
        }}
      >

        <div
          id="avatar-popover-target"
          className={`avatar is-editable ${colorClass} ${sizeClass}`}
        >
          {avatarContent}
          <span className="avatar-change-text">Change</span>
        </div>

        {this.popover()}
      </div>
    );
  }
}

AvatarUploader.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  avatarJson: PropTypes.instanceOf(Object),
  uploaderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  uploaderType: PropTypes.string.isRequired,
  avatarLetters: PropTypes.string.isRequired,
  sizeClass: PropTypes.string,
  fallbackImageUrl: PropTypes.string,
};

AvatarUploader.defaultProps = {
  avatarJson: undefined,
  sizeClass: undefined,
  fallbackImageUrl: undefined,
};

export default AvatarUploader;
