import React from 'react';
import PropTypes from 'prop-types';
import FavoriteButton from '../../favorite_button';

function ActionButtons({ source, smallMode }) {
  return (
    <>
      <div className="clearfix" style={{ minHeight: smallMode ? '2.75rem' : '3.0rem' }} />
      <div className="mt-4 mb-2 row w-100" style={{ position: 'absolute', bottom: '0' }}>
        <div className="col">
          {source.messaging_url && !source.is_self_source && (
            <a href={`${source.messaging_url}&back_path=${window.location.pathname}`}>
              <button
                type="button"
                className="btn btn-outline-primary me-2 animated faster fadeIn"
                style={{ padding: '0.25rem', paddingTop: '0.15rem', borderRadius: '5px' }}
              >
                <i className="fa-solid fa-envelope me-1" style={{ verticalAlign: 'middle' }} />
                <small>Contact</small>
              </button>
            </a>
          )}
        </div>
        {source.favoriteable_id && !source.is_self_source && (
          <div className="col-1 me-4">
            <FavoriteButton
              favoriteableId={source.favoriteable_id}
              favoriteableType="Source"
              favoriteId={source.favorite_id}
              favorited={source.favorited}
              ahoyObjectGid={source.object_gid}
            />
          </div>
        )}
      </div>
    </>
  );
}

ActionButtons.propTypes = {
  source: PropTypes.instanceOf(Object).isRequired,
  smallMode: PropTypes.bool,
};

ActionButtons.defaultProps = {
  smallMode: false,
};

export default ActionButtons;
