import React from 'react';
import PropTypes from 'prop-types';

import Navigation from './Navigation';

function MenuBar({
 view, showNav, children, onlyTabs,
}) {
  return (
    <div id="menu-bar" className="d-flex flex-wrap pt-3 border-top border-bottom mb-4">
      {showNav && (
        <div id="nav-container" className="flex-fill flex-grow-1 flex-sm-grow-0 mb-3 me-xl-5">
          <Navigation view={view} onlyTabs={onlyTabs} />
        </div>
      )}
      <div className="flex-fill flex-shrink-0 d-flex flex-wrap">
        <div id="search-form-container" className="flex-fill flex-shrink-0 flex-sm-shrink-1 mb-3 d-flex">
          {children.searchForm}
        </div>
      </div>
      {children.toggleSwitch && (
        <div id="toggle-switch-container" className="mb-3 ps-2 d-flex">
          {children.toggleSwitch}
        </div>
      )}
    </div>
  );
}

MenuBar.propTypes = {
  view: PropTypes.string.isRequired,
  onlyTabs: PropTypes.array,
  showNav: PropTypes.bool,
  children: PropTypes.shape({
    searchForm: PropTypes.node.isRequired,
    toggleSwitch: PropTypes.node,
  }).isRequired,
};

MenuBar.defaultProps = {
  showNav: true,
  onlyTabs: [],
};

export default MenuBar;
