import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';

function RepButton({
                     promoEntityId, promoEntityType, userId, repped, representationId, handleChange,
                   }) {
  const [reppedState, setReppedState] = useState(repped);
  const [representationIdState, setRepresentationIdState] = useState(representationId);
  const prevRepresentationIdRef = useRef();
  const prevReppedRef = useRef();

  useEffect(() => {
    if (prevRepresentationIdRef.current != representationId) {
      setRepresentationIdState(representationId);
    }
    if (prevReppedRef.current != repped) {
      setReppedState(repped);
    }
  }, [repped, representationId]);

  useEffect(() => {
    prevRepresentationIdRef.current = representationId;
    prevReppedRef.current = repped;
  }, [representationId, repped]);

  const addRep = () => {
    vapi.createRepresentation(
      promoEntityId,
      promoEntityType,
      userId,
    )
      .then((response) => {
        setReppedState(true);
        setRepresentationIdState(response.data.data.id);
        handleChange(Number(userId));
      });
  };

  /* eslint-disable */
  const removeRep = () => {
    console.log("removeRep");
    console.log(representationIdState);
    vapi.deleteRepresentation(
      representationIdState,
      promoEntityType,
    )
      .then(() => {
        setReppedState(false);
        setRepresentationIdState(null);
        handleChange(Number(userId));
      })
      .catch((error) => {
        throw new Error(`RepButton#removeRep: ${error}`);
      });
  };
  /* eslint-enable */

  const renderAddRepButton = () => (
    <button
      type="button"
      onClick={addRep}
      className="btn btn-sm btn-link p-1 rep-btn float-end"
    >
      <span className="check-label text-info"> Add </span>
      <i
        className="text-info fa-regular fa-square pl-2 fa-2x"
        title="Add representation."
      />
    </button>
  );

  const renderRemoveRepButton = () => (
    <button
      type="button"
      onClick={removeRep}
      className="focus-outline-0 btn btn-sm btn-link p-1 rep-btn float-end"
    >
      <span className="check-label text-info"> Remove </span>
      <i
        className="text-info fa-solid fa-square-check pl-1 fa-2x"
      />
    </button>
  );

  return (
    <>
      {
        reppedState ? renderRemoveRepButton() : renderAddRepButton()
      }
    </>
  );
}

RepButton.propTypes = {
  promoEntityId: PropTypes.number.isRequired, // Id of promo entity being repped
  promoEntityType: PropTypes.string.isRequired, // Type of promo entity being repped
  userId: PropTypes.number.isRequired, // Id of rep being added
  repped: PropTypes.bool.isRequired, // Whether or not the target is currently repped
  representationId: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
};

RepButton.defaultProps = {
  representationId: undefined,
};

export default RepButton;
