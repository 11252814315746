/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RepButton from './card/rep_button';
import vapi from '../../javascript/frontend/api/vapi';
import InviteButton from './invite_button';


function RepsList({ inviteUrl, promoEntityId, promoEntityType, representingUserIds, handleChange }) {
  const [reps, setReps] = useState([]);
  const [repsLoaded, setRepsLoaded] = useState(false);
  const fetchReps = async () => {
    try {
      const response = await vapi.getRepresentingUsers(promoEntityId, promoEntityType);
      const json = await response.data;
      return { success: true, data: json };
    } catch (error) {
      return { error };
    }
  };

  useEffect(() => {
    (async () => {
      setRepsLoaded(false);
      const res = await fetchReps();
      if (res.success) {
        setReps(res.data);
        setRepsLoaded(true);
      } else {
        console.log("error")
      }
    })();
  }, [representingUserIds]);

  return (
    <div>
      {repsLoaded
        && (
          <div className='rep-list'>
            <h2 className="text-info"> Current Representatives </h2>
            <ul>
              {reps.data.map((rep) => (
                <li key={`reps-list-${rep.id}`} className="position-relative py-1">
                  {reps.included.filter((r) => r.id === rep.attributes.user_id)[0].attributes.full_name}
                  <RepButton
                    promoEntityId={promoEntityId}
                    promoEntityType={promoEntityType}
                    userId={Number(rep.attributes.user_id)}
                    repped={true}
                    representationId={Number(rep.id)}
                    handleChange={handleChange}
                  />
                  <p className="d-xl-block d-sm-none">
                    {
                      reps.included.filter((r) => r.id === rep.attributes.user_id)[0].attributes.client_firm_name ?
                        reps.included.filter((r) => r.id === rep.attributes.user_id)[0].attributes.client_firm_name :
                        reps.included.filter((r) => r.id === rep.attributes.user_id)[0].attributes.email_domain
                    }
                  </p>
                </li>
              ))}
            </ul>
            <div className="clearfix">
              <InviteButton inviteUrl={inviteUrl} size={"26"} />
            </div>
          </div>
        )}
    </div>
  );
}

RepsList.propTypes = {
  promoEntityId: PropTypes.number.isRequired,
  promoEntityType: PropTypes.string.isRequired,
  representingUserIds: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  inviteUrl: PropTypes.string.isRequired,
};

export default RepsList;
/* eslint-enable */
