import React, { useState } from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';
import { useForm } from 'react-hook-form';
import {
  Button, ModalBody, Modal, ModalHeader, ModalFooter,
} from 'reactstrap';
import Avatar from '../../avatar/avatar';
import SaveSpinnerButton from '../../save_spinner_button';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';

function RequestSampleModal({
  productName, productCompanyName, productPrice, productSgid, productImageUrl, isOpen, close,
}) {
  const [isSending, setIsSending] = useState(false);
  const { id: userId, email: userEmail, authentication_token: authToken } = window.CURRENT_USER_JSON;

  const subject = `PRODUCT SAMPLE REQUEST | For ${productName}`;
  const bodyTemplateText = `Hi!

I'm writing an article and your product may be suitable for inclusion.

Could you please send me a sample so I can try it out?

Thanks!`;

  const {
    register, handleSubmit, formState: { errors },
  } = useForm(
    {
      defaultValues: {
        body: bodyTemplateText,
      },
    },
  );

  const sendMessage = (body) => {
    setIsSending(true);

    vapi.createMessage(userId, userEmail, authToken, productSgid, subject, body)
      .then((result) => {
        vahoy.track('RequestSampleModal#sendMessage');

        window.location.href = new URI(window.location.href)
          .pathname(result.data.path)
          .search({ flash_success: [result.data.success] });
      }).catch((error) => {
        setIsSending(false);
        alert(error);
        throw (error);
      });
  };

  const onSubmit = (data) => {
    sendMessage(data.body);
  };

  const modalCloseButton = <button type="button" className="btn-close" aria-label="Close" onClick={close} />;

  if (!window.CURRENT_USER_JSON) { return null; }
  if (!productSgid) { return null; }

  return (
    <Modal isOpen={isOpen} toggle={close} backdrop="static">
      <ModalHeader toggle={close} close={modalCloseButton} className="request-sample-modal-header">
        Request a Sample
      </ModalHeader>

      <form id="request-sample-modal-form" onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <div className="card mt-3 mb-4">
            <div className="card-body pt-3 pb-2">
              <div className="float-start pe-2">
                <Avatar
                  avatarLetters={productName.substring(0, 2)}
                  avatarUrl={productImageUrl}
                />
              </div>
              <div>
                { productCompanyName && (
                  <h5 className="small">{productCompanyName}</h5>
                )}
                <h4 className="mb-1 fw-bold auto-truncate">
                  {productName}
                </h4>
                {productPrice && (
                  <h5 className="mb-3">{productPrice}</h5>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <label className="fw-bold mb-2" htmlFor="subject">Subject</label>
            <div className="mb-3">
              <input
                id="request-sample-modal-message-subject"
                value={subject}
                className="form-control"
                disabled={true}
              />
            </div>

            <div className="col-12">
              <label className="fw-bold mb-2" htmlFor="body">Body</label>

              <textarea
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('body', {
                  required: 'The message body is required',
                  minLength: { value: 50, message: 'The message body must be at least 50 characters' },
                })}
                id="request-sample-modal-message-body"
                className={`form-control p-3 ${errors.body ? 'is-invalid' : ''}`}
                name="body"
                disabled={isSending}
                rows="8"
                aria-invalid={errors.body ? 'true' : 'false'}
              />
              {errors.body?.message && (
                <div className="mt-2 invalid-feedback">{errors.body.message}</div>
              )}
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <SaveSpinnerButton
            inactiveText="Request a Sample"
            activeText="Sending request..."
            isSaving={isSending}
            className="btn btn-primary"
          />
          <Button onClick={close}>Close</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

RequestSampleModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  productName: PropTypes.string.isRequired,
  productPrice: PropTypes.string,
  productCompanyName: PropTypes.string.isRequired,
  productSgid: PropTypes.string,
  productImageUrl: PropTypes.string.isRequired,
};

RequestSampleModal.defaultProps = {
  productPrice: undefined,
  productSgid: undefined,
  isOpen: false,
  close: () => {},
};

export default RequestSampleModal;
