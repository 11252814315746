import React from 'react';
import PropTypes from 'prop-types';
import ContactInfoReactInput from './contact_info_react_input';

function ContactInfosReactFields(props) {
  const { contact_infos: contactInfos, info_type: infoType } = props;
  const {
    contactable, contactableType, placeholder, refreshOpportunityContactEmployment,
  } = props;

  const fieldList = contactInfos
    .sort((a, b) => a.id - b.id)
    .map((info, i) => (
      <ContactInfoReactInput
        key={info.id || info.__private_id}
        contactInfo={info}
        contactable={contactable}
        contactableType={contactableType}
        placeholder={placeholder}
        info_type={infoType}
        number={i}
        refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
      />
    ));

  return (
    <div>
      {fieldList}
    </div>
  );
}

ContactInfosReactFields.propTypes = {
  contact_infos: PropTypes.arrayOf(Object).isRequired,
  contactable: PropTypes.instanceOf(Object),
  contactableType: PropTypes.string,
  info_type: PropTypes.string,
  placeholder: PropTypes.string,
  refreshOpportunityContactEmployment: PropTypes.func.isRequired,
};

ContactInfosReactFields.defaultProps = {
  contactable: undefined,
  contactableType: undefined,
  info_type: undefined,
  placeholder: undefined,
};

export default ContactInfosReactFields;
