import React, { useState } from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../javascript/vahoy';
import MuteModal from './mute_modal';
import vapi from '../../javascript/frontend/api/vapi';

function MuteButton({
                      muted_user_id: mutedUserId,
                      reporting_user_id: reportingUserId,
                      muted,
                      default_mute_duration_days: defaultMuteDurationDays,
                      button_font_size: buttonFontSize,
                    }) {
  const [showModal, setShowModal] = useState(false);
  const [durationDays, setDurationDays] = useState(`${defaultMuteDurationDays}`);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleClick = () => {
    setShowModal(true);
    vahoy.track('MuteButton#showModal');
  };

  const handleDurationDaysChange = (event) => {
    setDurationDays(event.target.value);
  };

  const submitReport = () => {
    const params = {
      // alphabetized
      duration_days: durationDays,
      muted_user_id: mutedUserId,
      reporting_user_id: reportingUserId,
    };

    vapi.muteUser(params)
      .then(() => {
        closeModal();
        window.location.reload();
      }).catch((error) => {
      alert('An error occurred while muting the user. Please try again later.');
      throw error; // Rethrow the error
    });

    vahoy.track('MuteModal#muteUser');
  };

  const renderButton = () => (
    <>

      {muted && buttonFontSize && (
        <a className="btn btn-small btn-outline-warning" style={{ fontSize: `${buttonFontSize}px` }} rel="nofollow" data-method="delete" href={`/users/${reportingUserId}/delete_mute?muted_user_id=${mutedUserId}`}>
          <i className="fa-solid fa-triangle-exclamation" />&nbsp;Unmute User
        </a>
      )}
      {muted && !buttonFontSize && (
        <a className="btn btn-small btn-outline-warning" rel="nofollow" data-method="delete" href={`/users/${reportingUserId}/delete_mute?muted_user_id=${mutedUserId}`}>
          <i className="fa-solid fa-triangle-exclamation" />&nbsp;Unmute User
        </a>
      )}
      {!muted && buttonFontSize && (
        <button
          className="btn btn-small btn-outline-warning"
          style={{ fontSize: `${buttonFontSize}px` }}
          onClick={handleClick}
          type="button"
        >
          <i className="fa-solid fa-volume-xmark" />&nbsp;Mute User
        </button>
      )}
      {!muted && !buttonFontSize && (
        <button
          className="btn btn-small btn-outline-warning"
          onClick={handleClick}
          type="button"
        >
          <i className="fa-solid fa-volume-xmark" />&nbsp;Mute User
        </button>
      )}
      <MuteModal
        close_modal={closeModal}
        handle_duration_days_change={handleDurationDaysChange}
        duration_days={durationDays}
        is_opening={showModal}
        show_modal={showModal}
        submit_report={submitReport}
        default_mute_duration_days={defaultMuteDurationDays}
      />
    </>
  );

  return renderButton();
}

MuteButton.propTypes = {
  muted_user_id: PropTypes.number,
  reporting_user_id: PropTypes.number,
  muted: PropTypes.bool,
  default_mute_duration_days: PropTypes.number.isRequired,
  button_font_size: PropTypes.number,
};

MuteButton.defaultProps = {
  muted_user_id: undefined,
  reporting_user_id: undefined,
  muted: false,
  button_font_size: undefined,
};

export default MuteButton;
