import React from 'react';
import {
  Panel,
  ToggleRefinement,
  RefinementList,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

function PublicationFilterPanel({ customClass }) {
  return (
    <div className={`card bg-transparent border-0 mb-3 ${customClass}`}>
      <div className="card-body ais-hide-counts">
        <Panel header="Filter by publication">
          <ToggleRefinement
            attribute="publications.tier_1_publication"
            label="Tier 1"
            value={true}
            defaultRefinement={false}
          />
          <ToggleRefinement
            attribute="publications.tier_2_publication"
            label="Tier 2"
            value={true}
            defaultRefinement={false}
          />
          <RefinementList
            attribute="publications.name"
            limit={6}
            showMore={true}
            showMoreLimit={15}
            searchable={true}
          />
        </Panel>
      </div>
    </div>
  );
}

PublicationFilterPanel.propTypes = {
  customClass: PropTypes.instanceOf(Object),
};

PublicationFilterPanel.defaultProps = {
  customClass: undefined,
};

export default PublicationFilterPanel;
