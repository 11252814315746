import React from 'react';
import { Panel } from 'react-instantsearch-dom';
import RefinementListWithoutCount from '../../refinement_list_without_count';

function MediumFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by medium">
          <RefinementListWithoutCount
            attribute="request_sub_type_text_filtered"
          />
        </Panel>
      </div>
    </div>
  );
}

export default MediumFilterPanel;
