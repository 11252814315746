import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row,
} from 'reactstrap';
import vahoy from '../../javascript/vahoy';
import MediaProfile from './media_profile';
import Request from './request';
import vlog from '../../javascript/vlog';

const PITCH_GRACE_PHASE_DAYS = 30;

function RelatedRequests({
                           match_text: matchText,
                           num_to_display: numToDisplay,
                           pitch_id: pitchId,
                           request_id: requestId,
                         }) {
  const [v2SimilarRequests, setV2SimilarRequests] = useState([]);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const apiUrl = `${window.V2_URL}/match_feed/api/similarity_search?query=${matchText}&search_model_types=Interact%3A%3ARequest&limit=15`;

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        Apikey: window.SUPABASE_ANON_KEY,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.data?.results) {
          setV2SimilarRequests(response.data?.results);
        } else {
          vlog.warn('No results found');
        }
      })
      .catch((error) => {
        vlog.error(apiUrl, error.toString());
      });
  }, [matchText, numToDisplay]);

  useEffect(() => {
    const fetchRequests = async (idFromGid) => {
      try {
        const { data, error } = await window.supabaseClient
          .from('requests')
          .select('*')
          .eq('id', idFromGid)
          .neq('migrate_id', requestId); // Exclude the current request

        if (error) {
          vlog.error('Error fetching request:', error.message);
          return;
        }

        if (data.length > 0) {
          setRequests((prevRequests) => [...prevRequests, data[0]]);
        } else {
          vlog.warn('No request data found for ID:', idFromGid);
        }
      } catch (err) {
        vlog.error('Unexpected error fetching request:', err);
      }
    };

    if (v2SimilarRequests.length > 0) {
      v2SimilarRequests.forEach((similarRequest) => {
        const gid = similarRequest.model_gid;
        const idFromGid = gid.split('/').pop();
        fetchRequests(idFromGid);
      });
    }
  }, [v2SimilarRequests, requestId]);

  const trackClicks = () => {
    vahoy.track(`RelatedRequests#CTA_click; pitch_id:${pitchId}`);
  };

  const isExpired = (request) => {
    const deadlineDate = new Date(request.deadline_at);
    const currentDate = new Date();
    const differenceInDays = (currentDate - deadlineDate) / (1000 * 3600 * 24);
    return differenceInDays > PITCH_GRACE_PHASE_DAYS;
  };

  return (
    <Container className="px-0">
      <Row>
        {requests && requests.map((request) => (
          request.id !== requestId && (isExpired(request) ? (
            <MediaProfile
              key={request.id}
              mediaOutletName={request.media_outlet_name}
              mediaOutletProfileImageUrl={request.media_outlet_profile_image_url}
              mediaProfileId={request.media_profile_id}
              previousRequestId={request.migrate_id}
              previousRequestTitle={request.title}
              trackClicks={trackClicks}
            />
          ) : (
            <Request key={request.id} request={request} trackClicks={trackClicks} />
          ))
        ))}
      </Row>
    </Container>
  );
}

RelatedRequests.propTypes = {
  match_text: PropTypes.string.isRequired,
  num_to_display: PropTypes.number.isRequired,
  pitch_id: PropTypes.number.isRequired,
  request_id: PropTypes.number.isRequired,
};

export default RelatedRequests;
