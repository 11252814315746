import React from 'react';
import PropTypes from 'prop-types';

function DowngradeWarning(props) {
  const {
    newSourcePlan,
    oldSourcePlan,
    oldPlanPitches,
    oldPlanUnlimited,
    oldPlanDiscontinued,
    nextBillDateString,
    willLoseDiscount,
  } = props;
  const PLAN_FEATURES = [];

  // Free plan
  PLAN_FEATURES[0] = [<span>Daily "roundup" email of new requests</span>];

  // Paid (Plus) plan -- KT Dec 2021 NB: This plan is retired, however note it's used in the programming additively as
  // a component of the Pro & Enterprise (Teams) features.
  PLAN_FEATURES[1] = PLAN_FEATURES[0].concat([
    <span>Pitch at speed, ahead of the competition</span>,
    <span>Media search</span>,
    <span><strong>Real-time alerts</strong> for opportunities that match your expertise</span>,
    <span>Pitch intelligence</span>,
    <span>Access to <strong>Media Moves Database</strong></span>,
  ]);

  // PR Team (Pro) plan
  PLAN_FEATURES[2] = PLAN_FEATURES[1].concat([
    <span>Onboarding call with training and support</span>,
    <span>Access to <strong>Events & Awards Database</strong></span>,
    <span>Expert profile view notifications</span>,
    <span>Expert profiles appear in Media users' Expert Database</span>,
  ]);

  // Client Firm (Enterprise) plan
  PLAN_FEATURES[3] = PLAN_FEATURES[2].concat([
    <span>Team functionality</span>,
    <span>White labeling</span>,
    <span>Weekly activity report</span>,
    <span>Access to <strong>Administrative Dashboard</strong></span>,
    <span>Access to <strong>Tips & Templates Library</strong></span>,
    <span>Premium support</span>,
  ]);

  const missingFeatures = PLAN_FEATURES[oldSourcePlan]
    .filter((f) => !PLAN_FEATURES[newSourcePlan].includes(f))
    .concat(PLAN_FEATURES[newSourcePlan].filter((f) => !PLAN_FEATURES[oldSourcePlan].includes(f)));

  const pitches = oldPlanUnlimited ? 'unlimited' : oldPlanPitches;

  const discontinuedWarning = oldPlanDiscontinued && (
    <p className="my-2 fw-bold">
      IMPORTANT: Your current plan has been discontinued.&nbsp;
      If you downgrade now, you'll permanently lose your special legacy plan pricing.&nbsp;
      <em>
        If you decide to upgrade again in the future, you'll have to choose one of our more expensive plans.
      </em>
    </p>
  );

  const discountLossWarning = willLoseDiscount && (
    <p className="my-2 fw-bold">
      IMPORTANT: Your account has a discounted price.&nbsp;
      If you downgrade now, you'll permanently lose your discounted plan pricing.&nbsp;
      <em>
        If you decide to upgrade again in the future, you'll have to pay full price.
      </em>
    </p>
  );

  return (
    <div className="row">
      <div className="col">
        <div className="alert alert-warning" role="alert">
          <h3 className="alert-heading">
            <i className="fa fa-triangle-exclamation text-danger animated flash me-2" aria-hidden="true" />
            <span className="fw-bold">Warning</span>
          </h3>
          <p className="my-2">If you downgrade your plan, you'll lose access to the following features
            after {nextBillDateString}:
          </p>
          <ul>
            <li><strong>{pitches} pitches</strong> per month</li>
            {missingFeatures.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          {discontinuedWarning}
          {discountLossWarning}
        </div>
      </div>
    </div>
  );
}

DowngradeWarning.propTypes = {
  oldSourcePlan: PropTypes.number.isRequired,
  newSourcePlan: PropTypes.number.isRequired,
  oldPlanPitches: PropTypes.number.isRequired,
  nextBillDateString: PropTypes.string.isRequired,
  oldPlanUnlimited: PropTypes.bool,
  oldPlanDiscontinued: PropTypes.bool,
  willLoseDiscount: PropTypes.bool,
};

DowngradeWarning.defaultProps = {
  oldPlanUnlimited: false,
  oldPlanDiscontinued: false,
  willLoseDiscount: false,
};

export default DowngradeWarning;
