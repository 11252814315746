import React from 'react';
import { Panel, RefinementList } from 'react-instantsearch-dom';

function SourceProfileCompletionFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body ais-hide-counts">
        <Panel header="Filter by source profile completion">
          <RefinementList attribute="source_profile_completion_score_slug" limit={3} operator="or" />
        </Panel>
      </div>
    </div>
  );
}

export default SourceProfileCompletionFilterPanel;
