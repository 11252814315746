import React from 'react';
import PropTypes from 'prop-types';

function SubmittedPitchesBadge(props) {
  const {
    submittedPitchesSize = {},
  } = props;

  const spString = submittedPitchesSize;
  const spTooltip = `# of submitted pitches: ${spString}`;

  return (
    <div
      className="position-absolute"
      style={{
        top: '-8px',
        right: '-5px',
      }}
    >
      <span
        className="badge rounded-pill bg-primary"
        style={{
          fontSize: '20px',
        }}
        data-bs-toggle="tooltip"
        title={spTooltip}
      >
        <strong>{spString}</strong>
      </span>
    </div>
  );
}

SubmittedPitchesBadge.propTypes = {
  submittedPitchesSize: PropTypes.number.isRequired,
};

export default SubmittedPitchesBadge;
