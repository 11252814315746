import React from 'react';
import PropTypes from 'prop-types';
import { Highlight } from 'react-instantsearch-dom';

function SmartHighlight(props) {
  const { attribute, hit } = props;

  if (Object.prototype.hasOwnProperty.call(hit, '_highlightResult')) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Highlight {...props} />
    );
  }

  return hit[attribute];
}

SmartHighlight.propTypes = {
  attribute: PropTypes.string.isRequired,
  hit: PropTypes.instanceOf(Object).isRequired,
  tagName: PropTypes.string,
  nonHighlightedTagName: PropTypes.string,
  separator: PropTypes.node,
};

SmartHighlight.defaultProps = {
  tagName: undefined,
  nonHighlightedTagName: undefined,
  separator: undefined,
};

export default SmartHighlight;
