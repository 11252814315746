import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane } from 'reactstrap';
import { CompanyContext } from '../data/company_context_management';
import CompanyExperts from './company_experts';
import CompanyProducts from './company_products';
import CompanyPrTeam from './company_pr_team';
import CompanyBrands from './company_brands';

function CompanyTabContent({ activeTab }) {
  const { company, setOpenEditModal } = useContext(CompanyContext);

  const renderModalButton = (tab) => (
    <div className="d-flex flex-row justify-content-end align-items-center w-100 pb-4 pt-2">
      <button
        type="button"
        className="btn btn-primary btn-sm ms-auto"
        style={{
          maxHeight: '2rem',
          maxWidth: '2rem',
        }}
        onClick={() => setOpenEditModal(tab)}
      >
        <i className="fa-solid fa-pencil cursor-pointer" />
        &nbsp;
      </button>
    </div>
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <TabContent activeTab={activeTab} className="col position-relative px-0">
          <TabPane tabId="experts">
            <CompanyExperts prFirmView={company.display_as_pr_firm} />
          </TabPane>
          <TabPane tabId="products">
            <CompanyProducts prFirmView={company.display_as_pr_firm} />
          </TabPane>
          {company.display_as_pr_firm && company.show_pr_users && (
            <TabPane tabId="team">
              <CompanyPrTeam />
            </TabPane>
          )}
          {company.display_as_pr_firm && company.show_brands && (
            <TabPane tabId="brands">
              <CompanyBrands />
            </TabPane>
          )}
          <TabPane tabId="about">
            {company?.editable && renderModalButton('MAIN')}
            <div className="card p-4" style={{ whiteSpace: 'pre-line', fontSize: '1.15rem' }}>
              {company.about}
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}

CompanyTabContent.propTypes = {
  activeTab: PropTypes.string,
};

CompanyTabContent.defaultProps = {
  activeTab: undefined,
};

export default CompanyTabContent;
