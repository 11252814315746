import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';

function SelectHashtags({ sourceRequestId, onHashtagAdded }) {
  const hashtagsEditableRef = useRef(null);
  const hashtagSelectRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  function formatHashtagResult(hashtag) {
    if (!hashtag.id && !hashtag.text) {
      // This handles the case when there are no meaningful values to display.
      return 'Loading...'; // Default text when waiting for data
    }
    if (hashtag.loading) {
      return 'Loading...'; // While data is loading
    }
    // Display the actual hashtag if present
    return hashtag.text || hashtag.attributes.hashtag;
  }

  useEffect(() => {
    function saveHashtag(hashtag) {
      if (!hashtag) {
        onHashtagAdded();
        return;
      }

      vapi.createHashtagTag(hashtag, sourceRequestId, 'SourceRequest', 'hashtag_tags')
        .then(() => {
          setErrorMessage('');
          $(hashtagSelectRef.current).val(null).trigger('change');
          onHashtagAdded();
          vahoy.track('Approval#createHashtagTag');
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            const { errors } = error.response.data;
            const firstError = errors[0];
            setErrorMessage(`${hashtag.text} ${firstError.title}`);
          } else {
            // Fallback error message if the error structure is not as expected
            setErrorMessage(`Failed to save hashtag ${hashtag.text}.`);
          }
          throw error; // Re-throw the error if you need further error handling or logging
        });
    }

    const $hashtagsSelect = $(hashtagSelectRef.current).select2({
      placeholder: 'Type to search for hashtag ...',
      ajax: {
        url: '/api/internal/jsonapi/hashtags',
        dataType: 'json',
        delay: 250,
        data: (params) => ({ filter: { name: params.term.replace('#', '') } }),
        processResults: (response) => {
          if (response.data.length === 0) {
            // Return a special format when no hashtags are found
            return { results: [{ id: '', text: 'No hashtags found!' }] };
          }
          // Map the response data to a format usable by Select2
          return {
            results: response.data.map((item) => ({
              id: item.id,
              text: item.attributes.hashtag,
            })),
          };
        },
      },
      tags: false,
      minimumInputLength: 1,
      dropdownParent: $(hashtagsEditableRef.current),
      templateResult: formatHashtagResult,
    });

    $hashtagsSelect.on('select2:select', (e) => {
      const hashtag = e.params.data;
      saveHashtag(hashtag);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={hashtagsEditableRef} className="hashtags-editable">
      <select ref={hashtagSelectRef} style={{ width: '100%' }} aria-label="Select hashtags" />
      <div className="text-danger">
        {errorMessage}
      </div>
    </div>
  );
}

SelectHashtags.propTypes = {
  sourceRequestId: PropTypes.number.isRequired,
  onHashtagAdded: PropTypes.func.isRequired,
};

export default SelectHashtags;
