import React from 'react';
import PropTypes from 'prop-types';

function AttachedNewEntity({
                             localId,
                             name,
                             removeCallback,
                             subheading,
                             type,
                           }) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      removeCallback(localId);
    }
  };

  return (
    <div className="alert alert-secondary p-2 mt-2 me-2 mb-0 d-inline-flex flex-row align-items-center">
      <div className="">
        <div>{name}</div>
        <div className="small">{subheading}</div>
        <div className="mt-1 badge rounded-pill text-bg-secondary">New {type}</div>
      </div>
      <div
        className="btn btn-link p-0 ps-2 text-secondary"
        onClick={() => removeCallback(localId)}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <i className="fa-solid fa-xmark" />
      </div>
    </div>
  );
}

AttachedNewEntity.propTypes = {
  localId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  removeCallback: PropTypes.func.isRequired,
  subheading: PropTypes.string,
  type: PropTypes.string.isRequired,
};

AttachedNewEntity.defaultProps = {
  subheading: undefined,
};

export default AttachedNewEntity;
