/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

function SaveSpinnerButton({
                             inactiveText,
                             activeText,
                             isSaving,
                             disabled,
                             ...props
                           }) {
  return (
    <button
      type="submit"
      {...props}
      disabled={props.disabled || isSaving}
    >
      <Spinner
        color="light"
        className="me-2"
        style={{
          width: '1.25rem',
          height: '1.25rem',
          display: `${isSaving ? 'inline-block' : 'none'}`,
        }}
      />
      {isSaving ? activeText : inactiveText}
    </button>
  );
}

export default SaveSpinnerButton;

SaveSpinnerButton.propTypes = {
  inactiveText: PropTypes.string,
  activeText: PropTypes.string,
  isSaving: PropTypes.bool,
  disabled: PropTypes.bool,
};

SaveSpinnerButton.defaultProps = {
  inactiveText: 'Save',
  activeText: 'Saving',
  isSaving: false,
  disabled: false,
};
