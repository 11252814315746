import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import CompanyTabHeader from './company_tab_header';
import CompanyTabContent from './company_tab_content';
import CompanyHeader from './company_header';
import Sidebar from './sidebar';
import EditModal from '../edit/edit_modal';
import { CompanyContext } from '../data/company_context_management';
import ShowPageSkeleton from './show_page_skeleton';

function CompanyShow({ companyId, userId }) {
  const [activeTab, setActiveTab] = useState('experts');
  const {
    setCompanyId,
    topLevelIsLoading,
    asyncError,
    aboutSectionHidden,
    company,
  } = useContext(CompanyContext);

  useEffect(() => {
    setCompanyId(companyId);
  }, [companyId, setCompanyId]);

  // Switch to first tab if the about section tab is active and that section gets hidden
  useEffect(() => {
    if (aboutSectionHidden && activeTab === 'about') setActiveTab('experts');
  }, [aboutSectionHidden, activeTab]);

  // Switch to the first tab if the team section tab is active and the company switches to standard view
  useEffect(() => {
    if (activeTab === 'team' && !company.display_as_pr_firm) setActiveTab('experts');
  }, [activeTab, company.display_as_pr_firm]);

  // Switch to the first tab if the Our PR Team or Our Brands tab is active and that tab gets hidden
  useEffect(() => {
    if (!company.show_pr_users && activeTab === 'team') setActiveTab('experts');
    if (!company.show_brands && activeTab === 'brands') setActiveTab('experts');
  }, [company, activeTab]);

  const toggle = (tab) => {
    activeTab !== tab ? setActiveTab(tab) : null;
  };

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  return (
    <div>
      <EditModal />

      {topLevelIsLoading && (
        <ShowPageSkeleton />
      )}

      {!topLevelIsLoading && (
        <div className="row">
          <div className="col mb-3">
            <CompanyHeader />
            <div className="mt-4">
              <CompanyTabHeader activeTab={activeTab} toggle={toggle} />
              <CompanyTabContent activeTab={activeTab} />
            </div>
          </div>
          <Sidebar userId={userId} />
        </div>
      )}
    </div>
  );
}

CompanyShow.propTypes = {
  companyId: PropTypes.string.isRequired,
  userId: PropTypes.string,
};

CompanyShow.defaultProps = {
  userId: undefined,
};

export default CompanyShow;
