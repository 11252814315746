import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';

function TextFieldListForArray({
 formFor, fieldName, fieldClass, values, placeholder,
}) {
  const autoAddField = (theFields) => {
    const emptyFields = theFields.filter((f) => !f.value);

    if (emptyFields.length === 0) {
      theFields.push({ internalRef: _uniqueId(), unused: true });
    }

    return theFields;
  };

  const valueToField = (value) => ({ value, internalRef: _uniqueId(), unused: false });
  const [fields, setFields] = useState(autoAddField(values.map(valueToField)));

  const updateFieldsState = (newState) => {
    setFields(autoAddField(newState));
  };

  const handleInfoInputChange = (e, changedField) => {
    const updatedFields = fields.map((f) => {
      if (f === changedField) return { ...f, value: e.target.value, unused: false };
      return f;
    });

    updateFieldsState(updatedFields);
  };

  const removeField = (field) => {
    updateFieldsState(fields.filter((info) => info !== field));
  };

  return (
    <>
      {/* This allow us to persist an empty list (requires custom setter in the model) */}
      <input type="hidden" name={`${formFor}[${fieldName}][]`} value="" />

      {fields.map((field, i) => (
        <div key={field.internalRef} className="d-flex mt-1 mb-2">
          <input
            className={`${fieldClass} flex-fill me-3`}
            type="text"
            defaultValue={field.value}
            name={field.unused ? '' : `${formFor}[${fieldName}][]`}
            onChange={(e) => handleInfoInputChange(e, field)}
            placeholder={placeholder || `New ${fieldName}`}
            id={`field--${i}`}
          />
          <button
            type="button"
            className={`btn btn-sm btn-outline-danger mb-1 ${field.unused ? 'invisible' : ''}`}
            onClick={() => removeField(field)}
          >
            <i className="fa-solid fa-trash-can" />
          </button>
        </div>
      ))}
    </>
  );
}

TextFieldListForArray.propTypes = {
  values: PropTypes.arrayOf(Object).isRequired,
  fieldName: PropTypes.string,
  fieldClass: PropTypes.string,
  formFor: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

TextFieldListForArray.defaultProps = {
  fieldName: undefined,
  fieldClass: undefined,
  placeholder: undefined,
};

export default TextFieldListForArray;
