import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { connectRefinementList } from 'react-instantsearch-dom';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

/* eslint-disable max-len, no-shadow, no-plusplus, no-return-assign */
/* eslint-disable no-use-before-define, func-names, react/no-this-in-sfc */

function BubbleChart({
  items,
  refine,
  width = 1000,
  height = 200,
  labelFontSize = 5,
  enablePopoverTooltip = true,
}) {
  const divRef = useRef(null);
  const baseColor = 'rgb(113,96,233)';
  const hoverColor = 'rgb(217,64,40)';
  const textColor = 'lavender';

  useEffect(() => {
    if (items.length === 0) return;

    const div = d3.select(divRef.current);
    div.selectAll('*').remove();

    const radius = Math.min(width * 5, height / 1.2) / 2.5;
    const maxBubbleSize = Math.min(width, height) / 7;

    const svg = div
      .append('svg')
      .attr('viewBox', [-(width / 10.2), 0, width * 1.2, height * 0.72])
      .attr('width', '100%')
      .attr('style', 'margin-left: 0%;');

    const g = svg
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 3})`);

    function getBubbleSize(count) {
      const maxCount = d3.max(items, (d) => d.count);
      return Math.sqrt((count / maxCount)) * maxBubbleSize;
    }

    function getColor(count) {
      const maxCount = d3.max(items, (d) => d.count);
      const minCount = d3.min(items, (d) => d.count);
      const scale = d3.scaleLinear().domain([minCount, maxCount]).range([d3.rgb(baseColor).brighter(), d3.rgb(baseColor).darker()]);
      return scale(count);
    }

    function forceBoundary(radius) {
      let nodes;

      function force() {
        for (let i = 0, n = nodes.length; i < n; ++i) {
          const node = nodes[i];
          const distance = Math.sqrt(node.x * node.x + node.y * node.y);
          if (distance > radius) {
            const angle = Math.atan2(node.y, node.x);
            node.x = Math.cos(angle) * radius;
            node.y = Math.sin(angle) * radius;
          }
        }
      }

      force.initialize = (_) => nodes = _;

      return force;
    }

    function ticked() {
      const u = g
        .selectAll('g.bubble-group')
        .data(items)
        .join('g')
        .attr('class', 'bubble-group')
        .attr('transform', (d) => `translate(${d.x}, ${d.y})`)
        .on('click', handleCellClick);

      u.selectAll('g.content')
        .data((d) => [d])
        .join('g')
        .attr('class', 'content');

      u.selectAll('g.hover-area')
        .data((d) => [d])
        .join('g')
        .attr('class', 'hover-area');

      u.select('g.content')
        .selectAll('circle.visible-circle')
        .data((d) => [d])
        .join('circle')
        .attr('class', 'visible-circle')
        .attr('r', (d) => getBubbleSize(d.count))
        .attr('fill', (d) => getColor(d.count))
        .attr('stroke', 'white')
        .attr('stroke-width', 1);

      u.select('g.content')
        .selectAll('foreignObject')
        .data((d) => [d])
        .join('foreignObject')
        .attr('x', (d) => -getBubbleSize(d.count))
        .attr('y', (d) => -getBubbleSize(d.count) / 2)
        .attr('width', (d) => getBubbleSize(d.count) * 2)
        .attr('height', (d) => getBubbleSize(d.count))
        .html((d) => `
          <div style="width: 100%; height: 80%; display: flex; justify-content: center; align-items: center; color: ${textColor}; font-size: ${labelFontSize}px; text-align: center; pointer-events: none;">
            ${d.label.replace('#', '')}
          </div>
        `);

      const hoverArea = u.select('g.hover-area')
        .selectAll('circle.hover-circle')
        .data((d) => [d])
        .join('circle')
        .attr('class', 'hover-circle')
        .attr('r', (d) => getBubbleSize(d.count))
        .attr('fill', 'transparent')
        .style('cursor', 'pointer')
        .on('mouseover', handleMouseOver)
        .on('mouseout', handleMouseOut);

      if (enablePopoverTooltip) {
        hoverArea.each(function (d) {
          tippy(this, {
            content: `<span class="bg-white rounded p-2 border">${d.label}</span>`,
            allowHTML: true,
          });
        });
      }
    }

    function handleMouseOver() {
      const bubbleGroup = d3.select(this.parentNode.parentNode);
      bubbleGroup.select('circle.visible-circle').attr('fill', hoverColor);
    }

    function handleMouseOut(event, d) {
      const bubbleGroup = d3.select(this.parentNode.parentNode);
      bubbleGroup.select('circle.visible-circle').attr('fill', getColor(d.count));
    }

    function handleCellClick(event, d) {
      const url = new URL(window.location.href);
      const cleanedValue = d.value;
      url.searchParams.set('query', cleanedValue);
      window.location.href = url.toString();
    }

    const simulation = d3.forceSimulation(items)
      .force('x', d3.forceX().strength(0.1))
      .force('y', d3.forceY().strength(0.3))
      .force('charge', d3.forceManyBody().strength(5))
      .force('collision', d3.forceCollide().radius((d) => getBubbleSize(d.count) + 5))
      .force('boundary', forceBoundary(radius))
      .on('tick', ticked);

      return () => simulation.stop();
  }, [items, refine, width, height, labelFontSize, enablePopoverTooltip]);

  return <div ref={divRef} style={{ width: '100%', height: '50%' }} />;
}

BubbleChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    }),
  ).isRequired,
  refine: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  labelFontSize: PropTypes.number,
  enablePopoverTooltip: PropTypes.bool,
};

BubbleChart.defaultProps = {
  width: 500,
  height: 500,
  labelFontSize: 7,
  enablePopoverTooltip: true,
};

const ConnectedBubbleChart = connectRefinementList(BubbleChart);

export default ConnectedBubbleChart;
