import React from 'react';
import Skeleton from 'react-loading-skeleton';

function PressReleaseSkeletonCard() {
  return (
    <div className="card h-100 skeleton-card animated faster fadeIn">
      <div className="card-body position-relative">
        {/* Title of Press Release */}
        <div className="mb-3">
          <Skeleton height={32} width="100%" />
        </div>

        {/* Snippet of Press Release */}
        <div className="mb-3">
          <Skeleton height={96} width="100%" />
        </div>

        {/* Hashtags of Press Release */}
        <div className="mb-3">
          <Skeleton height={10} width={80} className="p-1 mb-1 me-1" />
          <Skeleton height={10} width={120} className="p-1 mb-1 me-1" />
          <Skeleton height={10} width={100} className="p-1 mb-1 me-1" />
          <Skeleton height={10} width={140} className="p-1 mb-1 me-1" />
          <Skeleton height={10} width={80} className="p-1 mb-1 me-1" />
        </div>

        <div className="clearfix" style={{ minHeight: '4rem' }} />

        {/* Meta data */}
        <div
          className="card-footer p-0 pb-2 bg-transparent border-0 position-absolute w-100"
          style={{ bottom: 0 }}
        >
          <Skeleton height={10} width={150} className="mt-1" />
          <br />
          <Skeleton height={10} width={150} className="mt-1" />
          <br />
          <Skeleton height={10} width={150} className="mt-1" />
        </div>

      </div>

    </div>
  );
}

export default PressReleaseSkeletonCard;
