import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom';

import ClearRefinementsButton from '../../algolia_search/clear_refinements_button';
import HashtagFilterPanel from './hashtag_filter_panel';
import InfiniteHits from './infinite_hits';
import Insights from './insights/insights_full_view/insights';
import InsightCards from './insights/insight_cards/insight_cards';
import LockedFilterPanel from '../../algolia_search/locked_filter_panel';
import MediumFilterPanel from './medium_filter_panel';
import PitchFilterPanel from './pitch_filter_panel';
import PublicationFilterPanel from './publication_filter_panel';
import RegionFilterPanel from './region_filter_panel';
import ReporterFilterPanel from './reporter_filter_panel';
import SmartStats from '../../algolia_search/smart_stats';
import StatusFilterPanel from './status_filter_panel';
import TabbedNavigation from '../../tabbed_navigation';
import ToggleSwitch from '../../toggle_switch';
import TypeFilterPanel from './type_filter_panel';
import useClientFeaturesToken from '../../hooks/use_client_features_token';
import useLocalStorageState from '../../hooks/use_local_storage_state';
import vahoy from '../../../javascript/vahoy';

function SourceRequestsSearch({
  query,
  featuresToken,
  algoliaAppId,
  algoliaSearchKey,
  indexName,
  reporterIndexName,
  canPitchConnectionOpps,
  showAds,
  view,
  delayedFeed,
  feedDelayMinutes,
  canViewInsights,
}) {
  const startingTab = view && view === 'all' ? 'ALL' : 'SEARCH';
  const [activeTab, setActiveTab] = useState(startingTab);
  const [currentQuery, setCurrentQuery] = useState(query);
  const [expandedChart, setExpandedChart] = useState(null);

  const [smallMode, setSmallMode] = useLocalStorageState(false, 'smallModeSourceRequestCards');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSmallModeToggle = () => {
    setSmallMode((currentMode) => !currentMode);
  };

  const handleExpandChart = (chart) => {
    setExpandedChart(chart);
    vahoy.track(`InsightsExpanded#${chart}`);
  };

  const handleCollapseChart = () => {
    setExpandedChart(null);
  };

  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);
  const showReporterFilter = useClientFeaturesToken(featuresToken, 'REPORTER_FILTER');
  const showPitchFilter = useClientFeaturesToken(featuresToken, 'PITCH_FILTER');

  // For when reporter filtering isn't allowed
  const lockedReporterFilter = (
    <LockedFilterPanel
      panelTitle="Filter by reporter"
      planName="ENTERPRISE"
      filterPlaceholders={[
        { name: 'John Doe', count: 10 },
        { name: 'Bonnie Smith', count: 8 },
        { name: 'Edmund Tam', count: 7 },
        { name: 'Carl Louzo', count: 3 },
        { name: 'Chris Sarota', count: 2 },
        { name: 'Nora Giraldi', count: 1 },
      ]}
    />
  );

  // For when pitch count filtering isn't allowed
  const lockedPitchFilter = (
    <LockedFilterPanel
      panelTitle="Filter by # of pitches"
      planName="PRO"
      showSearchBox={false}
      showMoreButton={false}
      filterPlaceholders={[
        { name: '0', count: 20 },
        { name: '1 to 5', count: 25 },
        { name: '6 to 10', count: 30 },
        { name: '11 to 24', count: 40 },
        { name: '25 or more', count: 50 },
      ]}
    />
  );

  const tabs = [
    {
 id: 'ALL', display: 'All Opportunities', href: '/source_requests?view=all', icon: 'globe',
},
    {
 id: 'MEDIAMATCHER', display: 'Media Matcher', href: '/media_matches', icon: 'users',
},
    {
 id: 'FREE', display: 'Free to Pitch', href: '/source_requests?view=free', icon: 'bolt',
},
    {
 id: 'SAVED', display: 'Saved', href: '/source_requests?view=saved', icon: 'bookmark',
},
    {
 id: 'PITCHED', display: 'Pitched', href: '/source_requests?view=pitched', icon: 'messages',
},
    {
 id: 'SEARCH', display: 'Advanced Search', href: '/source_requests/search', icon: 'magnifying-glass',
},
  ];

  const renderExpandedChart = () => (
<Insights
  query={currentQuery}
  expandedChart={expandedChart}
  onCollapse={handleCollapseChart}
/>
);

  const feedDelayMinutesAgo = Math.floor(Date.now() / 1000) - 60 * feedDelayMinutes;

  return (
    <div className="ais-InstantSearch">
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        {delayedFeed && <Configure filters={`published_at_int < ${feedDelayMinutesAgo}`} />}
        <div className="d-flex flex-wrap pt-3 border-top border-bottom">
          <div className="flex-fill flex-shrink-0 flex-grow-0 mb-3 me-5">
            <TabbedNavigation tabs={tabs} activeTab={activeTab} onTabChange={(tab) => handleTabChange(tab)} />
          </div>
          <div className="flex-fill flex-column flex-sm-row flex-shrink-0 d-flex">
            <div className="flex-fill mb-3 ps-2 d-flex">
              <SearchBox
                autoFocus
                translations={{
                  placeholder: 'Search',
                }}
                searchAsYouType={true}
                defaultRefinement={query}
                onChange={(e) => setCurrentQuery(e.currentTarget.value)}
                className="d-flex me-3"
              />
              <ClearRefinementsButton
                buttonText="Clear"
                clearsQuery={true}
                bootstrapClasses="px-3 btn btn-sm btn-outline-info text-nowrap"
              />
            </div>
            <div className="mb-3 ps-2 d-flex">
              <ToggleSwitch label="Condensed View" value={smallMode} onChange={handleSmallModeToggle} />
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col pe-4 text-end flex">
            <SmartStats />
          </div>
        </div>
        <div className="row">
          {canViewInsights && (
            <div className="mb-2" style={{ marginLeft: '0px', marginRight: '0px' }}>
              <InsightCards
                query={currentQuery}
                expandedChart={expandedChart}
                onExpand={handleExpandChart}
                onCollapse={handleCollapseChart}
              />
            </div>
)}
          {(view !== 'all' || expandedChart) && (
            <div className="col-12 col-md-3 pe-0 d-none d-md-block">
              <TypeFilterPanel />
              <StatusFilterPanel />
              <MediumFilterPanel />
              <RegionFilterPanel />
              <HashtagFilterPanel />
              <PublicationFilterPanel />
              {showPitchFilter ? <PitchFilterPanel /> : lockedPitchFilter}
              {showReporterFilter ? <ReporterFilterPanel /> : lockedReporterFilter}
            </div>
          )}
          <div className={`col col-12 ${((view !== 'all' || expandedChart)) ? 'col-md-9' : 'col-md-12'}`}>
            {expandedChart ? (
              <div className="row justify-content-center">
                {renderExpandedChart()}
              </div>
            ) : (
              <>
                <InfiniteHits
                  smallMode={smallMode}
                  query={currentQuery}
                  canPitchConnectionOpps={canPitchConnectionOpps}
                  algoliaAppId={algoliaAppId}
                  algoliaSearchKey={algoliaSearchKey}
                  reporterIndexName={reporterIndexName}
                  showAds={showAds}
                  view={view}
                />
              </>
            )}
          </div>
          {(view !== 'all' || expandedChart) && (
            <div className="col-12 col-md-3 pe-0 mt-4 d-block d-md-none">
              <TypeFilterPanel />
              <StatusFilterPanel />
              <MediumFilterPanel />
              <RegionFilterPanel />
              <HashtagFilterPanel />
              <PublicationFilterPanel />
              {showPitchFilter ? <PitchFilterPanel /> : lockedPitchFilter}
              {showReporterFilter ? <ReporterFilterPanel /> : lockedReporterFilter}
            </div>
          )}
        </div>
      </InstantSearch>
    </div>
  );
}

SourceRequestsSearch.propTypes = {
  query: PropTypes.string,
  featuresToken: PropTypes.string.isRequired,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  reporterIndexName: PropTypes.string,
  canPitchConnectionOpps: PropTypes.bool,
  view: PropTypes.string,
  showAds: PropTypes.bool,
  delayedFeed: PropTypes.bool,
  feedDelayMinutes: PropTypes.number,
  canViewInsights: PropTypes.bool,
};

SourceRequestsSearch.defaultProps = {
  query: '',
  canPitchConnectionOpps: true,
  view: '',
  showAds: false,
  reporterIndexName: '',
  delayedFeed: false,
  feedDelayMinutes: 90,
  canViewInsights: false,
};

export default SourceRequestsSearch;
