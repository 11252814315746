/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalBody, ModalFooter } from 'reactstrap';
import * as yup from 'yup';
import { CompanyContext } from '../data/company_context_management';
import useCompanyUpdateApi from '../data/use_company_update_api';
import SaveSpinnerButton from '../../save_spinner_button';
import companySchema from '../../../javascript/frontend/yup_schemas/company_schema';

function CompanyTwitterFeedForm() {
  const context = useContext(CompanyContext);

  const {
    company,
    isUpdating,
    setOpenEditModal,
    setGeneralError,
  } = context;

  const { updateCompany } = useCompanyUpdateApi({ ...context });

  const reducedSchema = yup.object()
    .shape({
      primary_twitter_url: companySchema.fields.primary_twitter_url,
      show_twitter_feed: companySchema.fields.show_twitter_feed,
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(reducedSchema),
  });

  const closeModal = () => {
    setOpenEditModal(null);
    setGeneralError(null);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    updateCompany(data);
  };

  return (
    <>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3 row">
            <label
              htmlFor="primary_twitter_url"
              className="col-sm-3 col-form-label"
            >
              Twitter URL
            </label>
            <div className="col-sm-9">
              <input
                className="form-control"
                defaultValue={company.primary_twitter_url}
                id="primary_twitter_url"
                placeholder="https://www.twitter.com/example"
                type="text"
                {...register('primary_twitter_url')}
              />
              {errors?.primary_twitter_url && (
                <span className="text-danger">
                  {errors?.primary_twitter_url?.message}
                </span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <div className="col-12">
              <div className="form-check form-switch">
                <input
                  defaultChecked={company.show_twitter_feed}
                  className="form-check-input"
                  disabled={isUpdating}
                  id="show_twitter_feed"
                  type="checkbox"
                  {...register('show_twitter_feed')}
                />
                <label
                  className="form-check-label"
                  htmlFor="show_twitter_feed"
                >
                  Show twitter feed in the right sidebar
                </label>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <SaveSpinnerButton
          inactiveText="Save Changes"
          activeText="Saving..."
          isSaving={isUpdating}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
        <button type="button" className="btn text-danger" onClick={closeModal}>
          Cancel
        </button>
      </ModalFooter>
    </>
  );
}

export default CompanyTwitterFeedForm;
