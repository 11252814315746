import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  getContactOrganizations(contactId) {
    vlog.info('api#getContactOrganizations');

    api.configure();

    const url = `/api/internal/jsonapi/contacts/${contactId}/organizations`;

    return axios.get(url, api.axiosFix);
  },

  updateOrganization(organization) {
    vlog.info('api#updateOrganization');

    api.configure();

    const url = `/api/internal/jsonapi/organizations/${organization.id}?include=contact_employments`;

    const data = {
      type: 'organizations',
      id: organization.id,
      attributes: organization.attributes,
    };

    return axios.patch(url, { data }, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },
});
