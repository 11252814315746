import React from 'react';
import {
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

function CompanyFilterPanel({ customClass, defaultRefinement }) {
  return (
    <div className={`card bg-transparent border-0 mb-3 ${customClass}`}>
      <div className="card-body ais-hide-counts">
        <Panel header="Filter by company">
          <RefinementList
            attribute="company.name"
            defaultRefinement={defaultRefinement}
            limit={6}
            showMore={true}
            showMoreLimit={15}
            searchable={true}
          />
        </Panel>
      </div>
    </div>
  );
}

CompanyFilterPanel.propTypes = {
  customClass: PropTypes.string,
  defaultRefinement: PropTypes.instanceOf(Object),
};

CompanyFilterPanel.defaultProps = {
  customClass: undefined,
  defaultRefinement: '',
};

export default CompanyFilterPanel;
