import React, { useState } from 'react';

function ConnectionOppPaywall() {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleShow();
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-lg w-75 px-1"
        onClick={handleShow}
        onKeyPress={handleKeyPress}
      >
        <i className="fa-solid fa-message me-1" aria-hidden="true" /> Start a conversation
      </button>
      <div className={`modal ${show ? 'show' : ''}`} style={{ position: 'absolute', display: show ? 'block' : 'none' }} tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-primary">Sorry, you're over your limit</h5>
              <button type="button" className="btn-close" onClick={handleClose} aria-label="Close" />
            </div>
            <div className="modal-body">
              <p>You've hit your limit of free Media Match requests this month.</p>
              <p>
                Upgrade to get unlimited requests -
                <a className="text-primary" href="/pricing"> view plans here. </a>
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={handleClose}>Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal-backdrop fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none', position: 'absolute', width: '100%' }} />
    </>
  );
}

export default ConnectionOppPaywall;
