import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/avatar';
import SmartHighlight from '../../algolia_search/smart_highlight';

function NamePhotoTitle({
                          source,
                          smallMode,
                        }) {
  let titleHtml = null;
  if (source.current_positions && source.current_positions[0]) {
    titleHtml = (
      <div className="card-grid-subtitle fw-bold font-size-12px">
        {source.current_positions[0].title} at {
        source.company_show && (
          <a href={source.current_positions[0].company_path}>
            {source.current_positions[0].company}
          </a>
        )
      }
        {!source.company_show && source.current_positions[0].company}
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="float-start pe-2">
          <Avatar
            avatarUrl={source.avatar_url}
            avatarLetters={source.initials}
            sizeClass={smallMode ? 'avatar-xs' : 'avatar'}
          />
        </div>
        <div>
          <h6 className="mb-0 fw-bold">
            <a href={source.source_path}>
              <SmartHighlight attribute="full_name" hit={source} />
            </a>
            {source.is_self_source && (
              <span className="badge bg-primary self-source-badge ms-1">You</span>
            )}
          </h6>
          {titleHtml}
        </div>
      </div>
      <div className="clearfix mb-2" />
    </>
  );
}

NamePhotoTitle.propTypes = {
  source: PropTypes.instanceOf(Object).isRequired,
  smallMode: PropTypes.bool,
};

NamePhotoTitle.defaultProps = {
  smallMode: false,
};

export default NamePhotoTitle;
