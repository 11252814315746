import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import MultiIndexSearch from './multi_index_search';

function TopLevelMultiSearch({
                          query,
                          algoliaAppId,
                          algoliaSearchKey,
                          mainIndexName,
                          expertsIndexName,
                          pitchesIndexName,
                          productsIndexName,
                          pressReleasesIndexName,
                        }) {
  return (
    <ErrorBoundary>
      <MultiIndexSearch
        algoliaAppId={algoliaAppId}
        algoliaSearchKey={algoliaSearchKey}
        query={query}
        mainIndexName={mainIndexName}
        expertsIndexName={expertsIndexName}
        pitchesIndexName={pitchesIndexName}
        productsIndexName={productsIndexName}
        pressReleasesIndexName={pressReleasesIndexName}
      />
    </ErrorBoundary>
  );
}

TopLevelMultiSearch.propTypes = {
  query: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  mainIndexName: PropTypes.string.isRequired,
  expertsIndexName: PropTypes.string.isRequired,
  pitchesIndexName: PropTypes.string.isRequired,
  productsIndexName: PropTypes.string.isRequired,
  pressReleasesIndexName: PropTypes.string.isRequired,
};

TopLevelMultiSearch.defaultProps = {
  query: '',
};

export default TopLevelMultiSearch;
