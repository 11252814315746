import React, { useRef } from 'react';
import PropTypes from 'prop-types';

function AttachFiles({ files, setFiles }) {
  const baseInputRef = useRef(null);

  const handleFakeInputClick = () => {
    baseInputRef.current.click();
  };

  const renderFakeInput = () => {
    let btnText;
    if (files.length > 0) {
      btnText = 'Change attachments';
    } else {
      btnText = 'Attach file(s)';
    }

    return (
      <button
        type="button"
        className="btn btn-secondary btn-sm"
        onClick={handleFakeInputClick}
      >
        <i className="fa-solid fa-paperclip" /> {btnText}
      </button>
    );
  };

  const handleFileChange = async (e) => {
    const input = e.target;
    const { files: inputFiles } = input;

    // https://stackoverflow.com/a/67484772
    const fileReaderPromises = [...inputFiles].map((file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({
          name: file.name, base64File: reader.result,
        });
      };
      reader.onerror = (error) => {
        reject(error);
        alert('Error loading files! Please reload the page and try again.');
        throw (error);
      };
      reader.readAsDataURL(file);
    }));

    const base64Files = await Promise.all(fileReaderPromises);

    setFiles(base64Files);
  };

  const renderBaseInput = () => (
    <input
      ref={baseInputRef}
      type="file"
      style={{ display: 'none' }}
      multiple
      onChange={handleFileChange}
    />
  );

  const renderFilePreupload = (file, index) => (
    <span className="ps-2 pe-1" key={index}>
      {file.name}
    </span>
  );

  const clearInput = () => {
    if (window.confirm('Clear files?')) {
      setFiles([]);
    }
  };

  const renderClearButton = () => (
    <span
      className="pe-3 cursor-pointer"
      role="button"
      tabIndex="-1"
      onClick={clearInput}
      onKeyUp={(evt) => evt.keyCode === 13 && clearInput()}
    >
      <i className="fa-solid fa-xmark" />
    </span>
  );

  return (
    <div className="mb-3">
      {renderBaseInput()}
      <div className="mb-1">
        {renderFakeInput()}
        {Array.from(files).map((file, index) => renderFilePreupload(file, index))}
        {files.length > 0 && renderClearButton()}
      </div>
    </div>
  );
}

AttachFiles.propTypes = {
  files: PropTypes.array, setFiles: PropTypes.func,
};

AttachFiles.defaultProps = {
  files: undefined, setFiles: undefined,
};

export default AttachFiles;
