import React from 'react';
import {
  Panel,
  ToggleRefinement,
} from 'react-instantsearch-dom';

function CompanyTypeFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by company type">
          <ToggleRefinement
            attribute="is_pr_agency"
            label="PR agencies"
            value={true}
            defaultRefinement={false}
          />
          <ToggleRefinement
            attribute="is_not_pr_agency"
            label="Non-PR agencies"
            value={true}
            defaultRefinement={false}
          />
        </Panel>
      </div>
    </div>
  );
}

export default CompanyTypeFilterPanel;
