import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import ProductsIndex from './index/products_index';

function TopLevelIndex({ view }) {
  return (
    <ErrorBoundary>
      <ProductsIndex view={view} />
    </ErrorBoundary>
  );
}

TopLevelIndex.propTypes = {
  view: PropTypes.string,
};

TopLevelIndex.defaultProps = {
  view: 'all',
};

export default TopLevelIndex;
