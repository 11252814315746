import axios from 'axios';
import _ from 'lodash';
import api from './api';

_.extend(api, {
  getSourceRequest(params) {
    api.configure();
    const { id } = params;

    return axios.get(`/api/internal/source_requests/${id}`, null, api.axiosFix);
  },

  getSourceRequests(params) {
    api.configure();
    const options = { params };

    return axios.get('/api/internal/source_requests', options, api.axiosFix);
  },

  getSourceRequestHashtags(params) {
    api.configure();
    const { id } = params;

    return axios.get(`/api/internal/source_requests/${id}/hashtags`, null, api.axiosFix);
  },

  updateSourceRequest(id, params) {
    api.configure();

    return axios.patch(
      `/api/internal/source_requests/${id}`,
      { source_request: params },
      api.axiosFix,
    );
  },

  getAlgoliaAttribsForIds(ids) {
    api.configure();
    const idStr = ids.join('&ids[]=');

    return axios.get(`/api/internal/source_requests/algolia_attribs?ids[]=${idStr}`, {}, api.axiosFix);
  },
});
