import helper from '../../../helpers/helper';
import select2Helpers from '../../../helpers/select2_helpers';

document.addEventListener('turbolinks:load', () => {
  const $contactEmploymentOrganizationId = $('#contact_employment_organization_id:visible');

  if (
    helper.notPresent($contactEmploymentOrganizationId)
  ) {
    return;
  }

  select2Helpers.setupSelect2($contactEmploymentOrganizationId, '/api/internal/jsonapi/organizations', select2Helpers.formatOrganizations);
});
