import React from 'react';
import 'select2';
import PropTypes from 'prop-types';

const perPage = 20;

jQuery.fn.select2.amd.define(
  'CustomDropdownAdapter',
  [
    'select2/utils',
    'select2/dropdown',
    'select2/dropdown/attachBody',
    'select2/dropdown/search',
    'select2/dropdown/closeOnSelect',
  ],
  (Utils, DropdownAdapter, AttachBody, DropdownSearch, CloseOnSelect) => {
    function AddNew() {
    }

    AddNew.prototype.render = function renderAddNew(decorated) {
      const $rendered = decorated.call(this);
      const self = this;
      const addNewText = self.options.get('appendedBtnLabel');

      if (addNewText) {
        const $addNew = $(
          `<div class="appended-btn-container"><button type="button" class="btn appended-btn">${addNewText}</button></div>`,
        );

        $rendered.find('.select2-dropdown').append($addNew);

        $addNew.on('click', () => {
          self.$element.trigger('select2:appendedBtnClick');
          self.trigger('close');
        });
      }

      return $rendered;
    };

    const adapter = Utils.Decorate(
      Utils.Decorate(
        Utils.Decorate(
          DropdownAdapter,
          DropdownSearch,
        ),
        AttachBody,
      ),
      AddNew,
    );

    return Utils.Decorate(adapter, CloseOnSelect);
  },
);

class Select2Ajax extends React.Component {
  componentDidMount() {
    if (this.root) {
      const { props } = this;
      let { ajax } = props;
      const { ajaxUrl } = props;

      ajax = ajax || {
        url: ajaxUrl,
        dataType: props.ajaxDataType || 'json',
        delay: props.ajaxDelay || 250,
        // eslint-disable-next-line func-names
        data: props.ajaxData || function (params) {
          const page = params.page || 1;
          const obj = {
            filter: {
              name: params.term,
            },
          };

          obj['page[offset]'] = (page - 1) * perPage;
          obj['page[limit]'] = perPage;

          return obj;
        },
        processResults(response, params) {
          if (props.ajaxProcessResults) {
            return props.ajaxProcessResults(response, params);
          }

          return {
            results: response.data,
            pagination: {
              more: !!(response.links || {}).next,
            },
          };
        },
      };

      let { dropdownParent } = props;
      if (!dropdownParent && props.isInsideModal) {
        dropdownParent = jQuery('.modal');
      }

      const options = props.options || {
        placeholder: props.placeholderText || '',
        width: '100%',
        allowClear: true,
        ajax,
        tags: props.tags || false,
        dropdownParent,
        // eslint-disable-next-line func-names
        escapeMarkup: props.escapeMarkup || function (markup) {
          return markup;
        },
        minimumInputLength: props.minimumInputLength,
        language: props.language || {
          searching: props.onSearching,
          // eslint-disable-next-line func-names
          inputTooShort: props.languageInputTooShort || function () {
            return '';
          },
          noResults: this.formatNoResults,
        },
        createTag: props.createTag,
        insertTag: props.insertTag,
        templateResult: props.templateResult,
        templateSelection: props.templateSelection,
        dropdownAdapter: jQuery.fn.select2.amd.require('CustomDropdownAdapter'),
        appendedBtnLabel: props.appendedBtnLabel,
      };

      const $select2 = jQuery(this.root).select2(options);
      $select2.on('select2:open', props.onOpen);
      $select2.on('select2:close', props.onClose);
      $select2.on('select2:select', props.onSelect);
      $select2.on('select2:unselect', props.onUnselect);
      $select2.on('change', props.onChange);
      $select2.on('select2:appendedBtnClick', () => {
        props.onAppendedBtnClick && props.onAppendedBtnClick($select2);
      });

      const select2Obj = $select2.data('select2');
      select2Obj.$dropdown.on('click', '#new_btn', () => {
        const text = select2Obj.dropdown.$search.val();
        const option = new Option(text, 'new', true, true);

        $select2.append(option).trigger('change');
        $select2.select2('close');
      });
    }
  }

  formatNoResults = () => {
    const { existingOnly, newButtonText } = this.props;
    let text;

    if (existingOnly) {
      text = '... nothing found';
    } else {
      const newOrgButton = `<button id="new_btn" type="button" class="btn btn-outline-secondary">${newButtonText || 'Create new'}</button>`;
      text = `... nothing found: ${newOrgButton}`;
    }

    return text;
  };

  render() {
    const {
      ajax, ajaxUrl, elementName, className, selectedOption, required,
    } = this.props;
    let selectedOptionComp;

    if (!ajax && !ajaxUrl) {
      return (<></>);
    }

    if (selectedOption && selectedOption.value) {
      selectedOptionComp = (
        <option value={selectedOption.value}>{selectedOption.label || selectedOption.value}</option>
      );

      return (
        <select
          name={elementName}
          ref={(instance) => {
            this.root = instance;
          }}
          className={`select2-ajax ${className || ''}`}
          defaultValue={selectedOption.value}
          required={required}
        >
          {selectedOptionComp}
        </select>
      );
    }

    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <select
        name={elementName}
        ref={(instance) => {
          this.root = instance;
        }}
        className={`select2-ajax ${className || ''}`}
        required={required}
      />
    );
  }
}

Select2Ajax.propTypes = {
  ajax: PropTypes.instanceOf(Object),
  ajaxData: PropTypes.instanceOf(Object),
  ajaxDataType: PropTypes.string,
  ajaxDelay: PropTypes.number,
  ajaxProcessResults: PropTypes.func,
  ajaxUrl: PropTypes.string,
  className: PropTypes.string,
  createTag: PropTypes.func,
  dropdownParent: PropTypes.instanceOf(Object),
  elementName: PropTypes.string,
  escapeMarkup: PropTypes.func,
  existingOnly: PropTypes.bool,
  insertTag: PropTypes.func,
  isInsideModal: PropTypes.bool,
  language: PropTypes.instanceOf(Object),
  languageInputTooShort: PropTypes.func,
  minimumInputLength: PropTypes.number,
  newButtonText: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onSelect: PropTypes.func,
  onUnselect: PropTypes.func,
  onSearching: PropTypes.func,
  options: PropTypes.instanceOf(Object),
  placeholderText: PropTypes.string,
  required: PropTypes.bool,
  selectedOption: PropTypes.instanceOf(Object),
  tags: PropTypes.arrayOf(Object),
  templateResult: PropTypes.func,
  templateSelection: PropTypes.func,
  appendedBtnLabel: PropTypes.string,
  onAppendedBtnClick: PropTypes.func,
};

Select2Ajax.defaultProps = {
  ajax: undefined,
  ajaxData: undefined,
  ajaxDataType: undefined,
  ajaxDelay: undefined,
  ajaxProcessResults: undefined,
  ajaxUrl: undefined,
  className: undefined,
  createTag: undefined,
  dropdownParent: undefined,
  elementName: undefined,
  escapeMarkup: undefined,
  existingOnly: undefined,
  insertTag: undefined,
  isInsideModal: undefined,
  language: undefined,
  languageInputTooShort: undefined,
  minimumInputLength: undefined,
  newButtonText: undefined,
  onChange: undefined,
  onClose: undefined,
  onOpen: undefined,
  onSelect: undefined,
  onUnselect: undefined,
  onSearching: undefined,
  options: undefined,
  placeholderText: undefined,
  required: false,
  selectedOption: undefined,
  tags: undefined,
  templateResult: undefined,
  templateSelection: undefined,
  appendedBtnLabel: undefined,
  onAppendedBtnClick: undefined,
};

export default Select2Ajax;
