import React from 'react';
import PropTypes from 'prop-types';

function ErrorMessages(props) {
  const { messages, errorMessagesHeader } = props;
  const header = errorMessagesHeader && (<h2>{errorMessagesHeader}</h2>);

  return (
    <div className="alert alert-danger">
      <div className="" id="error-explanation">
        {header}
        <ul className="list-unstyled">
          {messages.map((message, index) => <li key={index}>{message}</li>)}
        </ul>
      </div>
    </div>
  );
}

ErrorMessages.propTypes = {
  messages: PropTypes.arrayOf(String).isRequired,
  errorMessagesHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

ErrorMessages.defaultProps = {
  errorMessagesHeader: undefined,
};

export default ErrorMessages;
