import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';

import PressReleaseCard from './card';
import NoResultsMessage from '../../algolia_search/no_results_message';
import SearchLoadingCards from './search_loading_cards';
import PressReleasesSkeletonPage from './skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';

function InfiniteHits({
                        hasMore,
                        hits,
                        refine,
                      }) {
  // Ignore scrollContainerRef since we want to use root as the scrolling container
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });

  // Effect to refine results when infinite scroll triggers
  useEffect(() => {
    if (lastTriggeredAt > 0) refine();
  }, [lastTriggeredAt, refine]);

  return (
    <>
      <NoResultsMessage />

      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 px-1">
        <SearchLoadingCards />

        {hits.map((pressRelease) => (
          <div className="col px-2 pb-3 pt-0" key={pressRelease.objectID}>
            <PressReleaseCard
              pressRelease={pressRelease}
            />
          </div>
        ))}
      </div>

      {hasMore && (
        <div
          ref={scrollTriggerRef}
          className="row row-cols-1 row-cols-md-2 row-cols-xl-3 px-1"
        >
          <PressReleasesSkeletonPage numberOfCards={3} singleRow />
        </div>
      )}
    </>
  );
}

InfiniteHits.propTypes = {
  hasMore: PropTypes.bool,
  refine: PropTypes.func,
  hits: PropTypes.arrayOf(Object),
};

InfiniteHits.defaultProps = {
  hasMore: false,
  refine: undefined,
  hits: [],
};

export default connectInfiniteHits(InfiniteHits);
