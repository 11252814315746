import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ResponsiveAvatar from '../../responsive_avatar';
import ActionButtons from '../../sources/card/action_buttons';
import Hashtags from '../../hashtags';

function CompanyFeaturedExpert({ source }) {
  const [seeMoreBio, setSeeMoreBio] = useState(false);

  const renderBio = () => {
    const bioLength = source.attributes.bio?.length;
    if (!bioLength) return null;

    const maxBioLength = 350;

    if (seeMoreBio) {
      return (
        <>
          {source.attributes.bio}
          <br />
          <button
            onClick={() => setSeeMoreBio(!seeMoreBio)}
            type="button"
            className="btn btn-link btn-outline-light"
          >
            Hide
          </button>
        </>
      );
    }

    if (bioLength < maxBioLength) {
      return <>{source.attributes.bio}</>;
    }

    return (
      <>
        {`${source.attributes.bio.slice(0, maxBioLength)}...`}
        <button
          onClick={() => setSeeMoreBio(!seeMoreBio)}
          type="button"
          className="btn btn-link"
        >
          See more
        </button>
      </>
    );
  };

  return (
    <div className="card w-100 h-100 mx-3 mb-3" key={source.id}>
      <div className="card-body h-100 row align-items-stretch gx-0">
        <div className="col-md-3 mb-3 pe-3">
          <ResponsiveAvatar
            avatarUrl={source.attributes.avatar_url}
            avatarLetters={source.attributes.initials}
          />
        </div>

        <div className="col">
          <h3 className="mb-1">
            <a
              href={source.attributes.source_path}
              className="fw-bold cursor-pointer"
            >
              {source.attributes.full_name}
            </a>
          </h3>

          <span className="text-secondary">{source.attributes.title}</span>

          <p className="mt-2">{renderBio()}</p>

          <Hashtags
            hashtagTarget={source.attributes}
            clickEventName="Sources/SourceCardMinimal#clickHashtag"
          />
          <ActionButtons source={source.attributes} />
        </div>
      </div>
    </div>
  );
}

CompanyFeaturedExpert.propTypes = {
  source: PropTypes.object.isRequired,
};

export default CompanyFeaturedExpert;
