import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ImageCropped from '../../../image_cropped';
import { PitchWizardContext } from '../../provider/pitch_wizard_provider';

function AttachableRepresentedEntity({ entity, onClickCallback }) {
  const btnClasses = 'btn btn-outline-primary d-inline-flex flex-row cursor-pointer mb-2 me-2';
  const { userId: currentUserId } = useContext(PitchWizardContext);
  const selfExpertIsCurrentUser = entity.selfExpert && entity.selfExpertUserId === currentUserId;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClickCallback(entity);
    }
  };

  return (
    <div
      className={btnClasses}
      id={`${entity.type}_${entity.id}`.toLowerCase()}
      onClick={() => onClickCallback(entity)}
      onKeyDown={handleKeyDown}
      role="button"
      style={{ lineHeight: '1.1em' }}
      tabIndex="0"
    >
      <ImageCropped
        roundedCircle
        wrapperClasses="me-2"
        src={entity.profileImageUrl}
        alt={entity.name}
        size={40}
      />
      <div className="d-flex flex-column align-items-start justify-content-start text-start">
        <div>
          {selfExpertIsCurrentUser && 'Myself: '}
          <strong>{entity.name}</strong>
        </div>
        {entity.subheading && <div>{entity.subheading}</div>}
      </div>
    </div>
  );
}

AttachableRepresentedEntity.propTypes = {
  entity: PropTypes.shape({
    profileImageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    selfExpert: PropTypes.bool,
    selfExpertUserId: PropTypes.string,
    subheading: PropTypes.string,
  }),
  onClickCallback: PropTypes.func,
};

AttachableRepresentedEntity.defaultProps = {
  entity: undefined,
  onClickCallback: () => {
  },
};

export default AttachableRepresentedEntity;
