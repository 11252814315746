import React from 'react';
import PropTypes from 'prop-types';
import ImageCropped from '../../../image_cropped';

function EntityRow({ entity, attach }) {
  const handleKeyDown = (event, action) => {
    if (event.key === 'Enter' || event.key === ' ') {
      action();
    }
  };

  let profilePagePath;

  switch (entity.type) {
    case 'Source':
      profilePagePath = `/sources/${entity.id}`;
      break;
    case 'Product':
      profilePagePath = `/products/${entity.id}`;
      break;
    default:
      throw new Error(`Unhandled entity type: ${entity.type}`);
  }

  return (
    <tr>
      <td>
        <div className="d-flex align-items-start">
          <ImageCropped
            src={entity.profileImageUrl}
            alt={entity.name}
            size={40}
            roundedCircle
            wrapperClasses="me-2"
          />
          <span className="d-flex flex-column align-items-start">
            <button
              type="button"
              className="text-start btn btn-link p-0"
              onClick={() => attach(entity)}
              onKeyDown={(e) => handleKeyDown(e, () => attach(entity))}
              style={{ cursor: 'pointer' }}
              tabIndex="0"
            >
              {entity.name}
            </button>
            <a
              href={profilePagePath}
              className="btn btn-link smaller p-0 d-none d-md-table-cell"
              target="_blank"
              rel="noreferrer"
            >
              view profile <i className="fa-solid fa-up-right-from-square smaller" />
            </a>
          </span>
        </div>
      </td>
      <td className="align-start d-none d-md-table-cell">
        {entity.subheading}
      </td>
      <td className="align-start">
        <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => attach(entity)}>
          Attach
        </button>
      </td>
    </tr>
  );
}

EntityRow.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    profileImageUrl: PropTypes.string,
    subheading: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  attach: PropTypes.func.isRequired,
};

export default EntityRow;
