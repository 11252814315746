import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {

  getArticles(params) {
    vlog.info('api#getArticles');

    api.configure();
    const url = '/api/internal/jsonapi/articles';

    return axios.get(url, { params }, api.axiosFix);
  },
});
