import { useEffect, useState, useRef } from 'react';
import 'intersection-observer'; // Polyfill to support older browsers

const useInfiniteScroll = ({ hasMore, rootMargin = 0, threshold = 0 }) => {
  const [lastTriggeredAt, setLastTriggeredAt] = useState(0);
  const scrollContainerRef = useRef();
  const scrollTriggerRef = useRef();

  useEffect(() => {
    const scrollContainerNode = scrollContainerRef.current;
    const scrollTriggerNode = scrollTriggerRef.current;
    const options = {
      root: scrollContainerNode,
      rootMargin: `${rootMargin}px`,
      threshold,
    };

    const handleIntersection = (entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setLastTriggeredAt(Date.now());
      }
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (scrollTriggerNode) {
      observer.observe(scrollTriggerNode);
    }

    return () => {
      observer.disconnect();
    };
  }, [hasMore, rootMargin, threshold]);

  return [lastTriggeredAt, scrollTriggerRef, scrollContainerRef];
};

export default useInfiniteScroll;
