import React from 'react';
import PropTypes from 'prop-types';
import SmartSnippet from '../../algolia_search/smart_snippet';

function QuoteOrBio({ source }) {
  let attributeToDisplay;
  if (!source.latest_quote && !source.bio) return null;
  // Determine what to display based on whether we're displaying a search results and,
  // if so, where the match is.
  if (source._highlightResult) {
    // This is a search result, so look for any match highlights
    if (source._highlightResult.latest_quote
      && source._highlightResult.latest_quote.matchLevel !== 'none') {
      attributeToDisplay = 'latest_quote';
    } else if (source._highlightResult.bio
      && source._highlightResult.bio.matchLevel !== 'none') {
      attributeToDisplay = 'bio';
    } else if (source._highlightResult.quotes) {
      const i = source._highlightResult.quotes.findIndex((q) => q.quote && q.quote.matchLevel !== 'none');
      if (i > -1) attributeToDisplay = `quotes[${i}].quote`;
    }
  }

  // Fall back to regular, non-search-result logic if we don't have an attribute yet
  if (!attributeToDisplay) {
    attributeToDisplay = source.latest_quote ? 'latest_quote' : 'bio';
  }

  return (
    <div className="font-size-12px mt-2">
      {(attributeToDisplay === 'latest_quote') && (<span className="fw-bold">LATEST: </span>)}
      <em className="text-secondary">
        <SmartSnippet attribute={attributeToDisplay} hit={source} />
      </em>
    </div>
  );
}

QuoteOrBio.propTypes = {
  source: PropTypes.instanceOf(Object).isRequired,
};

export default QuoteOrBio;
