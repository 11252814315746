import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch, SearchBox, Panel, ToggleRefinement,
  Configure,
} from 'react-instantsearch-dom';
import RepsResults from './reps_results';
import RepsList from '../reps_list';

function RepsSearch({
  promoEntityId,
  promoEntityType,
  clientFirmName,
  algoliaAppId,
  algoliaSearchKey,
  indexName,
  representingUserIds,
  inviteUrl,
}) {
  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

  const [repUIds, setRepUIds] = useState(representingUserIds);

  const handleChange = (val) => {
    if (repUIds.includes(val)) {
      setRepUIds(repUIds.filter((r) => r !== val));
    } else {
      setRepUIds([...repUIds, val]);
    }
  };

  return (
    <div className="ais-InstantSearch reps-search m-3 mt-1">
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <Configure filters="disabled:false AND under_audit:false" />
        <div className="row pl-3 mt-4">
          <div className="col-md-3">
            <SearchBox
              autoFocus
              translations={{
                placeholder: 'Search by name or company',
              }}
              searchAsYouType={true}
            />
            <div className="d-none">
              <ToggleRefinement attribute="user_type" label="source" value="source" defaultRefinement={true} />
            </div>
            {clientFirmName && (
              <div className="col-12 pr-0 mt-3">
                <div className="card mb-3 border-0 bg-transparent rounded-0 p-0">
                  <div className="card-body border-0 bg-transparent rounded-0 ais-hide-counts p-0">
                    <Panel header="Filter by associated companies:">
                      <ToggleRefinement
                        attribute="client_firm_name"
                        label={clientFirmName}
                        value={clientFirmName}
                        defaultRefinement={true}
                      />
                    </Panel>
                  </div>
                </div>
              </div>
            )}
            <RepsList
              inviteUrl={inviteUrl}
              promoEntityId={promoEntityId}
              promoEntityType={promoEntityType}
              representingUserIds={repUIds}
              handleChange={handleChange}
            />
          </div>
          <div className="col-md-9">
            <RepsResults
              promoEntityId={promoEntityId}
              promoEntityType={promoEntityType}
              representingUserIds={repUIds}
              handleChange={handleChange}
            />
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

RepsSearch.propTypes = {
  promoEntityId: PropTypes.number.isRequired,
  promoEntityType: PropTypes.string.isRequired,
  clientFirmName: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  representingUserIds: PropTypes.array.isRequired,
  inviteUrl: PropTypes.string,
};

RepsSearch.defaultProps = {
  clientFirmName: null,
  inviteUrl: '',
};

export default RepsSearch;
