import React from 'react';
import PropTypes from 'prop-types';
import vapi from '../../javascript/frontend/api/vapi';
import Contact from './contact/contact';
import Organization from './organization/organization';

class OpportunityContactEmployment extends React.Component {
  constructor(props) {
    super(props);
    // this.props ->
    //   opportunity_id

    this.state = {
      contact: null,
      contactEmployment: null,
      contactEmployments: null,
      initialLoading: true,
      opportunity: null,
      organization: null,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    // eslint-disable-next-line react/destructuring-assignment
    vapi.getOpportunity(this.props.opportunity_id)
      .then((response) => {
        let opportunity;
        if (response.data.data != null) {
          opportunity = response.data.data;
        }

        const contact = _(response.data.included).filter((i) => i.type == 'contacts').first();

        const organization = _(response.data.included).filter((i) => i.type == 'organizations').first();

        const contactEmployments = _(response.data.included).filter((i) => i.type == 'contact_employments').value();

        const contactInfos = _(response.data.included).filter((i) => i.type == 'contact_infos').value();

        let contactEmployment;
        if (organization) {
          contactEmployment = _(contactEmployments)
            .filter((ce) => ce.attributes.organization_id == organization.id)
            .first();
        }

        this.setState({
          contact,
          contactEmployment,
          contactEmployments,
          initialLoading: false,
          opportunity,
          organization,
          contactInfos,
        });
      });
  };

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.initialLoading) {
      return null;
    }

    const {
      hide_contact_info_details: hideContactInfoDetails,
      hide_view_reporter_details_page: hideViewReporterDetailsPage,
    } = this.props;
    const {
      contact,
      opportunity,
      organization,
      contactInfos,
      contactEmployment,
      contactEmployments,
    } = this.state;

    return (
      <div>
        <Organization
          contact={contact}
          contact_employment={contactEmployment}
          opportunity={opportunity}
          organization={organization}
          contact_infos={contactInfos.filter((info) => info.attributes.contactable_type == 'Organization')}
          refreshOpportunityContactEmployment={this.refresh}
        />
        <Contact
          contact={contact}
          contact_employment={contactEmployment}
          contact_employments={contactEmployments}
          hide_contact_info_details={hideContactInfoDetails}
          hide_view_reporter_details_page={hideViewReporterDetailsPage}
          contact_infos={contactInfos.filter((info) => info.attributes.contactable_type == 'Contact')}
          opportunity={opportunity}
          organization={organization}
          refreshOpportunityContactEmployment={this.refresh}
        />
      </div>
    );
  }
}

OpportunityContactEmployment.propTypes = {
  opportunity_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hide_contact_info_details: PropTypes.bool,
  hide_view_reporter_details_page: PropTypes.bool,
};

OpportunityContactEmployment.defaultProps = {
  hide_contact_info_details: undefined,
  hide_view_reporter_details_page: undefined,
};

export default OpportunityContactEmployment;
