/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { ModalBody, ModalFooter } from 'reactstrap';
import { CompanyContext } from '../data/company_context_management';
import useCompanyUpdateApi from '../data/use_company_update_api';
import SaveSpinnerButton from '../../save_spinner_button';

function ContactInfoForm() {
  const context = useContext(CompanyContext);
  const {
    company,
    isUpdating,
    setOpenEditModal,
    setGeneralError,
  } = context;
  const emptyRecord = [{ title: null, value: null }];
  const { register, control, handleSubmit } = useForm(
    {
      defaultValues: {
        freeform_contact_info: (company.freeform_contact_info ? [...company.freeform_contact_info] : emptyRecord),
      },
    },
  );
  const {
    fields,
    append,
    move,
    remove,
  } = useFieldArray({
    control,
    name: 'freeform_contact_info',
  });
  const { updateCompany } = useCompanyUpdateApi({ ...context });

  const closeModal = () => {
    setOpenEditModal(null);
    setGeneralError(null);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    updateCompany(data);
  };

  return (
    <>
      <ModalBody className="py-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((item, index) => {
            const fieldName = `freeform_contact_info.${index}`;
            return (
              <fieldset
                name={fieldName}
                key={item.id}
                className="row border-bottom my-2 animated faster fadeIn"
              >
                <div className="col-sm-10">
                  <div className="row mb-1">
                    <label
                      htmlFor={`${fieldName}.title`}
                      className="col-sm-2 col-form-label"
                    >
                      Label
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        {...register(`${fieldName}.title`)}
                        disabled={isUpdating}
                        defaultValue={item.title}
                        placeholder="West Coast"
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <label
                      htmlFor={`${fieldName}.value`}
                      className="col-sm-2 col-form-label"
                    >
                      Info
                    </label>
                    <div className="col-sm-10">
                      <textarea
                        className="form-control form-control-sm"
                        {...register(`${fieldName}.value`)}
                        disabled={isUpdating}
                        rows={3}
                        defaultValue={item.value}
                        placeholder="comms-west@example.com"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 text-center ps-0 mb-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger mb-1 w-100"
                    onClick={() => remove(index)}
                  >
                    <i className="fa-solid fa-minus me-1" />
                    Remove
                  </button>
                  {(index > 0) && (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary mb-1 w-100"
                      onClick={() => move(index, index - 1)}
                    >
                      <i className="fa-solid fa-arrow-up me-1" />
                      Move
                    </button>
                  )}
                  {(index < (fields.length - 1)) && (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary mb-1 w-100"
                      onClick={() => move(index, index + 1)}
                    >
                      <i className="fa-solid fa-arrow-down me-1" />
                      Move
                    </button>
                  )}
                </div>
              </fieldset>
            );
          })}
          <div className="my-2 text-center">
            <button
              type="button"
              className="btn btn btn-outline-success"
              onClick={() => append({ title: '', value: '' })}
            >
              <i className="fa-solid fa-plus me-1" />
              {(fields.length > 0) ? 'Add another' : 'Add an entry'}
            </button>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <SaveSpinnerButton
          inactiveText="Save Changes"
          activeText="Saving..."
          isSaving={isUpdating}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
        <button
          type="button"
          className="btn text-danger"
          onClick={closeModal}
          disabled={isUpdating}
        >
          Cancel
        </button>
      </ModalFooter>
    </>
  );
}

export default ContactInfoForm;
