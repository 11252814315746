import URI from 'urijs';

import _ from 'lodash';
import 'select2';
import DOMPurify from 'dompurify';

const helper = {

  // Checks if any of the passed elements exist on the page;
  present(...args) {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(args)) {
      if (value.length) {
        return true;
      }
    }
    return false;
  },

  // Checks if any of the passed elements doesn't exist on the page;
  notPresent(...args) {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(args)) {
      if (!value.length) {
        return true;
      }
    }
    return false;
  },

  urlParamsHash() {
    return URI(window.location.href).query(true);
  },

  urlParamPresent(param) {
    return param in this.urlParamsHash() && this.urlParamsHash()[param].length > 0;
  },

  sanitize(string) {
    return DOMPurify.sanitize(string, { ALLOWED_TAGS: ['mark'] });
  },

  currentUserInternal() {
    if (window.CURRENT_USER_JSON) {
      return window.CURRENT_USER_JSON.attributes.auth_level !== 'BasicUser';
    }
  },

  currentUserPaid() {
    if (window.CURRENT_USER_JSON) {
      return window.CURRENT_USER_JSON.attributes.user_type_humanized
        && window.CURRENT_USER_JSON.attributes.source_plan !== 'free';
    }
  },

  // Copied from https://gist.github.com/ghinda/8442a57f22099bdb2e34
  objectToFormData(obj, form, namespace) {
    const fd = form || new FormData();
    let formKey;

    for (const property in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, property)) {
        if (namespace) {
          formKey = `${namespace}[${property}]`;
        } else {
          formKey = property;
        }

        if (obj[property] instanceof Array) {
          const array = obj[property];
          for (let i = 0; i < array.length; i += 1) {
            this.objectToFormData(array[i], fd, `${formKey}[]`);
          }
        } else if (typeof obj[property] === 'object') {
          if (obj[property] instanceof File) {
            fd.append(formKey, obj[property]);
          } else if ((obj[property] instanceof FileList)) {
            const filesList = obj[property];
            for (let i = 0; i < filesList.length; i += 1) {
              fd.append(`${formKey}[]`, filesList[i]);
            }
          } else {
            this.objectToFormData(obj[property], fd, formKey);
          }
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }

    return fd;
  },

  getMessagesFromErrors(errors, options) {
    const opts = options || {};
    const messages = [];

    if (!errors || _.isEmpty(errors)) {
      return messages;
    }

    for (const field in errors) {
      if ({}.hasOwnProperty.call(errors, field)) {
        const fieldErrors = errors[field];
        let humanizedField;

        if (field === 'opportunity_id') {
          humanizedField = 'Request';
        } else if (field === 'base') {
          humanizedField = opts.modelName || 'This document';
        } else {
          humanizedField = _.capitalize(field.replace(/_/g, ' '));
        }

        messages.push(`${humanizedField}: ${_.flatten([fieldErrors]).join(', ')}`);
      }
    }

    return messages;
  },

  // https://gist.github.com/kares/740162
  // https://makandracards.com/makandra/1395-simple_format-helper-for-javascript
  simpleFormat(str) {
    const simpleFormatRE1 = /\r\n?/g;
    const simpleFormatRE2 = /\n\n+/g;
    const simpleFormatRE3 = /([^\n]\n)(?=[^\n])/g;
    let fstr = str;

    fstr = fstr.replace(simpleFormatRE1, '\n'); // \r\n and \r -> \n
    fstr = fstr.replace(simpleFormatRE2, '</p>\n\n<p>'); // 2+ newline  -> paragraph
    fstr = fstr.replace(simpleFormatRE3, '$1<br/>'); // 1 newline   -> br
    fstr = `<p>${fstr}</p>`;

    return fstr;
  },

  isValidEmail(str) {
    return window.REGEX_SIMPLE_EMAIL.test(str);
  },

  isValidPhone(str) {
    return window.REGEX_PHONE.test(str);
  },

  isValidTwitter(str) {
    return window.REGEX_TWITTER.test(str);
  },

  // NOTE: The requirement is that we don't accept url like facebook.com/ or facebook.com?
  //       The REGEX_FACEBOOK is quite complicated to change.
  //       So here we check it manually to exclude those urls.
  isValidFacebook(str) {
    let bool = window.REGEX_FACEBOOK.test(str);

    if (bool && /(facebook|fb)\.com\//i.test(str)) {
      bool = bool && (/(facebook|fb)\.com\/[^?/]+/i.test(str));
    }

    return bool;
  },

  isValidLinkedin(str) {
    return !window.REGEX_LINKEDIN || window.REGEX_LINKEDIN.test(str);
  },

  getInitialLettersFromString(str) {
    let letters = (str || '').split(/[\s+|._@]/).map((s) => s[0]).join('').substr(0, 2);

    if (letters.length === 1 && str.length > 1) {
      letters = str.substr(0, 2);
    }

    return letters;
  },

  beautifulTruncateStr(str, maxLength) {
    if (!str || !maxLength || maxLength < 1 || str.length <= maxLength + 3) {
      return str;
    }

    if ([' ', '\r', '\n'].indexOf(str[maxLength]) >= 0) {
      return `${_.trim(str.substr(0, maxLength))}...`;
    }

    const tmpStr = str.slice(0, maxLength);
    const arr = [tmpStr.lastIndexOf(' ', maxLength), tmpStr.lastIndexOf('\r', maxLength), tmpStr.lastIndexOf('\n', maxLength)];
    const spaceCharacterIndex = _.max(arr);

    if (spaceCharacterIndex > 0) {
      return `${str.substr(0, spaceCharacterIndex)}...`;
    }

    return `${str.substr(0, maxLength)}...`;
  },

  toTitleCase(str) {
    const strsDividedBySpaces = str.toLowerCase().split(' ');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < strsDividedBySpaces.length; i++) {
      strsDividedBySpaces[i] = strsDividedBySpaces[i].charAt(0).toUpperCase() + strsDividedBySpaces[i].slice(1);
    }

    const strsDividedByForwardSlash = strsDividedBySpaces.join(' ').split('/');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < strsDividedByForwardSlash.length; i++) {
      strsDividedByForwardSlash[i] = strsDividedByForwardSlash[i].charAt(0).toUpperCase()
        + strsDividedByForwardSlash[i].slice(1);
    }

    return strsDividedByForwardSlash.join('/');
  },
};

export default helper;
