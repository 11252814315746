// Google Analytics tracking
const gaTracking = {};

// eslint-disable-next-line func-names
gaTracking.trackSignUp = function (c, a, l, v) {
  const result = window.dataLayer && window.dataLayer.push({
    // NOTE: This must match with an event name on GTM
    event: 'signup',

    // NOTE: This object must have the corresponding variables on GTM, ie eventProps.category, eventProps.action,...
    eventProps: {
      category: c || window.location.pathname,
      action: a,
      label: l || 'emptyLabel',
      value: v || 'emptyValue',
    },
  });

  return result;
};

window.gaTracking = gaTracking;

export default gaTracking;
