import React from 'react';
import {
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';

function TitleFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by title">
          <RefinementList
            attribute="current_sources.title"
            limit={6}
            showMore={true}
            showMoreLimit={15}
            searchable={true}
          />
        </Panel>
      </div>
    </div>
  );
}

export default TitleFilterPanel;
