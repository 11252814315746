import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import PrUsersSearch from './search/pr_users_search';

function TopLevelSearch({
                          query, algoliaAppId, algoliaSearchKey, indexName, hideTabs,
                        }) {
  return (
    <ErrorBoundary>
      <PrUsersSearch
        query={query}
        algoliaAppId={algoliaAppId}
        algoliaSearchKey={algoliaSearchKey}
        indexName={indexName}
        hideTabs={hideTabs}
      />
    </ErrorBoundary>
  );
}

TopLevelSearch.propTypes = {
  query: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  hideTabs: PropTypes.bool,
};

TopLevelSearch.defaultProps = {
  query: '',
  hideTabs: false,
};

export default TopLevelSearch;
