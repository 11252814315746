import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  updateContactEmployment(contactEmployment) {
    vlog.info('api#updateOpportunityContact');

    api.configure();

    const url = contactEmployment.links.self;

    const data = {
      type: 'contact_employments',
      id: contactEmployment.id,
      attributes: {
        organization_id: contactEmployment.attributes.organization_id,
        title: contactEmployment.attributes.title,
      },
    };

    return axios.put(url, { data }, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },

  createContactEmployment(contactEmployment, contactId) {
    vlog.info('api#createContactEmployment');

    api.configure();

    const url = '/api/internal/jsonapi/contact-employments';

    const data = {
      type: 'contact_employments',
      attributes: {
        contact_id: contactId,
        organization_id: contactEmployment.attributes.organization_id,
        title: contactEmployment.attributes.title,
      },
    };

    return axios.post(url, { data }, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },

  deleteContactEmployment(contactEmployment) {
    vlog.info('api#deleteOpportunityContact');

    api.configure();

    const url = contactEmployment.links.self;

    return axios.delete(url, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },

  getContactEmployment(contactId, organizationId) {
    vlog.info('api#getContactEmployment');

    api.configure();

    const url = `/api/internal/jsonapi/contact-employments?filter[contact_id]=${contactId}&filter[organization_id]=${organizationId}`;

    return axios.get(url, api.axiosFix);
  },

  getContactEmployments(contactId, params) {
    vlog.info('api#getContactEmployments');

    api.configure();

    const url = `/api/internal/jsonapi/contacts/${contactId}/contact-employments`;

    return axios.get(url, { params }, api.axiosFix);
  },

});
