import React from 'react';
import PropTypes from 'prop-types';
import { connectStateResults } from 'react-instantsearch-dom';

function NoResultsMessage({ searchResults, customMessage }) {
  const hasResults = searchResults && searchResults.nbHits !== 0;

  if (hasResults || !searchResults) return null;

  return (
    <>
      {!customMessage && (
        <div className="text-center p-4 fw-bold">
          No Results. Adjust your search and try again.
        </div>
      )}
      {customMessage}
    </>
  );
}

NoResultsMessage.propTypes = {
  searchResults: PropTypes.instanceOf(Object),
  customMessage: PropTypes.element,
};

NoResultsMessage.defaultProps = {
  searchResults: undefined,
  customMessage: undefined,
};

export default connectStateResults(NoResultsMessage);
