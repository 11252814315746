import helper from '../../helpers/helper';

document.addEventListener('turbolinks:load', () => {
  if (
    helper.notPresent($('.represented_sources.new')) && helper.notPresent($('.represented_sources.create'))
  ) {
    return;
  }

  // this is a validation to ensure user fills the email address
  // field if when 'Email the source, inviting them to join Qwoted?' is ticked
  $('input[type="submit"]').click(() => {
    const $contactInfoFieldEmail = $('#contact-info-field-email-0');

    if (
      $('#create_invitation').prop('checked') === true
      && $contactInfoFieldEmail && $contactInfoFieldEmail.val().length === 0
    ) {
      $contactInfoFieldEmail.closest('.row .contact-infos-form-fields-column').append(
        '<div class="form-text text-danger mb-1">email is required</div>',
      );
      alert('Please enter an email address for the source');
      return false;
    }
    return true;
  });
});
