/* eslint-disable react/no-danger */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DOMPurify from 'dompurify';
import vapi from '../../../../javascript/frontend/api/vapi';
import Avatar from '../../../avatar/avatar';

function AhoyEventList({ companyId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [asyncError, setAsyncError] = useState();
  const [ahoyEvents, setAhoyEvents] = useState([]);
  const [visibleEvents, setVisibleEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isMounted, setIsMounted] = useState(true);
  const eventsPerPage = 10;

  const getCompanyAhoyEvents = useCallback(async (paramPage, paramLimit) => {
    setIsLoading(true);
    try {
      const response = await vapi.getCompanyAhoyEvents(companyId, paramPage, paramLimit);
      if (response.status === 200) {
        const result = response.data;
        if (result.data) {
          setAhoyEvents((prevEvents) => [...prevEvents, ...result.data]);
          setVisibleEvents((prevEvents) => [...prevEvents, ...result.data.slice(0, eventsPerPage)]);
          setTotalPages(result.total_pages);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setAsyncError(error);
    }
  }, [companyId, eventsPerPage]);

  const loadMoreEvents = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCompanyAhoyEvents(page, eventsPerPage);
    };

    fetchData();

    // Cleanup function to cancel ongoing tasks or subscriptions
    return () => {
      setIsMounted(false);
    };
  }, [getCompanyAhoyEvents, page, eventsPerPage]);

  useEffect(() => {
    if (isMounted && asyncError) {
      throw asyncError;
    }
  }, [asyncError, isMounted]);

  if (isLoading) {
    return null; // Render loading state if still loading
  }

  return (
    <>
      {ahoyEvents.length > 0 && (
        <>
          <div className="card">
            <div className="card-header no-border-bottom mb-2">
              Activity
            </div>

            <div className="container">
              {visibleEvents.map((ahoyEvent) => {
                const ahoyDate = `${moment(ahoyEvent.time).format('hh:mm a')} ${moment(ahoyEvent.time).fromNow()}`;
                const sanitizedVisitor = DOMPurify.sanitize(ahoyEvent.visitor);

                return (
                  <div className="row mb-2" key={ahoyEvent.id}>
                    <div className="col-2">
                      <Avatar
                        avatarUrl={ahoyEvent.avatar_url}
                        sizeClass="avatar-xs"
                        avatarLetters={ahoyEvent.user_initials}
                      />
                    </div>
                    <div className="col-10">
                      <span dangerouslySetInnerHTML={{ __html: sanitizedVisitor }} /> viewed source profile for <a
                        href={`/sources/${ahoyEvent.source_slug}`}
                      >{ahoyEvent.source_name}</a>
                      <br />
                      <small>
                        <em>
                          {ahoyDate}
                        </em>
                      </small>
                    </div>
                  </div>
                );
              })}

              {page < totalPages && (
                <div className="mb-3">
                  <button className="btn btn-secondary btn-sm" type="button" onClick={loadMoreEvents}>Load More</button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

AhoyEventList.propTypes = {
  companyId: PropTypes.number,
};

AhoyEventList.defaultProps = {
  companyId: undefined,
};

export default AhoyEventList;
