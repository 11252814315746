import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch-dom';
import { orderBy } from 'lodash';
import { Tooltip } from 'reactstrap';
import MenuBar from '../../directory/MenuBar';
import InfiniteHits from './infinite_hits';
import BeatsFilterPanel from './beats_filter_panel';
import ClearRefinementsButton from '../../algolia_search/clear_refinements_button';
import HashtagFilterPanel from './hashtag_filter_panel';
import GenderFilterPanel from './gender_filter_panel';
import CompanyFilterPanel from './company_filter_panel';
import TitleFilterPanel from './title_filter_panel';
import GeoFilterPanel from './geo_filter_panel';

function PrUsersSearch({
                         view, query, algoliaAppId, algoliaSearchKey, indexName, hideTabs,
                       }) {
  const [clearGeoSearch, setClearGeoSearch] = useState(false);
  const [topPrFilter, setTopPrFilter] = useState(false);
  const [clearMenuToggle, setClearMenuToggle] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

  const handleClearAllSearch = () => {
    setClearGeoSearch(true);
    setClearMenuToggle(true);
    setTopPrFilter(false);
  };

  const handleClearGeoSearch = () => {
    setClearGeoSearch(false);
  };

  const handleToggleTopPr = () => {
    setTopPrFilter(!topPrFilter);
  };

  const handleClearMenuToggle = () => {
    setClearMenuToggle(false);
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div className="ais-InstantSearch">
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <MenuBar view={view} showNav={!hideTabs}>
          {{
            searchForm: (
              <>
                <SearchBox
                  autoFocus
                  translations={{
                    placeholder: 'Search',
                  }}
                  searchAsYouType={true}
                  defaultRefinement={query}
                  className="d-flex me-3"
                />
                <ClearRefinementsButton
                  buttonText="Clear"
                  clearsQuery={true}
                  onClear={handleClearAllSearch}
                  bootstrapClasses="px-3 btn btn-sm btn-outline-info text-nowrap"
                />
              </>
            ),
          }}
        </MenuBar>

        <div className="row mb-2">
          <div className="col pr-4 text-right">
            &nbsp;
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-3 pr-0">
            <div className="card mb-3">
              <div className="card-body">
                <div className="ais-Panel">
                  <div className="ais-Panel-header">
                    Top PRs
                    &nbsp;
                    <i
                      id="TooltipTopPr"
                      className="fa fa-circle-question ml-2"
                      style={{ cursor: 'pointer' }}
                    />
                    <Tooltip
                      autohide={false}
                      isOpen={tooltipOpen}
                      target="TooltipTopPr"
                      toggle={toggleTooltip}
                    >
                      The <a href="https://www.qwoted.com/qwoted-100/" target="_blank" rel="noopener noreferrer">Qwoted
                      100</a> is a quarterly ranking of the best PRs working in the industry today.
                    </Tooltip>
                  </div>
                  <div className="ais-Panel-body">
                    <ul className="ais-RefinementList-list">
                      <li className="ais-RefinementList-item">
                        <label className="ais-RefinementList-label" htmlFor="topPrCheckbox">
                          <input
                            type="checkbox"
                            className="ais-RefinementList-checkbox"
                            id="topPrCheckbox"
                            checked={topPrFilter}
                            onChange={handleToggleTopPr}
                          />
                          <span className="ais-RefinementList-labelText">Top 100</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {topPrFilter && (
              <Configure filters="top_pr:true" />
            )}
            <BeatsFilterPanel
              attribute="_tags"
              transformItems={(items) => orderBy(items, 'label', 'asc')}
              clearMenuToggle={clearMenuToggle}
              onMenuToggleCleared={handleClearMenuToggle}
            />
            <GeoFilterPanel clearGeoSearch={clearGeoSearch} onGeoSearchCleared={handleClearGeoSearch} />
            <CompanyFilterPanel />
            <TitleFilterPanel />
            <HashtagFilterPanel />
            <GenderFilterPanel />
          </div>
          <div className="col">
            <InfiniteHits />
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

PrUsersSearch.propTypes = {
  view: PropTypes.string,
  query: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  hideTabs: PropTypes.bool,
};

PrUsersSearch.defaultProps = {
  view: 'prs',
  query: '',
  hideTabs: false,
};

export default PrUsersSearch;
