import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';
import {
  FormGroup,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from 'reactstrap';
import { convertStringToBool } from '../utilities';
import vapi from '../../javascript/frontend/api/vapi';

function OpenToJobs({ userId }) {
  const [openToJobs, setOpenToJobs] = useState();
  const [dropdownOpen, toggleDropdownOpen] = useState(false);
  const [showAlert, toggleAlert] = useState(false);

  const handleResponse = (res) => {
    if (res.status === 200) {
      let openToJobsResValue = res.data.data.attributes.open_to_jobs;
      openToJobsResValue = convertStringToBool(openToJobsResValue);

      setOpenToJobs(openToJobsResValue);
      toggleAlert(true);
    }
  };

  const updateServer = (openToJobsValue) => {
    const userParams = {
      id: userId,
      attributes: { open_to_jobs: openToJobsValue },
    };

    vapi.updateUser(userParams)
      .then((r) => handleResponse(r));
  };

  // Mounting funcs
  useEffect(() => {
    const setOpenToJobsFromLink = () => {
      const uri = URI(window.location.href);
      let openToJobsFromLink = uri.search(true).open_to_jobs;
      openToJobsFromLink = convertStringToBool(openToJobsFromLink);

      if (openToJobsFromLink !== undefined) {
        setOpenToJobs(openToJobsFromLink);
        updateServer(openToJobsFromLink);
      }
    };

    const getCurrentOpenToJobsValue = () => {
      vapi.getUser(userId)
        .then((r) => {
          if (r.status === 200 && r.data.data.attributes.open_to_jobs) {
            const result = r.data.data.attributes.open_to_jobs;
            setOpenToJobs(convertStringToBool(result));
          }
        });
    };
    setOpenToJobsFromLink();
    getCurrentOpenToJobsValue();
  });

  const handleClick = (e) => {
    e.preventDefault();
    let userValue = e.target.value;
    userValue = convertStringToBool(userValue);

    setOpenToJobs(userValue);
    updateServer(userValue);
  };

  const toggleDropdown = () => {
    toggleDropdownOpen(!dropdownOpen);
  };

  const onDismiss = () => {
    toggleAlert(false);
  };

  return (
    <div style={{ marginTop: '1.5rem' }} className="card flex-card p-2">
      <FormGroup className="required">
        <h4>Open to Work?</h4>

        <Label>
          Do you want to receive new job opportunities from Qwoted?
        </Label>

        <Alert color="success" fade={true} toggle={onDismiss} isOpen={showAlert}>
          <p className="mb-0">You selected to {openToJobs === true ? 'opt in to' : 'opt out of'} new job
            listings.
          </p>
        </Alert>

        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>

          <DropdownToggle caret>
            {openToJobs ? 'Yes' : 'No'}
          </DropdownToggle>

          <DropdownMenu>
            <DropdownItem onClick={handleClick} value={true}>
              Yes.
            </DropdownItem>

            <DropdownItem onClick={handleClick} value={false}>
              No.
            </DropdownItem>
          </DropdownMenu>

        </Dropdown>
      </FormGroup>
    </div>
  );
}

OpenToJobs.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default OpenToJobs;
