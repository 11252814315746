import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/avatar';
import SmartHighlight from '../../algolia_search/smart_highlight';

function NamePhotoPrice({ product }) {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <>
      <div>
        <div className="float-start pe-2">
          <Avatar
            avatarUrl={product.avatar_url}
            avatarLetters={product.initials || '--'}
            sizeClass="avatar"
          />
        </div>
        <div>
          <h5 className="mb-0 fw-bold">
            <a href={product.product_path}>
              <SmartHighlight attribute="name" hit={product} />
            </a>
          </h5>
          {product.price && <h6>{USDollar.format(product.price)}</h6>}
        </div>
      </div>
      <div className="clearfix mb-2" />
    </>
  );
}

NamePhotoPrice.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

export default NamePhotoPrice;
