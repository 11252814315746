import React from 'react';
import {
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';
import { orderBy } from 'lodash';

function GenderFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body ais-hide-counts">
        <Panel header="Filter by gender">
          <RefinementList
            attribute="gender"
            limit={6}
            showMore={false}
            searchable={false}
            transformItems={(items) => orderBy(items, 'label', 'asc')}
          />
        </Panel>
      </div>
    </div>
  );
}

export default GenderFilterPanel;
