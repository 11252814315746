import React from 'react';
import PropTypes from 'prop-types';
import vapi from '../javascript/frontend/api/vapi';
import vahoy from '../javascript/vahoy';

class Flagging extends React.Component {
  // props:
  // flaggable (json)
  // flaggable_type (string)

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      editing: false,
      flag: null,
    };

    this.flagPersisted = this.flagPersisted.bind(this);
    this.startEditing = this.startEditing.bind(this);
    this.stopEditing = this.stopEditing.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeFlag = this.removeFlag.bind(this);
    this.renderInfo = this.renderInfo.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  componentDidMount() {
    const { flaggable } = this.props;

    if (!flaggable.relationships.flag) {
      this.setState({
        loading: false,
      });

      return;
    }

    const flagApiPath = flaggable.relationships.flag.links.related;
    vapi.getFlag(flagApiPath)
      .then((response) => {
        this.setState({
          flag: response.data.data,
          loading: false,
        });
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.stopEditing();
    const { flaggable, flaggable_type: flaggableType } = this.props;
    const { flag } = this.state;

    const newData = flag ? flag.attributes : {};
    newData.severity = this.severityInput.value;
    newData.details = this.detailsInput.value;
    newData.flaggable_id = flaggable.id;
    newData.flaggable_type = flaggableType;
    delete newData.created_by_name;
    delete newData.updated_by_name;

    const mergedFlag = _.extend({}, flag);
    mergedFlag.attributes = newData;

    if (this.flagPersisted()) {
      vapi.updateFlag(mergedFlag)
        .then((response) => {
          this.setState({
            flag: response.data.data,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            editing: false,
            loading: false,
          });
          throw new Error(`Flagging#handleSubmit: ${error}`);
        });

      vahoy.track('Flagging#updateFlag');
    } else {
      vapi.createFlag(mergedFlag)
        .then((response) => {
          this.setState({
            flag: response.data.data,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            editing: false,
            loading: false,
          });
          throw new Error(`Flagging#handleSubmit: ${error}`);
        });

      vahoy.track('Flagging#createFlag');
    }
  }

  startEditing() {
    this.setState({ editing: true });
    vahoy.track('Flagging#startEditing');
  }

  stopEditing(e = null) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ editing: false });
  }

  flagPersisted() {
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.flag && this.state.flag.id;
  }

  removeFlag() {
    const r = window.confirm('Are you sure?');
    if (r === true) {
      this.stopEditing();

      // eslint-disable-next-line react/destructuring-assignment
      vapi.deleteFlag(this.state.flag)
        .then(() => {
          this.setState({
            flag: null,
          });
        });

      vahoy.track('Flagging#removeFlag');
    }
  }

  renderInfo() {
    // eslint-disable-next-line react/destructuring-assignment
    const flagAttrs = this.state.flag.attributes;
    const needsAttention = !flagAttrs.details;
    const that = this;

    return (
      <div className="alert alert-warning flag">

        <h2 className="mb-3">
          <i className="fa-solid fa-lg fa-flag" />&nbsp;
          Flagged for review
        </h2>

        {needsAttention
          && (
            <div>
              It's not clear why this is flagged.
              <span
                role="button"
                tabIndex="-1"
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  that.startEditing();
                }}
                onKeyUp={(evt) => evt.keyCode === 13 && that.startEditing()}
              > Can you provide details?
              </span>
            </div>
          )}

        {flagAttrs.severity
          && (
            <dl className="row">
              <dt className="col-md-2">Severity</dt>
              <dd className="col-md-10">{flagAttrs.severity}</dd>
            </dl>
          )}

        {flagAttrs.details
          && (
            <dl className="row">
              <dt className="col-md-2">Details</dt>
              <dd className="col-md-10">{flagAttrs.details}</dd>
            </dl>
          )}

        {flagAttrs.created_by_name
          && (
            <dl className="row">
              <dt className="col-md-2">Created by</dt>
              <dd className="col-md-10">{flagAttrs.created_by_name}</dd>
            </dl>
          )}

        {flagAttrs.updated_by_name
          && (
            <dl className="row">
              <dt className="col-md-2">Last updated by</dt>
              <dd className="col-md-10">{flagAttrs.updated_by_name}</dd>
            </dl>
          )}

        <button
          type="button"
          className="btn btn-outline-secondary btn-outline-primary btn-sm"
          onClick={this.startEditing}
        >
          <i className="fa-solid fa-pen-to-square" />&nbsp;
          Edit flag
        </button>

        &nbsp;

        <button
          type="button"
          className="btn btn-confirm btn-outline-secondary btn-outline-danger btn-sm"
          onClick={this.removeFlag}
        >
          <i className="fa-solid fa-trash-can" />&nbsp;
          Remove flag
        </button>

      </div>
    );
  }

  renderForm() {
    const { flag_severities_array: severities, flaggable_type: flaggableType } = this.props;
    const { flag } = this.state;

    return (
      <div className="alert alert-warning">
        <h2><i className="fa-solid fa-lg fa-flag" /> {`Flag ${flaggableType}`}</h2>

        <form onSubmit={this.handleSubmit}>
          <div className="mb-3">
            <label htmlFor="severity" className="col-form-label">Severity</label>

            <select
              name="severity"
              id="severity"
              className="form-control"
              defaultValue={flag ? flag.attributes.severity : 'medium'}
              ref={(severityInput) => {
                this.severityInput = severityInput;
              }}
            >
              {
                severities.map((severity) => (
                  <option
                    key={severity}
                    value={severity}
                  >{severity}
                  </option>
                ))
              }
            </select>

          </div>
          <div className="mb-3">
            <label htmlFor="details" className="col-form-label">Details</label>

            <textarea
              name="details"
              id="details"
              className="form-control"
              defaultValue={flag ? flag.attributes.details : ''}
              ref={(detailsInput) => {
                this.detailsInput = detailsInput;
              }}
            />

          </div>
          <div className="float-md-end">
            <button
              type="button"
              className="cancel-button btn btn-link"
              onClick={this.stopEditing}
            >Cancel
            </button>
            &nbsp;

            <input
              type="submit"
              className="btn btn-outline-primary"
              value={this.flagPersisted() ? 'Update' : 'Create'}
            />

          </div>

          <div className="clearfix" />
        </form>
      </div>
    );
  }

  renderButton() {
    return (
      <button
        type="button"
        className="form btn btn-staff btn-md"
        onClick={this.startEditing}
      >
        <i className="fa-solid fa-lg fa-flag" />&nbsp;
        Flag for review
      </button>
    );
  }

  render() {
    const { loading, editing, flag } = this.state;

    if (loading) {
      return null;
    }
    if (editing) {
      return this.renderForm();
    }
    if (flag) {
      return this.renderInfo();
    }
    return this.renderButton();
  }
}

Flagging.propTypes = {
  flaggable: PropTypes.instanceOf(Object).isRequired,
  flaggable_type: PropTypes.string.isRequired,
  flag_severities_array: PropTypes.arrayOf(String).isRequired,
};

export default Flagging;
