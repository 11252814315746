import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import BillingPlanChanger from './_billing_plan_changer';

class PlanChangerWrapper extends React.Component {
  STRIPE_PROMISE = loadStripe(this.props.stripePublishableKey);

  constructor(props) {
    super(props);
    this.state = { wasSuccessful: false };
  }

  componentWillUnmount() {
    const { wasSuccessful } = this.state;
    if (!wasSuccessful) {
      // This is an intentional location reload meant to unload the Stripe
      // iframe. There's no need to keep Stripe loaded in memory once we
      // leave the payment or payment method update page:
      // https://github.com/stripe/react-stripe-elements/issues/99
      window.location.reload();
    }
  }

  handleSuccess = () => {
    this.setState({ wasSuccessful: true });
  };

  render() {
    const {
      accountCode,
      newSourcePlan,
      oldSourcePlan,
      oldPlanPitches,
      oldPlanUnlimited,
      oldPlanDiscontinued,
      willLoseDiscount,
      nextBillDateString,
      newMonthlyChargeString,
      successUrl,
      interval,
    } = this.props;

    return (
      <Elements stripe={this.STRIPE_PROMISE}>
        <BillingPlanChanger
          accountCode={accountCode}
          newSourcePlan={newSourcePlan}
          oldSourcePlan={oldSourcePlan}
          oldPlanPitches={oldPlanPitches}
          oldPlanUnlimited={oldPlanUnlimited}
          oldPlanDiscontinued={oldPlanDiscontinued}
          willLoseDiscount={willLoseDiscount}
          nextBillDateString={nextBillDateString}
          newMonthlyChargeString={newMonthlyChargeString}
          successUrl={successUrl}
          onSuccess={this.handleSuccess}
          interval={interval}
        />
      </Elements>
    );
  }
}

PlanChangerWrapper.propTypes = {
  stripePublishableKey: PropTypes.string.isRequired, // Stripe public key to pass to the loadStripe method
  accountCode: PropTypes.number.isRequired,
  newSourcePlan: PropTypes.number.isRequired,
  oldSourcePlan: PropTypes.number.isRequired,
  oldPlanPitches: PropTypes.number.isRequired,
  oldPlanUnlimited: PropTypes.bool,
  oldPlanDiscontinued: PropTypes.bool,
  willLoseDiscount: PropTypes.bool,
  nextBillDateString: PropTypes.string.isRequired,
  newMonthlyChargeString: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
  interval: PropTypes.string,
};

PlanChangerWrapper.defaultProps = {
  oldPlanUnlimited: false,
  oldPlanDiscontinued: false,
  willLoseDiscount: false,
  interval: 'month',
};

export default PlanChangerWrapper;
