import React from 'react';
import PropTypes from 'prop-types';

function ArticleFields({
                     setArticleUrl,
                   }) {
  return (
    <div className="mb-3 col-md-8">
      <label htmlFor="articleUrl" className="fw-bold mb-1">
        Share an article you wrote
        <span style={{ color: '#E71E42' }}> &#42;</span>
      </label>
      <input
        className="mb-1 form-control"
        id="article_url"
        name="article_url"
        onInput={(e) => setArticleUrl(e.target.value)}
        placeholder="Link to the published article"
        required
        type="text"
      />
    </div>
  );
}

 ArticleFields.propTypes = {
  setArticleUrl: PropTypes.func,
};

 ArticleFields.defaultProps = {
  setArticleUrl: undefined,
};

export default ArticleFields;
