import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import ReportersSearch from './search/reporters_search';

function TopLevelSearch({
                          query, algoliaAppId, algoliaSearchKey, indexName, displayContactAndInfo, displayFilters,
                        }) {
  return (
    <ErrorBoundary>
      <ReportersSearch
        query={query}
        algoliaAppId={algoliaAppId}
        algoliaSearchKey={algoliaSearchKey}
        indexName={indexName}
        displayContactAndInfo={displayContactAndInfo}
        displayFilters={displayFilters}
      />
    </ErrorBoundary>
  );
}

TopLevelSearch.propTypes = {
  query: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  displayContactAndInfo: PropTypes.bool,
  displayFilters: PropTypes.bool,
};

TopLevelSearch.defaultProps = {
  query: '',
  displayContactAndInfo: true,
  displayFilters: false,
};

export default TopLevelSearch;
