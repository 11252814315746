import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ClipboardJS from 'clipboard';

function InviteButton({ inviteUrl, size }) {
  const [showLink, setShowLink] = useState(false);
  const [showFlash, setShowFlash] = useState(false);

  useEffect(() => {
    const newClipboard = new ClipboardJS('#invite-link-copy');
    newClipboard.on('success', () => {
      setShowFlash(true);
      setTimeout(() => {
        setShowFlash(false);
      }, 2000);
    });

    newClipboard.on('error', () => {
    });

    return () => {
      newClipboard.destroy();
    };
  }, []);

  const showLinkFn = (e) => {
    e.preventDefault();
    setShowLink(true);
  };

  const renderButton = () => (
    <div className="float-end d-xl-inline w-xl-auto w-md-100 mt-md-2">
      <h5 className="my-1 d-xl-inline float-start">
        <span className="mt-2 float-start px-2"> Don't see what you're looking for?</span>
        <button
          type="button"
          className="btn ml-2 mt-md-1 btn-large btn-primary float-end text-white"
          onClick={(e) => showLinkFn(e)}
        >Invite To Qwoted
        </button>
      </h5>
    </div>
  );

  const renderLink = () => (
    <div className="float-end d-xl-inline w-xl-auto w-md-100 mt-md-2 animated fadeIn">
      <h5 className="my-2 d-xl-inline float-start position-relative">
        {showFlash && <span className="position-absolute text-primary"> Copied! </span>}

        <span className="mt-3 float-start font-weight-bold"> Invite anyone with this link</span>

        <span className="mt-md-1 float-end">
          <input
            id="invite-link"
            className="d-inline border rounded p-2 w-80 mx-2"
            value={inviteUrl}
            readOnly
            size={size}
          />
          <button
            type="button"
            id="invite-link-copy"
            className="d-inline border-0 bg-light float-end"
            data-clipboard-target="#invite-link"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="manual"
            title="Click To Copy"
          >
            <i className="fa-solid fa-paste ml-2 fa-2x text-primary d-inline bg-0 mt-1" alt="Copy to clipboard" />
          </button>
        </span>
      </h5>
    </div>
  );

  return (
    <>
      {
        showLink ? renderLink() : renderButton()
      }
    </>
  );
}

InviteButton.propTypes = {
  inviteUrl: PropTypes.string,
  size: PropTypes.string,
};

InviteButton.defaultProps = {
  inviteUrl: '',
  size: '50',
};

export default InviteButton;
