/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import sourceRequestDistributionImg from '../../../assets/images/source-request-distribution.png';

function ConcernedAboutRevealingModal({ isOpen, toggle }) {
  return (
    <Modal
      className="modal-lg "
      isOpen={isOpen}
      toggle={toggle}
      style={{ border: 'none' }}
    >
      <div className="p-3 p-md-5">
        <button
          onClick={() => toggle()}
          type="button"
          className="btn-close float-end"
        />

        <h2 className="fw-bold mb-4">
          Concerned About Revealing Your Story Idea?
        </h2>

        <p>
          You can source expert commentary without revealing details about your
          story. Instead of describing your angle, emphasize the type of expertise
          you need.
        </p>

        <div className="w-75 d-flex flex-column align-content-center justify-content-center mx-auto mt-4 mb-2">
          <p className="text-font-grey">
            For example, instead of mentioning your interview topic:
            <br />
            <span className="fw-bold">
              "Epidemiology expert needed for an interview on pandemic
              preparedness."
            </span>
          </p>

          <p className="text-font-grey">
            Focus on the experts you'd like to interview:
            <br />
            <span className="fw-bold text-success">
              "Seeking an epidemiology or public health expert."
            </span>
          </p>
        </div>

        <p>
          If you prefer even more discretion, check the box at the bottom of the
          form that tells us not to promote your request outside of the Qwoted
          platform:
        </p>

        <div className="d-flex">
          <img
            src={sourceRequestDistributionImg}
            alt=""
            className="img-fluid w-75 border rounded mx-auto mt-3 shadow"
          />
        </div>
      </div>
    </Modal>
  );
}

ConcernedAboutRevealingModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

ConcernedAboutRevealingModal.defaultProps = {
  isOpen: undefined,
  toggle: undefined,
};

export default ConcernedAboutRevealingModal;
