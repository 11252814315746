import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import AutocompletePlaceInput from './autocomplete_place_input';
import connectGeoSearch from './connectors/geo_search_connector';

function GeoSearchByPlace({ refine, clearGeoSearch, onGeoSearchCleared }) {
  const handlePlaceFilterChange = useCallback((locationParams) => {
    refine(locationParams);
  }, [refine]);

  useEffect(() => {
    if (clearGeoSearch) {
      onGeoSearchCleared();
      handlePlaceFilterChange({});
    }
  }, [clearGeoSearch, handlePlaceFilterChange, onGeoSearchCleared]);

  return (
    <AutocompletePlaceInput onChange={handlePlaceFilterChange} clearGeoSearch={clearGeoSearch} />
  );
}

GeoSearchByPlace.propTypes = {
  refine: PropTypes.func.isRequired,
  clearGeoSearch: PropTypes.bool.isRequired,
  onGeoSearchCleared: PropTypes.func.isRequired,
};

export default connectGeoSearch(GeoSearchByPlace);
