import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import preparePitchedEntitiesForSubmission from './pitched_entity';

// Port submit method from pitch/form.jsx which contains the logic for creating sources, source employments
// and the like ...
const completePitch = async (
  sourceRequestId,
  pitchId,
  pitchText,
  pitchPublicizable,
  promoEntities,
  attachedFileUploads,
) => {
  const pitchAttributes = {
    attachments_attributes: attachedFileUploads,
    draft: false,
    id: pitchId,
    pitch: pitchText,
    pitched_entities: await preparePitchedEntitiesForSubmission(promoEntities),
    pitch_publicizable: pitchPublicizable,
    source_request_id: sourceRequestId,
  };

  await vapi.updatePitch(pitchAttributes);
  vahoy.track('PitchForm#submitPitch');
};

export default completePitch;
