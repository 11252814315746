// Solution for small issue: 100vw is not guaranteed to be 100% of the viewport width, because it does not account for
// the width of the vertical scrollbars. document.documentElement.clientWidth gives the correct value, which then allows
// for divs to be set the true 100% width of a page without triggering a horizontal scrollbar.
// https://stackoverflow.com/a/56254633

function setVw() {
  const vw = document.documentElement.clientWidth / 100;
  document.documentElement.style.setProperty('--vw', `${vw}px`);
}

window.addEventListener('DOMContentLoaded', setVw);
window.addEventListener('resize', setVw);
