import helper from '../../helpers/helper';

document.addEventListener('turbolinks:load', () => {
  if (helper.notPresent($('.js-search-form'))) {
    return;
  }

  const $inputNear = $('input#near');
  let autocomplete;

  // Event handler for Google Autocomplete widget
  function onPlaceChanged() {
    const place = autocomplete.getPlace();
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    if (lat && lng) {
      $('input#latitude').val(lat);
      $('input#longitude').val(lng);
      $('.js-search-form .submit').prop('disabled', false);
    } else { // reset field
      $('input#near').val('');
      $('.js-search-form .submit').prop('disabled', false);
    }
  }

  // Initialize the Google Autocomplete widget
  if ($('input#near').length === 1) { // Initialize Google Autocomplete Widget
    autocomplete = new window.google.maps.places.Autocomplete(
      $inputNear[0],
      { types: ['(cities)'] },
    );
    autocomplete.addListener('place_changed', onPlaceChanged);
  }

  // Do not submit the form if enter is pressed while navigating the Google autocomplete widget
  // https://stackoverflow.com/questions/11388251/google-autocomplete-enter-to-select
  $inputNear.keydown((e) => {
    if (e.which === 13 && $('.pac-container:visible').length) return false;
  });

  // Form validation code related to Google Autocomplete widget
  // eslint-disable-next-line func-names
  $inputNear.bind('input', function () {
    const $searchSubmit = $('.js-search-form .submit');

    $searchSubmit.prop('disabled', true);
    if ($(this).val().length === 0) {
      $searchSubmit.prop('disabled', false);
    }
  });
});
