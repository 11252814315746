import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {

  createUserSearch(attrs) {
    vlog.info('api#createUserSearch');

    api.configure();

    const url = '/api/internal/jsonapi/user-searches';
    const config = api.axiosFix || {};
    const data = {
      type: 'user_searches',
      attributes: attrs,
    };

    return axios.post(url, { data }, config);
  },

});
