/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveAvatar from '../../responsive_avatar';
import RepButton from './rep_button';

function SelectableUserCard({
                              promoEntityId,
                              promoEntityType,
                              user,
                              repped,
                              representationId,
                              handleChange,
                            }) {
  const parsedEmail = user.email.replace(/.*@/, '');
  return (
    <div className="card h-100 rounded-0 border-0 shadow user-card">
      <div className="card-body rounded-0 p-0 position-relative">
        <div className="row">
          <div className="col-4">
            <ResponsiveAvatar
              avatarUrl={user.avatar_url}
              avatarLetters={user.initials}
              className="mb-0 rounded-0"
            />
          </div>
          <div className="col-8">
            <h3 className="p-3 name-bar">{user.full_name}</h3>
            <h4 className="company-name mt-4">
              {user.client_firm_name ? user.client_firm_name : parsedEmail}
            </h4>
            <RepButton
              promoEntityId={promoEntityId}
              promoEntityType={promoEntityType}
              userId={Number(user.objectID)}
              repped={repped}
              representationId={representationId}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

SelectableUserCard.propTypes = {
  user: PropTypes.instanceOf(Object),
  promoEntityId: PropTypes.number.isRequired,
  promoEntityType: PropTypes.string.isRequired,
  repped: PropTypes.bool.isRequired,
  representationId: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
};

SelectableUserCard.defaultProps = {
  user: undefined,
  representationId: undefined,
};

export default SelectableUserCard;
