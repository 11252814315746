import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { useDebounce } from 'use-lodash-debounce';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import NoResultsMessage from '../../algolia_search/no_results_message';
import SearchLoadingCards from './search_loading_cards';
import ReporterCard from '../card/reporter_card';
import ReporterSkeletonPage from '../index/reporter_skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import EmptyResults from './empty_results';

function InfiniteHits({
                        hasMore, refine, hits, displayContactAndInfo,
                      }) {
  // Ignore scrollContainerRef since we want to use root as the scrolling container
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });
  const [qwotedRecords, setQwotedRecords] = useState({});
  const [idsToLookup, setIdsToLookup] = useState([]);
  const [asyncError, setAsyncError] = useState();

  // Effect to refine results when infinite scroll triggers
  useEffect(() => {
    if (lastTriggeredAt > 0) refine();
  }, [lastTriggeredAt, refine]);

  // Effect to find new Algolia hit records that haven't yet been updated from Qwoted DB
  // Using the debounced list makes search-while-typing more responsive and limits the number
  // of queries hitting the Qwoted API.
  const debouncedHits = useDebounce(hits, 700);

  useEffect(() => {
    const missingIds = debouncedHits.filter((h) => qwotedRecords[h.objectID] === undefined).map((h) => h.objectID);
    setIdsToLookup((prevList) => [...prevList, ...missingIds]);
  }, [debouncedHits, qwotedRecords]);

  // Effect to perform lookup of yet-to-be Qwoted-filled records and populate state with results
  useEffect(() => {
    const fetchRecords = async () => {
      try {
        if (idsToLookup.length > 0) {
          vahoy.track('ReportersSearch#getAlgoliaAttribs', { reporterIds: idsToLookup });
          const response = await vapi.getAlgoliaAttribsForReporterIds(idsToLookup);
          if (response.status === 200) {
            const sourceResults = response.data;
            if (sourceResults.data && sourceResults.data.length > 0) {
              const recordsToAdd = {};
              sourceResults.data.forEach((source) => {
                recordsToAdd[source.id] = source;
              });
              setIdsToLookup([]);
              setQwotedRecords((prevList) => Object.assign(recordsToAdd, prevList));
            }
          }
        }
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchRecords();
  }, [idsToLookup]);

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;
  const customMessage = window.CURRENT_USER_CLIENT_SESSION ? <EmptyResults /> : undefined;

  return (
    <div className="container-fluid">
      <NoResultsMessage customMessage={customMessage} />
      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 px-1">
        <SearchLoadingCards />
        {hits.map((reporter) => (
          <React.Fragment key={reporter.objectID}>
            <div className="col px-2 pb-3 pt-0">
              <ReporterCard
                reporter={reporter}
                updates={qwotedRecords[reporter.objectID]}
                displayContactAndInfo={displayContactAndInfo}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
      {hasMore && (
        <div
          ref={scrollTriggerRef}
          className="row row-cols-1 row-cols-md-2 row-cols-xl-3 px-1"
        >
          <ReporterSkeletonPage numberOfCards={3} singleRow />
        </div>
      )}
    </div>
  );
}

InfiniteHits.propTypes = {
  hasMore: PropTypes.bool,
  refine: PropTypes.func,
  hits: PropTypes.arrayOf(Object),
  displayContactAndInfo: PropTypes.bool,
};

InfiniteHits.defaultProps = {
  hasMore: false,
  refine: undefined,
  hits: [],
  displayContactAndInfo: true,
};

export default connectInfiniteHits(InfiniteHits);
