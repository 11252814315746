import { useEffect, useState } from 'react';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';

const useAugmentedProductHits = (hits) => {
  const [augmentedHits, setAugmentedHits] = useState([]);
  const [asyncError, setAsyncError] = useState();

  useEffect(() => {
    const fetchAdditionalData = async () => {
      const ids = hits.map((hit) => hit.objectID);
      if (ids.length > 0) {
        try {
          vahoy.track('ProductsSearch#getAlgoliaAttribs', { productRequestids: ids });
          const response = await vapi.getAlgoliaAttribsForProductIds(ids);
          if (response.status === 200) {
            const productResults = response.data.data;
            const updatedHits = hits.map((hit) => {
              const additionalData = productResults.find((data) => data.id === hit.objectID);
              return additionalData ? { ...hit, ...additionalData.attributes } : hit;
            });
            setAugmentedHits(updatedHits);
          }
        } catch (error) {
          setAsyncError(error);
        }
      }
    };

    fetchAdditionalData();
  }, [hits]);

  if (asyncError) throw asyncError;
  return augmentedHits;
};

export default useAugmentedProductHits;
