import React from 'react';
import _ from 'lodash';
import {
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

function ApprovedFilterPanel({ customClass, defaultRefinement }) {
  const transformItems = (items) => items.map((item) => ({
    ...item,
    label: _.startCase(item.label),
  }));

  return (
    <div className={`card bg-transparent border-0 mb-3 ${customClass}`}>
      <div className="card-body ais-hide-counts">
        <Panel header="Filter by approval">
          <RefinementList
            attribute="approval"
            defaultRefinement={defaultRefinement}
            facetOrdering={false}
            limit={2}
            transformItems={transformItems}
          />
        </Panel>
      </div>
    </div>
  );
}

ApprovedFilterPanel.propTypes = {
  customClass: PropTypes.string,
  defaultRefinement: PropTypes.instanceOf(Object),
};

ApprovedFilterPanel.defaultProps = {
  customClass: undefined,
  defaultRefinement: undefined,
};

export default ApprovedFilterPanel;
