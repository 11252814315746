import * as cloud from 'd3-cloud';
import * as d3 from 'd3';
import React from 'react';
import PropTypes from 'prop-types';

class WordsCloud extends React.Component {
  constructor(props) {
    super(props);
    // tableau.Tableau10 theme from chartjs-plugin-colorschemes which, we use also in chart_js.jsx
    this.colorscheme = ['#4E79A7', '#F28E2B', '#E15759', '#76B7B2', '#59A14F', '#EDC948', '#B07AA1', '#FF9DA7',
      '#9C755F', '#BAB0AC'];
  }

  componentDidMount() {
    // Note this is "within" the SVG and are relative, as the SVG is scaled into the page using the viewBox attr:
    // https://css-tricks.com/scale-svg/
    this.width = 1000;
    this.height = 2000;

    let minFontSize = 0;
    const { words } = this.props;
    if (words.length < 30) {
      minFontSize = 80;
    }

    const layout = cloud()
      .size([this.width * 1.15, this.height * 1.15])
      // eslint-disable-next-line react/destructuring-assignment
      .words(this.props.words)
      .rotate(() => (Math.floor(Math.random() * 6) - 3) * 30)
      .font('Impact')
      .fontSize((d) => {
        const sqrt = Math.sqrt(d.count * 1000);
        if (sqrt < minFontSize) {
          return minFontSize;
        }
        return sqrt;
      })
      .on('end', this.draw);

    layout.start();
  }

  randomColour = () => {
    const { length } = this.colorscheme;
    return this.colorscheme[Math.floor(Math.random() * length)];
  };

  draw = () => {
    d3.select('#words-cloud')
      .append('svg')
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`)
      .selectAll('text')
      // eslint-disable-next-line react/destructuring-assignment
      .data(this.props.words)
      .enter()
      .append('text')
      .style('font-size', (d) => `${d.size}px`)
      .style('font-family', 'Impact')
      .style('fill', this.randomColour)
      .style('cursor', 'pointer')
      .attr('text-anchor', 'middle')
      .attr('transform', (d) => `translate(${[d.x, d.y]})rotate(${d.rotate})`)
      .text((d) => d.text);
  };

  render() {
    return <div id="words-cloud" />;
  }
}

WordsCloud.propTypes = {
  words: PropTypes.arrayOf(Object).isRequired,
};

export default WordsCloud;
