import React from 'react';
import PropTypes from 'prop-types';

function StatusFooter({ source, smallMode }) {
  if (!source.frequent_source && !source.quick_to_respond) return null;

  const baseBadgeStyle = `badge ${smallMode ? 'font-size-9px' : 'font-size-10px'} fw-bold mt-1`;

  return (
    <div className={`card-footer ${smallMode ? 'pt-0 pb-1' : 'pt-1 pb-2'} bg-transparent border-1`}>
      {source.quick_to_respond && (
        <span
          className={`${baseBadgeStyle} bg-success me-1 status-badge-quick-to-respond`}
          style={{ borderRadius: '3px' }}
        >
          Quick to Respond
        </span>
      )}

      {source.frequent_source && (
        <span
          className={`${baseBadgeStyle} bg-info me-1 status-badge-frequent-source`}
          style={{ borderRadius: '3px' }}
        >
          Frequent Source
        </span>
      )}
    </div>
  );
}

StatusFooter.propTypes = {
  source: PropTypes.instanceOf(Object).isRequired,
  smallMode: PropTypes.bool,
};

StatusFooter.defaultProps = {
  smallMode: false,
};

export default StatusFooter;
