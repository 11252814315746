import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import URI from 'urijs';
import Item from './_item';
import helper from '../../../javascript/frontend/helpers/helper';
import opportunityHelpers from '../../../javascript/frontend/helpers/opportunity_helpers';
import vahoy from '../../../javascript/vahoy';

class OpportunityCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.calendarRef = React.createRef();

    this.state = {
      opportunities: props.opportunities,
    };
  }

  componentDidMount() {
    this.setupFullCalendar();
  }

  getCalendar = () => this.calendarRef.current;

  setupFullCalendar = () => {
    const that = this;
    const { opportunities } = this.state;

    // TODO Sanity check? https://teamgaslight.com/blog/wrapping-jquery-with-react

    // the start_date in the URL parameter overrides the current month, if present
    let defaultDate = moment().startOf('month');
    const queryStringDate = helper.urlParamsHash().start_date;
    if (queryStringDate) {
      defaultDate = queryStringDate;
    }

    const $calendar = $(this.getCalendar());

    $calendar.fullCalendar({
      plugins: ['momentTimezone'],
      customButtons: {
        loadingIndicator: {
          text: '',
        },
        spacer: {
          text: '',
        },
      },
      displayEventTime: false,
      defaultDate,
      eventLimit: true,
      eventRender(item, element) {
        that.renderItem(item, element);
      },
      events(start, end, timezone, callback) {
        if (typeof that.initialStart === 'undefined') {
          that.initialStart = start;
        }

        const { firstDate, lastDate } = opportunityHelpers.firstAndLastDateInOpportunitiesArray(opportunities);

        if (firstDate && lastDate && start.isBefore(firstDate) && end.isAfter(lastDate)) {
          callback(opportunityHelpers.mapApiOpportunitiesToFullcalendarEvents(opportunities));
        } else {
          // do not make an ajax call on the first load, if there are no opportunities
          if (start === that.initialStart) {
            callback([]);
            return;
          }

          that.search(start, end, callback);
        }
      },
      header: {
        left: 'prev,next today loadingIndicator',
        center: 'title',
        right: 'spacer',
      },
      loading(bool) {
        if (bool) {
          $('.fc-loadingIndicator-button').show();
        } else {
          $('.fc-loadingIndicator-button').hide();
        }
      },
      timezone: moment().tz(),
    });

    $('.fc-loadingIndicator-button').html(
      `<i class='fa ${window.SITE_DEFAULT_ANIMATED_SPINNER} fa-fw'></i>`,
    );
  };

  buildSearchURI = (start, end) => {
    const {
      opportunity_types: opportunityTypes,
      query,
    } = this.props;

    const uri = new URI(window.location.href).removeSearch('page').removeSearch('display_type');
    uri.addSearch(
      {
        'filter[after_opportunity_date]': start.toISOString(),
        'filter[before_opportunity_date]': end.toISOString(),
        'filter[opportunity_types]': opportunityTypes,
        'page[limit]': window.MAXIMUM_PERFORMANT_API_PAGE_SIZE,
      },
    );
    uri.path('/api/internal/jsonapi/opportunities.json');

    if (query) {
      // DELETEME: https://github.com/cerebris/jsonapi-resources/issues/628
      const queryWithoutDoubleQuotes = query.replace(/"/g, '\'');

      uri.addSearch({ 'filter[query]': queryWithoutDoubleQuotes });
    }

    return uri.toString();
  };

  // eslint-disable-next-line
  search = (start, end, callback) => {
    const uri = this.buildSearchURI(start, end);

    $.ajax(uri)
      .done((data) => {
        callback(opportunityHelpers.mapApiOpportunitiesToFullcalendarEvents(data.data));

        // refresh opportunities when navigating, do not reuse from initial load
        this.setState({ opportunities: [] });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        $('.fc-loadingIndicator-button').hide();
        // TODO: fix the error and uncomment
        // alert(window.GENERIC_USER_ERROR_MESSAGE);
        throw new Error(`OpportunityCalendar#items, ajax load -- textStatus: ${textStatus}, errorTrown: ${errorThrown}`);
      });
    vahoy.track('OpportunityCalendar#search');
  };

  // eslint-disable-next-line
  addItems = (items) => {
    const $calendar = $(this.getCalendar());
    const apiOpportunities = items.map((item) => item.data);
    const fullCalendarEvents = opportunityHelpers.mapApiOpportunitiesToFullcalendarEvents(apiOpportunities, []);
    $calendar.fullCalendar('addEventSource', fullCalendarEvents);
  };

  removeItem = (itemId) => {
    const $calendar = $(this.getCalendar());
    $calendar.fullCalendar('removeEvents', itemId);
  };

  // eslint-disable-next-line
  renderItem = (item, element) => {
    const reactDomHook = $(element).find('.fc-title')[0] || $(element)
      .find('.fc-list-item-title')[0];

    ReactDOM.render(
      <Item
        item={item}
        removeItem={this.removeItem}
      />,
      reactDomHook,
    );
  };

  render() {
    return (
      <div>
        <div id="calendar" ref={this.calendarRef} />
      </div>
    );
  }
}

OpportunityCalendar.propTypes = {
  opportunities: PropTypes.arrayOf(Object),
  opportunity_types: PropTypes.string,
  query: PropTypes.string,
};

OpportunityCalendar.defaultProps = {
  opportunities: undefined,
  opportunity_types: undefined,
  query: undefined,
};

export default OpportunityCalendar;
