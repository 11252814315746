import React from 'react';
import PropTypes from 'prop-types';
import freePitchBadgeUrl from '../../../assets/images/free-pitch-badge.svg';

function FreeToPitch({ sourceRequest, smallMode, isBroadcastMedia }) {
  const badgeStyle = {
    width: '13.25%',
    maxWidth: smallMode ? '45px' : '56px',
    top: '30px',
    right: '25px',
    position: 'absolute',
    zIndex: '1000',
  };

  const isFree = sourceRequest.source_request_free_to_pitch;
  if (!isFree) return null;

  return (
    <img
      src={freePitchBadgeUrl}
      alt="Free to pitch!"
      className={`ms-2 mt-2 free-pitch-badge ${isBroadcastMedia ? 'broadcast-header-present' : ''}`}
      style={badgeStyle}
    />
  );
}

FreeToPitch.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object).isRequired,
  smallMode: PropTypes.bool,
  isBroadcastMedia: PropTypes.bool,
};

FreeToPitch.defaultProps = {
  smallMode: false,
  isBroadcastMedia: false,
};

export default FreeToPitch;
