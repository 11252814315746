import React, { Component } from 'react';
import axios from 'axios';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Avatar from '../avatar/avatar';
import vapi from '../../javascript/frontend/api/vapi';
import vahoy from '../../javascript/vahoy';

class RecentlyMessagedUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      helpPopoverOpen: false,
      autoFollowUp: false,
      data: [],
      isUpdating: false,
    };
  }

  componentDidMount() {
    const currentUser = window.CURRENT_USER_JSON ? window.CURRENT_USER_JSON : null;
    const { maxContentItems } = this.props;

    if (currentUser !== null) {
      axios.get(`/api/internal/messaging/users/significant_recent_users_messaged?limit=${maxContentItems}`).then(({ data }) => {
        this.setState(
          {
            data: data.users_messaged,
            currentUser,
            autoFollowUp: data.pr_auto_follow_up,
          },
        );
      });
    }
  }

  helpToggle = () => {
    this.setState((prevState) => ({
      helpPopoverOpen: !prevState.helpPopoverOpen,
    }));
  };

  handleAutoFollupChange = (e) => {
    const newValue = e.target.checked;

    if (this.state.currentUser !== null) {
      const user = {
        id: this.state.currentUser.id,
        attributes: {
          pr_auto_follow_up: newValue,
        },
      };

      // Do an optimistic update in state; we'll revert state later if we get an API failure
      // Disable the radio while the update is in flight
      this.setState(
        {
          isUpdating: true,
          autoFollowUp: newValue,
        },
        () => this.updateUser(user),
      );
    }
  };

  updateUser = (user) => {
    vapi.updateUser(user)
      .catch(() => this.setState({ autoFollowUp: !user.attributes.pr_auto_follow_up }))
      .finally(() => this.setState({ isUpdating: false }));

    vahoy.track('RecentlyMessagedUsers#updateUser');
  };

  trackClicks = () => {
    vahoy.track('RecentlyMessagedUsers#clickLastConversationLink');
  };

  render() {
    const {
      currentUser, data, autoFollowUp, isUpdating, helpPopoverOpen,
    } = this.state;
    const sortedConversations = _.sortBy(data, (conversation) => -moment(conversation.last_conversation_with_user_at));
    let autoFollowUpSwitch = null;

    if (currentUser !== null && currentUser.attributes.user_type_humanized === 'source') {
      autoFollowUpSwitch = (
        <div className="form-check form-switch test-auto-follow-up">
          <input
            checked={autoFollowUp}
            className="form-check-input"
            disabled={isUpdating}
            id="auto-follow-up"
            onChange={this.handleAutoFollupChange}
            type="checkbox"
          />
          <label
            className="form-check-label"
            htmlFor="auto-follow-up"
          >
            Auto follow-up
          </label>
          <span>
            <button id="help-popover" type="button" className="btn btn-link py-0 ps-1">
              <i className="fa-solid fa-circle-question" />
            </button>

            <Popover
              placement="bottom"
              isOpen={helpPopoverOpen}
              target="help-popover"
              trigger="legacy"
              toggle={this.helpToggle}
            >
              <PopoverHeader>Auto Follow-Up</PopoverHeader>
              <PopoverBody>
                <p>Turn on this feature to have Qwoted automatically follow up with reporters if it's
                  been more than one month since you communicated.
                </p>
                <p>The messages will appear in your conversation stream.</p>
              </PopoverBody>
            </Popover>
          </span>
        </div>
      );
    }
    return ((currentUser !== null) && (sortedConversations.length > 0)) && (
      <div className="test-recently_messaged_users">
        <h4>
          {currentUser.attributes.user_type_humanized === 'source' && 'My Reporters'}
          {currentUser.attributes.user_type_humanized === 'reporter' && 'Recent Conversations'}
        </h4>
        <div className="d-flex flex-wrap">
          {
            sortedConversations.map((recentlyMessagedUser) => {
              const {
                last_conversation_with_user: lastConversationLink,
                user_avatar_url: avatarUrl,
                user_initials: userInitials,
                user_full_name: userFullName,
                username,
              } = recentlyMessagedUser;
              return (
                <a
                  key={username}
                  href={lastConversationLink}
                  onClick={this.trackClicks}
                  className="my-messaging-contact"
                >
                  <Avatar
                    avatarUrl={avatarUrl}
                    sizeClass="avatar"
                    avatarLetters={userInitials}
                  />
                  <div className="my-messaging-contact-name">
                    {userFullName}
                  </div>
                </a>
              );
            })
          }
        </div>
        {autoFollowUpSwitch}
      </div>
    );
  }
}

RecentlyMessagedUsers.propTypes = {
  maxContentItems: PropTypes.number,
};

RecentlyMessagedUsers.defaultProps = {
  maxContentItems: 8,
};

export default RecentlyMessagedUsers;
