import React from 'react';
import {
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';

function CompanyFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by company">
          <RefinementList
            attribute="current_positions.company"
            limit={6}
            showMore={true}
            showMoreLimit={15}
            searchable={true}
          />
        </Panel>
      </div>
    </div>
  );
}

export default CompanyFilterPanel;
