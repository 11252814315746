import React from 'react';
import PropTypes from 'prop-types';
import helper from '../javascript/frontend/helpers/helper';

function StaffView(props) {
  const { children } = props;

  return (
    <>
      {helper.currentUserInternal() && (
        <div className="card mb-3">
          <div className="card-header bg-staff">
            <h5 className="card-title text-white">
              Staff View
            </h5>
          </div>
          <div className="card-body">
            {children}
          </div>
        </div>
      )}
    </>
  );
}

StaffView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default StaffView;
