import React, {
  useRef, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connectRefinementList } from 'react-instantsearch-dom';
import * as d3 from 'd3';

/* eslint-disable no-unused-vars */

function ReporterForceGraph({ items, refine, labelFontSize = 10 }) {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 600, height: 300 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        setDimensions({ width, height: width / 2 });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const colorScale = useMemo(() => {
    const baseColor = d3.rgb('#72973a');
    const lighterColor = baseColor.brighter(1.5);
    const darkerColor = baseColor.darker(1.5);

    const maxCount = Math.max(...items.map((item) => item.count));
    return d3.scaleLinear()
      .domain([0, maxCount / 2, maxCount])
      .range([lighterColor, baseColor, darkerColor])
      .interpolate(d3.interpolateRgb.gamma(0.8));
  }, [items]);

  const treemapData = useMemo(() => {
    const root = d3.hierarchy({ children: items })
      .sum((d) => d.count)
      .sort((a, b) => b.value - a.value);

    d3.treemap()
      .size([dimensions.width, dimensions.height])
      .padding(1)(root);

    return root.leaves();
  }, [items, dimensions]);

  const handleClick = (item) => {
    const url = new URL(window.location.href);
    const cleanedValue = item.value;
    url.searchParams.set('query', cleanedValue);
    window.location.href = url.toString();
  };

  const getTextColor = (backgroundColor) => {
    const rgb = d3.rgb(backgroundColor);
    const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
    return brightness > 125 ? '#000000' : '#ffffff';
  };

  return (
    <div
      ref={containerRef}
      style={{
        display: 'flex', justifyContent: 'center', width: '100%', height: 'auto',
      }}
    >
      <svg width="100%" height="100%" viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}>
        {treemapData.map((d, i) => {
          const backgroundColor = colorScale(d.data.count);
          const textColor = getTextColor(backgroundColor);
          return (
            <g key={i} style={{ cursor: 'default' }}>
              <rect
                x={d.x0}
                y={d.y0}
                width={d.x1 - d.x0}
                height={d.y1 - d.y0}
                fill={backgroundColor}
                stroke="white"
              />
              <text
                x={d.x0 + (d.x1 - d.x0) / 2}
                y={d.y0 + (d.y1 - d.y0) / 2}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={labelFontSize}
                fill={textColor}
              >
                {d.data.label}
              </text>
            </g>
          );
        })}
      </svg>
    </div>
  );
}

ReporterForceGraph.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    }),
  ).isRequired,
  refine: PropTypes.func.isRequired,
  labelFontSize: PropTypes.number,
};

ReporterForceGraph.defaultProps = {
  labelFontSize: 7,
};

const ConnectedReporterForceGraph = connectRefinementList(ReporterForceGraph);
export default ConnectedReporterForceGraph;
