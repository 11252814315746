import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/avatar';
import SmartHighlight from '../../algolia_search/smart_highlight';

function NamePhotoTitle({
                          reporter,
                        }) {
  let titleHtml = null;
  if (reporter.employments) {
    const employments = reporter.employments.split(' and ');
    titleHtml = (
      <div className="card-grid-subtitle font-size-12px">
        <span className="fw-bold">
          {employments[0]}
        </span>
        {employments[1] && (
          ` and ${employments[1]}`
        )}
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="float-start pe-2">
          <Avatar
            avatarUrl={reporter.avatar_url}
            avatarLetters={reporter.initials}
            sizeClass="avatar"
          />
        </div>
        <div>
          <h6 className="mb-0 fw-bold">
            <a href={reporter.reporter_path}>
              <SmartHighlight attribute="full_name" hit={reporter} />
            </a>
          </h6>
          {titleHtml}
        </div>
      </div>
      <div className="clearfix mb-2" />
    </>
  );
}

NamePhotoTitle.propTypes = {
  reporter: PropTypes.instanceOf(Object).isRequired,
};

export default NamePhotoTitle;
