const avatarHelpers = {

  colorClassFromInitials(initials) {
    let letter = initials && initials.toLowerCase()[0];
    const regex = /[a-z]/;

    if (!letter) {
      letter = 'unknown';
    } else if (!regex.test(letter)) {
      letter = 'number';
    }

    return `letter-${letter}`;
  },
  colorTextClassFromName(name) {
    let letter = name && name.toLowerCase()[0];
    const regex = /[a-z]/;

    if (!letter) {
      letter = 'unknown';
    } else if (!regex.test(letter)) {
      letter = 'number';
    }

    return `letter-${letter}-text`;
  },

};

export default avatarHelpers;
