import helper from '../../helpers/helper';
import select2Helpers from '../../helpers/select2_helpers';

document.addEventListener('turbolinks:load', () => {
  const $statisticHashtagIds = $('#statistic_hashtag_ids');

  if (
    helper.notPresent($statisticHashtagIds)
  ) {
    return;
  }

  select2Helpers.setupSelect2($statisticHashtagIds, '/api/internal/jsonapi/hashtags', (hashtag) => {
    const attrs = hashtag.attributes;

    if (hashtag.loading) {
      return hashtag.text;
    }
    return attrs ? attrs.hashtag : hashtag.text;
  });
});
