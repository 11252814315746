import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import NoResultsMessage from '../../algolia_search/no_results_message';
import SearchLoadingCards from '../../pr_users/search/search_loading_cards';
import SelectableUserCard from '../card/selectable_user_card';
import PrUserSkeletonPage from '../../pr_users/index/pr_user_skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import EmptyResults from './empty_results';

function underscore(string) {
  return string.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
}

// Simplistic pluralization
function pluralize(string) {
  return string.endsWith('s') ? string : `${string}s`;
}

function RepsResults({
 promoEntityId, promoEntityType, hasMore, refine, hits, representingUserIds, handleChange,
}) {
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });
  const [asyncError] = useState();

  useEffect(() => {
    if (lastTriggeredAt > 0) refine();
  }, [lastTriggeredAt, refine]);

  if (asyncError) throw asyncError;
  const customMessage = window.CURRENT_USER_CLIENT_SESSION ? <EmptyResults /> : undefined;
  return (
    <div className="container-fluid">
      <NoResultsMessage customMessage={customMessage} />
      <div className="row no-gutters">
        <SearchLoadingCards />
        {hits.map((user) => (
          <div className="pb-3 pt-0 col-lg-6" key={`reps-results-${user.objectID}`}>
            <SelectableUserCard
              promoEntityId={promoEntityId}
              promoEntityType={promoEntityType}
              user={user}
              repped={representingUserIds.includes(Number(user.objectID))}
              handleChange={handleChange}
              representationId={
                user[`represented_${pluralize(underscore(promoEntityType))}`]?.filter(
                  (promoEntity) => promoEntity[`${underscore(promoEntityType)}_id`] === promoEntityId,
                )[0]?.id
              }
            />
          </div>
        ))}
      </div>
      {hasMore && (
        <div ref={scrollTriggerRef} className="row row-cols-1 row-cols-md-2 row-cols-xl-3 px-1">
          <PrUserSkeletonPage numberOfCards={3} singleRow />
        </div>
      )}
    </div>
  );
}

RepsResults.propTypes = {
  promoEntityId: PropTypes.number.isRequired,
  promoEntityType: PropTypes.string.isRequired,
  hasMore: PropTypes.bool,
  refine: PropTypes.func,
  hits: PropTypes.arrayOf(Object),
  representingUserIds: PropTypes.arrayOf(Number).isRequired,
  handleChange: PropTypes.func.isRequired,
};

RepsResults.defaultProps = {
  hasMore: false,
  refine: undefined,
  hits: [],
};

export default connectInfiniteHits(RepsResults);
