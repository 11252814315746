import React from 'react';
import PropTypes from 'prop-types';

function SectionAdmin({ hideQwotedBrandFromReporter, setHideQwotedBrandFromReporter }) {
  const handleChange = (e) => {
    const { target } = e;
    if (target.checked) {
      setHideQwotedBrandFromReporter(true);
    } else {
      setHideQwotedBrandFromReporter(false);
    }
  };

  return (
    <>
      <hr className="w-100" />

      <div className="px-2 px-md-5 py-3">
        <h4
          className="mb-3"
        >
          Admin
          &nbsp;
          <span className="badge bg-staff">Visible admin only</span>
        </h4>

        <div className="mb-3">
          <div className="form-check form-switch">
            <input
              checked={hideQwotedBrandFromReporter}
              className="form-check-input"
              id="hide-qwoted-brand-from-reporter"
              onChange={(e) => handleChange(e)}
              type="checkbox"
              value={hideQwotedBrandFromReporter}
            />
            <label
              className="custom-control-label"
              htmlFor="hide-qwoted-brand-from-reporter"
            >
              Hide Qwoted brand from reporter?
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

SectionAdmin.propTypes = {
  hideQwotedBrandFromReporter: PropTypes.bool,
  setHideQwotedBrandFromReporter: PropTypes.func,
};

SectionAdmin.defaultProps = {
  hideQwotedBrandFromReporter: false,
  setHideQwotedBrandFromReporter: undefined,
};

export default SectionAdmin;
