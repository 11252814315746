/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import vapi from '../../javascript/frontend/api/vapi';
import vahoy from '../../javascript/vahoy';
import Avatar from '../avatar/avatar';
import ConfirmModal from '../confirm_modal';
import PitchWizard from '../pitch_wizard/PitchWizard';

class UnlockPitchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);
  }

  getInitialState(props) {
    return {
      cancelPath: props.cancelPath,
      pitchId: props.pitchId,
      redirectPath: props.redirectPath,
      sourceRequestId: props.sourceRequestId,
      userId: props.userId,
      reporter: props.reporter,
    };
  }

  componentDidMount() {
    const { pitchId } = this.state;
    if (pitchId) {
      this.loadSourceRequestReporter();
    }
  }

  yesToConfirmUnlock = () => {
    this.createDraftPitch();
  };

  toggleConfirmDiscardModal = (freeToPitch) => {
    if (freeToPitch) {
      this.createDraftPitch();
    } else if (this.state.confirmingUnlock) {
      this.setState({ confirmingUnlock: false });
    } else {
      this.setState({ confirmingUnlock: true });
    }
  };

  createDraftPitch() {
    const { sourceRequestId } = this.state;
    const attributes = {
      draft: true,
      source_request_id: sourceRequestId,
    };
    vapi.createPitch(attributes)
      .then((response) => {
        const pitchId = response.data.id;
        this.setState({ pitchId });
        this.loadSourceRequestReporter();
      })
      .catch((error) => {
        const sourceRequestErrors = error.response.data.errors && error.response.data.errors.source_request;
        let reloaded = false;
        if (sourceRequestErrors) {
          sourceRequestErrors.forEach((value) => {
            if (value === 'has already been taken') {
              window.location.reload();
              reloaded = true;
            }
          });
        }
        if (!reloaded) {
          const message = 'Something went wrong, please try again later.';
          alert(message);
          throw error;
        }
      });
    vahoy.track('PitchForm#createDraftPitch');
  }

  loadSourceRequestReporter() {
    const params = {};
    const { sourceRequestId } = this.state;

    params.id = sourceRequestId;
    params.include = 'reporter';

    vapi.getSourceRequest(params)
      .then((response) => {
        const responseData = response.data;
        const sourceRequest = responseData.data || [];

        this.setState({
          reporter: sourceRequest.attributes.reporter,
        });
      });
  }

  renderConfirmModal() {
    const { confirmingUnlock } = this.state;
    const { bypassConcurrentUnlock } = this.props;

    const confirmContent = (
      <div>
        <p>Spend one of your pitches to reveal the reporter and the pitch form?</p>
        <p>Only unlock when you intend to pitch.</p>
        {!bypassConcurrentUnlock
          && <p className="text-danger">You will not be able to unlock other requests until you have pitched this one.</p>}
      </div>
    );

    return (
      <ConfirmModal
        confirmHeaderContent="Spend a pitch to unlock?"
        confirmYesText="Unlock"
        confirmNoText="Cancel"
        confirmContent={confirmContent}
        confirmYes={() => this.yesToConfirmUnlock()}
        confirmNo={() => this.toggleConfirmDiscardModal()}
        isOpening={confirmingUnlock}
        className="modal-md p-3"
        confirmNoButtonClassName="shadow-none"
      />
    );
  }

  renderReporter() {
    const { reporter } = this.state;

    if (reporter) {
      return (
        <>
          <div>
            {this.renderReporterAvatar('avatar-sm')}
            <div>
              <p className="mb-0">
                <a
                  className="fw-bold"
                  href={reporter.profile_path}
                >
                  {reporter.name}
                </a>
                <a
                  href={reporter.profile_path}
                >
                  &nbsp;<small><em>(See profile)</em></small>
                </a>
              </p>

              <div className="card-grid-subtitle fw-bold font-size-12px">
                {reporter.employment_string}
              </div>
            </div>
          </div>

          <div className="clearfix mb-2" />
        </>
      );
    }
  }

  renderPitchForm() {
    const { pitchId } = this.state;
    const {
      sourceRequestId,
      cancelPath,
      profileType,
      redirectPath,
      userId,
      conversationOnly,
    } = this.props;

    return (
      <div className="row">
        <div className="col-md-4 mt-3">
          {this.renderReporter()}
        </div>
        <div className="col-md-8">
          <PitchWizard
            cancelPath={cancelPath}
            conversationOnly={conversationOnly}
            pitchId={pitchId}
            redirectPath={redirectPath}
            requestId={sourceRequestId}
            userId={userId}
            profileType={profileType}
          />
        </div>
      </div>
    );
  }

  renderRemainingPitchesMessage() {
    const {
      clientFirmId,
      freeToPitch,
      unlimitedPitches,
      maxPitchesPerMonth,
      pitchesRemaining,
      conversationOnly,
    } = this.props;

    let firstParagraph;
    let secondParagraph;
    let secondParagraphLine2;

    if (unlimitedPitches || freeToPitch) {
      firstParagraph = <p>Submit a pitch for this request.</p>;
    } else {
      firstParagraph = <p>Use a pitch to unlock this request, displaying the reporter and the pitch form.</p>;
    }

    if (freeToPitch && !unlimitedPitches && !clientFirmId) {
      secondParagraphLine2 = (
        <span>
          &nbsp;Unlocking this request won't use a pitch. You have {pitchesRemaining} pitches currently. Each month you
          get {maxPitchesPerMonth} pitch credits good for use during the month.
        </span>
      );
    } else if (freeToPitch && !unlimitedPitches && clientFirmId) {
      secondParagraphLine2 = <span>&nbsp;You won't use one of your monthly pitches.</span>;
    }

    if (unlimitedPitches) {
      secondParagraph = <p>You have unlimited pitches.</p>;
    } else if (freeToPitch) {
      secondParagraph = (
        <p>
          <strong>For a limited time, this request is free to pitch.</strong>{secondParagraphLine2}
        </p>
      );
    } else if (!clientFirmId) {
      secondParagraph = (
        <p>
          You have {pitchesRemaining} pitches currently. Each month you get {maxPitchesPerMonth} pitches good for use
          during the month. <a href="/pricing_plans">Learn about our paid plans.</a>
        </p>
      );
    }

    if (conversationOnly) {
      firstParagraph = (
        <h2 className="mb-3">Introduce yourself or your client for <span className="fw-bold">free</span>.
        </h2>
      );
      secondParagraph = (<></>);
    }

    return (
      <div>
        {firstParagraph}
        {secondParagraph}
      </div>
    );
  }

  renderReporterAvatar(avatarSizeClass = 'avatar-md') {
    const { reporter } = this.state;

    if (reporter) {
      return (
        <div className="float-start pe-1">
          <Avatar
            avatarUrl={reporter.avatar_url}
            sizeClass={avatarSizeClass}
            avatarLetters={reporter.initials || ''}
          />
        </div>
      );
    }
  }

  renderUnlockMessage() {
    const {
      unlimitedPitches,
      freeToPitch,
      clientFirmId,
      conversationOnly,
    } = this.props;

    let pitchHistoryLink;
    let buttonText;
    let iconClass;

    if (conversationOnly) {
      buttonText = 'Start a conversation';
      iconClass = 'fa-solid fa-message';
    } else if (freeToPitch || unlimitedPitches) {
      buttonText = 'Start a Pitch';
      iconClass = 'fa-solid fa-pen-to-square';
    } else {
      buttonText = 'Unlock & Pitch';
      iconClass = 'fa-solid fa-key';
    }

    iconClass += ' me-3';

    if (!(clientFirmId && !unlimitedPitches)) {
      pitchHistoryLink = (
        <div className="mt-3">
          <small>
            <a href="/my_pitch_history">View your detailed pitch history</a>
          </small>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col-md-2">
          {conversationOnly && this.renderReporterAvatar()}
          {!conversationOnly
            && (
              <img
                alt="Reporter locked"
                src="/locked-reporter.png"
                style={{ width: '100%', maxWidth: '150px' }}
              />
            )}
        </div>
        <div className="col-md-10">
          {this.renderRemainingPitchesMessage()}
          <button
            className="btn btn-primary btn-lg mb-2 me-4 float-md-start"
            onClick={() => this.toggleConfirmDiscardModal(freeToPitch || unlimitedPitches || conversationOnly)}
            type="button"
          >
            <i
              className={iconClass}
            />
            <span className="m-1">{buttonText}</span>
          </button>
          <span>
            {conversationOnly
              && (
                <span className="fw-bold float-md-start mt-2">
                You will not use any pitch credits.
              </span>
              )}
          </span>
          {!conversationOnly && pitchHistoryLink}
          {this.renderConfirmModal()}
        </div>
      </div>
    );
  }

  render() {
    const { pitchId } = this.state;
    if (pitchId) {
      return this.renderPitchForm();
    }
    return this.renderUnlockMessage();
  }
}

UnlockPitchForm.propTypes = {
  maxPitchesPerMonth: PropTypes.number,
  pitchesRemaining: PropTypes.number,
  cancelPath: PropTypes.string.isRequired,
  unlimitedPitches: PropTypes.bool,
  freeToPitch: PropTypes.bool,
  pitchId: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
  redirectPath: PropTypes.string,
  sourceRequestId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  clientFirmId: PropTypes.number,
  bypassConcurrentUnlock: PropTypes.bool,
  conversationOnly: PropTypes.bool,
  reporter: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  profileType: PropTypes.string.isRequired,
};

UnlockPitchForm.defaultProps = {
  maxPitchesPerMonth: undefined,
  pitchesRemaining: undefined,
  pitchId: undefined,
  freeToPitch: false,
  unlimitedPitches: false,
  redirectPath: '/source_requests',
  clientFirmId: undefined,
  bypassConcurrentUnlock: false,
  conversationOnly: false,
  reporter: undefined,
};

export default UnlockPitchForm;
