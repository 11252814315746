import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart, Bar, Cell, XAxis, YAxis, ResponsiveContainer, Text,
} from 'recharts';
import { connectRefinementList } from 'react-instantsearch-dom';
/* eslint-disable no-bitwise */

const COLORS_RED = ['#D92F18', '#FF8C7A', '#FFAEA5'];
const COLORS_PURPLE = ['#7061DD', '#9A87FF', '#B4A3FF'];
const COLORS_GREEN = ['#8C9D2A', '#B3C23A', '#DDE98C'];

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

const getDarkerColor = (color) => {
  const num = parseInt(color.slice(1), 16);
  const amt = -120;
  const R = clamp((num >> 16) + amt, 0, 255);
  const G = clamp(((num >> 8) & 0x00FF) + amt, 0, 255);
  const B = clamp((num & 0x0000FF) + amt, 0, 255);
  return `#${(0x1000000 + R * 0x10000 + G * 0x100 + B).toString(16).slice(1).toUpperCase()}`;
};

const handleCellClick = (value, isClickable) => {
  if (!isClickable) return;
  const sanitizedValue = Array.isArray(value) ? value.join(',') : value.replace('#', '');
  const url = new URL(window.location);
  url.searchParams.set('query', sanitizedValue);
  window.location.href = url.toString();
};

function renderCustomBarLabel(props) {
  const {
    x, y, height, index, filteredItems: items, colors, isHovered, isClickable,
  } = props;

  const publication = items[index] ? items[index].label : '';
  const isDarkBackground = index === 0 || isHovered;
  const labelColor = isDarkBackground ? '#fff' : getDarkerColor(colors[0]);

  return (
    <Text
      x={x + 10}
      y={y + height / 2}
      fill={labelColor}
      textAnchor="start"
      verticalAnchor="middle"
      fontSize="14px"
      fontWeight="bold"
      style={{ cursor: isClickable ? 'pointer' : 'default' }}
      onClick={() => isClickable && handleCellClick(items[index].value, isClickable)}
    >
      {`${publication}`}
    </Text>
  );
}

renderCustomBarLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fill: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    }),
  ).isRequired,
  colors: PropTypes.array.isRequired,
  isHovered: PropTypes.bool.isRequired,
  isClickable: PropTypes.bool.isRequired,
};

function RefinementsHorizontalBarChart({ items, color, attribute }) {
  let colors;
  const isClickable = attribute !== 'reporter.name';

  // Filter out blacklisted items
  const BLACKLISTED_ITEMS = ['GOBankingRates.com', 'Erik J. Martin'];
  const numBars = 3;
  const filteredItems = items.filter((item) => !BLACKLISTED_ITEMS.includes(item.label)).slice(0, numBars);

  switch (color) {
    case 'purple':
      colors = COLORS_PURPLE;
      break;
    case 'green':
      colors = COLORS_GREEN;
      break;
    case 'red':
    default:
      colors = COLORS_RED;
      break;
  }

  return (
    <div style={{ width: '100%', height: '75px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={filteredItems}
          layout="vertical"
          margin={{
            top: 2, right: 2, left: 0, bottom: 2,
          }}
        >
          <XAxis type="number" hide />
          <YAxis type="category" dataKey="label" hide />
          <Bar
            dataKey="count"
            label={(props) => renderCustomBarLabel({
              ...props, filteredItems, colors, isHovered: false, isClickable,
            })}
          >
            {filteredItems.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
                onClick={() => handleCellClick(entry.value, isClickable)}
                onMouseEnter={() => (props) => renderCustomBarLabel({
                  ...props, filteredItems, colors, isHovered: true, isClickable,
                })}
                onMouseLeave={() => (props) => renderCustomBarLabel({
                  ...props, filteredItems, colors, isHovered: false, isClickable,
                })}
                cursor={isClickable ? 'pointer' : 'default'}
                style={{ transition: 'fill 0.3s ease' }}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

RefinementsHorizontalBarChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    }),
  ).isRequired,
  color: PropTypes.oneOf(['red', 'purple', 'green']).isRequired,
  attribute: PropTypes.string.isRequired,
};

const ConnectedRefinementsHorizontalBarChart = connectRefinementList(RefinementsHorizontalBarChart);

export default ConnectedRefinementsHorizontalBarChart;
