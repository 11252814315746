import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import Avatar from '../avatar/avatar';
import helper from '../../javascript/frontend/helpers/helper';

function CollapsePitchText(props) {
  const { pitchText, proposedEntities } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const sourceAndCompanyProposedEntities = proposedEntities.filter((pe) => ['source', 'company'].includes(pe.type));
  const productProposedEntities = proposedEntities.filter((pe) => pe.type === 'product');

  /* eslint-disable react/no-danger */
  return (
    <React.StrictMode>
      <Collapse isOpen={isOpen}>
        <div style={{ whiteSpace: 'pre-line' }}>
          {helper.sanitize(pitchText)}
        </div>
      </Collapse>
      {!isOpen && (
        <div className="pitch-text-collapsed truncate-lines">
          {helper.sanitize(pitchText)}
        </div>
      )}
      <div className="row pt-2">
        <div className="col-2">
          <button type="button" className="align-top truncate-lines-toggle btn btn-sm btn-outline-secondary me-3 mb-2 collapsed" onClick={toggle}>
            {!isOpen && (
              <span>Expand</span>
            )}

            {isOpen && (
              <span>Collapse</span>
            )}
          </button>
        </div>
        <div className="col-10">
          {sourceAndCompanyProposedEntities.length > 0 && (
            <>
              <div className="badge bg-secondary">Proposed sources:</div>
              <div className="mb-2" />
              {sourceAndCompanyProposedEntities.map((proposedEntity) => (
                <React.Fragment key={proposedEntity.slug}>
                  {proposedEntity.type === 'source' && (
                    <div className="card-body pb-2">
                      <div className="float-start pe-2">
                        <Avatar
                          avatarUrl={proposedEntity.avatar_url}
                          avatarLetters={proposedEntity.avatar_letters}
                          sizeClass="avatar-xs"
                        />
                      </div>
                      <div>
                        <h6 className="mb-0 fw-bold">
                          <a href={`/sources/${proposedEntity.slug}`} target="_blank" rel="noreferrer">{proposedEntity.name} (see profile)</a>
                        </h6>
                        <div className="mb-2" />
                        <span dangerouslySetInnerHTML={{ __html: proposedEntity.title }} />
                      </div>
                    </div>
                  )}

                  {proposedEntity.type === 'company' && (
                    <div className="card-body pb-2">
                      <div className="float-start pe-2" style={{ height: '50px' }}>
                        <img alt={proposedEntity.name} src={proposedEntity.image_url} style={{ height: '33px' }} />
                      </div>
                      <div>
                        <h6 className="mb-0 fw-bold">
                          <span dangerouslySetInnerHTML={{ __html: proposedEntity.title }} />
                        </h6>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {productProposedEntities.length > 0 && (
            <>
              <div className="badge bg-secondary">Proposed products:</div>
              <div className="mb-2" />
              {productProposedEntities.map((proposedEntity) => (
                <React.Fragment key={proposedEntity.slug}>
                  <div className="card-body pb-2">
                    <div className="float-start pe-2" style={{ height: '50px' }}>
                      <img alt={proposedEntity.name} src={proposedEntity.image_url} style={{ height: '33px' }} />
                    </div>
                    {proposedEntity.company_image_url && (
                      <div className="float-start pe-2" style={{ height: '50px' }}>
                        <img alt={proposedEntity.company_name} src={proposedEntity.company_image_url} style={{ height: '33px' }} />
                      </div>
                    )}
                    <div>
                      <h6 className="mb-0 fw-bold">
                        <a href={`/products/${proposedEntity.slug}`} target="_blank" rel="noreferrer">{proposedEntity.name} <small><em>(see profile)</em></small></a>
                      </h6>
                      <small><span dangerouslySetInnerHTML={{ __html: proposedEntity.company_byline }} /></small>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      </div>
    </React.StrictMode>
  );
  /* eslint-enable react/no-danger */
}

CollapsePitchText.propTypes = {
  pitchText: PropTypes.string,
  proposedEntities: PropTypes.array,
};

CollapsePitchText.defaultProps = {
  pitchText: undefined,
  proposedEntities: undefined,
};

export default CollapsePitchText;
