import React from 'react';
import PropTypes from 'prop-types';

function SectionAddLinks({
                           autosave,
                           links,
                           setLinks,
                         }) {
  const updateLinks = () => {
    const newLinks = [];
    const inputs = document.querySelectorAll("input[name='link']");
    inputs.forEach((i) => {
      if (i.value.length > 0) {
        newLinks.push(i.value);
      }
    });
    setLinks(newLinks);
  };

  const renderLinkInputs = () => {
    let keyInteger = 0;
    let linkArray;

    if (links.length === 1 && links[0] === '') {
      linkArray = [''];
    } else {
      linkArray = [...links, ''];
    }

    return linkArray.map((link) => {
      const linkKey = `link-${keyInteger + 1}`;
      keyInteger += 1;

      return (
        <div className="mb-1" key={`div-${linkKey}`}>
          <input
            className="form-control"
            defaultValue={link}
            id={linkKey}
            key={linkKey}
            name="link"
            onBlur={autosave}
            onChange={() => updateLinks()}
            type="url"
          />
        </div>
      );
    });
  };

  return (
    <>
      <label htmlFor="link" className="fw-bold">
        Links
      </label>
      {renderLinkInputs()}
    </>
  );
}

SectionAddLinks.propTypes = {
  autosave: PropTypes.func,
  links: PropTypes.array,
  setLinks: PropTypes.func,
};

SectionAddLinks.defaultProps = {
  autosave: undefined,
  links: undefined,
  setLinks: undefined,
};

export default SectionAddLinks;
