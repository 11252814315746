import React from 'react';
import {
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

function AudienceFilterPanel({ customClass, defaultRefinement }) {
  const transformItems = (items) => items.map((item) => {
    let newLabel;
    if (item.label === 'pr_users') {
      newLabel = 'PR Users';
    } else if (item.label === 'reporters') {
      newLabel = 'Reporters';
    } else {
      throw new Error('Unexepcted item label!');
    }
    return ({
      ...item,
      label: newLabel,
    });
  });

  return (
    <div className={`card bg-transparent border-0 mb-3 ${customClass}`}>
      <div className="card-body ais-hide-counts">
        <Panel header="Filter by audience">
          <RefinementList
            attribute="audience"
            defaultRefinement={defaultRefinement}
            limit={6}
            transformItems={transformItems}
          />
        </Panel>
      </div>
    </div>
  );
}

AudienceFilterPanel.propTypes = {
  customClass: PropTypes.string,
  defaultRefinement: PropTypes.instanceOf(Object),
};

AudienceFilterPanel.defaultProps = {
  customClass: undefined,
  defaultRefinement: undefined,
};

export default AudienceFilterPanel;
