import React from 'react';
import PropTypes from 'prop-types';
import SmartHighlight from '../../algolia_search/smart_highlight';

function LogoWithName({ company }) {
  return (
    <>
      <div>
        {company.logo_url && (
          <div className="float-start me-4">
            <img
              alt={company.name}
              src={company.logo_url}
              style={{
                maxHeight: '80px',
                maxWidth: '120px',
              }}
            />
          </div>
        )}
        <div>
          <h6 className="mb-0 fw-bold">
            <a href={company.company_path}>
              <SmartHighlight attribute="name" hit={company} />
            </a>
          </h6>
        </div>
      </div>
      <div className="clearfix mb-2" />
    </>
  );
}

LogoWithName.propTypes = {
  company: PropTypes.object.isRequired,
};

export default LogoWithName;
