import React from 'react';
import PropTypes from 'prop-types';

function FollowupButton(props) {
  const { buttonText, clickEvent, className } = props;

  return (
    <button
      type="button"
      className={className || 'btn btn-lg mb-1'}
      onClick={clickEvent}
    >
      {buttonText}
    </button>
  );
}

FollowupButton.propTypes = {
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  clickEvent: PropTypes.func.isRequired,
  className: PropTypes.string,
};

FollowupButton.defaultProps = {
  buttonText: undefined,
  className: undefined,
};

export default FollowupButton;
