import React from 'react';
import { Panel, ToggleRefinement } from 'react-instantsearch-dom';

function SamplesAvailablePanel() {
  return (
    <div className="card mb-3 product-samples-available-panel">
      <div className="card-body">
        <Panel>
          <ToggleRefinement
            attribute="samples_available"
            label="Samples available?"
            value={true}
          />
        </Panel>
      </div>
    </div>
  );
}

export default SamplesAvailablePanel;
