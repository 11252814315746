import helper from '../../helpers/helper';
import select2Helpers from '../../helpers/select2_helpers';

document.addEventListener('turbolinks:load', () => {
  const $sourceRequestPublicationId = $('#source_request_publication_id:visible');

  if (
    helper.notPresent($sourceRequestPublicationId)
  ) {
    return;
  }

  select2Helpers.setupSelect2($sourceRequestPublicationId, '/api/internal/jsonapi/publications', select2Helpers.formatPublications);

  // tab toggles and visibility
  $('.tab_expertise_required')
    .click(() => {
      $('.tab_expertise_required')
        .addClass('active');
      $('.tab_standard_type')
        .removeClass('active');
      $('#source_request_source_request_type')
        .attr('value', 'expertise_required');
      $('label[for=source_request_name]')
        .html('Expertise required');
      $('input[name="source_request[name]"]')
        .attr('placeholder', 'Summarize the expertise you\'re looking for: e.g. Looking for Doctors of Epidemiology');
      $('textarea[name="source_request[details]"]')
        .attr('placeholder', 'Describe the expertise you\'re looking for in detail');
    });

  // tab toggles and visibility
  $('.tab_standard_type')
    .click(() => {
      $('.tab_standard_type')
        .addClass('active');
      $('.tab_expertise_required')
        .removeClass('active');
      $('#source_request_source_request_type')
        .attr('value', 'standard_type');
      $('label[for=source_request_name]')
        .html('Question');
      $('input[name="source_request[name]"]')
        .attr('placeholder', 'Summarize your question');
      $('label[for=source_request_details]')
        .html('Details');
      $('textarea[name="source_request[details]"]')
        .attr('placeholder', 'Describe the question in detail');
    });
});
