import React from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';

import ContactSearch from './_search';
import ContactButton from './_button';
import ContactForm from './_form';
import ContactInfo from './_info';
import vahoy from '../../../javascript/vahoy';

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: props.contact,
      showFormModal: false,
      showSearchModal: false,
    };

    this.updateContactState = this.updateContactState.bind(this);
    this.removeContact = this.removeContact.bind(this);
    this.setContact = this.setContact.bind(this);
    this.raiseSearchModal = this.raiseSearchModal.bind(this);
    this.closeSearchModal = this.closeSearchModal.bind(this);
    this.raiseFormModal = this.raiseFormModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleRemoval = this.handleRemoval.bind(this);
    this.renderContactSearch = this.renderContactSearch.bind(this);
    this.renderContactForm = this.renderContactForm.bind(this);
  }

  // eslint-disable-next-line
  updateContactState(contact) {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.refreshOpportunityContactEmployment();
  }

  removeContact() {
    const { opportunity, refreshOpportunityContactEmployment } = this.props;

    vapi.setOpportunityContact(opportunity.id, null)
      .then(() => {
        refreshOpportunityContactEmployment();
      });

    vahoy.track('Opportunity/Contact/Contact#removeOpportunityContact');
  }

  handleSearchSubmit(contactId) {
    this.setContact(contactId, () => {
      this.setState({ showFormModal: false, showSearchModal: false });

      // eslint-disable-next-line react/destructuring-assignment
      this.props.refreshOpportunityContactEmployment();
    });

    this.closeSearchModal();
  }

  setContact(contactId, callback) {
    const { opportunity, refreshOpportunityContactEmployment } = this.props;

    vapi.setOpportunityContact(opportunity.id, contactId)
      .then(() => {
        if (callback) callback();
        refreshOpportunityContactEmployment();
      });

    vahoy.track('Opportunity/Contact/Contact#setOpportunityContact');
  }

  raiseSearchModal() {
    this.closeModal();
    this.setState({ showSearchModal: true });
    vahoy.track('Opportunity/Contact/Contact#raiseSearchModal');
  }

  closeSearchModal() {
    this.setState({ showSearchModal: false });
  }

  raiseFormModal() {
    this.closeSearchModal();
    this.setState({ showFormModal: true });
    vahoy.track('Opportunity/Contact/Contact#raiseFormModal');
  }

  closeModal() {
    this.setState({ showFormModal: false });
  }

  handleRemoval() {
    this.setState({ showFormModal: false });
    this.removeContact();
  }

  renderContactSearch() {
    const { contact, organization } = this.props;
    const { showSearchModal } = this.state;

    if (window.CURRENT_USER_INTERNAL && showSearchModal) {
      return (
        <ContactSearch
          contact={contact}
          onHide={this.closeSearchModal}
          onNew={this.raiseFormModal}
          onSubmit={this.handleSearchSubmit}
          organization={organization}
          showModal={showSearchModal}
        />
      );
    }
  }

  renderContactForm() {
    const { showFormModal, contact } = this.state;
    const {
      contact_employments: contactEmployments,
      contact_employment: contactEmployment,
      contact_infos: contactInfos,
      organization,
      opportunity,
      refreshOpportunityContactEmployment,
    } = this.props;

    if (window.CURRENT_USER_INTERNAL && showFormModal) {
      return (
        <ContactForm
          contact={contact}
          contact_employment={contactEmployment}
          contact_employments={contactEmployments}
          contact_infos={contactInfos}
          onHide={this.closeModal}
          onRemove={this.handleRemoval}
          setContact={this.setContact}
          organization={organization}
          opportunity={opportunity}
          refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
          showModal={showFormModal}
          toSearch={this.raiseSearchModal}
        />
      );
    }
  }

  render() {
    const { contact } = this.props;
    if (_.isEmpty(contact) && !window.CURRENT_USER_INTERNAL) {
      return null;
    }

    const {
      contact_employment: contactEmployment,
      hide_contact_info_details: hideContactInfoDetails,
      hide_view_reporter_details_page: hideViewReporterDetailsPage,
      organization,
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <h5>Contact</h5>
        </div>
        <div className="card-body">

          <div>
            {this.renderContactSearch()}
            {this.renderContactForm()}

            <ContactInfo
              contact={contact}
              contact_employment={contactEmployment}
              hide_contact_info_details={hideContactInfoDetails}
              hide_view_reporter_details_page={hideViewReporterDetailsPage}
              organization={organization}
            />

            {window.CURRENT_USER_INTERNAL
              && (
                <div>
                  {contact && !contactEmployment
                    && (
                      <small>
                        <div className="alert alert-staff" role="alert">
                          No organization specified
                          for {contact.attributes.full_name}.
                        </div>
                      </small>
                    )}
                  {contact && contactEmployment && !contactEmployment.attributes.title
                    && (
                      <small>
                        <div className="alert alert-staff" role="alert">
                          {contact.attributes.full_name} has no
                          title at {organization.attributes.name}.
                        </div>
                      </small>
                    )}

                  <div className="mb-3" />

                  <ContactButton
                    onAdd={this.raiseSearchModal}
                    onEdit={this.raiseFormModal}
                    contact={contact}
                  />
                </div>
              )}
          </div>
        </div>
      </div>

    );
  }
}

Contact.propTypes = {
  contact: PropTypes.instanceOf(Object),
  contact_employment: PropTypes.instanceOf(Object),
  contact_employments: PropTypes.arrayOf(Object),
  opportunity: PropTypes.instanceOf(Object),
  organization: PropTypes.instanceOf(Object),
  contact_infos: PropTypes.arrayOf(Object),
  refreshOpportunityContactEmployment: PropTypes.func,
  hide_contact_info_details: PropTypes.bool,
  hide_view_reporter_details_page: PropTypes.bool,
};

Contact.defaultProps = {
  contact: undefined,
  contact_employment: undefined,
  contact_employments: undefined,
  opportunity: undefined,
  organization: undefined,
  contact_infos: undefined,
  refreshOpportunityContactEmployment: undefined,
  hide_contact_info_details: undefined,
  hide_view_reporter_details_page: undefined,
};

export default Contact;
