document.addEventListener('turbolinks:load', () => {
  $(document).on('change', 'input[type="file"]', (e) => {
    const input = e.target;

    for (const file of input.files) {
      const fileSizeBytes = file.size;
      // eslint-disable-next-line no-undef
      const limitSizeBytes = GLOBAL_UPLOAD_SIZE_LIMIT_MB * 1000000;
      if (fileSizeBytes > limitSizeBytes) {
        input.value = '';
        // eslint-disable-next-line no-undef
        alert(`File size is limited by ${GLOBAL_UPLOAD_SIZE_LIMIT_MB}MB.`);
        break;
      }
    }
  });
});
