import React from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../../javascript/vahoy';
import qwotedLogo from '../../../assets/images/qwoted-logo.svg';

function UpgradeAd({ className, adVersion, smallMode }) {
  const handleClick = (version) => {
    vahoy.track(
      'InfiniteSourceRequests#view_paid_plans',
      {
        utm_source: 'source_requests',
        utm_campaign: `v${version}`,
      },
    );
    window.location.href = `/pricing_plans?back_path=${window.location.href}`;
  };

  const renderAd = (version) => {
    const adHeadlineTexts = [];
    const adBodies = [];
    const adButtonTexts = [];

    adHeadlineTexts[1] = 'Are you reaching reporters in time?';
    adHeadlineTexts[2] = 'Pitch like a pro';
    adHeadlineTexts[3] = 'Save yourself some scrolling';

    adBodies[1] = (
      <p className={`${smallMode ? 'mb-2 small' : 'mb-4'}`}>
        Free users must wait 2 hours before pitching a new request.
        Don't let your coverage opportunities get away—reach reporters faster
        by upgrading to a paid Qwoted membership.
      </p>
    );

    adBodies[2] = (
      <p className={`${smallMode ? 'mb-2 small' : 'mb-4'}`}>
        Most of our users get coverage after pitching five requests.
        Your free membership grants you up to two pitches per month.
        Unlock more pitches and increase your chances of coverage with a
        paid Qwoted plan.
      </p>
    );

    adBodies[3] = (
      <p className={`${smallMode ? 'mb-2 small' : 'mb-4'}`}>
        Are you spending your time scrolling to find the perfect requests
        to pitch? Upgrade to a Qwoted paid membership and get instant email alerts
        about new requests from reporters.
      </p>
    );

    adButtonTexts[1] = 'Learn more about our paid memberships';
    adButtonTexts[2] = 'Check out our paid member benefits';
    adButtonTexts[3] = 'Discover the power of Qwoted paid plans';

    return (
      <div className="card-body pb-0 upgrade-ad position-relative d-flex flex-column">
        <div className="clearfix" />
        <div className="row gx-0">
          <div
            className="col-12"
            style={{ textAlign: 'center' }}
          >
            <div className="mb-3" />
            <img
              alt=""
              src={qwotedLogo}
              style={{ maxHeight: smallMode ? '45px' : '60px', maxWidth: smallMode ? '110px' : '140px' }}
            />
          </div>
        </div>
        <div className="row flex-fill">
          <div className="col align-self-center">
            <h3
              className={`fw-bolder ${smallMode ? 'my-2 font-size-18px' : 'mt-0 mb-4'}`}
            >
              {adHeadlineTexts[version]}
            </h3>
            {adBodies[version]}
            <div className="text-center d-block">
              <button
                type="button"
                className={`btn btn-large btn-primary ${smallMode ? 'my-2' : 'mt-4 mb-2'}`}
                onClick={() => handleClick(version)}
              >
                {adButtonTexts[version]}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  let versionToRender;

  // Fall back to version 1 if we receive an out-of-range version
  if (adVersion > 0 && adVersion <= 3) {
    versionToRender = adVersion;
  } else {
    versionToRender = 1;
  }

  return (
    <div
      className={`card h-100 animated faster fadeIn ${className}`}
      style={{ border: '1px solid #FF7C5E' }}
    >
      {renderAd(versionToRender)}
    </div>
  );
}

UpgradeAd.propTypes = {
  className: PropTypes.string,
  adVersion: PropTypes.number.isRequired,
  smallMode: PropTypes.bool,
};

UpgradeAd.defaultProps = {
  className: undefined,
  smallMode: false,
};

export default UpgradeAd;
