import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {

  getOpportunityOrganization(opportunityId) {
    api.configure();

    const url = `/api/internal/jsonapi/opportunities/${opportunityId}/organization?include=contacts,contact_employments`;

    vlog.info(`api#getOpportunityOrganization: ${url}`);

    return axios.get(url, api.axiosFix);
  },

  createOpportunityOrganization(organizationAttrs, opportunityId) {
    vlog.info('api#createOpportunityOrganization');

    if (!organizationAttrs || !opportunityId) {
      throw new Error('api#createOpportunityContact missing organizationAttrs or opportunityId');
    }

    api.configure();

    const url = '/api/internal/jsonapi/organizations';

    const data = {
      type: 'organizations',
      attributes: organizationAttrs.attributes, // TODO
      relationships: {
        opportunities: {
          data: [{
            type: 'opportunities',
            id: opportunityId,
          }],
        },
      },
    };

    return axios.post(url, { data }, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },

  setOpportunityOrganization(opportunityId, orgId) {
    vlog.info('api#setOpportunityOrganization');

    api.configure();

    const url = `/api/internal/jsonapi/opportunities/${opportunityId}/relationships/organization`;

    const data = {
      type: 'organizations',
      id: orgId,
    };

    return axios.patch(url, { data }, api.axiosFix);
  },
});
