import PropTypes from 'prop-types';
import React from 'react';

import PublicPitchBody from './public_pitch_body';
import AuthorHeader from './author_header';

// PublicPitch component encapsulates the entire structure of a public pitch,
// including the pitch body and author details.
function PublicPitch({
  canSendMessages,
  currentUserAdmin,
  currentUserSlug,
  defaultConversationStartPath,
  isAdminModalOpen,
  publicPitch,
  toggleAdmin,
}) {
  return (
    <div key={publicPitch.id} className="col-12 col-sm-6 col-lg-4 mb-3">
      <div className="card h-100">
        <div className="card-header p-3 d-flex flex-column justify-content-between">
          <AuthorHeader publicPitch={publicPitch} />
        </div>
        <div className="card-body p-3 d-flex flex-column">
          <PublicPitchBody
            canSendMessages={canSendMessages}
            contactPath={publicPitch.contactPath}
            currentUserAdmin={currentUserAdmin}
            currentUserSlug={currentUserSlug}
            defaultConversationStartPath={defaultConversationStartPath}
            isAdminModalOpen={isAdminModalOpen}
            publicPitch={publicPitch}
            toggleAdmin={toggleAdmin}
          />
        </div>
      </div>
    </div>
  );
}

PublicPitch.propTypes = {
  canSendMessages: PropTypes.bool.isRequired,
  currentUserAdmin: PropTypes.bool,
  currentUserSlug: PropTypes.string,
  defaultConversationStartPath: PropTypes.string,
  isAdminModalOpen: PropTypes.bool,
  publicPitch: PropTypes.shape({
    avatarLetters: PropTypes.string,
    avatarUrl: PropTypes.string,
    companyName: PropTypes.string,
    companyPath: PropTypes.string,
    contactPath: PropTypes.string,
    employmentsString: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    hashtags: PropTypes.arrayOf(
      PropTypes.shape({
        hashtag: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    pitchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pitchRequestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pitchText: PropTypes.string,
    publicPitchLede: PropTypes.string,
    publicPitchPreamble: PropTypes.string,
    publicPitchText: PropTypes.string.isRequired,
    publicPitchTitle: PropTypes.string.isRequired,
    schemaVersion: PropTypes.number.isRequired,
    sourcePath: PropTypes.string,
  }).isRequired,
  toggleAdmin: PropTypes.func,
};

PublicPitch.defaultProps = {
  currentUserAdmin: false,
  currentUserSlug: null,
  defaultConversationStartPath: null,
  isAdminModalOpen: false,
  toggleAdmin: (e) => {}, // eslint-disable-line no-unused-vars
};

export default PublicPitch;
