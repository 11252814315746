/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import InviteSourceToPitchForm from './invite_source_to_pitch_form';
import helper from '../../../javascript/frontend/helpers/helper';
import vahoy from '../../../javascript/vahoy';

// TODO Invite to pitch flow
class InviteSourceToPitchButton extends React.Component {
  constructor(props) {
    super(props);

    const sourceRequests = props.source_requests;

    let requestId = props.source_request_id || (sourceRequests[0] && sourceRequests[0].id);
    if (helper.urlParamPresent('source_request_id')) {
      requestId = helper.urlParamsHash().source_request_id;
    }

    this.state = {
      invitedSource: false,
      requestId,
    };
  }

  onInvitedSource = (bool) => {
    this.setState({ invitedSource: bool, showPopup: false });
  };

  renderButton = () => {
    let button;
    const { additional_classes: additionalClasses } = this.props;
    if (this.state.invitedSource) {
      button = (
        <button
          type="button"
          className={`btn btn-secondary ${additionalClasses}`}
          disabled
        >
          Invite sent!
        </button>
      );
    } else {
      button = (
        <button
          onClick={this.openModal}
          type="button"
          className={`btn btn-outline-primary ${additionalClasses}`}
        >
          Invite to pitch
        </button>
      );
    }

    return button;
  };

  renderModal = () => {
    const { showPopup, requestId } = this.state;
    const {
      hashtags, source_id: sourceId, source_requests: sourceRequests, source_name: sourceName,
    } = this.props;

    return (
      <Modal className="modal-lg" isOpen={showPopup} toggle={this.onCancel}>
        <ModalBody>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <button
                  type="button"
                  id="test-close-tweet-modal"
                  className="btn-close btn-xs float-end"
                  onClick={this.onCancel}
                />
              </div>
            </div>
          </div>
          <InviteSourceToPitchForm
            sourceRequests={sourceRequests}
            sourceId={sourceId}
            sourceName={sourceName}
            requestId={requestId}
            onInvitedSource={this.onInvitedSource}
            hashtags={hashtags}
          />
        </ModalBody>
      </Modal>
    );
  };

  openModal = () => {
    const { origin_type: originType } = this.props;
    const sourceRequests = this.props.source_requests;
    const requestId = this.props.source_request_id || (sourceRequests[0] && sourceRequests[0].id);
    this.setState({ showPopup: true });
    vahoy.track('ReporterRequests/InviteSourceToPitchButton#clickInviteToPitchButton; '
      + `origin_type:${originType}; request_id:${requestId}`);
  };

  onCancel = () => {
    this.setState({ showPopup: false });
  };

  render() {
    return (
      <>
        {this.renderButton()}
        {this.renderModal()}
      </>
    );
  }
}

InviteSourceToPitchButton.propTypes = {
  additional_classes: PropTypes.string,
  hashtags: PropTypes.instanceOf(Array),
  origin_type: PropTypes.string.isRequired,
  source_id: PropTypes.number.isRequired,
  source_name: PropTypes.string.isRequired,
  source_request_id: PropTypes.number,
  source_requests: PropTypes.instanceOf(Array).isRequired,
};

InviteSourceToPitchButton.defaultProps = {
  additional_classes: '',
  hashtags: [],
  source_request_id: null,
};

export default InviteSourceToPitchButton;
