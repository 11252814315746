import React from 'react';
import PropTypes from 'prop-types';
import SearchLaunchForm from './search_launch_form';

function LockedFilterPanel({
                             planName,
                             upgradeButtonText,
                             upgradeButtonUrl,
                             panelTitle,
                             showSearchBox,
                             showMoreButton,
                             filterPlaceholders,
                           }) {
  const cardOverlayStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '1000',
    backgroundColor: '#bbbbbb2e',
    borderRadius: '7px',
  };

  const premiumBadgeStyle = {
    borderRadius: '3px',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
  };

  const blurPanelStyle = {
    filter: 'blur(0.8px)',
    WebkitFilter: 'blur(0.8px)',
  };

  const upgradeButtonStyle = {
    position: 'absolute',
    margin: 'auto',
    height: '40px',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
  };

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div style={cardOverlayStyle}>
          <span className="badge bg-primary me-1" style={premiumBadgeStyle}>
            {planName} Feature
          </span>
          <a href={upgradeButtonUrl}>
            <button
              type="button"
              className="btn btn-info"
              style={upgradeButtonStyle}
            >
              {upgradeButtonText}
            </button>
          </a>
        </div>
        <div className="ais-Panel" style={blurPanelStyle}>
          <div className="ais-Panel-header">{panelTitle}</div>
          <div className="ais-Panel-body">
            <div className="ais-RefinementList">
              {showSearchBox && <SearchLaunchForm searchUrl="#" disabled />}
              <ul className="ais-RefinementList-list mt-2">
                {filterPlaceholders.map((placeholder, index) => (
                  <li className="ais-RefinementList-item" key={index}>
                    <label className="ais-RefinementList-label">
                      <input className="ais-RefinementList-checkbox" disabled type="checkbox" />
                      <span className="ais-RefinementList-labelText">{placeholder.name}&nbsp;</span>
                      <span className="ais-RefinementList-count">{placeholder.count}</span>
                    </label>
                  </li>
                ))}
              </ul>
              {showMoreButton && (
                <button
                  type="button"
                  disabled
                  className="ais-RefinementList-showMore"
                >
                  Show more
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const defaultPlaceholders = [
  { name: 'Filter #1', count: 10 },
  { name: 'Filter #2', count: 8 },
  { name: 'Filter #3', count: 7 },
  { name: 'Filter #4', count: 3 },
  { name: 'Filter #5', count: 2 },
  { name: 'Filter #6', count: 1 },
];

LockedFilterPanel.propTypes = {
  planName: PropTypes.string,
  upgradeButtonText: PropTypes.string,
  upgradeButtonUrl: PropTypes.string,
  panelTitle: PropTypes.string,
  showSearchBox: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  filterPlaceholders: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
  })),
};

LockedFilterPanel.defaultProps = {
  planName: 'PRO',
  upgradeButtonText: 'See our plans...',
  upgradeButtonUrl: '/pricing_plans',
  panelTitle: 'Filter',
  showSearchBox: true,
  showMoreButton: true,
  filterPlaceholders: defaultPlaceholders,
};

export default LockedFilterPanel;
