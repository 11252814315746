import select2Helpers from '../../helpers/select2_helpers';
import helper from '../../helpers/helper';

document.addEventListener('turbolinks:load', () => {
  const $mergerMergeIntoObjectId = $('select#merger_merge_into_object_id');

  if (
    helper.notPresent($mergerMergeIntoObjectId)
  ) {
    return;
  }

  const type = $('#merger-type').data('type');
  let formatter;

  if (type === 'contacts') {
    formatter = select2Helpers.formatContacts;
  } else if (type === 'reporters') {
    formatter = select2Helpers.formatReporters;
  } else if (type === 'sources') {
    formatter = select2Helpers.formatSources;
  } else if (type === 'publications') {
    formatter = select2Helpers.formatPublications;
  } else if (type === 'companies') {
    formatter = select2Helpers.formatCompanies;
  } else {
    formatter = select2Helpers.formatOrganizations;
  }

  select2Helpers.setupSelect2($mergerMergeIntoObjectId, `/api/internal/jsonapi/${type}`, formatter);
});
