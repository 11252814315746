import helper from '../../../helpers/helper';

document.addEventListener('turbolinks:load', () => {
  const $toggleQuotes = $('#toggle-quotes');

  if (
    helper.notPresent($toggleQuotes)
  ) {
    return;
  }

  // eslint-disable-next-line func-names
  $toggleQuotes.on('click', function () {
    $('.quote').slice(1).toggle();

    if ($(this).text() === 'Show Past Quotes') {
      $(this).text('Hide Past Quotes');
    } else {
      $(this).text('Show Past Quotes');
    }
  });
});
