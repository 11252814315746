import React from 'react';
import PropTypes from 'prop-types';
import {
  Panel,
} from 'react-instantsearch-dom';
import GeoSearchByPlace from '../../algolia_search/geo_search_by_place';

function GeoFilterPanel({ clearGeoSearch, onGeoSearchCleared }) {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by location">
          <GeoSearchByPlace clearGeoSearch={clearGeoSearch} onGeoSearchCleared={onGeoSearchCleared} />
        </Panel>
      </div>
    </div>
  );
}

GeoFilterPanel.propTypes = {
  clearGeoSearch: PropTypes.bool.isRequired,
  onGeoSearchCleared: PropTypes.func.isRequired,
};

export default GeoFilterPanel;
