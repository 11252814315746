import React from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import OrganizationFields from './_organization_fields';
import OrganizationContactInfoFields from './_contact_info_fields';

class OrganizationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: props.showModal,
    };
  }

  handleClose = () => {
    const { refreshOpportunityContactEmployment, onHide } = this.props;

    refreshOpportunityContactEmployment();
    onHide();
  };

  render() {
    const { showModal } = this.state;
    const {
      onHide, organization, toSearch, setOrganization, opportunity, onRemove,
    } = this.props;
    const { refreshOpportunityContactEmployment, contact_infos: contactInfos } = this.props;

    return (
      <Modal isOpen={showModal} className="modal-lg" toggle={onHide}>
        <ModalHeader toggle={this.handleCancel}>
          Organization&nbsp;
          {!organization
            && (
              <button onClick={toSearch} className="btn btn-outline-secondary me-2" type="button">
                <i
                  className="fa-solid fa-magnifying-glass"
                />&nbsp;Search
              </button>
            )}
        </ModalHeader>

        <ModalBody>
          <form>
            <OrganizationFields
              organization={organization}
              setOrganization={setOrganization}
              opportunity={opportunity}
              refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
            />
            {organization
              && (
                <OrganizationContactInfoFields
                  organization={organization}
                  contactInfos={contactInfos}
                  refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
                />
              )}
          </form>

        </ModalBody>

        <ModalFooter>
          <Button
            color="danger"
            className="float-start"
            onClick={onRemove}
          >Remove from opportunity
          </Button>
          <Button onClick={this.handleClose}>Close</Button>
        </ModalFooter>

      </Modal>
    );
  }
}

OrganizationForm.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  organization: PropTypes.instanceOf(Object),
  toSearch: PropTypes.func,
  setOrganization: PropTypes.func,
  opportunity: PropTypes.instanceOf(Object),
  onRemove: PropTypes.func,
  refreshOpportunityContactEmployment: PropTypes.func.isRequired,
  contact_infos: PropTypes.arrayOf(Object),
};

OrganizationForm.defaultProps = {
  showModal: undefined,
  organization: undefined,
  toSearch: undefined,
  setOrganization: undefined,
  opportunity: undefined,
  onRemove: undefined,
  contact_infos: undefined,
};

export default OrganizationForm;
