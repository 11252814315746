import React, { useState } from 'react';
import {
  Configure,
  Panel,
  connectPagination,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

function MostRecentFilterPanel({
 mostRecent, customClass, refine, currentRefinement,
}) {
  const [isRecent, setIsRecent] = useState(mostRecent);
  const [recentCutoffTimestamp, setRecentCutoffTimestamp] = useState();
  const recentDays = 14;

  const getFilters = () => {
    let filters = '';

    if (isRecent) {
      filters = `created_at_timestamp > ${recentCutoffTimestamp}`;
    }

    return filters;
  };

  const handleChange = () => {
    setIsRecent(!isRecent);
    setRecentCutoffTimestamp(Math.floor(Date.now() / 1000) - (60 * 60 * 24 * recentDays));

    if (currentRefinement !== 0) {
      refine(1); // set the page back to 0
    }
  };

  return (
    <div className={`card bg-transparent border-0 mb-3 ${customClass}`}>
      <div className="card-body ais-hide-counts">
        <Panel header="Filter by Post Date">
          <label>
            <input
              type="checkbox"
              checked={isRecent}
              onChange={handleChange}
            />
            &nbsp;Most Recent
          </label>
          <Configure numericFilters={getFilters()} />
        </Panel>
      </div>
    </div>
  );
}

MostRecentFilterPanel.propTypes = {
  mostRecent: PropTypes.bool,
  customClass: PropTypes.instanceOf(Object),
  refine: PropTypes.func.isRequired,
  currentRefinement: PropTypes.number,
};

MostRecentFilterPanel.defaultProps = {
  mostRecent: false,
  customClass: undefined,
  currentRefinement: 1,
};

export default connectPagination(MostRecentFilterPanel);
