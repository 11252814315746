import axios from 'axios';
import _ from 'lodash';
import api from './api';

_.extend(api, {
  getOpportunity(opportunityId) {
    api.configure();

    const url = `/api/internal/jsonapi/opportunities/${opportunityId}.json?include=contact,contact.contact_infos,organization,organization.contact_infos,contact_employments`;

    return axios.get(url, api.axiosFix);
  },
  getOpportunities(after, before, type, sort) {
    api.configure();

    return axios.get('/api/internal/jsonapi/opportunities.json', {
      params: {
        'filter[after_opportunity_date]': after,
        'filter[before_opportunity_date]': before,
        'filter[opportunity_types]': type,
        'page[limit]': window.MAXIMUM_PERFORMANT_API_PAGE_SIZE,
        sort,
      },
    });
  },
});
