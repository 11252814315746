document.addEventListener('turbolinks:load', () => {
  const sendSourceRequestAlertsButton = document.querySelector('#send_source_request_alerts_button');

  if (sendSourceRequestAlertsButton != null) {
    sendSourceRequestAlertsButton.addEventListener('click', (event) => {
      event.preventDefault();
      document.getElementById('send_source_request_alerts').value = 'true';
      document.querySelector('.article-form').submit();
    }, false);

    const textboxes = document.querySelectorAll('[type="text"]');
    // eslint-disable-next-line no-plusplus
    for (let counter = 0; counter < textboxes.length; counter++) {
      textboxes[counter].addEventListener('input', () => {
        const values = [];
        textboxes.forEach((v) => values.push(v.value));
        let disabled = values.includes('');
        if (!disabled) {
          disabled = ($('input[id=article_used_pitch_ids_]:checked').length == 0) && $('input[id=article_unused_pitch_ids_]:checked').length == 0;
        }
        sendSourceRequestAlertsButton.disabled = disabled;
      });
    }

    const checkboxes = $('input[type=checkbox]');
    // eslint-disable-next-line no-plusplus
    for (let counter = 0; counter < checkboxes.length; counter++) {
      checkboxes[counter].addEventListener('change', () => {
        const values = [];
        textboxes.forEach((v) => values.push(v.value));
        let disabled = values.includes('');
        if (!disabled) {
          disabled = ($('input[id=article_used_pitch_ids_]:checked').length == 0) && $('input[id=article_unused_pitch_ids_]:checked').length == 0;
        }
        sendSourceRequestAlertsButton.disabled = disabled;
      });
    }

    $('input:checkbox').on('click', function handleCheckboxClick() {
      const $checkBox = $(this);
      if ($checkBox.is(':checked')) {
        const group = `input:checkbox[class="pitch_${$checkBox.val()}"]`;
        $(group).prop('checked', false);
        $checkBox.prop('checked', true);
      } else {
        $checkBox.prop('checked', false);
      }
    });
  }
});
