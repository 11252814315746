import React from 'react';
import _ from 'lodash';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Select2Ajax from '../select2_ajax';
import LoadingImage from '../loading_image';

const requiredErrorText = 'This field is required';

class WorkExperienceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInititalState(props);
  }

  getInititalState(props) {
    return {
      form: props.workExperience || {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isEqual(prevState.responseError, nextProps.responseError)) {
      return { responseError: nextProps.responseError };
    }

    return {};
  }

  getFormErrors(form) {
    const errors = {};

    if (!form.title) {
      errors.title = requiredErrorText;
    }

    if (!form.publication_id || (form.publication_id === 'new' && !form.publication_name)) {
      errors.publication_name = requiredErrorText;
    }

    return errors;
  }

  handleInputChange = (field, value) => {
    const { form } = this.state;
    const states = {};

    form[field] = value;
    states.form = form;

    this.setState(states);
  };

  handleCompanyChange = (e) => {
    const publicationId = jQuery(e.target).val();
    this.handleInputChange('publication_id', publicationId);

    if (publicationId === 'new') {
      const publicationName = jQuery(e.target).find('option:selected').text();
      this.handleInputChange('publication_name', publicationName);
    }
  };

  processSelect2Result = (response) => {
    const data = (response.data || []);
    const results = data.map((result) => _.assign({}, result, { text: result.attributes.name }));

    return {
      results,
      pagination: {
        more: !!(response.links || {}).next,
      },
    };
  };

  submit(evt) {
    if (evt) {
      evt.preventDefault();
    }

    const { form } = this.state;
    const { onSave, fromPublication } = this.props;
    const errors = this.getFormErrors(form);

    if (_.isEmpty(errors)) {
      this.setState({ displayErrors: false });

      if (onSave) {
        form.publication = fromPublication;

        onSave(form, () => {
          this.setState({ displayErrors: true });
        });
      }
    } else {
      this.setState({ displayErrors: true });
    }
  }

  renderError(errors, field) {
    const errorText = errors[field];

    if (!errorText) {
      return;
    }

    return (
      <small className="form-text d-block text-danger">
        {errorText}
      </small>
    );
  }

  renderMonthInput(field, value, errors) {
    const months = [
      { name: 'Month', value: '' },
      { name: 'January', value: 1 },
      { name: 'February', value: 2 },
      { name: 'March', value: 3 },
      { name: 'April', value: 4 },
      { name: 'May', value: 5 },
      { name: 'June', value: 6 },
      { name: 'July', value: 7 },
      { name: 'August', value: 8 },
      { name: 'September', value: 9 },
      { name: 'October', value: 10 },
      { name: 'November', value: 11 },
      { name: 'December', value: 12 },
    ];

    return (
      <div className="">
        <select
          className="form-select"
          value={value}
          id={field}
          onChange={(evt) => this.handleInputChange(field, evt.target.value)}
        >
          {months.map((month) => <option value={month.value} key={month.value}>{month.name}</option>)}
        </select>
        {this.renderError(errors, field)}
      </div>
    );
  }

  renderYearInput(field, value, errors) {
    const now = new Date();
    const currentYear = now.getFullYear();
    const count = 70;
    const years = [{ label: 'Year', value: '' }];

    for (let i = 0; i < count; i += 1) {
      years.push({ label: currentYear - i, value: currentYear - i });
    }

    return (
      <div className="">
        <select
          className="form-select"
          value={value}
          id={field}
          onChange={(evt) => this.handleInputChange(field, evt.target.value)}
        >
          {years.map((year) => <option value={year.label} key={year.value}>{year.label}</option>)}
        </select>
        {this.renderError(errors, field)}
      </div>
    );
  }

  render() {
    const {
      isOpening, isSaving, onCancel, workExperience = {},
    } = this.props;
    const { form, displayErrors, responseError } = this.state;
    const serverErrors = (responseError || {}).errors || {};
    const localErrors = displayErrors ? this.getFormErrors(form) : {};
    const errors = _.assign({}, serverErrors, localErrors);
    const existingPublication = workExperience.publication_id && {
      value: workExperience.publication_id,
      label: workExperience.publication_name,
    };
    let endDateFields;
    let formActions;

    if (isSaving) {
      formActions = (
        <div className="mb-3">
          <LoadingImage height="50" />
        </div>
      );
    } else {
      formActions = (
        <div className="mb-3">
          <input type="submit" className="btn btn-primary btn-lg ps-5 pe-5" value="Submit" id="submit" />
          <button
            type="button"
            className="btn btn-lg text-danger ms-2 shadow-none ps-5 pe-5"
            onClick={() => onCancel && onCancel()}
          >Cancel
          </button>
        </div>
      );
    }

    if (!form.is_current_company) {
      endDateFields = (
        <div className="col-md-6">
          <div className="">
            <label className="form-label">End date</label>
          </div>
          <div className="mb-3 required">
            {this.renderMonthInput('end_date_month', form.end_date_month, errors)}
          </div>
          <div className="mb-3 required">
            {this.renderYearInput('end_date_year', form.end_date_year, errors)}
          </div>
        </div>
      );
    }

    return (
      <Modal isOpen={isOpening} className="work-experience-modal">
        <ModalHeader toggle={() => onCancel && onCancel()}>
          <div className="h3">Add Work Experience</div>
        </ModalHeader>
        <ModalBody>
          <form
            className=""
            onSubmit={(evt) => {
              evt.preventDefault();
              this.submit();
            }}
          >
            <div className="mb-3 required">
              <label className="form-label" htmlFor="title">Title</label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  value={form.title || ''}
                  required
                  onChange={(evt) => this.handleInputChange('title', evt.target.value)}
                  placeholder="Ex: Manager"
                  id="title"
                />
                {this.renderError(errors, 'title')}
              </div>
            </div>

            <div className="mb-3 required">
              <label className="form-label">Company</label>
              <div className="">
                <Select2Ajax
                  ajaxUrl="/api/internal/jsonapi/publications"
                  ajaxProcessResults={this.processSelect2Result}
                  onChange={this.handleCompanyChange}
                  isInsideModal
                  languageNoResults="new Publication"
                  className="form-control"
                  selectedOption={existingPublication}
                />
                {this.renderError(errors, 'publication_name')}
              </div>
            </div>

            <div className="mb-3">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={form.is_current_company || false}
                    onChange={(evt) => this.handleInputChange('is_current_company', evt.target.checked)}
                    id="is_current_company"
                  />
                  <span>I currently work here</span>
                </label>
              </div>
            </div>

            <div className="mb-3">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={form.featured || false}
                    onChange={(evt) => this.handleInputChange('featured', evt.target.checked)}
                    id="featured"
                  />
                  <span>Display as headline</span>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="">
                  <label className="form-label">Start date</label>
                </div>
                <div className="mb-3 required">
                  {this.renderMonthInput('start_date_month', form.start_date_month, errors)}
                </div>
                <div className="mb-3 required">
                  {this.renderYearInput('start_date_year', form.start_date_year, errors)}
                </div>
              </div>
              {endDateFields}
            </div>

            {formActions}
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

WorkExperienceModal.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  isOpening: PropTypes.bool,
  isSaving: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  responseError: PropTypes.instanceOf(Object),
  workExperience: PropTypes.instanceOf(Object),
  fromPublication: PropTypes.bool,
};

WorkExperienceModal.defaultProps = {
  isOpening: undefined,
  isSaving: undefined,
  responseError: undefined,
  workExperience: undefined,
  fromPublication: undefined,
};

export default WorkExperienceModal;
