import React from 'react';
import {
 BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell,
} from 'recharts';
import PropTypes from 'prop-types';
import { connectRefinementList } from 'react-instantsearch-dom';

const COLORS = ['#E4604B', '#E98675', '#EFADA2']; // Example colors to match the design

function HorizontalBarChart({ items }) {
  return (
<ResponsiveContainer width="100%" height={205}>
    <h1> Hello</h1>
    <BarChart
      data={items}
      layout="horizontal"
      margin={{
        top: 5, right: 10, left: 0, bottom: 5,
      }}
      barCategoryGap="10%"
    >
      <XAxis type="number" hide />
      <YAxis type="category" dataKey="label" width={100} tickLine={false} axisLine={false} />
      <Tooltip cursor={{ fill: 'transparent' }} />
      <Bar dataKey="count" fill="#8884d8" barSize={12}>
        {items.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);
}

HorizontalBarChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

const ConnectedHorizontalBarChart = connectRefinementList(HorizontalBarChart);

export default ConnectedHorizontalBarChart;
