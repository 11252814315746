import React from 'react';
import PropTypes from 'prop-types';

const ImageCropped = React.forwardRef(
  (
    {
 src, alt = '', size, wrapperClasses = '', className = '', style = {}, roundedCircle = false, ...rest
},
    ref,
  ) => {
    const wrapperStyle = {
      ...({ width: `${size}px` }),
      ...({ height: `${size}px` }),
      ...style,
    };

    const imgStyle = {
      width: '100%',
      height: '100%',
    };

    let imgClasses = `object-cover ${className}`;
    if (roundedCircle) { imgClasses += ' rounded-circle'; }

    if (!src) { return null; }

    return (
      <div className={`d-inline-block ${wrapperClasses}`}>
        <div className="d-inline-block" style={wrapperStyle}>
          {React.cloneElement(
            <img ref={ref} src={src} alt={alt} className={imgClasses} style={imgStyle} />,
            rest,
          )}
        </div>
      </div>
    );
  },
);

ImageCropped.displayName = 'ImageCropped';

ImageCropped.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  size: PropTypes.number,
  wrapperClasses: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  roundedCircle: PropTypes.bool,
};

ImageCropped.defaultProps = {
  alt: '',
  size: 40,
  wrapperClasses: '',
  className: '',
  style: {},
  roundedCircle: false,
};

export default ImageCropped;
