import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';
import ExpertCard from './expert_card';

function AddMoreExperts({
                          addMoreExperts, handleAddSourceClick, initialSourceId, setAddMoreExperts,
                        }) {
  useEffect(() => {
    const fetchAddMoreExperts = async () => {
      if (initialSourceId) {
        // TODO will there be a limit to how many can be invited?
        const params = {
          'filter[related_to_source_id]': initialSourceId, 'page[limit]': 9,
        };

        const response = await vapi.loadSources(params);

        if (response.status === 200) {
          const results = response.data;
          if (results.data && results.data.length > 0) {
            setAddMoreExperts(results.data);
          }
        } else {
          // TODO server side form validation
        }
      }
    };

    fetchAddMoreExperts();
  }, [initialSourceId, setAddMoreExperts]);

  return (
    <div className="mb-3">
      <label className="mb-0">
        <h3 className="mb-0">
          Add more experts
        </h3>
      </label>
      <br />
      <small className="text-muted">
        Suggestions based on your search parameters. All messages sent separately.
      </small>
      <div className="mb-3" />
      <div className="d-flex flex-row flex-wrap">
        {addMoreExperts?.slice(0, 3).map((source) => (
          <div
            className="me-2 mb-2"
            key={source.id}
          >
            <ExpertCard
              source={source}
              onClick={() => {
                handleAddSourceClick(source);
              }}
            />
          </div>
        ))}
        {addMoreExperts.length === 0 && <p>Maximum reached.</p>}
      </div>
    </div>
  );
}

AddMoreExperts.propTypes = {
  addMoreExperts: PropTypes.array,
  handleAddSourceClick: PropTypes.func,
  initialSourceId: PropTypes.number,
  setAddMoreExperts: PropTypes.func,
};

AddMoreExperts.defaultProps = {
  addMoreExperts: undefined, handleAddSourceClick: undefined, initialSourceId: undefined, setAddMoreExperts: undefined,
};

export default AddMoreExperts;
