import axios from 'axios';
import _ from 'lodash';
import api from './api';

_.extend(api, {
  createProductRepresentation(productId, userId) {
    api.configure();
    const url = '/api/internal/jsonapi/represented-products';

    const data = {
      type: 'represented_products',
      attributes: {
        product_id: productId,
        user_id: userId,
      },
    };

    return axios.post(url, { data }, api.axiosFix);
  },

  deleteProductRepresentation(representationId) {
    api.configure();
    const url = `/api/internal/jsonapi/represented-products/${representationId}`;
    return axios.delete(url, api.axiosFix);
  },

  getProductRepresentingUsers(id) {
    api.configure();
    const url = `/api/internal/jsonapi/products/${id}/represented-products?include=user`;
    return axios.get(url, {}, api.axiosFix);
  },
});
