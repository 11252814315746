import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

function OrganizationButton(props) {
  let button;
  const { organization, onAdd, onEdit } = props;

  if (_.isEmpty(organization)) {
    button = (
      <div>
        <div className="row">
          <div className="col-12">
            <div className="alert alert-staff" role="alert">No organization specified</div>
          </div>
        </div>
        <button
          type="button"
          id="opportunity-add-organization"
          className="btn btn-staff btn-sm"
          onClick={onAdd}
          onKeyUp={(evt) => evt.keyCode == 13 && onAdd(evt)}
        >
          <i className="fa-solid fa-plus" />
          &nbsp;Add organization
        </button>
      </div>
    );
  } else {
    button = (
      <button
        type="button"
        id="opportunity-edit-organization"
        className="btn btn-staff btn-sm"
        onClick={onEdit}
        onKeyUp={(evt) => evt.keyCode == 13 && onEdit(evt)}
      >
        <i className="fa-solid fa-pen-to-square" />
        &nbsp;Edit organization
      </button>
    );
  }

  return (
    <div>
      {button}
    </div>
  );
}

OrganizationButton.propTypes = {
  organization: PropTypes.instanceOf(Object),
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
};

OrganizationButton.defaultProps = {
  organization: undefined,
  onAdd: undefined,
  onEdit: undefined,
};

export default OrganizationButton;
