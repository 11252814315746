import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../../javascript/vahoy';
import vapi from '../../../javascript/frontend/api/vapi';
import { fetchPromoEntitiesRepresentedBy } from '../services/promo_entity';

const PitchWizardContext = React.createContext(null);

function PitchWizardContextProvider({ children, userId }) {
  const [asyncError, setAsyncError] = useState(null);
  const [topLevelIsLoading, setTopLevelIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [promoEntities, setPromoEntities] = useState([]);

  // Pulls user info from the API whenever the userId changes
  useEffect(() => {
    if (!userId) return;

    const fetchUser = async () => {
      try {
        setTopLevelIsLoading(true);
        vahoy.track('PitchWizard#getUser');

        const response = await vapi.getUser(userId);
        if (response.status === 200) {
          const userData = response.data;
          if (userData.data) {
            setUser(userData.data.attributes);
          }
        }
      } catch (error) {
        setAsyncError(error);
      } finally {
        setTopLevelIsLoading(false);
      }
    };
    fetchUser();
  }, [userId]);

  // Load the promo entities represented by the user
  useEffect(() => {
    if (!userId) return;

    const loadPromoEntities = async () => {
      const fetchedPromoEntities = await fetchPromoEntitiesRepresentedBy(userId); // TODO: we do this in multiple places. Extract to a hook or context?
      setPromoEntities(fetchedPromoEntities);
    };

    loadPromoEntities();
  }, [userId]);

  // Values and functions made available to consumers of the company context
  // (Memoized based on state values to avoid triggering unnecessary component rerenders)
  const providerValue = useMemo(
    () => ({
      promoEntities,
      userId,
      user,
      topLevelIsLoading,
      setTopLevelIsLoading,
      asyncError,
      setAsyncError,
      }),
    [
      promoEntities,
      userId,
      user,
      topLevelIsLoading,
      asyncError,
    ],
  );

  return (
    <PitchWizardContext.Provider value={providerValue}>
      {children}
    </PitchWizardContext.Provider>
  );
}

export { PitchWizardContext, PitchWizardContextProvider };

PitchWizardContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  userId: PropTypes.number.isRequired,
};
