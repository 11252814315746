import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import vlog from '../../javascript/vlog';
import SearchResult from './search_result';

function MessagingSearch({ userId }) {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passedHealthCheck, setPassedHealthCheck] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [supabaseToken, setSupabaseToken] = useState(null);
  const [v2UserProfileId, setV2UserProfileId] = useState(null);
  const debounceTimer = useRef(null);

  useEffect(() => {
    const v2HealthCheck = async () => {
      const passed = await window.v2HealthCheck();
      if (passed) {
        setPassedHealthCheck(true);
      } else {
        vlog.debug('Component not ready: V2 health check did not pass.');
      }
    };

    v2HealthCheck();
  }, []);

  useEffect(() => {
    const checkComponentReady = async () => {
      const token = await window.supabaseToken();
      if (token) {
        setSupabaseToken(token);
      } else {
        vlog.debug('Component not ready: token not found.');
      }
    };

    checkComponentReady();
  }, []);

  const componentReady = useCallback(
    () => passedHealthCheck && supabaseToken != null && v2UserProfileId != null,
    [passedHealthCheck, supabaseToken, v2UserProfileId],
  );

  useEffect(() => {
    const fetchV2identifiers = async () => {
      try {
        const { data, error: fetchError } = await window.supabaseClient
          .from('user_profiles')
          .select('profile_id, account_user_id')
          .eq('user_migrate_id', userId)
          .single(); // We expect only one profile per user

        if (fetchError) {
          vlog.error('Error fetching user profile:', fetchError);
          return null;
        }

        if (data?.profile_id && data?.account_user_id) {
          setV2UserProfileId(data.profile_id);
          return true;
        }
        vlog.error('V2 IDs not found!');
        return null;
      } catch (fetchError) {
        vlog.error('Error fetching V2 IDs', fetchError);
        return null;
      }
    };

    fetchV2identifiers();
  }, [userId]);

  // Search messages
  useEffect(() => {
    // Clear any existing timeout when dependencies change
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Only proceed if all necessary data is available
    if (!v2UserProfileId || !componentReady() || searchQuery.trim().length < 3) {
      setSearchResults([]); // Clear results if query is too short or conditions aren't met
      return;
    }

    setIsLoading(true);

    // Set a debounce timer to delay the search
    debounceTimer.current = setTimeout(async () => {
      try {
        const trimmedQuery = searchQuery.trim();

        const authenticatedHeadersHash = {
          apikey: window.SUPABASE_ANON_KEY,
          Authorization: `Bearer ${supabaseToken}`,
        };

        // Fetch search results
        const response = await fetch(
          `${window.V2_URL}/interact/api/messaging/conversation_searches?filter[profile_id]=${
            v2UserProfileId}&filter[query]=${trimmedQuery}`,
          {
            method: 'GET',
            headers: new Headers({
              ...authenticatedHeadersHash,
              'Content-Type': 'application/json',
            }),
          },
        );

        if (response.ok) {
          const json = await response.json();
          const conversations = json.data;
          setSearchResults(conversations);
        } else {
          const json = await response.json();
          throw new Error(`Search error: ${json}`);
        }
      } catch (err) {
        vlog.error('Unexpected error:', err);
        setError(true);
      } finally {
        setIsLoading(false); // Stop loading when search completes
      }
    }, 500); // 500ms debounce delay

    // Cleanup function to clear the debounce timer
    return () => {
      clearTimeout(debounceTimer.current);
    };
  }, [searchQuery, v2UserProfileId, componentReady, supabaseToken]);

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    };

    return date.toLocaleString(undefined, options);
  };

  const renderSearchResults = () => {
    if (isLoading) {
      return (
        <div
          className="bg-white border list-group p-3 mt-2 shadow shadow-sm flex flex-column justify-content-start align-items-start"
        >
          <div className="align-middle w-100 d-flex justify-content-between">
            <em role="status">Loading...</em>
            <div
              className="spinner-border ms-auto"
              aria-hidden="true"
              style={{ height: '1.5em', width: '1.5em' }}
            />
          </div>
        </div>
      );
    }

    if (searchResults.length === 0 && searchQuery.trim().length > 2 && !isLoading) {
      return (
        <div
          style={{ height: '100px' }}
          className="bg-white border list-group py-2 mt-2 shadow shadow-sm flex flex-column justify-content-center align-items-center"
        >
          <i className="fa fa-magnifying-glass fa-2x text-primary" />
          <span className="text-center">
            { 'We didn\'t find any conversations'}
            <br />
            that matched your search.
          </span>
        </div>
      );
    }

    return (
      <>
        <style>
          {`
            .results:hover {
              transition: 0.3s;
              background-color: #F8F8F8;
             }
            mark {
              background: #eceaf9;
              font-weight: bold;
              color: #533DD1;
            }
         `}
        </style>
        <ul className="list-group mt-2 shadow shadow-sm">
          {searchResults.map((result) => (
            <SearchResult
              key={result.id}
              result={result}
              v2UserProfileId={v2UserProfileId}
              formatDateTime={formatDateTime}
            />
          ))}
        </ul>
      </>
    );
  };

  if (error) {
    return null; // Do not display the component in case of error
  }

  if (!componentReady()) {
    return null; // Do not display the component until ready
  }

  return (
    <div className="all-in-one-search position-relative mt-2 mb-4">
      <div className="search-input">
        <input
          type="search"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          placeholder="Search conversations..."
          className="form-control"
          style={{
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          }}
          // eslint-disable-next-line no-return-assign
          onMouseOver={(e) => (e.target.style.border = '1px solid #533DD1')}
          // eslint-disable-next-line no-return-assign
          onMouseOut={(e) => (e.target.style.border = '1px solid #D2D3D4')}
          // eslint-disable-next-line no-return-assign
          onFocus={(e) => (e.target.style.boxShadow = '0 0 0 3px #eceaf9')}
          // eslint-disable-next-line no-return-assign
          onBlur={(e) => (e.target.style.boxShadow = 'none')}
        />
      </div>
      <div style={{ zIndex: '1000' }} className="search-results position-absolute top-2 left-0 w-100">
        {renderSearchResults()}
      </div>
    </div>
  );
}

MessagingSearch.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default MessagingSearch;
