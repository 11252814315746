import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ReporterSubtypeListSelect from './reporter_subtype_list_select';
import SourceSubtypeSelect from './source_subtype_select';
import gaTracking from '../../javascript/ga_tracking';
import vahoy from '../../javascript/vahoy';

function UserTypeSelect({
                          hasError,
                          initialUserType,
                          initialReporterSubtypeList,
                          initialSourceSubtype,
                          listReporterSubtypes,
                          listSourceSubtypes,
                        }) {
  const [userType, setUserType] = useState(initialUserType);
  const [reporterSubtypeList, setReporterSubtypeList] = useState(initialReporterSubtypeList || '');
  const [sourceSubtype, setSourceSubtype] = useState(initialSourceSubtype || '');
  const gaPageName = 'signup';

  useEffect(() => {
    const inputs = document.querySelectorAll('.user_user_type');
    inputs.forEach((input) => {
      // eslint-disable-next-line no-param-reassign
      input.value = userType;
    });

    if (userType === 'reporter') {
      setSourceSubtype('');
      gaTracking.trackSignUp(gaPageName, 'clickUserTypeReporter');
      vahoy.track('User/UserTypeSelect#setUserType/reporter');
    }
    if (userType === 'source') {
      setReporterSubtypeList('');
      gaTracking.trackSignUp(gaPageName, 'clickUserTypeSource');
      vahoy.track('User/UserTypeSelect#setUserType/source');
    }
  }, [userType]);

  useEffect(() => {
    const inputs = document.querySelectorAll('.user_reporter_subtype_list');
    inputs.forEach((input) => {
      // eslint-disable-next-line no-param-reassign
      input.value = reporterSubtypeList;
    });
    if (reporterSubtypeList) {
      gaTracking.trackSignUp(gaPageName, 'clickUserTypeButton', reporterSubtypeList);
      vahoy.track(`User/UserTypeButton#clickButton/${reporterSubtypeList}`);
    }
  }, [reporterSubtypeList]);

  useEffect(() => {
    const inputs = document.querySelectorAll('.user_source_subtype');
    inputs.forEach((input) => {
      // eslint-disable-next-line no-param-reassign
      input.value = sourceSubtype;
    });
    if (sourceSubtype) {
      gaTracking.trackSignUp(gaPageName, 'clickUserTypeButton', sourceSubtype);
      vahoy.track(`User/UserTypeButton#clickButton/${sourceSubtype}`);
    }
  }, [sourceSubtype]);

  let errorDiv = '';
  if (hasError) {
    errorDiv = (
      <div className="text-danger">
        <div className="mt-3 mb-2">You must choose your user type</div>
      </div>
    );
  }

  const reporterSelectedClass = userType === 'reporter' ? 'selected' : '';
  const sourceSelectedClass = userType === 'source' ? 'selected' : '';
  return (
    <div className="row expanding-row">
      <div className="col-12">
        {errorDiv}
        <button
          className={`shadow d-flex flex-row justify-content-center align-content-center user-type-row card mb-4
            p-4 ${reporterSelectedClass}`}
          onClick={() => {
            setUserType('reporter');
          }}
          type="button"
          tabIndex="0"
        >
          <div>
            <strong className="font-size-22px">
              I Work in Media
            </strong>
            <div className="text-black-50 mt-1 font-size-16px">
              I write, produce, or create media.
            </div>
          </div>
        </button>
      </div>

      {userType === 'reporter' && (
        <ReporterSubtypeListSelect
          listReporterSubtypes={listReporterSubtypes}
          setReporterSubtypeList={setReporterSubtypeList}
          reporterSubtypeList={reporterSubtypeList}
        />
      )}

      <div className="col-12">
        <button
          className={`shadow d-flex flex-row justify-content-center align-content-center user-type-row card mb-4 p-4 ${sourceSelectedClass}`}
          style={{ marginBottom: '7px' }}
          onClick={() => {
            setUserType('source');
          }}
          type="button"
          tabIndex="0"
        >
          <div>
            <strong className="font-size-22px">I'm Looking to Earn Media</strong>
            <div className="text-black-50 mt-1 font-size-16px">I'm looking for media coverage for myself, my
              clients, or business.
            </div>
          </div>
        </button>
      </div>

      {userType === 'source' && (
        <SourceSubtypeSelect
          listSourceSubtypes={listSourceSubtypes}
          setSourceSubtype={setSourceSubtype}
          sourceSubtype={sourceSubtype}
        />
      )}
    </div>
  );
}

UserTypeSelect.propTypes = {
  hasError: PropTypes.bool,
  initialUserType: PropTypes.string,
  initialReporterSubtypeList: PropTypes.string,
  initialSourceSubtype: PropTypes.string,
  listReporterSubtypes: PropTypes.object,
  listSourceSubtypes: PropTypes.object,
};

UserTypeSelect.defaultProps = {
  hasError: false,
  initialUserType: undefined,
  initialReporterSubtypeList: undefined,
  initialSourceSubtype: undefined,
  listReporterSubtypes: undefined,
  listSourceSubtypes: undefined,
};

export default UserTypeSelect;
