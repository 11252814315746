import React from 'react';
import PropTypes from 'prop-types';

function ImageUploader(props) {
  const { uploadImage } = props;

  return (
    <div className="md-editor-fileupload-container">
      <label className="text-muted">
        <input type="file" className="md-editor-fileupload" onChange={uploadImage} />
        Click here to add an image.
      </label>
    </div>
  );
}

ImageUploader.propTypes = {
  uploadImage: PropTypes.func.isRequired,
};

export default ImageUploader;
