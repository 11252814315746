import React from 'react';
import PropTypes from 'prop-types';
import ProductCardSkeleton from '../../products/card/product_card_skeleton';

function CompanyProductsSkeletonPage({ numberOfCards }) {
  return (
    <>
      {Array(numberOfCards)
        .fill()
        .map((item, index) => (
          <div className="col mb-3" key={index} style={{ marginTop: '4rem' }}>
            <ProductCardSkeleton />
          </div>
        ))}
    </>
  );
}

CompanyProductsSkeletonPage.propTypes = {
  numberOfCards: PropTypes.number.isRequired,
};

export default CompanyProductsSkeletonPage;
