import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PitchWizardContextProvider } from './provider/pitch_wizard_provider';
import ErrorBoundary from '../error_boundary';
import RootUnlocked from './compose/RootUnlocked';

const generateUniqueString = () => Math.random().toString(36).substr(2, 4);

function PitchWizard({
                       cancelPath,
                       conversationOnly,
                       isEditing,
                       pitchId,
                       profileType,
                       redirectPath,
                       requestId,
                       userId,
                     }) {
  const [pitch, setPitch] = useState(null);

  useEffect(() => {
    if (!pitchId) {
      return;
    }

    const query = `/api/internal/jsonapi/pitches/${pitchId}?include=attachments,pitched_entities,products,companies,sources`;
    fetch(query, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          const d = responseJson;

          let pitchedEntities = [];
          if (d.included) {
            // // We are transforming the data from this format:
            // {
            //   "included":
            //     [
            //       {
            //         "id": "155284",
            //         "type": "pitched_entities",
            //         "attributes": {
            //           "product_id": "",
            //           "source_id": "4074",
            //         }
            //       },
            //       {
            //         "id": "4074",
            //         "type": "sources",
            //         "attributes": {
            //           "full_name": "Kevin Trowbridge",
            //         },
            //       }]}
            //
            // // To this format
            // { "pitchedEntities": [
            //     {
            //       "pitchedEntityId": "155284",
            //       "id": "4074",
            //       "type": "sources",
            //       "full_name": "Kevin Trowbridge",
            //     }]}

            // Step 1: Initial pass to pull out the correspondance between pitchedEntityId and (entity) id
            const pitchedEntityEntityMapping = d.included
              .filter((item) => item.type === 'pitched_entities')
              .map((included) => ({
                pitchedEntityId: included.id, id: included.attributes.source_id || included.attributes.product_id,
              }));

            // Step 2: Loop through the included array, pull all the products and sources out, and merge
            pitchedEntities = d.included
              .filter((item) => ['products', 'sources'].includes(item.type))
              .map((item) => {
                const a = item.attributes;
                const matchingEntity = pitchedEntityEntityMapping.find((pe) => pe.id === item.id);
                if (!matchingEntity) {
                  return null;
                }

                const serverTypeMapping = {
                  products: 'Product', sources: 'Source',
                };
                const serverType = serverTypeMapping[item.type];

                return {
                  existing: {
                    id: item.id,
                    localId: generateUniqueString(),
                    name: a.full_name || a.name, // Assuming full_name for sources and name for products
                    profileImageUrl: a.image_url || a.avatar_cloudinary_url,
                    selfExpert: a.is_self_expert === 'true',
                    selfExpertUserId: a.self_expert_user_id,
                    subheading: a.company_name,
                    type: serverType,
                    pitchedEntityId: matchingEntity.pitchedEntityId,
                  },
                };
              })
              .filter(Boolean);
          }

          let attachments = [];
          if (d.included) {
            attachments = d.included
              .filter((item) => item.type === 'attachments')
              .map((attachment) => ({
                id: attachment.id,
                name: attachment.attributes.name,
                url: attachment.attributes.url,
              }));
          }

          const transformedData = {
            ...d.data.attributes,
            existing_files: attachments,
            id: d.data.id,
            pitched_entities: pitchedEntities,
          };

          setPitch(transformedData);
        }
      });
  }, [pitchId, setPitch]);

  return (
    <div className="mw-100">
      {pitch && (
        <PitchWizardContextProvider userId={userId}>
          <ErrorBoundary>
            <RootUnlocked
              cancelPath={cancelPath}
              conversationOnly={conversationOnly}
              isEditing={isEditing}
              pitch={pitch}
              profileType={profileType}
              redirectPath={redirectPath}
              requestId={requestId}
            />
          </ErrorBoundary>
        </PitchWizardContextProvider>
      )}
    </div>
  );
}

PitchWizard.propTypes = {
  cancelPath: PropTypes.string.isRequired,
  conversationOnly: PropTypes.bool,
  isEditing: PropTypes.bool,
  pitchId: PropTypes.number.isRequired,
  profileType: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
  requestId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

PitchWizard.defaultProps = {
  conversationOnly: false,
  isEditing: false,
};

export default PitchWizard;
