import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { orderBy } from 'lodash';
import InfiniteHits from './infinite_hits';
import BeatsFilterPanel from './beats_filter_panel';
import ClearRefinementsButton from '../../algolia_search/clear_refinements_button';
import HashtagFilterPanel from '../../source_requests/search/hashtag_filter_panel';
import SmartStats from '../../algolia_search/smart_stats';
import StatusFilterPanel from './status_filter_panel';
import GenderFilterPanel from './gender_filter_panel';
import CompanyFilterPanel from './company_filter_panel';
import TitleFilterPanel from './title_filter_panel';
import GeoFilterPanel from './geo_filter_panel';
import MenuBar from '../../directory/MenuBar';

function SourcesSearch({
 view, query, algoliaAppId, algoliaSearchKey, indexName, displayContactAndInfo,
}) {
  const [clearGeoSearch, setClearGeoSearch] = useState(false);
  const [clearMenuToggle, setClearMenuToggle] = useState(false);

  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

  const handleClearAllSearch = () => {
    setClearGeoSearch(true);
    setClearMenuToggle(true);
  };

  const handleClearGeoSearch = () => {
    setClearGeoSearch(false);
  };

  const handleClearMenuToggle = () => {
    setClearMenuToggle(false);
  };

  return (
    <div className="ais-InstantSearch mt-3">
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <MenuBar view={view}>
          {{
            searchForm: (
              <>
                <SearchBox
                  autoFocus
                  translations={{
                    placeholder: 'Search',
                  }}
                  searchAsYouType={true}
                  defaultRefinement={query}
                  className="d-flex me-3"
                />
                <ClearRefinementsButton
                  buttonText="Clear"
                  clearsQuery={true}
                  onClear={handleClearAllSearch}
                  bootstrapClasses="px-3 btn btn-sm btn-outline-info text-nowrap"
                />
              </>
            ),
          }}
        </MenuBar>

        <div className="row mb-2">
          <div className="col pe-4 text-end">
            <SmartStats />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-3">
            <GenderFilterPanel />
            <BeatsFilterPanel
              attribute="_tags"
              transformItems={(items) => orderBy(items, 'label', 'asc')}
              clearMenuToggle={clearMenuToggle}
              onMenuToggleCleared={handleClearMenuToggle}
            />
            <StatusFilterPanel />
            <GeoFilterPanel clearGeoSearch={clearGeoSearch} onGeoSearchCleared={handleClearGeoSearch} />
            <CompanyFilterPanel />
            <TitleFilterPanel />
            <HashtagFilterPanel />
          </div>
          <div className="col">
            <InfiniteHits smallMode={false} displayContactAndInfo={displayContactAndInfo} query={query} />
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

SourcesSearch.propTypes = {
  view: PropTypes.string,
  query: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  displayContactAndInfo: PropTypes.bool,
};

SourcesSearch.defaultProps = {
  view: 'sources',
  query: '',
  displayContactAndInfo: true,
};

export default SourcesSearch;
