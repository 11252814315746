import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

function SourceRequestSkeletonCard({ smallMode }) {
  return (
    <div className="card h-100 skeleton-card animated faster fadeIn">
      <div className="card-body position-relative">
        {/* Publication logo */}
        <div className={`row gx-0 ${smallMode ? 'mb-3' : 'mb-4'}`}>
          <div className="col-12 text-center">
            <Skeleton height={smallMode ? 45 : 60} width={smallMode ? 45 : 60} />
          </div>
        </div>
        {/* Reporter avatar, name, and title */}
        <div>
          <div className="float-start pe-2">
            <Skeleton height={smallMode ? 33 : 50} width={smallMode ? 33 : 50} circle />
          </div>
          <div>
            <Skeleton height={10} width="40%" /><br />
            <Skeleton height={8} width="30%" />
          </div>
        </div>

        <div className={`clearfix ${smallMode ? 'mb-2' : 'mb-4'}`} />

        {/* "Request:" label */}
        {!smallMode && (
          <div className="mb-0">
            <Skeleton height={8} width={120} />
          </div>
        )}

        {/* Request name */}
        <div>
          <Skeleton height={10} width="100%" />
          <Skeleton height={10} width="80%" />
        </div>

        {/* Details */}
        {!smallMode && (
          <p className="my-2">
            <Skeleton height={8} count={2} />
            <Skeleton height={8} width="60%" />
          </p>
        )}

        {/* Hashtags */}
        <div>
          <Skeleton height={10} width={80} className="p-1 mb-1 me-1" />
          <Skeleton height={10} width={120} className="p-1 mb-1 me-1" />
          <Skeleton height={10} width={100} className="p-1 mb-1 me-1" />
          {!smallMode && (
            <>
              <Skeleton height={10} width={140} className="p-1 mb-1 me-1" />
              <Skeleton height={10} width={80} className="p-1 mb-1 me-1" />
            </>
          )}
        </div>

        <div className="reverse-space" style={{ minHeight: smallMode ? '2.75rem' : '3.0rem' }} />

        {/* Status/Deadline */}
        <div
          className="card-footer p-0 pb-2 bg-transparent border-0 position-absolute w-100"
          style={{ bottom: 0 }}
        >
          <Skeleton height={10} width={160} className="mt-1" /><br />
          <Skeleton height={10} width={80} className="mt-1" />
        </div>
      </div>
    </div>
  );
}

SourceRequestSkeletonCard.propTypes = {
  smallMode: PropTypes.bool,
};

SourceRequestSkeletonCard.defaultProps = {
  smallMode: false,
};

export default SourceRequestSkeletonCard;
