import React, { useState } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';

function NeedsPitchesCheckbox({ sourceRequest }) {
  const [showValue, setShowValue] = useState(sourceRequest.want_pitches);

  const handleShowChange = (e) => {
    const newValue = e.target.checked;

    const params = {
      want_pitches: newValue,
    };

    vapi.updateSourceRequest(sourceRequest.id, params)
      .then(() => {
        setShowValue(newValue);
      }).catch((error) => {
      alert('An error occurred while muting the user. Please try again later.');
      throw error; // Rethrow the errosr
    });
  };

  return (
    <input
      type="checkbox"
      className="form-check-input"
      checked={showValue}
      onChange={handleShowChange}
    />
  );
}

NeedsPitchesCheckbox.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object).isRequired,
};

export default NeedsPitchesCheckbox;
