import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  updatePrUser(prUser) {
    vlog.info('api#updatePrUser');

    api.configure();

    const url = prUser.links.self;

    const data = {
      id: prUser.id,
      type: 'pr_users',
      attributes: prUser.attributes,
    };

    return axios.put(url, { data }, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },

  getPrUsers(params) {
    api.configure();
    const options = { params };

    return axios.get('/api/internal/pr_users', options, api.axiosFix);
  },

  getAlgoliaAttribsForPrUserIds(ids) {
    api.configure();
    const idStr = ids.join('&ids[]=');

    return axios.get(`/api/internal/pr_users/algolia_attribs?ids[]=${idStr}`, {}, api.axiosFix);
  },
});
