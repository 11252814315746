import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RequestSampleModal from './modal';

function Button({
  productName, productPrice, productCompanyName, productImageUrl, productSgid, forPage,
}) {
  const [showRequestSampleModal, setShowRequestSampleModel] = useState(false);
  const horizontalSpacer = ' ';
  const buttonText = forPage == 'show' ? 'Request a Sample' : 'Request Sample';

  return (
    <>
      {forPage == 'index' && (
        <button
          type="button"
          className="request-sample btn btn-sm qwoted-btn-xs btn-outline-primary animated faster fadeIn"
          onClick={() => setShowRequestSampleModel(true)}
          title={buttonText}
        >
          <i className="fa-solid fa-basket-shopping align-middle" />
          {horizontalSpacer}
          <span className="align-middle">{buttonText}</span>
        </button>
      )}

      {forPage == 'show' && (
        <button
          type="button"
          onClick={() => setShowRequestSampleModel(true)}
          className="btn btn-outline-primary fw-bold text-smallcaps animated faster fadeIn"
          style={{ whiteSpace: 'normal', fontSize: '1em' }}
        >
          <span style={{ margin: 'auto', width: '100em' }}>
            <i className="fa-solid fa-basket-shopping me-2" title={buttonText} /> {buttonText}
          </span>
        </button>
      )}

      <RequestSampleModal
        isOpen={showRequestSampleModal}
        close={() => setShowRequestSampleModel(false)}
        productName={productName}
        productPrice={productPrice}
        productCompanyName={productCompanyName}
        productImageUrl={productImageUrl}
        productSgid={productSgid}
      />
    </>
  );
}

Button.propTypes = {
  forPage: PropTypes.string,
  productName: PropTypes.string.isRequired,
  productPrice: PropTypes.string,
  productCompanyName: PropTypes.string.isRequired,
  productSgid: PropTypes.string,
  productImageUrl: PropTypes.string.isRequired,
};

Button.defaultProps = {
  forPage: 'index',
  productPrice: undefined,
  productSgid: undefined,
};

export default Button;
