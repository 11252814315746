import React from 'react';
import { Button, Input } from 'reactstrap';
import PropTypes from 'prop-types';

import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';

class ContactInfoReactInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      contactInfo: props.contactInfo,
      errors: [],
    };
  }

  componentDidMount() {
    const { contactInfo } = this.props;
    if (!contactInfo.attributes.value) {
      this.startEditing();
    }
  }

  getIcon = () => {
    let icon;
    const { info_type: infoType } = this.props;

    switch (infoType) {
      case 'email': {
        icon = <i className="fa-solid fa-envelope me-2" />;
        break;
      }
      case 'phone': {
        icon = <i className="fa-solid fa-phone me-2" />;
        break;
      }
      case 'url': {
        icon = <i className="fa-solid fa-link me-2" />;
        break;
      }
      case 'facebook': {
        icon = <i className="fa-brands fa-facebook me-2" />;
        break;
      }
      case 'twitter': {
        icon = <i className="fa-brands fa-x-twitter me-2" />;
        break;
      }
      default: {
        icon = '';
      }
    }

    return icon;
  };

  inputField = (info, i) => {
    const { info_type: infoType, placeholder } = this.props;

    return (
      <Input
        type="text"
        key={info.id || info.__private_id}
        defaultValue={info.attributes.value}
        onChange={this.handleInputChange}
        placeholder={placeholder}
        id={`contact-info-field-${infoType}-${i}`}
      />
    );
  };

  delete = () => {
    const {
      contactInfo: propsContactInfo,
      refreshOpportunityContactEmployment,
    } = this.props;
    const { contactInfo } = this.state;

    if (propsContactInfo && propsContactInfo.id) {
      this.destroyContactInfo(contactInfo);
    } else {
      refreshOpportunityContactEmployment();
    }
  };

  handleInputChange = (e) => {
    const { contactInfo } = this.state;
    _.extend(contactInfo.attributes, { value: e.target.value });
    this.setState({ contactInfo });
  };

  makePrimary = () => {
    const { contactInfo } = this.state;
    _.extend(contactInfo.attributes, { primary: true });
    this.setState({ contactInfo }, this.handleSave);
  };

  handleCancel = () => {
    const { persistedContactInfo } = this.state;
    this.setState({
      hidden: !persistedContactInfo.attributes.value,
      editing: false,
      contactInfo: persistedContactInfo,
      errors: [],
    });
  };

  handleSave = () => {
    const {
      contactInfo: propsContactInfo,
    } = this.props;
    const { contactInfo } = this.state;

    if (propsContactInfo && propsContactInfo.id) {
      this.updateContactInfo(contactInfo);
    } else {
      this.createContactInfo(contactInfo);
    }
  };

  startEditing = () => {
    const { contactInfo } = this.state;
    this.setState({
      persistedContactInfo: _.cloneDeep(contactInfo),
      editing: true,
    });

    vahoy.track('Opportunity/ContactInfo/ContactInfoReactInput#startEditing');
  };

  stopEditing = () => {
    this.setState({ editing: false });
  };

  destroyContactInfo = (contactInfo) => {
    const { refreshOpportunityContactEmployment } = this.props;
    vapi.deleteContactInfo(contactInfo)
      .then(() => {
        refreshOpportunityContactEmployment();
      });

    vahoy.track('Opportunity/ContactInfo/ContactInfoReactInput#destroyContactInfo');
  };

  updateContactInfo = (contactInfo) => {
    vapi.updateContactInfo(contactInfo)
      .then((response) => {
        this.handleUpdateCreateResponse(response);
      });

    vahoy.track('Opportunity/ContactInfo/ContactInfoReactInput#updateContactInfo');
  };

  createContactInfo = (contactInfo) => {
    const { contactable, contactableType } = this.props;

    vapi.createContactInfo(contactInfo, contactable.id, contactableType)
      .then((response) => {
        this.handleUpdateCreateResponse(response);
      });

    vahoy.track('Opportunity/ContactInfo/ContactInfoReactIput#createContactInfo');
  };

  handleUpdateCreateResponse = (response) => {
    const responseErrors = vapi.responseErrors(response);
    const { refreshOpportunityContactEmployment } = this.props;

    if (responseErrors) {
      this.setState({ errors: responseErrors });
    } else {
      this.setState({ errors: [] });
      this.stopEditing();
      refreshOpportunityContactEmployment();
    }
  };

  staticFields = () => {
    const { contactInfo } = this.state;
    let primaryButton;

    if (JSON.parse(contactInfo.attributes.primary)) {
      primaryButton = (
        <button
          type="button"
          className="me-1 mb-1 btn btn-link"
          title="Primary"
        >
          <i className="fa-solid fa-check text-success" />
        </button>
      );
    } else {
      primaryButton = (
        <button
          type="button"
          className="me-1 mb-1 btn btn-link"
          title="Make primary"
          onClick={this.makePrimary}
        >
          <i className="fa-solid fa-check text-muted" />
        </button>
      );
    }

    const editButton = (
      <button
        type="button"
        className="btn btn-sm btn-link mb-1"
        onClick={this.startEditing}
      >
        <i className="fa-solid fa-pencil" />
      </button>
    );

    const icon = this.getIcon();

    return (
      <div>
        <span>{icon}{contactInfo.attributes.value}</span>
        <span>&nbsp;{primaryButton}</span>
        <span>&nbsp;{editButton}</span>
      </div>
    );
  };

  editableFields = () => {
    const { contactInfo, errors } = this.state;
    const { number } = this.props;

    const deleteButton = (
      <button
        type="button"
        className="btn btn-sm btn-outline-danger me-1"
        onClick={this.delete}
      >
        <i className="fa-solid fa-trash-can" />
      </button>
    );

    return (
      <div>
        <div key={contactInfo.id || contactInfo.__private_id} className="row mt-1">
          <div className="col-9">
            {this.inputField(contactInfo, number)}
          </div>
          <div className="col-3">
            <Button
              className="btn btn-sm me-1"
              type="button"
              onClick={this.handleCancel}
            >Cancel
            </Button>
            {deleteButton}
            <Button
              color="primary"
              className="btn btn-sm"
              type="button"
              onClick={this.handleSave}
            >Save
            </Button>
            <span />
          </div>
        </div>
        {errors.map((error) => <div className="form-text text-danger">{error.title}</div>)}
      </div>
    );
  };

  render() {
    let fields;
    const { editing, hidden } = this.state;

    if (editing) {
      fields = this.editableFields();
    } else if (!hidden) {
      fields = this.staticFields();
    } else {
      fields = null;
    }
    return fields;
  }
}

ContactInfoReactInput.propTypes = {
  contactInfo: PropTypes.instanceOf(Object).isRequired,
  info_type: PropTypes.string,
  placeholder: PropTypes.string,
  refreshOpportunityContactEmployment: PropTypes.func.isRequired,
  contactable: PropTypes.instanceOf(Object),
  contactableType: PropTypes.string,
  number: PropTypes.number,
};

ContactInfoReactInput.defaultProps = {
  info_type: undefined,
  placeholder: undefined,
  contactable: undefined,
  contactableType: undefined,
  number: undefined,
};

export default ContactInfoReactInput;
