import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

function SidebarSkeletonList({ numberOfLines, textOnly }) {
  return (
    <>
      {Array(numberOfLines).fill().map((item, index) => (
        <div className="row ms-1 mb-2 align-items-center animated faster fadeIn" key={index}>
          {!textOnly && (
            <Skeleton height={80} width={80} circle />
          )}
          <div className="col">
            <Skeleton height={10} width="60%" />
            <Skeleton height={8} width="100" lines={2} />
          </div>
        </div>
      ))}
    </>
  );
}

SidebarSkeletonList.propTypes = {
  numberOfLines: PropTypes.number.isRequired,
  textOnly: PropTypes.bool,
};

SidebarSkeletonList.defaultProps = {
  textOnly: false,
};

export default SidebarSkeletonList;
