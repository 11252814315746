import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';

function Comments({ sourceRequest }) {
  const [asyncError, setAsyncError] = useState();
  const [sourceRequestComment, setSourceRequestComment] = useState();

  useEffect(() => {
    const params = {
      source_request_id: sourceRequest.id,
    };

    const getSourceRequestLatestComment = async () => {
      try {
        const response = await vapi.getSourceRequestLatestComment(params);
        setSourceRequestComment(response.data);
      } catch (error) {
        setAsyncError(error);
      }
    };
    getSourceRequestLatestComment();
  }, [sourceRequest.id]);

  if (asyncError) throw asyncError;

  return (
    <span>
      {sourceRequestComment && sourceRequestComment.comment}
    </span>
  );
}

Comments.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object).isRequired,
};

export default Comments;
