import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LoadingImage from '../loading_image';
import AutocompletePlace from '../autocomplete_place';

const invalidErrorText = 'Invalid location';

class LocationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {};
  }

  getFormErrors() {
    const { location } = this.state;
    const errors = {};

    if (location && !location.fullAddress) {
      errors.value = invalidErrorText;
    }

    return errors;
  }

  storeLocation = (location) => {
    this.setState({ location });
  };

  submit = (evt) => {
    evt && evt.preventDefault();
    const errors = this.getFormErrors();
    const { location } = this.state;
    const { onSave } = this.props;

    if (_.isEmpty(errors)) {
      this.setState({ displayErrors: false });
      onSave && onSave(location, () => {
        this.setState({ displayErrors: true });
      });
    } else {
      this.setState({ displayErrors: true });
    }
  };

  renderError(errors, field) {
    const errorText = errors[field];

    if (!errorText) {
      return;
    }

    return (
      <small className="form-text d-block text-danger">
        {errorText}
      </small>
    );
  }

  render() {
    const {
      isSaving, isOpening, label, onCancel, headerContent, value, responseError, onRemove,
    } = this.props;
    let saveButton;
    let removeButton;
    const { displayErrors } = this.state;

    // NOTE: Jsonapi-resources returns errors in array
    const serverErrors = (responseError || {}).errors || [];
    const error = serverErrors[0] || {};
    const localErrors = displayErrors ? this.getFormErrors() : {};
    const errors = {
      value: localErrors.value || error.detail,
    };

    if (isSaving) {
      saveButton = (
        <LoadingImage height="30px" />
      );
    } else {
      saveButton = (<button type="submit" className="btn btn-primary mb-2">Save</button>);
      removeButton = (
        <button type="button" className="btn btn-danger mb-2 ms-2 btn-remove" onClick={onRemove}>
          <i className="fa fa-trash-can" />
        </button>
      );
    }

    return (
      <Modal isOpen={isOpening} className="location-modal">
        <ModalHeader toggle={() => onCancel && onCancel()}>{headerContent}</ModalHeader>
        <ModalBody>
          <form className="" onSubmit={this.submit}>
            <div className="d-flex flex-row w-100">
              <div className="mx-sm-3 mb-2" style={{ flex: 1 }}>
                <label className="visually-hidden">{label}</label>
                <AutocompletePlace className="form-control" defaultValue={value} onChange={this.storeLocation} />
              </div>
              {saveButton}
              {removeButton}
            </div>
            <div className="mx-sm-3">{this.renderError(errors, 'value')}</div>
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

LocationModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  isOpening: PropTypes.bool,
  label: PropTypes.string,
  headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  value: PropTypes.string,
  responseError: PropTypes.instanceOf(Object),
};

LocationModal.defaultProps = {
  isSaving: undefined,
  isOpening: undefined,
  label: undefined,
  headerContent: undefined,
  value: undefined,
  responseError: undefined,
};

export default LocationModal;
