// PitchedEntity service
//
// A PitchedEntity is the join model between a Pitch and the thing(s) being
// pitched i.e. one or more PromoEntities (Source/Product/Company).

const preparePitchedEntitiesForSubmission = async (promoEntities) => {
  const pitchedEntities = await Promise.all(promoEntities.map(async (promoEntity) => {
    if (promoEntity.new) {
      throw new Error('preparePitchedEntitiesForSubmission should not be called with new promoEntities');
    }

    return {
      entity_id: promoEntity.existing.id,
      entity_type: promoEntity.existing.type,
    };
  }));

  return pitchedEntities;
};

export default preparePitchedEntitiesForSubmission;
