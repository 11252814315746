import React from 'react';
import PropTypes from 'prop-types';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

function ClearRefinementsButton({
                                  items, refine, buttonText, bootstrapClasses, onClear,
                                }) {
  const handleClick = () => {
    refine(items);
    onClear();
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={!items.length}
      className={`${bootstrapClasses} ais-ClearRefinements-button-custom`}
    >
      {buttonText}
    </button>
  );
}

ClearRefinementsButton.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  refine: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  bootstrapClasses: PropTypes.string,
  onClear: PropTypes.func,
};

ClearRefinementsButton.defaultProps = {
  items: undefined,
  buttonText: 'Clear all',
  bootstrapClasses: 'btn btn-sm btn-outline-info text-nowrap',
  onClear: () => {
  },
};

export default connectCurrentRefinements(ClearRefinementsButton);
