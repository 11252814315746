import axios from 'axios';
import _ from 'lodash';
import api from './api';
import helper from '../helpers/helper';
import vlog from '../../vlog';

_.extend(api, {

  createOrUpdatePitch(pitch) {
    if (pitch.id) {
      return this.updatePitch(pitch);
    }
    return this.createPitch(pitch);
  },

  createPitch(pitch) {
    vlog.info('api#createPitch');

    api.configure();

    const url = '/api/internal/pitches';
    const params = { pitch };
    const formData = helper.objectToFormData(params);
    const config = {};

    config.headers = {
      'Content-Type': 'multipart/form-data',
    };

    return axios.post(url, formData, config);
  },

  updatePitch(pitch) {
    vlog.info('api#updatePitch');

    api.configure();

    const url = `/api/internal/pitches/${pitch.id}`;
    const params = { pitch };
    delete params.pitch.id;
    const formData = helper.objectToFormData(params);
    const config = {};

    config.headers = {
      'Content-Type': 'multipart/form-data',
    };
    return axios.put(url, formData, config);
  },

  loadPitches(params) {
    vlog.info('api#loadPitches');

    api.configure();

    const url = '/api/internal/jsonapi/pitches';

    return axios.get(url, { params }, api.axiosFix);
  },
});
