import helper from '../../../helpers/helper';

jQuery(document).ready(() => {
  if (helper.notPresent($('.sessions.new'))) {
    return;
  }

  // KT: Search globally for force_omniauth to understand what this is for ...
  const forceOmniauthParam = helper.urlParamsHash().force_omniauth || '';
  if (forceOmniauthParam.length > 0 && forceOmniauthParam === 'twitter') {
    $('#twitter-omniauth .js-force-omniauth-autologin').trigger('click');
  }
});
