import React from 'react';
import PropTypes from 'prop-types';
import AttachEntityInline from './attach_entity/AttachEntityInline';

function InitialAttachEntityType({ addExistingEntityToPitch, addNewEntityToPitch, onUnspecifiedButtonClick }) {
  return (
    <div>
      <h3>Which to pitch?</h3>
      <AttachEntityInline
        addExistingEntityToPitch={addExistingEntityToPitch}
        addNewEntityToPitch={addNewEntityToPitch}
        onUnspecifiedButtonClick={onUnspecifiedButtonClick}
      />
    </div>
  );
}

InitialAttachEntityType.propTypes = {
  addExistingEntityToPitch: PropTypes.func.isRequired,
  addNewEntityToPitch: PropTypes.func.isRequired,
  onUnspecifiedButtonClick: PropTypes.func.isRequired,
};

export default InitialAttachEntityType;
