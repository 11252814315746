import React from 'react';
import PropTypes from 'prop-types';
import Select2Ajax from './select2_ajax';

function Select2ClientFirm(props) {
  const { onChange } = props;

  const processSelect2Result = (response) => {
    const results = (response.data || []).map((result) => {
      const r = { ...result };
      r.text = result.attributes.name;
      return r;
    });

    return {
      results,
      pagination: {
        more: !!(response.links || {}).next,
      },
    };
  };

  const handleOnChange = (e) => {
    const clientFirmId = jQuery(e.target)
      .val();
    onChange && onChange(clientFirmId);
  };

  const {
    ajaxUrl, placeholderText, className, selectedOption,
  } = props;

  return (
    <Select2Ajax
      ajaxUrl={ajaxUrl || '/api/internal/jsonapi/client-firms'}
      ajaxProcessResults={processSelect2Result}
      placeholderText={placeholderText || 'Client firm represented? Type to search ...'}
      onChange={handleOnChange}
      className={className || 'form-select'}
      selectedOption={selectedOption}
      existingOnly
    />
  );
}

Select2ClientFirm.propTypes = {
  ajaxUrl: PropTypes.string,
  placeholderText: PropTypes.string,
  className: PropTypes.string,
  selectedOption: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
};

Select2ClientFirm.defaultProps = {
  ajaxUrl: undefined,
  placeholderText: undefined,
  className: undefined,
  selectedOption: undefined,
  onChange: undefined,
};

export default Select2ClientFirm;
