import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/avatar';

const createHashtagSet = (hiddenSearchString) => {
  if (!hiddenSearchString) return new Set();
  return new Set(
    hiddenSearchString
      .split(' ')
      .filter((tag) => tag.startsWith('#'))
      .map((tag) => tag.toLowerCase()),
  );
};

function MediaMatchCard({
  match,
  updates,
  hiddenSearchString,
  query,
  canPitchMediaMatch,
  onCardClick,
}) {
  if (updates) Object.assign(match, updates.attributes);
  const myMatches = (hiddenSearchString) && (hiddenSearchString.length > 2) && (hiddenSearchString === query);
  const requestUrl = canPitchMediaMatch ? `${match.source_request_path}?query=${query}` : '';
  const matchingHashtags = useMemo(() => {
    if (!match.hashtags || !hiddenSearchString) return [];

    const searchHashtags = createHashtagSet(hiddenSearchString);

    return match.hashtags
      .filter(({ hashtag }) => searchHashtags.has(hashtag.toLowerCase()))
      .slice(0, 4)
      .map(({ hashtag }) => hashtag);
  }, [match.hashtags, hiddenSearchString]);

  const employmentString = match.reporter?.employment_string;

  return (
    <div
      className="position-relative card h-100 source-request-card animated faster fadeIn d-flex justify-content-between flex-column"
      id={`source-request-${match.objectID}`}
      style={{ padding: '24px 16px' }}
    >
      { matchingHashtags.length > 3 && (
        <span
          className="border position-absolute rounded px-1"
          style={{
 fontSize: '14px', borderColor: '#175CD3 !important', backgroundColor: '#EFF8FF', color: '#175CD3', right: '10px', top: '10px',
}}
        >
          Great Match!
        </span>
      )}
      <div className="d-flex flex-row justify-content-start" style={{ marginBottom: '16px' }}>
          <Avatar
            avatarUrl={match.reporter?.avatar_url}
            sizeClass="avatar"
            avatarLetters={match.reporter?.initials}
            className="rounded p-0 me-0 rounded rounded-circle"
          />
        <div style={{ marginLeft: '12px' }} className="d-flex flex-column justify-content-center">
          <h5 className="fw-bold mb-0" style={{ fontSize: '18px' }}>{match.reporter?.first_name_last_initial}</h5>
          { employmentString && (
            <p style={{ marginBottom: '0', fontSize: '14px', marginTop: '4px' }}>{match.reporter?.employment_string}</p>
          )}
        </div>

      </div>
      {myMatches && matchingHashtags.length > 0 && (
        <div
          className="border bg-gray-100 rounded p-2"
          style={{
 marginBottom: '16px', float: 'left', height: 'auto', backgroundColor: '#F6F6F6',
}}
        >
          <div className="text-muted small mb-2 s">
            <span className="text-black mb-2">Chosen for you based on: </span>
            <div className="w-full d-flex flex-wrap mt-2">
             {matchingHashtags.map((hashtag) => (
                <span key={hashtag} className="rounded border py-1 px-2 mb-1 bg-white me-1 text-black" style={{ fontSize: '12px' }}>
                  {hashtag}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
      {!myMatches && match.hashtags.length > 0 && (
        <div
          className="border bg-gray-100 rounded p-2 mb-2"
          style={{
 marginBottom: '16px', float: 'left', height: '100px', backgroundColor: '#F6F6F6',
}}
        >
          <div className="text-muted small mb-2 s">
            <div className="w-full d-flex flex-wrap mt-2">
             {match.hashtags.slice(0, 4).map((hashtag) => (
                <span key={hashtag.hashtag} className="rounded border py-1 px-2 mb-1 bg-white me-1 text-black" style={{ fontSize: '12px' }}>
                  {hashtag.hashtag}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
      <div style={{ marginBottom: '16px' }}>
        <p style={{ fontSize: '14px', marginBottom: '8px' }}>Contributor For:</p>
        <img
          alt={match.publication.name}
          src={match.publication.logo_url}
          className="me-2 ms-0 float-start"
          style={{
            height: '50px',
            maxHeight: '50px',
            maxWidth: '250px',
          }}
        />
      </div>
      <a
        className="border btn btn-primary rounded p-2 text-center"
        style={{ fontSize: '14px' }}
        href={requestUrl}
        onClick={onCardClick}
      >
        <i className="fa fa-envelope me-2" />
        Start a conversation
      </a>
    </div>
  );
}

MediaMatchCard.propTypes = {
  match: PropTypes.instanceOf(Object),
  updates: PropTypes.instanceOf(Object),
  hiddenSearchString: PropTypes.string,
  query: PropTypes.string.isRequired,
  onCardClick: PropTypes.func.isRequired,
  canPitchMediaMatch: PropTypes.bool,
};

MediaMatchCard.defaultProps = {
  match: undefined,
  updates: undefined,
  hiddenSearchString: '',
  canPitchMediaMatch: true,
};

export default MediaMatchCard;
