import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';

function MuteModal({
                     close_modal: closeModal,
                     handle_duration_days_change: handleDurationDaysChange,
                     duration_days: durationDays,
                     show_modal: showmodal,
                     submit_report: submitReport,
                     default_mute_duration_days: defaultMuteDurationDays,
                   }) {
  return (
    <Modal className="modal-lg" isOpen={showmodal} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        <div className="h3 text-warning"><i className="fa-solid fa-volume-xmark" />&nbsp;Mute User</div>
      </ModalHeader>

      <ModalBody>
        <div className="alert alert-warning">
          Are you sure you want to mute this user?<br />
          You will no longer receive any pitches or direct messages from this user.
        </div>
        <div className="row">
          <div className="col-12">
            <div className="col-md-10 form-inline">
              <div className="form-check-inline">
                <label
                  className="form-check-label"
                  htmlFor={`reporter_mute_duration_days_${defaultMuteDurationDays}`}
                >
                  Mute for {defaultMuteDurationDays} days
                </label>&nbsp;
                <input
                  className="form-check-input"
                  id={`reporter_mute_duration_days_${defaultMuteDurationDays}`}
                  name="reporter_mute[duration_days]"
                  type="radio"
                  value={defaultMuteDurationDays}
                  checked={durationDays === `${defaultMuteDurationDays}`}
                  onChange={handleDurationDaysChange}
                />
                <span className="px-2" />
                <label
                  className="form-check-label"
                  htmlFor="reporter_mute_duration_days_0"
                >
                  Mute Permanently
                  </label>&nbsp;
                <input
                  className="form-check-input"
                  id="reporter_mute_duration_days_0"
                  name="reporter_mute[duration_days]"
                  type="radio"
                  value="0"
                  checked={durationDays === '0'}
                  onChange={handleDurationDaysChange}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color="danger" onClick={submitReport}>Mute</Button>
        <Button onClick={closeModal}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}

MuteModal.propTypes = {
  close_modal: PropTypes.func.isRequired,
  handle_duration_days_change: PropTypes.func.isRequired,
  duration_days: PropTypes.string,
  show_modal: PropTypes.bool,
  submit_report: PropTypes.func.isRequired,
  default_mute_duration_days: PropTypes.number.isRequired,
};

MuteModal.defaultProps = {
  duration_days: undefined,
  show_modal: false,
};

export default MuteModal;
