import React from 'react';
import PropTypes from 'prop-types';
import vahoy from '../javascript/vahoy';

function Hashtags({
 hashtagTarget, maxHashtags, title, smallMode, clickEventName, tagStyle,
}) {
  if (!hashtagTarget.hashtags || !hashtagTarget.hashtags.length > 0) return null;

  const trackClicks = () => {
    if (clickEventName) {
      vahoy.track(clickEventName);
    }
  };

  const styleClasses = {
    default: 'p-1 mb-1 me-1 text-dark badge bg-light text-dark font-size-10px text-decoration-none',
    links: 'btn btn-sm btn-link p-0 border-0 text-decoration-underline',
  };

  const hashtagsToRender = hashtagTarget.hashtags.slice(0, maxHashtags);

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const formatHashtag = (hashtag, index) => {
    if (tagStyle == 'default') {
      return hashtag;
    }

    const linkFormattedHashtag = hashtag
      .substr(1)
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase();
    return index == 0 ? capitalizeFirstLetter(linkFormattedHashtag) : linkFormattedHashtag;
  };

  const renderHashtag = (hashtag, index) => (
    <React.Fragment key={hashtag.id}>
      {tagStyle == 'links' && !!index && ', ' /* Join links with commas */}
      <a href={hashtag.hashtag_path} key={hashtag.id} onClick={trackClicks} className={styleClasses[tagStyle]}>
        {formatHashtag(hashtag.hashtag, index)}
      </a>
    </React.Fragment>
  );

  const renderHashtags = () => hashtagsToRender.map((hashtag, i) => renderHashtag(hashtag, i));

  return (
    <>
      {smallMode && <div className="mb-1" />}
      {title && <div className="mb-0 text-uppercase text-secondary font-size-12px">{title}</div>}
      {renderHashtags()}
    </>
  );
}

Hashtags.propTypes = {
  hashtagTarget: PropTypes.instanceOf(Object).isRequired,
  maxHashtags: PropTypes.number,
  title: PropTypes.string,
  smallMode: PropTypes.bool,
  clickEventName: PropTypes.string,
  tagStyle: PropTypes.string,
};

Hashtags.defaultProps = {
  maxHashtags: 10,
  title: undefined,
  smallMode: false,
  clickEventName: undefined,
  tagStyle: 'default',
};

export default Hashtags;
