import React from 'react';
import PropTypes from 'prop-types';
import Hashtags from '../../hashtags';
import ActionButtons from './action_buttons';
import ResponsiveAvatar from '../../responsive_avatar';

function SourceCardMinimal({
                             source, updates, smallMode, displayContactAndInfo,
                           }) {
  // Late arriving attributes to be merged in, if any
  if (updates) Object.assign(source, updates.attributes);
  const maxHashtags = smallMode ? 3 : 5;

  return (
    <div className="card h-100 source-card animated faster fadeIn">
      <div className="card-body position-relative">
        <ResponsiveAvatar
          avatarUrl={source.avatar_url}
          avatarLetters={source.initials}
          className="mb-2 avatar-responsive"
        />

        <a
          href={source.source_path}
          className="fw-bold cursor-pointer"
        >
          {source.full_name}
        </a>

        <div className="mb-2 small">
          {source.employments_string}
        </div>

        <div className="row m-0">
          <Hashtags
            hashtagTarget={source}
            maxHashtags={maxHashtags}
            title={null}
            clickEventName="Sources/SourceCardMinimal#clickHashtag"
          />
        </div>

        {displayContactAndInfo && (
          <ActionButtons
            source={source}
            smallMode={smallMode}
            displayContactAndInfo={displayContactAndInfo}
          />
        )}
      </div>
    </div>
  );
}

SourceCardMinimal.propTypes = {
  source: PropTypes.instanceOf(Object),
  updates: PropTypes.instanceOf(Object),
  smallMode: PropTypes.bool,
  displayContactAndInfo: PropTypes.bool,
};

SourceCardMinimal.defaultProps = {
  source: undefined,
  updates: undefined,
  smallMode: false,
  displayContactAndInfo: true,
};

export default SourceCardMinimal;
