import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CompanyContext } from '../../data/company_context_management';
import PrUserList from './pr_user_list';
import PrAgencyList from './pr_agency_list';
import ContactInfosList from './contact_infos_list';
import StaffView from '../../../staff_view';
import ButtonMerge from './button_merge';
import ButtonDelete from './button_delete';
import CompanyOrPrFirmInfo from './company_or_pr_firm_info';
import CompanyTwitterFeed from './company_twitter_feed';
import HashtagList from './hashtag_list';
import ClaimThisPageBox from './claim_this_page_box';
import AhoyEventList from './ahoy_event_list';

function Sidebar(props) {
  const { userId } = props;
  const { company } = useContext(CompanyContext);

  return (
    <div className="h-100 col-md-3 col-sm-9 ms-auto">
      {!userId && (
        <ClaimThisPageBox />
      )}
      <StaffView>
        <ButtonMerge />
        <ButtonDelete />
        <hr />
        <CompanyOrPrFirmInfo />
      </StaffView>
      {!company.display_as_pr_firm && (
        <>
          <PrUserList />
          <PrAgencyList />
        </>
      )}
      <HashtagList />
      <ContactInfosList />
      <CompanyTwitterFeed />
      {userId && (
        <AhoyEventList companyId={company.objectID} />
      )}
    </div>
  );
}

Sidebar.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Sidebar.defaultProps = {
  userId: undefined,
};

export default Sidebar;
