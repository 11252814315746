// PromoEntity service
//
// A PromoEntity is a "thing" that can be/is being pitched.
//
// Previously the only possible "thing" was a Source, but now we also have
// introduced the ability to pitch a Product (and, some support was previously
// added for pitching a Company, but that was never completed).
//
// So, although we don't have an abstract PromoEntity model, you can think of it as:
//
// PromoEntity
//  |
//  +-- Source
//  |
//  +-- Product
//  |
//  +-- (Company)

import vapi from '../../../javascript/frontend/api/vapi';

const generateUniqueString = () => Math.random().toString(36).substr(2, 4);

const subheadingForSource = (companyName, jobTitle) => {
  if (companyName && jobTitle) {
    return `${jobTitle} at ${companyName}`;
  }

  return jobTitle || companyName;
};

const newPromoEntity = (type, data) => {
  const basicRepresentation = {
    localId: generateUniqueString(),
    type,
  };

  if (type === 'Source') {
    return {
      ...basicRepresentation,
      name: data.name,
      jobTitle: data.jobTitle,
      companyName: data.companyName,
      companyId: data.companyId,
      selfExpert: data.isSelfExpert,
      subheading: subheadingForSource(data.companyName, data.jobTitle),
    };
  }

  if (type === 'Product') {
    return {
      ...basicRepresentation,
      name: data.name,
      productUrl: data.productUrl,
      companyName: data.companyName,
      companyId: data.companyId,
      subheading: `by ${data.companyName}`,
    };
  }

  throw new Error(`Unknown promoted entity type: ${type}`);
};

// Converts an API representation of a promo entity to our frontend representation
const formattedPromoEntity = (apiPromoEntity, localId) => {
  const remoteToLocalTypes = { sources: 'Source', products: 'Product' };

  const basicRepresentation = {
    localId: localId || generateUniqueString(), // keep consistent localIds when persisting new promo entities
    id: apiPromoEntity.id,
    type: remoteToLocalTypes[apiPromoEntity.type],
  };

  if (basicRepresentation.type === 'Source') {
    return {
      ...basicRepresentation,
      name: apiPromoEntity.attributes.full_name,
      profileImageUrl: apiPromoEntity.attributes.avatar_cloudinary_url,
      companyName: apiPromoEntity.attributes.company_name,
      jobTitle: apiPromoEntity.attributes.employments_string_without_company,
      selfExpert: apiPromoEntity.attributes.is_self_expert === 'true',
      selfExpertUserId: apiPromoEntity.attributes.self_expert_user_id,
      subheading: subheadingForSource(
        apiPromoEntity.attributes.company_name,
        apiPromoEntity.attributes.employments_string_without_company,
      ),
    };
  }

  if (basicRepresentation.type === 'Product') {
    return {
      ...basicRepresentation,
      name: apiPromoEntity.attributes.name,
      profileImageUrl: apiPromoEntity.attributes.image_url,
      companyName: apiPromoEntity.attributes.company_name,
      subheading: `by ${apiPromoEntity.attributes.company_name}`,
    };
  }

  throw new Error(`Unknown promoted entity type: ${basicRepresentation.type}`);
};

const convertApiPromoEntityFromBulkFetchToFrontendFormat = (apiPromoEntity) => (
  formattedPromoEntity(apiPromoEntity)
);

const convertApiPromoEntityToFrontendFormat = (apiPromoEntity, localId = null) => (
  formattedPromoEntity(apiPromoEntity, localId)
);

const fetchPromoEntitiesRepresentedBy = async (userId) => {
  if (!userId) {
    throw new Error('fetchPromoEntitiesRepresentedBy: userId is required');
  }

  const query = `/api/internal/jsonapi/users/${userId}?include=sources,products`;
  const response = await fetch(query, { method: 'GET' });
  const json = await response.json();
  if (!json.included) { return []; }
  const apiUserPromoEntities = json.included;
  const promotedEntities = apiUserPromoEntities.map(convertApiPromoEntityFromBulkFetchToFrontendFormat);
  return promotedEntities;
};

// Since we are going to be calling vapi.createSource, vapi.createProduct, etc,
// which call our internal APIs (e.g. /api/internal/sources), we need to prepare
// the data in the format expected by those APIs.
const requestDataForPromoEntityCreation = (data) => {
  if (data.type == 'Source') {
    // Data expected by /api/internal/sources
    return {
      full_name: data.name,
      title: data.jobTitle,
      company_id: data.companyId,
      company_name: data.companyName,
      is_self_source: data.selfExpert,
    };
  }

  if (data.type == 'Product') {
    // Data expected by /api/internal/products
    return {
      name: data.name,
      product_url: data.productUrl,
      company_id: data.companyId,
      company_name: data.companyName,
    };
  }
};

const createPromoEntity = async (localId, data) => {
  const requestData = requestDataForPromoEntityCreation(data);
  const response = await vapi.createPromoEntity(data.type, requestData);
  const promoEntity = convertApiPromoEntityToFrontendFormat(response.data.data, localId);

  return promoEntity;
};

const persistNewPromoEntity = async (entity) => {
  if (entity.existing) { return entity; }

  // TODO: handle possible error?
  const persistedPromoEntity = await createPromoEntity(entity.new.localId, entity.new);
  return { existing: persistedPromoEntity };
};

const deletePromoEntity = async (entityId) => {
  await vapi.deletePromoEntity(entityId);
};

export {
  fetchPromoEntitiesRepresentedBy, newPromoEntity, createPromoEntity, deletePromoEntity, persistNewPromoEntity,
};
