import helper from '../../../helpers/helper';

document.addEventListener('turbolinks:load', () => {
  const $opportunitiesSearch = $('[data-js="opportunities-search"]');

  if (
    helper.notPresent($opportunitiesSearch)
  ) {
    return;
  }

  // eslint-disable-next-line func-names
  $opportunitiesSearch.find('.js-filter-option').click(function () {
    const selectedType = $(this).data('type');
    const oldFilter = $('#opportunity_types').val();

    let newFilterArray = oldFilter.split(',');
    let newFilter = '';

    if (selectedType === '') {
      newFilter = '';
    } else {
      const index = newFilterArray.indexOf(selectedType);
      if (index === -1) {
        if (oldFilter === '') {
          newFilterArray = [selectedType];
        } else {
          newFilterArray.push(selectedType);
        }
      } else {
        newFilterArray.splice(index, 1);
      }
      newFilter = newFilterArray.join(',');
    }

    $('#opportunity_types').val(newFilter);

    $('[data-js="opportunities-search"]').submit();
  });
});
