/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DOMPurify from 'dompurify';
import helper from '../../javascript/frontend/helpers/helper';

function SnippetHtmlEntities({ attribute, hit, truncateLength }) {
  const snippetResult = _.get(hit, '_snippetResult');
  const originalText = _.get(hit, attribute, '').replace(/<[^>]*>/g, '');

  if (snippetResult) {
    const snippetAttribute = _.get(snippetResult, attribute, {}).value || '';
    const snippetText = snippetAttribute.replace(/<[^>]*>/g, '');
    const showOpeningEllipsis = snippetText.substring(0, 10) !== originalText.substring(0, 10);

    return (
      <>
        {showOpeningEllipsis && <span>...</span>}
        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(snippetText) }} />
        <span>...</span>
      </>
    );
  }

  return (
    // eslint-disable-next-line max-len
    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(helper.beautifulTruncateStr(originalText, truncateLength)) }} />
  );
}

SnippetHtmlEntities.propTypes = {
  attribute: PropTypes.string.isRequired,
  hit: PropTypes.object.isRequired,
  truncateLength: PropTypes.number,
};

SnippetHtmlEntities.defaultProps = {
  truncateLength: 150,
};

export default SnippetHtmlEntities;
