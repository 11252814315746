import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  createFlag(flag) {
    vlog.info('api#createFlag');

    api.configure();

    const url = '/api/internal/jsonapi/flags';

    const data = _.merge({ type: 'flags' }, flag);

    return axios.post(url, { data }, api.axiosFix);
  },

  getFlag(url) {
    vlog.info('api#getFlag');

    api.configure();

    return axios.get(url, api.axiosFix);
  },

  updateFlag(flag) {
    vlog.info('api#updateFlag');

    api.configure();

    const url = flag.links.self;

    const data = _.merge({ type: 'flags', id: flag.id }, flag);
    delete data.links;
    delete data.relationships;

    return axios.put(url, { data }, api.axiosFix);
  },

  deleteFlag(flag) {
    vlog.info('api#deleteFlag');

    api.configure();

    const url = flag.links.self;

    return axios.delete(url, api.axiosFix);
  },
});
