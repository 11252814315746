import React from 'react';
import { Panel } from 'react-instantsearch-dom';
import RefinementListWithoutCount from '../../refinement_list_without_count';

function RegionFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel
          header={(
            <div>
              Filter by region
            </div>
          )}
        >
          <RefinementListWithoutCount
            attribute="publication.region"
            titleCase={false}
          />
        </Panel>
      </div>
    </div>
  );
}

export default RegionFilterPanel;
