import React from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';

import ContactEmploymentForm from './_organization_form';
import ContactFields from './_contact_fields';
import ContactContactInfoFields from './_contact_info_fields';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: props.showModal,
    };
  }

  handleClose = () => {
    this.props.refreshOpportunityContactEmployment();
    this.props.onHide();
  };

  render() {
    const { showModal } = this.state;
    const {
      contact, toSearch, setContact, refreshOpportunityContactEmployment, onHide, onRemove, organization, opportunity,
    } = this.props;
    const {
      contact_employment: contactEmployment, contact_employments: contactEmployments, contact_infos: contactInfos,
    } = this.props;

    return (
      <Modal
        id="contact-form-modal"
        className="modal-lg"
        isOpen={showModal}
        toggle={this.handleClose}
      >
        <ModalHeader toggle={this.handleClose}>
          Contact&nbsp;
          {!contact
            && (
              <button type="button" onClick={toSearch} className="btn btn-outline-secondary me-2">
                <i className="fa-solid fa-magnifying-glass" />
                Search
              </button>
            )}
        </ModalHeader>
        <ModalBody>
          <form>
            <ContactFields
              contact={contact}
              setContact={setContact}
              opportunity={opportunity}
              refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
            />
            {contact
              && (
                <ContactEmploymentForm
                  contact={contact}
                  contact_employment={contactEmployment}
                  contact_employments={contactEmployments}
                  onHide={onHide}
                  organization={organization}
                  opportunity={opportunity}
                  updateContactEmployment={this.updateContactEmployment}
                  updateOrganization={this.updateOrganization}
                  refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
                />
              )}

            <hr />

            {contact
              && (
                <ContactContactInfoFields
                  contact={contact}
                  contactInfos={contactInfos}
                  refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
                />
              )}
          </form>

        </ModalBody>

        <ModalFooter>
          <Button
            color="danger"
            className="float-start"
            onClick={onRemove}
          >Remove from opportunity
          </Button>
          <Button onClick={this.handleClose}>Close</Button>
        </ModalFooter>

      </Modal>
    );
  }
}

ContactForm.propTypes = {
  onRemove: PropTypes.func,
  showModal: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  refreshOpportunityContactEmployment: PropTypes.func.isRequired,
  contact: PropTypes.instanceOf(Object),
  contact_infos: PropTypes.arrayOf(Object),
  toSearch: PropTypes.func,
  setContact: PropTypes.func,
  contact_employment: PropTypes.instanceOf(Object),
  contact_employments: PropTypes.arrayOf(Object),
  opportunity: PropTypes.instanceOf(Object),
  organization: PropTypes.instanceOf(Object),
};

ContactForm.defaultProps = {
  onRemove: undefined,
  showModal: undefined,
  contact: undefined,
  contact_infos: undefined,
  toSearch: undefined,
  setContact: undefined,
  contact_employment: undefined,
  contact_employments: undefined,
  opportunity: undefined,
  organization: undefined,
};

export default ContactForm;
