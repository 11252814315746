import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import useLocalStorageState from '../../hooks/use_local_storage_state';
import TabbedNavigation from '../../tabbed_navigation';
import ToggleSwitch from '../../toggle_switch';
import SourceRequestCard from '../card/source_request_card';
import NewMediaCard from '../card/new_media_card';
import SourceRequestSkeletonPage from './source_request_skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import SearchLaunchForm from '../../algolia_search/search_launch_form';
import UpgradeAd from './upgrade_ad';

function SourceRequestsIndex({ view, showAds, reporterUser }) {
  // One ad per page - retrieve 1 fewer to maintain divisibility by 12
  const perPage = showAds ? 23 : 24;
  const adIndexes = useRef([]);
  const adMinIndex = useRef(12);
  const adMaxIndex = useRef(22);

  const [activeTab, setActiveTab] = useState(reporterUser ? '' : view.toUpperCase());
  const [reporterQuery, setReporterQuery] = useState(reporterUser);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [refreshCount, setRefreshCount] = useState(0);
  const [smallMode, setSmallMode] = useLocalStorageState(false, 'smallModeSourceRequestCards');
  const [sourceRequests, setSourceRequests] = useState([]);
  const [asyncError, setAsyncError] = useState();

  const forceTabRefresh = () => {
    setRefreshCount((prevCount) => prevCount + 1);
  };

  const handleTabChange = (tab) => {
    if (tab === activeTab) forceTabRefresh();
    setIsLoading(true);
    setSourceRequests([]);
    setActiveTab(tab);
    setReporterQuery(null);
    setPage(1);
  };

  const handleSmallModeToggle = () => {
    setSmallMode((currentMode) => !currentMode);
  };

  // Ignore scrollContainerRef since we want to use root as the scrolling container
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });

  // Effect to track infinite scrolling intersection observer event, which increments page
  useEffect(() => {
    if (lastTriggeredAt > 0) setPage((prevPage) => prevPage + 1);
  }, [lastTriggeredAt]);

  const getRandom = (min, max) => {
    const minCeil = Math.ceil(min);
    const maxFloor = Math.floor(max);
    return Math.floor(Math.random() * (maxFloor - minCeil + 1) + minCeil);
  };

  // Effect to track the page number and trigger fetch from API
  useEffect(() => {
    const fetchRecords = async () => {
      if (activeTab === null) return;

      try {
        if (page === 1) setIsLoading(true);
        vahoy.track(`InfiniteSourceRequests#loadPage_${page}`);

        // If we're displaying ads, figure out where to put one on each page via record index
        if (showAds) {
          adIndexes.current.push(getRandom(adMinIndex.current, adMaxIndex.current));
          adMinIndex.current += perPage;
          adMaxIndex.current += perPage;
        }

        const params = {
          page,
          per_page: perPage,
          free: activeTab === 'FREE' ? 'y' : 'n',
          pitched: activeTab === 'PITCHED' ? 'y' : 'n',
          saved: activeTab === 'SAVED' ? 'y' : 'n',
          reporter_user: reporterQuery,
        };

        const response = await vapi.getSourceRequests(params);
        if (response.status === 200) {
          const sourceRequestResults = response.data;
          if (sourceRequestResults.data && sourceRequestResults.data.length > 0) {
            setSourceRequests((prevRecords) => [...prevRecords, ...sourceRequestResults.data]);
          }
          setHasMore(sourceRequestResults.data.length >= perPage);
        }
        setIsLoading(false);
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchRecords();
  }, [page, perPage, activeTab, reporterQuery, showAds, refreshCount]);

  const tabs = [
    {
 id: 'ALL', display: 'All Opportunities', href: '/source_requests?view=all', icon: 'globe',
},
    {
 id: 'MEDIAMATCHER', display: 'Media Matcher', href: '/media_matches', icon: 'users',
},
    {
 id: 'FREE', display: 'Free to Pitch', href: '/source_requests?view=free', icon: 'bolt',
},
    {
 id: 'SAVED', display: 'Saved', href: '/source_requests?view=saved', icon: 'bookmark',
},
    {
 id: 'PITCHED', display: 'Pitched', href: '/source_requests?view=pitched', icon: 'messages',
},
    {
 id: 'SEARCH', display: 'Advanced Search', href: '/source_requests/search', icon: 'magnifying-glass',
},
  ];

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  return (
    <div className="mt-3">
      <div className="d-flex flex-wrap pt-3 border-top border-bottom mb-4">
        <div className="flex-fill flex-shrink-0 flex-grow-0 mb-3 me-5">
          <TabbedNavigation
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={(tab) => handleTabChange(tab)}
          />
        </div>
        <div className="flex-fill flex-shrink-0 d-flex">
          <div className="flex-fill mb-3 ps-2 d-flex">
            <SearchLaunchForm searchUrl="/source_requests/search" />
          </div>
          <div className="mb-3 ps-2 d-flex">
            <ToggleSwitch label="Condensed View" value={smallMode} onChange={handleSmallModeToggle} />
          </div>
        </div>
      </div>
      {!isLoading && sourceRequests.length === 0 && (
        <div className="text-center p-4 fw-bold">
          {activeTab === 'PITCHED' && (<span>You haven't pitched anything yet...</span>)}
          {activeTab === 'SAVED' && (<span>You haven't saved any favorites yet...</span>)}
          {activeTab === 'FREE' && (
            <span>There aren't any free-to-pitch requests at the moment. Check back later or...</span>)}
          <br />
          <a href="/source_requests/search">
            Try searching for requests
          </a>
        </div>
      )}
      {!isLoading && sourceRequests.length > 0 && activeTab === 'PITCHED' && (
        <a
          href="/my_pitches"
          className="btn btn-outline-info mb-3"
          style={{ marginTop: '-10px' }}
        >
          View all your pitches
        </a>
      )}
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 px-1">
        {isLoading && <SourceRequestSkeletonPage numberOfCards={12} smallMode={smallMode} />}
        {!isLoading
          && sourceRequests.map((sourceRequest, index) => (
            <React.Fragment key={sourceRequest.id}>
              {(adIndexes.current.includes(index)) && (
                <div className="col px-2 pb-3 pt-0">
                  <UpgradeAd
                    adVersion={(Math.floor(index / perPage) % 3) + 1}
                    smallMode={smallMode}
                  />
                </div>
              )}
              <div className="col px-2 pb-3 pt-0">
                {sourceRequest.attributes.published_at && (
                  <SourceRequestCard sourceRequest={sourceRequest.attributes} smallMode={smallMode} />
                )}
                {sourceRequest.attributes.new_media_approved_at && (
                  <NewMediaCard newMediaUser={sourceRequest.attributes} smallMode={smallMode} />
                )}
              </div>

            </React.Fragment>
          ))}
      </div>
      {hasMore && (
        <div
          ref={scrollTriggerRef}
          className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 px-1"
        >
          <SourceRequestSkeletonPage numberOfCards={4} singleRow smallMode={smallMode} />
        </div>
      )}
    </div>
  );
}

SourceRequestsIndex.propTypes = {
  view: PropTypes.string.isRequired,
  showAds: PropTypes.bool,
  reporterUser: PropTypes.number,
};

SourceRequestsIndex.defaultProps = {
  showAds: false,
  reporterUser: undefined,
};

export default SourceRequestsIndex;
