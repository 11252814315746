import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectMenu, Panel } from 'react-instantsearch-dom';

function BeatsFilterPanel({
                            items, refine, createURL, clearMenuToggle, onMenuToggleCleared,
                          }) {
  const [originalItems, setOriginalItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('All');

  useEffect(() => {
    if (originalItems.length === 0 && items.length > 0) {
      const initialArray = [{
        count: 1,
        isRefined: false,
        label: 'All Beats',
        value: 'All',
      }];
      initialArray.push(...items);
      setOriginalItems(initialArray);
    }
  }, [items, originalItems.length]);

  useEffect(() => {
    if (clearMenuToggle) {
      onMenuToggleCleared();
      setSelectedItem('All');
    }
  }, [clearMenuToggle, onMenuToggleCleared]);

  const handleItemClick = (event, itemValue) => {
    event.preventDefault();
    if (!itemValue) return;

    setSelectedItem(itemValue);
    const itemToRefine = itemValue === 'All' ? null : itemValue;
    refine(itemToRefine);
  };

  const itemIsSelected = (item) => item.value === selectedItem;

  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by beat">
          <ul
            className="nav flex-column custom-vertical-nav"
          >
            {originalItems.map((item) => (
              <li className="nav-item" key={item.value}>
                <a
                  href={createURL(item.value)}
                  className={`nav-link rounded-0 py-1 ${itemIsSelected(item) ? 'active' : ''}`}
                  style={{ color: '#3A4570' }}
                  onClick={(event) => handleItemClick(event, item.value)}
                >
                  {item.label}
                </a>
              </li>
            ))}
            {(originalItems.length === 0) && (
              <li className="nav-item mt-3" key={0}>
                &nbsp;
              </li>
            )}
          </ul>
        </Panel>
      </div>
    </div>
  );
}

BeatsFilterPanel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    isRefined: PropTypes.bool.isRequired,
  })).isRequired,
  refine: PropTypes.func.isRequired,
  createURL: PropTypes.func.isRequired,
  clearMenuToggle: PropTypes.bool.isRequired,
  onMenuToggleCleared: PropTypes.func.isRequired,
};

export default connectMenu(BeatsFilterPanel);
