import React from 'react';
import PropTypes from 'prop-types';
import Datetimepicker from '../../datetimepicker';

function SelectSubmitDate({ submitDate, setSubmitDate, errorText }) {
  const handleDateChange = (value) => {
    const newDate = value[0];
    setSubmitDate(newDate);
  };

  return (
    <div className="mb-3">
      <label htmlFor="request-due-date" className="fw-bold">
        Add a deadline <span style={{ color: '#E71E42' }}>&#42;</span>
      </label>

      <Datetimepicker
        attr="source_request_submit_date"
        attr_humanized={null}
        include_time={true}
        min={new Date()}
        onChange={handleDateChange}
        placeholderText="When do you need it?"
        value={submitDate?.toString()}
      />

      {errorText.length > 0 && (
        <div className="form-text d-block text-danger">
          {errorText}
        </div>
      )}
    </div>
  );
}

SelectSubmitDate.propTypes = {
  submitDate: PropTypes.object, // date object
  setSubmitDate: PropTypes.func,
  errorText: PropTypes.string,
};

SelectSubmitDate.defaultProps = {
  submitDate: undefined,
  setSubmitDate: undefined,
  errorText: '',
};

export default SelectSubmitDate;
