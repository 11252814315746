import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList,
} from 'recharts';
import { connectRefinementList } from 'react-instantsearch-dom';

function MediaBarChart({ items, refine }) {
  const COLORS = ['#8884d8', '#8dd1e1', '#ffc658', '#ff8042', '#82ca9d', '#d0ed57', '#a4de6c', '#d84d4d', '#d8d888', '#d88ed1'];

  return (
    <div style={{ width: '100%', height: '25vh' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={items}
          margin={{
            top: 7, right: 0, left: 0, bottom: 5,
          }}
        >
          <XAxis dataKey="label" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count">
            {items.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.isRefined ? '#745ff1' : COLORS[index % COLORS.length]} onClick={() => refine(entry.value)} />
            ))}
            <LabelList dataKey="count" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

MediaBarChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  refine: PropTypes.func.isRequired,
};

const ConnectedMediaBarChart = connectRefinementList(MediaBarChart);

export default ConnectedMediaBarChart;
