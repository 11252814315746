import axios from 'axios';
import vlog from '../../vlog';

const handleRedirection = (response) => {
  const { location, disabledOrRestricted } = response.data;
  if (disabledOrRestricted) {
    window.location.href = location;
  }
};

// eslint-disable-next-line import/prefer-default-export
const api = {
  active: 0,

  // See: https://github.com/mzabriskie/axios/issues/545
  axiosFix: {
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  },

  getCSRFToken() {
    const metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i += 1) {
      const meta = metas[i];
      if (meta.getAttribute('name') === 'csrf-token') {
        return meta.getAttribute('content');
      }
    }

    return null;
  },

  responseErrors(response) {
    if (!_.isNil(response.response && response.response.data && response.response.data.errors)) {
      return response.response.data.errors;
    }

    if (response.message) {
      // handle cruder messages as well
      return [{ title: response.message }];
    }
  },

  configure() {
    // NB: https://github.com/mzabriskie/axios/issues/545
    // axios.defaults.headers.common['Content-Type'] = 'application/vnd.api+json';
    axios.defaults.headers.common.Accept = 'application/vnd.api+json';
    axios.defaults.headers.common['X-CSRF-Token'] = api.getCSRFToken();

    // keep track of the 'active' API requests
    axios.interceptors.request.use((config) => {
      api.active += 1;
      vlog.trace(`Api request, opening, now ${api.active} open requests`);
      return config;
    }, (error) => {
      api.active -= 1;
      vlog.trace(`Api request, error, ${api.active} open requests`);
      return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      api.active -= 1;
      vlog.trace(`Api response, closing, ${api.active} open requests`);
      handleRedirection(response);
      return response;
    }, (error) => {
      api.active -= 1;
      vlog.trace(`Api response, error, closing, ${api.active} open requests`);
      return Promise.reject(error);
    });
  },
};

export default api;
