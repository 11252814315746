import React from 'react';

const emptyResults = () => (
  <div className="card w-75 mx-auto mt-4">
    <div className="card-body">
      <div className="card-title">
        <h4 className="font-weight-bold">No sources found matching this search.</h4>
      </div>
      <hr />
      <p className="text-info">
        <em>
          Having trouble finding who you're looking for?&nbsp;
          <a href={`mailto:${window.SUPPORT_EMAIL}`} className="font-weight-bold">Get in touch</a>
        </em>
      </p>
    </div>
  </div>
);

export default emptyResults;
