import 'select2';

const select2Helpers = {
  formatContacts(contact) {
    const attrs = contact.attributes;

    if (!contact.id) {
      return contact.text;
    }
    return `${attrs.full_name}
       ${attrs.email}`;
  },

  formatReporters(reporter) {
    const attrs = reporter.attributes;

    if (!reporter.id) {
      return reporter.text;
    }
    return `${attrs.full_name}
         ${attrs.email}`;
  },

  formatOrganizations(organization) {
    const attrs = organization.attributes;

    if (!organization.id) {
      return organization.text;
    }
    if (organization.attributes) {
      return `${attrs.name}`;
    }
    return organization.text;
  },

  formatCompanies(company) {
    const attrs = company.attributes;

    if (!company.id) {
      return company.text;
    }
    if (company.attributes) {
      return `${attrs.name}`;
    }
    return company.text;
  },

  formatPublications(publication) {
    const attrs = publication.attributes;

    if (!publication.id) {
      return publication.text;
    }
    if (publication.attributes) {
      return `${attrs.name}`;
    }
    return publication.text;
  },

  formatSources(source) {
    const attrs = source.attributes;

    if (!source.id) {
      return source.text;
    }
    return `${attrs.full_name}
         ${attrs.email}`;
  },

  formatUsers(user) {
    const attrs = user.attributes;

    if (!user.id) {
      return user.text;
    }
    return `${attrs.full_name} (${attrs.email})`;
  },

  setupSelect2($field, url, formatter) {
    $field.select2({
      allowClear: true,
      ajax: {
        url,
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            filter: {
              name: params.term,
            },
          };
        },
        // eslint-disable-next-line no-unused-vars
        processResults(response, params) {
          return {
            results: response.data,
          };
        },
      },
      dropdownParent: $field.parent(),
      escapeMarkup(markup) {
        return markup;
      },
      minimumInputLength: 3,
      placeholder: '',
      templateResult: formatter,
      templateSelection: formatter,
      width: '100%',
    });
  },
};

export default select2Helpers;
