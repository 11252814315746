import React, { useState, useContext, useEffect } from 'react';
import vapi from '../../../../javascript/frontend/api/vapi';
import { CompanyContext } from '../../data/company_context_management';
import ConfirmModal from '../../../confirm_modal';
import LoadingImage from '../../../loading_image';
import AddSectionButton from './add_sections_button';
import OrganizationLogo from '../../../organization_logo';

function CompanyHeader() {
  const { company, setOpenEditModal, setDoTopLevelFetch } = useContext(CompanyContext);
  const [savingBannerImage, setSavingBannerImage] = useState(false);
  const [removingBannerImage, setRemovingBannerImage] = useState(false);
  const [showDeleteBannerModal, setDeleteBannerModal] = useState(false);
  const [bannerHeight, setBannerHeight] = useState('130px');

  const bannerContainerStyle = {
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '7px',
    height: bannerHeight,
    minHeight: '100px',
    overflow: 'hidden',
    border: 'none',
  };

  useEffect(() => {
    if (company && company.banner_image_cloudinary) {
      jQuery(window).resize(() => {
        if (jQuery('.banner-image-container img').length > 0) {
          const bannerMinWidth = 893;
          const bannerMinHeight = 300;
          const bannerWidth = jQuery('.banner-image-container').outerWidth();
          const resizedBannerHeight = (bannerMinHeight / bannerMinWidth) * bannerWidth;
          setBannerHeight(resizedBannerHeight);
        }
      });
    }
  });

  useEffect(() => {
    const setBannerSize = () => {
      if (company && company.banner_image_cloudinary) {
        const bannerMinWidth = 893;
        const bannerMinHeight = 300;
        const bannerWidth = jQuery('.banner-image-container').outerWidth();
        const resizedBannerHeight = (bannerMinHeight / bannerMinWidth) * bannerWidth;
        setBannerHeight(resizedBannerHeight);
      } else {
        setBannerHeight('130px');
      }
    };
    setBannerSize();
  }, [company]);

  const showEditModal = () => {
    setOpenEditModal('HEADER');
  };

  const renderVisibilityBadge = () => {
    if (!company.editable) return null;

    let colorClass;
    let badgeText;

    if (company.visibility === 'hidden') {
      colorClass = 'danger';
      badgeText = 'Hidden';
    }
    if (company.visibility === 'reporters') {
      colorClass = 'info';
      badgeText = 'Reporters Only';
    }
    if (company.visibility === 'public') {
      colorClass = 'success';
      badgeText = 'Public';
    }

    if (colorClass && badgeText) {
      return (
        <div className={`badge bg-${colorClass} ms-auto me-2`}>
          Visibility: {badgeText}
        </div>
      );
    }
  };

  const onBannerChange = (evt) => {
    const { files = [] } = evt.target;

    if (files.length > 0) {
      const file = files[0];
      const companyId = company.objectID;
      const attributes = {
        banner_image_cloudinary: file,
      };

      setSavingBannerImage(true);

      vapi
        .updateCompanyUsingFormData(companyId, attributes)
        .then(() => {
          setSavingBannerImage(false);
          setDoTopLevelFetch(true);
        })
        .catch(() => {
          setSavingBannerImage(false);
          alert('Something went wrong, please try again later');
        });
    }
  };

  const removeBannerImage = () => {
    const companyId = company.objectID;
    const attributes = {
      remove_banner_image_cloudinary: true,
    };

    setRemovingBannerImage(true);

    vapi
      .updateCompanyUsingFormData(companyId, attributes)
      .then(() => {
        setRemovingBannerImage(false);
        setDoTopLevelFetch(true);
      })
      .catch(() => {
        setRemovingBannerImage(false);
        alert('Something went wrong, please try again later');
      });
  };

  const renderDeleteBannerModal = () => (
    <ConfirmModal
      confirmYesText="Yes"
      confirmNoText="No"
      confirmContent="Are you sure you want to remove this banner image?"
      confirmYes={removeBannerImage}
      confirmNo={() => setDeleteBannerModal(false)}
      isOpening
      className="modal-sm p-3 confirm-remove-banner-image-modal"
      confirmNoButtonClassName="shadow-none"
      isSubmitting={removingBannerImage}
    />
  );

  const renderDeleteBannerButton = () => {
    if (!company.editable || !company.banner_image_cloudinary) return null;

    if (removingBannerImage) {
      return (
        <button
          className="btn bg-white text-primary btn-remove-banner-image me-2"
          type="button"
          style={{ opacity: '0.5' }}
        >
          <LoadingImage className="" height="24" />
        </button>
      );
    }
    return (
      <button
        className="btn bg-white text-primary btn-remove-banner-image me-2"
        type="button"
        onClick={() => setDeleteBannerModal(true)}
        style={{ opacity: '0.5' }}
      >
        <i className="fa fa-trash-can" />
      </button>
    );
  };

  const renderEditBannerButton = () => {
    if (!company.editable) return null;

    if (savingBannerImage) {
      return <LoadingImage className="me-2" height="24" />;
    }

    return (
      <div className="d-inline-block border-0 p-0 bg-transparent me-2">
        <label
          className="m-0 cursor-pointer btn bg-white text-primary form-label"
          style={{ opacity: 0.7 }}
        >
          <input
            type="file"
            accept=".png, .jpg, .jpeg, .svg"
            className=""
            onChange={onBannerChange}
            style={{ display: 'none' }}
            id="banner_image_input"
          />
          <span className="d-none d-sm-inline">
            {company.banner_image_cloudinary
              ? 'Change Cover Photo'
              : 'Upload Cover Photo'}{' '}
            (1200x400px)
          </span>
          <span className="d-inline d-sm-none">
            <i className="fa-solid fa-pencil" />
          </span>
        </label>
      </div>
    );
  };

  return (
    <>
      {showDeleteBannerModal && renderDeleteBannerModal()}
      <div
        className="card animated faster fadeIn"
        style={{
          position: 'relative',
          zIndex: 500,
        }}
      >
        <div className="container-fluid" style={{ padding: '0' }}>
          <div
            className="banner-image-container bg-light position-relative"
            style={bannerContainerStyle}
          >
            {company.banner_image_cloudinary && (
              <img
                alt=""
                src={company.banner_image_cloudinary}
                style={{
                  minWidth: '100%',
                  minHeight: '100%',
                  width: '100%',
                }}
              />
            )}
            <div
              className="position-absolute"
              style={{
                bottom: '10px',
                right: '0',
              }}
            >
              {renderDeleteBannerButton()}
              {renderEditBannerButton()}
            </div>
          </div>

          <div className="row px-3 ps-3">
            <div className="col-auto position-relative d-flex justify-content-center px-4 company-logo-container">
              <OrganizationLogo
                editable={company.editable}
                orgID={company.objectID}
                orgLogoURL={company.logo_url}
                orgName={company.name}
                orgType="company"
              />
            </div>

            <div className="col ms-2 pe-3 ps-3 pt-3">
              <h1 className="fw-bold p-0 company-header-center-text mt-2 text-center text-sm-start">
                {company.name}
              </h1>

              {company.editable && (
                <div className="pt-2 company-header-btns-container">
                  <AddSectionButton />
                  <button
                    type="button"
                    className="btn btn-primary btn-sm mb-2"
                    style={{
                      width: 'auto',
                      maxHeight: '2rem',
                    }}
                    onClick={showEditModal}
                  >
                    <i className="fa-solid fa-pencil cursor-pointer me-2" />
                    Edit
                  </button>
                </div>
              )}

              <div className="row ms-0 mt-3 pe-3 company-header-center-text">
                {company.overview}
              </div>

              <div className="align-items-center justify-content-sm-start pb-3 ms-0 company-header-btns-container">
                {company.primary_website_url && (
                  <a
                    href={company.primary_website_url}
                    role="button"
                    className="btn btn-outline-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Website
                  </a>
                )}

                {company.primary_twitter_url && (
                  <a
                    href={company.primary_twitter_url}
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-x-twitter me-1 ms-3 btn btn-primary btn-small p-1 rounded-circle" />
                  </a>
                )}

                {company.primary_linkedin_url && (
                  <a
                    href={company.primary_linkedin_url}
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-linkedin me-1 ms-1 btn btn-primary btn-small p-1 rounded-circle" />
                  </a>
                )}

                {company.primary_facebook_url && (
                  <a
                    href={company.primary_facebook_url}
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-facebook me-1 ms-1 btn btn-primary btn-small p-1 rounded-circle" />
                  </a>
                )}
              </div>
            </div>
            <div className="col-auto p-2">
              {renderVisibilityBadge()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyHeader;
