import React from 'react';
import {
  FormGroup, Button, Label, Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';
import 'select2';
import vahoy from '../../../javascript/vahoy';

class ContactEmploymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      localContact: props.contact,
      localContactEmployment: props.contact_employment, // ContactEmployment that matches both Contact & Organization
      localContactEmployments: props.contact_employments, // all ContactEmployments that match the Contact
      localOrganization: props.organization, // may be undefined
      editing: false,
    };
  }

  componentDidMount() {
    const { editing, localContactEmployment } = this.state;
    const { contact, organization } = this.props;
    if (editing) {
      this.setupSelect2();
    }

    if (contact) {
      vapi.getContactEmployments(contact.id)
        .then((result) => {
          const contactEmployments = result.data.data || [];
          let possibleOrganizations = contactEmployments;
          if (organization) {
            possibleOrganizations = contactEmployments.concat([organization]);
          }
          possibleOrganizations = _.uniqBy(possibleOrganizations, (org) => org.id);
          this.setState({ possibleOrganizations });

          if (localContactEmployment) {
            this.setContactEmployment(localContactEmployment.attributes.organization_id);
          }
        });
    }
  }

  componentDidUpdate() {
    const { editing, localContactEmployment } = this.state;

    if (editing) {
      if (!$('#searchOrganizationName').data('select2')) {
        this.setupSelect2();
      }
      if (!$('#searchOrganizationName').val()) {
        if (localContactEmployment) {
          this.setContactEmployment(localContactEmployment.attributes.organization_id);
        }
      }
    }
  }

  onSelect = (id, data) => {
    this.setContactEmployment(id, data);
  };

  getStaticFields = () => {
    const {
      localOrganization: organization,
      localContactEmployment: contactEmployment,
    } = this.state;
    const contactEmploymentAttrs = (contactEmployment || {}).attributes;
    const editButton = (
      <button
        type="button"
        className="btn btn-sm btn-link mb-1"
        onClick={this.startEditing}
      >
        <i className="fa-solid fa-pencil" />
      </button>
    );

    return (
      <div>
        {contactEmploymentAttrs && contactEmploymentAttrs.title
          && <span>{`${contactEmploymentAttrs.title} at `}</span>}
        {organization && contactEmployment
          && <a href={organization.links.self}>{organization.attributes.name}</a>}
        {(!organization || !contactEmployment)
          && <span className="text-muted">No organization set</span>}
        {editButton}
      </div>
    );
  };

  getEditingFields = () => {
    const {
      localOrganization: organization,
      localContactEmployment: contactEmployment,
    } = this.state;
    const { contact_employment: propsContactEmployment } = this.props;
    const contactEmploymentAttrs = (contactEmployment || {}).attributes;

    return (
      <div>
        <FormGroup>
          <Label for="searchOrganizationName">Set the contact's organization</Label>
          <Input id="searchOrganizationName" type="select" name="search_organization_name" />
        </FormGroup>

        {this.renderPossibleOrganizations()}

        {organization && contactEmployment
          && (
            <FormGroup>
              <Label for="contactFormOrganizationTitle">Title
                (at {organization.attributes.name})
              </Label>
              <Input
                id="contactFormOrganizationTitle"
                type="text"
                name="title"
                value={contactEmploymentAttrs.title}
                placeholder="My title"
                onChange={this.handleContactEmploymentAttrsInputChange}
              />
            </FormGroup>
          )}

        <div className="mt-1 clearfix">
          {contactEmployment
            && (
              <Button
                className="float-end"
                color="success"
                type="button"
                onClick={this.handleSave}
              >Save
              </Button>
            )}
          {propsContactEmployment && !contactEmployment
            && (
              <Button
                className="float-end"
                color="danger"
                type="button"
                onClick={this.handleClear}
              >Clear
              </Button>
            )}
          <Button
            className="float-end me-1"
            type="button"
            onClick={this.handleCancel}
          >Cancel
          </Button>
        </div>
      </div>
    );
  };

  setupSelect2 = () => {
    const that = this;
    const $organizationSearchModal = $('#contact-form-modal');
    const $organizationSelect = $('#searchOrganizationName').select2({
      ajax: {
        url: '/api/internal/jsonapi/organizations',
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            filter: {
              name: params.term,
            },
          };
        },
        processResults(response) {
          return {
            results: response.data,
          };
        },
      },
      allowClear: true,
      dropdownParent: $organizationSearchModal.parent(),
      escapeMarkup(markup) {
        return markup;
      },
      minimumInputLength: 1,
      templateResult: this.formatOrganizations,
      language: {
        inputTooShort() {
          return '';
        },
        noResults: this.formatNoResults,
      },
      templateSelection: this.formatOrganizations,
      placeholder: true,
    });

    $organizationSearchModal.on('click', '#new-organization-btn', () => {
      if (window.confirm('Abandon new contact creation, and jump to new organization creation?')) {
        that.props.onHide();
      }
    });

    $organizationSelect.on('select2:select select2:unselect', (e) => {
      if (e.type === 'select2:unselect') {
        that.setState({
          localContactEmployment: null,
          localOrganization: null,
        });
      } else {
        // It's kind of complicated to describe what this does,
        // but imagine if you search for an Organization, and choose
        // one for which there's already a ContactEmployment.
        // We already have all this Contact's ContactEmployments
        // from the API.  So, either we sync up and use one of those,
        // or, we will need to create a new one.  In the case
        // where we use an already existing one, the title will be filled in.

        const o = $organizationSelect.select2('data')[0];
        const localOrganization = {
          attributes: o.attributes,
          id: o.id,
          links: o.links,
          relationships: o.relationships,
          type: o.type,
        };

        let localContactEmployment;
        if (localOrganization) {
          localContactEmployment = _(that.state.localContactEmployments)
            .filter((ce) => ce.attributes.organization_id === localOrganization.id).first();
        }
        if (!localContactEmployment) {
          localContactEmployment = {
            id: null,
            type: 'contact_employments',
            attributes: {
              contact_id: that.state.localContact ? that.state.localContact.id : null,
              organization_id: localOrganization.id,
            },
          };
        }

        that.setState({
          localContactEmployment,
          localOrganization,
        });
      }
    });
  };

  setContactEmployment = (organizationId) => {
    if (!organizationId) {
      return;
    }

    const {
      localContact,
      localOrganization,
      localContactEmployments,
      possibleOrganizations,
    } = this.state;

    let newLocalOrganization;

    // setting the contact organization to the opportunity organization
    if (localOrganization && localOrganization.id === organizationId) {
      newLocalOrganization = localOrganization;
    }

    // else hunting through the contact's multiple organizations
    if (!newLocalOrganization) {
      newLocalOrganization = _(possibleOrganizations)
        .filter((o) => o.id === organizationId).first();
    }

    let newLocalContactEmployment;
    newLocalContactEmployment = _(localContactEmployments)
      .filter((ce) => ce.attributes.organization_id === organizationId).first();

    if (!newLocalContactEmployment) {
      newLocalContactEmployment = {
        id: null,
        type: 'contact_employments',
        attributes: {
          contact_id: localContact ? localContact.id : null,
          organization_id: newLocalOrganization.id,
        },
      };
    }

    this.setState({
      localOrganization: newLocalOrganization,
      localContactEmployment: newLocalContactEmployment,
    });
    this.setSelect2Organization(newLocalOrganization);
  };

  setSelect2Organization = (localOrganization) => {
    const option = new Option(localOrganization.attributes.name, localOrganization.id);
    $('#searchOrganizationName option').remove();
    $('#searchOrganizationName').append(option).trigger('change');
  };

  setOpportunityOrganization = (orgId) => {
    const { opportunity, refreshOpportunityContactEmployment } = this.props;

    vapi.setOpportunityOrganization(opportunity.id, orgId)
      .then(() => {
        refreshOpportunityContactEmployment();
      });

    vahoy.track('Opportunity/Contact/OrganizationForm#setOpportunityOrganization');
  };

  startEditing = () => {
    const { localContactEmployment } = this.state;
    let persistedLCAs;
    if (localContactEmployment) {
      persistedLCAs = _.cloneDeep(localContactEmployment.attributes);
    } else {
      persistedLCAs = null;
    }
    this.setState({
      persistedLocalContactEmploymentAttrs: persistedLCAs,
      editing: true,
    });

    vahoy.track('Opportunity/Contact/OrganizationForm#startEditing');
  };

  stopEditing = () => {
    this.setState({ editing: false });
  };

  handleClear = () => {
    const { contact_employment: contactEmployment } = this.props;
    if (contactEmployment && contactEmployment.id) {
      this.deleteContactEmployment(contactEmployment);
    }
  };

  handleSave = () => {
    const { localContactEmployment } = this.state;
    if (localContactEmployment && localContactEmployment.id) {
      this.updateContactEmployment(localContactEmployment);
    } else {
      this.createContactEmployment(localContactEmployment);
    }
  };

  createContactEmployment = (contactEmployment) => {
    const { contact } = this.props;

    vapi.createContactEmployment(contactEmployment, contact.id)
      .then((response) => {
        this.stopEditing();
        this.setOpportunityOrganization(response.data.data.attributes.organization_id);
      });

    vahoy.track('Opportunity/Contact/OrganizationForm#createContactEmployment');
  };

  deleteContactEmployment = (contactEmployment) => {
    const { refreshOpportunityContactEmployment } = this.props;

    vapi.deleteContactEmployment(contactEmployment)
      .then(() => {
        this.stopEditing();
        refreshOpportunityContactEmployment();
      });

    vahoy.track('Opportunity/Contact/OrganizationForm#deleteContactEmployment');
  };

  updateContactEmployment = (contactEmployment) => {
    const { refreshOpportunityContactEmployment } = this.props;

    vapi.updateContactEmployment(contactEmployment)
      .then((response) => {
        this.stopEditing();
        this.setOpportunityOrganization(response.data.data.attributes.organization_id);
        refreshOpportunityContactEmployment();
      });

    vahoy.track('Opportunity/Contact/OrganizationForm#updateContactEmployment');
  };

  formatNoResults = () => {
    const newOrgButton = '<button id="new-organization-btn" type="button" class="btn btn-outline-secondary">New organization</button>';
    return `... nothing found: ${newOrgButton}`;
  };

  formatOrganizations = (organization) => {
    const attrs = organization.attributes;

    if (organization.text) { // adjust for custom placeholder values
      return organization.text;
    }
    if (attrs) {
      const row = `${attrs.name}`;
      return row;
    }
    return 'The contact\'s organization';
  };

  handleContactEmploymentAttrsInputChange = (e) => {
    const { localContactEmployment } = this.state;
    _.extend(localContactEmployment.attributes, { [e.target.name]: e.target.value });

    this.setState({ localContactEmployment });
  };

  handleCancel = () => {
    const {
      persistedLocalContactEmploymentAttrs,
      localContactEmployment,
    } = this.state;
    if (localContactEmployment) {
      _.extend(localContactEmployment.attributes, (persistedLocalContactEmploymentAttrs || {}));
    }
    this.setState({
      editing: false,
      localContactEmployment,
    });
  };

  renderPossibleOrganizations = () => {
    const { localContactEmployment, possibleOrganizations } = this.state;

    return (
      <div>
        {possibleOrganizations && !localContactEmployment
          && possibleOrganizations.length > 0
          && (
            <div className="clearfix pb-2">
              <div>
                <label>
                  Likely organizations
                </label>
              </div>
              {possibleOrganizations.map((item, index) => (
                <button
                  type="button"
                  style={{ cursor: 'pointer' }}
                  className="btn btn-link btn-outline-secondary btn-sm me-1 mb-1"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    this.onSelect(item.id, item.attributes);
                  }}
                >
                  {item.attributes.name}
                </button>
              ))}
              <div className="clearfix" />
            </div>
          )}
      </div>
    );
  };

  render() {
    let fields;
    const { editing } = this.state;

    if (editing) {
      fields = this.getEditingFields();
    } else {
      fields = this.getStaticFields();
    }

    return (
      <div>
        {fields}
      </div>
    );
  }
}

ContactEmploymentForm.propTypes = {
  contact: PropTypes.instanceOf(Object),
  organization: PropTypes.instanceOf(Object),
  opportunity: PropTypes.instanceOf(Object),
  contact_employment: PropTypes.instanceOf(Object),
  contact_employments: PropTypes.arrayOf(Object),
  refreshOpportunityContactEmployment: PropTypes.func,

  // eslint-disable-next-line react/no-unused-prop-types
  onHide: PropTypes.func.isRequired,
};

ContactEmploymentForm.defaultProps = {
  contact: undefined,
  organization: undefined,
  opportunity: undefined,
  contact_employment: undefined,
  contact_employments: undefined,
  refreshOpportunityContactEmployment: undefined,
};

export default ContactEmploymentForm;
