import React, { useContext } from 'react';
import vapi from '../../../../javascript/frontend/api/vapi';
import { CompanyContext } from '../../data/company_context_management';

function ButtonDelete() {
  const { company } = useContext(CompanyContext);

  const handleClick = () => {
    vapi.deleteCompany(company)
      .then((response) => {
        window.location.assign(response.data.redirect_path);
      });
  };

  return (
    <button
      type="button"
      className="btn btn-confirm btn-outline-danger"
      onClick={handleClick}
    >
      <i className="fa-solid fa-trash-can" />
    </button>
  );
}

export default ButtonDelete;
