import React, { useContext } from 'react';
import { CompanyContext } from '../../data/company_context_management';

function ButtonMerge() {
  const { company } = useContext(CompanyContext);

  const handleClick = () => {
    window.location.assign(company.company_merger_path);
  };

  return (
    <button type="button" className="btn btn-outline-secondary me-1" onClick={handleClick}>
      Merge
    </button>
  );
}

export default ButtonMerge;
