/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { ModalBody, ModalFooter } from 'reactstrap';
import SaveSpinnerButton from '../../save_spinner_button';
import useCompanyUpdateApi from '../data/use_company_update_api';
import { CompanyContext } from '../data/company_context_management';

function MainContentForm() {
  // eslint-disable-next-line no-unused-vars
  const [asyncError, setAsyncError] = useState();
  const { register, handleSubmit } = useForm();
  const context = useContext(CompanyContext);
  const {
    company, isUpdating, setOpenEditModal, setGeneralError,
  } = context;
  const { updateCompany } = useCompanyUpdateApi({ ...context });

  const closeModal = () => {
    setOpenEditModal(null);
    setGeneralError(null);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    updateCompany(data);
  };

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  return (
    <>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)} className="pt-3">
          <div className="mb-3 row">
            <label htmlFor="about" className="col-sm-3 col-form-label">
              About Us
            </label>
            <div className="col-sm-9">
              <textarea
                id="about"
                className="form-control"
                {...register('about')}
                disabled={isUpdating}
                defaultValue={company.about}
                rows={5}
                placeholder="We lead the world in providing widgets."
              />
            </div>
          </div>
          {company.display_as_pr_firm && (
            <div className="mb-3 row">
              <div className="col-sm-3">Optional Tabs</div>
              <div className="form-check form-switch col-sm-9 ps-5">
                <input
                  type="checkbox"
                  className="form-check-input"
                  disabled={isUpdating}
                  {...register('show_pr_users')}
                  id="show_pr_users"
                  defaultChecked={company.show_pr_users}
                />
                <label
                  className="form-check-label"
                  htmlFor="show_pr_users"
                >
                  Show the "Our PR Team" tab
                </label>
              </div>
            </div>
          )}
          {company.display_as_pr_firm && (
            <div className="mb-3 row">
              <div className="col-sm-3">&nbsp;</div>
              <div className="form-check form-switch col-sm-9 ps-5">
                <input
                  type="checkbox"
                  className="form-check-input"
                  disabled={isUpdating}
                  {...register('show_brands')}
                  id="show_brands"
                  defaultChecked={company.show_brands}
                />
                <label
                  className="form-check-label"
                  htmlFor="show_brands"
                >
                  Show the "Our Brands" tab
                </label>
              </div>
            </div>
          )}
        </form>
      </ModalBody>

      <ModalFooter>
        <SaveSpinnerButton
          inactiveText="Save Changes"
          activeText="Saving..."
          isSaving={isUpdating}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />

        <button
          type="button"
          className="btn text-danger"
          onClick={closeModal}
          disabled={isUpdating}
        >
          Cancel
        </button>
      </ModalFooter>
    </>
  );
}

export default MainContentForm;
