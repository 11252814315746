import React from 'react';
import { Panel, RefinementList } from 'react-instantsearch-dom';

function LastVisitFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body ais-hide-counts">
        <Panel header="Filter by source last visit">
          <RefinementList attribute="last_source_visit_time_slug" limit={3} operator="or" />
        </Panel>
      </div>
    </div>
  );
}

export default LastVisitFilterPanel;
