import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function PitchLibrary({
                        attachedEntities,
                        setPitchText,
                        pitchTextEdited,
                        discardEdits,
                      }) {
  const [pitches, setPitches] = useState([]);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    let isMounted = true; // Flag to track mounting status

    const entityIds = attachedEntities
      .map((entity) => entity.existing && entity.existing.id)
      .filter((id) => id != null);

    if (entityIds.length === 0) {
      setPitches([]);
    } else if (entityIds.length > 1) {
      throw new Error('Pitch library only works with a single attached entity!');
    } else {
      const promoEntityIdsString = entityIds[0];

      const promoEntityType = attachedEntities[0].existing.type;
      const promoEntityPitchForeignKey = `${promoEntityType.toLowerCase()}_id`;
      const query = `/api/internal/jsonapi/pitches?page[offset]=0&page[limit]=10&filter[${promoEntityPitchForeignKey}]=${promoEntityIdsString}`;

      fetch(query, {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (isMounted && responseJson.data) {
            setPitches(responseJson.data);
          }
        });
    }

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [attachedEntities]);

  const handleClick = (pitchId) => {
    if (pitchTextEdited) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Discard your edits?')) {
        discardEdits();
      } else {
        return;
      }
    }
    const foundPitch = pitches.find((pitch) => pitch.id === pitchId);
    if (foundPitch) {
      setPitchText(foundPitch.attributes.pitch);
    }
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      {pitches.length > 0 && (
        <div className="mb-3">
          <p className="mb-2">{attachedEntities[0]?.existing?.name}'s previous pitches</p>
          {collapsed && (
            <button type="button" className="btn btn-light w-100" onClick={toggleCollapsed}>
              Browse previous pitches
            </button>
          )}
          {!collapsed && (
            <div className="card d-flex flex-column overflow-auto" style={{ maxHeight: '250px' }}>
              <div className="card-body flex-shrink-1 d-flex flex-column overflow-auto p-0">
                {/* TODO: search pitches */}
                <table className="table table-sm" style={{ tableLayout: 'fixed' }}>
                  <tbody>
                  {pitches.length === 0 && (
                    <tr>
                      <td>
                        <em>No previous pitches.</em>
                      </td>
                    </tr>
                  )}
                  {pitches.map((pitch) => (
                    <tr key={pitch.id}>
                      <td>
                        <button
                          className="btn btn-link text-truncate font-size-12px"
                          style={{ maxWidth: '100%' }}
                          type="button"
                          onClick={() => handleClick(pitch.id)}
                        >
                          {pitch.attributes.pitch}
                        </button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
              <div className="card-footer d-flex flex-row justify-content-between">
                <a
                  href="/my_pitches"
                  className="btn btn-link btn-sm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View all your pitches <i className="fa-solid fa-up-right-from-square" />
                </a>
                <button type="button" className="btn btn-light btn-sm" onClick={toggleCollapsed}>Close</button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

PitchLibrary.propTypes = {
  attachedEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
  setPitchText: PropTypes.func.isRequired,
  pitchTextEdited: PropTypes.bool.isRequired,
  discardEdits: PropTypes.func.isRequired,
};

export default PitchLibrary;
