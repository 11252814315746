import React from 'react';
import PropTypes from 'prop-types';

class ContactButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let button;
    const { contact, onAdd, onEdit } = this.props;

    if (_.isEmpty(contact)) {
      button = (
        <div>
          <div className="row">
            <div className="col-12">
              <div className="alert alert-staff mb-3" role="alert">No contact specified</div>
            </div>
          </div>
          <button
            type="button"
            tabIndex="-1"
            id="opportunity-add-contact"
            className="btn btn-staff btn-sm"
            onClick={onAdd}
            onKeyUp={(evt) => evt.keyCode == 13 && onAdd(evt)}
          >
            <i className="fa-solid fa-plus" />
            &nbsp;Add contact
          </button>
        </div>
      );
    } else {
      button = (
        <button
          type="button"
          tabIndex="-1"
          id="opportunity-edit-contact"
          className="btn btn-staff btn-sm"
          onClick={onEdit}
          onKeyUp={(evt) => evt.keyCode == 13 && onEdit(evt)}
        >
          <i className="fa-solid fa-pen-to-square" />
          &nbsp;Edit contact
        </button>
      );
    }

    return (
      <div>
        {button}
      </div>
    );
  }
}

ContactButton.propTypes = {
  contact: PropTypes.instanceOf(Object),

  // onAdd is required if contact is blank, on Edit is required if contact is present
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
};

ContactButton.defaultProps = {
  contact: undefined,
  onAdd: undefined,
  onEdit: undefined,
};

export default ContactButton;
