/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

class PlanChangeSuccessModal extends React.Component {
  onCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  render() {
    const {
      showPopup,
      oldSourcePlan,
      newSourcePlan,
    } = this.props;

    let successContent;

    if (newSourcePlan > oldSourcePlan) {
      successContent = (
        <div className="mt-3 ms-3">
          <h1 className="mb-3"><strong>Done</strong></h1>
          <p>
            We have successfully processed your payment and upgraded your account.
          </p>
          <p className="mb-4">
            You can start using the features of your upgraded account immediately.
          </p>
        </div>
      );
    } else {
      successContent = (
        <div className="mt-3 ms-3">
          <h1 className="mb-3"><strong>Done</strong></h1>
          <p>
            We've successfully processed your account change.
          </p>
          <p className="mb-4">
            {newSourcePlan === 0
              ? 'There will be no further charges made to your credit card.'
              : "Your next automated payment will be for your new plan's price."}
            &nbsp;You'll have access to your existing plan's features until the end of the billing cycle.
          </p>
        </div>
      );
    }

    return (
      <Modal isOpen={showPopup} toggle={this.onCancel}>
        <ModalBody>
          <div className="container-fluid">
            <div className="row">
              <div className="mb-4 col-lg-12">
                <button
                  type="button"
                  id="test-close-success-modal"
                  className="btn-close float-end"
                  style={{ position: 'relative', right: '-10px' }}
                  onClick={this.onCancel}
                />
                <div className="text-center pt-5">
                  <i
                    className={`fa-regular fa-circle-check text-success ${showPopup ? 'animated bounceInDown' : ''}`}
                    style={{ fontSize: '7rem' }}
                  />
                </div>
                {successContent}
              </div>
            </div>
            <div className="d-grid">
              <button
                type="button"
                onClick={this.onCancel}
                className={`btn btn-success btn-lg mb-3 ${showPopup ? 'animated fadeIn delay-1s' : ''}`}
              >
                Continue
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

PlanChangeSuccessModal.propTypes = {
  showPopup: PropTypes.bool.isRequired, // Whether or not to show the modal popup
  oldSourcePlan: PropTypes.number.isRequired,
  newSourcePlan: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PlanChangeSuccessModal;
