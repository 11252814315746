// Rails uses a different set of timezones, which is compressed somewhat (does not include all the timezones in the
// standardized timezone databases) although, the Rails timezones are functionally equivalent.

// Because of https://github.com/rails/rails/issues/12461#issuecomment-25891483
// ... we are forced to resort to this ridiculousness:
// https://gist.github.com/jpmckinney/767070

// alphabetized
const activeSupportTimeZoneCompleteMapping = {
  'Africa/Abidjan': 'London',
  'Africa/Accra': 'London',
  'Africa/Addis_Ababa': 'Moscow',
  'Africa/Algiers': 'Paris',
  'Africa/Asmara': 'Moscow',
  'Africa/Bamako': 'London',
  'Africa/Bangui': 'Paris',
  'Africa/Banjul': 'London',
  'Africa/Bissau': 'London',
  'Africa/Blantyre': 'Cairo',
  'Africa/Brazzaville': 'Paris',
  'Africa/Bujumbura': 'Cairo',
  'Africa/Cairo': 'Cairo',
  'Africa/Casablanca': 'Casablanca',
  'Africa/Ceuta': 'Paris',
  'Africa/Conakry': 'London',
  'Africa/Dakar': 'London',
  'Africa/Dar_es_Salaam': 'Moscow',
  'Africa/Djibouti': 'Moscow',
  'Africa/Douala': 'Paris',
  'Africa/El_Aaiun': 'London',
  'Africa/Freetown': 'London',
  'Africa/Gaborone': 'Cairo',
  'Africa/Harare': 'Harare',
  'Africa/Johannesburg': 'Pretoria',
  'Africa/Kampala': 'Moscow',
  'Africa/Khartoum': 'Moscow',
  'Africa/Kigali': 'Cairo',
  'Africa/Kinshasa': 'Paris',
  'Africa/Lagos': 'Paris',
  'Africa/Libreville': 'Paris',
  'Africa/Lome': 'London',
  'Africa/Luanda': 'Paris',
  'Africa/Lubumbashi': 'Cairo',
  'Africa/Lusaka': 'Cairo',
  'Africa/Malabo': 'Paris',
  'Africa/Maputo': 'Cairo',
  'Africa/Maseru': 'Cairo',
  'Africa/Mbabane': 'Cairo',
  'Africa/Mogadishu': 'Moscow',
  'Africa/Monrovia': 'Monrovia',
  'Africa/Nairobi': 'Nairobi',
  'Africa/Ndjamena': 'Paris',
  'Africa/Niamey': 'Paris',
  'Africa/Nouakchott': 'London',
  'Africa/Ouagadougou': 'London',
  'Africa/Porto-Novo': 'Paris',
  'Africa/Sao_Tome': 'London',
  'Africa/Tripoli': 'Cairo',
  'Africa/Tunis': 'Paris',
  'Africa/Windhoek': 'Paris',
  'America/Adak': 'Hawaii',
  'America/Anchorage': 'Alaska',
  'America/Anguilla': 'Atlantic Time (Canada)',
  'America/Antigua': 'Atlantic Time (Canada)',
  'America/Araguaina': 'Buenos Aires',
  'America/Argentina/Buenos_Aires': 'Buenos Aires',
  'America/Argentina/Catamarca': 'Buenos Aires',
  'America/Argentina/Cordoba': 'Buenos Aires',
  'America/Argentina/Jujuy': 'Buenos Aires',
  'America/Argentina/La_Rioja': 'Buenos Aires',
  'America/Argentina/Mendoza': 'Buenos Aires',
  'America/Argentina/Rio_Gallegos': 'Buenos Aires',
  'America/Argentina/Salta': 'Buenos Aires',
  'America/Argentina/San_Juan': 'Buenos Aires',
  'America/Argentina/San_Luis': 'Atlantic Time (Canada)',
  'America/Argentina/Tucuman': 'Buenos Aires',
  'America/Argentina/Ushuaia': 'Buenos Aires',
  'America/Aruba': 'Atlantic Time (Canada)',
  'America/Asuncion': 'Atlantic Time (Canada)',
  'America/Atikokan': 'Eastern Time (US & Canada)',
  'America/Bahia': 'Buenos Aires',
  'America/Barbados': 'Atlantic Time (Canada)',
  'America/Belem': 'Buenos Aires',
  'America/Belize': 'Central Time (US & Canada)',
  'America/Blanc-Sablon': 'Atlantic Time (Canada)',
  'America/Boa_Vista': 'Atlantic Time (Canada)',
  'America/Bogota': 'Bogota',
  'America/Boise': 'Mountain Time (US & Canada)',
  'America/Buenos_Aires': 'Buenos Aires',
  'America/Cambridge_Bay': 'Mountain Time (US & Canada)',
  'America/Campo_Grande': 'Atlantic Time (Canada)',
  'America/Cancun': 'Central Time (US & Canada)',
  'America/Caracas': 'Caracas',
  'America/Cayenne': 'Buenos Aires',
  'America/Cayman': 'Eastern Time (US & Canada)',
  'America/Chicago': 'Central Time (US & Canada)',
  'America/Chihuahua': 'Chihuahua',
  'America/Costa_Rica': 'Central Time (US & Canada)',
  'America/Cuiaba': 'Atlantic Time (Canada)',
  'America/Curacao': 'Atlantic Time (Canada)',
  'America/Danmarkshavn': 'London',
  'America/Dawson': 'Pacific Time (US & Canada)',
  'America/Dawson_Creek': 'Mountain Time (US & Canada)',
  'America/Denver': 'Mountain Time (US & Canada)',
  'America/Detroit': 'Eastern Time (US & Canada)',
  'America/Dominica': 'Atlantic Time (Canada)',
  'America/Edmonton': 'Mountain Time (US & Canada)',
  'America/Eirunepe': 'Atlantic Time (Canada)',
  'America/El_Salvador': 'Central Time (US & Canada)',
  'America/Fortaleza': 'Buenos Aires',
  'America/Glace_Bay': 'Atlantic Time (Canada)',
  'America/Godthab': 'Greenland',
  'America/Goose_Bay': 'Atlantic Time (Canada)',
  'America/Grand_Turk': 'Eastern Time (US & Canada)',
  'America/Grenada': 'Atlantic Time (Canada)',
  'America/Guadeloupe': 'Atlantic Time (Canada)',
  'America/Guatemala': 'Central America',
  'America/Guayaquil': 'Eastern Time (US & Canada)',
  'America/Guyana': 'Georgetown',
  'America/Halifax': 'Atlantic Time (Canada)',
  'America/Havana': 'Eastern Time (US & Canada)',
  'America/Hermosillo': 'Mountain Time (US & Canada)',
  // The America/Indianapolis time zone has been deemed obsolete. It has been replaced by America/Indiana/Indianapolis. http://www.timezoneconverter.com/cgi-bin/zoneinfo.tzc
  'America/Indianapolis': 'Indiana (East)',
  'America/Indiana/Indianapolis': 'Indiana (East)',
  'America/Indiana/Knox': 'Central Time (US & Canada)',
  'America/Indiana/Marengo': 'Eastern Time (US & Canada)',
  'America/Indiana/Petersburg': 'Eastern Time (US & Canada)',
  'America/Indiana/Tell_City': 'Central Time (US & Canada)',
  'America/Indiana/Vevay': 'Eastern Time (US & Canada)',
  'America/Indiana/Vincennes': 'Eastern Time (US & Canada)',
  'America/Indiana/Winamac': 'Eastern Time (US & Canada)',
  'America/Inuvik': 'Mountain Time (US & Canada)',
  'America/Iqaluit': 'Eastern Time (US & Canada)',
  'America/Jamaica': 'Eastern Time (US & Canada)',
  'America/Juneau': 'Alaska',
  'America/Kentucky/Louisville': 'Eastern Time (US & Canada)',
  'America/Kentucky/Monticello': 'Eastern Time (US & Canada)',
  'America/La_Paz': 'La Paz',
  'America/Lima': 'Lima, Quito',
  'America/Los_Angeles': 'Pacific Time (US & Canada)',
  'America/Maceio': 'Buenos Aires',
  'America/Managua': 'Central Time (US & Canada)',
  'America/Manaus': 'Atlantic Time (Canada)',
  'America/Marigot': 'Atlantic Time (Canada)',
  'America/Martinique': 'Atlantic Time (Canada)',
  'America/Matamoros': 'Central Time (US & Canada)',
  'America/Mazatlan': 'Mazatlan',
  'America/Menominee': 'Central Time (US & Canada)',
  'America/Merida': 'Central Time (US & Canada)',
  'America/Mexico_City': 'Mexico City, Guadalajara',
  'America/Miquelon': 'Buenos Aires',
  'America/Moncton': 'Atlantic Time (Canada)',
  'America/Monterrey': 'Monterrey',
  'America/Montevideo': 'Montevideo',
  'America/Montreal': 'Eastern Time (US & Canada)',
  'America/Montserrat': 'Atlantic Time (Canada)',
  'America/Nassau': 'Eastern Time (US & Canada)',
  'America/New_York': 'Eastern Time (US & Canada)',
  'America/Nipigon': 'Eastern Time (US & Canada)',
  'America/Nome': 'Alaska',
  'America/Noronha': 'Mid-Atlantic',
  'America/North_Dakota/Center': 'Central Time (US & Canada)',
  'America/North_Dakota/New_Salem': 'Central Time (US & Canada)',
  'America/Ojinaga': 'Mountain Time (US & Canada)',
  'America/Panama': 'Eastern Time (US & Canada)',
  'America/Pangnirtung': 'Eastern Time (US & Canada)',
  'America/Paramaribo': 'Buenos Aires',
  'America/Phoenix': 'Arizona',
  'America/Port-au-Prince': 'Eastern Time (US & Canada)',
  'America/Port_of_Spain': 'Atlantic Time (Canada)',
  'America/Porto_Velho': 'Atlantic Time (Canada)',
  'America/Puerto_Rico': 'Atlantic Time (Canada)',
  'America/Rainy_River': 'Central Time (US & Canada)',
  'America/Rankin_Inlet': 'Central Time (US & Canada)',
  'America/Recife': 'Buenos Aires',
  'America/Regina': 'Saskatchewan',
  'America/Resolute': 'Eastern Time (US & Canada)',
  'America/Rio_Branco': 'Atlantic Time (Canada)',
  'America/Santa_Isabel': 'Pacific Time (US & Canada)',
  'America/Santarem': 'Buenos Aires',
  'America/Santiago': 'Santiago',
  'America/Santo_Domingo': 'Atlantic Time (Canada)',
  'America/Sao_Paulo': 'Brasilia',
  'America/Scoresbysund': 'Cape Verde Is.',
  'America/Shiprock': 'Mountain Time (US & Canada)',
  'America/St_Barthelemy': 'Atlantic Time (Canada)',
  'America/St_Johns': 'Newfoundland',
  'America/St_Kitts': 'Atlantic Time (Canada)',
  'America/St_Lucia': 'Atlantic Time (Canada)',
  'America/St_Thomas': 'Atlantic Time (Canada)',
  'America/St_Vincent': 'Atlantic Time (Canada)',
  'America/Swift_Current': 'Central Time (US & Canada)',
  'America/Tegucigalpa': 'Central Time (US & Canada)',
  'America/Thule': 'Atlantic Time (Canada)',
  'America/Thunder_Bay': 'Eastern Time (US & Canada)',
  'America/Tijuana': 'Tijuana',
  'America/Toronto': 'Eastern Time (US & Canada)',
  'America/Tortola': 'Atlantic Time (Canada)',
  'America/Vancouver': 'Pacific Time (US & Canada)',
  'America/Whitehorse': 'Pacific Time (US & Canada)',
  'America/Winnipeg': 'Central Time (US & Canada)',
  'America/Yakutat': 'Alaska',
  'America/Yellowknife': 'Mountain Time (US & Canada)',
  'Antarctica/Casey': 'Hong Kong',
  'Antarctica/Davis': 'Jakarta',
  'Antarctica/DumontDUrville': 'Sydney',
  'Antarctica/Mawson': 'Dhaka',
  'Antarctica/McMurdo': 'Auckland',
  'Antarctica/Palmer': 'Atlantic Time (Canada)',
  'Antarctica/Rothera': 'Buenos Aires',
  'Antarctica/South_Pole': 'Auckland',
  'Antarctica/Syowa': 'Moscow',
  'Antarctica/Vostok': 'London',
  'Arctic/Longyearbyen': 'Paris',
  'Asia/Aden': 'Moscow',
  'Asia/Almaty': 'Almaty',
  'Asia/Amman': 'Cairo',
  'Asia/Anadyr': 'Solomon Is.',
  'Asia/Aqtau': 'Karachi',
  'Asia/Aqtobe': 'Karachi',
  'Asia/Ashgabat': 'Karachi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Bahrain': 'Moscow',
  'Asia/Baku': 'Baku',
  'Asia/Bangkok': 'Bangkok, Hanoi',
  'Asia/Beirut': 'Cairo',
  'Asia/Bishkek': 'Dhaka',
  'Asia/Brunei': 'Hong Kong',
  // The Asia/Calcutta time zone has been deemed obsolete. It has been replaced by Asia/Kolkata. http://www.timezoneconverter.com/cgi-bin/zoneinfo.tzc?s=default&tz=Asia/Calcutta
  'Asia/Calcutta': 'Kolkata, New Delhi',
  'Asia/Choibalsan': 'Hong Kong',
  'Asia/Chongqing': 'Chongqing',
  'Asia/Colombo': 'Mumbai, Sri Jayawardenepura',
  'Asia/Damascus': 'Cairo',
  'Asia/Dhaka': 'Dhaka, Astana',
  'Asia/Dili': 'Tokyo',
  'Asia/Dubai': 'Baku',
  'Asia/Dushanbe': 'Karachi',
  'Asia/Gaza': 'Cairo',
  'Asia/Harbin': 'Hong Kong',
  'Asia/Ho_Chi_Minh': 'Jakarta',
  'Asia/Hong_Kong': 'Hong Kong',
  'Asia/Hovd': 'Jakarta',
  'Asia/Irkutsk': 'Irkutsk',
  'Asia/Jakarta': 'Jakarta',
  'Asia/Jayapura': 'Tokyo',
  'Asia/Jerusalem': 'Jerusalem',
  'Asia/Kabul': 'Kabul',
  'Asia/Kamchatka': 'Kamchatka',
  'Asia/Karachi': 'Islamabad',
  'Asia/Kashgar': 'Hong Kong',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Kolkata': 'Kolkata, New Delhi',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur',
  'Asia/Kuching': 'Hong Kong',
  'Asia/Kuwait': 'Kuwait',
  'Asia/Macau': 'Hong Kong',
  'Asia/Magadan': 'Magadan',
  'Asia/Makassar': 'Hong Kong',
  'Asia/Manila': 'Hong Kong',
  'Asia/Muscat': 'Muscat, Abu Dhabi',
  'Asia/Nicosia': 'Cairo',
  'Asia/Novokuznetsk': 'Dhaka',
  'Asia/Novosibirsk': 'Novosibirsk',
  'Asia/Omsk': 'Dhaka',
  'Asia/Oral': 'Karachi',
  'Asia/Phnom_Penh': 'Jakarta',
  'Asia/Pontianak': 'Jakarta',
  'Asia/Pyongyang': 'Tokyo',
  'Asia/Qatar': 'Moscow',
  'Asia/Qyzylorda': 'Dhaka',
  'Asia/Rangoon': 'Rangoon',
  'Asia/Riyadh': 'Riyadh',
  'Asia/Saigon': 'Jakarta',
  'Asia/Sakhalin': 'Sydney',
  'Asia/Samarkand': 'Karachi',
  'Asia/Seoul': 'Seoul',
  'Asia/Shanghai': 'Beijing',
  'Asia/Singapore': 'Singapore',
  'Asia/Srednekolymsk': 'Srednekolymsk',
  'Asia/Taipei': 'Taipei',
  'Asia/Tashkent': 'Tashkent',
  'Asia/Tbilisi': 'Tbilisi',
  'Asia/Tehran': 'Tehran',
  'Asia/Thimphu': 'Dhaka',
  'Asia/Tokyo': 'Tokyo, Sapporo',
  'Asia/Ulaanbaatar': 'Ulaanbaatar',
  'Asia/Urumqi': 'Urumqi',
  'Asia/Vientiane': 'Jakarta',
  'Asia/Vladivostok': 'Vladivostok',
  'Asia/Yakutsk': 'Yakutsk',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Yerevan': 'Yerevan',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Bermuda': 'Atlantic Time (Canada)',
  'Atlantic/Canary': 'London',
  'Atlantic/Cape_Verde': 'Cape Verde Is.',
  'Atlantic/Faroe': 'London',
  'Atlantic/Madeira': 'London',
  'Atlantic/Reykjavik': 'London',
  'Atlantic/South_Georgia': 'Mid-Atlantic',
  'Atlantic/St_Helena': 'London',
  'Atlantic/Stanley': 'Atlantic Time (Canada)',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Broken_Hill': 'Adelaide',
  'Australia/Currie': 'Sydney',
  'Australia/Darwin': 'Darwin',
  'Australia/Hobart': 'Hobart',
  'Australia/Lindeman': 'Sydney',
  'Australia/Melbourne': 'Melbourne, Canberra',
  'Australia/Perth': 'Perth',
  'Australia/Sydney': 'Sydney',
  'Etc/UTC': 'UTC',
  'Europe/Amsterdam': 'Amsterdam',
  'Europe/Andorra': 'Paris',
  'Europe/Athens': 'Athens',
  'Europe/Belgrade': 'Belgrade',
  'Europe/Berlin': 'Berlin',
  'Europe/Bratislava': 'Bratislava',
  'Europe/Brussels': 'Brussels',
  'Europe/Bucharest': 'Bucharest',
  'Europe/Budapest': 'Budapest',
  'Europe/Chisinau': 'Cairo',
  'Europe/Copenhagen': 'Copenhagen',
  'Europe/Dublin': 'Dublin',
  'Europe/Gibraltar': 'Paris',
  'Europe/Guernsey': 'London',
  'Europe/Helsinki': 'Helsinki',
  'Europe/Isle_of_Man': 'London',
  'Europe/Istanbul': 'Istanbul',
  'Europe/Jersey': 'London',
  'Europe/Kaliningrad': 'Kaliningrad',
  'Europe/Kiev': 'Kyiv',
  'Europe/Lisbon': 'Lisbon',
  'Europe/Ljubljana': 'Ljubljana',
  'Europe/London': 'London, Edinburgh',
  'Europe/Luxembourg': 'Paris',
  'Europe/Madrid': 'Madrid',
  'Europe/Malta': 'Paris',
  'Europe/Mariehamn': 'Cairo',
  'Europe/Minsk': 'Minsk',
  'Europe/Monaco': 'Paris',
  'Europe/Moscow': 'Moscow, St. Petersburg',
  'Europe/Oslo': 'Paris',
  'Europe/Paris': 'Paris',
  'Europe/Podgorica': 'Paris',
  'Europe/Prague': 'Prague',
  'Europe/Riga': 'Riga',
  'Europe/Rome': 'Rome',
  'Europe/Samara': 'Samara',
  'Europe/San_Marino': 'Paris',
  'Europe/Sarajevo': 'Sarajevo',
  'Europe/Simferopol': 'Cairo',
  'Europe/Skopje': 'Skopje',
  'Europe/Sofia': 'Sofia',
  'Europe/Stockholm': 'Stockholm',
  'Europe/Tallinn': 'Tallinn',
  'Europe/Tirane': 'Paris',
  'Europe/Uzhgorod': 'Cairo',
  'Europe/Vaduz': 'Paris',
  'Europe/Vatican': 'Paris',
  'Europe/Vienna': 'Vienna',
  'Europe/Vilnius': 'Vilnius',
  'Europe/Volgograd': 'Volgograd',
  'Europe/Warsaw': 'Warsaw',
  'Europe/Zagreb': 'Zagreb',
  'Europe/Zaporozhye': 'Cairo',
  'Europe/Zurich': 'Zurich',
  'Indian/Antananarivo': 'Moscow',
  'Indian/Chagos': 'Dhaka',
  'Indian/Christmas': 'Jakarta',
  'Indian/Cocos': 'Rangoon',
  'Indian/Comoro': 'Moscow',
  'Indian/Kerguelen': 'Karachi',
  'Indian/Mahe': 'Baku',
  'Indian/Maldives': 'Karachi',
  'Indian/Mauritius': 'Baku',
  'Indian/Mayotte': 'Moscow',
  'Indian/Reunion': 'Baku',
  'Pacific/Apia': 'Samoa',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Chatham': 'Chatham Is.',
  'Pacific/Easter': 'Central Time (US & Canada)',
  'Pacific/Efate': 'Solomon Is.',
  'Pacific/Enderbury': "Nuku'alofa",
  'Pacific/Fakaofo': 'Tokelau Is.',
  'Pacific/Fiji': 'Fiji',
  'Pacific/Funafuti': 'Auckland',
  'Pacific/Galapagos': 'Central Time (US & Canada)',
  'Pacific/Gambier': 'Alaska',
  'Pacific/Guadalcanal': 'Solomon Is.',
  'Pacific/Guam': 'Guam',
  'Pacific/Honolulu': 'Hawaii',
  'Pacific/Johnston': 'Hawaii',
  'Pacific/Kosrae': 'Solomon Is.',
  'Pacific/Kwajalein': 'Auckland',
  'Pacific/Majuro': 'Marshall Is.',
  'Pacific/Midway': 'Midway Island, International Date Line West',
  'Pacific/Nauru': 'Auckland',
  'Pacific/Niue': 'Samoa',
  'Pacific/Noumea': 'New Caledonia',
  'Pacific/Pago_Pago': 'American Samoa',
  'Pacific/Palau': 'Tokyo',
  'Pacific/Pitcairn': 'Pacific Time (US & Canada)',
  'Pacific/Ponape': 'Solomon Is.',
  'Pacific/Port_Moresby': 'Port Moresby',
  'Pacific/Rarotonga': 'Hawaii',
  'Pacific/Saipan': 'Sydney',
  'Pacific/Tahiti': 'Hawaii',
  'Pacific/Tarawa': 'Auckland',
  'Pacific/Tongatapu': "Nuku'alofa",
  'Pacific/Truk': 'Sydney',
  'Pacific/Wake': 'Auckland',
  'Pacific/Wallis': 'Auckland',
};

export default activeSupportTimeZoneCompleteMapping;
