import React from 'react';
import PropTypes from 'prop-types';

function ContactInfo({ product }) {
  const liClasses = 'list-group-item auto-truncate text-nowrap';
  const hasContactInfo = product.email || product.twitter || product.phone || product.location_string;

  if (!hasContactInfo) return null;

  return (
    <>
      <ul
        className="list-group list-group-borderless list-group-xs"
        style={{ fontSize: '85%' }}
      >
        {product.twitter && (
          <li className={liClasses}>
            <a
              href={`https://twitter.com/${product.twitter}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-x-twitter me-1" />
              {product.twitter}
            </a>
          </li>
        )}
        {product.phone && (
          <li className={liClasses}>
            <a href={`tel:${product.phone}`}>
              <i className="fa-solid fa-phone me-1" />
              {product.phone}
            </a>
          </li>
        )}
        {product.location_string && (
          <li className={liClasses}>
            <a
              href={`https://www.google.com/maps?q=${product.location_string}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-solid fa-location-dot me-1" />
              {product.location_string}
            </a>
          </li>
        )}
      </ul>
      <div className="clearfix mb-2" />
    </>
  );
}

ContactInfo.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

export default ContactInfo;
