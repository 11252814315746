import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';
import vahoy from '../../javascript/vahoy';
import vapi from '../../javascript/frontend/api/vapi';
import AddMoreExperts from './fields/add_more_experts';
import AttachFiles from './fields/attach_files';
import AttachRequest from './fields/attach_request';
import SendTo from './fields/send_to';
import TypeYourMessage from './fields/type_your_message';
import helper from '../../javascript/frontend/helpers/helper';

function MultiMessaging({
                          backPath, sourceId, userId,
                        }) {
  const [sendToSources, setSendToSources] = useState([]);
  const [sendToError, setSendToError] = useState(null);
  const [addMoreExperts, setAddMoreExperts] = useState([]);
  const [request, setRequest] = useState(null);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [files, setFiles] = useState([]);

  const handleAddSourceClick = (source) => {
    setSendToSources([...sendToSources, source]);
    setAddMoreExperts(addMoreExperts.filter((s) => s !== source));
  };

  const handleRemoveSourceClick = (source) => {
    setSendToSources(sendToSources.filter((s) => s !== source));
    setAddMoreExperts([source, ...addMoreExperts]);
  };

  useEffect(() => {
    if (request) {
      const inviteToPitchMessage = `Please pitch my request on Qwoted: "${request.name}" ${request.shareWithSourcesUrl}`;
      setMessage(inviteToPitchMessage);
    }
  }, [request]);

  const isValid = () => {
    let valid = true;

    if (sendToSources.length === 0) {
      valid = false;
      setSendToError('You must send to at least one expert!');
    } else {
      setSendToError(null);
    }

    if (message.length === 0) {
      valid = false;
      setMessageError('Your message cannot be empty!');
    } else {
      setMessageError(null);
    }

    return valid;
  };

  const send = () => {
    if (isValid()) {
      // This is not necessarily current_user, because admins can send messages on behalf of users.
      const uId = userId;
      const to = sendToSources.map((source) => source.attributes.sgid);

      // Subject is not a part of Sonia's designs so we parse it from the start of the message
      const subject = helper.beautifulTruncateStr(message, 50);

      const attachments = {};
      files.forEach((file, index) => {
        attachments[index] = { file_cloudinary: [file.base64File] };
      });

      vapi.createMessageV2(uId, to, subject, message, attachments)
        .then((result) => {
          vahoy.track('MultiMessaging#send');
          window.location.href = new URI(window.location.href)
            .pathname(result.data.path)
            .search({ flash_success: [result.data.success] });
        })
        .catch((error) => {
          alert(error);
          throw (error);
        });
    }
  };

  const ctaText = sendToSources.length > 1 ? 'Send Separately' : 'Send';

  return (
    <>
      {(messageError || sendToError) && (
        <div className="alert alert-danger">
          <div className="" id="error-explanation">
            <h4 className="alert-heading">Error: Unable to send message. Please correct the following:</h4>
            <ul>
              {sendToError && <li>{sendToError}</li>}
              {messageError && <li>{messageError}</li>}
            </ul>
          </div>
        </div>
      )}

      <div className="mb-3">
        <SendTo
          handleRemoveSourceClick={handleRemoveSourceClick}
          initialSourceId={sourceId}
          sendToError={sendToError}
          sendToSources={sendToSources}
          setSendToSources={setSendToSources}
        />
      </div>
      <hr />
      <div className="mb-3">
        <AddMoreExperts
          addMoreExperts={addMoreExperts}
          handleAddSourceClick={handleAddSourceClick}
          initialSourceId={sourceId}
          setAddMoreExperts={setAddMoreExperts}
        />
      </div>
      <hr />
      <div className="mb-3">
        <AttachRequest
          request={request}
          setRequest={setRequest}
          userId={userId}
        />
      </div>
      <div className="mb-3 required">
        <TypeYourMessage
          message={message}
          messageError={messageError}
          setMessage={setMessage}
        />
      </div>
      <div className="float-end">
        <a
          className="cancel-button btn btn-link"
          href={backPath}
        >
          Cancel
        </a>
        <button
          className="btn btn-primary no-border btn-lg"
          data-disable-with="Send Separately"
          type="submit"
          value={ctaText}
          onClick={() => {
            send();
          }}
        >
          {ctaText}
        </button>
      </div>
      <div>
        <AttachFiles
          files={files}
          setFiles={setFiles}
        />
      </div>
    </>
  );
}

MultiMessaging.propTypes = {
  backPath: PropTypes.string,
  sourceId: PropTypes.number,
  userId: PropTypes.number,
};

MultiMessaging.defaultProps = {
  backPath: undefined,
  sourceId: undefined,
  userId: undefined,
};

export default MultiMessaging;
