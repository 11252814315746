import React from 'react';
import {
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

function PublicationFilterPanel({ customClass, defaultRefinement }) {
  return (
    <div className={`card bg-transparent border-0 mb-3 ${customClass}`}>
      <div className="card-body ais-hide-counts">
        <Panel header="Filter by publication">
          <RefinementList
            attribute="publication.name"
            defaultRefinement={defaultRefinement}
            limit={6}
            showMore={true}
            showMoreLimit={15}
            searchable={true}
          />
        </Panel>
      </div>
    </div>
  );
}

PublicationFilterPanel.propTypes = {
  customClass: PropTypes.string,
  defaultRefinement: PropTypes.instanceOf(Object),
};

PublicationFilterPanel.defaultProps = {
  customClass: undefined,
  defaultRefinement: '',
};

export default PublicationFilterPanel;
