import React from 'react';
import PropTypes from 'prop-types';

function Publication({ sourceRequest, smallMode }) {
  const { publication } = sourceRequest;

  if (typeof publication.logo_url === 'string') {
    return (
      <div className="gx-0 mb-0">
        <div
          style={{
            textAlign: 'start',
            minHeight: smallMode ? '45px' : '55px',
          }}
        >
          <div
            style={{
              minHeight: '60px',
            }}
            className="w-100"
          >
            <img
              alt={publication.name}
              src={publication.logo_url}
              style={{
                maxHeight: smallMode ? '45px' : '60px',
                maxWidth: smallMode ? '110px' : '140px',
              }}
              className="mt-1"
            />
          </div>
        </div>
      </div>
    );
  }

  // If there's a free to pitch badge but no publication, we need a placeholder
  if (sourceRequest.source_request_free_to_pitch) {
    return (
      <div className="clearfix" style={{ height: '65px', width: '65px' }} />
    );
  }

  // If none of the above is true, cancel render
  return null;
}

Publication.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object).isRequired,
  smallMode: PropTypes.bool,
};

Publication.defaultProps = {
  smallMode: false,
};

export default Publication;
