import React from 'react';
import Skeleton from 'react-loading-skeleton';

function CompanySkeletonCard() {
  return (
    <div className="card h-100 skeleton-card animated faster fadeIn">
      <div className="card-body position-relative">
        <div className="row">
          <div className="ms-3" style={{ width: '80px' }}>
            {/* Logo */}
            <div className="float-start">
              <Skeleton height={80} width={80} />
            </div>
          </div>
          <div className="col p-0 ms-3 me-2">
            {/* Company Name */}
            <div className="mb-2">
              <Skeleton height={10} width="40%" /><br />
            </div>

            {/* Hashtags */}
            <div>
              <Skeleton height={10} width={80} className="p-1 mb-1 me-1" />
              <Skeleton height={10} width={120} className="p-1 mb-1 me-1" />
              <Skeleton height={10} width={100} className="p-1 mb-1 me-1" />
              <Skeleton height={10} width={140} className="p-1 mb-1 me-1" />
              <Skeleton height={10} width={80} className="p-1 mb-1 me-1" />
            </div>
          </div>
        </div>
        {/* Overview */}
        <div className="row">
          <div className="col">
            <p className="mt-3 mb-0">
              <Skeleton height={8} count={2} />
              <Skeleton height={8} width="60%" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanySkeletonCard;
