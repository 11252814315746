import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalBody, ModalHeader,
} from 'reactstrap'; // Importing from Reactstrap
import AttachEntityInline from './AttachEntityInline';

function AttachEntityModal({
                             addExistingEntityToPitch,
                             addNewEntityToPitch,
                             attachedEntities,
                             onUnspecifiedButtonClick,
                           }) {
  const [hidden, setHidden] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [resetCounter, setResetCounter] = useState(0);

  const handleModalClose = () => {
    setShowModal(false);
    setResetCounter((prev) => prev + 1); // Increment counter
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  // In V1 it is only possible to attach a single entity
  useEffect(() => {
    if (attachedEntities.length > 0) {
      setHidden(true);
      setShowModal(false);
    } else {
      setHidden(false);
    }
  }, [attachedEntities]);

  return (
    <div className={hidden ? 'd-none' : ''}>
      <Button className="mb-2" variant="secondary" size="sm" onClick={handleModalOpen}>
        <i className="fa-solid fa-paperclip" />
        &nbsp; Propose expert or product
      </Button>
      {attachedEntities?.length === 0 && (
        <small>
          &nbsp;<em> * Reporters want to quote someone or something</em>
        </small>
      )}

      <Modal isOpen={showModal} onClosed={handleModalClose} id="attach-entity-modal">
        <ModalHeader toggle={() => setShowModal(false)}>
          Attach
        </ModalHeader>
        <ModalBody>
          <AttachEntityInline
            addExistingEntityToPitch={addExistingEntityToPitch}
            addNewEntityToPitch={addNewEntityToPitch}
            attachedEntities={attachedEntities}
            onUnspecifiedButtonClick={onUnspecifiedButtonClick}
            resetCounter={resetCounter}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

AttachEntityModal.propTypes = {
  addNewEntityToPitch: PropTypes.func.isRequired,
  addExistingEntityToPitch: PropTypes.func.isRequired,
  attachedEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUnspecifiedButtonClick: PropTypes.func,
};

AttachEntityModal.defaultProps = {
  onUnspecifiedButtonClick: undefined,
};

export default AttachEntityModal;
