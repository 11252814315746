import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import axios from 'axios';

function StaffTopPrsCurationIndex() {
  const [topPrs, setTopPrs] = useState([]);
  const [loadingIds, setLoadingIds] = useState([]);

  useEffect(() => {
    axios.get('/api/internal/top_prs.json')
      .then((response) => {
        setTopPrs(response.data);
      })
      .catch((error) => {
        alert('Error fetching Top PRs:', error);
      });
  }, []);

  const toggleIneligible = (id, currentState) => {
    setLoadingIds([...loadingIds, id]);
    axios.put(`/api/internal/top_prs/${id}`, {
      top_prs_ineligible: !currentState,
    })
      .then((response) => {
        setLoadingIds(loadingIds.filter((loadingId) => loadingId !== id));
        setTopPrs(topPrs.map((pr) => (pr.user_id === id ? response.data : pr)));
      })
      .catch((error) => {
        setLoadingIds(loadingIds.filter((loadingId) => loadingId !== id));
        alert('Error updating:', error);
      });
  };

  return (
    <div>
      {topPrs.length === 0 ? (
        <Spinner color="dark" />
      ) : (
        <table className="table">
          <thead>
          <tr>
            <th>PR Name</th>
            <th>Eligible</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          {topPrs.map((pr, index) => (
            <tr key={index}>
              <td><a href={`/users/${pr.user_id}`}>{pr.full_name}</a></td>
              <td>
                {!pr.top_prs_ineligible && (<span>✅</span>)}
                {pr.top_prs_ineligible && (<span>❌</span>)}
              </td>
              <td>
                {loadingIds.includes(pr.user_id) ? (
                  <div>
                    <Spinner
                      color="dark"
                      className="me-2"
                      style={{
                        width: '1.25rem',
                        height: '1.25rem',
                      }}
                    />
                  </div>
                ) : (
                  <button type="button" onClick={() => toggleIneligible(pr.user_id, pr.top_prs_ineligible)}>
                    Toggle
                  </button>
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default StaffTopPrsCurationIndex;
