import React from 'react';
import PropTypes from 'prop-types';

const quickResponses = [
  {
    responseVersion: 1,
    buttonText: "Thanks. Let's stay in touch...",
    responseBody: "Thanks for reaching out. I don't have anything for you right now, but let's stay in touch.",
    defaultResponse: true,
  },
  {
    responseVersion: 2,
    buttonText: 'Yes. I could use help with...',
    responseBody: "Thanks for reaching out. I may be able to use your help with something I'm working on right now:",
    defaultResponse: false,
  },
];

class BodyTextArea extends React.Component {
  constructor(props) {
    super(props);

    this.bodyTextInput = React.createRef();
    const { favoriteableType, favoriteableId, showExtraOptions } = props;
    this.state = {
      favoriteableType,
      favoriteableId,
      favoriteOnSend: true,
      bodyText: '',
      currentResponseVersion: null,
      displayButtons: true,
      previousBodyText: '',
      previewMode: showExtraOptions,
    };
  }

  handleFavoriteOnSendClick = (e) => {
    this.setState({ favoriteOnSend: e.target.checked });
  };

  handleBodyTextChange = (e) => {
    let displayButtons = null;

    // It's clear the user is typing their own message,
    // so ditch the quick response buttons
    if (e.target.value.length > 40) {
      displayButtons = false;
    } else {
      displayButtons = this.state.displayButtons;
    }

    this.setState({
      bodyText: e.target.value,
      previousBodyText: e.target.value,
      previewMode: false,
      displayButtons,
    });
  };

  handleMouseEnter = (responseVersion) => {
    // Preview the quick response by replacing text area with grayed out version of text
    const previewedResponse = quickResponses
      .filter((response) => response.responseVersion === responseVersion)[0];
    if (previewedResponse !== undefined) {
      this.setState({
        bodyText: this.personalize(previewedResponse.responseBody),
        previewMode: true,
      });
    }
  };

  handleMouseLeave = () => {
    // End the preview of the quick response by putting back the old text
    const previousResponse = this.state.previousBodyText;

    if (!this.state.currentResponseVersion) {
      this.setState({
        bodyText: previousResponse,
        previewMode: false,
      });
    }
  };

  handleResponseButtonClick = (responseVersion) => {
    const selectedResponse = quickResponses
      .filter((response) => response.responseVersion === responseVersion)[0];
    if (selectedResponse !== undefined) {
      this.setState({
        displayButtons: false,
        bodyText: this.personalize(selectedResponse.responseBody),
        currentResponseVersion: selectedResponse.responseVersion,
        previewMode: false,
      });
    }
    this.bodyTextInput.current.focus();
  };

  personalize(responseText) {
    let finalText = responseText;
    const { recipientFirstName, senderFirstName } = this.props;

    if (recipientFirstName) {
      finalText = `Hi ${recipientFirstName},\n\n${finalText}`;
    }
    if (senderFirstName) {
      finalText = `${finalText}\n\n${senderFirstName}`;
    }
    return finalText;
  }

  render() {
    let extraOptions = '';
    let favoriteableFields = '';
    let responseButtons = '';
    let favoriteCheckbox = '';
    const {
      showExtraOptions, isFavorite, modelObject, recipientFirstName, borderClass,
    } = this.props;
    const {
      favoriteableType, favoriteableId, favoriteOnSend, displayButtons, previewMode, bodyText,
    } = this.state;

    // These hidden inputs control favoriting the message recipient
    if (showExtraOptions) {
      if (favoriteOnSend && !isFavorite) {
        favoriteableFields = (
          <div>
            <input
              type="hidden"
              value={favoriteableType}
              name={`${modelObject}[favoriteable_type]`}
              id={`${modelObject}_favoriteable_type`}
            />
            <input
              type="hidden"
              value={favoriteableId}
              name={`${modelObject}[favoriteable_id]`}
              id={`${modelObject}_favoriteable_id`}
            />
          </div>
        );
      }

      // The list of quick response buttons to render
      responseButtons = quickResponses.map((button) => (
        <button
          type="button"
          name="commit"
          key={button.responseVersion}
          onMouseEnter={() => this.handleMouseEnter(button.responseVersion)}
          onMouseLeave={() => this.handleMouseLeave(button.responseVersion)}
          onClick={() => this.handleResponseButtonClick(button.responseVersion)}
          className="btn btn-sm btn-outline-secondary ms-1 mt-1"
        >
          {button.buttonText}
        </button>
      ));

      // Checkbox to favorite the recipient (hidden if already favorited according to isFavorite prop)
      if (!isFavorite) {
        favoriteCheckbox = (
          <div className="col-12 col-lg-4">
            <div className="float-lg-end me-2 mt-2 ms-2 mb-0">
              <div className="form-check">
                <label className="mb-0">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={favoriteOnSend}
                    onChange={this.handleFavoriteOnSendClick}
                    id="is_current_compangy"
                  />
                  <small
                    style={{ display: 'inline-block', marginTop: '0.4em' }}
                  >
                    Add {recipientFirstName || 'user'} as a favorite
                  </small>
                </label>
              </div>
            </div>
          </div>
        );
      }

      // All of the extra stuff that can be displayed (or not) at the bottom of the textarea
      extraOptions = (
        <div>
          {favoriteableFields}
          <div
            className="text-info ms-2 ps-1"
            style={{ visibility: displayButtons ? 'visible' : 'hidden' }}
          >
            <strong>
              or start with a quick response:
            </strong>
          </div>
          <div className="row px-2 pt-1 pb-0">
            <div
              className="col-12 col-lg-8"
              style={{ visibility: displayButtons ? 'visible' : 'hidden' }}
            >
              {responseButtons}
            </div>
            {favoriteCheckbox}
          </div>
        </div>
      );
    }

    // Mimic the standard text area we'd get from Rails, but add in the extra "Pitch Buddy" goodies
    return (
      <div className="messaging_body_text_area">
        <textarea
          rows={10}
          className={`form-control no-form-highlighting ${previewMode ? 'text-muted' : ''} ${borderClass}`}
          placeholder="Type your message..."
          style={{ resize: 'none' }}
          value={bodyText}
          onChange={this.handleBodyTextChange}
          required
          name={`${modelObject}[body]`}
          id={`${modelObject}_body`}
          ref={this.bodyTextInput}
        />
        {extraOptions}
      </div>
    );
  }
}

// Component designed to drop into an existing Rails form so that values
// send seamlessly without the need for API calls. Used for composing
// messaging responses with quick response text drafts and checkbox
// for favoriting the user being responded to.
BodyTextArea.propTypes = {
  modelObject: PropTypes.string.isRequired, // Rails object name to use in form
  favoriteableType: PropTypes.string, // Favoriteable profile type of user to favorite if selected by user
  favoriteableId: PropTypes.number, // Favoriteable profile id of user to favorite if selected by user
  borderClass: PropTypes.string, // Optional border class string to pass to the textarea
  showExtraOptions: PropTypes.bool, // Whether or not to display quick response and favorite controls
  recipientFirstName: PropTypes.string, // Optional first name of person receiving the composed message
  senderFirstName: PropTypes.string, // Optional first name of person sending the composed message
  isFavorite: PropTypes.bool, // Optional boolean specifying whether or not favoriteable is already favorited
};

BodyTextArea.defaultProps = {
  favoriteableType: null,
  favoriteableId: null,
  borderClass: '',
  showExtraOptions: false,
  recipientFirstName: null,
  senderFirstName: null,
  isFavorite: false,
};

export default BodyTextArea;
