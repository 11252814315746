import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import Skeleton from 'react-loading-skeleton';
import vlog from '../../javascript/vlog';

function ConfigureSpamTolerance({
                                  userId, authenticatedHeadersHash, setError, onChange = null,
                                }) {
  const [spamTolerance, setSpamTolerance] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showSpamToleranceCheckmark, setShowSpamToleranceCheckmark] = useState(false);

  useEffect(() => {
    async function fetchNotificationConfig() {
      try {
        const { data, error } = await window.supabaseClient
          .from('notification_configurations')
          .select()
          .eq('user_id', userId)
          .single(); // We expect only one profile per user

        if (error) {
          vlog.error('Error fetching notification configuration:', error);
          setError(true);
          return;
        }
        setSpamTolerance(data.spam_tolerance);
      } catch (err) {
        vlog.error('Error fetching notification configuration:', err);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }

    if (userId && authenticatedHeadersHash && window.supabaseClient) {
      fetchNotificationConfig();
    }
  }, [userId, authenticatedHeadersHash, setError]);

  useEffect(() => {
    async function fetchSpamTolerance() {
      vlog.trace('Fetching spam tolerance for user:', userId);

      const { supabaseClient } = window;
      const { data, err } = await supabaseClient
        .from('notification_configurations')
        .select('spam_tolerance')
        .eq('user_id', userId)
        .single();

      if (err) {
        vlog.error('Error fetching spam tolerance:', err);
        setError(err.message);
        setIsLoading(false);
      } else {
        vlog.debug('Fetched spam tolerance value:', data.spam_tolerance);
        setSpamTolerance(data.spam_tolerance);
        setIsLoading(false);
      }
    }

    fetchSpamTolerance();
  }, [setError, userId]);

  async function updateSpamToleranceConfig(roundedValue) {
    vlog.trace('Updating spam tolerance config to:', roundedValue);

    try {
      const response = await fetch(
        `${window.V2_URL}/notify/api/users/${userId}/notification_configuration`,
        {
          method: 'PUT',
          headers: new Headers({
            ...authenticatedHeadersHash,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({ data: { attributes: { spam_tolerance_config: roundedValue } } }),
        },
      );

      if (!response.ok) {
        vlog.warn('Failed to update spam tolerance, network response not ok:', response.status);
        throw new Error('Network response was not ok.');
      }

      vlog.debug('Spam tolerance updated successfully:', roundedValue);
      if (onChange !== null) {
        onChange(roundedValue);
      }
      setShowSpamToleranceCheckmark(true);
      setTimeout(() => setShowSpamToleranceCheckmark(false), 1000);
    } catch (err) {
      vlog.error('Error updating spam tolerance:', err);
    }
  }

  const handleSpamToleranceChange = (e) => {
    const { value } = e.target;
    const roundedValue = Math.round(value);
    vlog.trace('Spam tolerance slider changed:', { value, roundedValue });
    setSpamTolerance(value);
    updateSpamToleranceConfig(roundedValue);
  };

  vlog.trace('Rendering ConfigureSpamTolerance component with state:', {
    spamTolerance,
    isLoading,
    showSpamToleranceCheckmark,
  });

  const dWide = 'fst-italic d-none d-lg-inline fw-bold';
  const dNarrow = 'fst-italic d-inline d-lg-none fw-bold';

  return (
    <div className="container-fluid p-0">
      <style>
        {`
          .form-range {
            -webkit-appearance: none;
            width: 100%;
            background: transparent;
          }
          .form-range:focus {
            outline: none;
          }
          .form-range::-webkit-slider-runnable-track {
            width: 100%;
            height: 12px;
            cursor: pointer;
            animate: 0.2s;
            background: linear-gradient(to right, #6366F1 0%, #6366F1 ${((spamTolerance - 1) / 4) * 100}%, #d3d3d3 ${((spamTolerance - 1) / 4) * 100}%, #d3d3d3 100%);
            border-radius: 200px;
          }
          .form-range::-moz-range-track {
            width: 100%;
            height: 12px;
            cursor: pointer;
            animate: 0.2s;
            background: #d3d3d3;
            border-radius: 200px;
          }
          .form-range::-moz-range-progress {
            height: 12px;
            background-color: #6366F1;
            border-radius: 200px;
          }
          .form-range::-webkit-slider-thumb {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: white;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -4px;
            border: 3px solid #6366F1;
            box-shadow: 0 0 5px rgba(0,0,0,0.2);
          }
          .form-range::-moz-range-thumb {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: white;
            cursor: pointer;
            border: 3px solid #6366F1;
            box-shadow: 0 0 5px rgba(0,0,0,0.2);
          }
          .form-range::-webkit-slider-thumb:hover,
          .form-range::-moz-range-thumb:hover {
            background: #6366F1;
          }
          .form-range::-webkit-slider-thumb:active,
          .form-range::-moz-range-thumb:active {
            background: #4F46E5;
            box-shadow: 0 0 5px rgba(0,0,0,0.4);
          }
        `}
      </style>
      {isLoading ? (
        <div className="row mb-3">
          <div className="col-12">
            <Skeleton height={35} />
            <div className="d-flex justify-content-between mt-2">
              <Skeleton width={100} />
              <Skeleton width={100} />
              <Skeleton width={100} />
            </div>
          </div>
        </div>
      ) : (
        <div className="row mb-3 pt-4">
          <div className="col-12">
            <h4 className="fw-bold">Notification Frequency</h4>
            <p className="text-muted">Choose how often you’d like to hear from us.</p>
          </div>
          <div className="col-12">
            <label className="form-label w-100 position-relative" style={{ zIndex: '1' }}>
              {showSpamToleranceCheckmark && (
                <i
                  className="fa-solid fa-circle-check text-success ms-2 position-absolute"
                  style={{ right: '-20px', top: '4px' }}
                />
              )}
              <input
                className="form-range w-100"
                type="range"
                min="1"
                max="5"
                step="1"
                value={spamTolerance}
                onChange={handleSpamToleranceChange}
                onClick={handleSpamToleranceChange}
              />
            </label>
            <div
              className="d-flex justify-content-between position-relative"
              style={{
                marginBottom: '2em',
                marginTop: '-1.8em',
                pointerEvents: 'none',
                zIndex: '0',
              }}
            >
              {[1, 2, 3, 4, 5].map((tick) => (
                <strong
                  key={tick}
                  style={{
                    position: 'absolute',
                    left: `${((tick - 1) / 4) * 97.5 + 1}%`,
                    fontSize: '1.1rem',
                  }}
                  className="text-body-tertiary"
                >
                  &middot;
                </strong>
              ))}
            </div>
            <div className="d-flex justify-content-between">
              <span className="w-25">
                <span className={dWide}>I only want to see the best matches</span>
                <span className={dNarrow}>Only the best</span>
              </span>

              <span>
                <span className={dWide}>I&apos;d like to see a mixture of both</span>
                <span className={dNarrow}>A mixture of both</span>
              </span>
              <span className="w-25">
                <span className={dWide}>I want to see everything that might be relevant to me</span>
                <span className={dNarrow}>Everything relevant</span>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

ConfigureSpamTolerance.propTypes = {
  authenticatedHeadersHash: PropTypes.object,
  onChange: PropTypes.func,
  setError: PropTypes.func.isRequired,
  userId: PropTypes.string,
};

ConfigureSpamTolerance.defaultProps = {
  authenticatedHeadersHash: null,
  onChange: null,
  userId: null,
};

export default ConfigureSpamTolerance;
