// Documentation: https://github.com/ankane/ahoy.js

import ahoy from 'ahoy.js';
import globals from './globals';

ahoy.configure({
  urlPrefix: '',
  visitsUrl: '/api/internal/ahoy/visits',
  eventsUrl: '/api/internal/ahoy/events',
  page: null,
  platform: 'Web',
  useBeacon: true,
  startOnReady: true,
  trackVisits: false,
  cookies: true,
  // eslint-disable-next-line no-undef
  cookieDomain: globals.TWO_LEVEL_DOMAIN,
  headers: {},
  visitParams: {},
  withCredentials: false,
});

// eslint-disable-next-line no-undef
if (globals.BUSINESS_ENVIRONMENT !== 'production') {
  ahoy.debug();
}

const vahoy = ahoy;

window.vahoy = vahoy;

// eslint-disable-next-line import/prefer-default-export
export default vahoy;
