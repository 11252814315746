import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConcernedAboutRevealingModal from './concerned_about_revealing_modal';

function FieldName({
                     name,
                     onBlur,
                     requestType,
                     setName,
                   }) {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  let label;
  let exampleText;
  let revealStoryIdea = true;
  let placeholder;
  switch (requestType) {
    case 'exposure':
      label = 'Title of your article';
      exampleText = '';
      placeholder = 'The title of your article';
      revealStoryIdea = false;
      break;
    case 'guests':
      label = 'What kind of guest are you looking for?';
      exampleText = 'ex: Experienced driver needed for podcast episode on adventure travel tourism and its effect on the environment';
      break;
    case 'speakers':
      label = 'What type of speaker are you looking for?';
      exampleText = 'ex: Seeking panelist for webinar on cyber security in the financial sector';
      break;
    case 'story_ideas':
      label = 'What topic would you like story ideas for?';
      exampleText = 'ex: Need topics for weekly column on health and wellness';
      revealStoryIdea = false;
      break;
    case 'products':
      label = 'What type of products are you looking for?';
      exampleText = 'ex: Need Father\'s Day gifts for dads who like the outdoors';
      placeholder = 'Summarize your request in one line — what are you looking for?';
      revealStoryIdea = false;
      break;
      case 'man_on_the_street':
        label = 'What type of source are you looking for?';
        exampleText = 'ex: Looking for moms who enjoy yoga';
        placeholder = 'It\'s best to summarize your request in one line';
        revealStoryIdea = false;
        break;
    default:
      // also 'experts'
      placeholder = 'Summarize your request in one line — what are you looking for?';
      label = 'What kind of expert are you looking for?';
      exampleText = 'ex: Immunologist needed for opinions on booster administration for a live on-air interview';
      break;
  }

  return (
    <div className={`mb-3 ${requestType == 'exposure' ? 'col-md-6' : ''}`}>
      <label htmlFor="name" className="fw-bold">
        {label}
        <span style={{ color: '#E71E42' }}> &#42;</span>
        <div className="text-secondary fst-italic fw-light my-1">
          {exampleText}
        </div>
      </label>
      <input
        className="w-100 mb-1 form-control"
        defaultValue={name}
        id="name"
        name="name"
        onBlur={() => onBlur()}
        onInput={(e) => setName(e.target.value)}
        placeholder={placeholder}
        required
        type="text"
      />
      {revealStoryIdea && (
        <button type="button" className="btn btn-link p-0 text-start" onClick={toggleModal}>
          <em>Concerned About Revealing Your Story Idea?</em>
        </button>
      )}
      {revealStoryIdea && modalOpen && (
        <ConcernedAboutRevealingModal isOpen={modalOpen} toggle={toggleModal} />
      )}
    </div>
  );
}

FieldName.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  requestType: PropTypes.string,
  setName: PropTypes.func,
};

FieldName.defaultProps = {
  name: undefined,
  onBlur: undefined,
  requestType: undefined,
  setName: undefined,
};

export default FieldName;
