import React from 'react';
import PropTypes from 'prop-types';
import Select2Publication from '../../select2_publication';

function SelectPublication({
                             publicationId,
                             publicationName,
                             setPublicationId,
                             setPublicationName,
                             requestType,
                           }) {
  const onPublicationChange = (selectedPublicationId, selectedPublicationName) => {
    setPublicationId(selectedPublicationId);
    setPublicationName(selectedPublicationName);
  };

  let label;
  switch (requestType) {
    case 'guests':
      label = 'Where will this guest appear?';
      break;
    case 'speakers':
      label = 'Where will this speaker appear?';
      break;
    case 'story_ideas':
      label = 'Where will this story idea appear?';
      break;
    case 'exposure':
      label = 'Where was this article published?';
      break;
    case 'products':
      label = 'Where will this product be featured?';
      break;
      case 'man_on_the_street':
        label = 'Where will this case study appear?';
        break;
    default: // also 'experts'
      label = 'Where will this expertise appear?';
      break;
  }

  return (
    <div className="mb-3">
      <label className="fw-bold">
        {label}
      </label>

      <br />

      <Select2Publication
        onChange={onPublicationChange}
        placeholderText={requestType === 'exposure' ? 'ex: Your Publication' : 'ex: Your Publication, Podcast, Event, Conference, Educational institutions, etc.'}
        selectedPublication={{
          id: publicationId,
          name: publicationName,
        }}
        className="w-100"
      />

      <div className="mb-1" />

      <div>
        { requestType != 'exposure' && (
          <em className="mt-2">
            Can't find your company? Just include it in the description.
          </em>
         )}
      </div>
    </div>
  );
}

SelectPublication.propTypes = {
  publicationId: PropTypes.string,
  publicationName: PropTypes.string,
  requestType: PropTypes.string,
  setPublicationId: PropTypes.func,
  setPublicationName: PropTypes.func,
};

SelectPublication.defaultProps = {
  publicationId: undefined,
  publicationName: undefined,
  requestType: undefined,
  setPublicationId: undefined,
  setPublicationName: undefined,
};

export default SelectPublication;
