import React from 'react';
import PropTypes from 'prop-types';
import ImageCropped from '../../image_cropped';

function AttachedExistingEntity({
                                  name,
                                  profileImageUrl,
                                  promoEntityId,
                                  removeCallback,
                                  subheading,
                                }) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      removeCallback(promoEntityId);
    }
  };

  return (
    <div className="alert alert-secondary p-2 mt-2 me-2 mb-0 d-inline-flex flex-row align-items-center">
      {profileImageUrl && (
        <ImageCropped roundedCircle wrapperClasses="me-2" src={profileImageUrl} alt={name} size={40} />
      )}
      <div className="d-flex flex-column align-items-start justify-content-start">
        <strong>{name}</strong>
        <div className="small">{subheading}</div>
      </div>
      {removeCallback && (
        <div
          className="btn btn-link p-0 ps-2 text-secondary"
          id="test-remove"
          onClick={() => removeCallback(promoEntityId)}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex="0"
        >
          <i className="fa-solid fa-xmark" />
        </div>
      )}
    </div>
  );
}

AttachedExistingEntity.propTypes = {
  name: PropTypes.string.isRequired,
  profileImageUrl: PropTypes.string,
  promoEntityId: PropTypes.string.isRequired,
  removeCallback: PropTypes.func.isRequired,
  subheading: PropTypes.string,
};

AttachedExistingEntity.defaultProps = {
  profileImageUrl: undefined,
  subheading: undefined,
};

export default AttachedExistingEntity;
