import React from 'react';
import PropTypes from 'prop-types';

class ContactInfosForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactInfos: props.contact_infos,
    };

    this.addContactInfo = this.addContactInfo.bind(this);
    this.makePrimary = this.makePrimary.bind(this);
    this.handleInfoInputChange = this.handleInfoInputChange.bind(this);
    this.autoAddField = this.autoAddField.bind(this);
    this.refreshPrimary = this.refreshPrimary.bind(this);
    this.delete = this.delete.bind(this);
    this.destroyField = this.destroyField.bind(this);
    this.inputField = this.inputField.bind(this);
    this.fieldList = this.fieldList.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  addContactInfo() {
    const { contactInfos } = this.state;
    contactInfos.push({ __private_id: Date.now() });
    this.setState({ contactInfos });
  }

  makePrimary(toBePrimary) {
    const { contactInfos } = this.state;
    const updatedInfos = contactInfos.map((info) => {
      if (info.primary) {
        // eslint-disable-next-line no-param-reassign
        info.primary = false;
      }

      if (info == toBePrimary) {
        // eslint-disable-next-line no-param-reassign
        info.primary = true;
      }

      return info;
    });

    this.setState({ contactInfos: updatedInfos });
  }

  componentDidMount() {
    this.autoAddField();
    this.refreshPrimary();
  }

  handleInfoInputChange(e, changedInfo) {
    const { contactInfos } = this.state;
    const newInfos = contactInfos.map((info) => {
      if (info === changedInfo) {
        _.extend(info, { value: e.target.value });
        return info;
      }

      return info;
    });

    this.setState({ contactInfos: newInfos }, this.autoAddField);
  }

  autoAddField() {
    // eslint-disable-next-line react/destructuring-assignment
    const emptyInfos = this.state.contactInfos.filter((info) => !info.value);

    if (emptyInfos.length === 0) {
      this.addContactInfo();
    }
  }

  refreshPrimary() {
    const { contactInfos } = this.state;
    const primaryInfo = contactInfos.filter((info) => info.primary && !info._destroy);

    if (primaryInfo.length === 0) {
      this.makePrimary(contactInfos.filter((info) => !info._destroy)[0]);
    }
  }

  delete(toBeDeleted) {
    let updatedInfos = [];
    const { contactInfos } = this.state;

    if (toBeDeleted.id) {
      updatedInfos = contactInfos.map((info) => {
        if (info === toBeDeleted) {
          // eslint-disable-next-line no-param-reassign
          info._destroy = true;
        }

        return info;
      });
    } else {
      updatedInfos = contactInfos.filter((info) => info !== toBeDeleted);
    }

    this.setState({ contactInfos: updatedInfos }, this.refreshPrimary);
  }

  destroyField(info) {
    const { form_for: formFor, fields_for: fieldsFor } = this.props;

    return (
      <div key={info.id || info.__private_id}>
        <input
          type="hidden"
          value={info.value}
          name={`${formFor}[${fieldsFor}_attributes][][value]`}
        />
        <input
          type="hidden"
          name={`${formFor}[${fieldsFor}_attributes][][_destroy]`}
          value
        />
        <input
          type="hidden"
          name={`${formFor}[${fieldsFor}_attributes][][id]`}
          value={info.id}
        />
      </div>
    );
  }

  inputField(info, i) {
    const {
      form_for: formFor,
      fields_for: fieldsFor,
      field_class: fieldClass,
      info_type: infoType,
    } = this.props;

    return (
      <div key={info.id || info.__private_id}>
        <input
          className={fieldClass}
          type="text"
          defaultValue={info.value}
          name={`${formFor}[${fieldsFor}_attributes][][value]`}
          onChange={(e) => this.handleInfoInputChange(e, info)}
          placeholder={`New ${infoType}`}
          id={`contact-info-field-${infoType}-${i}`}
        />
        <input
          type="hidden"
          name={`${formFor}[${fieldsFor}_attributes][][info_type]`}
          value={infoType}
        />
        <input
          type="hidden"
          name={`${formFor}[${fieldsFor}_attributes][][primary]`}
          value={!!info.primary}
        />
        <input
          type="hidden"
          name={`${formFor}[${fieldsFor}_attributes][][id]`}
          value={info.id}
        />
      </div>
    );
  }

  fieldList() {
    const that = this;
    const { contactInfos } = this.state;

    return contactInfos.map((info, i) => {
      let primaryButton;

      if (info.primary) {
        primaryButton = (
          <span className="btn btn-sm btn-success me-2 mb-1" title="Primary">
            <i className="fa-solid fa-check" />
          </span>
        );
      } else {
        primaryButton = (
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary me-2 mb-1"
            title="Make primary"
            onClick={() => that.makePrimary(info)}
          >
            <i className="fa-solid fa-check" />
          </button>
        );
      }

      const deleteButton = (
        <button
          type="button"
          className="btn btn-sm btn-outline-danger mb-1"
          onClick={() => that.delete(info)}
        >
          <i className="fa-solid fa-trash-can" />
        </button>
      );

      let fieldList;

      if (info._destroy) {
        fieldList = that.destroyField(info, i);
      } else {
        fieldList = (
          <div
            key={info.id || info.__private_id}
            className="row mt-1"
          >
            <div className="col-10 contact-infos-form-fields-column">
              {that.inputField(info, i)}
              {info.errors
                && <div className="form-text text-danger mb-1">{info.errors.value}</div>}
            </div>
            <div className="col-2">
              {(info.primary || !!info.value)
                && (
                  <span>
                    {primaryButton}
                  </span>
                )}
              {!!info.value && contactInfos.filter((inf) => !inf._destroy).length > 1
                && (
                  <span>
                    {deleteButton}
                  </span>
                )}
            </div>
          </div>
        );
      }

      return fieldList;
    });
  }

  render() {
    return (
      <div>
        {this.fieldList()}
      </div>
    );
  }
}

ContactInfosForm.propTypes = {
  contact_infos: PropTypes.arrayOf(Object).isRequired,
  form_for: PropTypes.string,
  fields_for: PropTypes.string,
  field_class: PropTypes.string,
  info_type: PropTypes.string,
};

ContactInfosForm.defaultProps = {
  form_for: undefined,
  fields_for: undefined,
  field_class: undefined,
  info_type: undefined,
};

export default ContactInfosForm;
