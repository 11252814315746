import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import { withAsyncPaginate } from 'react-select-async-paginate';

import vapi from '../javascript/frontend/api/vapi';
import ImageCropped from './image_cropped';

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const increaseUniq = (uniq) => uniq + 1;

const letterTextCharForCompanyLabel = (label) => {
  const letterWhoseLetterTextIsLightGray = 'q';

  return label.replace(/[^a-zA-Z]+/g, letterWhoseLetterTextIsLightGray).charAt(0).toLowerCase();
};

const formatCompanyLabel = (company) => (
  <>
    {company.image_url && <ImageCropped roundedCircle src={company.image_url} alt={company.label} size={45} />}
    {!company.image_url && (
      <span className="ms-3">
        <i className={`fa-solid fa-city letter-${letterTextCharForCompanyLabel(company.label)}-text`} />
      </span>
    )}
    <span className="ms-3">{company.label}</span>
  </>
);

const formatOptionFromApiResponse = (company) => ({
  value: company.id,
  label: company.attributes.name,
  image_url: company.attributes.image_cloudinary_url,
});

// A React Select dropdown that populates itself with companies from the API.
// It is designed to be embedded within a Rails view, so it takes a selector
// (companyIdInputSelector) for a hidden input that will be populated with the
// selected company's ID.
function SelectCompanyProduct({ companyIdInputSelector, selectedCompany }) {
  // See https://codesandbox.io/p/sandbox/creatable-with-adding-new-options-example-6pznz
  const [cacheUniq, setCacheUniq] = useState(0);
  const [isAddingInProgress, setIsAddingInProgress] = useState(false);

  const [selectedOption, setSelectedOption] = useState(!selectedCompany ? null : {
    value: selectedCompany.id,
    label: selectedCompany.name,
    image_url: selectedCompany.imageCloudinaryUrl,
  });

  const setCompanyIdInputValue = (companyId) => {
    const companyIdInput = jQuery(companyIdInputSelector);
    companyIdInput.val(companyId);
  };

  const onChange = (option) => {
    setSelectedOption(option);
    setCompanyIdInputValue(option?.value);
  };

  const loadCompanies = async (searchString, loadedOptions, { page }) => {
    const perPage = 20;
    const emptyResult = { options: [], hasMore: false };
    const params = {
      'page[offset]': (page - 1) * perPage,
      'page[limit]': perPage,
    };

    if (searchString && !searchString == '') {
      params['filter[name]'] = searchString;
    }

    const response = await vapi.searchCompanies(params);

    if (response.status !== 200 || !response.data) {
      return emptyResult;
    }

    const companiesInResponse = response.data.data;
    const options = companiesInResponse?.map(formatOptionFromApiResponse);
    const totalPages = Math.ceil(response.data.meta.record_count / perPage);

    return {
      options,
      hasMore: totalPages > page,
      additional: {
        page: page + 1,
      },
    };
  };

  const onCreateOption = useCallback(async (inputValue) => {
    setIsAddingInProgress(true);

    const response = await vapi.createCompany({ name: inputValue });
    const newOption = formatOptionFromApiResponse(response.data.data);

    setIsAddingInProgress(false);
    setCacheUniq(increaseUniq);
    onChange(newOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // we only want to create this once, on first render

  return (
    <div>
      <CreatableAsyncPaginate
        loadOptions={loadCompanies}
        debounceTimeout={300}
        formatOptionLabel={formatCompanyLabel}
        value={selectedOption}
        onChange={onChange}
        onCreateOption={onCreateOption}
        defaultOptions
        isDisabled={isAddingInProgress}
        isClearable
        additional={{
          page: 1,
        }}
        cacheUniqs={[cacheUniq]}
      />
    </div>
  );
}

SelectCompanyProduct.propTypes = {
  companyIdInputSelector: PropTypes.string.isRequired,
  selectedCompany: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imageCloudinaryUrl: PropTypes.string,
  }),
};

SelectCompanyProduct.defaultProps = {
  selectedCompany: undefined,
};

export default SelectCompanyProduct;
