import React, { useContext } from 'react';
import { CompanyContext } from '../../data/company_context_management';

function ContactInfosList() {
  const { company, setOpenEditModal } = useContext(CompanyContext);

  if (!company.freeform_contact_info || company.freeform_contact_info?.length === 0) return null;

  return (
    <div>
      <div className="card mb-3 animated faster fadeIn">
        <div className="card-body">
          <div className="fw-bold mb-3 pb-2 text-uppercase small border-bottom d-flex align-items-center">
            Contact
            {company.editable && (
              <button
                className="btn btn-primary btn-sm ms-auto"
                style={{ maxHeight: '2rem', maxWidth: '2rem' }}
                onClick={() => setOpenEditModal('CONTACT_INFO')}
                type="button"
              >
                <i className="fa-solid fa-pencil cursor-pointer" />
              </button>
            )}
          </div>
          {company.freeform_contact_info?.map((contactInfo, index) => (
            <div key={index}>
              <div className="fw-bold mt-2">
                {contactInfo.title}
              </div>

              <div className="text-secondary" style={{ lineHeight: '0.95rem' }}>
                <small style={{ whiteSpace: 'pre-line' }}>
                  {contactInfo.value}
                </small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContactInfosList;
