/* eslint-disable camelcase */
/* eslint-disable new-cap */

import React, { useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import PropTypes from 'prop-types';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';

function Datetimepicker({
                          attr,
                          attr_humanized: attrHumanized,
                          div_className: divClassName,
                          flatpickr_className: flatpickrClassname,
                          form_name: formName,
                          include_time: includeTime,
                          input_group_className: inputGroupClassname,
                          label_className: labelClassname,
                          max,
                          min,
                          onChange,
                          placeholderText,
                          value,
                        }) {
  const flatpickr = useRef(null);

  let options = {
    // these need to come first: https://github.com/flatpickr/flatpickr/issues/633#issuecomment-281472918
    maxDate: max,
    minDate: min,

    altInput: true,
    dateFormat: includeTime ? 'd F Y h:i K' : 'd F Y',
    defaultDate: value,
    disableMobile: true,
    onClose: onChange,
  };

  if (includeTime) {
    options = Object.assign(options, {
      enableTime: includeTime,
      defaultHour: 17,
      minuteIncrement: 30,
      plugins: [new confirmDatePlugin({})],
    });
  }

  const openTimePicker = () => {
    if (flatpickr) {
      // Flatpickr API is awkward
      flatpickr.current.flatpickr.open();
    }
  };

  const flatpickrValue = value ? new Date(value) : null;

  return (
    <div id={`js-form-group-${attr}`} className={`${divClassName} w-100`}>
      {attrHumanized && (
        <label className={`col-form-label ${labelClassname}`} htmlFor={attr}>
          {attrHumanized}
        </label>
      )}

      {/* https://github.com/ankurk91/vue-flatpickr-component/issues/85 */}
      <style>
        {`input.flatpickr.flatpickr-border {
          border-top-left-radius: 7px !important;
          border-bottom-left-radius: 7px !important;
        }`}
      </style>

      <div className={`input-group ${inputGroupClassname}`}>
        <Flatpickr
          className={`flatpickr flatpickr-border bg-white ${flatpickrClassname}`}
          id={attr}
          key={value?.toString()}
          name={formName}
          options={options}
          placeholder={placeholderText}
          ref={flatpickr}
          value={flatpickrValue}
        />
        <button
          className="btn btn-outline-secondary"
          onClick={openTimePicker}
          onKeyUp={(evt) => evt.keyCode == 13 && openTimePicker(evt)}
          type="button"
          tabIndex="-1"
        ><i className="fa-solid fa-calendar-days" />
        </button>

        {includeTime && (
          <button
            className="btn btn-outline-secondary"
            onClick={openTimePicker}
            type="button"
            tabIndex="-1"
            onKeyUp={(evt) => evt.keyCode == 13 && openTimePicker(evt)}
          ><i className="fa-regular fa-clock" />
          </button>
        )}
      </div>
    </div>
  );
}

Datetimepicker.propTypes = {
  attr: PropTypes.string,
  attr_humanized: PropTypes.string,
  div_className: PropTypes.string,
  flatpickr_className: PropTypes.string,
  form_name: PropTypes.string,
  include_time: PropTypes.bool,
  input_group_className: PropTypes.string,
  label_className: PropTypes.string,
  max: PropTypes.instanceOf(Object),
  min: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  placeholderText: PropTypes.string,
  value: PropTypes.string,
};

Datetimepicker.defaultProps = {
  attr: undefined,
  attr_humanized: undefined,
  div_className: '',
  flatpickr_className: '',
  form_name: undefined,
  include_time: undefined,
  input_group_className: '',
  label_className: '',
  max: undefined,
  min: undefined,
  onChange: undefined,
  placeholderText: undefined,
  value: undefined,
};

export default Datetimepicker;
