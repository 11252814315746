import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  createCompanyProduct(companyProduct, productId) {
    vlog.info('api#createCompanyProduct');

    api.configure();

    const url = `/api/internal/products/${productId}/companies`;

    // eslint-disable-next-line camelcase
    const companyProductParams = {
      company_id: companyProduct.company_id,
      company_name: companyProduct.company_name,
      title: companyProduct.title,
    };

    return axios
      .post(url, { company_product: companyProductParams })
      .then((result) => result)
      .catch((errorResponse) => errorResponse);
  },

  updateCompanyProduct(companyProduct, productId, params) {
    vlog.info('api#updateCompanyProduct');

    api.configure();

    const url = `/api/internal/products/${productId}/companies/${companyProduct.id}`;

    return axios
      .put(url, { company_product: params }, api.axiosFix)
      .then((result) => result)
      .catch((errorResponse) => errorResponse);
  },
});
