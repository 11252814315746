import React from 'react';
import PropTypes from 'prop-types';
import LoadingImage from '../../loading_image';
import vahoy from '../../../javascript/vahoy';

class InviteSourceToPitchForm extends React.Component {
  constructor(props) {
    super(props);

    const { sourceRequests } = props;

    this.state = {
      requestId: props.requestId || (sourceRequests[0] && sourceRequests[0].id),
      message: this.getDefaultMessage(),
    };
  }

  getDefaultMessage = () => {
    const { sourceName } = this.props;
    const text = `I was looking at ${sourceName}'s Qwoted profile and would love your thoughts on my recent request.`;

    return text;
  };

  invite = () => {
    const {
      message, requestId,
    } = this.state;
    const { sourceId, onInvitedSource } = this.props;

    this.setState({ inviteState: 'inviting' });
    $.ajax({
      url: '/api/internal/pitch_invitations.json',
      type: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      data: JSON.stringify({
        source_ids: [sourceId], source_request_id: requestId, message,
      }),
      success: () => {
        this.setState({ inviteState: 'invited' });
        onInvitedSource && onInvitedSource(true);
      },
      error: (xhr, status, err) => {
        this.setState({ inviteState: undefined });

        // eslint-disable-next-line
        console.error(this.props.url, xhr, status, err);
      },
    });

    vahoy.track('InviteSourceToPitchForm#invite');
  };

  handleMessageChange = (message) => {
    this.setState({ message });
  };

  handleSourceRequestChange = (requestId) => {
    this.setState({ requestId });
  };

  render() {
    const { message, requestId, inviteState } = this.state;
    const { sourceRequests, sourceName } = this.props;
    let inviteButton;

    if (inviteState == 'inviting') {
      inviteButton = (<LoadingImage height="48" />);
    } else if (inviteState == 'invited') {
      inviteButton = (
        <button
          type="button"
          className="btn btn-lg btn-primary"
          id="test-send-pitch-invitation"
          disabled
        >
          Invited
        </button>
      );
    } else {
      inviteButton = (
        <button
          type="button"
          className="btn btn-lg btn-primary"
          id="test-send-pitch-invitation"
          onClick={() => {
            this.invite();
          }}
        >
          Invite to Pitch
        </button>
      );
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="mb-3 col-lg-12">
            <h2>Invite {sourceName} to pitch</h2>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            Select request:
            <select
              className="form-control source-request"
              id="select_source_request"
              value={requestId || ''}
              onChange={(e) => this.handleSourceRequestChange(e.target.value)}
            >
              {sourceRequests.map((sr) => <option value={sr.id} key={sr.id}>{sr.name}</option>)}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            Write your message:
            <textarea
              rows="6"
              className="form-control message"
              value={message}
              onChange={(e) => this.handleMessageChange(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-2" />
        <div className="d-flex align-items-center justify-content-around mb-3">
          <div className="d-flex">
            {inviteButton}
          </div>
        </div>
      </div>
    );
  }
}

InviteSourceToPitchForm.propTypes = {
  sourceId: PropTypes.number.isRequired,
  requestId: PropTypes.number,
  sourceRequests: PropTypes.arrayOf(Object).isRequired,
  sourceName: PropTypes.string.isRequired,
  onInvitedSource: PropTypes.func,
};

InviteSourceToPitchForm.defaultProps = {
  requestId: null,
  onInvitedSource: undefined,
};

export default InviteSourceToPitchForm;
