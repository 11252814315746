import React from 'react';

const emptyResults = () => (
  <div className="card w-75 mx-auto mt-4">
    <div className="card-body">
      <div className="card-title">
        <h4 className="fw-bold">No products found matching this search.</h4>
      </div>
      <hr />
      <p>
        Our product database is a fraction of Qwoted's global product network. Many of our users haven't taken the
        time to proactively create product profiles for their businesses. We almost always have an expert that can
        can provide product ideas for your piece.
      </p>
      <div className="alert alert-info">
        The easiest way to reach them is to <a href="/reporter_requests/new" className="fw-bold">create a
        request</a>, which will only be delivered to relevant experts in our network.
      </div>
      <hr />
      <p className="text-info">
        <em>
          Still having trouble finding a product?&nbsp;
          <a href={`mailto:${window.SUPPORT_EMAIL}`} className="fw-bold">Get in touch</a> and one of our
          research assistants will be happy to help connect you with relevant experts.
        </em>
      </p>
    </div>
  </div>
);

export default emptyResults;
