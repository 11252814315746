import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import SourcesIndex from './index/sources_index';

function TopLevelIndex({ view }) {
  return (
    <ErrorBoundary>
      <SourcesIndex view={view} />
    </ErrorBoundary>
  );
}

TopLevelIndex.propTypes = {
  view: PropTypes.string,
};

TopLevelIndex.defaultProps = {
  view: 'all',
};

export default TopLevelIndex;
