let businessEnvironment;
let twoLevelDomain;

// In config/webpack/environment.js we use [WebPack's DefinePlugin](https://webpack.js.org/plugins/define-plugin/). If
// this file is compiled via webpack these const variables will be replaced (i.e. webpacker is a preprocessor) with
// string variables.  So when compiled this will read: `typeof 'production' !== 'undefined'` if this is via webpacker.
// However if this file is loaded and executed on the server via node for running tests, DEFINE_BUSINESS_ENVIRONMENT
// will be undefined so then we'll fall back to the default.
if (typeof DEFINE_BUSINESS_ENVIRONMENT !== 'undefined') {
  // eslint-disable-next-line no-undef
  businessEnvironment = DEFINE_BUSINESS_ENVIRONMENT;
  // eslint-disable-next-line no-undef
  twoLevelDomain = DEFINE_TWO_LEVEL_DOMAIN;
} else {
  businessEnvironment = 'test';
  twoLevelDomain = null;
}

const globals = {
  BUSINESS_ENVIRONMENT: businessEnvironment,
  TWO_LEVEL_DOMAIN: twoLevelDomain,
};

export default globals;
