import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function SignupTags(props) {
  const element = useRef(null);
  const { className, elementName } = props;

  useEffect(() => {
    const node = element.current;
    const perPage = 20;

    jQuery(node).select2({
      maximumSelectionLength: 50,
      minimumInputLength: 3,
      multiple: true,
      tags: true,
      tokenSeparators: [',', ' '],
      ajax: {
        url: '/api/internal/jsonapi/hashtags',
        dataType: 'json',
        data: (params) => {
          const page = params.page || 1;
          const obj = {
            filter: {
              name: params.term.replace('#', ''),
            },
          };

          obj['page[offset]'] = (page - 1) * perPage;
          obj['page[limit]'] = perPage;

          return obj;
        },
        processResults(response) {
          const results = (response.data || []).map((result) => {
            const r = { ...result };
            r.text = result.attributes.hashtag;
            return r;
          });

          return {
            results,
            pagination: {
              more: !!(response.links || {}).next,
            },
          };
        },
      },
      createTag: (params) => {
        const dirtyTerm = params.term || '';
        const term = dirtyTerm.trim();

        if (term === '') {
          return null;
        }

        return {
          id: params.term,
          text: `#${params.term}`,
          attributes: {
            hashtag: term,
          },
        };
      },
    });
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <select
      multiple
      name={elementName}
      ref={element}
      className={`${className} tags-input post-signup-tags-input`}
    />
  );
}

SignupTags.propTypes = {
  className: PropTypes.string,
  elementName: PropTypes.string,
};

SignupTags.defaultProps = {
  className: '',
  elementName: '',
};

export default SignupTags;
