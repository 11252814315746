import React from 'react';
import PropTypes from 'prop-types';
import SourceForm from './source/Form';
import ProductForm from './product/Form';

function NewAttachedEntityForm({ addNewEntityToPitch, onCancel, type }) {
  const displayTypes = {
    source: 'Expert',
    product: 'Product',
  };
  const displayType = displayTypes[type?.toLowerCase()];

  return (
    <div style={{ maxWidth: '800px' }}>
      <h4>Add New {displayType}</h4>

      <div className="mb-2" />

      {type === 'Source' && <SourceForm onSubmit={addNewEntityToPitch} onCancel={() => onCancel()} />}
      {type === 'Product' && <ProductForm onSubmit={addNewEntityToPitch} onCancel={() => onCancel()} />}
    </div>
  );
}

NewAttachedEntityForm.propTypes = {
  addNewEntityToPitch: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.string,
};

NewAttachedEntityForm.defaultProps = {
  addNewEntityToPitch: () => {
  },
  onCancel: () => {
  },
  type: undefined,
};

export default NewAttachedEntityForm;
