// Documentation: https://github.com/pimterry/loglevel

// Note the levels:
// logMethods = [
//   "trace",
//   "debug",
//   "info",
//   "warn",
//   "error"
// ];

import log from 'loglevel';
import globals from './globals';

const vlog = log.noConflict();

// eslint-disable-next-line no-undef
if (globals.BUSINESS_ENVIRONMENT === 'production') {
  vlog.disableAll();
} else {
  vlog.setLevel('debug');
}

// Allow access from production console ...
window.vlog = vlog;
// Turn on logging in production with:
// window.vlog.setLevel('trace');

export default vlog;
