import React from 'react';
import PropTypes from 'prop-types';
import useFavorite from './hooks/use_favorite';

function FavoriteButton({
  favorited: initiallyFavorited,
  favoriteId: initialFavoriteId,
  favoriteableId,
  favoriteableType,
  ahoyObjectGid,
  size,
  icon,
  align,
}) {
  const { isFavorited, toggleFavoritedStatus } = useFavorite({
    favoriteableId,
    favoriteableType,
    initiallyFavorited,
    initialFavoriteId,
    ahoyObjectGid,
    icon,
  });

  const bookmark = icon == 'fa-bookmark';
  const fontAwesomeIconStyle = isFavorited ? 'fa-solid' : 'fa-regular';
  const titleText = isFavorited ? 'Remove from saved/favorites.' : 'Add to saved/favorites.';
  const rightAligned = align == 'right' ? 'float-end' : '';

  return (
    <>
      <button
        type="button"
        onClick={toggleFavoritedStatus}
        style={{ verticalAlign: 'middle' }}
        className={`btn btn-${size} btn-link p-1 animated faster fadeIn ${rightAligned ? 'text-end' : ''}`}
      >
        { bookmark && (
          <span className={`text-secondary font-size-16px ${rightAligned ? '' : 'me-2'}`}>{isFavorited ? 'Saved' : 'Save' }</span>
        )}
        <i
          className={`text-${icon == 'fa-heart' ? 'danger' : 'primary'} ${fontAwesomeIconStyle} ${icon} ${rightAligned ? 'ms-2' : ''} fa-lg`}
          title={titleText}
        />
      </button>
    </>
  );
}

FavoriteButton.propTypes = {
  favoriteableId: PropTypes.number.isRequired, // Id of favoriting target
  favoriteableType: PropTypes.string.isRequired, // Object type of favoriting target (ex: 'PrUser')
  favoriteId: PropTypes.number, // Primary key of favorite record in the favorites table
  favorited: PropTypes.bool, // Whether or not the target is currently favorited
  ahoyObjectGid: PropTypes.string.isRequired, // Global id for user being favorited
  size: PropTypes.string,
  icon: PropTypes.string,
  align: PropTypes.string,
};

FavoriteButton.defaultProps = {
  favoriteId: undefined,
  favorited: false,
  size: 'sm',
  icon: 'fa-heart',
  align: 'left',
};

export default FavoriteButton;
