import React, { useContext } from 'react';
import { CompanyContext } from '../../data/company_context_management';

function CompanyOrPrFirmInfo() {
  const { company, topLevelIsLoading } = useContext(CompanyContext);

  if (topLevelIsLoading) return null;

  const renderDisplayBadge = () => {
    if (!company.editable) return null;

    let colorClass;
    let badgeText;

    if (company.display_as_pr_firm) {
      colorClass = 'primary';
      badgeText = 'PR Agency';
    } else {
      colorClass = 'info';
      badgeText = 'Standard';
    }

    return (
      <span className="ms-auto">
        {(colorClass && badgeText) && (
          <span className={`badge ms-1 bg-${colorClass}`}>Display Style: {badgeText}</span>
        )}
      </span>
    );
  };

  return (
    <dl>
      <dt>
        Company or PR firm?
      </dt>
      <dd>
        {company.company_or_pr_firm?.company_or_pr_firm.replace('_', ' ').toUpperCase()}
      </dd>
      <dt>
        Confidence
      </dt>
      <dd>
        {company.company_or_pr_firm?.confidence.replace('_', ' ').toUpperCase()}
      </dd>
      <dt>
        Company score
      </dt>
      <dd>
        {company.company_score}
      </dd>
      <dt>
        PR firm score
      </dt>
      <dd>
        {company.pr_firm_score}
      </dd>
      <hr />
      <dd>
        {renderDisplayBadge()}
      </dd>
    </dl>
  );
}

export default CompanyOrPrFirmInfo;
