import React from 'react';
import PropTypes from 'prop-types';

function ReporterSubtypeListSelect({
                                     listReporterSubtypes,
                                     setReporterSubtypeList,
                                     reporterSubtypeList,
                                   }) {
  // This looks like: {reporter_reporter: true, reporter_writer: false, reporter_freelancer: true, ...}
  const reporterSubtypeListHash = () => {
    const hash = {};
    Object.keys(listReporterSubtypes).forEach((key) => {
      hash[key] = reporterSubtypeList.includes(key);
    });
    return hash;
  };

  // Pass in the hash from above, and it will return a comma separated string of those which
  // are toggled to true: "reporter_reporter,reporter_freelancer"
  const reporterSubtypeListHashToCommaSeparatedString = (hash) => {
    const array = Object.entries(hash);
    const trueKeys = array.map(([key, value]) => {
      if (value) {
        return key;
      }
      return null;
    });
    const trueKeysCompacted = trueKeys.filter((e) => e != null);
    return trueKeysCompacted.join(','); // hash to comma separated string
  };

  const toggleUserTypeReporterSubtype = (key) => {
    const hash = reporterSubtypeListHash();
    hash[key] = !hash[key];
    const string = reporterSubtypeListHashToCommaSeparatedString(hash);
    setReporterSubtypeList(string);
  };

  return (
    <>
      <div className="col-sm-12 mb-3 mt-1">
        <h2 className="text-center h2-subtypes">Select All that Apply</h2>
      </div>

      {Object.entries(listReporterSubtypes).map(([key, value]) => {
        const activeClass = reporterSubtypeList.includes(key) ? 'active' : '';
        return (
          <div className="col-sm-6 mb-3" key={key}>
            <div className="d-grid">
              <button
                className={`btn btn-usertype btn-lg btn-rounded btn-outline-primary shadow-none ${activeClass}`}
                onClick={() => toggleUserTypeReporterSubtype(key)}
                type="button"
              >
                {value}
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
}

ReporterSubtypeListSelect.propTypes = {
  listReporterSubtypes: PropTypes.object,
  setReporterSubtypeList: PropTypes.func,
  reporterSubtypeList: PropTypes.string,
};

ReporterSubtypeListSelect.defaultProps = {
  listReporterSubtypes: undefined,
  setReporterSubtypeList: undefined,
  reporterSubtypeList: undefined,
};

export default ReporterSubtypeListSelect;
