import React from 'react';
import PropTypes from 'prop-types';
import Select2ClientFirm from '../select2_client_firm';

class UserTypeFormDetails extends React.Component {
  constructor(props) {
    super(props);

    const {
      user,
    } = this.props;

    this.state = {
      selectedUserType: user.user_type,
      selectedAuthLevel: user.auth_level,
      selectedSourcePlan: user.source_plan,
      clientFirmAdmin: user.client_firm_admin,
    };
  }

  componentDidMount() {
    const {
      clientFirmId,
    } = this.props;

    this.handleClientFirmChange(clientFirmId);
  }

  handleUserTypeChange = (changeEvent) => {
    this.setState({
      selectedUserType: changeEvent.target.value,
    });
  };

  handleClientFirmChange = (clientFirmId) => {
    const newState = {
      selectedClientFirmId: clientFirmId,
    };

    if (clientFirmId) {
      newState.selectedAuthLevel = 'BasicUser';
      newState.selectedSourcePlan = null;
    }

    this.setState(newState);
  };

  handleClientFirmAdminChange = (event) => {
    this.setState({ clientFirmAdmin: event.target.checked });
  };

  handleAuthLevelChange = (event) => {
    const selectedAuthLevel = event.target.value;

    const newState = {
      selectedAuthLevel,
    };

    if (selectedAuthLevel !== 'BasicUser') {
      newState.selectedSourcePlan = 'pr_team';
    }

    this.setState(newState);
  };

  handleSourcePlanChange = (changeEvent) => {
    this.setState({
      selectedSourcePlan: changeEvent.target.value,
    });
  };

  renderAuthLevel() {
    const { canEditAuthLevel } = this.props;

    if (!canEditAuthLevel) {
      return;
    }

    const {
      selectedAuthLevel,
      selectedClientFirmId,
      selectedUserType,
    } = this.state;

    const enabled = !selectedClientFirmId;
    const visible = !selectedClientFirmId && selectedUserType === 'source';

    return (
      <div
        id="auth-level-edit"
        className="row mb-3"
        style={{ display: visible ? 'flex' : 'none' }}
      >
        <label
          className="col-form-label col-md-2 label-staff"
          htmlFor="user_auth_level"
        >
          Auth Level
        </label>
        <div
          className="col-md-10"
        >
          <select
            className="form-control"
            id="user_auth_level"
            name="user[auth_level]"
            onChange={this.handleAuthLevelChange}
            value={selectedAuthLevel}
            disabled={!enabled}
          >
            <option value="Admin">Admin</option>
            <option value="Staff">Staff</option>
            <option value="BasicUser">Basic user</option>
          </select>
        </div>
      </div>
    );
  }

  renderClientFirm() {
    const {
      clientFirmId,
      clientFirmName,
      canEditClientFirm,
    } = this.props;

    if (!canEditClientFirm) {
      return;
    }

    const {
      selectedAuthLevel,
      selectedClientFirmId,
      selectedUserType,
    } = this.state;

    const visible = selectedUserType === 'source' && selectedAuthLevel === 'BasicUser';

    return (
      <div style={{ display: visible ? '' : 'none' }}>
        <div className="row mb-3 " data-js="client-firm-editing">
          <label className="col-form-label col-md-2 label-staff" htmlFor="user_client_firm">Client Firm</label>
          <div className="col-md-10">
            <input type="hidden" name="user[client_firm_id]" value={selectedClientFirmId} />
            <Select2ClientFirm
              selectedOption={{
                value: clientFirmId,
                label: clientFirmName,
              }}
              onChange={this.handleClientFirmChange}
              placeholderText="What is the name of the client firm (leave blank for none)?"
            />
          </div>
        </div>
      </div>
    );
  }

  renderClientFirmAdmin() {
    const {
      clientFirmAdmin,
      selectedClientFirmId,
      selectedUserType,
    } = this.state;

    const visible = selectedClientFirmId && selectedUserType === 'source';

    const {
      canEditClientFirmAdmin,
    } = this.props;

    if (!canEditClientFirmAdmin) {
      return;
    }

    return (
      <div style={{ display: visible ? '' : 'none' }}>
        {selectedClientFirmId
          && (
            <div
              className="row mb-3 "
              data-js="client-firm-admin-editing"
            >
              <label
                className="col-form-label col-md-2"
                htmlFor="user_client_firm_admin"
              >
                Client firm admin
              </label>
              <div
                className="col-md-10 pt-2"
              >
                <input name="user[client_firm_admin]" type="hidden" value="0" />
                <input
                  checked={clientFirmAdmin}
                  id="user_client_firm_admin"
                  name="user[client_firm_admin]"
                  onChange={this.handleClientFirmAdminChange}
                  type="checkbox"
                  value={clientFirmAdmin}
                />
              </div>
            </div>
          )}
      </div>
    );
  }

  renderUserType() {
    const {
      modelPersisted,
      canEditUserType,
    } = this.props;

    if (!canEditUserType) {
      return;
    }

    const { selectedUserType } = this.state;

    const disabled = modelPersisted ? 'disabled' : null;
    const visible = !disabled;

    return (
      <div className="row mb-3 " style={{ display: visible ? 'flex' : 'none' }}>
        <label
          className="col-form-label col-md-2"
          htmlFor="user_user_type"
        >
          User type
        </label>
        <div className="col-md-10 form-inline">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              disabled={disabled}
              id="user_user_type_reporter"
              name="user[user_type]"
              type="radio"
              value="reporter"
              checked={selectedUserType === 'reporter'}
              onChange={this.handleUserTypeChange}
            />
            <label
              className="form-check-label"
              htmlFor="user_user_type_reporter"
            >
              Reporter
            </label>
          </div>
          <div
            className="form-check form-check-inline"
          >
            <input
              className="form-check-input"
              disabled={disabled}
              id="user_user_type_source"
              name="user[user_type]"
              type="radio"
              value="source"
              checked={selectedUserType === 'source'}
              onChange={this.handleUserTypeChange}
            />
            <label
              className="form-check-label"
              htmlFor="user_user_type_source"
            >
              Source
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderSourcePlan() {
    const { canEditSourcePlan } = this.props;

    if (!canEditSourcePlan) {
      return;
    }

    const {
      selectedClientFirmId,
      selectedAuthLevel,
      selectedSourcePlan,
      selectedUserType,
    } = this.state;

    let freeEnabled = true;
    let paidEnabled = true;
    const prTeamEnabled = true;
    let clientFirmEnabled = true;
    if (selectedClientFirmId || selectedAuthLevel !== 'BasicUser') {
      freeEnabled = false;
      paidEnabled = false;
      clientFirmEnabled = false;
    }
    const visible = selectedUserType === 'source' && !selectedClientFirmId;

    return (
      <div
        className="row mb-3"
        data-js="source-plan-editing"
        style={{ display: visible ? 'flex' : 'none' }}
      >
        <label
          className="col-form-label col-md-2 label-staff"
          htmlFor="user_source_plan"
        >
          Source Plan
        </label>
        <div
          className="col-md-10 form-inline"
        >
          <div
            className="form-check form-check-inline"
          >
            <input
              checked={selectedSourcePlan === 'free'}
              className="form-check-input"
              disabled={!freeEnabled}
              id="user_source_plan_free"
              name="user[source_plan]"
              onChange={this.handleSourcePlanChange}
              type="radio"
              value="free"
            />
            <label
              className="form-check-label"
              htmlFor="user_source_plan_free"
            >
              Free
            </label>
          </div>
          <div
            className="form-check form-check-inline"
          >
            <input
              checked={selectedSourcePlan === 'paid'}
              className="form-check-input"
              disabled={!paidEnabled}
              id="user_source_plan_paid"
              name="user[source_plan]"
              onChange={this.handleSourcePlanChange}
              type="radio"
              value="paid"
            />
            <label
              className="form-check-label"
              htmlFor="user_source_plan_paid"
            >
              Paid
            </label>
          </div>
          <div
            className="form-check form-check-inline"
          >
            <input
              checked={selectedSourcePlan === 'pr_team'}
              className="form-check-input"
              disabled={!prTeamEnabled}
              id="user_source_plan_pr_team"
              name="user[source_plan]"
              onChange={this.handleSourcePlanChange}
              type="radio"
              value="pr_team"
            />
            <label
              className="form-check-label"
              htmlFor="user_source_plan_pr_team"
            >
              Pr team
            </label>
          </div>
          <div
            className="form-check form-check-inline"
          >
            <input
              checked={selectedSourcePlan === 'client_firm'}
              className="form-check-input"
              disabled={!clientFirmEnabled}
              id="user_source_plan_client_firm"
              name="user[source_plan]"
              onChange={this.handleSourcePlanChange}
              type="radio"
              value="client_firm"
            />
            <label
              className="form-check-label"
              htmlFor="user_source_plan_client_firm"
            >
              Client firm
            </label>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderUserType()}
        {this.renderClientFirm()}
        {this.renderClientFirmAdmin()}
        {this.renderAuthLevel()}
        {this.renderSourcePlan()}
      </div>
    );
  }
}

UserTypeFormDetails.propTypes = {
  canEditAuthLevel: PropTypes.bool,
  canEditClientFirm: PropTypes.bool,
  canEditClientFirmAdmin: PropTypes.bool,
  canEditSourcePlan: PropTypes.bool,
  canEditUserType: PropTypes.bool,
  clientFirmName: PropTypes.string,
  clientFirmId: PropTypes.number,
  modelPersisted: PropTypes.bool,
  user: PropTypes.instanceOf(Object),
};

UserTypeFormDetails.defaultProps = {
  canEditAuthLevel: undefined,
  canEditClientFirm: undefined,
  canEditClientFirmAdmin: undefined,
  canEditSourcePlan: undefined,
  canEditUserType: undefined,
  clientFirmName: undefined,
  clientFirmId: undefined,
  modelPersisted: undefined,
  user: undefined,
};

export default UserTypeFormDetails;
