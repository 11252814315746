import React from 'react';
import PropTypes from 'prop-types';
import ConnectedChordDiagram from './charts/chord_diagram';
import ConnectedBubbleChart from '../shared_charts/bubble_chart';
import ConnectedReporterForceGraph from '../shared_charts/force_graph';

function Insights({ query, expandedChart, onCollapse }) {
  const renderBackButton = () => (
    <button
      className="btn btn-link float-end text-secondary"
      onClick={onCollapse}
      style={{ fontSize: '1.5rem' }}
      type="button"
    >
      <i style={{ backgroundColor: '#f5f5f5', fontSize: '10px' }} className="p-2 fa-solid fa-up-right-and-down-left-from-center" />
      <span style={{ fontSize: '12px' }} className="ms-2"> Collapse</span>
    </button>
  );

  if (expandedChart === 'topics') {
    return (
      <div className="justify-content-center">
        <div className="card w-full">
          <div className="card-header bg-white w-full" style={{ height: '75px' }}>
            <h2 className="fs-3 fw-bold mt-3 w-full">
              Associated Topics
              {renderBackButton()}
            </h2>
          </div>
          <div className="card-body">
            <ConnectedChordDiagram attribute="hashtags.hashtag" limit={25} query={query} />
          </div>
        </div>
      </div>
    );
  }

  if (expandedChart === 'publications') {
    return (
      <div className="flex justify-content-center">
        <div className="card w-full">
          <div className="card-header bg-white w-full" style={{ height: '75px' }}>
            <h2 className="fs-3 fw-bold mt-3 w-full">
              Top Publications
              {renderBackButton()}
            </h2>
          </div>
          <div className="card-body">
            <ConnectedBubbleChart
              attribute="publication.name"
              limit={12}
              query={query}
              width={200}
              height={300}
              labelFontSize={3}
            />
          </div>
        </div>
      </div>
    );
  }

  if (expandedChart === 'media') {
    return (
      <div className="flex justify-content-center">
        <div className="card w-full">
          <div className="card-header bg-white" style={{ height: '75px' }}>
            <h2 className="fs-3 fw-bold mt-3 w-full">
              Relevant Media
              {renderBackButton()}
            </h2>
          </div>
          <div className="card-body p-4">
            <ConnectedReporterForceGraph
              attribute="reporter.name"
              limit={20}
              labelFontSize={12}
              width={700}
              height={700}
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
}

Insights.propTypes = {
  query: PropTypes.string,
  expandedChart: PropTypes.string,
  onCollapse: PropTypes.func.isRequired,
};

Insights.defaultProps = {
  query: '',
  expandedChart: null,
};

export default Insights;
