import React from 'react';
import PropTypes from 'prop-types';
import Select2Ajax from './select2_ajax';

function Select2Organization(props) {
  const processSelect2Result = (response) => {
    const results = (response.data || []).map((result) => {
      const r = { ...result };
      r.text = result.attributes.name;
      return r;
    });

    return {
      results,
      pagination: {
        more: !!(response.links || {}).next,
      },
    };
  };

  const onChange = (e) => {
    const organizationId = jQuery(e.target).val();
    const { organizationIdSelector, organizationNameSelector } = props;

    jQuery(organizationIdSelector || '#organization_id').val(organizationId);

    if (organizationId === 'new') {
      const organizationName = jQuery(e.target).find('option:selected').text();
      jQuery(organizationNameSelector || '#organization_name').val(organizationName);
    } else if (!organizationId) {
      jQuery(organizationNameSelector || '#organization_name').val('');
    }
  };

  const {
    ajaxUrl, placeholderText, className, selectedOption,
  } = props;

  return (
    <Select2Ajax
      ajaxUrl={ajaxUrl || '/api/internal/jsonapi/organizations'}
      ajaxProcessResults={processSelect2Result}
      placeholderText={placeholderText || 'Organization represented? Type to search ...'}
      onChange={onChange}
      className={className || 'form-select'}
      selectedOption={selectedOption}
    />
  );
}

Select2Organization.propTypes = {
  organizationIdSelector: PropTypes.string,
  organizationNameSelector: PropTypes.string,
  ajaxUrl: PropTypes.string,
  placeholderText: PropTypes.string,
  className: PropTypes.string,
  selectedOption: PropTypes.instanceOf(Object),
};

Select2Organization.defaultProps = {
  organizationIdSelector: undefined,
  organizationNameSelector: undefined,
  ajaxUrl: undefined,
  placeholderText: undefined,
  className: undefined,
  selectedOption: undefined,
};

export default Select2Organization;
