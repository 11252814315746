import React from 'react';
import PropTypes from 'prop-types';
import OpportunityIndexTableHeader from './opportunity_index_table_header';
import OpportunityIndexTableRow from './opportunity_index_table_row';

class OpportunityIndexTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opportunities: props.opportunities,
    };
  }

  optionalEmptyMessage = () => {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.opportunities.length == 0) {
      return (
        <tr>
          <td colSpan={999}>Nothing found ...</td>
        </tr>
      );
    }
  };

  render() {
    const { opportunities } = this.state;

    return (
      <table className="table table-striped table-sm table-borderless opportunity-index-table">
        <OpportunityIndexTableHeader />
        <tbody>
          {_.map(opportunities, (opportunity) => (
            <OpportunityIndexTableRow
              key={opportunity.id}
              opportunity={opportunity}
            />
          ))}
          {this.optionalEmptyMessage()}
        </tbody>
      </table>
    );
  }
}

OpportunityIndexTable.propTypes = {
  opportunities: PropTypes.arrayOf(Object).isRequired,
};

OpportunityIndexTable.defaultProps = {};

export default OpportunityIndexTable;
