import PropTypes from 'prop-types';
import React from 'react';
import AuthorHeader from '../../public_pitches/public_pitch/author_header';
import PublicPitchBody from '../../public_pitches/public_pitch/public_pitch_body';
import { keysToCamelCase } from '../../utilities';

function PublicPitchHit({ hit, currentQuery }) {
  const camelCaseHit = keysToCamelCase(hit);
  const { id, sourcePath } = camelCaseHit;

  return (
    <div key={id} className="h-100 card mb-3 ms-0 me-0 d-flex headline-card">
      <div style={{ background: '#e8aba2' }} className="card-header text-black text-center fw-bold">
        <a className="text-black" title="Expert Insights" href="/expert_insights">
          {' '}
          Expert Insights
        </a>
      </div>
      <div className="card-header p-3 d-flex flex-column justify-content-between">
        <AuthorHeader publicPitch={camelCaseHit} />
      </div>

      <div className="card-body p-3 d-flex flex-column">
        <PublicPitchBody
          canSendMessages={false}
          currentUserAdmin={false}
          isAdminModalOpen={false}
          publicPitch={camelCaseHit}
          toggleAdmin={() => {}}
        />

        <div className="mt-4 mb-2 row w-100">
          <div className="col">
            {sourcePath && (
              <a className="btn btn-primary btn-large" href={sourcePath}>
                <small>View Expert Profile </small>
              </a>
            )}
          </div>
        </div>
        <a
          className="pt-2 text-decoration-underline font-size-14px fw-bold"
          href={`/expert_insights?query=${currentQuery}`}
        >{`Read ${currentQuery === '' ? 'all' : 'similar'} Expert Insights`}</a>
      </div>
    </div>
  );
}

PublicPitchHit.propTypes = {
  hit: PropTypes.shape({
    avatarLetters: PropTypes.string,
    avatarUrl: PropTypes.string,
    companyName: PropTypes.string,
    companyPath: PropTypes.string,
    employmentsString: PropTypes.string,
    fullName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    publicPitchText: PropTypes.string.isRequired,
    publicPitchTitle: PropTypes.string.isRequired,
    publicPitchLede: PropTypes.string,
    publicPitchPreamble: PropTypes.string,
    sourcePath: PropTypes.string,
  }),
  currentQuery: PropTypes.string,
};

PublicPitchHit.defaultProps = {
  hit: {
    avatarLetters: 'QW',
    avatarUrl: null,
    companyName: null,
    companyPath: null,
    employmentsString: null,
    fullName: '',
    sourcePath: null,
  },
  currentQuery: '',
};

export default PublicPitchHit;
