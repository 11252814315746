/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Hashtags from '../../hashtags';
import FavoriteButton from '../../favorite_button';
import ResponsiveAvatar from '../../responsive_avatar';

function PrUserCard({
                      prUser,
                      updates,
                    }) {
  // Late arriving attributes to be merged in, if any
  if (updates) Object.assign(prUser, updates.attributes);
  const maxHashtags = 5;

  let titleHtml = null;
  if (prUser.current_positions && prUser.current_positions[0]) {
    titleHtml = (
      <div className="card-grid-subtitle font-size-12px">
        {prUser.current_positions[0].title} at&nbsp;
        <a href={prUser.current_positions[0].company_path}>
          {prUser.current_positions[0].company}
        </a>
      </div>
    );
  }

  return (
    <div className="card h-100 pr-user-card animated faster fadeIn">
      <div className="card-body position-relative">
        <ResponsiveAvatar
          avatarUrl={prUser.avatar_url}
          avatarLetters={prUser.initials}
          className="mb-2"
        />

        <a href={prUser.pr_user_path} className="font-weight-bold cursor-pointer">
          {prUser.full_name}
        </a>

        <div className="mb-2">
          {titleHtml}
        </div>

        <Hashtags
          hashtagTarget={prUser}
          maxHashtags={maxHashtags}
          title={null}
          clickEventName="PrUsers/PrUserCard#clickHashtag"
        />

        {prUser.companies && (
          <div className="mb-2">
            Representing: <span dangerouslySetInnerHTML={{ __html: prUser.companies }} />
          </div>
        )}

        <div className="clearfix" style={{ minHeight: '2.0rem' }} />
        <div className="mt-4 mb-2 row w-100" style={{ position: 'absolute', bottom: '0' }}>
          <div className="col" />
          <div className="d-flex align-items-end mr-3">
            {prUser.favoriteable_id && (
              <FavoriteButton
                favoriteableId={prUser.favoriteable_id}
                favoriteableType="PrUser"
                favoriteId={prUser.favorite_id}
                favorited={prUser.favorited}
                ahoyObjectGid={prUser.object_gid}
              />
            )}
            {prUser.user && (
              <div className="ms-3 p-0 m-0">
                <a className="btn btn-sm btn-primary" href={`/users/${prUser.user.slug}/dm/new`}>
                  <i className="float-left fa fa-paper-plane text-white" />
                  <span className="ms-2">message {prUser.user.first_name}</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

PrUserCard.propTypes = {
  prUser: PropTypes.instanceOf(Object),
  updates: PropTypes.instanceOf(Object),
};

PrUserCard.defaultProps = {
  prUser: undefined,
  updates: undefined,
};

export default PrUserCard;
