import React, { useState } from 'react';
import PropTypes from 'prop-types';
import vapi from '../javascript/frontend/api/vapi';
import vahoy from '../javascript/vahoy';

function NewMediaApprovalButton({ reporterId, approved: approvedProp, ahoyObjectGid }) {
  const [approved, setApproved] = useState(approvedProp);

  const approve = () => {
    vapi.approveNewMedia(reporterId)
      .then(() => {
        setApproved(true);
        vahoy.track('ApproveNewMediaButton#approve', { object_gid: ahoyObjectGid });
      })
      .catch((error) => {
        throw new Error(`ApproveNewMediaButton#approve: ${error}`);
      });
  };

  const unapprove = () => {
    vapi.unapproveNewMedia(reporterId)
      .then(() => {
        setApproved(false);
        vahoy.track('UnapproveNewMediaButton#approve#unapprove', { object_gid: ahoyObjectGid });
      })
      .catch((error) => {
        throw new Error(`UnapproveNewMediaButton#unapprove: ${error}`);
      });
  };

  const renderApproveButton = () => (
    <button
      type="button"
      onClick={approve}
      style={{ verticalAlign: 'middle' }}
      className="btn btn-outline text-garish border rounded rounded-lg px-3 border-garish animated faster fadeIn shadow-sm"
    >
      <i
        className="text-garish fa-regular fa-circle-check me-2 fa-lg"
        title="Approve for new media"
      />
      Approve for New Media Listing

    </button>
  );

  const renderUnapproveButton = () => (
    <button
      type="button"
      onClick={unapprove}
      style={{ verticalAlign: 'middle' }}
      className="btn btn-outline bg-garish text-white border rounded rounded-lg px-3 animated faster fadeIn"
    >
      <i
        className="text-white fa-solid fa-circle-check me-2 fa-lg"
        title="Unapprove"
      />
      Approved for New Media Listing
    </button>
  );

  return approved ? renderUnapproveButton() : renderApproveButton();
}

NewMediaApprovalButton.propTypes = {
  reporterId: PropTypes.number.isRequired,
  approved: PropTypes.bool.isRequired,
  ahoyObjectGid: PropTypes.string.isRequired,
};

export default NewMediaApprovalButton;
