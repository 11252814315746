import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {

  getSourceHeadshots(sourceId) {
    api.configure();

    // TODO: I'm not sure why this is necessary, the source headshots API call is being Conditional Get (304)
    // cached in production environment ...
    const cachebuster = Math.round(new Date().getTime() / 1000);

    const url = `/api/internal/jsonapi/sources/${sourceId}/source-headshots?cachebuster=${cachebuster}`;

    vlog.info(`api#getSourceHeadshots: ${url}`);
    return axios.get(url, api.axiosFix);
  },

  createSourceHeadshot(sourceId, metadataCloudinary) {
    vlog.info('api#createSourceHeadshot');
    api.configure();

    const url = '/api/internal/jsonapi/source-headshots';

    const data = {
      type: 'source_headshots',
      attributes: {
        source_id: sourceId,
        headshot_cloudinary_metadata: metadataCloudinary,
      },
    };

    return axios.post(url, { data }, api.axiosFix);
  },

  deleteSourceHeadshot(SourceHeadshotId) {
    vlog.info('api#deleteSourceHeadshot');

    api.configure();

    const url = `/api/internal/jsonapi/source-headshots/${SourceHeadshotId}`;

    return axios.delete(url, api.axiosFix);
  },

});
