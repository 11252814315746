import PropTypes from 'prop-types';
import React, { useState } from 'react';

import AdminModal from './admin_modal';
import PublicPitch from './public_pitch';

// Renders the view of a public pitch with admin popup
function PublicPitchWithAdmin({
 canSendMessages, currentUserSlug, defaultConversationStartPath, publicPitch,
}) {
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);

  const toggleAdmin = (e) => {
    e.preventDefault();
    setIsAdminModalOpen(!isAdminModalOpen);
  };

  const pitchComponent = (
    <PublicPitch
      canSendMessages={canSendMessages}
      currentUserAdmin={true}
      currentUserSlug={currentUserSlug}
      defaultConversationStartPath={defaultConversationStartPath}
      isAdminModalOpen={isAdminModalOpen}
      publicPitch={publicPitch}
      toggleAdmin={toggleAdmin}
    />
  );

  return (
    <>
      {pitchComponent}
      <AdminModal
        hashtags={publicPitch.hashtags}
        isOpen={isAdminModalOpen}
        onCancel={toggleAdmin}
        pitchId={publicPitch.pitchId}
        pitchRequestId={publicPitch.pitchRequestId}
        pitchText={publicPitch.pitchText}
      >
        <div className="d-flex justify-content-center">{pitchComponent}</div>
      </AdminModal>
    </>
  );
}

PublicPitchWithAdmin.propTypes = {
  canSendMessages: PropTypes.bool.isRequired,
  currentUserSlug: PropTypes.string,
  defaultConversationStartPath: PropTypes.string,
  publicPitch: PropTypes.shape({
    avatarLetters: PropTypes.string,
    avatarUrl: PropTypes.string,
    companyName: PropTypes.string,
    companyPath: PropTypes.string,
    contactPath: PropTypes.string,
    employmentsString: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    hashtags: PropTypes.arrayOf(
      PropTypes.shape({
        hashtag: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ).isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    pitchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    pitchRequestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    pitchText: PropTypes.string.isRequired,
    publicPitchLede: PropTypes.string,
    publicPitchPreamble: PropTypes.string,
    publicPitchText: PropTypes.string.isRequired,
    publicPitchTitle: PropTypes.string.isRequired,
    schemaVersion: PropTypes.number.isRequired,
    sourcePath: PropTypes.string,
  }).isRequired,
};

PublicPitchWithAdmin.defaultProps = {
  currentUserSlug: null,
  defaultConversationStartPath: null,
};

export default PublicPitchWithAdmin;
