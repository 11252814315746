import React from 'react';

const emptyResults = () => (
  <div className="card w-75 mx-auto mt-4">
    <div className="card-body">
      <div className="card-title">
        <h4 className="font-weight-bold">No sources found matching this search.</h4>
      </div>
      <hr />
      <p>
        Our source database is a fraction of Qwoted's global expert network. Many of our users haven't taken the
        time to
        proactively create source profiles for themselves, colleagues and clients. We almost always have an expert
        that
        can speak to you about the subject you're researching.
      </p>
      <div className="alert alert-info">
        The easiest way to reach them is to <a href="source_requests/new" className="font-weight-bold">create a
        source request
      </a>, which will
        only be delivered to relevant sources in our network.
      </div>
      <hr />
      <p className="text-info">
        <em>
          Still having trouble finding a source?&nbsp;
          <a href={`mailto:${window.SUPPORT_EMAIL}`} className="font-weight-bold">Get in touch</a> and one of our
          research assistants will be happy to help connect you with relevant experts.
        </em>
      </p>
    </div>
  </div>
);

export default emptyResults;
