import React from 'react';
import {
  FormGroup, Modal, ModalBody, Label, Input, ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import vapi from '../javascript/frontend/api/vapi';
import vahoy from '../javascript/vahoy';

function Select2AddCompanyModal(props) {
  const {
    showModal, modalTitle, placeHolderText, labelText, onSelect, onHide,
  } = props;

  // eslint-disable-next-line react/destructuring-assignment
  const [searchingTerm, setSearchingTerm] = React.useState(props.searchingTerm);

  // eslint-disable-next-line react/destructuring-assignment
  const [companies, setCompanies] = React.useState(props.companies || []);

  React.useEffect(() => {
    if (searchingTerm && showModal) {
      const loadCompanies = async () => {
        const params = {
          'filter[name]': searchingTerm,
          'page[offset]': 0,
          'page[limit]': 2,
        };

        const response = await vapi.searchCompanies(params);
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.data) {
            setCompanies(responseData.data);
          }
        }
      };

      loadCompanies();
    }
  }, [searchingTerm, showModal]);

  React.useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment
    setSearchingTerm(props.searchingTerm);
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.searchingTerm]);

  const selectCompany = (company) => {
    onSelect && onSelect(company.id, company.attributes.name, company);
    vahoy.track('Select2AddCompanyModal#selectCompany');
  };

  const addNewCompany = () => {
    onSelect && onSelect('new', searchingTerm);
    vahoy.track('Select2AddCompanyModal#addNewCompany');
  };

  return (
    <Modal
      id="add_company_modal"
      className="modal-lg select2-add-company-modal"
      isOpen={showModal}
      toggle={onHide}
    >
      <ModalBody>
        <div className="d-flex justify-content-between">
          <div className="flex-grow-1">
            <div className="h3 mt-2 fw-bold mb-3 mb-4 pb-1">{modalTitle || 'Add new company'}</div>
            <div className="pe-4 me-3">
              <form>
                <FormGroup>
                  <Label for="searchPublicationName">{labelText}</Label>
                  <Input
                    id="searchPublicationName"
                    type="text"
                    value={searchingTerm || ''}
                    placeholder={placeHolderText || 'New Company Name'}
                    name="term"
                    onChange={(evt) => setSearchingTerm(evt.target.value)}
                  />
                </FormGroup>
              </form>
              {companies && companies.length > 0
                && (
                  <div className="companies-container">
                    <div className="mb-2">Did you mean?</div>
                    <div className="ps-5 ms-3">
                      {companies.map((company) => (
                        <div
                          key={company.id}
                          className="company d-flex cursor-pointer"
                          role="button"
                          tabIndex={-1}
                          onClick={() => selectCompany(company)}
                          onKeyPress={(evt) => evt.key == 'enter' && selectCompany(company)}
                        >
                          <div className="company-image">
                            <img src={company.attributes.image_cloudinary_url} alt="" />
                          </div>
                          <div className="company-name">
                            {company.attributes.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              <div className="clearfix" />
            </div>
          </div>

          <button type="button" className="btn-close" aria-label="Close" onClick={onHide} />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="text-end pe-4 me-3">
          <button type="button" className="btn btn-primary pe-5 ps-5" onClick={() => addNewCompany()}>
            {modalTitle}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

Select2AddCompanyModal.propTypes = {
  showModal: PropTypes.bool,
  modalTitle: PropTypes.string,
  placeHolderText: PropTypes.string,
  labelText: PropTypes.string,
  searchingTerm: PropTypes.string,
  companies: PropTypes.arrayOf(PropTypes.object),
  onHide: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};

Select2AddCompanyModal.defaultProps = {
  showModal: undefined,
  modalTitle: undefined,
  placeHolderText: undefined,
  labelText: undefined,
  searchingTerm: undefined,
  companies: [],
  onSelect: undefined,
};

export default Select2AddCompanyModal;
