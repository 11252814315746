import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import dateHelpers from '../../../javascript/frontend/helpers/date_helpers';

function Datestamps({ sourceRequest, smallMode }) {
  const [deadlineTooltipOpen, setDeadlineTooltipOpen] = useState(false);
  const [filedTooltipOpen, setFiledTooltipOpen] = useState(false);

  let deadline;
  let filed;
  const exposure = sourceRequest.request_type_text === 'exposure';
  const deadlineTooltipTargetRef = useRef();
  const filedTooltipTargetRef = useRef();

  if (sourceRequest.want_pitches
    && !exposure
    && sourceRequest.source_request_submit_date
    && !sourceRequest.current_user_pitch_status) {
    let htmlClass = '';
    const currentTimestamp = (new Date()).getTime();
    const next6HoursTimestamp = currentTimestamp + 60 * 60 * 6 * 1000;
    const deadlineTimestamp = (new Date(sourceRequest.source_request_submit_date)).getTime();

    if (deadlineTimestamp >= currentTimestamp && deadlineTimestamp <= next6HoursTimestamp) {
      htmlClass = 'text-warning fst-italic';
    } else if (deadlineTimestamp < currentTimestamp) {
      htmlClass = 'text-danger fst-italic';
    }

    deadline = (
      <>
        <div className={`${htmlClass} font-size-12px source-request-deadline`} ref={deadlineTooltipTargetRef}>
          <strong>Deadline:&nbsp;</strong>
          {dateHelpers.timeAgoInWords(sourceRequest.source_request_submit_date)}&nbsp;
          <i className={`fa fa-circle-info ${htmlClass} fst-normal`} />
        </div>
        <Tooltip
          placement="top"
          isOpen={deadlineTooltipOpen}
          target={deadlineTooltipTargetRef}
          toggle={() => setDeadlineTooltipOpen(!deadlineTooltipOpen)}
        >
          Deadline: {dateHelpers.formatDatetime(sourceRequest.source_request_submit_date, 'h:mm a MMM D')}
        </Tooltip>
      </>
    );
  }

  if (sourceRequest.published_at) {
    const filedLabel = !exposure ? 'Filed' : 'Published';

    filed = (
      <>
        { !exposure && (
          <div className="text-secondary font-size-12px source-request-created-at" ref={filedTooltipTargetRef}>
            <strong>{ filedLabel }:&nbsp;</strong>
            <span>{dateHelpers.timeAgoInWords(sourceRequest.published_at)}&nbsp;</span>
            <i className="fa fa-circle-info text-secondary" />
          </div>
        )}

        { exposure && (
          <div className="text-secondary font-size-12px source-request-created-at" ref={filedTooltipTargetRef}>
            <span className="font-size-14px fw-bold me-1 text-uppercase">{ filedLabel }:&nbsp;</span>
            <span className="text-uppercase">{dateHelpers.timeAgoInWords(sourceRequest.published_at)}&nbsp;</span>
            <i className="fa fa-circle-info text-secondary" />
          </div>
        )}
        <Tooltip
          placement="top"
          isOpen={filedTooltipOpen}
          target={filedTooltipTargetRef}
          toggle={() => setFiledTooltipOpen(!filedTooltipOpen)}
        >
          { filedLabel }: {dateHelpers.formatDatetime(sourceRequest.published_at, 'ddd MMM D')}
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <div className="clearfix" style={{ minHeight: smallMode ? '2.75rem' : '3.0rem' }} />
      <div className="mt-4 mb-2 row" style={{ position: 'absolute', bottom: '0' }}>
        <div>
          {filed}
        </div>
        {!exposure && (
          <div>
            {deadline}
          </div>
        )}
      </div>
    </>
  );
}

Datestamps.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object).isRequired,
  smallMode: PropTypes.bool,
};

Datestamps.defaultProps = {
  smallMode: false,
};

export default Datestamps;
