import React from 'react';
import {
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

function LocationFilterPanel({ defaultRefinement }) {
  return (
    <div className="card bg-transparent border-0 mb-3">
      <div className="card-body ais-hide-counts">
        <Panel header="Filter by location">
          <RefinementList
            attribute="location_string"
            defaultRefinement={defaultRefinement}
            limit={6}
            showMore={true}
            searchable={true}
          />
        </Panel>
      </div>
    </div>
  );
}

LocationFilterPanel.propTypes = {
  defaultRefinement: PropTypes.instanceOf(Object),
};

LocationFilterPanel.defaultProps = {
  defaultRefinement: '',
};

export default LocationFilterPanel;
