import React from 'react';
import PropTypes from 'prop-types';

function FieldDetails({
                        details,
                        onBlur,
                        requestType,
                        setDetails,
                      }) {
  let label;
  let placeholder;
  switch (requestType) {
    case 'story_ideas':
      label = 'Add a description';
      placeholder = 'Be as specific as possible about the type of story idea you need!';
      break;
    case 'guests':
      label = 'Add a description';
      placeholder = 'Be as specific as possible about the type of guest you need!';
      break;
    case 'speakers':
      label = 'Add a description';
      placeholder = 'Be as specific as possible about the type of speaker you need!';
      break;
    case 'products':
      label = 'Add a description';
      placeholder = 'Be as specific as possible about the type of products you need!';
      break;
    case 'man_on_the_street':
      label = 'Add a description';
      placeholder = 'Be as specific as possible about the type of source you need!';
      break;
    default: // also 'experts'
      label = 'Add a description';
      placeholder = 'Be as specific as possible about the type of expertise you need!';
      break;
  }

  return (
    <div className="mb-3">
      <label htmlFor="details" className="fw-bold">
        {label}
      </label>
      <textarea
        id="details"
        name="details"
        className="w-100 form-control"
        rows="6"
        placeholder={placeholder}
        onBlur={() => onBlur()}
        onInput={(e) => setDetails(e.target.value)}
        defaultValue={details}
      />
    </div>
  );
}

FieldDetails.propTypes = {
  details: PropTypes.string,
  onBlur: PropTypes.func,
  requestType: PropTypes.string,
  setDetails: PropTypes.func,
};

FieldDetails.defaultProps = {
  details: undefined,
  onBlur: undefined,
  requestType: undefined,
  setDetails: undefined,
};

export default FieldDetails;
