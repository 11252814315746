import React from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import LoadingImage from './loading_image';

function ConfirmModal(props) {
  let modalHeader;
  let okButton;
  let noButton;
  const {
    confirmYes, confirmYesText, confirmYesButtonClassName,
    confirmNo, confirmNoText, confirmNoButtonClassName,
    className, modalBodyClassName, modalFooterClassName,
    confirmHeaderContent, confirmContent,
    isOpening, isSubmitting,
  } = props;

  if (confirmYes) {
    if (isSubmitting) {
      okButton = (
        <LoadingImage height="36" />
      );
    } else {
      const txt = confirmYesText || 'Ok';
      okButton = (
        <Button
          color="primary"
          onClick={() => confirmYes()}
          className={`btn-confirm ${confirmYesButtonClassName || ''}`}
        >
          {txt}
        </Button>
      );
    }
  }

  if (confirmNo) {
    const noButtonText = confirmNoText || 'Cancel';

    noButton = (
      <button
        type="button"
        onClick={() => confirmNo()}
        className={`btn ${confirmNoButtonClassName || ''}`}
      >
        {noButtonText}
      </button>
    );
  }

  if (confirmHeaderContent) {
    modalHeader = (
      <ModalHeader>{confirmHeaderContent}</ModalHeader>
    );
  }

  return (
    <Modal isOpen={isOpening} className={className || ''}>
      {modalHeader}
      <ModalBody className={modalBodyClassName || ''}>
        {confirmContent}
      </ModalBody>
      <ModalFooter className={modalFooterClassName || ''}>
        {okButton}
        {noButton}
      </ModalFooter>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  className: PropTypes.string,
  confirmContent: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  confirmHeaderContent: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  confirmNo: PropTypes.func,
  confirmNoButtonClassName: PropTypes.string,
  confirmNoText: PropTypes.string,
  confirmYes: PropTypes.func,
  confirmYesButtonClassName: PropTypes.string,
  confirmYesText: PropTypes.string,
  isOpening: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  modalBodyClassName: PropTypes.string,
  modalFooterClassName: PropTypes.string,
};

ConfirmModal.defaultProps = {
  className: undefined,
  confirmContent: undefined,
  confirmHeaderContent: undefined,
  confirmNo: undefined,
  confirmNoButtonClassName: undefined,
  confirmNoText: undefined,
  confirmYes: undefined,
  confirmYesButtonClassName: undefined,
  confirmYesText: undefined,
  isOpening: undefined,
  isSubmitting: undefined,
  modalBodyClassName: undefined,
  modalFooterClassName: undefined,
};

export default ConfirmModal;
