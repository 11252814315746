import vapi from '../api/vapi';
import cookieHelpers from './cookie_helpers';
import activeSupportTimeZoneCompleteMapping from '../etc/activesupport_timezone_complete_mapping';

// How this works: purpose here is to synchronize the timezone between the client and the server:
// 1) The server timezone is passed to this method.
// 2) The JS uses moment.tz.guess(); to get the timezone from the client browser.
// 3) If the client timezone does not match the server timezone, the server timezone is updated via an API call, and
//    then the page is reloaded.
// 4) Once the client & server timezones match, the client timezone is set to the server timezone so that there is a
//    single source of truth.  This also allows the client timezone (from the browser) to be ignored & this forces the
//    timezone for the whole system to the server timezone.

const timezoneHelpers = {

  // 1) The server timezone is passed to this method.
  setupTimezone(serverTimezoneRails, confirmTimezoneChange) {
    // 2) The JS uses moment.tz.guess(); to get the timezone from the client browser.
    // this is a timezone code which matches standardized timezone databases
    const timezoneTzIdentifier = moment.tz.guess();
    const mappedTimezone = activeSupportTimeZoneCompleteMapping[timezoneTzIdentifier];

    if (mappedTimezone) {
      const timezoneTzIdentifierMappedToTimezoneRails = mappedTimezone.split(', ')[0];
      const user = {
        id: window.CURRENT_USER_JSON.id,
        attributes: {
          time_zone_rails: timezoneTzIdentifierMappedToTimezoneRails,
        },
      };

      // TODO: figure out something about popups in tests
      if (window.RAILS_ENV !== 'test') {
        const ignoredTimeZoneChange = cookieHelpers.getCookie('ignore_time_zone_change') === timezoneTzIdentifierMappedToTimezoneRails;

        if (!ignoredTimeZoneChange && timezoneTzIdentifierMappedToTimezoneRails !== serverTimezoneRails) {
          const confirmation = !confirmTimezoneChange || window.confirm(`Change time zone to '${timezoneTzIdentifierMappedToTimezoneRails}'?`);
          if (confirmation) {
            // 3) If the client timezone does not match the server timezone, the server timezone is updated via an API call, and
            //    then the page is reloaded.
            vapi.updateUser(user).then(() => window.location.reload());
          } else {
            const rememberChoiceToIgnoreTimeZoneChangeDays = 7;
            alert(`Your choice to ignore the '${timezoneTzIdentifierMappedToTimezoneRails}' timezone will be remembered for ${rememberChoiceToIgnoreTimeZoneChangeDays} days.`);
            cookieHelpers.setCookie(
              'ignore_time_zone_change',
              timezoneTzIdentifierMappedToTimezoneRails,
              rememberChoiceToIgnoreTimeZoneChangeDays,
            );
          }
        }
      }
    } else if (window.RAILS_ENV !== 'test') {
      window.Rollbar && window.Rollbar.error(`Guessed time zone is not found: ${timezoneTzIdentifier}`);
    }

    window.TIME_ZONE_RAILS_HUMAN_READABLE = serverTimezoneRails;

    // Here we resolve problems with Daylight Savings.  This will probably need to be corrected in the future to account
    // for those places that legitimately do not have Daylight savings.
    //
    // NOTE: activeSupportTimeZoneCompleteMapping has many keys with the same values.
    //       With the same value, some keys is Daylight Saving Time, some are not.
    //       Here I favor key that has Daylight Saving Time.
    /*
      {
        "London":["Africa/Abidjan","Africa/Accra","Africa/Bamako","Africa/Banjul","Africa/Bissau","Africa/Conakry","Africa/Dakar","Africa/El_Aaiun","Africa/Freetown","Africa/Lome","Africa/Nouakchott","Africa/Ouagadougou","Africa/Sao_Tome","America/Danmarkshavn","Antarctica/Vostok","Atlantic/Canary","Atlantic/Faroe","Atlantic/Madeira","Atlantic/Reykjavik","Atlantic/St_Helena","Europe/Guernsey","Europe/Isle_of_Man","Europe/Jersey"],
        "Moscow":["Africa/Addis_Ababa","Africa/Asmara","Africa/Dar_es_Salaam","Africa/Djibouti","Africa/Kampala","Africa/Khartoum","Africa/Mogadishu","Antarctica/Syowa","Asia/Aden","Asia/Bahrain","Asia/Qatar","Indian/Antananarivo","Indian/Comoro","Indian/Mayotte"],
        "Paris":["Africa/Algiers","Africa/Bangui","Africa/Brazzaville","Africa/Ceuta","Africa/Douala","Africa/Kinshasa","Africa/Lagos","Africa/Libreville","Africa/Luanda","Africa/Malabo","Africa/Ndjamena","Africa/Niamey","Africa/Porto-Novo","Africa/Tunis","Africa/Windhoek","Arctic/Longyearbyen","Europe/Andorra","Europe/Gibraltar","Europe/Luxembourg","Europe/Malta","Europe/Monaco","Europe/Oslo","Europe/Paris","Europe/Podgorica","Europe/San_Marino","Europe/Tirane","Europe/Vaduz","Europe/Vatican"],
        "Cairo":["Africa/Blantyre","Africa/Bujumbura","Africa/Cairo","Africa/Gaborone","Africa/Kigali","Africa/Lubumbashi","Africa/Lusaka","Africa/Maputo","Africa/Maseru","Africa/Mbabane","Africa/Tripoli","Asia/Amman","Asia/Beirut","Asia/Damascus","Asia/Gaza","Asia/Nicosia","Europe/Chisinau","Europe/Mariehamn","Europe/Simferopol","Europe/Uzhgorod","Europe/Zaporozhye"],
        "Hawaii":["America/Adak","Pacific/Honolulu","Pacific/Johnston","Pacific/Rarotonga","Pacific/Tahiti"],
        "Alaska":["America/Anchorage","America/Juneau","America/Nome","America/Yakutat","Pacific/Gambier"],
        "Atlantic Time (Canada)":["America/Anguilla","America/Antigua","America/Argentina/San_Luis","America/Aruba","America/Asuncion","America/Barbados","America/Blanc-Sablon","America/Boa_Vista","America/Campo_Grande","America/Cuiaba","America/Curacao","America/Dominica","America/Eirunepe","America/Glace_Bay","America/Goose_Bay","America/Grenada","America/Guadeloupe","America/Halifax","America/Manaus","America/Marigot","America/Martinique","America/Moncton","America/Montserrat","America/Port_of_Spain","America/Porto_Velho","America/Puerto_Rico","America/Rio_Branco","America/Santo_Domingo","America/St_Barthelemy","America/St_Kitts","America/St_Lucia","America/St_Thomas","America/St_Vincent","America/Thule","America/Tortola","Antarctica/Palmer","Atlantic/Bermuda","Atlantic/Stanley"],
        "Buenos Aires":["America/Araguaina","America/Argentina/Buenos_Aires","America/Argentina/Catamarca","America/Argentina/Cordoba","America/Argentina/Jujuy","America/Argentina/La_Rioja","America/Argentina/Mendoza","America/Argentina/Rio_Gallegos","America/Argentina/Salta","America/Argentina/San_Juan","America/Argentina/Tucuman","America/Argentina/Ushuaia","America/Bahia","America/Belem","America/Cayenne","America/Fortaleza","America/Maceio","America/Miquelon","America/Paramaribo","America/Recife","America/Santarem","Antarctica/Rothera"],
        "Eastern Time (US & Canada)":["America/Atikokan","America/Cayman","America/Detroit","America/Grand_Turk","America/Guayaquil","America/Havana","America/Indiana/Marengo","America/Indiana/Petersburg","America/Indiana/Vevay","America/Indiana/Vincennes","America/Indiana/Winamac","America/Iqaluit","America/Jamaica","America/Kentucky/Louisville","America/Kentucky/Monticello","America/Montreal","America/Nassau","America/New_York","America/Nipigon","America/Panama","America/Pangnirtung","America/Port-au-Prince","America/Resolute","America/Thunder_Bay","America/Toronto"],
        "Central Time (US & Canada)":["America/Belize","America/Cancun","America/Chicago","America/Costa_Rica","America/El_Salvador","America/Indiana/Knox","America/Indiana/Tell_City","America/Managua","America/Matamoros","America/Menominee","America/Merida","America/North_Dakota/Center","America/North_Dakota/New_Salem","America/Rainy_River","America/Rankin_Inlet","America/Swift_Current","America/Tegucigalpa","America/Winnipeg","Pacific/Easter","Pacific/Galapagos"],
        "Mountain Time (US & Canada)":["America/Boise","America/Cambridge_Bay","America/Dawson_Creek","America/Denver","America/Edmonton","America/Hermosillo","America/Inuvik","America/Ojinaga","America/Shiprock","America/Yellowknife"],
        "Pacific Time (US & Canada)":["America/Dawson","America/Los_Angeles","America/Santa_Isabel","America/Vancouver","America/Whitehorse","Pacific/Pitcairn"],
        "Indiana (East)":["America/Indianapolis","America/Indiana/Indianapolis"],
        "Mid-Atlantic":["America/Noronha","Atlantic/South_Georgia"],
        "Cape Verde Is.":["America/Scoresbysund","Atlantic/Cape_Verde"],
        "Hong Kong":["Antarctica/Casey","Asia/Brunei","Asia/Choibalsan","Asia/Harbin","Asia/Hong_Kong","Asia/Kashgar","Asia/Kuching","Asia/Macau","Asia/Makassar","Asia/Manila"],
        "Jakarta":["Antarctica/Davis","Asia/Ho_Chi_Minh","Asia/Hovd","Asia/Jakarta","Asia/Phnom_Penh","Asia/Pontianak","Asia/Saigon","Asia/Vientiane","Indian/Christmas"],
        "Sydney":["Antarctica/DumontDUrville","Asia/Sakhalin","Australia/Currie","Australia/Lindeman","Australia/Sydney","Pacific/Saipan","Pacific/Truk"],
        "Dhaka":["Antarctica/Mawson","Asia/Bishkek","Asia/Novokuznetsk","Asia/Omsk","Asia/Qyzylorda","Asia/Thimphu","Indian/Chagos"],
        "Auckland":["Antarctica/McMurdo","Antarctica/South_Pole","Pacific/Funafuti","Pacific/Kwajalein","Pacific/Nauru","Pacific/Tarawa","Pacific/Wake","Pacific/Wallis"],
        "Solomon Is.":["Asia/Anadyr","Pacific/Efate","Pacific/Guadalcanal","Pacific/Kosrae","Pacific/Ponape"],
        "Karachi":["Asia/Aqtau","Asia/Aqtobe","Asia/Ashgabat","Asia/Dushanbe","Asia/Oral","Asia/Samarkand","Indian/Kerguelen","Indian/Maldives"],
        "Baku":["Asia/Baku","Asia/Dubai","Indian/Mahe","Indian/Mauritius","Indian/Reunion"],
        "Kolkata, New Delhi":["Asia/Calcutta","Asia/Kolkata"],
        "Tokyo":["Asia/Dili","Asia/Jayapura","Asia/Pyongyang","Pacific/Palau"],
        "Rangoon":["Asia/Rangoon","Indian/Cocos"],
        "Adelaide":["Australia/Adelaide","Australia/Broken_Hill"],
        "Samoa":["Pacific/Apia","Pacific/Niue"],
        "Nuku'alofa":["Pacific/Enderbury","Pacific/Tongatapu"]
      }
    */
    const duplicateValues = {
      London: 'America/Danmarkshavn',
      Moscow: 'Antarctica/Syowa',
      Paris: 'Europe/Paris',
      Cairo: 'Africa/Cairo',
      Hawaii: 'America/Adak',
      Alaska: 'America/Yakutat',
      'Atlantic Time (Canada)': 'America/Moncton',
      'Buenos Aires': 'America/Argentina/Buenos_Aires',
      'Eastern Time (US & Canada)': 'America/New_York',
      'Central Time (US & Canada)': 'America/Chicago',
      'Mountain Time (US & Canada)': 'America/Denver',
      'Pacific Time (US & Canada)': 'America/Los_Angeles',
      'Indiana (East)': 'America/Indianapolis',
      'Mid-Atlantic': 'America/Noronha',
      'Cape Verde Is.': 'America/Scoresbysund',
      'Hong Kong': 'Asia/Hong_Kong',
      Jakarta: 'Asia/Jakarta',
      Sydney: 'Australia/Sydney',
      Dhaka: 'Asia/Bishkek',
      Auckland: 'Antarctica/McMurdo',
      'Solomon Is.': 'Asia/Anadyr',
      Karachi: 'Indian/Kerguelen',
      Baku: 'Asia/Baku',
      'Kolkata, New Delhi': 'Asia/Kolkata',
      Tokyo: 'Asia/Dili',
      Rangoon: 'Asia/Rangoon',
      Adelaide: 'Australia/Adelaide',
      Samoa: 'Pacific/Apia',
      "'Nuku'alofa'": 'Pacific/Enderbury',
    };

    const serverTimezoneRailsMappedToTzIdentifier = duplicateValues[serverTimezoneRails]
      || _.findKey(
        activeSupportTimeZoneCompleteMapping,
        (item) => (item.indexOf(serverTimezoneRails) !== -1),
      );

    // 4) Once the client & server timezones match, the client timezone is set to the server timezone so that there is a
    //    single source of truth.  This also allows the client timezone (from the browser) to be ignored & this forces the
    //    timezone for the whole system to the server timezone.
    // access this via moment().tz();
    moment.tz.setDefault(serverTimezoneRailsMappedToTzIdentifier);
  },
};

window.timezoneHelpers = timezoneHelpers;

export default timezoneHelpers;
