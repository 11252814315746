import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import vlog from '../../javascript/vlog';
import SkeletonTable from './skeleton_table';
import FollowsTable from './follows_table';
import PaginationControls from './pagination_controls';
import TabNavigation from './tab_navigation';
import ConfigureSpamTolerance from './configure_spam_tolerance';
import { handleFollow, handleUnfollow, handleBlock } from './follow_actions';

function Following({
                     userId, profileId, authenticatedHeadersHash, setError,
                   }) {
  vlog.trace('Rendering Following component');

  const [myFollows, setMyFollows] = useState([]);
  const [myBlocked, setMyBlocked] = useState([]);
  const [myUnfollowed, setMyUnfollowed] = useState([]);
  const [activeTab, setActiveTab] = useState('following');
  const [loading, setLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [spamTolerance, setSpamTolerance] = useState(null);
  const [hashtagUsageCeiling, setHashtagUsageCeiling] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const limit = 30;

  useEffect(() => {
    async function fetchHashtagUsageCeiling() {
      vlog.trace('Fetching hashtag usage ceiling with spamTolerance:', spamTolerance);
      const { supabaseClient } = window;
      const { data, err } = await supabaseClient
        .from('notification_configurations')
        .select('hashtag_usage_ceiling')
        .eq('user_id', userId)
        .single();

      if (err) {
        vlog.error('Error fetching hashtag usage ceiling:', err);
      } else {
        vlog.debug('Fetched hashtag usage ceiling:', data.hashtag_usage_ceiling);
        setHashtagUsageCeiling(data.hashtag_usage_ceiling);
      }
    }

    fetchHashtagUsageCeiling();
  }, [spamTolerance, userId]);

  const fetchActiveTab = useCallback(
    async (page = 1) => {
      if (!hashtagUsageCeiling) return null;

      vlog.trace('Fetching data for active tab:', activeTab, 'at page:', page);
      setIsActionLoading(true);

      let followStatus = '';
      let url = '';

      if (activeTab === 'following') {
        followStatus = 'follow';
      } else if (activeTab === 'blocked') {
        followStatus = 'block';
      } else if (activeTab === 'unfollowed') {
        try {
          const response = await fetch(
            `${window.V2_URL}/match_feed/api/profiles/${profileId
            }/followed_hashtags?follow_status=unfollow&min_hashtag_count=${hashtagUsageCeiling + 1
            }&combine_conditions_with_or=true&page=${page}&limit=${limit}`,
            {
              method: 'GET',
              headers: new Headers({
                ...authenticatedHeadersHash,
                'Content-Type': 'application/json',
              }),
            },
          );

          if (response.ok) {
            const responseData = await response.json();

            // Mark the "too noisy" items (those exceeding the usage count ceiling) with an "isTooNoisy" flag
            const combinedData = responseData.data.map((item) => ({
              ...item,
              isTooNoisy: item.hashtag_usage_count > hashtagUsageCeiling, // Flag "too noisy" items based on usage count
            }));

            // Set merged data to myUnfollowedFollows
            setMyUnfollowed(combinedData);

            // Set the total pages based on the response's total count
            const totalItems = responseData.total;
            setTotalPages(Math.ceil(totalItems / limit));
            vlog.trace('Total items in Unfollowed tab:', totalItems, 'Total pages:', totalPages);
          } else {
            throw new Error('Network response was not ok.');
          }
        } catch (err) {
          vlog.error('Error fetching follows for Unfollowed tab:', err);
        } finally {
          setLoading(false);
          setIsActionLoading(false);
        }
        return;
      } else {
        vlog.warn('Unknown tab selected:', activeTab);
        return;
      }

      if (!url) {
        url = `${window.V2_URL}/match_feed/api/profiles/${profileId}/followed_hashtags?follow_status=${followStatus
        }&max_hashtag_count=${hashtagUsageCeiling}&page=${page}&limit=${limit}`;
      }

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: new Headers({
            ...authenticatedHeadersHash,
            'Content-Type': 'application/json',
          }),
        });

        if (response.ok) {
          const responseBody = await response.json();
          vlog.debug('Fetched data for tab:', activeTab, 'response size:', responseBody.data.length);

          if (activeTab === 'following') setMyFollows(responseBody.data);
          if (activeTab === 'blocked') setMyBlocked(responseBody.data);
          if (activeTab === 'unfollowed') setMyUnfollowed(responseBody.data);

          const totalItems = responseBody.total;
          setTotalPages(Math.ceil(totalItems / limit));
          vlog.trace('Total items:', totalItems, 'Total pages:', totalPages);
        } else {
          vlog.warn('Network response was not ok for URL:', url);
          throw new Error('Network response was not ok.');
        }
      } catch (err) {
        vlog.error('Error fetching follows for active tab:', err);
      } finally {
        setLoading(false);
        setIsActionLoading(false);
      }
    },
    [activeTab, hashtagUsageCeiling, authenticatedHeadersHash, profileId, totalPages],
  );

  useEffect(() => {
    vlog.trace('useEffect triggered by currentPage or fetchActiveTab');
    fetchActiveTab(currentPage);
  }, [fetchActiveTab, currentPage]);

  const handleTabChange = (tab) => {
    vlog.debug('Tab changed to:', tab);
    setActiveTab(tab);
    setCurrentPage(1);
    setTotalPages(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      vlog.trace('Page changed to:', newPage);
      setCurrentPage(newPage);
    } else {
      vlog.warn('Invalid page change attempt:', newPage);
    }
  };

  return (
    <div className="container-fluid mt-4 p-0">
      <div className="row position-relative">
        <div className="col-12 col-lg-9">
          <ConfigureSpamTolerance
            onChange={setSpamTolerance}
            userId={userId}
            authenticatedHeadersHash={authenticatedHeadersHash}
            setError={setError}
          />
        </div>
      </div>

      <div className="row position-relative">
        <div className="col-12 col-lg-9">
          <hr />
        </div>
      </div>

      <div className="row position-relative">
        <div className="col-12 col-lg-9">
          <h4 className="fw-bold">Hashtags you follow</h4>
          <p>
            Block or unfollow hashtags to customize your notifications. Hashtags with traffic exceeding{' '}
            {hashtagUsageCeiling} are automatically unfollowed based on your Notification Frequency setting.
          </p>
        </div>
        <div className="col-12 col-lg-9 position-relative">
          {isActionLoading && (
            <div
              className="overlay"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1000,
                pointerEvents: 'none',
              }}
            />
          )}
        </div>
        <div className="col-12 col-lg-9 position-relative">
          <TabNavigation
            activeTab={activeTab}
            onTabChange={handleTabChange}
            isLoading={isActionLoading}
            tabs={['following', 'blocked', 'unfollowed']}
          />

          {loading ? (
            <div className="my-4">
              <SkeletonTable />
            </div>
          ) : (
            <>
              {isActionLoading && (
                <div
                  className="spinner-border"
                  role="status"
                  style={{
                    position: 'absolute', right: '10px', top: '10px', width: '1.5rem', height: '1.5rem',
                  }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}

              <div style={{ pointerEvents: isActionLoading ? 'none' : 'auto', opacity: isActionLoading ? 0.5 : 1 }}>
                {activeTab === 'following' && (
                  <FollowsTable
                    follows={myFollows}
                    handleUnfollow={(type, id) => handleUnfollow(
type,
id,
profileId,
authenticatedHeadersHash,
setIsActionLoading,
fetchActiveTab,
currentPage,
)}
                    handleBlock={(type, id) => handleBlock(
type,
id,
profileId,
authenticatedHeadersHash,
setIsActionLoading,
fetchActiveTab,
currentPage,
)}
                  />
                )}
                {activeTab === 'blocked' && (
                  <FollowsTable
                    follows={myBlocked}
                    handleFollow={(type, id) => handleFollow(
type,
id,
profileId,
authenticatedHeadersHash,
setIsActionLoading,
fetchActiveTab,
currentPage,
)}
                    handleUnfollow={(type, id) => handleUnfollow(
type,
id,
profileId,
authenticatedHeadersHash,
setIsActionLoading,
fetchActiveTab,
currentPage,
)}
                  />
                )}
                {activeTab === 'unfollowed' && (
                  <FollowsTable
                    follows={myUnfollowed}
                    handleFollow={(type, id) => handleFollow(
type,
id,
profileId,
authenticatedHeadersHash,
setIsActionLoading,
fetchActiveTab,
currentPage,
)}
                    handleBlock={(type, id) => handleBlock(
type,
id,
profileId,
authenticatedHeadersHash,
setIsActionLoading,
fetchActiveTab,
currentPage,
)}
                  />
                )}

                {totalPages > 1 && (
                  <PaginationControls
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    isLoading={isActionLoading}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

Following.propTypes = {
  authenticatedHeadersHash: PropTypes.object.isRequired,
  profileId: PropTypes.number.isRequired,
  setError: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default Following;
