/* eslint-disable react/forbid-prop-types,react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { Spinner } from 'reactstrap';
import _ from 'lodash';
import UpgradeSuccessModal from './_upgrade_success_modal';
import validationHelpers from '../../javascript/frontend/helpers/validation_helpers';
import vapi from '../../javascript/frontend/api/vapi';
import vahoy from '../../javascript/vahoy';
import gaTracking from '../../javascript/ga_tracking';

const unknownMessage = 'Something went wrong, please try again later.';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Arial, sans-serif',
      color: '#495057',
      fontSmoothing: 'subpixel-antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#FF4074',
      iconColor: '#FF4074',
    },
  },
};

class BillingAccountForm extends React.Component {
  constructor(props) {
    super(props);
    const {
      defaultName,
      defaultCompany,
      defaultEmail,
      accountCode,
    } = this.props;

    this.state = {
      accountCode,
      fields: {
        billingCompany: defaultCompany || '',
        billingName: defaultName,
        billingEmail: defaultEmail,
      },
      fieldErrors: {},
      fieldErrorMessages: {},
      generalErrorTitle: '',
      generalErrorMessage: '',
      disableInput: false,
      isProcessing: false,
      formIsEmpty: true,
      isFinished: false,
      referral: '',
    };
  }

  componentDidMount() {
    if (typeof window.rewardful === 'function') {
      window.rewardful('ready', () => {
        this.setState({ referral: window.Rewardful.referral });
      });
    }
  }

  validateFields = (eventOrStripeResult = null, apiError = null) => {
    const {
      fields,
      fieldErrors,
    } = this.state;
    if (!fields.billingName) fieldErrors.billingName = 'Please provide a name.';
    if (!validationHelpers.isValidEmail(fields.billingEmail)) fieldErrors.billingEmail = 'Please provide a valid email address.';

    // Special handling for the Stripe card element that does its
    // own validation as the user types
    if (eventOrStripeResult && eventOrStripeResult.error) {
      fieldErrors.paymentCard = eventOrStripeResult.error.message;
    }

    if (apiError && apiError.response && apiError.response.data) {
      fieldErrors.paymentCard = apiError.response.data.message;
    }

    this.setState({ fieldErrors });

    return fieldErrors;
  };

  formIsInvalid = () => {
    // Make a copy of the in-memory field errors into the
    // error messages state value for display
    // Return true if the form is invalid
    const { fieldErrors } = this.state;
    const fieldErrorMessages = _.cloneDeep(fieldErrors);
    this.setState({ fieldErrorMessages });
    return !!Object.keys(fieldErrors).length;
  };

  clearGeneralError = () => {
    this.setState({
      generalErrorTitle: '',
      generalErrorMessage: '',
    });
  };

  disableInput = (disableInput) => {
    this.setState({ disableInput });
  };

  setProcessingState = (isProcessing) => {
    this.setState({ isProcessing }, () => {
      this.disableInput(isProcessing);
    });
  };

  reportSuccess = () => {
    const { accountCode } = this.state;

    this.setProcessingState(false);
    this.disableInput(true);

    // Presence of accountCode indicates we did a payment method update versus
    // creating a new billing account. If we just updated the payment, there's no
    // need to do isFinished, which displays the success modal. Instead, just immediately
    // do the onSuccess callback and move the the successURL
    if (accountCode) {
      const {
        onSuccess,
        successUrl,
      } = this.props;
      onSuccess();
      window.location.replace(successUrl);
    } else {
      this.setState({ isFinished: true });
    }
  };

  displayGeneralError = (message, title = null) => {
    const generalErrorTitle = title || 'Oops!';
    const generalErrorMessage = message;
    this.setState({
      generalErrorTitle,
      generalErrorMessage,
    });
    this.setProcessingState(false);

    const { pageName } = this.props;
    gaTracking.trackSignUp(pageName, 'failStripePayment', 'generalError', generalErrorTitle, message);
    vahoy.track('NewBillingAccount#failStripePaymentWithGeneralError');
  };

  displayStripeError = (result = null, apiError = null) => {
    const fieldErrors = this.validateFields(result, apiError) || {};
    const { pageName } = this.props;
    this.formIsInvalid();
    this.setProcessingState(false);

    gaTracking.trackSignUp(pageName, 'failStripePayment', 'stripeError', JSON.stringify(fieldErrors));
    vahoy.track('NewBillingAccount#failStripePaymentWithStripeError');
  };

  displayServerValidationErrors = (errors) => {
    const messages = [];

    // eslint-disable-next-line guard-for-in
    for (const key in errors) {
      const values = errors[key];

      if (values) {
        messages.push(`${_.startCase(key)} ${values.join(', ')}`);
      }
    }

    this.displayGeneralError(messages.join('. '));
  };

  onSuccessDialogDismiss = () => {
    const {
      onSuccess,
      successUrl,
      pageName,
    } = this.props;
    this.setState({ isFinished: false });
    onSuccess();
    gaTracking.trackSignUp(pageName, 'closeSuccessDialog');
    vahoy.track('NewBillingAccount#closeSuccessDialog');
    window.location.replace(successUrl);
  };

  onInputChange = (e) => {
    const {
      fields,
      fieldErrors,
      fieldErrorMessages,
    } = this.state;
    const formIsEmpty = false;
    let fieldName;
    let fieldValue;
    let eventOrStripeResult;

    // The Stripe card element doesn't conform to standard
    // inputs, so normalize the name and value here
    if (e.target) {
      eventOrStripeResult = {};
      fieldName = e.target.name;
      fieldValue = e.target.value;
    } else {
      fieldName = 'paymentCard';
      fieldValue = undefined; // Value is unused for Stripe card element
      eventOrStripeResult = e;
    }

    // Set the field value
    fields[fieldName] = fieldValue;

    // Clear any errors for the field both for errors (in memory)
    // and the messages (those displayed)
    delete fieldErrors[fieldName];
    delete fieldErrorMessages[fieldName];

    // Put it all into state
    this.setState({
      fields,
      fieldErrors,
      fieldErrorMessages,
      formIsEmpty,
    }, () => {
      // Run field-level validations once state is updated
      this.validateFields(eventOrStripeResult);
    });
  };

  // NB: this is a bit of a hack. Currently it is only called as a part of the BillingAccountsController#new_unauthenticated process
  registerNewUser = async (email, name, sourceSubtype) => {
    const nameArray = name.split(/\s+/);
    const firstName = nameArray.shift();
    const lastName = nameArray.join(' ');

    const registerParams = {
      user: {
        email,
        first_name: firstName,
        last_name: lastName,
        legal_agreement: '1',
        offer_free_trial: true,
        user_type: 'source',
        source_subtype: sourceSubtype || 'source_smb',
      },
    };

    return vapi.signUp(registerParams);
  };

  stripePaymentMethodHandler = async (result, billingCompany) => {
    const billingAccountParams = {};
    const {
      stripe,
      sourcePlan,
      delegateUserId,
      promotionCodeId,
      pageName,
    } = this.props;
    const { accountCode } = this.state;
    let apiResult;

    if (result.error) {
      this.displayStripeError(result);
    } else {
      billingAccountParams.payment_method = result.paymentMethod.id;
      billingAccountParams.name = result.paymentMethod.billing_details.name;
      billingAccountParams.email = result.paymentMethod.billing_details.email;
      billingAccountParams.company = billingCompany;
      billingAccountParams.source_plan = sourcePlan;
      billingAccountParams.promotion_code_id = promotionCodeId;

      try {
        if (accountCode) {
          apiResult = await vapi.updateBillingAccount(accountCode, billingAccountParams);
        } else {
          apiResult = await vapi.createBillingAccount(delegateUserId, billingAccountParams);
        }
        const { subscription } = apiResult.data;

        if (subscription) {
          if (subscription.payment_intent_status === 'requires_action') {
            // Perform 3D secure validation
            const confirmResult = await stripe.confirmCardPayment(subscription.client_secret);

            if (confirmResult.error) {
              if (subscription.account_code) {
                await vapi.cleanupAccount(subscription.account_code);
              }
              this.displayStripeError(confirmResult);
              return;
            }
          }
          // Success! Trigger the last steps on the server if necessary
          if (!accountCode) {
            // (Only need to do this last step when we're creating a new billing account)
            // The web hooks for invoices (finalized and paid) act as our insurance policy:
            // The finalizeAccount method call here will activate the premium Qwoted account
            // in a synchronous fashion so the user has immediate access.
            // But if something happens in the browser and this doesn't get called, the events
            // Stripe sends via web hook to our server will take care of everything.
            await vapi.finalizeAccount(subscription.account_code);
          }
          this.reportSuccess();
          gaTracking.trackSignUp(pageName, 'succeedStripePayment', billingAccountParams.name, billingAccountParams.source_plan);
          vahoy.track('NewBillingAccount#succeedStripePayment');
        } else {
          const { errors } = apiResult.data;
          let errorMessage = unknownMessage;

          if (errors) {
            errorMessage = errors.join('. ');
          }

          this.displayGeneralError(errorMessage);
        }
      } catch (error) {
        if (error.response && error.response.status === 402) {
          // Card error
          if (error.response.data && error.response.data.account_code) {
            await vapi.cleanupAccount(error.response.data.account_code);
          }
          this.displayStripeError(null, error);
        } else {
          let message;

          if (error.response && error.response.data) {
            if (error.response.data.account_code) {
              await vapi.cleanupAccount(error.response.data.account_code);
            }
            const responseData = error.response.data;
            message = responseData.error || responseData.message || unknownMessage;
          } else {
            message = unknownMessage;
          }
          this.displayGeneralError(message);
        }
      }
    }
  };

  onFormSubmit = async (e) => {
    e.preventDefault();
    this.clearGeneralError();
    this.setProcessingState(true);

    const {
      currentUserId,
      elements,
      pageName,
      sourceSubtype,
      stripe,
    } = this.props;
    const {
      billingName,
      billingCompany,
      billingEmail,
    } = this.state.fields;

    if (this.formIsInvalid() || !stripe || !elements) {
      // Don't continue if fields are invalid or Stripe isn't loaded
      this.setProcessingState(false);
      gaTracking.trackSignUp(pageName, 'failSubmitForm', 'UserErrorOrPageError', `InvalidForm: ${this.formIsInvalid}, stripeNotFound: ${!stripe}, elementsNotFound: ${!elements}`);
      vahoy.track('NewBillingAccount#failSubmitForm');
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: billingName,
        email: billingEmail,
      },
    });

    if (result.error) {
      // Initial Stripe call to createPaymentMethod failed
      this.displayStripeError(result);
    } else if (currentUserId) {
      // createPaymentMethod succeeded and we are logged in ...
      this.stripePaymentMethodHandler(result, billingCompany);
    } else {
      // sign_up_for_free_trial: createPaymentMethod succeeded and we are NOT logged in so now we must
      // register a new user, do the rest of the Stripe process
      this.registerNewUser(billingEmail, billingName, sourceSubtype)
        .then(() => {
          this.stripePaymentMethodHandler(result, billingCompany);
        })
        .catch((errorResponse) => {
          const responseData = errorResponse.response && errorResponse.response.data;
          const errors = responseData && responseData.errors;
          if (errors) {
            this.displayServerValidationErrors(errors);
          } else {
            const message = responseData.error || responseData.message || unknownMessage;
            this.displayGeneralError(message);
          }
        });
    }

    vahoy.track('NewBillingAccount#submitForm');
  };

  render() {
    const {
      stripe,
      priceString,
      planNameString,
      planInterval,
      cancelPath,
      freeTrial,
      hideLinksForCcPage,
    } = this.props;

    const {
      fields,
      fieldErrorMessages,
      formIsEmpty,
      generalErrorTitle,
      generalErrorMessage,
      disableInput,
      isProcessing,
      isFinished,
      accountCode,
      referral,
    } = this.state;

    const errorBox = generalErrorMessage ? (
      <div className="alert alert-danger mb-3" role="alert">
        <h4 className="alert-heading">{generalErrorTitle}</h4>
        <p>{generalErrorMessage}</p>
        <hr />
        <small className="mb-0">
          If you continue to experience problems, please contact us at {window.SUPPORT_EMAIL}.
        </small>
      </div>
    ) : '';

    const zeroPayment = priceString === '$0.00';

    let processingText;

    // Set the button text based on processing status and presence of accountCode:
    // When present, we're doing a card on file update. When absent, we're doing
    // a payment and creation of a billing account.
    if (accountCode) {
      processingText = isProcessing ? 'Updating Payment Method...' : 'Update Payment Method';
    } else {
      let payString = `Upgrade to ${planNameString}`;
      if (freeTrial) {
        payString = 'Start Trial';
      }

      if (zeroPayment) {
        processingText = isProcessing ? 'Validating Card...' : payString;
      } else {
        processingText = isProcessing ? 'Processing Payment...' : payString;
      }
    }

    const processingButton = (
      <button
        type="submit"
        disabled={!stripe || formIsEmpty || disableInput}
        className="btn btn-large btn-primary rounded-5 py-2 float-start mx-auto d-block"
      >
        <Spinner
          color="light"
          className="me-2"
          style={{
            width: '1.5rem',
            height: '1.5rem',
            display: `${isProcessing ? 'inline-block' : 'none'}`,
          }}
        />
        <span className="ms-1 fw-bold">
          {processingText}
        </span>
      </button>
    );

    const onCancel = () => {
      const { pageName } = this.props;
      gaTracking.trackSignUp(pageName, 'cancelStripePayment');
      vahoy.track('NewBillingAccount#clickCancel');
      window.location.replace(cancelPath);
    };

    const cancelButton = (
      <button
        type="button"
        disabled={disableInput}
        className="btn text-primary btn-large cc-cancel-button rounded-5 py-2 me-3 shadow bg-white"
        onClick={onCancel}
      >
        Cancel
      </button>
    );

    return (
      <div className="billing-account-form">
        <UpgradeSuccessModal
          showPopup={isFinished}
          onCancel={this.onSuccessDialogDismiss}
          zeroPayment={zeroPayment}
        />
        {errorBox}
        <div className="row">
          <div className="col-12">
            <form
              onSubmit={this.onFormSubmit}
              data-rewardful={true}
            >
              {referral ? <input type="hidden" name="referral" value={referral} /> : null}
              <div className="mb-3 required">
                <label
                  className="form-label text-muted"
                  htmlFor="billing_name"
                >
                  Name on card
                </label>
                <input
                  className={`form-control border-0 billing-input ${fieldErrorMessages.billingName ? 'is-invalid' : ''}`}
                  placeholder="Name of billing contact"
                  name="billingName"
                  id="billing_name"
                  value={fields.billingName}
                  disabled={disableInput}
                  onChange={this.onInputChange}
                  style={{ background: '#ECEEF0' }}
                />
                <small className="form-text d-block text-danger">
                  {fieldErrorMessages.billingName}
                </small>
              </div>

              <div className="mb-3">
                <label
                  className="form-label text-muted"
                  htmlFor="billing_company"
                >
                  Company name
                </label>
                <input
                  className="form-control border-0  billing-input"
                  placeholder="Optional company name"
                  name="billingCompany"
                  id="billing_company"
                  value={fields.billingCompany}
                  disabled={disableInput}
                  onChange={this.onInputChange}
                  style={{ background: '#ECEEF0' }}
                />
              </div>

              <div className="mb-3 required">
                <label
                  className="form-label text-muted"
                  htmlFor="billing_email"
                >
                  Email
                </label>
                <input
                  className={`form-control border-0 billing-input ${fieldErrorMessages.billingEmail ? 'is-invalid' : ''}`}
                  placeholder="Email address for invoices"
                  name="billingEmail"
                  id="billing_email"
                  value={fields.billingEmail}
                  disabled={disableInput}
                  onChange={this.onInputChange}
                  style={{ background: '#ECEEF0' }}
                />
                <small className="form-text text-danger">
                  {fieldErrorMessages.billingEmail}
                </small>
              </div>

              <div
                className="mb-3 required"
                style={{ visibility: disableInput ? 'hidden' : 'visible' }}
              >
                <label htmlFor="card-element" className="form-label text-muted">
                  Card information
                </label>
                <div className="">
                  <div id="card-element">
                    <CardElement
                      className={`form-control border-0 ${fieldErrorMessages.paymentCard ? 'is-invalid' : ''}`}
                      options={CARD_ELEMENT_OPTIONS}
                      disabled={disableInput}
                      onChange={this.onInputChange}
                    />
                  </div>
                  <small className="form-text text-danger">
                    {fieldErrorMessages.paymentCard}
                  </small>
                </div>
              </div>
              <div className="row gx-0 justify-content-start mb-3">
                <div className="col pe-3 text-primary fs-2 fw-bold">
                  <span className="total"> Subscription Total: </span>
                  <span className="amount">
                    {freeTrial == true
                      && (
                        <strong>
                          0.00
                        </strong>
                      )}

                    {freeTrial == false
                      && (
                        <strong>
                          {priceString}
                        </strong>
                      )}
                  </span>
                  <small
                    className="font-size-10px"
                    style={{
                      display: 'block',
                      lineHeight: '1.375',
                    }}
                  >
                    {freeTrial == true
                      && (
                        <span>
                          Automatic payment of {priceString} after 2 weeks.
                        </span>
                      )}

                    {freeTrial == false
                      && (
                        <span>
                          Next automatic payment in 1 {planInterval}.
                        </span>
                      )}
                  </small>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {hideLinksForCcPage == false
                    && (
                     <span className="ms-3">{ cancelButton }</span>
                  )}
                  {processingButton}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

BillingAccountForm.propTypes = {
  accountCode: PropTypes.number, // Billing account id provided when updating payment method instead of creating account
  cancelPath: PropTypes.string.isRequired, // Where to navigate to when the user clicks the cancel link
  currentUserId: PropTypes.number,
  defaultCompany: PropTypes.string, // Existing company name on billing account used when updating payment method
  defaultEmail: PropTypes.string.isRequired, // Email to pre-populate in the billing email field
  defaultName: PropTypes.string.isRequired, // Name to pre-populate in the billing name field
  delegateUserId: PropTypes.number, // If current user is an admin, send in the user id to attach the new billing account to
  elements: PropTypes.object, // Stripe Elements instance to be injected by ElementsConsumer
  freeTrial: PropTypes.bool,
  hideLinksForCcPage: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired, // Function to call when payment is successful
  pageName: PropTypes.string,
  planInterval: PropTypes.string, // 'month' or 'year' depending on the billing plan interval
  planNameString: PropTypes.string, // String representation of the billing plan name to display on form and submit button
  priceString: PropTypes.string, // String representation of price to charge immediately
  promotionCodeId: PropTypes.string, // If there is an applied promotion, the Stripe ID for that promotion
  sourcePlan: PropTypes.string, // Stripe ID for the plan used for subscription
  sourceSubtype: PropTypes.string,
  stripe: PropTypes.object, // Stripe object from loadStripe method to be injected by ElementsConsumer
  successUrl: PropTypes.string.isRequired, // URL to redirect to when the payment is successful
};

export default function InjectedCheckoutForm(props) {
  const {
    accountCode,
    onboardedUser,
    cancelPath,
    currentUserId,
    defaultCompany,
    defaultEmail,
    defaultName,
    delegateUserId,
    freeTrial,
    onSuccess,
    pageName,
    planInterval,
    planNameString,
    priceString,
    promotionCodeId,
    sourceSubtype,
    sourcePlan,
    successUrl,
  } = props;

  return (
    <ElementsConsumer>
      {({
          elements,
          stripe,
        }) => (
        <BillingAccountForm
          accountCode={accountCode}
          cancelPath={cancelPath}
          currentUserId={currentUserId}
          defaultCompany={defaultCompany}
          defaultEmail={defaultEmail}
          defaultName={defaultName}
          delegateUserId={delegateUserId}
          elements={elements}
          freeTrial={freeTrial}
          onSuccess={onSuccess}
          onboardedUser={onboardedUser}
          pageName={pageName}
          planInterval={planInterval}
          planNameString={planNameString}
          priceString={priceString}
          promotionCodeId={promotionCodeId}
          sourcePlan={sourcePlan}
          sourceSubtype={sourceSubtype}
          stripe={stripe}
          successUrl={successUrl}
        />
      )}
    </ElementsConsumer>
  );
}

InjectedCheckoutForm.propTypes = {
  accountCode: PropTypes.number, // Billing account id provided when updating payment method instead of creating account
  cancelPath: PropTypes.string.isRequired, // Where to navigate to when the user clicks the cancel link
  currentUserId: PropTypes.number,
  defaultCompany: PropTypes.string, // Existing company name on billing account used when updating payment method
  defaultEmail: PropTypes.string.isRequired, // Email to pre-populate in the billing email field
  defaultName: PropTypes.string.isRequired, // Name to pre-populate in the billing name field
  delegateUserId: PropTypes.number, // If current user is an admin, send in the user id to attach the new billing account to
  freeTrial: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired, // Function to call when payment is successful
  onboardedUser: PropTypes.bool.isRequired, // Indicates whether or not the signed in user is already onboarded
  pageName: PropTypes.string,
  planInterval: PropTypes.string, // 'month' or 'year' depending on the billing plan interval
  planNameString: PropTypes.string, // String representation of the billing plan name to display on form and submit button
  priceString: PropTypes.string, // String representation of price to charge immediately
  promotionCodeId: PropTypes.string, // If there is an applied promotion, the Stripe ID for that promotion
  sourcePlan: PropTypes.string, // Stripe ID for the plan used for subscription
  sourceSubtype: PropTypes.string,
  successUrl: PropTypes.string.isRequired, // URL to redirect to when the payment is successful
};
