import React from 'react';
import PropTypes from 'prop-types';
import NamePhotoTitle from './name_photo_title';
import ContactInfo from '../../sources/card/contact_info';
import Hashtags from '../../hashtags';
import QuoteOrBio from './quote_or_bio';

function SourceHit({ hit, currentQuery }) {
  const source = hit;
  const maxHashtags = 5;
  const smallMode = false;

  return (
    <div className="card h-100 source-card animated faster fadeIn">
      <div style={{ background: '#dbdfb6' }} className="card-header text-black text-center fw-bold">
       <a className="text-black" title="Sources" href="/sources"> Expert Profile</a>
      </div>
      <div className="card-body position-relative">
        <NamePhotoTitle source={source} />
        <ContactInfo source={source} />
        <Hashtags
          hashtagTarget={source}
          maxHashtags={maxHashtags}
          title="Expert On"
          clickEventName="Sources/SourceCard#clickHashtag"
        />
        <QuoteOrBio source={source} />
        <div className="clearfix" style={{ minHeight: smallMode ? '2.75rem' : '3.0rem' }} />
        <div className="mt-4 mb-2 row w-100" style={{ position: 'absolute', bottom: '0' }}>
          <div className="col">
            {source.messaging_url && !source.is_self_source && (
              <a
                href={`${source.messaging_url}&back_path=${window.location.pathname}`}
                className="btn btn-primary btn-large"
              >
                <i className="fa-solid fa-envelope mx-1" />
                <small className="me-2">Message</small>
              </a>
            )}
          </div>
        </div>
      </div>
      <a className="mx-3 my-2 text-decoration-underline font-size-14px fw-bold pb-2" href={`sources?query=${currentQuery}`}>{`View ${currentQuery === '' ? 'all' : 'similar'} Experts`}</a>
    </div>
  );
}

SourceHit.propTypes = {
  hit: PropTypes.object.isRequired,
  currentQuery: PropTypes.string,
};

SourceHit.defaultProps = {
  currentQuery: '',
};

export default SourceHit;
