import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Input, FormGroup, Form,
} from 'reactstrap';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';

function MultipleHashtags({ sourceRequestId, onHashtagAdded }) {
  const [text, setText] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessages([]);

    if (!text.trim()) {
      return;
    }

    try {
      const responses = await Promise.all(
        text.split(',').map((hashtag) => vapi.createMultipleHashtagTag(hashtag.trim(), sourceRequestId, 'SourceRequest', 'hashtag_tags')),
      );

      const responseErrors = [];
      let success = false;

      responses.forEach((response) => {
        if (response.response && response.response.data && response.response.data.errors) {
          const { errors } = response.response.data;
          const firstError = errors[0];
          responseErrors.push(`${firstError.title} ${firstError.detail}`);
        } else {
          success = true; // At least one hashtag was successfully added
        }
      });

      setErrorMessages(responseErrors);

      if (success) {
        onHashtagAdded(); // Call onHashtagAdded if at least one hashtag is successfully added
        setText('');
        vahoy.track('Approval#createMultipleHashtagTag');
      }
    } catch (error) {
      setErrorMessages(['Error: Failed to save hashtags']);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Input type="textarea" id="paste_hashtags" placeholder="Paste multiple topics, separated by commas, do not include #" onChange={handleChange} value={text} />
          <Button type="submit" id="paste_hashtags_submit" color="primary" className="mx-1 mb-2 my-2 my-lg-1">
            Submit
          </Button>
        </FormGroup>
      </Form>
      <div className="text-danger">
        {errorMessages.map((errorMessage, index) => (
          <span key={index}>
            {errorMessage}
            <br />
          </span>
        ))}
      </div>
    </>
  );
}

MultipleHashtags.propTypes = {
  sourceRequestId: PropTypes.number.isRequired,
  onHashtagAdded: PropTypes.func.isRequired,
};

export default MultipleHashtags;
