import React, {
 useRef, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connectRefinementList } from 'react-instantsearch-dom';
import * as d3 from 'd3';

/* eslint-disable no-shadow */

function VoronoiTreemap({ items, refine }) {
  const svgRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 300, height: 300 });

  useEffect(() => {
    const updateDimensions = () => {
      if (svgRef.current) {
        const { width, height } = svgRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const data = useMemo(() => ({
    name: 'root',
    children: items.map((item) => ({
      name: item.label,
      value: item.count,
      isRefined: item.isRefined,
    })),
  }), [items]);

  const treemap = useMemo(() => d3.treemap()
      .size([dimensions.width, dimensions.height])
      .padding(1)
      .round(true), [dimensions]);

  const root = useMemo(() => treemap(d3.hierarchy(data)
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value)), [data, treemap]);

  const voronoi = useMemo(() => {
    const points = root.leaves().map((d) => [d.x0 + (d.x1 - d.x0) / 2, d.y0 + (d.y1 - d.y0) / 2]);
    return d3.Delaunay.from(points).voronoi([0, 0, dimensions.width, dimensions.height]);
  }, [root, dimensions]);

  const handleClick = (d) => {
    const item = items.find((item) => item.label === d.data.name);
    if (item) {
      refine(item.value);
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', minHeight: '300px' }}>
      <svg ref={svgRef} width="100%" height="100%" viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}>
        {root.leaves().map((d, i) => (
          <g key={i}>
            <path
              d={voronoi.renderCell(i)}
              fill={d.data.isRefined ? '#4a90e2' : '#67c23a'}
              opacity={0.7}
              stroke="#fff"
              strokeWidth={1}
              style={{ cursor: 'pointer' }}
              onClick={() => handleClick(d)}
            />
            {(d.x1 - d.x0 > 30 && d.y1 - d.y0 > 20) && (
              <text
                x={(d.x0 + d.x1) / 2}
                y={(d.y0 + d.y1) / 2}
                dy=".3em"
                textAnchor="middle"
                fontSize={Math.min((d.x1 - d.x0) / 5, (d.y1 - d.y0) / 3)}
                fill="white"
                pointerEvents="none"
              >
                {d.data.name}
              </text>
            )}
          </g>
        ))}
      </svg>
    </div>
  );
}

VoronoiTreemap.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    }),
  ).isRequired,
  refine: PropTypes.func.isRequired,
};

const ConnectedVoronoiTreemap = connectRefinementList(VoronoiTreemap);
export default ConnectedVoronoiTreemap;
