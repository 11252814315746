import React from 'react';
import PropTypes from 'prop-types';

function EntityTypeButton({ onClick, entityType, iconClasses }) {
  const btnClasses = 'btn btn-outline-primary cursor-pointer mb-2 me-2 d-inline-flex flex-row';
  const displayEntityTypes = {
    source: 'Expert',
    product: 'Product',
  };
  const displayEntityType = displayEntityTypes[entityType?.toLowerCase()];

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick(entityType);
    }
  };

  return (
    <div
      className={`${btnClasses}`}
      role="button"
      tabIndex="0"
      onClick={() => onClick(entityType)}
      onKeyDown={handleKeyDown}
      id={`add_${entityType?.toLowerCase()}`}
    >
      <div className="d-inline-block me-2 align-self-center">
        <i className={`${iconClasses} fa-lg`} />
      </div>
      <div className="d-flex align-items-center justify-content-start fs-4" style={{ height: '1.9em' }}>
        {displayEntityType}
      </div>
    </div>
  );
}

EntityTypeButton.propTypes = {
  entityType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  iconClasses: PropTypes.string.isRequired, // If iconClasses is used dynamically and not a required prop
};

function SearchHeader({ entityType, onCancel, iconClasses }) {
  const pluralizedEntityTypes = {
    source: 'Experts',
    product: 'Products',
  };
  const pluralizedEntityType = pluralizedEntityTypes[entityType?.toLowerCase()];

  return (
    <div className="d-inline-flex flex-column pt-1 mb-2">
      <div className="fs-4">
        <i className={`${iconClasses} fa-lg me-2`} />
        Search {pluralizedEntityType}
      </div>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          onCancel();
        }}
        className="btn btn-link btn-sm align-self-start px-0"
        style={{ cursor: 'pointer' }}
      >
        <i className="fa-solid fa-arrow-left" />
        &nbsp;Back
      </button>
    </div>
  );
}

SearchHeader.propTypes = {
  entityType: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  iconClasses: PropTypes.string.isRequired, // If iconClasses is used dynamically and not a required prop
};

// Main Component
function EntityTypeSelector({
                              onClick, entityType, active, onCancel,
                            }) {
  const iconClasses = {
    source: 'fa-solid fa-user-tie',
    product: 'fa-solid fa-box-open',
    company: 'fa-solid fa-building',
    default: 'fa-solid fa-circle-plus',
  };

  const getIconClass = (type) => iconClasses[type.toLowerCase()] || iconClasses.default;

  if (active) {
    return <SearchHeader entityType={entityType} onCancel={onCancel} iconClasses={getIconClass(entityType)} />;
  }

  return <EntityTypeButton onClick={onClick} entityType={entityType} iconClasses={getIconClass(entityType)} />;
}

EntityTypeSelector.propTypes = {
  onClick: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EntityTypeSelector;
