import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import MenuBar from '../../directory/MenuBar';
import PrUserCard from '../card/pr_user_card';
import PrUserSkeletonPage from './pr_user_skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import SearchLaunchForm from '../../algolia_search/search_launch_form';

function PrUsersIndex({ view }) {
  const perPage = 24;
  const [activeTab, setActiveTab] = useState(view.toUpperCase());
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [refreshCount, setRefreshCount] = useState(0);
  const [prUsers, setPrUsers] = useState([]);
  const [asyncError, setAsyncError] = useState();

  const forceTabRefresh = () => {
    setRefreshCount((prevCount) => prevCount + 1);
  };

  const handleTabChange = (tab) => {
    if (tab === activeTab) forceTabRefresh();
    setIsLoading(true);
    setPrUsers([]);
    setActiveTab(tab);
    setPage(1);
  };

  // Ignore scrollContainerRef since we want to use root as the scrolling container
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });

  // Effect to track infinite scrolling intersection observer event, which increments page
  useEffect(() => {
    if (lastTriggeredAt > 0) setPage((prevPage) => prevPage + 1);
  }, [lastTriggeredAt]);

  // Effect to track the page number and trigger fetch from API
  useEffect(() => {
    const fetchRecords = async () => {
      if (activeTab === null) return;

      try {
        if (page === 1) setIsLoading(true);
        vahoy.track(`InfinitePrUsers#loadPage_${page}`);

        const params = {
          page,
          per_page: perPage,
          favorited: activeTab === 'FAV_PRS' ? 'y' : 'n',
        };

        const response = await vapi.getPrUsers(params);
        if (response.status === 200) {
          const results = response.data;
          if (results.data && results.data.length > 0) {
            setPrUsers((prevRecords) => [...prevRecords, ...results.data]);
          }
          setHasMore(results.data.length >= perPage);
        }
        setIsLoading(false);
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchRecords();
  }, [page, perPage, activeTab, refreshCount]);

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  return (
    <div>
      <MenuBar view={view} onTabChange={handleTabChange}>
        {{ searchForm: <SearchLaunchForm searchUrl="/pr_users" /> }}
      </MenuBar>

      <h1 className="pt-2 mb-3">Saved PRs</h1>

      {!isLoading && prUsers.length === 0 && (
        <div className="text-center p-4 fw-bold">
          {activeTab === 'FAV_PRS' && (<span>You haven't saved any favorites yet...</span>)}
          <br />
          <a href="/pr_users">
            Try searching for pr users
          </a>
        </div>
      )}

      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 px-1">
        {isLoading && <PrUserSkeletonPage numberOfCards={12} />}
        {!isLoading
          && prUsers.map((prUser) => (
            <div className="col px-2 pb-3 pt-0" key={prUser.id}>
              <PrUserCard prUser={prUser.attributes} />
            </div>
          ))}
      </div>

      {hasMore && (
        <div
          ref={scrollTriggerRef}
          className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 px-1"
        >
          <PrUserSkeletonPage numberOfCards={4} singleRow />
        </div>
      )}
    </div>
  );
}

PrUsersIndex.propTypes = {
  view: PropTypes.string.isRequired,
};

export default PrUsersIndex;
