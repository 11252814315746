import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ResponsiveAvatar from '../../responsive_avatar';
import { CompanyContext } from '../data/company_context_management';
import vapi from '../../../javascript/frontend/api/vapi';
import vlog from '../../../javascript/vlog';

function ExpertForm({
                      source,
                      sources,
                      setSources,
                      setDoTopLevelFetch,
                    }) {
  const context = useContext(CompanyContext);
  const { company } = context;

  if (!sources.length) return null;

  const sourceEmployment = source.attributes.source_employments.find(
    (se) => se.company_id === company.objectID,
  );

  const handleSourceDisplayChange = async (e) => {
    const target = e.target.id;
    let toggledHideValue;
    let toggledFeaturedValue;

    if (target.includes('hide')) {
      toggledHideValue = !sourceEmployment.company_page_hide;
      // Turn off Featured if Hide is turned on
      toggledFeaturedValue = toggledHideValue
        ? false
        : sourceEmployment.company_page_featured;
    } else if (target.includes('featured')) {
      // Turn off Hide if Featured is turned on
      toggledFeaturedValue = !sourceEmployment.company_page_featured;
      toggledHideValue = toggledFeaturedValue
        ? false
        : sourceEmployment.company_page_hide;
    } else {
      toggledHideValue = sourceEmployment.company_page_hide;
      toggledFeaturedValue = sourceEmployment.company_page_featured;
    }

    const params = {
      company_page_hide: toggledHideValue,
      company_page_featured: toggledFeaturedValue,
    };

    try {
      const response = await vapi.updateSourceEmployment(
        sourceEmployment,
        source.id,
        params,
      );

      const sourceEmploymentResponse = response.data.source_employment;
      const sourceResponse = response.data.source.data;

      const sourceToUpdate = sources.find((src) => src.id == sourceResponse.id);

      const sourceEmploymentToUpdate = sourceToUpdate.attributes.source_employments.find(
        (se) => se.id == sourceEmploymentResponse.id,
      );

      sourceEmploymentToUpdate.company_page_hide = sourceEmploymentResponse.company_page_hide;
      sourceEmploymentToUpdate.company_page_featured = sourceEmploymentResponse.company_page_featured;

      setSources([...sources]);
      setDoTopLevelFetch(true);
    } catch (err) {
      alert('Sorry, something went wrong. Please try again.');
      vlog.warn('Error when attempting to update Source: ', err);
    }
  };

  return (
    <div className="row gx-0 w-100 mb-4">
      <div className="col-1">
        <ResponsiveAvatar
          avatarUrl={source.attributes.avatar_url}
          avatarLetters={source.attributes.initials}
          className="rounded"
        />
      </div>

      <div className="col ps-2">
        <div className="fw-bold font-size-14px">
          {source.attributes.full_name}
        </div>

        <div className="text-secondary font-size-14px">{source.attributes.title}</div>

        <div className="row pt-1">
          <div className="col-3">
            <div className="form-check form-switch">
              <input
                id={`hide-${source.attributes.objectID}`}
                type="checkbox"
                className="form-check-input"
                checked={sourceEmployment.company_page_hide}
                onChange={(e) => handleSourceDisplayChange(e)}
              />
              <label
                className="form-check-label"
                htmlFor={`hide-${source.attributes.objectID}`}
              >
                Hide
              </label>
            </div>
          </div>

          <div className="col-3">
            <div className="form-check form-switch">
              <input
                id={`featured-${source.attributes.objectID}`}
                type="checkbox"
                className="form-check-input"
                checked={sourceEmployment.company_page_featured}
                onChange={(e) => handleSourceDisplayChange(e)}
              />
              <label
                className="form-check-label"
                htmlFor={`featured-${source.attributes.objectID}`}
              >
                Feature
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ExpertForm.propTypes = {
  setDoTopLevelFetch: PropTypes.func.isRequired,
  setSources: PropTypes.func.isRequired,
  source: PropTypes.object.isRequired,
  sources: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ExpertForm;
