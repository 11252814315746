import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  createSourceEmployment(sourceEmployment, sourceId) {
    vlog.info('api#createSourceEmployment');

    api.configure();

    const url = `/api/internal/sources/${sourceId}/employments`;

    // eslint-disable-next-line camelcase
    const sourceEmploymentParams = {
      company_id: sourceEmployment.company_id,
      company_name: sourceEmployment.company_name,
      title: sourceEmployment.title,
    };

    return axios
      .post(url, { source_employment: sourceEmploymentParams })
      .then((result) => result)
      .catch((errorResponse) => errorResponse);
  },

  updateSourceEmployment(sourceEmployment, sourceId, params) {
    vlog.info('api#updateSourceEmployment');

    api.configure();

    const url = `/api/internal/sources/${sourceId}/employments/${sourceEmployment.id}`;

    return axios
      .put(url, { source_employment: params }, api.axiosFix)
      .then((result) => result)
      .catch((errorResponse) => errorResponse);
  },
});
