import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { useDebounce } from 'use-lodash-debounce';
import algoliasearch from 'algoliasearch/lite';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import NoResultsMessage from '../../algolia_search/no_results_message';
import NewMediaCard from '../card/new_media_card';
import SearchLoadingCards from './search_loading_cards';
import SourceRequestCard from '../card/source_request_card';
import MediaMatchCard from '../../media_matches/index/media_match_card';
import SourceRequestSkeletonPage from '../index/source_request_skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import FullPagePaywall from '../full_page_paywall';
import UpgradeAd from '../index/upgrade_ad';

const mergeAndRandomizeResults = (mainHits, reporterHits, showAds) => {
  const combinedHits = [...mainHits];
  let adCount = 0;
  if (showAds) {
    const ad = { ad: true };
    for (let i = 9; i < combinedHits.length; i += 10) {
      combinedHits.splice(i, 0, ad);
      adCount += 1;
    }
  }

  if (reporterHits && reporterHits.length > 0) {
    reporterHits.forEach((reporterHit, index) => {
      const insertAt = ((index + 1) * 6) + adCount;
      combinedHits.splice(insertAt, 0, reporterHit);
    });
  }
  return combinedHits;
};

function InfiniteHits({
  hasMore,
  refine,
  hits,
  smallMode,
  query,
  canPitchConnectionOpps,
  algoliaAppId,
  algoliaSearchKey,
  reporterIndexName,
  showAds,
  view,
}) {
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });
  const [qwotedRecords, setQwotedRecords] = useState({});
  const [idsToLookup, setIdsToLookup] = useState([]);
  const [reporterHitIds, setReporterHitIds] = useState([]);
  const [reporterHitList, setReporterHitList] = useState([]);
  const [reporterRecords, setReporterRecords] = useState({});
  const [asyncError, setAsyncError] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [mergedHits, setMergedHits] = useState([]);
  const mixNewMedia = view == 'all' && (query === undefined || query === null);
  const algoliaClient = algoliasearch(algoliaAppId, algoliaSearchKey);
  const pageSize = 24;
  const [showPaywall, setShowPaywall] = useState(false);

  // Effect to refine results when infinite scroll triggers
  useEffect(() => {
    if (lastTriggeredAt > 0) refine();
  }, [lastTriggeredAt, refine]);

  // Effect to find new Algolia hit records that haven't yet been updated from Qwoted DB
  // Using the debounced list makes search-while-typing more responsive and limits the number
  // of queries hitting the Qwoted API.
  const debouncedHits = useDebounce(hits, 700);

  useEffect(() => {
    const missingIds = debouncedHits.filter((h) => qwotedRecords[h.objectID] === undefined).map((h) => h.objectID);
    setIdsToLookup((prevList) => [...prevList, ...missingIds]);
  }, [debouncedHits, qwotedRecords]);

  // Effect to perform lookup of yet-to-be Qwoted-filled records and populate state with results
  useEffect(() => {
    const fetchRecords = async () => {
      try {
        if (idsToLookup.length > 0) {
          vahoy.track('SourceRequestsSearch#getAlgoliaAttribs', { sourceRequestids: idsToLookup });
          const response = await vapi.getAlgoliaAttribsForIds(idsToLookup);
          if (response.status === 200) {
            const sourceRequestResults = response.data;
            if (sourceRequestResults.data && sourceRequestResults.data.length > 0) {
              const recordsToAdd = {};
              sourceRequestResults.data.forEach((sourceRequest) => {
                recordsToAdd[sourceRequest.id] = sourceRequest;
              });
              setIdsToLookup([]);
              setQwotedRecords((prevList) => Object.assign(recordsToAdd, prevList));
              if (!mixNewMedia) {
                setMergedHits(hits);
              }
            }
          }
        }
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchRecords();
  }, [idsToLookup, hits, mixNewMedia]);

  // Handle reporter results fetching and merging

  useEffect(() => {
    const page = Math.floor(hits.length / (pageSize));

    if (page !== currentPage && mixNewMedia) {
      setCurrentPage(page);
      algoliaClient
        .initIndex(reporterIndexName)
        .search('', {
          hitsPerPage: 4,
          page: page - 1, // setting to zero gets first page
        })
        .then(({ hits: reporterHits }) => {
          const reporterIds = reporterHits.map((h) => h.favoriteable_id);
          setReporterHitList((prevList) => [...prevList, ...reporterHits]);
          setReporterHitIds((prevList) => [...prevList, ...reporterIds]);
        })
        .catch((error) => setAsyncError(error));
    }
  }, [hits, currentPage, algoliaClient, mixNewMedia, reporterIndexName]);

  useEffect(() => {
    const fetchReporterRecords = async () => {
      try {
        if (reporterHitIds.length > 0 && mixNewMedia) {
          vahoy.track('NewMediaSearch#getNewMediaAlgoliaAttribs', { reporterHitIds });
          const response = await vapi.getAlgoliaAttribsForNewMedia(reporterHitIds);
          if (response.status === 200) {
            const reporterResults = response.data;
            if (reporterResults.data && reporterResults.data.length > 0) {
              const recordsToAdd = {};
              reporterResults.data.forEach((reporter) => {
                recordsToAdd[reporter.id] = reporter.attributes;
              });
              setReporterHitIds([]);
              setReporterRecords((prevList) => Object.assign(recordsToAdd, prevList));
              if (!mixNewMedia) {
                setReporterHitList([]);
              }
              const newMergedHits = mergeAndRandomizeResults(hits, reporterHitList, showAds, mixNewMedia);
              setMergedHits(newMergedHits);
            }
          }
        } else {
          const newMergedHits = mergeAndRandomizeResults(hits, reporterHitList, showAds, mixNewMedia);
          setMergedHits(newMergedHits);
        }
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchReporterRecords();
  }, [reporterHitIds, hits, mixNewMedia, reporterHitList, showAds]);

  const onCardClick = (e) => {
    if (!canPitchConnectionOpps) {
      e.preventDefault();
      setShowPaywall(true);
    }
  };

  if (asyncError) throw asyncError;

  return (
    <>
      {showPaywall && (
         <FullPagePaywall
           onClose={() => setShowPaywall(false)}
           isOpen={showPaywall}
         />
       )}
      <NoResultsMessage />
    { hits && hits.length > 0 && (
      <div className={`row row-cols-1 row-cols-sm-1 ${view != '' ? 'row-cols-md-2 row-cols-lg-3 row-cols-xl-4' : 'row-cols-lg-2 row-cols-xl-3 row-cols-xxxl-4'} px-1`}>
        <SearchLoadingCards smallMode={smallMode} />
        { mergedHits.map((hit, index) => (
          <React.Fragment key={`${hit.objectID}${hit.initials}${index}`}>
            {hit.initials
              && (
<div className="col px-2 pb-3 pt-0">
                <NewMediaCard newMediaUser={hit} smallMode={smallMode} updates={reporterRecords[hit.favoriteable_id]} />
              </div>
)}
            {hit.published_at && !hit.media_match_phase && (
              <div className="col px-2 pb-3 pt-0">
                <SourceRequestCard
                  sourceRequest={hit}
                  updates={qwotedRecords[hit.objectID]}
                  smallMode={smallMode}
                  query={query}
                  canPitchConnectionOpps={canPitchConnectionOpps}
                  onCardClick={onCardClick}
                />
              </div>
            )}
            {hit.media_match_phase && (
              <div className="col px-2 pb-3 pt-0">
                <MediaMatchCard
                  match={hit}
                  updates={qwotedRecords[hit.objectID]}
                  query={query}
                  onCardClick={onCardClick}
                  canPitchMediaMatch={canPitchConnectionOpps}
                />
              </div>
            )}
            {hit.ad && (
              <div className="col px-2 pb-3 pt-0">
                <UpgradeAd
                  adVersion={(Math.floor(index / pageSize) % 3) + 1}
                  smallMode={smallMode}
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      )}

      {hasMore && (
        <div
          ref={scrollTriggerRef}
          className={`row row-cols-1 row-cols-sm-1 ${view != '' ? 'row-cols-md-2 row-cols-xl-3 row-cols-xxxl-4' : 'row-cols-lg-2 row-cols-xl-3 row-cols-xxxl-4'} px-1`}
        >
          <SourceRequestSkeletonPage numberOfCards={12} singleRow smallMode={smallMode} />
        </div>
      )}
    </>
  );
}

InfiniteHits.propTypes = {
  hasMore: PropTypes.bool,
  refine: PropTypes.func,
  hits: PropTypes.arrayOf(Object),
  smallMode: PropTypes.bool,
  query: PropTypes.string,
  canPitchConnectionOpps: PropTypes.bool,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  reporterIndexName: PropTypes.string,
  showAds: PropTypes.bool,
  view: PropTypes.string,
};

InfiniteHits.defaultProps = {
  hasMore: false,
  refine: undefined,
  hits: [],
  smallMode: false,
  query: undefined,
  canPitchConnectionOpps: true,
  showAds: false,
  view: '',
  reporterIndexName: '',
};

export default connectInfiniteHits(InfiniteHits);
