import React from 'react';
import {
  Button, FormGroup, Label, Input,
} from 'reactstrap';
import PropTypes from 'prop-types';

import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';

class ContactFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: props.contact || { attributes: {} },
      editing: false,
      errors: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.validate = this.validate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.startEditing = this.startEditing.bind(this);
    this.stopEditing = this.stopEditing.bind(this);
    this.updateContact = this.updateContact.bind(this);
    this.createContact = this.createContact.bind(this);
    this.handleUpdateCreateResponse = this.handleUpdateCreateResponse.bind(this);
    this.editableFields = this.editableFields.bind(this);
    this.staticFields = this.staticFields.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state.contact.attributes.full_name) {
      this.setState({ editing: true });
    }
  }

  handleInputChange(e) {
    const { contact: localContact } = this.state;
    _.extend(localContact.attributes, { [e.target.name]: e.target.value });
    this.setState({ contact: localContact });
  }

  handleCancel() {
    this.setState({ editing: false, errors: [] });

    // eslint-disable-next-line react/destructuring-assignment
    this.props.refreshOpportunityContactEmployment();
  }

  handleSave() {
    if (!this.validate()) {
      return;
    }

    const { contact } = this.state;

    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.contact) {
      this.updateContact(contact);
    } else {
      this.createContact(contact);
    }
  }

  handleUpdateCreateResponse(response) {
    const responseErrors = vapi.responseErrors(response);

    if (responseErrors) {
      this.setState({ errors: responseErrors });
    } else {
      this.stopEditing();

      // eslint-disable-next-line react/destructuring-assignment
      this.props.refreshOpportunityContactEmployment();
    }
  }

  updateContact(contact) {
    const that = this;
    vapi.updateContact(contact)
      .then((response) => {
        that.handleUpdateCreateResponse(response);
      });

    vahoy.track('Opportunity/Contact/ContactFields#updateContact');
  }

  createContact(contact) {
    const { opportunity, setContact } = this.props;

    vapi.createOpportunityContact(contact, opportunity.id)
      .then((response) => {
        this.handleUpdateCreateResponse(response);
        setContact(response.data.data.id);
      });

    vahoy.track('Opportunity/Contact/ContactFields#createOpportunityContact');
  }

  startEditing() {
    this.setState({ editing: true });
    vahoy.track('Opportunity/Contact/ContactField#startEditing');
  }

  stopEditing() {
    this.setState({ editing: false });
  }

  validate() {
    const { contact } = this.state;
    if (!contact.attributes.full_name
      || contact.attributes.full_name.replace(/\s/g, '').length === 0) {
      this.setState({ errors: [{ title: 'the name can not be empty' }] });
      return false;
    }

    this.setState({ errors: [] });
    return true;
  }

  editableFields() {
    const { contact, errors } = this.state;
    const contactAttrs = (contact || {}).attributes || {};

    return (
      <FormGroup>
        <Label for="contactFormName">Full name</Label>
        <Input
          id="contactFormName"
          type="text"
          required
          name="full_name"
          value={contactAttrs.full_name}
          placeholder="John Doe"
          onChange={this.handleInputChange}
        />
        <div className="mt-1 clearfix">
          <Button
            className="float-end"
            color="success"
            type="button"
            onClick={this.handleSave}
          >Save
          </Button>
          <Button
            className="float-end me-1"
            type="button"
            onClick={this.handleCancel}
          >Cancel
          </Button>
        </div>
        {errors.map((error, index) => <div className="form-text text-danger" key={index}>{error.title}</div>)}
      </FormGroup>
    );
  }

  staticFields() {
    // eslint-disable-next-line react/destructuring-assignment
    const contactAttrs = (this.state.contact || {}).attributes || {};
    const editButton = (
      <button
        id="edit-contact-name"
        type="button"
        className="btn btn-sm btn-link mb-1"
        onClick={this.startEditing}
      >
        <i className="fa-solid fa-pencil" />
      </button>
    );

    return (
      <FormGroup>
        <span>{contactAttrs.full_name}</span>
        <span>&nbsp;{editButton}</span>
      </FormGroup>
    );
  }

  render() {
    let fields;

    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.editing) {
      fields = this.editableFields();
    } else {
      fields = this.staticFields();
    }
    return fields;
  }
}

ContactFields.propTypes = {
  contact: PropTypes.instanceOf(Object),
  refreshOpportunityContactEmployment: PropTypes.func,
  opportunity: PropTypes.instanceOf(Object),
  setContact: PropTypes.func,
};

ContactFields.defaultProps = {
  contact: undefined,
  refreshOpportunityContactEmployment: undefined,
  opportunity: undefined,
  setContact: undefined,
};

export default ContactFields;
