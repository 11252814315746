import React from 'react';
import PropTypes from 'prop-types';
import avatarHelpers from '../javascript/frontend/helpers/avatar_helpers';

function WrapperLink({ children, href }) {
  return (
    <a role="button" href={href} className="d-block ratio ratio-1x1 my-2" style={{ maxHeight: '25rem' }}>
      {children}
    </a>
  );
}

WrapperLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

function ResponsiveAvatarUsingAspectRatio(props) {
  const {
    useInitials, avatarUrl, avatarLetters, className, emptyIcon, altText, linkHref,
  } = props;
  const clazz = className || '';
  const colorClass = avatarHelpers.colorClassFromInitials(avatarLetters);

  const renderAvatar = () => (
    <img
      src={avatarUrl}
      alt={altText}
      className={`avatar-responsive-aspect-ratio ${clazz}`}
    />
  );

  const renderDefaultAvatar = () => (
    <div>
      {useInitials && (
        <div className={`avatar-responsive-empty ${colorClass} ${clazz}`} style={{ borderRadius: '7px' }}>
          {avatarLetters}
        </div>
      )}
      {!useInitials && (
        <div className={`bg-gray-300 ${clazz} w-100 ratio ratio-1x1 text-white`} style={{ borderRadius: '7px', fontSize: '3em', maxHeight: '25rem' }}>
          <i className={`fa fa-${emptyIcon} fa-3x d-flex justify-content-center align-items-center w-100`} />
        </div>
      )}
    </div>
  );

  const renderedAvatar = avatarUrl ? renderAvatar() : renderDefaultAvatar();

  return (
    <>
      {linkHref && <WrapperLink href={linkHref}>{renderedAvatar}</WrapperLink>}
      {!linkHref && renderedAvatar}
    </>
  );
}

ResponsiveAvatarUsingAspectRatio.propTypes = {
  avatarUrl: PropTypes.string,
  altText: PropTypes.string.isRequired,
  avatarLetters: PropTypes.string,
  className: PropTypes.string,
  useInitials: PropTypes.bool,
  emptyIcon: PropTypes.string,
  linkHref: PropTypes.string,
};

ResponsiveAvatarUsingAspectRatio.defaultProps = {
  avatarUrl: undefined,
  className: undefined,
  useInitials: false,
  avatarLetters: '--',
  emptyIcon: 'user',
  linkHref: undefined,
};

export default ResponsiveAvatarUsingAspectRatio;
