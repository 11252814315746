import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EntityTypeSelector from './EntityTypeSelector';
import NewAttachedEntityForm from './NewAttachedEntityForm';
import UnspecifiedButton from './UnspecifiedButton';
import AttachEntityTable from './AttachEntityTable';
import SearchBar from './SearchBar';
import { PitchWizardContext } from '../../provider/pitch_wizard_provider';

function AttachEntityInline({
                              addExistingEntityToPitch,
                              addNewEntityToPitch,
                              attachedEntities,
                              onUnspecifiedButtonClick,
                              resetCounter,
                            }) {
  const [entityType, setEntityType] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [showList, setShowList] = useState(false);

  const { promoEntities } = useContext(PitchWizardContext);

  const displayTypes = {
    source: 'Expert',
    product: 'Product',
  };
  const displayType = entityType ? displayTypes[entityType.toLowerCase()] : undefined;

  const hideEntityList = () => {
    setEntityType(null);
    setShowList(false);
    setShowForm(false);
  };

  const showEntityList = (type) => {
    setEntityType(type);
    setShowList(true);
  };

  const toggleShowForm = () => {
    setShowForm(!showForm);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  useEffect(() => {
    setEntityType(null);
    setShowList(false);
    setShowForm(false);
  }, [resetCounter]);

  const searchedAndFilteredEntities = promoEntities.filter((entity) => {
    const isAlreadyAttached = attachedEntities?.some(
      (attached) => attached.existing?.promoEntityId === entity.id,
    );

    const matchesType = entity.type === entityType;
    const matchesSearch = entity.name.toLowerCase().includes(searchQuery);

    return matchesType && matchesSearch && !isAlreadyAttached;
  });

  const buttonId = `add_new_${entityType?.toLowerCase()}`;

  // TODO: validate that the promoentity will be able to be added on the server in a sort of "preflight" create
  return (
    <div style={{ maxWidth: '800px' }}>
      {!showForm && (
        <>
          {(!entityType || entityType === 'Source') && (
            <EntityTypeSelector
              onClick={() => showEntityList('Source')}
              entityType="Source"
              active={showList}
              onCancel={() => hideEntityList()}
            />
          )}
          {(!entityType || entityType === 'Product') && (
            <EntityTypeSelector
              onClick={() => showEntityList('Product')}
              entityType="Product"
              active={showList}
              onCancel={() => hideEntityList()}
            />
          )}
          {onUnspecifiedButtonClick && !entityType && <UnspecifiedButton onClick={() => onUnspecifiedButtonClick()} />}
        </>
      )}
      {showList && !showForm && (
        <>
          <div className="d-flex flex-column mb-2" style={{ marginTop: '-2em' }}>
            <button
              className="btn btn-primary text-nowrap align-self-end mb-2"
              id={buttonId}
              onClick={toggleShowForm}
              type="button"
            >
              <i className="fa-solid fa-plus" />
              &nbsp; Add New <strong>{displayType}</strong>
            </button>
            <SearchBar onSearch={handleSearch} />
          </div>
          <AttachEntityTable
            entities={searchedAndFilteredEntities}
            entityType={entityType}
            attach={addExistingEntityToPitch}
            toggleShowForm={toggleShowForm}
          />
        </>
      )}
      {showForm && (
        <NewAttachedEntityForm
          addNewEntityToPitch={addNewEntityToPitch}
          onCancel={() => toggleShowForm()}
          type={entityType}
        />
      )}
    </div>
  );
}

AttachEntityInline.propTypes = {
  addExistingEntityToPitch: PropTypes.func.isRequired,
  addNewEntityToPitch: PropTypes.func.isRequired,
  attachedEntities: PropTypes.arrayOf(PropTypes.object),
  onUnspecifiedButtonClick: PropTypes.func,
  resetCounter: PropTypes.number,
};

AttachEntityInline.defaultProps = {
  attachedEntities: [],
  onUnspecifiedButtonClick: undefined,
  resetCounter: undefined,
};

export default AttachEntityInline;
