import _ from 'lodash';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import expertHubImageUrl from '../../../../assets/images/expert-hub-powered-by.png';
import expertHubDemoImageUrl from '../../../../assets/images/expert-hub-demo.png';
import vapi from '../../../../javascript/frontend/api/vapi';

const requiredErrorText = 'This field is required';

function ClaimThisPageModal(props) {
  const { isOpen, toggle, companyId } = props;
  const [form, setForm] = React.useState({});
  const [submitted, setSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [serverResponse, setServerResponse] = React.useState({});

  const handleInputChange = (evt) => {
    const key = evt.target.name;
    const val = evt.target.value;

    setForm((previousForm) => ({ ...previousForm, [key]: val }));
  };

  const getFormErrors = () => {
    const errors = {};

    if (!form.name) {
      errors.name = requiredErrorText;
    }

    if (!form.email) {
      errors.email = requiredErrorText;
    }

    if (!form.phone) {
      errors.phone = requiredErrorText;
    }

    return errors;
  };

  const submit = (evt) => {
    evt && evt.preventDefault();

    const errors = getFormErrors();

    if (_.isEmpty(errors)) {
      setSubmitted(false);
      setIsLoading(true);
      setServerResponse({});

      vapi.connectWithCompany(companyId, form).then(() => {
        setIsLoading(false);
        toggle && toggle();
      }).catch((xhr) => {
        setSubmitted(true);
        setIsLoading(false);

        const errorResponse = {};

        if (xhr && xhr.response && xhr.response.data) {
          const responseData = xhr.response.data;
          if (responseData.errors) {
            errorResponse.errors = responseData.errors;
          } else {
            errorResponse.message = responseData.message || responseData.error || 'Something went wrong, please try again later.';
          }
        } else {
          errorResponse.message = 'Something went wrong, please try again later.';
        }

        setServerResponse(errorResponse);
      });
    } else {
      setSubmitted(true);
    }
  };

  let errors = {};
  let errorMessage;

  if (submitted) {
    errors = getFormErrors();

    if (_.isEmpty(errors)) {
      errors = serverResponse.errors || {};
      errorMessage = serverResponse.message;
    }
  }

  const renderError = (field) => {
    let errorText = errors[field];

    if (!errorText) {
      return;
    }

    if (_.isArray(errorText)) {
      errorText = errorText.join(', ');
    }

    return (
      <small className="form-text d-block text-danger">
        {errorText}
      </small>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="expert-hub-modal">
      <ModalBody className="p-0">
        <div className="container-fluid pg-white">
          <div className="row">
            <div className="col-md-7 left-pane text-center text-white">
              <div className="d-flex h-100">
                <div className="align-self-center w-100 mt-3">
                  <ul className="list-inline text-uppercase mb-2 features-list">
                    <li className="list-inline-item border-end-white pe-2">Customize</li>
                    <li className="list-inline-item border-end-white pe-2">Feature</li>
                    <li className="list-inline-item ">Connect</li>
                  </ul>
                  <img src={expertHubDemoImageUrl} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-md-5 right-pane pb-4">
              <div className="header text-end">
                <button
                  type="button"
                  className="btn font-size-22px mt-2 me-2 pb-0"
                  aria-label="Close"
                  onClick={toggle}
                >
                  <span aria-hidden="true" className="fa fa-xmark" />
                </button>
                <div className="text-center">
                  <img src={expertHubImageUrl} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="pt-3 pb-2 ps-5 pe-5 font-size-16px">Ready to level up your media strategy with
                real-time intelligence on reporter views & requests + improved SEO?
              </div>
              <div className="ps-5 pe-5">
                <form onSubmit={submit}>
                  <div className="form-group d-block">
                    <div className="text-danger">{errorMessage}</div>
                  </div>
                  <div className="form-group required">
                    <label className="form-label mb-0">First Name and Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={form.name || ''}
                      onChange={handleInputChange}
                    />
                    {renderError('name')}
                  </div>
                  <div className="form-group">
                    <label className="form-label mb-0">Job Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="job_title"
                      value={form.job_title || ''}
                      onChange={handleInputChange}
                    />
                    {renderError('job_title')}
                  </div>
                  <div className="form-group required">
                    <label className="form-label mb-0">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={form.email || ''}
                      onChange={handleInputChange}
                    />
                    {renderError('email')}
                  </div>
                  <div className="form-group required">
                    <label className="form-label mb-0">Phone Number</label>
                    <input
                      type="phone"
                      className="form-control"
                      name="phone"
                      value={form.phone || ''}
                      onChange={handleInputChange}
                    />
                    {renderError('phone')}
                  </div>
                  <div className="form-group text-center d-grid">
                    <button type="submit" disabled={isLoading} className="btn mt-3 btn-primary">Get Started
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

ClaimThisPageModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ClaimThisPageModal.defaultProps = {
  isOpen: undefined,
  toggle: undefined,
  companyId: undefined,
};

export default ClaimThisPageModal;
