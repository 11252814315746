/* eslint no-console:0 */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// https://babeljs.io/docs/en/next/babel-polyfill.html
// eslint-disable-next-line import/no-extraneous-dependencies
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// KT: This allows us to use $ to reference jQuery from tests and in other places.
// See: https://github.com/webpack-contrib/expose-loader#examples
// require("expose-loader?exposes=$,jQuery!jquery");
// import $ from 'jquery';
// import moment from 'moment';
// global.$ = global.jQuery = $;
// window.$ = window.jQuery = $;

// import so as to configure properly before before jQuery's ready event fires https://github.com/ankane/ahoy.js/issues/9
import '../javascript/vahoy';
import '../javascript/ga_tracking';

import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

import 'bootstrap';
import 'popper.js';
import 'fullcalendar';

// this exposes timezoneHelpers as window.timezoneHelpers
import '../javascript/frontend/helpers/timezone_helpers';
import '../javascript/frontend/helpers/google_tag_manager_helpers';

import '../javascript/frontend/pages';
import '../javascript/frontend/config';
import '../javascript/frontend/vendor';

import '../javascript/frontend/v2/supabase_client';

Rails.start();
Turbolinks.start();

// KT: This allows us to use $ to reference jQuery from tests and in other places.
// See: https://github.com/webpack-contrib/expose-loader#examples
// eslint-disable-next-line import/no-webpack-loader-syntax
// require('expose-loader?$!jquery');

// Exclude everything in the __tests__ directory.
const componentRequireContext = require.context('react_components', true, /^((?!__tests__).)*$/);
const ReactRailsUJS = require('react_ujs');

// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);

// https://trello.com/c/06ubrxae/1284-month-error-page-suspect-incomplete-implementation-of-custom-error-pages-is-causing-error-pages-to-not-render-when-navigated-to
// Monkey patch Turbolinks to render 403, 404 & 500 normally
// See https://github.com/turbolinks/turbolinks/issues/179
window.Turbolinks.HttpRequest.prototype.requestLoaded = function requestLoaded() {
  return this.endRequest(() => {
    const code = this.xhr.status;
    if ((code >= 200 && code < 300)
        || code === 403 || code === 404 || code === 500) {
      this.delegate.requestCompletedWithResponse(
        this.xhr.responseText,
        this.xhr.getResponseHeader('Turbolinks-Location'),
      );
    } else {
      this.failed = true;
      this.delegate.requestFailedWithStatusCode(code, this.xhr.responseText);
    }
  });
};

document.addEventListener('turbolinks:load', () => {
  ReactRailsUJS.mountComponents();
  ReactRailsUJS.detectEvents();
});
