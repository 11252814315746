import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import vapi from '../../../javascript/frontend/api/vapi';
import SelectHashtags from './select_hashtags';
import MultipleHashtags from './multiple_hashtags';

function AdminHashtagsEditor({ sourceRequestId }) {
  const [requestHashtags, setRequestHashtags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [sortBy, setSortBy] = useState('users_tagged_desc');
  const [needsRefresh, setNeedsRefresh] = useState(true);
  const [deletingHashtags, setDeletingHashtags] = useState(new Set());

  useEffect(() => {
    const fetchHashtags = async () => {
      try {
        setIsLoading(true);
        const params = { id: sourceRequestId };
        const response = await vapi.getSourceRequestHashtags(params);
        const hashtags = response.data.data || [];

        switch (sortBy) {
          case 'users_tagged_desc':
            hashtags.sort((a, b) => (b.users_manually_tagged + b.users_automatically_tagged)
              - (a.users_manually_tagged + a.users_automatically_tagged));
            break;
          case 'hashtag_tagged_at_desc':
            hashtags.sort((a, b) => new Date(b.hashtag_tagged_at) - new Date(a.hashtag_tagged_at));
            break;
          default:
            // Optionally handle default case
            break;
        }

        setRequestHashtags(hashtags);
      } catch (error) {
        alert('Error fetching source request hashtags:');
        throw error;
      } finally {
        setIsLoading(false);
        setNeedsRefresh(false);
      }
    };

    fetchHashtags();
  }, [sourceRequestId, sortBy, needsRefresh]);

  const totalUsersTagged = requestHashtags.reduce((sum, hashtag) => sum + hashtag.users_manually_tagged
    + hashtag.users_automatically_tagged, 0);

  const totalUsersManuallyTagged = requestHashtags
    .filter((hashtag) => hashtag.request_hashtag_type === 'manual')
    .reduce((sum, hashtag) => sum + hashtag.users_manually_tagged + hashtag.users_automatically_tagged, 0);

  const totalUsersAutomaticallyTagged = requestHashtags
    .filter((hashtag) => hashtag.request_hashtag_type === 'automatic')
    .reduce((sum, hashtag) => sum + hashtag.users_manually_tagged + hashtag.users_automatically_tagged, 0);

  const handleDeleteHashtag = async (hashtagTagId) => {
    setDeletingHashtags((current) => new Set(current.add(hashtagTagId)));
    try {
      await vapi.deleteHashtagTag(hashtagTagId, 'hashtag_tags');
      setNeedsRefresh(true);
    } catch (error) {
      alert(`Failed to delete hashtag: ${error.toString()}`);
    }
    setDeletingHashtags((current) => {
      const newSet = new Set(current);
      newSet.delete(hashtagTagId);
      return newSet;
    });
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const refresh = () => {
    setSortBy('hashtag_tagged_at_desc');
    setNeedsRefresh(true);
  };

  const formatDate = (dateString) => moment(dateString).format('l');

  return (
    <>
      <h6>Manage hashtags</h6>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggleTab('1');
            }}
          >
            Search
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggleTab('2');
            }}
          >
            Paste Multiple Hashtags
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <FormGroup>
            <SelectHashtags sourceRequestId={sourceRequestId} onHashtagAdded={refresh} />
          </FormGroup>
        </TabPane>
        <TabPane tabId="2">
          <MultipleHashtags sourceRequestId={sourceRequestId} onHashtagAdded={refresh} />
        </TabPane>
      </TabContent>

      <div className="mb-2" />

      {isLoading && (
        <div>Loading...</div>
      )}

      {!isLoading && (
        <>
          <p>
            Users tagged
            from manual (green) tags: {totalUsersManuallyTagged};
            Auto (dark) tags: {totalUsersAutomaticallyTagged};
            Total: <strong>{totalUsersTagged}</strong>
          </p>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th># users manually tagged</th>
                <th># users auto tagged</th>
                <th># total</th>
                {sortBy === 'hashtag_tagged_at_desc' && <th>Tagged at</th>}
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {requestHashtags
                .filter((hashtag) => hashtag.users_manually_tagged > 0 || hashtag.users_automatically_tagged > 0)
                .map((hashtag) => (
                  <tr key={hashtag.id}>
                    <td
                      className={hashtag.request_hashtag_type === 'manual' ? 'text-success' : ''}
                    >
                      <strong>
                        {hashtag.hashtag}
                      </strong>
                    </td>
                    <td>{hashtag.users_manually_tagged}</td>
                    <td>{hashtag.users_automatically_tagged}</td>
                    <td>{hashtag.users_manually_tagged + hashtag.users_automatically_tagged}</td>
                    {sortBy === 'hashtag_tagged_at_desc' && (<td>{formatDate(hashtag.hashtag_tagged_at)}</td>)}
                    <td>
                      {deletingHashtags.has(hashtag.hashtag_tagged_id) ? (
                        <div className="spinner-border spinner-border-sm text-danger" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-link p-0"
                          onClick={() => handleDeleteHashtag(hashtag.hashtag_tagged_id)}
                          onKeyDown={(e) => e.key === 'Enter'
                            && handleDeleteHashtag(hashtag.hashtag_tagged_id)}
                          tabIndex="0"
                        >
                          <i className="fa-solid fa-trash-can text-danger" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}

AdminHashtagsEditor.propTypes = {
  sourceRequestId: PropTypes.number.isRequired,
};

export default AdminHashtagsEditor;
