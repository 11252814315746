import React from 'react';
import PropTypes from 'prop-types';

function ContactInfo({ source, smallMode }) {
  const liClasses = 'list-group-item auto-truncate text-nowrap';
  const hasContactInfo = source.email || source.twitter || source.phone || source.location_string;

  if (!hasContactInfo) return null;

  return (
    <>
      <ul
        className="list-group list-group-borderless list-group-xs"
        style={{ fontSize: smallMode ? '75%' : '85%' }}
      >
        {source.twitter && (
          <li className={liClasses}>
            <a
              href={`https://twitter.com/${source.twitter}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-x-twitter me-1" />
              {source.twitter}
            </a>
          </li>
        )}
        {source.phone && (
          <li className={liClasses}>
            <a href={`tel:${source.phone}`}>
              <i className="fa-solid fa-phone me-1" />
              {source.phone}
            </a>
          </li>
        )}
        {source.location_string && (
          <li className={liClasses}>
            <a
              href={`https://www.google.com/maps?q=${source.location_string}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-solid fa-location-dot me-1" />
              {source.location_string}
            </a>
          </li>
        )}
      </ul>
      <div className="clearfix mb-2" />
    </>
  );
}

ContactInfo.propTypes = {
  source: PropTypes.instanceOf(Object).isRequired,
  smallMode: PropTypes.bool,
};

ContactInfo.defaultProps = {
  smallMode: false,
};

export default ContactInfo;
