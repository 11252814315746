/* eslint-disable react/prop-types, react/no-unstable-nested-components, react/jsx-no-bind, no-unused-vars, jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  connectHits,
} from 'react-instantsearch-dom';
import CopyText from '../copy_text';
import Avatar from '../avatar/avatar';
import vahoy from '../../javascript/vahoy';

const ReporterSearchHit = connectHits(({
                                         hits,
                                         toggleReporter,
                                         selectedReporterGids,
                                         selectAllChecked,
                                         setHits,
                                         isAdmin,
                                       }) => {
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    const newTimeout = setTimeout(() => {
      vahoy.track('media_db_reporters/ReporterSearchHit#agoliaReporterSearch');
    }, 3000);

    setDebounceTimeout(newTimeout);
    return () => clearTimeout(newTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hits]); // Dependency array, effect will re-run if `hits` changes

  setHits(hits);

  function formatHashtag(hashtag) {
    // Remove the pound sign
    let formatted = hashtag.replace(/^#/, '');

    // Split camel case into words and join with space
    formatted = formatted.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Convert to lowercase
    return formatted.toLowerCase();
  }

  function formatHashtagList(hashtagList) {
    const str = String(hashtagList);

    // Split the list into an array of hashtags
    const hashtags = str.split(',').map((s) => s.trim());

    // Format each hashtag and join them back into a string
    return hashtags.map(formatHashtag).join(', ');
  }

  function getTruncatedList(input, limit) {
    const str = String(input);

    const elements = str.split(',');
    let result = '';
    let currentLength = 0;

    for (const element of elements) {
      const additionalLength = element.length + (currentLength > 0 ? 1 : 0);
      if (currentLength + additionalLength <= limit) {
        result += (currentLength > 0 ? ', ' : '') + element;
        currentLength += additionalLength;
      } else {
        break;
      }
    }
    return result;
  }

  function emailCopied() {
    vahoy.track('media_db_reporters/ReporterSearchHit#emailCopied');
  }

  function phoneCopied() {
    vahoy.track('media_db_reporters/ReporterSearchHit#phoneCopied');
  }

  return (
    <>
      {hits.map((hit) => {
        const gid = hit.data_source;
        const gidForEmail = `${gid.replace(/[^a-zA-Z0-9-_]/g, '_')}-email`;
        const gidForPhone = `${gid.replace(/[^a-zA-Z0-9-_]/g, '_')}-phone`;

        const charLimit = 80;
        const initials = `${hit.first_name?.charAt(0)}${hit.last_name?.charAt(0)}`;

        const linkedInUrl = hit.linkedin;
        const xUrl = hit.twitter;
        let linkedInHandle = null;
        let xHandle = null;

        if (linkedInUrl) {
          const parts = linkedInUrl.split('/');
          linkedInHandle = parts[parts.length - 1];
        }

        if (xUrl) {
          const parts = xUrl.split('/');
          xHandle = parts[parts.length - 1];
          xHandle = (xHandle && xHandle.startsWith('@')) ? xHandle.substring(1) : xUrl;
        }

        const regex = /\w+\/([^/]+)\//;
        const match = gid.match(regex);
        const dataSourceType = match ? match[1] : null;

        return (
          <tr key={gidForEmail}>
            <td className="ps-2 d-md-table-cell d-block border-0 border-bottom-md">
              <div className="d-flex flex-row align-items-center">
                <input
                  type="checkbox"
                  className="form-check-input my-auto d-none d-md-block me-3"
                  checked={selectedReporterGids[gid] || selectAllChecked}
                  onChange={() => toggleReporter(gid)}
                />
                <div className="d-flex flex-column flex-lg-row">
                  <Avatar
                    avatarUrl={hit.headshot_url}
                    sizeClass="avatar-sm"
                    avatarLetters={initials}
                  />
                  <div className="fw-bold pt-md-3" style={{ minWidth: '150px', lineHeigh: '1' }}>
                    {hit.first_name}&nbsp;{hit.last_name}
                  </div>
                </div>
              </div>
            </td>
            <td className="d-xl-table-cell d-md-none d-block border-0 border-bottom-md">
              {!hit.title && 'reporter'}
              {hit.title && hit.title.length > 0 && (
                <div style={{ width: '125px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {hit.title}
                </div>
              )}
            </td>
            <td className="d-xl-table-cell d-md-none d-block border-0 border-bottom-md">
              {hit.publications && hit.publications.length > 0 && (
                <div>
                  {getTruncatedList(hit.publications, charLimit)}
                </div>
              )}
            </td>
            <td className="d-md-table-cell d-xl-none d-none border-0 border-bottom-md">
              {!hit.title && 'reporter'}
              {hit.title && hit.title.length > 0 && (
                <div style={{
 fontWeight: 'bold', width: '125px', overflow: 'hidden', textOverflow: 'ellipsis',
}}
                >
                  {hit.title}
                </div>
              )}
              {hit.publications && hit.publications.length > 0 && (
                <div className="fst-italic">
                  {getTruncatedList(hit.publications, charLimit)}
                </div>
              )}
            </td>
            <td className="d-md-table-cell d-block border-0 border-bottom-md small">
              {hit.topics && hit.topics.length > 0 && (
                <div>{getTruncatedList(formatHashtagList(hit.topics), charLimit)}</div>
              )}
            </td>
            <td className="d-md-table-cell d-block align-top border-0 border-bottom-md pb-0 pb-md-2">
              {hit.email && (
                <div className="link-primary overflow-hidden lh-lg" style={{ height: '2em' }}>
                  <a href={`mailto:${hit.email}`}>{hit.email}</a>
                  <CopyText
                    successCallback={emailCopied}
                    text={hit.email}
                    tooltipText="Copied!"
                    uniqueId={`obj-${gidForEmail}`}
                  />
                </div>
              )}
              {hit.phone && (
                <div className="link-primary overflow-hidden lh-lg" style={{ height: '2em' }}>
                  <a href={`phone:${hit.phone}`}>{hit.phone}</a>
                  <CopyText
                    successCallback={phoneCopied}
                    text={hit.phone}
                    tooltipText="Copied!"
                    uniqueId={`obj-${gidForPhone}`}
                  />
                </div>
              )}
              {hit.location_string && (
                <div className="link-primary overflow-hidden lh-lg" style={{ height: '2em' }}>
                  <i className="fa-solid fa-location-dot" />
                  &nbsp;
                  {hit.location_string}
                </div>
              )}
            </td>
            <td className="d-md-table-cell d-block align-top pb-0 pb-md-2 pt-0 pt-md-2">
              {xHandle && (
                <div className="link-primary overflow-hidden lh-lg mt-0 mt-md-3" style={{ height: '2em' }}>
                  <a
                    href={`https://x.com/${xHandle}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-x-twitter" />
                  </a>
                  &nbsp;
                  <a
                    href={`https://x.com/${xHandle}`}
                    className="d-inline d-md-none d-xl-inline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {xHandle}
                  </a>
                </div>
              )}
              {linkedInHandle && (
                <div className="link-primary overflow-hidden lh-lg mt-0 mt-md-2 mb-0 mb-md-3" style={{ height: '2em' }}>
                  <a
                    href={`https://www.linkedin.com/in/${linkedInHandle}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-linkedin" />
                  </a>
                  <a
                    href={`https://www.linkedin.com/in/${linkedInHandle}`}
                    className="d-inline d-md-none d-xl-inline ms-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                      {linkedInHandle}
                  </a>
                </div>
              )}
            </td>
            {isAdmin && (
              <td className="d-md-table-cell d-block border-0 border-bottom-md">
                {dataSourceType === 'MediaDbReporter' && (
                  <>
                    <a
                      className="btn btn-sm btn-staff mb-md-1 me-1"
                      href={`/media_db_reporters/${gid.split('/').pop()}/edit`}
                      rel="noreferrer"
                    >
                      Edit
                    </a>
                    {!hit.visible && (
                      <a
                        className="btn btn-sm btn-staff"
                        href={`/media_db_reporters/${gid.split('/').pop()}/hide?visible=true`}
                        rel="noreferrer"
                      >
                        Unhide
                      </a>
                    )}
                    {hit.visible && (
                      <a
                        className="btn btn-sm btn-staff"
                        href={`/media_db_reporters/${gid.split('/').pop()}/hide?visible=false`}
                        rel="noreferrer"
                      >
                        Hide
                      </a>
                    )}
                  </>
                )}
                {dataSourceType === 'Reporter' && (
                  <>
                    <a
                      className="btn btn-sm btn-staff"
                      href={`/reporters/${gid.split('/').pop()}`}
                      rel="noreferrer"
                    >
                      Reporter profile
                    </a>
                  </>
                )}
              </td>
            )}
          </tr>
        );
      })}
    </>
  );
});

export default ReporterSearchHit;
/* eslint-enable react/prop-types, react/no-unstable-nested-components, react/jsx-no-bind, no-unused-vars */
