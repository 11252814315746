import React from 'react';
import {
  FormGroup, Button, Label, Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';

class OrganizationFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organization: props.organization || { attributes: {} },
      editing: false,
      errors: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.validate = this.validate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.startEditing = this.startEditing.bind(this);
    this.stopEditing = this.stopEditing.bind(this);
    this.updateOrganization = this.updateOrganization.bind(this);
    this.createOrganization = this.createOrganization.bind(this);
    this.handleUpdateCreateResponse = this.handleUpdateCreateResponse.bind(this);
    this.editableFields = this.editableFields.bind(this);
    this.staticFields = this.staticFields.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line
    if (!this.state.organization.attributes.name) {
      this.setState({ editing: true });
    }
  }

  handleInputChange(e) {
    const { organization: localOrganization } = this.state;
    _.extend(localOrganization.attributes, { [e.target.name]: e.target.value });
    this.setState({ organization: localOrganization });
  }

  handleCancel() {
    this.setState({ editing: false, errors: [] });

    // eslint-disable-next-line react/destructuring-assignment
    this.props.refreshOpportunityContactEmployment();
  }

  handleSave() {
    if (!this.validate()) {
      return;
    }

    const { organization } = this.state;

    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.organization) {
      this.updateOrganization(organization);
    } else {
      this.createOrganization(organization);
    }
  }

  handleUpdateCreateResponse(response) {
    const responseErrors = vapi.responseErrors(response);

    if (responseErrors) {
      this.setState({ errors: responseErrors });
    } else {
      this.stopEditing();

      // eslint-disable-next-line react/destructuring-assignment
      this.props.refreshOpportunityContactEmployment();
    }
  }

  updateOrganization(organization) {
    vapi.updateOrganization(organization)
      .then((response) => {
        this.handleUpdateCreateResponse(response);
      });

    vahoy.track('Opportunity/Organization/OrganizationFields#updateOrganization');
  }

  createOrganization(organization) {
    const { opportunity, setOrganization } = this.props;

    vapi.createOpportunityOrganization(organization, opportunity.id)
      .then((response) => {
        this.handleUpdateCreateResponse(response);
        setOrganization(response.data.data.id);
      });

    vahoy.track('Opportunity/Organization/OrganizationFields#createOpportunityOrganization');
  }

  startEditing() {
    this.setState({ editing: true });
  }

  stopEditing() {
    this.setState({ editing: false });
  }

  validate() {
    const { organization } = this.state;

    if (!organization.attributes.name
      || organization.attributes.name.replace(/\s/g, '').length === 0) {
      this.setState({ errors: [{ title: 'the name can not be empty' }] });
      return false;
    }

    this.setState({ errors: [] });
    return true;
  }

  editableFields() {
    const { organization, errors } = this.state;
    const organizationAttrs = (organization || {}).attributes || {};

    return (
      <FormGroup>
        <Label for="name">Name</Label>
        <Input
          type="text"
          required
          id="name"
          name="name"
          value={organizationAttrs.name}
          placeholder="My Organization Name"
          onChange={this.handleInputChange}
        />
        <div className="mt-1 clearfix">
          <Button
            className="float-end"
            type="button"
            color="primary"
            onClick={this.handleSave}
          >Save
          </Button>
          <Button
            className="float-end me-1"
            type="button"
            onClick={this.handleCancel}
          >Cancel
          </Button>
        </div>
        {errors.map((error, index) => <div className="form-text text-danger" key={index}>{error.title}</div>)}
      </FormGroup>
    );
  }

  staticFields() {
    // eslint-disable-next-line react/destructuring-assignment
    const organizationAttrs = (this.state.organization || {}).attributes || {};
    const editButton = (
      <button
        id="edit-organization-name"
        type="button"
        className="btn btn-sm btn-link mb-1"
        onClick={this.startEditing}
      >
        <i className="fa-solid fa-pencil" />
      </button>
    );

    return (
      <FormGroup>
        <span>{organizationAttrs.name}</span>
        <span>&nbsp;{editButton}</span>
      </FormGroup>
    );
  }

  render() {
    let fields;
    // const { organization } = this.state;

    // {
    //   organization && organization.id
    //   && (
    //   <div className="alert alert-warning" role="alert">
    //     Be aware, when you edit this name, you're editing the name of the entire organization which
    //     may be associated with many opportunities and contacts.
    //   </div>
    //   );
    // }

    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.editing) {
      fields = this.editableFields();
    } else {
      fields = this.staticFields();
    }

    return fields;
  }
}

OrganizationFields.propTypes = {
  organization: PropTypes.instanceOf(Object),
  opportunity: PropTypes.instanceOf(Object),
  setOrganization: PropTypes.func,
  refreshOpportunityContactEmployment: PropTypes.func.isRequired,
};

OrganizationFields.defaultProps = {
  organization: undefined,
  opportunity: undefined,
  setOrganization: undefined,
};

export default OrganizationFields;
