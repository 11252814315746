import React from 'react';
import PropTypes from 'prop-types';
import { connectStateResults } from 'react-instantsearch-dom';
import CompanySkeletonPage from '../company_skeleton_page';

function SearchLoadingCards({ searchResults }) {
  const isLoading = !searchResults;

  if (!isLoading) return null;

  return (
    <CompanySkeletonPage numberOfCards={12} />
  );
}

SearchLoadingCards.propTypes = {
  searchResults: PropTypes.object,
};

SearchLoadingCards.defaultProps = {
  searchResults: {},
};

export default connectStateResults(SearchLoadingCards);
