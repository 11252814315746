import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import distance from 'jaro_winkler';
import Select2Ajax from '../../../../select2_ajax';
import vahoy from '../../../../../javascript/vahoy';
import { PitchWizardContext } from '../../../provider/pitch_wizard_provider';
import ConfirmModal from '../../../../confirm_modal';

function Form({ onSubmit, onCancel }) {
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [error, setError] = useState({ name: '' });
  const [isSelfExpert, setIsSelfExpert] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const [name, setName] = useState('');
  const [showConfirmSimilarNameModal, setShowConfirmSimilarNameModal] = useState(false);
  const { user } = useContext(PitchWizardContext);

  const validateForm = () => {
    const newError = { name: '' };
    let isValid = true;

    if (!name) {
      newError.name = 'Name is required.';
      isValid = false;
    } else if (name.length < 2) {
      newError.name = 'Name must be at least 2 characters long.';
      isValid = false;
    }

    setError(newError);
    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission

    if (validateForm()) {
      onSubmit('Source', {
        companyId, companyName, isSelfExpert, jobTitle, name,
      });
    }
  };

  // TODO: duplicated in both source and product form. Could extract.
  const prepareCompaniesApiResponseForSelect2 = (response) => {
    const results = (response.data || []).map((result) => {
      const r = { ...result };
      r.text = result.attributes.name;
      return r;
    });

    return {
      results,
      pagination: {
        more: !!(response.links || {}).next,
      },
    };
  };

  // TODO: duplicated in both source and product form. Could extract.
  const handleCompanyChange = (e) => {
    const selectedCompanyId = jQuery(e.target).val();
    setCompanyId(selectedCompanyId);

    const enteredOrSelectedcompanyName = jQuery(e.target).find('option:selected').text();
    setCompanyName(enteredOrSelectedcompanyName);

    vahoy.track('product/Form#handleCompanyChange');
  };

  const compareFullName = (enteredFullName) => { // TODO: need to port over this self-expert UI bugfix thingy
    const userFullName = user.full_name;

    if (enteredFullName) {
      // eslint-disable-next-line no-mixed-operators
      const distanceValue = userFullName && distance(userFullName, enteredFullName) || 0;
      setShowConfirmSimilarNameModal(distanceValue >= 0.8);
    }
  };

  const confirmedAsSelfSource = () => {
    setIsSelfExpert(true);
    setShowConfirmSimilarNameModal(false);
  };

  const confirmedNotSelfSourceAndSimilarNameEnteredInError = () => {
    setName('');
    setIsSelfExpert(false);
    setShowConfirmSimilarNameModal(false);
    vahoy.track('PitchForm#noToConfirmSimilarName');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="required row mb-3">
        <label className="col-12 col-md-3 col-form-label" htmlFor="entity_name">
          Name
        </label>
        <div className="col-12 col-md-9">
          <input
            className={`form-control ${error.name ? 'is-invalid' : ''}`}
            id="entity_name"
            onBlur={(e) => compareFullName(e.target.value)}
            onChange={(e) => setName(e.target.value)}
            placeholder="Expert's name"
            required={true}
            type="text"
            value={name}
          />
          {error.name && <div className="invalid-feedback">{error.name}</div>}
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-12 col-md-3 col-form-label" htmlFor="entity_company">
          Company
        </label>
        <div className="col-12 col-md-9">
          <Select2Ajax
            ajaxProcessResults={prepareCompaniesApiResponseForSelect2}
            ajaxUrl="/api/internal/jsonapi/companies?policy_used=associable"
            className="form-select"
            existingOnly={false}
            onChange={handleCompanyChange}
            placeholderText="Company name (type to search...)" // TODO: port over these UI details
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-12 col-md-3 col-form-label" htmlFor="expert_title">
          Job title
        </label>
        <div className="col-12 col-md-9">
          <input
            className="form-control"
            id="expert_title"
            maxLength="80"
            onChange={(e) => setJobTitle(e.target.value)}
            placeholder="Expert's job title"
            type="text"
            value={jobTitle}
          />
        </div>
      </div>

      <div className="mt-3">
        <button type="submit" className="btn btn-primary me-2">Add Expert</button>
        <button type="button" className="btn btn-outline-danger" onClick={onCancel}>
          Cancel
        </button>
      </div>

      {showConfirmSimilarNameModal && (
        <ConfirmModal
          confirmYesText="Don't change name"
          confirmNoText="Change name"
          confirmHeaderContent="Are you the expert?"
          confirmContent="The expert's name is very similar to your own name. This field should contain the name of the expert to be quoted by the media."
          confirmYes={confirmedAsSelfSource}
          confirmNo={confirmedNotSelfSourceAndSimilarNameEnteredInError}
          isOpening={showConfirmSimilarNameModal}
          className="p-3"
          confirmNoButtonClassName="btn-primary"
        />
      )}
    </form>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

Form.defaultProps = {
  onSubmit: () => {
  },
  onCancel: () => {
  },
};

export default Form;
