import { useState } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../javascript/frontend/api/vapi';
import vahoy from '../../javascript/vahoy';

function useFavorite({
  initiallyFavorited,
  initialFavoriteId,
  ahoyObjectGid,
  page,
  favoriteableId,
  favoriteableType,
  handleSaveProduct = () => {},
}) {
  const [isFavorited, setIsFavorited] = useState(initiallyFavorited);
  const [currentFavoritedId, setCurrentFavoritedId] = useState(initialFavoriteId);

  const favorite = () => {
    vapi
      .createFavorite(favoriteableId, favoriteableType, window.CURRENT_USER_JSON.id)
      .then((response) => {
        setIsFavorited(true);
        setCurrentFavoritedId(response.data.data.id);
        vahoy.track('FavoriteButton#favorite', { object_gid: ahoyObjectGid, page });
        handleSaveProduct();
      })
      .catch((error) => {
        throw new Error(`FavoriteButton#favorite: ${error}`);
      });
  };

  const unfavorite = () => {
    vapi
      .deleteFavorite(currentFavoritedId)
      .then(() => {
        setIsFavorited(false);
        setCurrentFavoritedId(null);
        vahoy.track('FavoriteButton#unfavorite', { objectGid: ahoyObjectGid, page });
        handleSaveProduct();
      })
      .catch((error) => {
        throw new Error(`FavoriteButton#unfavorite: ${error}`);
      });
  };

  const toggleFavoritedStatus = () => {
    isFavorited ? unfavorite() : favorite();
  };

  return { isFavorited, toggleFavoritedStatus };
}

// Headless component allowing users to favorite/unfavorite (a.k.a follow/unfollow),
// and see the favorited status of any instance whose model imports the Favoriteable
// concern. The UI variability prompted the extraction of this headless component.

useFavorite.propTypes = {
  favoriteableId: PropTypes.number.isRequired, // Id of favoriting target
  favoriteableType: PropTypes.string.isRequired, // Object type of favoriting target (ex: 'PrUser')
  initialFavoriteId: PropTypes.number, // Primary key of favorite record in the favorites table
  initiallyFavorited: PropTypes.bool.isRequired, // Whether or not the target is currently favorited on load
  ahoyObjectGid: PropTypes.string.isRequired, // Global id for user being favorited
  page: PropTypes.string,
  handleSaveProduct: PropTypes.func, // Function from parent to perform additional actions after save
};

export default useFavorite;
