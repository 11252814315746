import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import vapi from '../javascript/frontend/api/vapi';
import vahoy from '../javascript/vahoy';

class ProductImages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      loading: true,
      images: [],
      uploading: false,
    };

    this.reloadImages = this.reloadImages.bind(this);
    this.openCloudinaryWidget = this.openCloudinaryWidget.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.renderUploadButton = this.renderUploadButton.bind(this);
    this.renderImage = this.renderImage.bind(this);
  }

  componentDidMount() {
    this.reloadImages();
  }

  // eslint-disable-next-line react/sort-comp
  reloadImages() {
    // eslint-disable-next-line react/destructuring-assignment
    vapi.getProductImages(this.props.product_id)
      .then((response) => {
        this.setState({
          deleting: false,
          loading: false,
          images: response.data.data,
          uploading: false,
        });
      });
  }

  openCloudinaryWidget(evt) {
    evt && evt.preventDefault();
    const that = this;
    const { product_id: productId } = this.props;

    if (!window.CLOUDINARY_DEFAULT_UNSIGNED_UPLOAD_PREFIX) {
      alert('Uploading is disabled on Heroku Review apps. CLOUDINARY_DEFAULT_UNSIGNED_UPLOAD_PREFIX does not exist by default in the Cloudinary instances associated with Review apps.');
    } else {
      // Documentation: https://cloudinary.com/documentation/upload_widget
      // Last event: Aug 2022, https://support.cloudinary.com/hc/en-us/articles/360009420291-How-To-Migrate-To-The-Upload-Widget-v2-0
      // KT: I found that it does not work properly in development mode: once you upload a file, the "success" event
      // never fires. This makes it difficult to work with because there is a long iteration time as you have to push
      // all your changes to staging in order to test.
      const widget = window.cloudinary.createUploadWidget(
        {
          // NOTE: Does this value need to be matched with product_headshot_uploader#public_id?
          folder: `${window.CLOUDINARY_FOLDER_PREFIX}/products/${productId}/product-images`,
          max_file_size: '20000000',
          multiple: true,
          show_powered_by: false,
          products: ['local', 'camera', 'url'],
          uploadPreset: window.CLOUDINARY_DEFAULT_UNSIGNED_UPLOAD_PREFIX,
          reproduct_type: 'image',
          cloudName: window.CLOUDINARY_CLOUD_NAME,
        },
        // Cloudinary upload widget callback
        (error, result) => {
          if (error) {
            that.setState({ uploading: false });
            that.reloadImages();
            if (error.message === 'User closed widget') {
              return;
            }
            throw new Error(`ProductImages#createProductImage: ${error}`);
          }

          if (result.event === 'success') {
            that.setState({ uploading: true });

            const originalFilename = result.info.original_filename;
            const processedFilename = originalFilename.replace(/[^a-zA-Z0-9]/g, '');
            const metadataCloudinary = {
              ...result.info,
              original_filename: processedFilename,
            };
            const promise = vapi.createProductImage(productId, metadataCloudinary);

            promise.catch((err) => {
              that.setState({ uploading: false });
              throw new Error(`ProductImages#createProductImage: ${err}`);
            }).then(() => {
              // Just refresh the images after each upload, if it happens multipple times, that is not a big deal.
              that.reloadImages();
              that.setState({ uploading: false });
            });
          }
        },
      );

      widget.open();
      vahoy.track('ProductImages#openCloudinaryWidget');
    }
  }

  deleteImage(headshotRailsId, e) {
    const that = this;
    e && e.preventDefault();
    this.setState({ deleting: true });

    // API call to Rails app to delete our pointer to the Cloudinary object, Cloudinary Carrierwave integration will
    // handle deleting it from Cloudinary via the server ... :)
    vapi.deleteProductImage(headshotRailsId)
      .then(() => {
        that.reloadImages();
      })
      .catch((error) => {
        that.reloadImages();
        throw new Error(`ProductImages#deleteProductImage: ${error}`);
      });

    vahoy.track('ProductImages#deleteImage');
  }

  trackClicks = () => {
    vahoy.track('ProductImages#clickImageLink');
  };

  downloadImage(url) {
    return axios
      .get(url, { responseType: 'arraybuffer' })
      .then((response) => {
        const path = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = path;
        link.setAttribute('download', url.split('/').slice(-1)[0]);
        link.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 0);
      });
  }

  renderUploadButton() {
    const { uploading, deleting } = this.state;

    return (
      <small className="align-self-center ms-auto">
        {uploading
          && (
            <button className="btn btn-outline-primary btn-sm" disabled type="button">
              <i className={`fa-solid fa-fw ${window.SITE_DEFAULT_ANIMATED_SPINNER}`} />
              Uploading
            </button>
          )}
        {deleting
          && (
            <button className="btn btn-outline-danger btn-sm" disabled type="button">
              <i className={`fa-solid fa-fw ${window.SITE_DEFAULT_ANIMATED_SPINNER}`} />
              Deleting
            </button>
          )}
        {!uploading && !deleting
          && (
            <span
              onClick={this.openCloudinaryWidget}
              onKeyUp={(evt) => evt.keyCode == 13 && this.openCloudinaryWidget(evt)}
              className="btn btn-outline-primary btn-sm cursor-pointer"
              role="button"
              tabIndex="-1"
            >Upload
            </span>
          )}
      </small>
    );
  }

  renderImage(headshot) {
    const { canEdit } = this.props;

    return (
      <div className="position-relative" key={headshot.id} style={{ display: 'inline-flex' }}>
        <a
          className="m-1 img-thumbnail"
          href={headshot.attributes.image_url}
          key={`product-headshot-${headshot.id}`}
          style={{ position: 'relative', display: 'inline-table' }}
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.trackClicks}
        >
          <img style={{ maxHeight: '200px' }} src={headshot.attributes.thumbnail_image_url} alt="" />
        </a>

        <div style={{ position: 'absolute', bottom: '5px', right: '5px' }}>
          {/* eslint-disable react/no-unknown-property */}
          <a
            className="btn btn-sm btn-secondary btn-secondary-transparent-ish me-2 mb-2"
            onClick={(e) => { e.preventDefault(); this.downloadImage(headshot.attributes.image_url); }}
            role="button"
            tabIndex="-1"
            href={headshot.attributes.image_url}
          >
          {/* eslint-enable react/no-unknown-property */}
            <i className="fa-solid fa-download" />
          </a>
          {canEdit
            && (
              <span
                className="btn btn-sm btn-danger btn-danger-transparent-ish me-2 mb-2"
                onClick={() => this.deleteImage(headshot.id)}
                onKeyUp={(evt) => evt.keyCode == 13 && this.deleteImage(headshot.id)}
                role="button"
                tabIndex="-1"
              >
                <i className="fa-solid fa-trash-can" />
              </span>
            )}
        </div>
      </div>
    );
  }

  render() {
    const { loading, images } = this.state;
    const { canEdit } = this.props;

    if (loading || (images.length == 0 && !canEdit)) {
      return null;
    }

    return (
      <div className="card">

        <div className="card-header no-border-bottom d-flex flex-row">

          <div>
            Photos
          </div>

          {canEdit && this.renderUploadButton()}
        </div>

        <div className="card-body">
          {images.length == 0
            && (
<p className="form-text">Showcase your product! Upload high-quality images for extra impact and to build a
              stronger connection with media professionals.</p>
)}
          {images.length > 0
            && images.map((headshot) => this.renderImage(headshot))}
        </div>

      </div>
    );
  }
}

ProductImages.propTypes = {
  canEdit: PropTypes.bool,
  product_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

ProductImages.defaultProps = {
  canEdit: undefined,
};

export default ProductImages;
