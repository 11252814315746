import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {

  // We use a custom internal API to create and update Reporter employments ...
  createReporterWorkExperience(reporterId, reporterEmployment) {
    vlog.info('api#createReporterWorkExperience');

    api.configure();

    const url = `/api/internal/reporters/${reporterId}/reporter-employments`;

    return axios.post(url, { reporter_employment: reporterEmployment }, api.axiosFix);
  },

  updateReporterWorkExperience(reporterId, reporterEmploymentId, formData) {
    vlog.info('api#updateReporterWorkExperience');

    api.configure();

    const url = `/api/internal/reporters/${reporterId}/reporter-employments/${reporterEmploymentId}`;

    return axios.put(url, { reporter_employment: formData }, api.axiosFix);
  },

  // We use the default jsonapi for index, show, and delete
  deleteReporterEmployment(reporterEmployment) {
    vlog.info('api#deleteOpportunityReporter');

    api.configure();

    const url = reporterEmployment.links.self;

    return axios.delete(url, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },

  getReporterEmployment(reporterId, organizationId) {
    vlog.info('api#getReporterEmployment');

    api.configure();

    const url = `/api/internal/jsonapi/reporter-employments?filter[reporter_id]=${reporterId}&filter[organization_id]=${organizationId}`;

    return axios.get(url, api.axiosFix);
  },

  getReporterEmployments(reporterId, params) {
    vlog.info('api#getReporterEmployments');

    api.configure();

    const url = `/api/internal/jsonapi/reporters/${reporterId}/reporter-employments`;

    return axios.get(url, { params }, api.axiosFix);
  },
});
