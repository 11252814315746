import React from 'react';
import PropTypes from 'prop-types';
import Source from './source';

function Sources({
                   sources,
                 }) {
  return (
    <>
      {sources.length > 0 && (
        <div className="fw-bold mb-2">
          Representing
        </div>
      )}

      {sources.map((source) => (
        <Source
          key={source.id}
          source={source}
        />
      ))}

      <div className="clearfix mb-2" />
    </>
  );
}

Sources.propTypes = {
  sources: PropTypes.arrayOf(Object).isRequired,
};

export default Sources;
