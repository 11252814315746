import React from 'react';
import PropTypes from 'prop-types';

function Button({ onClick }) {
  const btnClasses = 'btn btn-outline-primary cursor-pointer mb-2 me-2 d-inline-flex flex-row';

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  };

  return (
    <div
      className={`${btnClasses}`}
      id="add_new_button"
      onClick={() => onClick()}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex="0"
    >
      <div className="d-inline-block me-2 align-self-center">
        <i className="fa-solid fa-circle-plus fa-2x" />
      </div>
      <span className="fs-4 pt-1">Add new</span>
    </div>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function Header({ onCancel }) {
  return (
    <div className="d-inline-flex flex-column pt-1 mb-2">
      <div className="fs-4">Add New</div>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          onCancel();
        }}
        className="btn btn-link btn-sm align-self-start px-0"
        style={{ cursor: 'pointer' }}
      >
        <i className="fa-solid fa-arrow-left" />
        &nbsp;Back
      </button>
    </div>
  );
}

Header.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

// Main Component
function AddNewButton({ onClick, active, onCancel }) {
  if (active) {
    return <Header onCancel={onCancel} />;
  }

  return <Button onClick={onClick} />;
}

AddNewButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddNewButton;
