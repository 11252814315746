import { createConnector } from 'react-instantsearch-dom';

export default createConnector({
  displayName: 'AlgoliaGeoSearchConnector',

  getProvidedProps() {
    return {};
  },

  refine(props, searchState, nextValue) {
    const { aroundLatLng } = nextValue || {};
    const { aroundRadius } = nextValue || 'all';

    return {
      ...searchState,
      aroundLatLng,
      aroundRadius,
      boundingBox: {},
      page: 1,
    };
  },

  getSearchParameters(searchParameters, props, searchState) {
    const currentRefinement = searchState.aroundLatLng || props.defaultRefinement;
    const { lat } = currentRefinement || {};
    const { lng } = currentRefinement || {};
    const latLngString = (lat && lng) ? `${lat}, ${lng}` : '';

    return searchParameters
      .setQueryParameter('insideBoundingBox')
      .setQueryParameter(
        'aroundRadius',
        searchState.aroundRadius,
      )
      .setQueryParameter(
        'aroundLatLng',
        latLngString,
      );
  },
});
