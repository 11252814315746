import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../../javascript/vahoy';
import vapi from '../../../javascript/frontend/api/vapi';

const CompanyContext = React.createContext(null);

function CompanyContextProvider(props) {
  const { children } = props;
  const [companyId, setCompanyId] = useState(null);
  const [company, setCompany] = useState({});
  const [hashtagsSectionHidden, setHashtagsSectionHidden] = useState(false);
  const [twitterFeedSectionHidden, setTwitterFeedSectionHidden] = useState(false);
  const [contactSectionHidden, setContactSectionHidden] = useState(false);
  const [aboutSectionHidden, setAboutSectionHidden] = useState(false);
  const [prUsersSectionHidden, setPrUsersSectionHidden] = useState(false);
  const [prAgenciesSectionHidden, setPrAgenciesSectionHidden] = useState(false);
  const [prUsersExist, setPrUsersExist] = useState(false);
  const [prAgenciesExist, setPrAgenciesExist] = useState(false);
  const [topLevelIsLoading, setTopLevelIsLoading] = useState(false);
  const [doTopLevelFetch, setDoTopLevelFetch] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const [asyncError, setAsyncError] = useState(null);

  // Pulls company info from the API whenever the companyId changes or doTopLevelFetch is set to true
  useEffect(() => {
    if (!doTopLevelFetch || !companyId) return;
    const fetchCompany = async () => {
      try {
        setTopLevelIsLoading(true);
        vahoy.track('Companies#getCompany');

        const params = {
          id: companyId,
        };

        const response = await vapi.getCompany(params);
        if (response.status === 200) {
          const companyData = response.data;
          if (companyData.data) {
            setCompany(companyData.data.attributes);
          }
        }
      } catch (error) {
        setAsyncError(error);
      } finally {
        setTopLevelIsLoading(false);
        setDoTopLevelFetch(false);
      }
    };
    fetchCompany();
  }, [companyId, doTopLevelFetch]);

  // Keeps the hashtags section hidden boolean updated
  useEffect(() => {
    setHashtagsSectionHidden(!(company.hashtags?.length > 0));
  }, [company, setHashtagsSectionHidden]);

  // Keeps the contact section hidden boolean updated
  useEffect(() => {
    setContactSectionHidden(!(company.freeform_contact_info?.length > 0));
  }, [company, setContactSectionHidden]);

  // Keeps the twitter feed section hidden boolean updated
  useEffect(() => {
    setTwitterFeedSectionHidden(!(company.primary_twitter_url) || !(company.show_twitter_feed));
  }, [company, setTwitterFeedSectionHidden]);

  // Keeps the about section hidden boolean updated
  useEffect(() => {
    setAboutSectionHidden(!company.about);
  }, [company, setAboutSectionHidden]);

  // Keeps the PR users section hidden boolean updated
  useEffect(() => {
    // Only show this in the "Add a section" list if there actually are PR users returned from
    // the API and the section has been set explicitly to not show. Otherwise, it's just hidden
    // and not shown in the "Add a section" at all (because adding it wouldn't do any good without records.)
    setPrUsersSectionHidden((prUsersExist && !company.show_pr_users && !company.display_as_pr_firm));
  }, [company, setPrUsersSectionHidden, prUsersExist]);

  // Keeps the PR agencies section hidden boolean updated
  useEffect(() => {
    // As above, only show in "Add a section" list if there actually are PR agencies.
    setPrAgenciesSectionHidden((prAgenciesExist && !company.show_pr_agencies && !company.display_as_pr_firm));
  }, [company, setPrAgenciesSectionHidden, prAgenciesExist]);

  // Values and functions made available to consumers of the company context
  // (Memoized based on state values to avoid triggering unnecessary component rerenders)
  const providerValue = useMemo(
    () => ({
      companyId,
      setCompanyId,
      company,
      setCompany,
      aboutSectionHidden,
      hashtagsSectionHidden,
      contactSectionHidden,
      twitterFeedSectionHidden,
      prUsersSectionHidden,
      prAgenciesSectionHidden,
      prUsersExist,
      setPrUsersExist,
      prAgenciesExist,
      setPrAgenciesExist,
      topLevelIsLoading,
      setTopLevelIsLoading,
      doTopLevelFetch,
      setDoTopLevelFetch,
      openEditModal,
      setOpenEditModal,
      isUpdating,
      setIsUpdating,
      generalError,
      setGeneralError,
      asyncError,
      setAsyncError,
      hiddenSectionsAvailable: (
        hashtagsSectionHidden
        || contactSectionHidden
        || aboutSectionHidden
        || twitterFeedSectionHidden
        || (prUsersSectionHidden || (company.display_as_pr_firm && !company.show_pr_users))
        || prAgenciesSectionHidden
        || (company.display_as_pr_firm && !company.show_brands)
      ),
    }),
    [
      companyId,
      company,
      aboutSectionHidden,
      hashtagsSectionHidden,
      contactSectionHidden,
      prUsersSectionHidden,
      twitterFeedSectionHidden,
      prAgenciesSectionHidden,
      prUsersExist,
      prAgenciesExist,
      topLevelIsLoading,
      doTopLevelFetch,
      openEditModal,
      isUpdating,
      generalError,
      asyncError,
    ],
  );

  return (
    <CompanyContext.Provider value={providerValue}>
      {children}
    </CompanyContext.Provider>
  );
}

export { CompanyContext, CompanyContextProvider };

CompanyContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
