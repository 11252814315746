import React from 'react';
import PropTypes from 'prop-types';
import FollowButton from '../../follow_button';
import Avatar from '../../avatar/avatar';
import vahoy from '../../../javascript/vahoy';

function NewMediaCard({ newMediaUser, smallMode, updates }) {
  if (updates) Object.assign(newMediaUser, updates);
  const maxHashtags = 2;
  const { hashtags } = newMediaUser;
  const { favorited } = newMediaUser;
  const trackHashtagClicks = () => {
    vahoy.track('SourceRequests/SourceRequestCard#clickHashtag');
  };

  const trackClicks = () => {
    vahoy.track('SourceRequests/NewMediaCard#clickNewMediaUserLink');
  };

  const renderHashtags = () => (
    hashtags.slice(0, maxHashtags).map((hashtag) => (
      <a
        href={hashtag.hashtag_path}
        key={hashtag.id}
        onClick={trackHashtagClicks}
        className="mb-2 me-2 pb-2 text-secondary font-size-16px text-decoration-underline"
      >
        {hashtag.hashtag}
      </a>
    ))
  );

  return (
    <div key={newMediaUser.object_gid} className="card h-100 source-request-card animated faster fadeIn border" id={`new-media-user-${newMediaUser.objectID}`}>
      <div className={`card-header ${smallMode ? 'pt-0 pb-1' : 'pt-1 pb-2'} bg-warning text-white border-1 border-warning`}>
        <p className="text-center text-white font-size-14px m-0 p-0 mt-1"> NEW MEDIA USER JUST JOINED!</p>
      </div>
      <div className="card-body pt-3">
        <div className="d-flex justify-content-start">
          <div className="me-3">
            <Avatar
              avatarUrl={newMediaUser.avatar_url}
              sizeClass={smallMode ? 'avatar-sm' : 'avatar-md'}
              avatarLetters={newMediaUser.initials || 'NA'}
            />
          </div>
          <div>
            <h3 className="font-size-22px mb-2 fw-bold text-primary">
              <a
                href={newMediaUser.profile_path}
                onClick={trackClicks}
              >
                {newMediaUser.full_name}
              </a>
              <br />
            </h3>
            <h4 className="fw-semibold">just joined Qwoted</h4>
            <span>
              <FollowButton
                favoriteableId={newMediaUser.favoriteable_id}
                favoriteableType="Reporter"
                favoriteId={newMediaUser.favorite_id}
                favorited={favorited}
                ahoyObjectGid={newMediaUser.object_gid}
                showTooltip={true}
                tooltipId={newMediaUser.favoriteable_id}
                tooltipText="Reporters will be notified of the follow and be offered a chance to connect with you!"
                page="New Media Card"
                key={newMediaUser.object_gid}
              />
            </span>
          </div>
        </div>
        <div className="mt-3 ps-2">
          { newMediaUser.hashtags && newMediaUser.hashtags.length > 0 && (
            <div className="my-2 row"><div className="me-2 fw-semibold col-4">Writes on: </div><div className="col-7">{renderHashtags()}</div></div>
          )}
          { newMediaUser.location_string && (
            <div className="my-2 row"><div className="me-2 fw-semibold col-4">Location: </div><div className="col-7">{newMediaUser.location_string}</div></div>
          )}
          { newMediaUser.featured_employment_publication_name && (
            <div className="row my-3"><div className="col-4 fw-semibold">Writes for:</div>
              <div className="col-3">
                <img src={newMediaUser.publication_logo_url} width="100%" alt={newMediaUser.featured_employment_publication_name} className="ms-2 mt-2" />
              </div>
              <div className="col-5">
                <a
                  href={newMediaUser.featured_employment_publication_url}
                  className="font-size-18px fw-semibold text-decoration-underline"
                >
                  {newMediaUser.featured_employment_publication_name}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

NewMediaCard.propTypes = {
  newMediaUser: PropTypes.instanceOf(Object).isRequired,
  smallMode: PropTypes.bool,
  updates: PropTypes.instanceOf(Object),
};

NewMediaCard.defaultProps = {
  smallMode: true,
  updates: undefined,
};

export default NewMediaCard;
