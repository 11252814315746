import React from 'react';
import PropTypes from 'prop-types';

function TypeYourMessage({
                           message,
                           messageError,
                           setMessage,
                         }) {
  return (
    <div className="mb-3">
      <label htmlFor="message" className="fw-bold">
        Type your message <span style={{ color: 'red' }}>*</span>
      </label>
      <textarea
        className="w-100 form-control"
        id="message"
        name="message"
        onChange={(e) => setMessage(e.target.value)}
        rows="6"
        value={message || ''}
      />
      {messageError && (
        <div className="form-text text-danger">
          {messageError}
        </div>
      )}
    </div>
  );
}

TypeYourMessage.propTypes = {
  message: PropTypes.string,
  messageError: PropTypes.string,
  setMessage: PropTypes.func,
};

TypeYourMessage.defaultProps = {
  message: undefined,
  messageError: undefined,
  setMessage: undefined,
};

export default TypeYourMessage;
