import helper from '../../helpers/helper';
import select2Helpers from '../../helpers/select2_helpers';

document.addEventListener('turbolinks:load', () => {
  const $sourceSelectElement = $('select#self_source_association_user_id');
  if (
    helper.notPresent($sourceSelectElement)
  ) {
    return;
  }

  select2Helpers.setupSelect2($sourceSelectElement, '/api/internal/jsonapi/users', select2Helpers.formatUsers);
});
