/* eslint-disable react/forbid-prop-types,react/require-default-props */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import PlanChangeButton from './_plan_change_button';
import vapi from '../../javascript/frontend/api/vapi';
import LoadingImage from '../loading_image';
import PlanChangeSuccessModal from './_plan_change_success_modal';
import DowngradeWarning from './_downgrade_warning';

class BillingPlanChanger extends React.Component {
  BILLING_PLANS = {
    FREE: 0,
    PAID: 1,
    PR_TEAM: 2,
    CLIENT_FIRM: 3,
  };

  constructor(props) {
    super(props);
    const { newSourcePlan } = this.props;
    const selectedPlan = _.invert(this.BILLING_PLANS)[newSourcePlan];

    this.state = {
      isError: false,
      errorMessage: null,
      errorTitle: null,
      isLoading: true,
      isProcessing: false,
      disableInput: false,
      isFinished: false,
      partialMonthCharge: '',
      partialMonthRefund: '',
      totalDueToday: '',
      selectedPlan,
    };
  }

  componentDidMount() {
    const { accountCode, newSourcePlan, interval } = this.props;

    if (newSourcePlan === this.BILLING_PLANS.PR_TEAM || newSourcePlan === this.BILLING_PLANS.CLIENT_FIRM) {
      this.calculateProPlanProration(accountCode, newSourcePlan, interval);
    } else {
      this.setState({ isLoading: false });
    }
  }

  calculateProPlanProration = async (accountCode, planCode, interval) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    try {
      const proration = await vapi.calculateUpgradeProration(accountCode, planCode, interval);
      const partialMonthCharge = formatter.format(proration.data.partial_month_charge);
      const partialMonthRefund = formatter.format(proration.data.partial_month_refund);
      const totalDueToday = formatter.format(proration.data.total_charge);

      this.setState({
        partialMonthCharge, partialMonthRefund, totalDueToday, isLoading: false,
      });
    } catch (error) {
      this.reportError(
        'Error Calculating Upgrade Cost',
        'There was a problem calculating the upgrade cost for your plan.',
      );
    }
  };

  disableInput = (disableInput) => {
    this.setState({ disableInput });
  };

  setProcessingState = (isProcessing) => {
    this.setState({ isProcessing });
  };

  reportError = (errorTitle, errorMessage) => {
    this.setState({
      isError: true,
      isProcessing: false,
      isLoading: false,
      errorMessage,
      errorTitle,
    });
  };

  reportSuccess = () => {
    const { onSuccess } = this.props;

    this.setState({ isProcessing: false, isFinished: true });
    onSuccess();
  };

  onPlanChangeClick = async () => {
    const {
 accountCode, newSourcePlan, stripe, interval,
} = this.props;
    this.disableInput(true);
    this.setProcessingState(true);

    try {
      await vapi.updatePlan(accountCode, newSourcePlan, interval);
      this.reportSuccess();
    } catch (error) {
      if (error.response && error.response.status === 402) {
        // Trigger the 3D Secure workflow or report a general or payment error
        if (error.response.data.client_secret && error.response.data.invoice_code) {
          const confirmResult = await stripe.confirmCardPayment(error.response.data.client_secret);

          if (confirmResult.error) {
            await vapi.cancelPlanUpdate(error.response.data.invoice_code);
            this.reportError(
              'Error Processing Payment',
              'There was a problem authorizing your payment.',
            );
          } else {
            try {
              await vapi.finalizePlanUpdate(error.response.data.invoice_code);
              this.reportSuccess();
            } catch (finalError) {
              this.reportError(
                'Error Upgrading Plan',
                'There was a problem upgrading your plan.',
              );
            }
          }
        } else {
          this.reportError(
            'Error Processing Payment',
            `There was a problem processing your payment: ${error.response.data.message}`,
          );
        }
      } else {
        this.reportError('Error Changing Plans', 'Something went wrong, please try again later.');
      }
    }
  };

  onSuccessDialogDismiss = () => {
    const { successUrl } = this.props;
    window.location.replace(successUrl);
  };

  _renderFreePlanSummary = () => {
    const { nextBillDateString } = this.props;

    return (
      <div className="row">
        <div className="col-md-4">
          <strong>Future Billing</strong><br />
          <small>(After you click the button below)</small>
        </div>
        <div className="col-md-8">
          <strong>We'll stop charging your credit card.</strong>
          <div className="mt-2 text-info">
            <em>
              You'll have access to your current plan's features until {nextBillDateString}.
            </em>
          </div>
        </div>
      </div>
    );
  };

  _renderPlusPlanSummary = () => {
    const { nextBillDateString, newMonthlyChargeString } = this.props;

    return (
      <div className="row">
        <div className="col-md-4">
          <strong>Next Bill</strong><br />
          <small>(After you click the button below)</small>
        </div>
        <div className="col-md-8">
          <strong>
            {nextBillDateString} for {newMonthlyChargeString}
          </strong>
          <div className="mt-2 text-info">
            <em>
              You'll have access to your current plan's features until this next billing date.
            </em>
          </div>
        </div>
      </div>
    );
  };

  _renderProOrEnterprisePlanSummary = () => {
    const { partialMonthCharge, partialMonthRefund, totalDueToday } = this.state;
    const { nextBillDateString, newMonthlyChargeString } = this.props;

    return (
      <div className="details">
        <div className="row">
          <div className="col-md-6">
            <strong>Due Today</strong>
          </div>
          <div className="col-md-3 text-primary">
            <strong>Cost of new plan</strong>
          </div>
          <div className="col-md-3 text-primary" style={{ textAlign: 'right' }}>
            {`${partialMonthCharge}`}
          </div>
          <div className="col-md-6" />
          <div className="col-md-3 text-danger">
            Prorated refund
          </div>
          <div className="col-md-3 text-danger" style={{ textAlign: 'right', marginBottom: '15px' }}>
            {partialMonthRefund}
          </div>
          <div className="col-md-6" />
          <div className="col-md-3 text-success">
            <h4><strong>TOTAL:</strong></h4>
          </div>
          <div className="col-md-3" style={{ textAlign: 'right' }}>
            <h4><strong className="text-success">{totalDueToday}</strong></h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="minor"><i className="fa fa-calendar" /> Next Payment Due: </span>
          </div>
          <div className="col-md-6" />
          <div className="col-md-6">
            <span className="minor">{nextBillDateString} for {newMonthlyChargeString}</span>
          </div>
          <div className="col-md-6" />
        </div>
      </div>
    );
  };

  renderSummary = () => {
    const { selectedPlan } = this.state;

    if (selectedPlan === 'FREE') {
      return this._renderFreePlanSummary();
    }
    if (selectedPlan === 'PAID') {
      return this._renderPlusPlanSummary();
    }
    if (selectedPlan === 'PR_TEAM' || selectedPlan === 'CLIENT_FIRM') {
      return this._renderProOrEnterprisePlanSummary();
    }
  };

  renderDowngradeWarning = () => {
    const {
      oldSourcePlan,
      newSourcePlan,
      oldPlanPitches,
      oldPlanUnlimited,
      oldPlanDiscontinued,
      willLoseDiscount,
      nextBillDateString,
    } = this.props;

    if (oldSourcePlan > newSourcePlan) {
      return (
        <DowngradeWarning
          newSourcePlan={newSourcePlan}
          oldSourcePlan={oldSourcePlan}
          oldPlanPitches={oldPlanPitches}
          oldPlanUnlimited={oldPlanUnlimited}
          oldPlanDiscontinued={oldPlanDiscontinued}
          willLoseDiscount={willLoseDiscount}
          nextBillDateString={nextBillDateString}
        />
      );
    }
  };

  render() {
    const {
      isLoading,
      totalDueToday,
      selectedPlan,
      isProcessing,
      disableInput,
      isFinished,
      isError,
      errorMessage,
      errorTitle,
    } = this.state;

    const { newSourcePlan, oldSourcePlan } = this.props;
    const loadingSpinner = <div className="text-center p-4"><LoadingImage width={50} /></div>;
    let componentUI;

    const buttonOrError = isError && errorMessage ? (
      <div className="alert alert-danger mb-3" role="alert">
        <h4 className="alert-heading">{errorTitle}</h4>
        <p>{errorMessage}</p>
        <hr />
        <small className="mb-0">
          If you continue to experience problems, please contact us at {window.SUPPORT_EMAIL}.
        </small>
      </div>
    ) : (
      <PlanChangeButton
        selectedPlan={selectedPlan}
        totalDueToday={totalDueToday}
        isProcessing={isProcessing}
        disabled={disableInput}
        onClick={() => this.onPlanChangeClick()}
      />
    );

    if (isLoading) {
      componentUI = loadingSpinner;
    } else {
      componentUI = (
        <>
          {this.renderSummary()}
          <hr />
          {this.renderDowngradeWarning()}
          <div className="row">
            <div className="col pt-3 px-5 mx-5">
              {buttonOrError}
            </div>
          </div>
          <PlanChangeSuccessModal
            showPopup={isFinished}
            oldSourcePlan={oldSourcePlan}
            newSourcePlan={newSourcePlan}
            onCancel={this.onSuccessDialogDismiss}
          />
        </>
      );
    }

    return componentUI;
  }
}

BillingPlanChanger.propTypes = {
  stripe: PropTypes.object,
  accountCode: PropTypes.number.isRequired,
  newSourcePlan: PropTypes.number.isRequired,
  oldSourcePlan: PropTypes.number.isRequired,
  oldPlanPitches: PropTypes.number.isRequired,
  oldPlanUnlimited: PropTypes.bool,
  oldPlanDiscontinued: PropTypes.bool,
  willLoseDiscount: PropTypes.bool,
  nextBillDateString: PropTypes.string.isRequired,
  newMonthlyChargeString: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  interval: PropTypes.string,
};

BillingPlanChanger.defaultProps = {
  oldPlanUnlimited: false,
  oldPlanDiscontinued: false,
  willLoseDiscount: false,
};

export default function InjectedPlanChanger(props) {
  const {
    accountCode,
    newSourcePlan,
    oldSourcePlan,
    oldPlanPitches,
    oldPlanUnlimited,
    oldPlanDiscontinued,
    willLoseDiscount,
    nextBillDateString,
    newMonthlyChargeString,
    successUrl,
    onSuccess,
    interval,
  } = props;

  return (
    <ElementsConsumer>
      {({ stripe }) => (
        <BillingPlanChanger
          stripe={stripe}
          accountCode={accountCode}
          newSourcePlan={newSourcePlan}
          oldSourcePlan={oldSourcePlan}
          oldPlanPitches={oldPlanPitches}
          oldPlanUnlimited={oldPlanUnlimited}
          oldPlanDiscontinued={oldPlanDiscontinued}
          willLoseDiscount={willLoseDiscount}
          nextBillDateString={nextBillDateString}
          newMonthlyChargeString={newMonthlyChargeString}
          successUrl={successUrl}
          onSuccess={onSuccess}
          interval={interval}
        />
      )}
    </ElementsConsumer>
  );
}

InjectedPlanChanger.propTypes = {
  accountCode: PropTypes.number.isRequired,
  newSourcePlan: PropTypes.number.isRequired,
  oldSourcePlan: PropTypes.number.isRequired,
  oldPlanPitches: PropTypes.number,
  oldPlanUnlimited: PropTypes.bool,
  oldPlanDiscontinued: PropTypes.bool,
  willLoseDiscount: PropTypes.bool,
  nextBillDateString: PropTypes.string.isRequired,
  newMonthlyChargeString: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  interval: PropTypes.string,
};

InjectedPlanChanger.defaultProps = {
  oldPlanUnlimited: false,
  oldPlanDiscontinued: false,
  willLoseDiscount: false,
};
