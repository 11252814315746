import React from 'react';
import PropTypes from 'prop-types';
import EntityRow from './EntityRow';

function AttachEntityTable({
 entities, entityType, attach, toggleShowForm,
}) {
  if (entities.length === 0) {
    const pluralizedEntityTypes = {
      source: 'experts',
      product: 'products',
    };
    const pluralizedDisplayType = pluralizedEntityTypes[entityType?.toLowerCase()];

    const displayTypes = {
      source: 'Expert',
      product: 'Product',
    };
    const displayType = displayTypes[entityType?.toLowerCase()];

    return (
      <table className="table">
        <tbody>
          <tr>
            <td colSpan="3" className="text-center align-middle">
              You don&apos;t represent any {pluralizedDisplayType}:&nbsp;
              <button
                className="btn btn-outline-primary btn-sm text-nowrap"
                onClick={toggleShowForm}
                type="button"
              >
                Add New <strong>{displayType}</strong>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  return (
    <table className="table">
      <tbody>
        {entities.map((entity) => (
          <EntityRow
            key={`row-${entity.id}`}
            entity={entity}
            attach={attach}
          />
        ))}
      </tbody>
    </table>
  );
}

AttachEntityTable.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.object).isRequired,
  entityType: PropTypes.string.isRequired,
  attach: PropTypes.func.isRequired,
  toggleShowForm: PropTypes.func.isRequired,
};

export default AttachEntityTable;
