import React from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox } from 'react-instantsearch-dom';
import ClearRefinementsButton from '../../algolia_search/clear_refinements_button';
import HashtagFilterPanel from './filter_panels/hashtag_filter_panel';
import InfiniteHits from './infinite_hits';
import MenuBar from '../../directory/MenuBar';
import ShowIfAnyRefinements from './show_if_any_refinements';
import SiteFilterPanel from './filter_panels/site_filter_panel';
import SmartStats from '../../algolia_search/smart_stats';

function PressReleasesIndex({
                              algoliaAppId,
                              algoliaSearchKey,
                              indexName,
                              query,
                              view,
                            }) {
  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

  return (
    <div className="ais-InstantSearch mt-3">
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <MenuBar view={view}>
          {{
            searchForm: (
              <>
                <SearchBox
                  autoFocus
                  translations={{
                    placeholder: 'Search',
                  }}
                  searchAsYouType={true}
                  defaultRefinement={query}
                  className="d-flex me-3"
                />
                <ClearRefinementsButton
                  buttonText="Clear"
                  clearsQuery={true}
                  bootstrapClasses="px-3 btn btn-sm btn-outline-info text-nowrap"
                />
              </>
            ),
          }}
        </MenuBar>

        <div className="row mb-2">
          <div className="col pe-4 text-end">
            <SmartStats />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-3">
            <SiteFilterPanel />
            <HashtagFilterPanel />
          </div>

          <div className="col-12 col-md-9">
            {/* In order to get search to be counted as an active refinement
            the clearsQuery property, quite strangely, has to be set to true. */}
            <ShowIfAnyRefinements clearsQuery={true}>
              <div className="row mb-3">
                <div className="col">
                  <ClearRefinementsButton buttonText="Clear search &#38; filters" clearsQuery={true} />

                  <div className="d-inline-block ms-3">
                    <SmartStats />
                  </div>
                </div>
              </div>
            </ShowIfAnyRefinements>

            <div className="row">
              <div className="col">
                <InfiniteHits />
              </div>
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

PressReleasesIndex.propTypes = {
  query: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  view: PropTypes.string,
};

PressReleasesIndex.defaultProps = {
  query: '',
  view: 'pressreleases',
};

export default PressReleasesIndex;
