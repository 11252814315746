import React from 'react';
import PropTypes from 'prop-types';

function Header(props) {
  const { mode, showWriting, showPreview } = props;

  return (
    <div className="md-editor-header">
      <div className="tabnav-tabs" role="tablist">
        <button
          type="button"
          tabIndex="-1"
          onClick={showWriting}
          className={`btn-link tabnav-tab write-tab ${mode == 'writing' ? 'selected' : ''}`}
        >Write
        </button>
        <button
          type="button"
          tabIndex="-1"
          onClick={showPreview}
          onKeyUp={(evt) => evt.keyCode == 13 && showPreview(evt)}
          className={`btn-link tabnav-tab preview-tab ${mode == 'preview' ? 'selected' : ''}`}
        >Preview
        </button>
      </div>
    </div>
  );
}

Header.propTypes = {
  showWriting: PropTypes.func.isRequired,
  showPreview: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
};

export default Header;
