import React from 'react';
import PropTypes from 'prop-types';
import SmartSnippet from '../../algolia_search/smart_snippet';

function QuoteOrBio({ source }) {
  let attributeToDisplay;
  if (!source.latest_quote && !source.bio) return null;
  if (!attributeToDisplay) {
    attributeToDisplay = source.latest_quote ? 'latest_quote' : 'bio';
  }

  return (
    <div className="font-size-12px mt-2">
      {(attributeToDisplay === 'latest_quote') && (<span className="fw-bold">LATEST: </span>)}
      <em className="text-secondary">
        <SmartSnippet attribute={attributeToDisplay} hit={source} />
      </em>
    </div>
  );
}

QuoteOrBio.propTypes = {
  source: PropTypes.instanceOf(Object).isRequired,
};

export default QuoteOrBio;
