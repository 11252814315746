import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import vlog from '../../javascript/vlog';
import Following from './following';

function Index({ userId }) {
  const [v2UserProfileId, setV2UserProfileId] = useState(null);
  const [v2UserId, setV2UserId] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [authenticatedHeadersHash, setAuthenticatedHeadersHash] = useState(null);

  useEffect(() => {
    const v2HealthCheck = async () => {
      const passed = await window.v2HealthCheck();
      if (passed) {
        vlog.debug('Component not ready: V2 health check did not pass.');
      } else {
        setLoading(false);
        setError(true);
      }
    };

    v2HealthCheck();
  }, []);

  useEffect(() => {
    const fetchV2identifiers = async () => {
      try {
        const { data, error: fetchError } = await window.supabaseClient
          .from('user_profiles')
          .select('profile_id, account_user_id')
          .eq('user_migrate_id', userId)
          .single(); // We expect only one profile per user

        if (fetchError) {
          vlog.error('Error fetching user profile:', fetchError);
          setLoading(false);
          return null;
        }

        if (data?.profile_id && data?.account_user_id) {
          setV2UserProfileId(data.profile_id);
          setV2UserId(data.account_user_id);
          setLoading(false);
          return true;
        }
        vlog.error('V2 IDs not found!');
        setLoading(false);
        return null;
      } catch (fetchError) {
        vlog.error('Error fetching V2 IDs', fetchError);
        setLoading(false);
        return null;
      }
    };

    fetchV2identifiers();
  }, [userId]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await window.supabaseToken();

        if (token) {
          setAuthenticatedHeadersHash({
            apikey: window.SUPABASE_ANON_KEY,
            Authorization: `Bearer ${token}`,
          });
          return true;
        }
        vlog.error('V2 IDs not found!');
        setError(true);
        return null;
      } catch (fetchError) {
        vlog.error('Error fetching V2 token', fetchError);
        setError(true);
        return null;
      }
    };

    fetchToken();
  }, []);

  if (loading) return null;

  return (
    <div>
      {error && (
        <div className="alert alert-warning">
          Under Maintenance: please come back later.
        </div>
      )}
      {!error && (
        <div>
          <Following
            authenticatedHeadersHash={authenticatedHeadersHash}
            profileId={v2UserProfileId}
            setError={setError}
            userId={v2UserId}
          />
        </div>
      )}
    </div>
  );
}

Index.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default Index;
