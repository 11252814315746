import React from 'react';
import PropTypes from 'prop-types';
import Pickr from '@simonwep/pickr/dist/pickr.min';
import vahoy from '../javascript/vahoy';

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
    };
  }

  componentDidMount() {
    this.setupColorpicker();
  }

  setupColorpicker() {
    const { value } = this.state;

    if (this.instance) {
      const options = {
        // https://github.com/Simonwep/pickr
        // Selector or element which will be replaced with the actual color-picker.
        // Can be a HTMLElement.
        el: this.instance,
        components: {

          // Main components
          preview: true,
          hue: true,

          // Input / output Options
          interaction: {
            hex: true,
            input: true,
            clear: true,
            save: true,
          },
        },
      };

      if (value) {
        options.default = value;
      }

      const pickr = new Pickr(options);

      pickr.on('save', (color) => {
        const code = color ? `#${color.toHEXA().join('')}` : '';
        this.setState({ value: code });
        vahoy.track('Colorpicker#selectColor');
      });
    }
  }

  // FIXME: Is there an existing noop function?
  doesNothing = () => {
    // NOTE: This function is blank intentionally, because we want the input readonly but responds to the required attribute.
  };

  render() {
    const { className = '', name = 'color' } = this.props;
    const { value } = this.state;
    let { required } = this.props;

    if (required === undefined) {
      required = true;
    }

    return (
      <span className={`${className} colorpicker`}>
        <span ref={(instance) => {
          this.instance = instance;
        }}
        />
        <input
          type="text"
          name={name}
          value={value || ''}
          required={required}
          onChange={this.doesNothing}
          className="form-control d-inline-block"
          style={{ width: '100px' }}
        />
      </span>
    );
  }
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};

ColorPicker.defaultProps = {
  value: undefined,
  className: undefined,
  name: undefined,
  required: undefined,
};

export default ColorPicker;
