import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {

  getProductImages(productId) {
    api.configure();

    // TODO: I'm not sure why this is necessary, the product images API call is being Conditional Get (304)
    // cached in production environment ...
    const cachebuster = Math.round(new Date().getTime() / 1000);

    const url = `/api/internal/jsonapi/products/${productId}/product-images?cachebuster=${cachebuster}`;

    vlog.info(`api#getProductImages: ${url}`);
    return axios.get(url, api.axiosFix);
  },

  createProductImage(productId, metadataCloudinary) {
    vlog.info('api#createProductImage');
    api.configure();

    const url = '/api/internal/jsonapi/product-images';

    const data = {
      type: 'product_images',
      attributes: {
        product_id: productId,
        image_cloudinary_metadata: metadataCloudinary,
      },
    };

    return axios.post(url, { data }, api.axiosFix);
  },

  deleteProductImage(ProductImageId) {
    vlog.info('api#deleteProductImage');

    api.configure();

    const url = `/api/internal/jsonapi/product-images/${ProductImageId}`;

    return axios.delete(url, api.axiosFix);
  },

});
