import helper from '../../../helpers/helper';

document.addEventListener('turbolinks:load', () => {
  const $searchForm = $('#js-user-search-form');

  if (
    helper.notPresent($searchForm)
  ) {
    return;
  }

  function setAuthLevelEnabled() {
    const $selectAuthLevel = $searchForm.find('select[name="auth_level"]');

    if ($searchForm.find('select[name="user_type"]').val() === 'reporter') {
      $selectAuthLevel.val('basic_user');
      $selectAuthLevel.prop('disabled', true);
    } else {
      $selectAuthLevel.prop('disabled', false);
    }
  }

  $searchForm.find('select[name="user_type"]').change(() => {
    setAuthLevelEnabled();
  });

  setAuthLevelEnabled();
});
