import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {

  createProduct(product) {
    vlog.info('api#createProduct');

    api.configure();

    const url = '/api/internal/products';

    return axios.post(url, { product }, api.axiosFix)
      .then((result) => result)
      .catch((errorResponse) => ({
        responseStatus: 'error',
        responseError: errorResponse,
      }));
  },

  // TODO establish naming convention?
  //  This makes a JSONAPI call and is modeled after loadReporters.
  //  getProducts by contrast calls the internal RPC API
  loadProducts(params) {
    vlog.info('api#loadProducts');

    api.configure();

    const url = '/api/internal/jsonapi/products';

    return axios.get(url, { params }, api.axiosFix);
  },

  getProduct(id, params) {
    api.configure();
    const options = { params };
    return axios.get(`/api/internal/jsonapi/products/${id}.json`, options, api.axiosFix);
  },

  getProducts(params) {
    api.configure();
    const options = { params };

    return axios.get('/api/internal/products', options, api.axiosFix);
  },

  getProductsJsonApi(params) {
    api.configure();
    const options = { params };

    return axios.get('/api/internal/jsonapi/products', options, api.axiosFix);
  },

  getCompanyProducts(params, companyId) {
    api.configure();
    const options = { params };

    return axios.get(`/api/internal/companies/${companyId}/products`, options, api.axiosFix);
  },

  getAlgoliaAttribsForProductIds(ids) {
    api.configure();
    const idStr = ids.join('&ids[]=');

    return axios.get(`/api/internal/products/algolia_attribs?ids[]=${idStr}`, {}, api.axiosFix);
  },

});
