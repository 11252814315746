import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import FreeToPitch from './free_to_pitch_badge';
import FollowButton from '../../follow_button';
import FavoriteButton from '../../favorite_button';
import Hashtags from '../../hashtags';
import NumberOfPitches from './number_of_pitches_badge';
import Publication from './publication';
import Reporter from './reporter';
import SourceRequestBody from './source_request_body';
import MediaMatchCard from './media_match_card';
import SourceRequestSkeletonCard from './source_request_skeleton_card';
import Datestamps from './datestamps';
import ShareButtons from './share_buttons';
import vahoy from '../../../javascript/vahoy';

import StatusFooter from './status_footer';
import SmartSnippet from '../../algolia_search/smart_snippet';

function SourceRequestCard({
                             sourceRequest, updates, smallMode, query, canPitchConnectionOpps, onCardClick,
                           }) {
  if (updates) Object.assign(sourceRequest, updates.attributes);
  const maxHashtags = smallMode ? 3 : 5;
  const { reporter } = sourceRequest;
  const sharedArticle = sourceRequest.shared_article;
  const isMediaMatch = sourceRequest.media_match_phase === true && query != undefined && query != '';
  const isMediaMatchFalseOrNoQuery = sourceRequest.media_match_phase === false || !query;
  const exposure = sourceRequest.request_type_text === 'exposure';
  const isBroadcastMedia = sourceRequest.request_sub_type_text_filtered == 'video/broadcast' && !isMediaMatch;
  const [tabTooltipOpen, setTabTooltipOpen] = useState(false);
  const tabTooltipTargetRef = useRef();
  const trackClicks = () => {
    vahoy.track('SourceRequests/SourceRequestCard#clickSourceRequestLink');
  };

  return (
    <div
      className={`card h-100 source-request-card animated faster fadeIn ${exposure ? 'border border-primary' : ''}`}
      id={`source-request-${sourceRequest.objectID}`}
    >
      {exposure && !isMediaMatch && (
        <div className={`card-header ${smallMode ? 'pt-0 pb-1' : 'pt-1 pb-2'} bg-primary text-white border-1`}>
          <p className="text-center text-white font-size-14px m-0 p-0 mt-1">REPORTERS ARE SHARING THEIR WORK!</p>
        </div>
      )}
      {isBroadcastMedia && (
        <div className={`card-header ${smallMode ? 'pt-0 pb-1' : 'pt-1 pb-2'} bg-success text-white border-1 border-success`}>
          <p className="text-center text-white font-size-14px m-0 p-0 mt-1">BROADCAST MEDIA</p>
        </div>
      )}
      {isMediaMatch && (
        <MediaMatchCard
          sourceRequest={sourceRequest}
          query={query}
          canPitchConnectionOpps={canPitchConnectionOpps}
          onCardClick={onCardClick}
        />
      )}
      {isMediaMatchFalseOrNoQuery && (
        <>
          {!exposure && (
            <FreeToPitch sourceRequest={sourceRequest} smallMode={smallMode} isBroadcastMedia={isBroadcastMedia} />
          )}
          <div className="card-body position-relative">
            {!exposure && (
              <div style={{ minHeight: '65px' }} className="mb-2">
                <NumberOfPitches sourceRequest={sourceRequest} />
                <div className="d-flex justify-content-between align-items-start mt-1">
                  <div className="w-50">
                    <Publication sourceRequest={sourceRequest} smallMode={smallMode} />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'start' }}>
                    <ShareButtons
                      shareText={`I found this media opportunity on Qwoted: ${sourceRequest.name}`}
                      shareUrl={sourceRequest.share_url}
                      requestUrl={sourceRequest.source_request_path}
                    />
                  </div>
                </div>
                {sourceRequest.publication && sourceRequest.publication.name && (
                  <>
                    <h6 className={`w-75 mb-0 fw-bold ${smallMode ? 'mt-0' : 'mt-2'} mb-0`}>
                      <a href={sourceRequest.source_request_path}>
                        {sourceRequest.publication.name}
                      </a>
                    </h6>
                  </>
                )}
              </div>
            )}
            <Reporter sourceRequest={sourceRequest} smallMode={exposure ? false : smallMode} />
            {!exposure && <SourceRequestBody sourceRequest={sourceRequest} smallMode={smallMode} />}

            {!exposure && (
              <div className="mb-1">
                <Hashtags
                  hashtagTarget={sourceRequest}
                  maxHashtags={maxHashtags}
                  smallMode={smallMode}
                  clickEventName="SourceRequests/SourceRequestCard#clickHashtag"
                />
              </div>
            )}
            {exposure && sharedArticle
              && (
                <div className="d-flex flex-column justify-content-between mt-1">
                  <a
                    href={sourceRequest.source_request_path}
                    onClick={trackClicks}
                    className="p-0 mb-1"
                  >
                    {sharedArticle.image_url && (
                      <div className="mt-2 d-flex align-items-center" style={{ maxHeight: '140px', overflow: 'hidden' }}>
                        <img src={sharedArticle.image_url} className="img-fluid" alt="article hero" />
                      </div>
                    )}
                  </a>
                  <div>
                    <a href={sourceRequest.source_request_path} onClick={trackClicks} className="p-0 mb-1 flex-grow-1">
                      <span className="lh-2 p-0 mt-0 mb-0 font-size-18px">
                        <SmartSnippet attribute="title" hit={sharedArticle} truncateLength={65} />
                      </span>
                    </a>
                    <a
                      href={sourceRequest.source_request_path}
                      onClick={trackClicks}
                      target="_blank"
                      rel="noreferrer"
                      className="ms-2 font-size-10px"
                    >
                      <i className="ps-2 fa-xl fa-solid fa-up-right-from-square" ref={tabTooltipTargetRef} />
                    </a>
                  </div>
                  <Tooltip
                    placement="top"
                    isOpen={tabTooltipOpen}
                    target={tabTooltipTargetRef}
                    toggle={() => setTabTooltipOpen(!tabTooltipOpen)}
                  >
                    Open in new tab
                  </Tooltip>
                  {!sharedArticle.image_url && sharedArticle.content_excerpt && (
                    <div className="mt-1 d-flex align-items-center">
                      <SmartSnippet attribute="content_excerpt" hit={sharedArticle} truncateLength={175} />
                    </div>
                  )}
                </div>
              )}
            {!exposure && sourceRequest.favoriteable_id && (
              <div style={{
                position: 'absolute', right: '10px', bottom: '3px', zIndex: '1000',
              }}
              >
                <FavoriteButton
                  favoriteableId={sourceRequest.favoriteable_id}
                  favoriteableType="SourceRequest"
                  favoriteId={sourceRequest.favorite_id}
                  favorited={sourceRequest.favorited}
                  ahoyObjectGid={sourceRequest.object_gid}
                  icon="fa-bookmark"
                />
              </div>
            )}
            {!exposure && <Datestamps sourceRequest={sourceRequest} smallMode={smallMode} />}
          </div>
          {!exposure && <StatusFooter sourceRequest={sourceRequest} smallMode={smallMode} />}
          {exposure && reporter && (
            <div className="card-footer border-0 bg-transparent w-100 pb-2">
              <div className="mb-3" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'start' }}>
                <ShareButtons
                  shareText="I thought you might be interested in this."
                  shareUrl={sourceRequest.share_url}
                />
              </div>
              <a
                href={sourceRequest.source_request_path}
                style={{ width: '48%' }}
                className="btn btn-md btn-primary font-size-14px px-3 py-2"
              >READ MORE</a>
              <span className="float-end" style={{ width: '48%' }}>
                <FollowButton
                  favoriteableId={reporter.id}
                  favoriteableType="Reporter"
                  favoriteId={reporter.favorite_id}
                  favorited={reporter.favorited}
                  ahoyObjectGid={reporter.object_gid}
                  showTooltip={true}
                  tooltipId={sourceRequest.objectID}
                  tooltipText="Reporters will be notified of the follow and be offered a chance to connect with you!"
                  size="md"
                  page="Shared Work Card"
                />
              </span>
            </div>
          )}
        </>
      )}
      {!isMediaMatch && !isMediaMatchFalseOrNoQuery && (
        <SourceRequestSkeletonCard smallMode={smallMode} />
      )}
    </div>
  );
}

SourceRequestCard.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object),
  updates: PropTypes.instanceOf(Object),
  smallMode: PropTypes.bool,
  query: PropTypes.string,
  canPitchConnectionOpps: PropTypes.bool,
  onCardClick: PropTypes.func,
};

SourceRequestCard.defaultProps = {
  sourceRequest: undefined,
  updates: undefined,
  smallMode: false,
  query: undefined,
  canPitchConnectionOpps: true,
  onCardClick: undefined,
};

export default SourceRequestCard;
