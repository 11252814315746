import React from 'react';
import PropTypes from 'prop-types';
import vapi from '../javascript/frontend/api/vapi';
import vahoy from '../javascript/vahoy';

class EditProfileImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entity: props.entity,
      profile_image_id: props.profile_image_id,
    };
  }

  setImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const {
      entity,
      profile_image_id: profileImageId,
    } = this.state;

    reader.addEventListener('load', () => {
      entity.attributes.image_cloudinary = reader.result;

      if (entity.type === 'publications') {
        vapi.updatePublication(entity)
          .then((result) => {
            $(`#${profileImageId}`)
              .attr('src', result.data.data.attributes.image_cloudinary_url);
            $('#edit-profile-image-form')
              .collapse('hide');
          });
        vahoy.track('EditProfileImage#uploadPublicationImage');
      } else {
        throw new Error('Should not happen!');
      }
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  render() {
    return (
      <div className="mt-2">
        <a data-bs-toggle="collapse" href="#edit-profile-image-form" className="btn btn-staff btn-sm">Set
          image
        </a>
        <div id="edit-profile-image-form" className="collapse" onChange={this.setImage}>
          <div className="mb-2" />
          <input id="edit-profile-image-input" type="file" />
        </div>
      </div>
    );
  }
}

EditProfileImage.propTypes = {
  entity: PropTypes.instanceOf(Object).isRequired,
  profile_image_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default EditProfileImage;
