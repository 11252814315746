import React from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import _ from 'lodash';
import PropTypes from 'prop-types';
import vapi from '../../javascript/frontend/api/vapi';
import vahoy from '../../javascript/vahoy';

const SortableHashtag = SortableElement(({ value, className }) => (
  <li className={`list-inline-item sortable-item cursor-move noselect ${className || ''}`}>
    {value}
  </li>
));

const SortableHashtags = SortableContainer((
  {
    items, className, itemClassName, maxFeaturedHashtags,
  },
) => {
  const featuredHashtags = items.slice(0, maxFeaturedHashtags);
  const otherHashtags = items.slice(maxFeaturedHashtags);
  const featuredHashtagsLength = featuredHashtags.length;
  let divider;

  if (featuredHashtags.length > 0 && otherHashtags.length > 0) {
    divider = (
      <li className="list-item mt-3 mb-2">
        <h4>Other Expertise</h4>
      </li>
    );
  }

  return (
    <ul className={`list-inline ${className || ''}`}>
      {(featuredHashtagsLength > 0) && (
        <li className="list-item"><h4>Featured Expertise</h4></li>
      )}
      {featuredHashtags.map((hashtag, index) => (
        <SortableHashtag
          key={hashtag.id}
          index={index}
          value={hashtag.attributes.hashtag}
          className={`featured-hashtag ${itemClassName || ''}`}
        />
      ))}
      {divider}
      {otherHashtags.map((hashtag, index) => (
        <SortableHashtag
          key={hashtag.id}
          index={featuredHashtagsLength + index}
          value={hashtag.attributes.hashtag}
          className={`other-hashtag ${itemClassName || ''}`}
        />
      ))}
    </ul>
  );
});

const defaultMaxFeaturedHashtags = 5;
const maxNumberOfHashtags = 50;

class HashtagsEditableForm extends React.Component {
  constructor(props) {
    super(props);

    const visibleHashtags = props.hashtagTags.filter((h) => h.attributes.is_visible === 'true'
      && h.attributes.manually_removed === 'false');
    const removedHashtags = props.hashtagTags.filter((h) => h.attributes.manually_removed === 'true'
      || (h.attributes.manually_removed === 'false'
        && h.attributes.manually_added === 'false'
        && h.attributes.is_visible === 'false'));
    this.state = {
      hashtags: { data: visibleHashtags },
      removedHashtags,
    };
  }

  onOpened = () => {
    this.setupSelect2();
  };

  // eslint-disable-next-line react/sort-comp
  formatResults(hashtag) {
    const attrs = hashtag.attributes;

    if (hashtag.loading || hashtag.id === 'placeholder') {
      return hashtag.addNewPrompt;
    }

    return `${attrs.hashtag}`;
  }

  formatSelection(hashtag, container) {
    // PLACEHOLDER HACK
    if (hashtag.text) {
      return hashtag.text;
    }

    if (hashtag.attributes.doNotCrawl && hashtag.attributes.doNotCrawl === 'true') {
      $(container).addClass('bg-danger text-light');
    } else if (hashtag.attributes.hideFromUsers && hashtag.attributes.hideFromUsers === 'true') {
      $(container).addClass('bg-warning text-light');
    }

    return hashtag.attributes.hashtag;
  }

  addHashtagToSelect($select, hashtagTag, hashtagType, tagToRemove = null, tagText = '') {
    if (tagToRemove) {
      tagToRemove.remove();
    }

    const option = new Option(tagText, hashtagTag.id, true, true);
    const attributes = {
      hashtag: hashtagTag.attributes.hashtag,
      hashtagId: hashtagTag.attributes.hashtag_id,
      manuallyAdded: hashtagTag.attributes.manually_added,
      manuallyRemoved: hashtagTag.attributes.manually_removed,
    };

    if (hashtagTag.attributes.do_not_crawl) {
      attributes.doNotCrawl = hashtagTag.attributes.do_not_crawl;
    }
    if (hashtagTag.attributes.hide_from_users) {
      attributes.hideFromUsers = hashtagTag.attributes.hide_from_users;
    }

    $(option).data(
      'data',
      {
        id: hashtagTag.id,
        type: hashtagType,
        attributes,
      },
    );
    $select.append(option);
  }

  refreshSelect2FromState = ($select) => {
    const { hashtags: hashtagsData } = this.state;
    const { hashtagType } = this.props;
    const hashtags = hashtagsData.data;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < hashtags.length; i++) {
      const hashtag = hashtags[i];
      this.addHashtagToSelect($select, hashtag, hashtagType);
    }
  };

  save = ($select = null, addedListItem = null) => {
    const { hashtagToAssociate, removedHashtags } = this.state;
    const { tagged_json: taggedJson, refreshParent, hashtagType } = this.props;

    if (hashtagToAssociate) {
      const taggedId = taggedJson.id;
      const taggedType = taggedJson.type.replace(/_/g, '-');
      const removedHashtagIds = removedHashtags.map((h) => h.attributes.hashtag_id);
      if (removedHashtagIds.includes(hashtagToAssociate.id)) {
        const hashtagTagToRestore = removedHashtags.find((ht) => ht.attributes.hashtag_id === hashtagToAssociate.id);
        if (hashtagTagToRestore) {
          vapi.restoreHashtagTag(hashtagTagToRestore.id, hashtagType)
            .then((response) => {
              const hashtagTag = response.data.data;
              const addedOption = _.find($select.find('option'), (option) => option.value === hashtagTagToRestore.attributes.hashtag_id);
              const restoredListItem = $select.find(addedOption);
              this.setState({ hashtagToAssociate: null });

              if (restoredListItem) {
                this.addHashtagToSelect($select, hashtagTag, hashtagType, restoredListItem);
              }
              refreshParent();
            });
        }
      } else {
        vapi.createHashtagTag(hashtagToAssociate, taggedId, taggedType, hashtagType)
          .then((response) => {
            const hashtagTag = response.data.data;
            this.setState({ hashtagToAssociate: null });
            refreshParent();

            if ($select && addedListItem) {
              this.addHashtagToSelect($select, hashtagTag, hashtagType, addedListItem);
            }
          })
          .catch((response) => {
            const errors = vapi.responseErrors(response);
            const error = errors && errors[0];
            if (error && error.title) {
              if (error.title === 'has already been taken' && addedListItem) {
                addedListItem.remove();
              }
            }
          });
      }
      vahoy.track('Hashtag/HashtagsEditableForm#updateTaggedHashtags');
    } else {
      refreshParent();
    }
  };

  setupSelect2 = () => {
    const that = this;

    const $hashtagsSelect = $(this.hashtagSelect).select2({
      ajax: {
        url: '/api/internal/jsonapi/hashtags',
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            filter: {
              name: params.term.replace('#', ''),
            },
          };
        },
        processResults(response) {
          return {
            results: response.data,
          };
        },
      },
      createTag(params) {
        const term = $.trim(params.term);

        if (term === '') {
          return null;
        }

        return {
          id: 'placeholder', // PLACEHOLDER HACK magical id attribute to mark the new record (before it has been persisted to the server)
          attributes: {
            hashtag: term,
          },
        };
      },
      tags: true,
      insertTag(data, tag) {
        // eslint-disable-next-line no-param-reassign
        tag.addNewPrompt = `New tag: '${tag.attributes.hashtag}'`;
        data.push(tag);
      },
      dropdownParent: $(this.hashtagsEditable),
      minimumInputLength: 1,
      templateResult: this.formatResults,
      templateSelection: this.formatSelection,
      language: {
        inputTooShort() {
          return '';
        },
      },
      maximumSelectionLength: maxNumberOfHashtags,
    });

    $hashtagsSelect.on('change', () => {
      // PLACEHOLDER HACK
      const filterPlaceholderOptions = _.filter($hashtagsSelect.select2('data'), (hashtag) => hashtag.id !== 'placeholder');

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < filterPlaceholderOptions.length; i++) {
        const hashtag = filterPlaceholderOptions[i];

        // new Option doesn't allow setting attributes, so we do it here.
        hashtag.attributes = hashtag.attributes || { label: hashtag.text };
      }

      that.setState({ hashtags: { data: filterPlaceholderOptions } }, () => {
        that.save();
      });
    });

    $hashtagsSelect.on('select2:select', (e) => {
      // Adding a hashtag from search or a newly-created one
      const { hashtagType } = this.props;
      let newHashtag = null;
      let lookedUpHashtag = null;
      if (e.params.data.id === 'placeholder') {
        newHashtag = e.params.data;
        // .label no worky here; we need .hashtag
        newHashtag.hashtag = newHashtag.label;
        delete newHashtag.label;
      } else {
        lookedUpHashtag = e.params.data;
      }

      // PLACEHOLDER HACK If the hashtag is new, it doesn't exist on the server yet, so we can't create a relationship
      // between it and the taggable entity. So, instead we have to do this little dance.  It's kind of ugly, but this is
      // the result of 2 epic work sessions.  Le sigh.  Anyways it works!
      if (newHashtag) {
        vapi.createHashtag(newHashtag)
          .then((response) => {
            const createdHashtag = response.data.data;
            // PLACEHOLDER HACK this is kind of a gross hack, best documented here https://stackoverflow.com/a/30328989/862027
            const placeholderOption = _.find($hashtagsSelect.find('option'), (option) => option.value === 'placeholder');
            const placeholderTag = $hashtagsSelect.find(placeholderOption);
            const createdHashtagTag = {
              id: 'new',
              attributes: {
                hashtag: createdHashtag.attributes.hashtag,
                hashtag_id: createdHashtag.id,
                manually_added: 'true',
                manually_removed: 'false',
              },
            };
            this.setState({ hashtagToAssociate: createdHashtag });
            this.addHashtagToSelect(
              $hashtagsSelect,
              createdHashtagTag,
              hashtagType,
              placeholderTag,
              createdHashtag.attributes.hashtag,
            );
            $hashtagsSelect.trigger('change');
          })
          .catch((response) => {
            const errors = vapi.responseErrors(response);
            const error = errors && errors[0];

            if (error && error.title) {
              if (error.title === 'has already been taken') {
                const duplicateOption = _.find($hashtagsSelect.find('option'), (option) => option.value === 'placeholder');
                $hashtagsSelect.find(duplicateOption).remove();
              }
            }
          });
        vahoy.track('Hashtag/HashtagEditableForm#createHashtag');
      } else if (lookedUpHashtag) {
        const addedOption = _.find($hashtagsSelect.find('option'), (option) => option.value === lookedUpHashtag.id);
        const addedListItem = $hashtagsSelect.find(addedOption);
        this.setState({ hashtagToAssociate: lookedUpHashtag });
        this.save($hashtagsSelect, addedListItem);
      }
    });

    $hashtagsSelect.on('select2:unselect', (e) => {
      const { hashtagType, tagged_json: taggedJson } = this.props;
      const taggedId = taggedJson.id;
      const taggedType = taggedJson.type.replace(/_/g, '-');
      const { hashtagId } = e.params.data.attributes;
      /*
      The hashtag_tag.id isn't guaranteed to stay constant since updates
      to manual hashtags trigger derived hashtag updates, which can change the
       primary keys... instead we'll look up the tagged record from the hashtag id itself.
      */
      vapi.retrieveHashtagTagFromHashtagId(taggedId, taggedType, hashtagId, hashtagType)
        .then((response) => {
          if (response.data.data.length > 0) {
            vapi.removeHashtagTag(response.data.data[0].id, hashtagType)
              .then(() => {
                const { removedHashtags } = this.state;
                this.setState({ removedHashtags: removedHashtags.concat(response.data.data) });
                $hashtagsSelect.trigger('change');
              });
          }
        });
    });

    this.refreshSelect2FromState($(this.hashtagSelect));
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const items = this.state.hashtags.data;
    let { maxFeaturedHashtags } = this.props;
    maxFeaturedHashtags = maxFeaturedHashtags || defaultMaxFeaturedHashtags;
    const min = Math.min(oldIndex, newIndex);
    const max = Math.max(oldIndex, newIndex);
    let sortedItems = [];

    if (min === max) {
      return;
    }

    if (oldIndex > newIndex) {
      sortedItems = items.slice(0, min)
        .concat([items[oldIndex]])
        .concat(items.slice(min, max))
        .concat(items.slice(max + 1));
    } else {
      sortedItems = items.slice(0, min)
        .concat(items.slice(min + 1, max + 1))
        .concat([items[oldIndex]])
        .concat(items.slice(max + 1));
    }
    this.setState({ hashtags: { data: sortedItems } });
    const newOrders = sortedItems.slice(0, maxFeaturedHashtags).map((tp) => (
      tp.attributes.hashtag_id || tp.attributes.hashtagId || tp.id
    ));
    this.props.saveOrders(newOrders);
    vahoy.track('Hashtag/HashtagsEditableForm#onSortEnd');
  };

  renderSortableHashtags() {
    const { saveOrders, maxFeaturedHashtags } = this.props;
    const { hashtags } = this.state;

    if (!saveOrders) {
      return;
    }

    const hashtagsData = hashtags.data;

    if (hashtagsData.length > 0) {
      return (
        <div className="sortable-hashtags mt-3">
          <SortableHashtags
            items={hashtagsData}
            onSortEnd={this.onSortEnd}
            maxFeaturedHashtags={maxFeaturedHashtags || defaultMaxFeaturedHashtags}
            itemClassName="p-2 mt-1 mb-1"
            axis="xy"
          />
        </div>
      );
    }
  }

  render() {
    const {
      saveOrders,
      maxFeaturedHashtags = defaultMaxFeaturedHashtags,
      max_hashtags: maxHashtags,
      closeEditingModal,
      modalTitle,
    } = this.props;
    const additionalNotice = saveOrders && `Drag to reorder tags. The first ${maxFeaturedHashtags} ${maxFeaturedHashtags === 1 ? 'tag' : 'tags'} will be displayed on your profile.`;

    return (
      <Modal
        id="hashtags-form-modal"
        className="hashtags-form-modal modal-lg"
        isOpen
        toggle={this.handleCancel}
        onOpened={this.onOpened}
      >

        <ModalHeader toggle={this.handleCancel}>
          {modalTitle || 'Expertise'}
        </ModalHeader>

        <ModalBody style={{ minHeight: '7rem' }}>
          <div><small>Start typing to add a topic hashtag. Click X to remove.</small></div>
          <div
            ref={(c) => {
              this.hashtagsEditable = c;
            }}
            className="hashtags-editable"
          >
            <select
              ref={(c) => {
                this.hashtagSelect = c;
              }}
              style={{ width: '100%' }}
              multiple
              aria-label="Select expertise"
            />
          </div>
          {maxHashtags && (
            <div className="form-text">
              <em>
                A maximum of {maxHashtags} tags can be associated. {additionalNotice}
              </em>
            </div>
          )}

          {this.renderSortableHashtags()}
        </ModalBody>

        <ModalFooter>
          <Button onClick={closeEditingModal}>Close</Button>
        </ModalFooter>

      </Modal>
    );
  }
}

HashtagsEditableForm.propTypes = {
  hashtagTags: PropTypes.arrayOf(Object),
  tagged_json: PropTypes.instanceOf(Object),
  max_hashtags: PropTypes.number,
  maxFeaturedHashtags: PropTypes.number,
  refreshParent: PropTypes.func.isRequired,
  closeEditingModal: PropTypes.func.isRequired,
  saveOrders: PropTypes.func,
  hashtagType: PropTypes.string.isRequired,
  modalTitle: PropTypes.string,
};

HashtagsEditableForm.defaultProps = {
  hashtagTags: undefined,
  tagged_json: undefined,
  max_hashtags: undefined,
  maxFeaturedHashtags: undefined,
  saveOrders: undefined,
  modalTitle: undefined,
};

export default HashtagsEditableForm;
