import React from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, Cell, Tooltip,
} from 'recharts';
import { connectRefinementList } from 'react-instantsearch-dom';

function RefinementsPieChart({ items, refine }) {
  const COLORS = ['#8884d8', '#8dd1e1', '#ffc658', '#ff8042', '#82ca9d', '#d0ed57', '#a4de6c', '#d84d4d', '#d8d888', '#d88ed1'];

  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const color = items[index].isRefined ? 'green' : COLORS[index % COLORS.length];
    const textAnchor = x > cx ? 'start' : 'end';
    const text = `${items[index].label} (${items[index].count})`;

    const textWidth = text.length * 9;
    const textHeight = 30;

    return (
      <>
        <rect
          x={textAnchor === 'start' ? x - 10 : x - (textWidth - 10)}
          y={y - textHeight / 2}
          width={textWidth}
          height={textHeight}
          fill="white"
          rx="10"
          ry="10"
          stroke={color}
          strokeWidth={1}
        />
        <text
          x={x}
          y={y}
          fill={color}
          fontWeight="bold"
          textAnchor={textAnchor}
          dominantBaseline="central"
        >
          {text}
        </text>
      </>
    );
  };

  return (
    <div style={{
      width: '100%', height: '80vh', display: 'block', position: 'relative',
    }}
    >
      <div className="insights-pie-chart" style={{ flex: 3 }}>
        <PieChart width={1000} height={800}>
          <Pie
            data={items}
            cx="50%"
            cy="300"
            innerRadius={70}
            outerRadius={230}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="count"
            nameKey="label"
            label={renderCustomizedLabel}
          >
            {items.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.isRefined ? 'green' : COLORS[index % COLORS.length]} onClick={() => refine(entry.value)} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>
    </div>
  );
}

RefinementsPieChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  refine: PropTypes.func.isRequired,
};

const ConnectedRefinementsPieChart = connectRefinementList(RefinementsPieChart);

export default ConnectedRefinementsPieChart;
