import React from 'react';
import PropTypes from 'prop-types';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

function ShowIfAnyRefinements({ items, children }) {
  return (
    <>
      {items.length > 0 && (
        React.Children.map(children, (child) => child)
      )}
    </>
  );
}

ShowIfAnyRefinements.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

ShowIfAnyRefinements.defaultProps = {
  items: undefined,
};

export default connectCurrentRefinements(ShowIfAnyRefinements);
