import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (window.Rollbar) {
      window.Rollbar.error(error, errorInfo);
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="container h-100">
          <div className="row justify-content-center align-self-center">
            <div className="col col-lg-8">
              <div className="card m-4 animated faster fadeIn">
                <div className="card-header text-center bg-warning text-white">
                  <h1 className="alert-heading my-1 font-size-36px">
                    <i className="fa fa-triangle-exclamation" />
                  </h1>
                </div>
                <div className="card-body">
                  <h1 className="card-title">Oops.</h1>
                  <p className="card-text">
                    Sorry. It looks like something went wrong.
                  </p>
                </div>
                <div className="card-footer bg-white text-muted">
                  <small>
                    Please try again. If the problem continues, please contact us at&nbsp;
                    <a
                      href="mailto:support@qwoted.com"
                    >support@qwoted.com
                    </a>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorBoundary;
