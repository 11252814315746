import React from 'react';
import PropTypes from 'prop-types';

function Preview(props) {
  const { previewClass, value } = props;

  /* eslint-disable react/no-danger */
  return (
    <div
      className={`md-editor-preview ${previewClass}`}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
  /* eslint-enable react/no-danger */
}

Preview.propTypes = {
  previewClass: PropTypes.string,
  value: PropTypes.string,
};

Preview.defaultProps = {
  previewClass: '',
  value: undefined,
};

export default Preview;
