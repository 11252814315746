import React from 'react';
import PropTypes from 'prop-types';
import ActionButtons from './action_buttons';
import ResponsiveAvatarUsingAspectRatio from '../../responsive_avatar_using_aspect_ratio';

function ProductCard({
 product, updates, displayContactAndInfo, handleSaveProduct,
}) {
  // Late arriving attributes to be merged in, if any
  if (updates) Object.assign(product, updates.attributes);
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const maxDescriptionLength = 100;

  return (
    <div className={`card h-100 product-card product-card-${product.objectID} animated faster fadeIn`}>
      <div className="card-body">
        <ResponsiveAvatarUsingAspectRatio
          avatarUrl={product.image_url}
          emptyIcon="tag"
          altText={product.name}
          linkHref={product.product_path}
        />
        { product.company && (
          <span className="d-block small mt-3 mb-1">
            {product.company.name}
          </span>
        )}
        <h4 className="fw-bold" style={{ fontSize: '1.3em' }}>
          <a href={product.product_path} alt={product.name}>
            {product.name}
          </a>
        </h4>

        {product.samples_available && <span className="badge bg-secondary mb-2">Sample available</span>}
        {product.price && <h5 className="mb-3">{USDollar.format(product.price)}</h5>}

        {product.description && (
          <p className="m-0 lh-sm">
            {product.description.length > maxDescriptionLength ? `${product.description.substring(0, maxDescriptionLength - 3)}...` : product.description}
          </p>
        )}
      </div>

      {displayContactAndInfo && (
        <div className="card-footer border-0 bg-white py-3">
          <ActionButtons
            product={product}
            displayContactAndInfo={displayContactAndInfo}
            handleSaveProduct={handleSaveProduct}
          />
        </div>
      )}
    </div>
  );
}

ProductCard.propTypes = {
  product: PropTypes.instanceOf(Object),
  updates: PropTypes.instanceOf(Object),
  displayContactAndInfo: PropTypes.bool,
  handleSaveProduct: PropTypes.func,
};

ProductCard.defaultProps = {
  product: undefined,
  updates: undefined,
  displayContactAndInfo: true,
  handleSaveProduct: () => {},
};

export default ProductCard;
