import React, { useState } from 'react';
import PropTypes from 'prop-types';
import vapi from '../javascript/frontend/api/vapi';

function ScoldBuddyDialog(props) {
  const {
    containerStyle, userId, codeOfConductURL,
  } = props;

  const [showAcceptenceContent, setShowAcceptenceContent] = useState(false);

  const agree = () => {
    setShowAcceptenceContent(true);
  };

  const accept = (event) => {
    if (event.target.value === 'y') {
      const userParams = {
        id: userId,
        attributes: { suspected_leaker_dialog_enabled: false },
      };

      vapi.updateUser(userParams)
        .then((response) => {
          if (response.status === 200) {
            window.location.reload();
          }
        });
    }
  };

  return (
    <div className="card" style={containerStyle}>
      <div className="card-body">
        <h3 className="card-title text-danger">
          <i className="fa-solid fa-user-lock" /> Your account is temporarily suspended
        </h3>
        <p className="card-text">
          You have unlocked several opportunities in Qwoted without responding or submitting a pitch.
          Our goal is to ensure that you are not violating our <a href="https://www.qwoted.com/qwoted-community-guidelines/" target="_blank" rel="noreferrer">Community Guidelines</a>
          &nbsp;by emailing or pitching journalists outside of the network.
          <strong>
            Please note that continued unlocks without pitching may result in a
            more permanent suspension of your account.&nbsp;
            If you have any questions or concerns regarding these regulations in Qwoted,
            contact us at any time through <a href="mailto:support@qwoted.com">Support</a>.
          </strong>
        </p>
        <p className="card-text text-danger">
          Please agree to accept our Community Guidelines policies to remove the suspension.
        </p>
        <button type="button" className="btn btn-primary" onClick={agree}>AGREE</button>

        {showAcceptenceContent && (
          <>
            <p className="mt-3">
              Please re-read our <a href={codeOfConductURL} target="_blank" rel="noreferrer">Code of
              Conduct
            </a> and
              accept below. By doing so, you agree to pitch inside the platform and understand that repeated
              unlocks
              without pitches will result in being permanently locked out of your account.
            </p>

            <div className="mb-3">
              <label htmlFor="acceptDropdown">Do you accept Qwoted's Code of Conduct?</label>
              <select className="form-select" id="acceptDropdown" onChange={accept}>
                <option value="?">Please select</option>
                <option value="y">I accept Qwoted's code of Conduct</option>
                <option value="n">I do not accept Qwoted's Code of Conduct</option>
              </select>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ScoldBuddyDialog.propTypes = {
  containerStyle: PropTypes.oneOfType([PropTypes.object]),
  userId: PropTypes.number.isRequired,
  codeOfConductURL: PropTypes.string.isRequired,
  // TODO: Is there a plan for this prop or can we remove?
  // learnMoreURL: PropTypes.string,
};

ScoldBuddyDialog.defaultProps = {
  containerStyle: undefined,
  // learnMoreURL: undefined,
};

export default ScoldBuddyDialog;
