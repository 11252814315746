import React from 'react';
import PropTypes from 'prop-types';

function FullPagePaywall({ isOpen = false, onClose }) {
  const navToBilling = () => {
    window.location.href = '/pricing';
  };

  return (
    <>
      {isOpen && (
        <div className="modal-backdrop fade show" />
      )}
      <div
        className={`modal w-full fade ${isOpen ? 'show' : ''}`}
        style={{ display: isOpen ? 'block' : 'none' }}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered media-match-paywall">
          <div className="modal-content">
            <div className="modal-header">
              <i className="fa fa-bullhorn" />
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close upgrade modal"
              ><i className="fa fa-close" /></button>

            </div>
            <div className="modal-body pt-5">
                <i className="fa-light fa-circle-user text-primary" />
              <h4 className="fw-bold">
                Let’s make sure you can send that message.
              </h4>
              <p className="text-secondary">
                Your account has reached its Media Match connection limit. <span className="fw-bold">That’s a lot of networking you’ve done!</span> To continue your streak, upgrade your account to a Premium account to make sure you can access all of Qwoted’s top-notch Media.
              </p>
              <div className="border border-primary border-2 p-3 rounded">
                <div className="secondary-header">
                  <p className="price fw-bold mb-1">$99</p>
                  <div className="d-flex justify-content-between">
                    <span className="fw-bold">Premium Plan</span>
                    <span className="interval">Billed Monthly</span>
                  </div>
                </div>
                <ul className="list-unstyled mt-3 pb-0 mb-0">
                  <li className="mb-3">
                    <i className="fa-solid fa-circle-check text-primary me-2" />
                    Connect with top-notch Qwoted Media
                  </li>
                  <li className="mb-3">
                    <i className="fa-solid fa-circle-check text-primary me-2" />
                    Send unlimited Pitch requests
                  </li>
                  <li className="mb-3">
                    <i className="fa-solid fa-circle-check text-primary me-2" />
                    Access to advanced search filters
                  </li>
                  <li className="mb-0">
                    <i className="fa fa-plus text-primary me-2" />
                    And so much more!
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                className="btn btn-primary px-4"
                onClick={navToBilling}
                type="button"
              >
                Compare Plans
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

FullPagePaywall.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

FullPagePaywall.defaultProps = {
  isOpen: false,
};

export default FullPagePaywall;
