// Place common utility functions here for DRY code
// import as named import. Ex:
//   import { convertStringToBool } from '../utilities';

// eslint-disable-next-line import/prefer-default-export
export const convertStringToBool = (string) => {
  if (string) {
    return JSON.parse(string);
  }
};

export const sortSourcesByName = (sources) => sources.sort((src, src2) => {
  const srcName = src.attributes.full_name;
  const src2Name = src2.attributes.full_name;

  if (srcName < src2Name) return -1;
  return 1;
});

export const sortByName = (items) => items.sort((src, src2) => {
  const srcName = src.attributes.name;
  const src2Name = src2.attributes.name;

  if (srcName < src2Name) return -1;
  return 1;
});

const toCamelCase = (str) => str.replace(/([-_][a-z])/gi, (match) => match.toUpperCase().replace('-', '').replace('_', ''));

export const keysToCamelCase = (obj) => {
  if (obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function') {
    const n = {};
    Object.keys(obj).forEach((key) => {
      n[toCamelCase(key)] = keysToCamelCase(obj[key]);
    });
    return n;
  } if (Array.isArray(obj)) {
    return obj.map((i) => keysToCamelCase(i));
  }
  return obj;
};
