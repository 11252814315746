import React from 'react';
import Skeleton from 'react-loading-skeleton';

function SourceSkeletonCardMinimal() {
  return (
    <div className="card skeleton-card animated faster fadeIn">
      <div className="card-body position-relative">
        {/* Responsive avatar */}
        <div className="avatar-responsive-container mb-3">
          <Skeleton height="100%" className="avatar-responsive-empty" />
        </div>

        <Skeleton height={20} width="40%" />
        <div className="my-2">
          <Skeleton height={8} width="75%" />
        </div>

        {/* Hashtags */}
        <div>
          <Skeleton height={10} width={80} className="p-1 mb-1 me-1" />
          <Skeleton height={10} width={120} className="p-1 mb-1 me-1" />
          <Skeleton height={10} width={100} className="p-1 mb-1 me-1" />
        </div>

        <div className="clearfix" style={{ minHeight: '3rem' }} />

        <div className="mt-4 mb-3 row w-100" style={{ position: 'absolute', bottom: '0' }}>
          <div className="col">
            <Skeleton height={20} width={80} className="me-2" />
          </div>
          <div className="d-flex align-items-end me-3">
            <Skeleton height={20} width={20} className="me-3" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SourceSkeletonCardMinimal;
