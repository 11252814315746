import React from 'react';
import PropTypes from 'prop-types';

function TabNavigation({
 activeTab, onTabChange, isLoading, tabs,
}) {
  return (
    <ul className="nav nav-tabs">
      {tabs.map((tab) => (
        <li className="nav-item" key={tab}>
          <button
            className={`nav-link ${activeTab === tab ? 'active' : ''}`}
            onClick={() => !isLoading && onTabChange(tab)}
            type="button"
            disabled={isLoading}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        </li>
      ))}
    </ul>
  );
}

TabNavigation.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tabs: PropTypes.array.isRequired,
};

export default TabNavigation;
