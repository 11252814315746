import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  getUser(userId, params) {
    vlog.info('api#getUser');

    api.configure();

    const url = `/api/internal/jsonapi/users/${userId}`;

    return axios.get(url, { params }, api.axiosFix);
  },

  updateUser(user) {
    vlog.info('api#updateUser');

    api.configure();

    const url = `/api/internal/jsonapi/users/${user.id}`;

    const data = {
      id: user.id,
      type: 'users',
      attributes: user.attributes,
    };

    delete data.links;
    delete data.relationships;

    return axios.put(url, { data }, api.axiosFix);
  },

  getUsers(params) {
    vlog.info('api#getUser');

    api.configure();

    const url = '/api/internal/jsonapi/users';

    return axios.get(url, { params }, api.axiosFix);
  },

  signUp(params) {
    vlog.info('api#signUp');

    api.configure();
    axios.defaults.headers.common.Accept = 'application/json';

    const url = '/users/sign_up';

    return axios.post(url, params, api.axiosFix);
  },

  flagUser(userId, params) {
    vlog.info('api#flagUser');

    api.configure();

    const url = `/api/internal/users/${userId}/flag`;

    return axios.put(url, params, api.axiosFix);
  },

  geolocateIpAddress(params) {
    vlog.info('api#geolocateIpAddress');

    api.configure();

    const url = '/api/internal/ahoy/visits/geolocate';

    return axios.get(url, { params: { ip_addresses: params } }, api.axiosFix);
  },
});
