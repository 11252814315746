import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import vahoy from '../../javascript/vahoy';
import vapi from '../../javascript/frontend/api/vapi';

function OrganizationLogo({
                            editable,
                            orgLogoURL,
                            orgID,
                            orgName,
                            orgType,
                          }) {
  const [logoURL, setLogoURL] = useState(orgLogoURL);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const inputFileRef = useRef(null);

  const handleHover = (pointerIsInside) => {
    setIsHovering(pointerIsInside);
  };

  const getUpdatedCompanyLogo = () => {
    // TODO: Instead of making a subsequent request here, figure out how to derive the logo_url from the original
    // response. Something appears to be happening async. If this is resolved, then this method can be removed,
    // as well as the three conditionals in #updateOrganizationLogo. They could simply be replaced with the agnostic
    // location of the logo URL in the update repsonse object.
    vapi.getCompany({ id: orgID })
      .then((response) => {
        setLogoURL(response.data.data.attributes.logo_url);
      });
  };

  const openFileInput = () => {
    inputFileRef.current.click();
  };

  const updateOrganizationLogo = (attributes) => {
    vapi.updateOrganizationLogo(orgID, orgType, attributes)
      .then(() => {
        if (orgType === 'company') {
          getUpdatedCompanyLogo();
        }
        // if (orgType === 'organization') { getOrganization(); }
        // if (orgType === 'publication') { getPublication(); }
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.');
      })
      .finally(() => {
        setSavingInProgress(false);
      });
  };

  const uploadOrganizationLogo = (event) => {
    const { files = [] } = event.target;

    if (files.length > 0) {
      const file = files[0];
      const attributes = { image_cloudinary: file };

      setSavingInProgress(true);

      updateOrganizationLogo(attributes);

      vahoy.track('OrganizationLogo#onLogoChange');
    }
  };

  const deleteOrganizationLogo = () => {
    const attributes = { remove_image_cloudinary: true };

    setSavingInProgress(true);

    updateOrganizationLogo(attributes);

    vahoy.track('OrganizationLogo#onLogoDelete');
  };

  return (
    <div
      className="organization-logo"
      style={{
        position: 'relative',
        borderRadius: '3.2px',
        overflow: 'hidden',
        maxHeight: '160px',
        maxWidth: '160px',
        margin: 'auto',
      }}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      {logoURL && (
        <img
          alt={orgName}
          src={logoURL}
          style={{
            maxWidth: '100%',
          }}
        />
      )}

      {!logoURL && (
        <i
          className="fa-solid fa-city fa-fw fa-8x"
          style={{
            color: 'lightgrey',
            margin: '16px 0',
            width: '100%',
          }}
        />
      )}

      {editable && orgID && orgType && (
        <div
          className={classNames({ fadeIn: (isHovering || savingInProgress || !logoURL) }, 'position-absolute d-inline-block btn-edit-organization-logo animated faster')}
          style={{
            bottom: 5,
            right: 5,
            visibility: (isHovering || savingInProgress || !logoURL) ? 'visible' : 'hidden',
          }}
        >
          <div className="btn-group btn-group-sm" role="group" aria-label="organization-logo-admin-buttons">
            {savingInProgress && (
              <button type="button" className="btn btn-primary">
                <i className="fa-solid fa-spinner fa-spin fa-fw" />
              </button>
            )}

            {!savingInProgress && (
              <>
                <button type="button" className="btn btn-primary" onClick={openFileInput}>
                  <i className="fa-solid fa-pencil fa-fw" />
                </button>

                {logoURL && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={deleteOrganizationLogo}
                  >
                    <i className="fa-solid fa-trash fa-fw" />
                  </button>
                )}
              </>
            )}
          </div>

          <input
            accept=".png, .jpg, .jpeg, .svg"
            className=""
            id="logo_input"
            onChange={uploadOrganizationLogo}
            ref={inputFileRef}
            style={{ display: 'none' }}
            type="file"
          />
        </div>
      )}
    </div>
  );
}

OrganizationLogo.propTypes = {
  editable: PropTypes.bool,
  orgID: PropTypes.number,
  orgLogoURL: PropTypes.string,
  orgName: PropTypes.string,
  orgType: PropTypes.string,
};

OrganizationLogo.defaultProps = {
  editable: false,
  orgID: undefined,
  orgLogoURL: undefined,
  orgName: undefined,
  orgType: undefined,
};

export default OrganizationLogo;
