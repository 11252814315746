import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import BillingAccountForm from './_form';

class StripeFormWrapper extends React.Component {
  STRIPE_PROMISE = loadStripe(this.props.stripePublishableKey);

  constructor(props) {
    super(props);
    this.state = { wasSuccessful: false };
  }

  componentWillUnmount() {
    const { wasSuccessful } = this.state;
    if (!wasSuccessful) {
      // This is an intentional location reload meant to unload the Stripe
      // iframe. There's no need to keep Stripe loaded in memory once we
      // leave the payment or payment method update page:
      // https://github.com/stripe/react-stripe-elements/issues/99
      window.location.reload();
    }
  }

  handleSuccess = () => {
    this.setState({ wasSuccessful: true });
  };

  render() {
    const {
      sourcePlan,
      priceString,
      planNameString,
      planInterval,
      successUrl,
      defaultName,
      defaultCompany,
      defaultEmail,
      accountCode,
      cancelPath,
      onboardedUser,
      delegateUserId,
      currentUserId,
      promotionCodeId,
      pageName,
      freeTrial,
      hideLinksForCcPage,
    } = this.props;

    return (
      <Elements stripe={this.STRIPE_PROMISE}>
        <BillingAccountForm
          sourcePlan={sourcePlan}
          priceString={priceString}
          planNameString={planNameString}
          planInterval={planInterval}
          successUrl={successUrl}
          defaultName={defaultName}
          defaultCompany={defaultCompany}
          defaultEmail={defaultEmail}
          onSuccess={this.handleSuccess}
          accountCode={accountCode}
          cancelPath={cancelPath}
          onboardedUser={onboardedUser}
          delegateUserId={delegateUserId}
          currentUserId={currentUserId}
          promotionCodeId={promotionCodeId}
          pageName={pageName}
          freeTrial={freeTrial}
          hideLinksForCcPage={hideLinksForCcPage}
        />
      </Elements>
    );
  }
}

StripeFormWrapper.propTypes = {
  stripePublishableKey: PropTypes.string.isRequired, // Stripe public key to pass to the loadStripe method
  sourcePlan: PropTypes.string, // Stripe ID for the plan used for subscription
  priceString: PropTypes.string, // String representation of price to charge immediately
  planNameString: PropTypes.string, // Name of the plan to display on form and submit button
  planInterval: PropTypes.string, // 'month' or 'year' depending on the billing plan interval
  successUrl: PropTypes.string.isRequired, // URL to redirect to when the payment is successful
  defaultName: PropTypes.string.isRequired, // Name to pre-populate in the billing name field
  defaultEmail: PropTypes.string.isRequired, // Email to pre-populate in the billing email field
  cancelPath: PropTypes.string.isRequired, // Where to navigate to when the user clicks the cancel link
  onboardedUser: PropTypes.bool.isRequired, // Indicates whether or not the signed in user is already onboarded
  defaultCompany: PropTypes.string, // Existing company name provided duing payment method update
  accountCode: PropTypes.number, // Billing account ID to indicate payment method update rather than billing account creation
  delegateUserId: PropTypes.number, // If current user is an admin, send in the user id to attach the new billing account to
  currentUserId: PropTypes.number,
  promotionCodeId: PropTypes.string, // If there is an applied promotion, the Stripe ID for that promotion
  pageName: PropTypes.string,
  freeTrial: PropTypes.bool,
  hideLinksForCcPage: PropTypes.bool,
};

StripeFormWrapper.defaultProps = {
  sourcePlan: undefined,
  priceString: undefined,
  planNameString: undefined,
  planInterval: undefined,
  defaultCompany: undefined,
  accountCode: undefined,
  delegateUserId: undefined,
  currentUserId: undefined,
  promotionCodeId: undefined,
  pageName: undefined,
  freeTrial: false,
  hideLinksForCcPage: false,
};

export default StripeFormWrapper;
