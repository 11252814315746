import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';

import NoResultsMessage from '../../algolia_search/no_results_message';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import { keysToCamelCase } from '../../utilities';

// Renderer component that uses hits received from Algolia and injected template
// components to show a list of public pitches.
function InfiniteHits({
  PublicPitch,
  PublicPitchSkeleton,
  canSendMessages,
  currentUserSlug,
  defaultConversationStartPath,
  hasMore,
  hits,
  refine,
}) {
  const publicPitches = hits.map(keysToCamelCase);
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });

  // Effect to refine results when infinite scroll triggers
  useEffect(() => {
    if (lastTriggeredAt > 0) refine();
  }, [lastTriggeredAt, refine]);

  return (
    <>
      <NoResultsMessage />
      {publicPitches.map((publicPitch) => (
        <PublicPitch
          key={publicPitch.id}
          canSendMessages={canSendMessages}
          currentUserSlug={currentUserSlug}
          defaultConversationStartPath={defaultConversationStartPath}
          publicPitch={publicPitch}
        />
      ))}
      {hasMore && (
        <div ref={scrollTriggerRef}>
          <PublicPitchSkeleton />
        </div>
      )}
    </>
  );
}

InfiniteHits.propTypes = {
  PublicPitch: PropTypes.elementType.isRequired,
  PublicPitchSkeleton: PropTypes.elementType.isRequired,
  canSendMessages: PropTypes.bool.isRequired, // can we send messages?
  currentUserSlug: PropTypes.string, // current user friendly ID
  defaultConversationStartPath: PropTypes.string.isRequired,
  hasMore: PropTypes.bool.isRequired, // are there more items to load?
  hits: PropTypes.arrayOf(
    PropTypes.shape({
      avatar_letters: PropTypes.string,
      avatar_url: PropTypes.string,
      company_name: PropTypes.string,
      company_path: PropTypes.string,
      contact_path: PropTypes.string,
      employments_string: PropTypes.string,
      full_name: PropTypes.string.isRequired,
      hashtags: PropTypes.arrayOf(
        PropTypes.shape({
          hashtag: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
      ).isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      pitch_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      pitch_request_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      pitch_text: PropTypes.string.isRequired,
      public_pitch_lede: PropTypes.string,
      public_pitch_preamble: PropTypes.string,
      public_pitch_text: PropTypes.string.isRequired,
      public_pitch_title: PropTypes.string.isRequired,
      schema_version: PropTypes.number.isRequired,
      source_path: PropTypes.string,
    }),
  ).isRequired, // array of pitches
  refine: PropTypes.func.isRequired, // refine is a function that will be called to load more items.
};

InfiniteHits.defaultProps = {
  currentUserSlug: null,
};

const ConnectedInfiniteHits = connectInfiniteHits(InfiniteHits);

export default ConnectedInfiniteHits;
