import React from 'react';
import Skeleton from 'react-loading-skeleton';

function PrUserSkeletonCard() {
  return (
    <div className="card h-100 skeleton-card animated faster fadeIn">
      <div className="card-body position-relative">
        {/* Avatar, name, and title */}
        <div>
          <div className="float-start pr-2">
            <Skeleton height={80} width={80} circle />
          </div>
          <div>
            <Skeleton height={10} width="40%" /><br />
            <Skeleton height={8} width="30%" />
            <Skeleton height={8} width="30%" />
            <Skeleton height={8} width="30%" />
          </div>
        </div>

        <div className={`clearfix ${'mb-4'}`} />

        {/* Contact Info */}
        <div className="mb-0">
          <Skeleton height={8} width={120} />
        </div>

        {/* Status */}
        <div>
          <Skeleton height={10} width="100%" />
          <Skeleton height={10} width="80%" />
        </div>

        {/* Details */}
        <p className="my-2">
          <Skeleton height={8} count={2} />
          <Skeleton height={8} width="60%" />
        </p>

        {/* Hashtags */}
        <div>
          <Skeleton height={10} width={80} className="p-1 mb-1 mr-1" />
          <Skeleton height={10} width={120} className="p-1 mb-1 mr-1" />
          <Skeleton height={10} width={100} className="p-1 mb-1 mr-1" />
          <Skeleton height={10} width={140} className="p-1 mb-1 mr-1" />
          <Skeleton height={10} width={80} className="p-1 mb-1 mr-1" />
        </div>

        <div className="reverse-space" style={{ minHeight: '3.0rem' }} />

        {/* Tags/labels */}
        <div
          className="card-footer p-0 pb-2 bg-transparent border-0 position-absolute w-100"
          style={{ bottom: 0 }}
        >
          <Skeleton height={10} width={160} className="mt-1" /><br />
          <Skeleton height={10} width={80} className="mt-1" />
        </div>
      </div>
    </div>
  );
}

export default PrUserSkeletonCard;
