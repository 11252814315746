import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch, SearchBox,
} from 'react-instantsearch-dom';
import ClearRefinementsButton from '../../algolia_search/clear_refinements_button';
import InfiniteHits from './infinite_hits';
import GeoFilterPanel from './geo_filter_panel';
import PublicationFilterByNamePanel from './publication_filter_by_name_panel';
import PublicationFilterByTierPanel from './publication_filter_by_tier_panel';
import HashtagFilterPanel from './hashtag_filter_panel';

function ReportersSearch({
                             query, algoliaAppId, algoliaSearchKey, indexName, displayContactAndInfo, displayFilters,
                           }) {
  const [clearGeoSearch, setClearGeoSearch] = useState(false);

  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

  const handleClearAllSearch = () => {
    setClearGeoSearch(true);
  };

  const handleClearGeoSearch = () => {
    setClearGeoSearch(false);
  };

  return (
    <div className="ais-InstantSearch mt-3">
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
      >
        <div className="row">
          {displayFilters && (
            <div className="col-12 col-md-3 pr-0">
              <GeoFilterPanel clearGeoSearch={clearGeoSearch} onGeoSearchCleared={handleClearGeoSearch} />
              <PublicationFilterByTierPanel />
              <PublicationFilterByNamePanel />
              <HashtagFilterPanel />
            </div>
          )}

          <div className="col">
            <div className="d-flex flex-row pl-3">
              <SearchBox
                autoFocus
                className="flex-grow-1 me-2"
                defaultRefinement={query}
                searchAsYouType={true}
                translations={{ placeholder: 'Search' }}
              />
              <ClearRefinementsButton
                buttonText="Clear"
                clearsQuery={true}
                onClear={handleClearAllSearch}
              />
            </div>
            <div className="row pl-3 mt-4">
              <InfiniteHits displayContactAndInfo={displayContactAndInfo} />
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

ReportersSearch.propTypes = {
  query: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  displayContactAndInfo: PropTypes.bool,
  displayFilters: PropTypes.bool,
};

ReportersSearch.defaultProps = {
  query: '',
  displayContactAndInfo: true,
  displayFilters: false,
};

export default ReportersSearch;
