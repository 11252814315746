import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/avatar';
import InviteSourceToPitchButton from './invite_source_to_pitch_button';
import helper from '../../../javascript/frontend/helpers/helper';
import vlog from '../../../javascript/vlog';

function V2Experts({
                     num_sources: numSources,
                     source_request_id: sourceRequestId,
                     source_request_text: sourceRequestText,
                     source_requests: sourceRequests,
                   }) {
  const [v2SimilarExperts, setV2SimilarExperts] = useState([]);
  const [experts, setExperts] = useState([]);

  useEffect(() => {
    const queryText = encodeURIComponent(sourceRequestText);
    const apiUrl = `${window.V2_URL}/match_feed/api/similarity_search?query=${queryText}&search_model_types=Id%3A%3APromoEntity%3A%3AExpert&embedding_types=MatchFeed%3A%3AAggregatedEmbedding&limit=${numSources}`;

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        Apikey: window.SUPABASE_ANON_KEY,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.data?.results) {
          setV2SimilarExperts(response.data?.results);
        } else {
          vlog.warn('No results found');
        }
      })
      .catch((error) => {
        vlog.error(apiUrl, error.toString());
      });
  }, [sourceRequestText, numSources]);

  useEffect(() => {
    const fetchExperts = async (idFromGid) => {
      try {
        const { data, error } = await window.supabaseClient
          .from('experts')
          .select('*')
          .eq('id', idFromGid);

        if (error) {
          vlog.error('Error fetching expert:', error.message);
          return;
        }

        if (data.length > 0) {
          const filteredExperts = data.filter((expert) => !expert.profile_image_url.includes('dicebear'));
          setExperts((prevExperts) => [...prevExperts, ...filteredExperts]);
        } else {
          vlog.warn('No expert data found for ID:', idFromGid);
        }
      } catch (err) {
        vlog.error('Unexpected error fetching expert:', err);
      }
    };

    if (v2SimilarExperts.length > 0) {
      v2SimilarExperts.forEach((similarExpert) => {
        const gid = similarExpert.model_gid;
        const idFromGid = gid.split('/').pop();
        fetchExperts(idFromGid);
      });
    }
  }, [v2SimilarExperts]);

  const renderSource = (source) => (
    <div key={source.migrate_id} className="col-12 col-md-4 mb-2">
      <div style={{
        height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
      }}
      >
        <div className="card mb-2 px-3 py-2 w-100" style={{ flexGrow: 1, textAlign: 'center' }}>
          <div className="card-body" style={{ minHeight: '120px' }}>
            <h6 className="fw-bold">{source.featured_company_name}</h6>
            <div className="mb-2" />
            <Avatar
              avatarUrl={source.profile_image_url}
              avatarLetters=""
              className="rounded-circle ms-auto me-auto"
            />
            <div className="mb-3" />
            <h5 className="mb-1 fw-bold">
              <a href={`/sources/${source.migrate_id}`}>
                {source.name}
              </a>
            </h5>
            <div className="card-grid-subtitle fw-bold font-size-12px">
              {source.featured_title_at_company}
            </div>
            <div className="mb-2" />
            <div className="font-size-14px">{helper.beautifulTruncateStr(source.description, 250)}</div>
          </div>
          <div className="d-grid">
            <InviteSourceToPitchButton
              origin_type="v2_experts"
              source_id={source.migrate_id}
              source_name={source.name}
              source_request_id={sourceRequestId}
              source_requests={sourceRequests}
            />
          </div>
          <div className="mb-2" />
        </div>
      </div>
    </div>
  );

  return (
    <div className="row">
      {experts.map((source) => renderSource(source))}
    </div>
  );
}

V2Experts.propTypes = {
  num_sources: PropTypes.number,
  source_request_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  source_request_text: PropTypes.string,
  source_requests: PropTypes.arrayOf(PropTypes.object),
};

V2Experts.defaultProps = {
  num_sources: undefined,
  source_request_id: undefined,
  source_request_text: undefined,
  source_requests: undefined,
};

export default V2Experts;
