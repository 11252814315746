import vlog from '../../javascript/vlog';

export const handleFollow = async (
followableType,
followableId,
profileId,
authenticatedHeadersHash,
  setIsActionLoading,
fetchActiveTab,
currentPage,
) => {
  setIsActionLoading(true); // Start spinner
  const data = {
    data: {
      attributes: {
        profile_id: profileId,
        followable_type: followableType,
        followable_id: followableId,
        follow_status: 'follow',
      },
    },
  };

  try {
    const response = await fetch(`${window.V2_URL}/match_feed/api/follows`, {
      method: 'POST',
      headers: new Headers({
        ...authenticatedHeadersHash,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    });

    if (response.ok) {
      fetchActiveTab(currentPage); // Refresh the active tab after action
    } else {
      throw new Error('Network response was not ok.');
    }
  } catch (err) {
    vlog.error('Error following hashtag:', err);
  } finally {
    setIsActionLoading(false); // Stop spinner
  }
};

export const handleUnfollow = async (
followableType,
followableId,
profileId,
authenticatedHeadersHash,
  setIsActionLoading,
fetchActiveTab,
currentPage,
) => {
  setIsActionLoading(true); // Start spinner
  const data = {
    data: {
      attributes: {
        profile_id: profileId,
        followable_type: followableType,
        followable_id: followableId,
        follow_status: 'unfollow',
      },
    },
  };

  try {
    const response = await fetch(`${window.V2_URL}/match_feed/api/follows`, {
      method: 'POST',
      headers: new Headers({
        ...authenticatedHeadersHash,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    });

    if (response.ok) {
      fetchActiveTab(currentPage); // Refresh the active tab after action
    } else {
      throw new Error('Network response was not ok.');
    }
  } catch (err) {
    vlog.error('Error ignoring hashtag:', err);
  } finally {
    setIsActionLoading(false); // Stop spinner
  }
};

export const handleBlock = async (
followableType,
followableId,
profileId,
authenticatedHeadersHash,
  setIsActionLoading,
fetchActiveTab,
currentPage,
) => {
  setIsActionLoading(true); // Start spinner
  const data = {
    data: {
      attributes: {
        profile_id: profileId,
        followable_type: followableType,
        followable_id: followableId,
        follow_status: 'block',
      },
    },
  };

  try {
    const response = await fetch(`${window.V2_URL}/match_feed/api/follows`, {
      method: 'POST',
      headers: new Headers({
        ...authenticatedHeadersHash,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    });

    if (response.ok) {
      fetchActiveTab(currentPage); // Refresh the active tab after action
    } else {
      throw new Error('Network response was not ok.');
    }
  } catch (err) {
    vlog.error('Error blocking hashtag:', err);
  } finally {
    setIsActionLoading(false); // Stop spinner
  }
};
