import React from 'react';
import _ from 'lodash';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import LoadingImage from '../loading_image';
import AutocompletePlace from '../autocomplete_place';
import helper from '../../javascript/frontend/helpers/helper';
import vahoy from '../../javascript/vahoy';

const requiredErrorText = 'This field is required';
const duplicateErrorText = 'Duplicate value';
const invalidErrorText = 'Invalid value';
const primaryErrorText = 'Only 1 primary value is allowed';

class ReporterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInititalState(props);
  }

  getInititalState(props) {
    const form = this.getFormFromProps(props);

    return {
      form,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isEqual(prevState.responseError, nextProps.responseError)) {
      return { responseError: nextProps.responseError };
    }

    return {};
  }

  getFormFromProps(props) {
    const form = _.cloneDeep(props.reporter || {});
    form.contact_infos = form.contact_infos || [];
    const contactInfos = form.contact_infos;
    const infoTypes = ['email', 'phone', 'twitter', 'facebook', 'linkedin'];

    for (let i = 0; i < infoTypes.length; i += 1) {
      const infoType = infoTypes[i];
      const record = contactInfos.find((ci) => ci.attributes.info_type === infoType);

      if (!record) {
        contactInfos.push({
          type: 'contact_infos',
          attributes: {
            info_type: infoType,
          },
        });
      }
    }

    return form;
  }

  getFormErrors(form) {
    const errors = {};

    if (!form.attributes.full_name) {
      errors.full_name = requiredErrorText;
    }

    const contactInfos = (form.contact_infos || []).filter((rc) => {
      const result = _.trim(rc.attributes.value) && !rc.attributes._destroy;
      return result;
    });
    const emailRecords = contactInfos.filter((emailRecord) => emailRecord.attributes.info_type === 'email');
    const phoneRecords = contactInfos.filter((emailRecord) => emailRecord.attributes.info_type === 'phone');
    const twitterRecords = contactInfos.filter((emailRecord) => emailRecord.attributes.info_type === 'twitter');
    const facebookRecords = contactInfos.filter((emailRecord) => emailRecord.attributes.info_type === 'facebook');
    const linkedinRecords = contactInfos.filter((emailRecord) => emailRecord.attributes.info_type === 'linkedin');

    for (let i = emailRecords.length - 1; i >= 0; i -= 1) {
      const emailRecord = emailRecords[i];
      const email = _.trim(emailRecord.attributes.value)
        .toLowerCase();

      if (!helper.isValidEmail(email)) {
        errors.emails = errors.emails || {};
        errors.emails[`${i}`] = invalidErrorText;
      } else {
        const emails = emailRecords
          .map((record) => _.trim(record.attributes.value)
            .toLowerCase())
          .filter((str) => str === email);
        if (emails.length > 1) {
          errors.emails = errors.emails || {};
          errors.emails[`${i}`] = duplicateErrorText;
        }
      }

      if (!errors.emails && emailRecords.length > 1) {
        const primaryRecords = emailRecords.filter((rc) => this.isPrimary(rc));

        if (primaryRecords.length !== 1) {
          errors.emails = primaryErrorText;
        }
      }
    }

    if (phoneRecords.length > 0) {
      for (let j = phoneRecords.length - 1; j >= 0; j -= 1) {
        const record = phoneRecords[j];
        const value = _.trim(record.attributes.value);

        if (!helper.isValidPhone(value)) {
          errors.phones = errors.phones || {};
          errors.phones[`${j}`] = invalidErrorText;
        } else {
          const values = phoneRecords.map((rc) => _.trim(rc.attributes.value))
            .filter((str) => str === value);
          if (values.length > 1) {
            errors.phones = errors.phones || {};
            errors.phones[`${j}`] = duplicateErrorText;
          }
        }
      }

      if (!errors.phones && phoneRecords.length > 1) {
        const primaryRecords = phoneRecords.filter((rc) => this.isPrimary(rc));
        if (primaryRecords.length !== 1) {
          errors.phones = primaryErrorText;
        }
      }
    }

    if (twitterRecords.length > 0) {
      for (let k = twitterRecords.length - 1; k >= 0; k -= 1) {
        const record = twitterRecords[k];
        const value = _.trim(record.attributes.value)
          .toLowerCase();

        if (!helper.isValidTwitter(value)) {
          errors.twitters = errors.twitters || {};
          errors.twitters[`${k}`] = 'Invalid value';
        } else {
          const values = twitterRecords.map((rc) => _.trim(rc.attributes.value)
            .toLowerCase())
            .filter((str) => str === value);
          if (values.length > 1) {
            errors.twitters = errors.twitters || {};
            errors.twitters[`${k}`] = 'Duplicate value';
          }
        }
      }

      if (!errors.twitters && twitterRecords.length > 1) {
        const primaryRecords = twitterRecords.filter((rc) => this.isPrimary(rc));
        if (primaryRecords.length !== 1) {
          errors.twitters = primaryErrorText;
        }
      }
    }

    if (facebookRecords.length > 0) {
      for (let l = facebookRecords.length - 1; l >= 0; l -= 1) {
        const record = facebookRecords[l];
        const value = _.trim(record.attributes.value)
          .toLowerCase();

        if (!helper.isValidFacebook(value)) {
          errors.facebooks = errors.facebooks || {};
          errors.facebooks[`${l}`] = 'Invalid value';
        } else {
          const values = facebookRecords.map((rc) => _.trim(rc.attributes.value)
            .toLowerCase())
            .filter((str) => str === value);
          if (values.length > 1) {
            errors.facebooks = errors.facebooks || {};
            errors.facebooks[`${l}`] = 'Duplicate value';
          }
        }
      }

      if (!errors.facebooks && facebookRecords && facebookRecords.length > 1) {
        const primaryRecords = facebookRecords.filter((rc) => this.isPrimary(rc));
        if (primaryRecords.length !== 1) {
          errors.facebooks = primaryErrorText;
        }
      }
    }

    if (linkedinRecords.length > 0) {
      for (let m = linkedinRecords.length - 1; m >= 0; m -= 1) {
        const record = linkedinRecords[m];
        const value = _.trim(record.attributes.value)
          .toLowerCase();

        if (!helper.isValidLinkedin(value)) {
          errors.linkedins = errors.linkedins || {};
          errors.linkedins[`${m}`] = 'Invalid value';
        } else {
          const values = linkedinRecords.map((rc) => _.trim(rc.attributes.value)
            .toLowerCase())
            .filter((str) => str === value);
          if (values.length > 1) {
            errors.linkedins = errors.linkedins || {};
            errors.linkedins[`${m}`] = 'Duplicate value';
          }
        }
      }

      if (!errors.linkedins && linkedinRecords.length > 1) {
        const primaryRecords = linkedinRecords.filter((rc) => this.isPrimary(rc));
        if (primaryRecords.length !== 1) {
          errors.linkedins = primaryErrorText;
        }
      }
    }

    return errors;
  }

  handleInputChange = (field, value) => {
    const { form } = this.state;
    const states = {};

    form.attributes[field] = value;
    states.form = form;

    this.setState(states);
  };

  storeLocation = (location) => {
    const { form } = this.state;
    const states = {};

    if (location) {
      form.attributes.skip_geocoding = true;
      form.attributes.location_geocoded_at = Date.now();
      form.attributes.location_latitude = location.lat;
      form.attributes.location_longitude = location.lng;
      form.attributes.location_string = location.fullAddress;
    } else {
      form.attributes.skip_geocoding = true;
      form.attributes.location_geocoded_at = '';
      form.attributes.location_latitude = '';
      form.attributes.location_longitude = '';
      form.attributes.location_string = '';
    }

    states.form = form;

    this.setState(states);
    vahoy.track('Reporter/ReporterModal#storeLocation');
  };

  handleArrayFieldChange = (infoType, index, value) => {
    const { form } = this.state;
    const arr = (form.contact_infos || []).filter((rc) => {
      const result = (rc.attributes.info_type === infoType) && !rc.attributes._destroy;
      return result;
    });
    const record = arr[index];

    record.attributes.value = value;
    const values = arr.map((a) => a && a.attributes.value)
      .filter((s) => s);

    if (values.length === 1) {
      if (value) {
        record.attributes.primary = true;
      } else {
        record.attributes.primary = false;
      }
    }

    if (!value) {
      record.attributes.primary = false;
    }

    this.setState({ form });
  };

  setPrimaryField = (infoType, index) => {
    const { form } = this.state;
    const arr = (form.contact_infos || []).filter((rc) => {
      const result = (rc.attributes.info_type === infoType) && !rc.attributes._destroy;
      return result;
    });
    const record = arr[index];

    for (let i = 0; i < arr.length; i += 1) {
      const rc = arr[i];
      rc.attributes.primary = false;
    }

    record.attributes.primary = true;
    this.setState({ form });
    vahoy.track('Reporter/ReporterModal#setPrimaryField');
  };

  removeArrayField = (infoType, index) => {
    const { form } = this.state;
    const arr = (form.contact_infos || []).filter((rc) => {
      const result = (rc.attributes.info_type === infoType) && !rc.attributes._destroy;
      return result;
    });
    const record = arr[index];

    if (!record.id) {
      form.contact_infos = _.without(form.contact_infos, record);
    } else {
      record.attributes._destroy = 1;
    }

    this.setState({ form });
  };

  addAnotherElement = (infoType) => {
    const { form } = this.state;
    const record = {
      type: 'contact_infos',
      attributes: {
        info_type: infoType,
        value: '',
      },
    };

    form.contact_infos = form.contact_infos || [];
    form.contact_infos.push(record);
    this.setState({ form });

    vahoy.track('Reporter/ReporterModal#addAnotherElement');
  };

  submit(evt) {
    if (evt) {
      evt.preventDefault();
    }

    const { form } = this.state;
    const { onSave } = this.props;
    const errors = this.getFormErrors(form);

    if (_.isEmpty(errors)) {
      if (onSave) {
        let contactInfos = form.contact_infos || [];
        contactInfos = contactInfos.map((ci) => {
          // eslint-disable-next-line no-param-reassign
          ci.attributes.value = _.trim(ci.attributes.value);

          if (ci.attributes.value) {
            return ci;
          }

          if (ci.id) {
            // eslint-disable-next-line no-param-reassign
            ci.attributes._destroy = true;
            return ci;
          }

          return undefined;
        })
          .filter((obj) => obj);

        const pickupAttrs = [
          'full_name',
          'hide_from_search_engines',
          'skip_geocoding',
          'location_geocoded_at',
          'location_latitude',
          'location_longitude',
          'location_string',
        ];
        const attributes = _.pick(form.attributes, pickupAttrs);
        attributes.contact_infos_attributes = contactInfos.map((contactInfo) => {
          // eslint-disable-next-line no-param-reassign
          delete contactInfo.attributes.to_sgid;
          const obj = _.assign({ id: contactInfo.id || '' }, contactInfo.attributes);
          return obj;
        });

        this.setState({ displayErrors: false });
        onSave(attributes, () => {
          this.setState({ displayErrors: true });
        });
      }
    } else {
      this.setState({ displayErrors: true });
    }
  }

  isPrimary(contactInfo) {
    const { primary } = contactInfo.attributes;
    return primary === true || primary === 'true';
  }

  renderError(errors, field) {
    const errorText = errors[field];

    if (!errorText) {
      return;
    }

    return (
      <small className="form-text d-block text-danger">
        {errorText}
      </small>
    );
  }

  render() {
    const {
      isOpening,
      isSaving,
      onCancel,
    } = this.props;
    const {
      form,
      displayErrors,
      responseError,
    } = this.state;
    const serverErrors = (responseError || {}).errors || {};
    const localErrors = displayErrors ? this.getFormErrors(form) : {};
    const errors = _.assign({}, serverErrors, localErrors);
    const contactInfos = form.contact_infos || [];
    const emailRecords = contactInfos.filter((ci) => (ci.attributes.info_type === 'email') && !ci.attributes._destroy);
    const facebookRecords = contactInfos.filter((ci) => (ci.attributes.info_type === 'facebook') && !ci.attributes._destroy);
    const linkedinRecords = contactInfos.filter((ci) => (ci.attributes.info_type === 'linkedin') && !ci.attributes._destroy);
    const phoneRecords = contactInfos.filter((ci) => (ci.attributes.info_type === 'phone') && !ci.attributes._destroy);
    const twitterRecords = contactInfos.filter((ci) => (ci.attributes.info_type === 'twitter') && !ci.attributes._destroy);
    let formActions;

    if (isSaving) {
      formActions = (
        <div className="mb-3">
          <LoadingImage height="50" />
        </div>
      );
    } else {
      const actionText = _.isEmpty(errors) ? 'Submit' : 'Error!!!';
      formActions = (
        <div className="mb-3">
          <input
            type="submit"
            className="btn btn-primary btn-lg ps-5 pe-5"
            value={actionText}
            id="submit"
          />
          <button
            type="button"
            className="btn btn-lg text-danger ms-2 shadow-none ps-5 pe-5"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      );
    }

    const emailErrors = errors.emails;
    const emailFormGroup = (
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Email address(es)</label>
        <div className="col-sm-9">
          {emailRecords.map((emailRecord, index) => (
            <div key={index} className={`d-flex flex-row ${index > 0 ? 'mt-2' : ''}`}>
              <div style={{ flex: 1 }}>
                <input
                  type="email"
                  value={emailRecord.attributes.value || ''}
                  onChange={(evt) => this.handleArrayFieldChange('email', index, evt.target.value)}
                  className="form-control"
                  id={`email_${index + 1}`}
                />
                {_.isObject(emailErrors) && this.renderError(emailErrors, `${index}`)}
                {emailRecords.length > 1 && (
                  <div className="text-end">
                    <label
                      className="form-check"
                      style={{ marginRight: '-0.75rem' }}
                      id={`email_primary_toggle_${index + 1}`}
                    >
                      <span style={{ marginRight: '2rem' }}>make default</span>
                      <input
                        type="checkbox"
                        checked={this.isPrimary(emailRecord)}
                        onChange={() => this.setPrimaryField('email', index)}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">&nbsp;</span>
                    </label>
                  </div>
                )}
              </div>
              <div className="ps-2 input-actions mt-2">
                <i
                  className={`fa-solid fa-trash-can cursor-pointer text-danger ${emailRecords.length > 1 ? '' : 'invisible'}`}
                  onClick={() => this.removeArrayField('email', index)}
                  onKeyUp={(evt) => evt.keyCode === 13 && this.removeArrayField('email', index)}
                  id={`email_trash_${index + 1}`}
                  tabIndex="-1"
                  role="button"
                  aria-label="Remove"
                />
              </div>
            </div>
          ))}
          {_.isString(emailErrors) && this.renderError(errors, 'emails')}
        </div>
        <div className="col-12 text-end">
          <button className="btn" type="button" onClick={() => this.addAnotherElement('email')}>Add another email
          </button>
        </div>
      </div>
    );

    const phoneErrors = errors.phones;
    const phoneFormGroup = (
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Phone(s)</label>
        <div className="col-sm-9">
          {phoneRecords.map((phoneRecord, index) => (
            <div key={index} className={`d-flex flex-row ${index > 0 ? 'mt-2' : ''}`}>
              <div style={{ flex: 1 }}>
                <input
                  type="tel"
                  value={phoneRecord.attributes.value || ''}
                  onChange={(evt) => this.handleArrayFieldChange('phone', index, evt.target.value)}
                  className="form-control"
                  id={`phone_${index + 1}`}
                />
                {_.isObject(phoneErrors) && this.renderError(phoneErrors, `${index}`)}
                {phoneRecords.length > 1 && (
                  <div className="text-end">
                    <label
                      className="form-check"
                      style={{ marginRight: '-0.75rem' }}
                      id={`phone_primary_toggle_${index + 1}`}
                    >
                      <span style={{ marginRight: '2rem' }}>make default</span>
                      <input
                        type="checkbox"
                        checked={this.isPrimary(phoneRecord)}
                        onChange={() => this.setPrimaryField('phone', index)}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">&nbsp;</span>
                    </label>
                  </div>
                )}
              </div>
              <div className="ps-2 input-actions mt-2">
                <i
                  className="fa-solid fa-trash-can cursor-pointer text-danger"
                  onClick={() => this.removeArrayField('phone', index)}
                  onKeyUp={(evt) => evt.keyCode === 13 && this.removeArrayField('phone', index)}
                  id={`phone_trash_${index + 1}`}
                  tabIndex="-1"
                  role="button"
                  aria-label="Remove"
                />
              </div>
            </div>
          ))}
          {_.isString(phoneErrors) && this.renderError(errors, 'phones')}
        </div>
        <div className="col-12 text-end">
          <button className="btn" type="button" onClick={() => this.addAnotherElement('phone')}>Add another phone
          </button>
        </div>
      </div>
    );

    const twitterErrors = errors.twitters;
    const twitterFormGroup = (
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Twitter(s)</label>
        <div className="col-sm-9">
          {twitterRecords.map((twitterRecord, index) => (
            <div key={index} className={`d-flex flex-row ${index > 0 ? 'mt-2' : ''}`}>
              <div style={{ flex: 1 }}>
                <input
                  type="text"
                  value={twitterRecord.attributes.value || ''}
                  onChange={(evt) => this.handleArrayFieldChange('twitter', index, evt.target.value)}
                  className="form-control"
                  id={`twitter_${index + 1}`}
                />
                {_.isObject(twitterErrors) && this.renderError(twitterErrors, `${index}`)}
                {twitterRecords.length > 1 && (
                  <div className="text-end">
                    <label
                      className="form-check"
                      style={{ marginRight: '-0.75rem' }}
                      id={`twitter_primary_toggle_${index + 1}`}
                    >
                      <span style={{ marginRight: '2rem' }}>make default</span>
                      <input
                        type="checkbox"
                        checked={this.isPrimary(twitterRecord)}
                        onChange={() => this.setPrimaryField('twitter', index)}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">&nbsp;</span>
                    </label>
                  </div>
                )}
              </div>
              <div className="ps-2 input-actions mt-2">
                <i
                  className="fa-solid fa-trash-can cursor-pointer text-danger"
                  onClick={() => this.removeArrayField('twitter', index)}
                  onKeyUp={(evt) => evt.keyCode === 13 && this.removeArrayField('twitter', index)}
                  id={`twitter_trash_${index + 1}`}
                  tabIndex="-1"
                  role="button"
                  aria-label="Remove"
                />
              </div>
            </div>
          ))}
          {_.isString(twitterErrors) && this.renderError(errors, 'twitters')}
        </div>
        <div className="col-12 text-end">
          <button type="button" className="btn" onClick={() => this.addAnotherElement('twitter')}>Add another
            twitter
          </button>
        </div>
      </div>
    );

    const facebookErrors = errors.facebooks;
    const facebookFormGroup = (
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Facebook(s)</label>
        <div className="col-sm-9">
          {facebookRecords.map((facebookRecord, index) => (
            <div key={index} className={`d-flex flex-row ${index > 0 ? 'mt-2' : ''}`}>
              <div style={{ flex: 1 }}>
                <input
                  type="text"
                  value={facebookRecord.attributes.value || ''}
                  onChange={(evt) => this.handleArrayFieldChange('facebook', index, evt.target.value)}
                  className="form-control"
                  id={`facebook_${index + 1}`}
                />
                {_.isObject(facebookErrors) && this.renderError(facebookErrors, `${index}`)}
                {facebookRecords.length > 0 && (
                  <div className="text-end">
                    <label
                      className="form-check"
                      style={{ marginRight: '-0.75rem' }}
                      id={`facebook_primary_toggle_${index + 1}`}
                    >
                      <span style={{ marginRight: '2rem' }}>make default</span>
                      <input
                        type="checkbox"
                        checked={this.isPrimary(facebookRecord)}
                        onChange={() => this.setPrimaryField('facebook', index)}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">&nbsp;</span>
                    </label>
                  </div>
                )}
              </div>
              <div className="ps-2 input-actions mt-2">
                <i
                  className="fa-solid fa-trash-can cursor-pointer text-danger"
                  onClick={() => this.removeArrayField('facebook', index)}
                  onKeyUp={(evt) => evt.keyCode === 13 && this.removeArrayField('facebook', index)}
                  tabIndex="-1"
                  role="button"
                  id={`facebook_trash_${index + 1}`}
                  aria-label="Remove"
                />
              </div>
            </div>
          ))}
          {_.isString(facebookErrors) && this.renderError(errors, 'facebooks')}
        </div>
        <div className="col-12 text-end">
          <button
            type="button"
            className="btn"
            onClick={() => this.addAnotherElement('facebook')}
            onKeyUp={(evt) => evt.keyCode === 13 && this.addAnotherElement('facebook')}
          >Add another facebook
          </button>
        </div>
      </div>
    );

    const linkedinErrors = errors.linkedins;
    const linkedinFormGroup = (
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Linkedin(s)</label>
        <div className="col-sm-9">
          {linkedinRecords.map((linkedinRecord, index) => (
            <div key={index} className={`d-flex flex-row ${index > 0 ? 'mt-2' : ''}`}>
              <div style={{ flex: 1 }}>
                <input
                  type="text"
                  value={linkedinRecord.attributes.value || ''}
                  onChange={(evt) => this.handleArrayFieldChange('linkedin', index, evt.target.value)}
                  className="form-control"
                  id={`linkedin_${index + 1}`}
                />
                {_.isObject(linkedinErrors) && this.renderError(linkedinErrors, `${index}`)}
                {linkedinRecords.length > 0 && (
                  <div className="text-end">
                    <label
                      className="form-check"
                      style={{ marginRight: '-0.75rem' }}
                      id={`linkedin_primary_toggle_${index + 1}`}
                    >
                      <span style={{ marginRight: '2rem' }}>make default</span>
                      <input
                        type="checkbox"
                        checked={this.isPrimary(linkedinRecord)}
                        onChange={() => this.setPrimaryField('linkedin', index)}
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">&nbsp;</span>
                    </label>
                  </div>
                )}
              </div>
              <div className="ps-2 input-actions mt-2">
                <i
                  className="fa-solid fa-trash-can cursor-pointer text-danger"
                  onClick={() => this.removeArrayField('linkedin', index)}
                  onKeyUp={(evt) => evt.keyCode === 13 && this.removeArrayField('linkedin', index)}
                  id={`linkedin_trash_${index + 1}`}
                  tabIndex="-1"
                  role="button"
                  aria-label="Remove"
                />
              </div>
            </div>
          ))}
          {_.isString(linkedinErrors) && this.renderError(errors, 'linkedins')}
        </div>
        <div className="col-12 text-end">
          <button
            type="button"
            className="btn"
            onClick={() => this.addAnotherElement('linkedin')}
            tabIndex="-1"
            onKeyUp={(evt) => evt.keyCode === 13 && this.addAnotherElement('linkedin')}
          >Add another linkedin
          </button>
        </div>
      </div>
    );

    return (
      <Modal isOpen={isOpening} className="reporter-modal">
        <ModalHeader toggle={() => onCancel && onCancel()}>
          <div className="h3">Edit info</div>
        </ModalHeader>
        <ModalBody>
          <form
            className=""
            onSubmit={(evt) => {
              evt.preventDefault();
              this.submit();
            }}
          >
            <div className="mb-3 row required">
              <label className="col-sm-3 col-form-label">Full name</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value={form.attributes.full_name || ''}
                  required
                  onChange={(evt) => this.handleInputChange('full_name', evt.target.value)}
                  placeholder=""
                  id="full_name"
                />
                {this.renderError(errors, 'full_name')}
              </div>
            </div>

            <div className="mb-3 row">
              <label className="col-sm-3 col-form-label">Location</label>
              <div className="col-sm-9">
                <AutocompletePlace
                  className="form-control"
                  defaultValue={form.attributes.location_string}
                  onChange={this.storeLocation}
                />
                {this.renderError(errors, 'location')}
              </div>
            </div>

            {emailFormGroup}
            {phoneFormGroup}
            {twitterFormGroup}
            {facebookFormGroup}
            {linkedinFormGroup}

            <div className="mb-3 row">
              <label className="col-sm-3 col-form-label">Hide from search engines</label>
              <div className="col-sm-9">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={(form.attributes.hide_from_search_engines === true || form.attributes.hide_from_search_engines === false) ? form.attributes.hide_from_search_engines : form.attributes.hide_from_search_engines === 'true'}
                  onChange={(evt) => this.handleInputChange('hide_from_search_engines', evt.target.checked)}
                  id="hide_from_search_engines"
                />
                &nbsp;
                Only show this profile to logged in users (this will also hide it from search engines such as Google.)
              </div>
            </div>

            {formActions}
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

ReporterModal.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  reporter: PropTypes.instanceOf(Object).isRequired,
  isOpening: PropTypes.bool,
  isSaving: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  responseError: PropTypes.instanceOf(Object),
};

ReporterModal.defaultProps = {
  isOpening: undefined,
  isSaving: undefined,
  responseError: undefined,
};

export default ReporterModal;
