import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

function GenderIdentitySelect({
                                modelObject,
                                existingGender,
                                existingGenderSelfDesc,
                                hasError,
                                errorClass,
                                errorText,
                              }) {
  const [selectValue, setSelectValue] = useState(existingGender);
  const [selfDescription, setSelfDescription] = useState(existingGenderSelfDesc);
  const [helpPopoverOpen, setHelpPopoverOpen] = useState(false);

  const handleSelectChange = (e) => {
    setSelectValue(e.target.value);
    if (e.target.value !== 'sd') {
      setSelfDescription(null);
    }
  };

  const handleSelfDescriptionChange = (e) => {
    setSelfDescription(e.target.value);
  };

  const helpToggle = () => {
    setHelpPopoverOpen(!helpPopoverOpen);
  };

  return (
    <div className={`row mb-1 ${hasError ? errorClass : ''}`}>
      <label
        className="col-form-label col-md-2"
        htmlFor="source_location"
      >
        Gender identity
        <span>
          <button id="help-popover" type="button" className="btn btn-link">
            <i className="fa-solid fa-circle-question" />
          </button>

          <Popover
            placement="right"
            isOpen={helpPopoverOpen}
            target="help-popover"
            trigger="legacy"
            toggle={helpToggle}
          >
            <PopoverHeader>Why do we ask?</PopoverHeader>
            <PopoverBody>
              <p>
                Reporters frequently seek sources with diverse voices. Providing optional information
                like gender identity makes it easier for them to find diverse experts.
              </p>
            </PopoverBody>
          </Popover>
        </span>
      </label>
      <div className="col-md-10">
        <div>
          <div className="row">
            <div className="col-3 pe-0">
              <select
                className="form-control"
                name={`${modelObject}[gender]`}
                id={`${modelObject}_gender`}
                value={selectValue}
                onChange={handleSelectChange}
              >
                <option value="">&nbsp;</option>
                <option value="f">Female</option>
                <option value="m">Male</option>
                <option value="nb">Non-binary</option>
                <option value="sd">Prefer to self-describe</option>
              </select>
            </div>
            <div className="col-7">
              {selectValue === 'sd' && (
                <input
                  type="text"
                  className="form-control"
                  name={`${modelObject}[gender_self_desc]`}
                  id={`${modelObject}_gender_self_desc`}
                  value={selfDescription}
                  onChange={handleSelfDescriptionChange}
                  placeholder="Please specify..."
                  required
                />
              )}
              {selectValue !== 'sd' && (
                <input
                  type="hidden"
                  name={`${modelObject}[gender_self_desc]`}
                  id={`${modelObject}_gender_self_desc`}
                  value={selfDescription}
                />
              )}
              {(hasError && errorText) && (
                <div className="form-text text-danger">
                  {errorText}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

GenderIdentitySelect.propTypes = {
  modelObject: PropTypes.string.isRequired,
  existingGender: PropTypes.string,
  existingGenderSelfDesc: PropTypes.string,
  hasError: PropTypes.bool,
  errorClass: PropTypes.string,
  errorText: PropTypes.string,
};

GenderIdentitySelect.defaultProps = {
  existingGender: undefined,
  existingGenderSelfDesc: undefined,
  hasError: false,
  errorClass: 'text-danger',
  errorText: undefined,
};

export default GenderIdentitySelect;
