import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import ClearRefinementsButton from '../../algolia_search/clear_refinements_button';
import InfiniteHits from './infinite_hits';
import SmartStats from '../../algolia_search/smart_stats';
import TabbedNavigation from '../../tabbed_navigation';

function MediaMatchesIndex({
  query,
  hiddenSearchString,
  algoliaAppId,
  algoliaSearchKey,
  indexName,
  canPitchMediaMatch,
}) {
  const [currentQuery, setCurrentQuery] = useState(query || hiddenSearchString);
  const [defaultSearchString, setDefaultSearchString] = useState(query || hiddenSearchString);
  const activeTab = 'MEDIAMATCHER';
  const myMatches = (hiddenSearchString) && (hiddenSearchString.length > 2) && (hiddenSearchString === currentQuery);

  const handleSearchFocus = (e) => {
    setDefaultSearchString('');
    e.currentTarget.value = null;
    setCurrentQuery(null);
  };

  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);
  const tabs = [
    {
 id: 'ALL', display: 'All Opportunities', href: '/source_requests?view=all', icon: 'globe',
},
    {
 id: 'MEDIAMATCHER', display: 'Media Matcher', href: '/media_matches', icon: 'users',
},
    {
 id: 'FREE', display: 'Free to Pitch', href: '/source_requests?view=free', icon: 'bolt',
},
    {
 id: 'SAVED', display: 'Saved', href: '/source_requests?view=saved', icon: 'bookmark',
},
    {
 id: 'PITCHED', display: 'Pitched', href: '/source_requests?view=pitched', icon: 'messages',
},
    {
 id: 'SEARCH', display: 'Advanced Search', href: '/source_requests/search', icon: 'magnifying-glass',
},
  ];
  return (
    <div className="ais-InstantSearch">
      <style>
        {`
          .ais-SearchBox-input { color: ${myMatches ? '#fff' : 'black'}; }
         `}
        </style>
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <Configure
          filters="media_match_phase:true"
          distinct={true}
        />
        <div className="d-flex flex-wrap pt-3 border-top border-bottom">
          <div className="flex-fill flex-shrink-0 flex-grow-0 mb-3 me-5">
            <TabbedNavigation tabs={tabs} activeTab={activeTab} />
          </div>
          <div className="flex-fill flex-column flex-sm-row d-flex">
            <div className="mb-3 ps-2 flex-fill">
              <div className="d-flex flex-row">
                <SearchBox
                  translations={{
                    placeholder: 'Search',
                  }}
                  searchAsYouType={true}
                  defaultRefinement={defaultSearchString}
                  onChange={(e) => setCurrentQuery(e.currentTarget.value)}
                  className="me-3 flex-grow-1"
                  onReset={() => setCurrentQuery(null)}
                  onFocus={handleSearchFocus}
                  onClick={handleSearchFocus}
                />
                <ClearRefinementsButton
                  onClear={() => setCurrentQuery(null)}
                  buttonText="Clear"
                  clearsQuery={true}
                  bootstrapClasses="px-3 btn btn-sm btn-outline-info text-nowrap"
                />
              </div>
            </div>
          </div>
        </div>
          {myMatches && (
            <div className="p-4 mb-4 position-relative overflow-hidden" style={{ backgroundColor: '#f1effe' }}>
              <h2 className="fw-bold text-xl mb-2" style={{ color: '#463991', fontSize: '24px' }}>You have matches!</h2>
              <p className="text-gray-600" style={{ fontSize: '16px' }}>Connect with media users who write about topics that match your expertise</p>

              <svg
                style={{
                  position: 'absolute',
                  right: '-20px',
                  bottom: '-20px',
                  width: '300px',
                  height: '200px',
                  pointerEvents: 'none',
                }}
              >
                <circle
                  cx="250"
                  cy="150"
                  r="100"
                  fill="rgba(124, 58, 237, 0.1)"
                />
                <circle
                  cx="200"
                  cy="180"
                  r="80"
                  fill="rgba(124, 58, 237, 0.15)"
                />
              </svg>
            </div>
            )}
          <div className="col pe-4 text-end flex">
            <SmartStats />
          </div>
        <div className="row">
          <div className={`col col-12 ${(query) ? 'col-md-9' : 'col-md-12'}`}>
            <>
              {currentQuery && currentQuery.length > 2 && (
                <InfiniteHits
                  query={currentQuery}
                  algoliaAppId={algoliaAppId}
                  algoliaSearchKey={algoliaSearchKey}
                  hiddenSearchString={hiddenSearchString}
                  canPitchMediaMatch={canPitchMediaMatch}
                />
              )}
              { (!currentQuery || (currentQuery.length < 3)) && (
                <div className="d-flex flex-column align-items-center text-center">
                 <div className="mb-4">
                   <i className="p-3 rounded bg-white fa fa-magnifying-glass fa-2x" />
                 </div>

                 <h2 className="mb-3 fw-bold text-dark">No matches yet !</h2>

                 <p className="text-muted mb-4">
                  <>
                  Tell us what you’re looking for
                  <br />
                  and we’ll find the best connections for you.
                  </>
                 </p>
                </div>
              )}
            </>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

MediaMatchesIndex.propTypes = {
  query: PropTypes.string,
  hiddenSearchString: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  canPitchMediaMatch: PropTypes.bool,
};

MediaMatchesIndex.defaultProps = {
  query: '',
  hiddenSearchString: '',
  canPitchMediaMatch: true,
};

export default MediaMatchesIndex;
