import React from 'react';
import PropTypes from 'prop-types';
import useFavorite from '../../hooks/use_favorite';

function SaveButton({
 favoriteableId, favoriteableType, favoriteId, favorited, ahoyObjectGid, handleSaveProduct,
}) {
  const { isFavorited, toggleFavoritedStatus } = useFavorite({
    favoriteableId,
    favoriteableType,
    initiallyFavorited: favorited,
    initialFavoriteId: favoriteId,
    ahoyObjectGid,
    handleSaveProduct,
  });

  const bookmarkStyleClass = isFavorited ? 'fa-solid' : 'fa-regular';
  const buttonColor = isFavorited ? 'danger' : 'outline-primary';
  const buttonText = isFavorited ? 'Saved' : 'Save';

  // The space between the icon and text is important because it creates the gap between the icon and text
  // when they are next to each other, but ensures the icon is not offset when they are stacked vertically
  // at certain screen sizes.
  const horizontalSpacer = ' ';

  return (
    <button
      type="button"
      className={`me-2 btn btn-sm qwoted-btn-xs btn-${buttonColor}`}
      onClick={toggleFavoritedStatus}
      title="Save"
    >
      <i className={`${bookmarkStyleClass} fa-bookmark align-middle`} title={buttonText} />
      {horizontalSpacer}
      <span className="align-middle d-none">{buttonText}</span>
    </button>
  );
}

SaveButton.propTypes = {
  favoriteableId: PropTypes.number.isRequired, // Id of favoriting target
  favoriteableType: PropTypes.string.isRequired, // Object type of favoriting target (ex: 'PrUser')
  favoriteId: PropTypes.number, // Primary key of favorite record in the favorites table
  favorited: PropTypes.bool.isRequired, // Whether or not the target is currently favorited
  ahoyObjectGid: PropTypes.string.isRequired, // Global id for user being favorited,
  handleSaveProduct: PropTypes.func, // Function from parent to perform additional actions after save
};

SaveButton.defaultProps = {
  favoriteId: undefined,
  handleSaveProduct: () => {},
};

export default SaveButton;
