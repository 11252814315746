import React from 'react';
import Skeleton from 'react-loading-skeleton';
import CompanyHeaderSkeleton from './company_header/company_header_skeleton';
import SideBarSkeleton from './sidebar/sidebar_skeleton';
import CompanyExpertsSkeletonPage from './company_experts_skeleton_page';

function ShowPageSkeleton() {
  return (
    <div className="row">
      <div className="col mb-3">
        <CompanyHeaderSkeleton />
        <div className="mt-4">
          <div className="mt-4 container-fluid">
            <div className="row mt-4">
              <div className="col pb-3 animated fadeIn faster">
                <Skeleton height={18} width="15%" />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col px-0 pt-2">
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3">
                  <CompanyExpertsSkeletonPage numberOfCards={6} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SideBarSkeleton />
    </div>
  );
}

export default ShowPageSkeleton;
