import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../javascript/frontend/api/vapi';

function MoreJobPostings({
                          jobPostingId,
                        }) {
  const [asyncError, setAsyncError] = useState();
  const [jobPostings, setJobPostings] = useState([]);

  useEffect(() => {
    const getMoreJobPostings = async () => {
      try {
        vapi.getMoreJobPostings(jobPostingId)
          .then((response) => {
            const result = response.data;
            setJobPostings(result.more_job_postings);
          });
      } catch (error) {
        setAsyncError(error);
      }
    };
    getMoreJobPostings();
  }, [jobPostingId]);

  if (asyncError) throw asyncError;

  return (
    <>
      {jobPostings.map((jobPosting) => (
        <React.Fragment key={jobPosting.id}>
          <div className="card">
            <div className="card-header d-flex flex-row p-0 fs-4">
              <div className={`rounded-top rounded-0 text-light ps-3 pe-5 lh-lg ${jobPosting.header_class}`} style={{ borderTopRightRadius: '0 !important' }}>{jobPosting.header_title}</div>
              <div className="ps-2 lh-lg">{jobPosting.location_string}</div>
              <div className="flex-shrink-1 ms-auto pe-2 lh-lg">{jobPosting.created_at}</div>
            </div>
            <div className="card-body d-flex align-items-center">
              {jobPosting.image_cloudinary_url && (
                <img src={jobPosting.image_cloudinary_url} alt={jobPosting.title} style={{ maxHeight: '80px', maxWidth: '120px' }} />
              )}
              <a className="fs-3 text-decoration-underline ms-3" href={`/job_postings/${jobPosting.slug}`}>{jobPosting.title}</a>
              <a className="btn btn-primary ms-auto px-3 text-uppercase" href={jobPosting.url}>Apply</a>
            </div>
          </div>
          <div className="mb-3" />
        </React.Fragment>
      ))}
    </>
  );
}

MoreJobPostings.propTypes = {
  jobPostingId: PropTypes.number.isRequired,
};

export default MoreJobPostings;
