import React from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../../javascript/vahoy';

function ShareButtons({
 shareText, shareUrl, requestUrl,
}) {
  const mailSubject = encodeURIComponent(shareText);
  const mailBody = encodeURIComponent(`${shareText} `) + encodeURIComponent(shareUrl);
  const encodedShareText = encodeURIComponent(`${shareText} `);
  const twitterText = encodedShareText.replace('Qwoted', '@Qwoted');

  function trackSocialShare(event) {
    let platform;

    if (event.currentTarget.href.includes('mailto:')) {
      platform = 'email';
    } else if (event.currentTarget.href.includes('linkedin.com')) {
      platform = 'linkedin';
    } else if (event.currentTarget.href.includes('twitter.com')) {
      platform = 'twitter';
    }

    vahoy.track('SocialShare', {
      url: shareUrl,
      platform,
      request_url: requestUrl,
    });
  }

  return (
    <div className="share-buttons">
      <span className="text-secondary font-size-14px" style={{ marginRight: '10px' }}>Share</span>
      <a href={`mailto:?subject=${mailSubject}&body=${mailBody}`} onClick={trackSocialShare} target="_blank" rel="noreferrer">
        <i className="fa-solid fa-envelope" style={{ marginRight: '10px' }} />
      </a>
      <a href={`https://www.linkedin.com/feed/?shareActive=true&text=${encodedShareText}${shareUrl}`} onClick={trackSocialShare} target="_blank" rel="noreferrer">
        <i className="fa-brands fa-linkedin" style={{ marginRight: '10px' }} />
      </a>
      <a href={`https://twitter.com/intent/tweet?text=${twitterText}&url=${shareUrl}`} onClick={trackSocialShare} target="_blank" rel="noreferrer">
        <i className="fa-brands fa-x-twitter" />
      </a>
    </div>
  );
}

ShareButtons.propTypes = {
  shareText: PropTypes.string.isRequired,
  shareUrl: PropTypes.string.isRequired,
  requestUrl: PropTypes.string.isRequired,
};

export default ShareButtons;
