import React from 'react';
import { Panel, ToggleRefinement } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

function PublicationFilterByTierPanel({ customClass }) {
  return (
    <div className={`card bg-transparent border-0 mb-3 ${customClass}`}>
      <div className="card-body ais-hide-counts">
        <Panel header="Appeared in Publication (Tier)">
          <ToggleRefinement
            attribute="publications.tier_1_publication"
            label="Tier 1"
            value={true}
            defaultRefinement={false}
          />
          <ToggleRefinement
            attribute="publications.tier_2_publication"
            label="Tier 2"
            value={true}
            defaultRefinement={false}
          />
        </Panel>
      </div>
    </div>
  );
}

PublicationFilterByTierPanel.propTypes = {
  customClass: PropTypes.instanceOf(Object),
};

PublicationFilterByTierPanel.defaultProps = {
  customClass: undefined,
};

export default PublicationFilterByTierPanel;
