import React from 'react';
import promoGraphic from '../../../../../assets/images/Graph-media-map.png';

function InsightsPromoCard() {
  return (
    <div className="card w-full rounded p-3 ms-2 pb-2 bg-white text-center h-100">
      <img
        src={promoGraphic}
        alt="Insights Promo"
        className="img-fluid mb-1"
        style={{ marginLeft: '7.5%' }}
        width="85%"
      />
      <h6 className="fw-bold mb-1 pb-1">Introducing Industry Insights</h6>
      <p className="mb-0">
        Click the graphs on the right to see trending topics, publications,
        <br />  and media.
      </p>
    </div>
  );
}

export default InsightsPromoCard;
