import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ConnectedRefinementsHorizontalBarChart from '../shared_charts/refinements_horizontal_bar_chart';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

function InsightCards({
 query, expandedChart, onExpand, onCollapse,
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleExpand = (type) => {
    if (expandedChart === type) {
      onCollapse();
    } else {
      onExpand(type);
    }
  };

  useEffect(() => {
    if (!expandedChart) {
      setIsCollapsed(false);
    }
  }, [expandedChart]);

  return (
    <div style={{ border: '1px solid #d5d5d5', marginLeft: '0px', marginRight: '0px' }} className="bg-white p-2 rounded-bottom row row-cols-1 row-cols-sm-1 mb-2">
      <style>
        {`
          .topics:hover{
            cursor: pointer;
            background: #f7ebe9 !important;

          }
          .publications:hover{
            cursor: pointer;
            background: #fcf7ff !important;
          }

          .media:hover{
            cursor: pointer;
            background: #f0f5e6 !important;
          }
          .slide-in-top {
            animation: slide-in-top 0.4s ease-in-out forwards;
          }

          @keyframes slide-in-top {
            0% {
              opacity: 0;
              transform: translateY(-20px);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }

          .slide-out-top {
            animation: slide-out-top 0.4s ease-in-out forwards;
          }

          @keyframes slide-out-top {
            0% {
              opacity: 1;
              transform: translateY(0);
            }
            100% {
              opacity: 0;
              transform: translateY(-20px);
            }
          }
        `}
      </style>
      <div className="col col-12 m-0 border-bottom p-2 mb-2 fs-4">
        <h3 className="text-primary fw-bold fs-6 py-0 my-0 ms-2">
          <i className="mx-2 fa fa-chart-bar" />
          MEDIA INSIGHTS
          { (!query || query === '') && (
             <span className="p-1 ps-4 border-start fs-8 ms-4 text-dark border-dark"> See trending topics, publications and media here as you search.</span>
          )}
          { (query && query !== '') && !isCollapsed && (
             <span className="p-1 ps-4 border-start fs-8 ms-4 text-dark border-dark"> Click any section below to get a closer look.</span>
          )}
          { ((query && query !== '') && isCollapsed) && (
             <span className="p-1 ps-4 border-start fs-8 ms-4 text-dark border-dark"> Expand to see trending topics, publications and media on Qwoted.</span>
          )}
          { ((query && query !== '') || isCollapsed) && (
             <button
               className="float-end btn btn-link btn-sm text-decoration-none p-0 m-0 mb-2"
               onClick={toggleCollapse}
               type="button"
             >
               <i style={{ backgroundColor: '#f5f5f5', fontSize: '12px' }} className={`p-1 fa-solid ${isCollapsed ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center'} mt-0`} />
               <span className="ms-2">{isCollapsed ? 'Expand' : 'Collapse'}</span>
             </button>
          )}
        </h3>
      </div>

      <div className={`row ${isCollapsed ? 'slide-out-top' : 'slide-in-top'}`}>
        { query && query !== '' && (
          <>
            {!isCollapsed && (
              <>
                <div
                  className="col col-md-4 border-end px-2 pb-0 pt-0 publications rounded"
                  onClick={() => toggleExpand('publications')}
                  style={{
                      background: expandedChart === 'publications' ? '#fcf7ff' : '#fff',
                    }}
                >
                  <div className="w-full mx-0 px-2 pb-0">
                    <h4 className="fw-bold fs-6 py-0 my-0">
                      TOP PUBLICATIONS
                      <button
                        className="pt-0 btn btn-link btn-sm text-decoration-none"
                        onClick={() => toggleExpand('publications')}
                        type="button"
                      >
                        <i style={{ backgroundColor: '#f5f5f5', fontSize: '10px' }} className={`p-1 fa-solid ${expandedChart === 'publications' ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center'} mt-0`} />
                        <span className="ms-2">{expandedChart === 'publications' ? 'Collapse' : 'View all'}</span>
                      </button>
                    </h4>
                    <p className="mb-0 text-primary" style={{ minHeight: '30px', fontStyle: 'italic', fontSize: '14px' }}>Which publications write about this topic the most</p>
                    <ConnectedRefinementsHorizontalBarChart
                      attribute="publication.name"
                      query={query}
                      limit={6}
                      labelFontSize={15}
                      enablePopoverTooltip={true}
                      color="purple"
                    />
                  </div>
                </div>

                <div
                  className="col col-md-4 px-2 pb-0 pt-0 border-end media rounded"
                  onClick={() => toggleExpand('media')}
                  style={{
                      background: expandedChart === 'media' ? '#f0f5e6' : '#fff',
                    }}
                >
                  <div className="w-full mx-0 px-2 pb-0">
                    <h4 className="fw-bold fs-6 py-0 my-0">
                      RELEVANT MEDIA
                      <button
                        className="pt-0 btn btn-link btn-sm text-decoration-none"
                        onClick={() => toggleExpand('media')}
                        type="button"
                      >
                        <i style={{ backgroundColor: '#f5f5f5', fontSize: '10px' }} className={`p-1 fa-solid ${expandedChart === 'media' ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center'} mt-0`} />
                        <span className="ms-2">{expandedChart === 'media' ? 'Collapse' : 'View all'}</span>
                      </button>
                    </h4>
                    <p className="mb-0 text-primary" style={{ minHeight: '30px', fontStyle: 'italic', fontSize: '14px' }}>Which writers/reports ask the most questions about this topic</p>
                    <ConnectedRefinementsHorizontalBarChart
                      attribute="reporter.name"
                      query={query}
                      limit={6}
                      labelFontSize={15}
                      enablePopoverTooltip={true}
                      color="green"
                    />
                  </div>
                </div>
                <div
                  className="col col-md-4 px-2 pb-0 pt-0 topics rounded"
                  onClick={() => toggleExpand('topics')}
                  style={{
                      background: expandedChart === 'topics' ? '#f7ebe9' : '#fff',
                    }}
                >
                  <div className="w-full mx-0 px-2 pb-0">
                    <h4 className="fw-bold fs-6 py-0 my-0">
                      ASSOCIATED TOPICS
                      <button
                        className="pt-0 btn btn-link btn-sm text-decoration-none"
                        onClick={() => toggleExpand('topics')}
                        type="button"
                      >
                        <i style={{ backgroundColor: '#f5f5f5', fontSize: '10px' }} className={`p-1 fa-solid ${expandedChart === 'topics' ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center'} mt-0`} />
                        <span className="ms-2">{expandedChart === 'topics' ? 'Collapse' : 'View all'}</span>
                      </button>
                    </h4>
                    <p className="mb-0 text-primary" style={{ minHeight: '30px', fontStyle: 'italic', fontSize: '14px' }}>Which terms do media most use alongside your searched word</p>
                    <ConnectedRefinementsHorizontalBarChart
                      attribute="hashtags.hashtag"
                      query={query}
                      limit={6}
                      labelFontSize={15}
                      enablePopoverTooltip={true}
                      color="red"
                    />
                  </div>
                </div>

              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

InsightCards.propTypes = {
  query: PropTypes.string,
  expandedChart: PropTypes.string,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
};

InsightCards.defaultProps = {
  query: '',
  expandedChart: null,
};

export default InsightCards;
