import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

function SectionPaid({
                               paid,
                               requestType,
                               setPaid,
                             }) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (requestType === 'story_ideas') {
    return null;
  }

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const handleChange = (e) => {
    const value = e.target.checked;
    setPaid(value);
  };

  return (
    <div className="mb-3">
      <label className="form-check ps-0" style={{ display: 'inline' }}>
        <input
          id="paid"
          type="checkbox"
          className="me-2"
          defaultChecked={paid}
          onInput={(e) => handleChange(e)}
        />
        <span htmlFor="paid">
          This is a fee-based opportunity
        </span>
        <small>
          <button
            id="paidPopover"
            type="button"
            className="btn btn-link ps-0 pt-0 ms-1"
          >
            <i className="fa-solid fa-circle-question" />
          </button>

          <Popover
            placement="auto"
            isOpen={popoverOpen}
            target="paidPopover"
            toggle={togglePopover}
          >
            <PopoverHeader>What does this mean?</PopoverHeader>
            <PopoverBody>
              Fee-based opportunities must be marked as such.
              Failure to do so when posting a fee-based request may result in account disablement.
            </PopoverBody>
          </Popover>
        </small>
      </label>
    </div>
  );
}

SectionPaid.propTypes = {
  paid: PropTypes.bool,
  requestType: PropTypes.string,
  setPaid: PropTypes.func,
};

SectionPaid.defaultProps = {
  paid: undefined,
  requestType: undefined,
  setPaid: undefined,
};

export default SectionPaid;
