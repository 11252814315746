import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import {
  ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, gql, useMutation, useLazyQuery,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { Spinner } from 'reactstrap';
import api from '../../javascript/frontend/api/vapi';
import ErrorBoundary from '../error_boundary';

import FieldDetails from './form/field_details';
import FieldName from './form/field_name';
import RequestTypeSelect from './form/select_request_type';
import SectionAdmin from './form/section_admin';
import SectionDistribution from './form/section_distribution';
import SectionLogistics from './form/section_logistics';
import SectionSubtype from './form/section_subtype';
import SectionPaid from './form/section_paid';
import SelectPublication from './form/select_publication';
import SelectSubmitDate from './form/select_submit_date';
import ArticleFields from './form/article_fields';

const GET_REQUEST = gql`
  query getRequest($id: ID!) {
    sourceRequest(id: $id) {
      details
      paid
      dontPromoteOutside
      equipmentNotes
      id
      links
      logisticsDetails
      name
      publication {
        id
        name
      }
      publishedAt
      articleUrl
      sourceRequestSubType
      sourceRequestSubmitDate
      sourceRequestType
      hideQwotedBrandFromReporter
      paid
    }
  }
`;

const CREATE_REQUEST = gql`
  mutation createRequest($request: SourceRequestInputType!) {
    createRequest(request: $request) {
      createdByUser {
        slug
      }
      id
      publishedAt
      slug
    }
  }
`;

const UPDATE_REQUEST = gql`
  mutation updateRequest($request: SourceRequestInputType!) {
    updateRequest(request: $request) {
      createdByUser {
        slug
      }
      id
      publishedAt
      slug
    }
  }
`;

// This allows us to pass up the CSRF token, which from research, we do need.
// See: https://github.com/rmosolgo/graphql-ruby/pull/2524
// https://www.apollographql.com/docs/react/networking/authentication/
const httpLink = createHttpLink({
  uri: '/api_graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = api.getCSRFToken();
  return {
    headers: {
      ...headers, 'X-CSRF-Token': token,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink), cache: new InMemoryCache(), // caching allows the client to serve data that hasn't changed without doing another fetch
});

function Requests({
                    backPath, createdByUserId, createdByAdmin, newDetails, newName, newType, requestId, canShareWork,
                  }) {
  const { handleSubmit } = useForm();
  const formRef = React.createRef();

  const [isOpen, setIsOpen] = useState(true);
  const [autosaved, setAutosaved] = useState(false);
  const [formErrorText, setFormErrorText] = useState('');

  const [details, setDetails] = useState(newDetails);
  const [dontPromoteOutside, setDontPromoteOutside] = useState();
  const [equipmentNotes, setEquipmentNotes] = useState();
  const [links, setLinks] = useState(['']);
  const [logisticsDetails, setLogisticsDetails] = useState([]);
  const [name, setName] = useState(newName);
  const [articleUrl, setArticleUrl] = useState();
  const [publicationId, setPublicationId] = useState();
  const [publicationName, setPublicationName] = useState();
  const [publishedAt, setPublishedAt] = useState();
  const [sourceRequestSubType, setSourceRequestSubType] = useState();
  const [localRequestId, setLocalRequestId] = useState(requestId);
  const [sourceRequestType, setSourceRequestType] = useState(newType || 'experts');
  const [sourceRequestSubmitDate, setSourceRequestSubmitDate] = useState();
  const [sourceRequestSubmitDateErrorText, setSourceRequestSubmitDateErrorText] = useState('');
  const [sourceRequestSubTypeErrorText, setSourceRequestSubTypeErrorText] = useState('');
  const [hideQwotedBrandFromReporter, setHideQwotedBrandFromReporter] = useState();
  const [paid, setPaid] = useState();

  const handleLogistics = (open, resetSelectedOption) => {
    setIsOpen(open);
    if (resetSelectedOption) {
      setLogisticsDetails([]);
    }
  };

  const parseServerObject = (request) => {
    const r = request;

    // This needs to come first, because for each setter called here, the autosave effect will be
    // triggered, and the behavior of the form changes profoundly (it no longer autosaves) once the
    // request has been published.
    if (r.publishedAt) {
      setPublishedAt(r.publishedAt);
    }

    if (r.details) {
      setDetails(r.details);
    }

    if (r.dontPromoteOutside) {
      setDontPromoteOutside(r.dontPromoteOutside);
    }

    if (r.equipmentNotes) {
      setEquipmentNotes(r.equipmentNotes);
    }

    if (r.links) {
      setLinks(r.links);
    }

    if (r.logisticsDetails) {
      setLogisticsDetails(r.logisticsDetails);
    }

    if (r.name) {
      setName(r.name);
    }

    if (r.articleUrl) {
      setArticleUrl(r.articleUrl);
    }

    if (r.publication?.id) {
      setPublicationId(r.publication?.id);
    }

    if (r.publication?.name) {
      setPublicationName(r.publication?.name);
    }

    if (r.sourceRequestSubType) {
      setSourceRequestSubType(r.sourceRequestSubType);
    }

    if (r.sourceRequestSubmitDate) {
      setSourceRequestSubmitDate(new Date(r.sourceRequestSubmitDate));
    }

    if (r.sourceRequestType) {
      setSourceRequestType(r.sourceRequestType);
    }

    if (r.hideQwotedBrandFromReporter) {
      setHideQwotedBrandFromReporter(r.hideQwotedBrandFromReporter);
    }

    if (r.paid) {
      setPaid(r.paid);
    }
  };

  const handleServerError = (error) => {
    let messageJson;
    let alertErrorString = false;

    setSourceRequestSubmitDateErrorText('');
    setSourceRequestSubTypeErrorText('');

    if (error.message) {
      messageJson = error.message;
    } else {
      messageJson = JSON.stringify(error);
    }
    const errorString = messageJson;

    // This is a hacky way to handle, but this is only field which is likely to have server side validation errors.
    // They will occur if the submit date is set near to the current moment, and then it sits open for a while.
    const submitDateErrorText = 'Source request submit date';
    if (errorString.includes(submitDateErrorText)) {
      setSourceRequestSubmitDateErrorText("Source request submit date can't be blank");
      setFormErrorText(errorString);
    } else {
      alertErrorString = true;
    }

    const subTypeErrorText = 'Source request sub type';
    if (errorString.includes(subTypeErrorText)) {
      setSourceRequestSubTypeErrorText('Source request sub type must be selected');
      setFormErrorText(errorString);
    } else {
      alertErrorString = true;
    }

    if (alertErrorString) {
      alert(errorString);
      // This will feed into Rollbar to hopefully get our attention
      throw new Error(`reporter_requests/form#handleServerError -- unhandled error: ${errorString}`);
    }
  };

  const redirectAfterSaving = (savedRequestAttrs) => {
    const attrs = savedRequestAttrs;
    window.location.replace(`/users/${attrs.createdByUser.slug}/reporter_requests/${attrs.slug}/thanks`);
  };

  const onExit = () => {
    const bp = backPath || `/users/${createdByUserId}/reporter_requests`;
    window.location.replace(bp);
  };

  // TODO: loading state
  // eslint-disable-next-line no-unused-vars
  const [getRequest, { loading: getRequestLoading }] = useLazyQuery(GET_REQUEST, {
    fetchPolicy: 'no-cache',
    variables: { id: localRequestId },
    client,
    onCompleted: (data) => {
      parseServerObject(data.sourceRequest);
    },
  });

  useEffect(() => {
    if (localRequestId) {
      getRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [createRequest, { loading: createRequestLoading }] = useMutation(CREATE_REQUEST, {
    client,
    onCompleted: ({ createRequest: created }) => {
      redirectAfterSaving(created);
    },
    onError: (error) => {
      handleServerError(error);
    },
  });

  const [updateRequest, { loading: updateRequestLoading }] = useMutation(UPDATE_REQUEST, {
    client,
    onCompleted: ({ updateRequest: updated }) => {
      redirectAfterSaving(updated);
    },
    onError: (error) => {
      handleServerError(error);
    },
  });

  const [autosaveCreateRequest, { loading: autosavingCreate }] = useMutation(CREATE_REQUEST, {
    client,
    onCompleted: ({ createRequest: created }) => {
      setLocalRequestId(created.id);
      parseServerObject(created);
      setAutosaved(true);
    },
  });

  const [autosaveUpdateRequest, { loading: autosavingUpdate }] = useMutation(UPDATE_REQUEST, {
    client,
    onCompleted: ({ updateRequest: updated }) => {
      parseServerObject(updated);
      setAutosaved(true);
    },
  });

  const serializeRequest = () => {
    // Remove empty strings from links
    const sanitizeLinks = links.filter((link) => link);
    const requestTypesWithoutLogistics = ['story_ideas'];

    const payload = {
      createdByUserId,
      details,
      dontPromoteOutside,
      equipmentNotes,
      links: sanitizeLinks,
      logisticsDetails,
      name,
      articleUrl,
      publicationId,
      publicationName,
      sourceRequestSubType,
      sourceRequestSubmitDate,
      sourceRequestType,
      hideQwotedBrandFromReporter,
      paid,
    };

    if (requestTypesWithoutLogistics.includes(sourceRequestType)) {
      delete payload.logisticsDetails;
      delete payload.links;
      delete payload.equipmentNotes;
      delete payload.sourceRequestSubType;
    }

    return payload;
  };

  const onSubmit = (data, e) => {
    e.preventDefault();

    const hash = serializeRequest();

    // for now whenever the Publish button is clicked the Request is "Re-published," meaning that its published_at
    // is bumped to the present, meaning it jumps back up in the request ordering and it's redistributed via alerts
    hash.publishedAt = new Date().toISOString();

    if (localRequestId) {
      hash.id = localRequestId;
      updateRequest({ variables: { request: hash } });
    } else {
      createRequest({ variables: { request: hash } });
    }
  };

  const autosave = () => {
    // This is to avoid synchronously calling createRequest too many times -- creating duplicate requests
    if (sourceRequestType === 'exposure') {
      return;
    }
    if (createRequestLoading || autosavingCreate || updateRequestLoading || autosavingUpdate) {
      return;
    }
    const hash = serializeRequest();
    if (!autosaved && (!hash.name || hash.name?.length < 10)) {
      return;
    }
    if (publishedAt) {
      return;
    }
    // Note we do not modify the publishedAt, which means this does not publish, or republish
    if (localRequestId) {
      hash.id = localRequestId;
      autosaveUpdateRequest({ variables: { request: hash } });
    } else {
      autosaveCreateRequest({ variables: { request: hash } });
    }
  };

  useEffect(
    () => {
      autosave();
    }, // Note no autosave onChange for equipmentNotes, links, name, & details: those are autosaved onBlur
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dontPromoteOutside, logisticsDetails, publicationId, publicationName, sourceRequestSubType,
      sourceRequestType, sourceRequestSubmitDate, hideQwotedBrandFromReporter, paid],
  );

  useEffect(() => {
    const autosaveTimer = setInterval(() => {
      autosave();
    }, 10000);
    return () => clearInterval(autosaveTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAutosaving = () => {
    if (autosavingCreate || autosavingUpdate) {
      return (<div className="text-muted fst-italic px-0 py-1">Autosaving</div>);
    }
    if (autosaved) {
      return (
        <>
          <span className="text-muted fst-italic px-0 py-1">Autosaved</span>
          <button
            onClick={() => onExit()}
            className="fw-bold btn btn-link"
            type="button"
          >
            Exit
          </button>
        </>
      );
    }
  };

  const renderButtons = () => {
    // TODO: This spinner is inadequate and should be replaced by a pane which obscures the form while the request
    // is being submitted.
    if (createRequestLoading || updateRequestLoading) {
      return (
        <Spinner
          color="dark"
          style={{
            width: '1.25rem', height: '1.25rem', display: 'inline-block',
          }}
        />
      );
    }

    return (
      <>
        {!autosaved && !sourceRequestType === 'exposure' && (
          <button
            onClick={() => onExit()}
            className="fw-bold btn btn-link d-block d-md-inline-block"
            type="button"
          >
            Cancel
          </button>
        )}
        <button
          type="submit"
          className="btn btn-primary btn-lg fw-bold d-block d-md-inline-block mx-2 ms-md-0"
        >
          Publish
        </button>
      </>
    );
  };

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <div className={`${sourceRequestType === 'exposure' ? 'mx-md-5 px-md-5' : 'card'} py-3`}>
          <h2 className="mb-3 mx-0 px-2 px-md-4">
            {requestId && (
              <>Edit Request</>
            )}
            {!requestId && sourceRequestType != 'exposure' && (
              <>
                New Request
                <small className="text-muted">
                  &nbsp;&mdash;&nbsp;What are you looking for today?
                </small>
              </>
            )}
          </h2>

          <div className="px-2 px-md-5">
            {formErrorText.length > 0 && (
              <div className="alert alert-danger mb-2" role="alert">
                {formErrorText}
              </div>
            )}
            {sourceRequestType != 'exposure' && (
              <RequestTypeSelect
                requestType={sourceRequestType}
                setRequestType={setSourceRequestType}
                handleLogistics={handleLogistics}
              />
            )}
          </div>
          {sourceRequestType != 'exposure' && (
            <hr className="mt-0 mb-4" />
          )}

          <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            {sourceRequestType != 'exposure'
              && (
                <div className="px-2 px-md-5">
                  <FieldName
                    name={name}
                    onBlur={autosave}
                    requestType={sourceRequestType}
                    setName={setName}
                  />
                  <FieldDetails
                    details={details}
                    onBlur={autosave}
                    requestType={sourceRequestType}
                    setDetails={setDetails}
                  />
                  <SelectPublication
                    publicationId={publicationId}
                    publicationName={publicationName}
                    requestType={sourceRequestType}
                    setPublicationId={setPublicationId}
                    setPublicationName={setPublicationName}
                  />
                  <SelectSubmitDate
                    errorText={sourceRequestSubmitDateErrorText}
                    setSubmitDate={setSourceRequestSubmitDate}
                    submitDate={sourceRequestSubmitDate}
                  />
                  <SectionDistribution
                    dontPromoteOutside={dontPromoteOutside}
                    requestType={sourceRequestType}
                    setDontPromoteOutside={setDontPromoteOutside}
                  />
                  <SectionPaid
                    paid={paid}
                    requestType={sourceRequestType}
                    setPaid={setPaid}
                  />
                  <SectionSubtype
                    errorText={sourceRequestSubTypeErrorText}
                    requestType={sourceRequestType}
                    setSubtype={setSourceRequestSubType}
                    subtype={sourceRequestSubType}
                    handleLogistics={handleLogistics}
                  />
                </div>
              )}

            {sourceRequestType === 'exposure'
              && (
                <div className="px-2 px-md-5">
                  <h2> Share Your Work </h2>
                  <p className="mb-1 pb-0">Showcase your published work with thousands of industry experts and grow your
                    audience!</p>
                  <p className="text-primary font-size-16px mt-0 pt-0">*Limit: one per day, so please share your best
                    work!</p>
                  {canShareWork === true && (
                    <>
                      <FieldName
                        name={name}
                        requestType={sourceRequestType}
                        setName={setName}
                      />
                      <ArticleFields
                        setArticleUrl={setArticleUrl}
                      />
                      <SelectPublication
                        publicationId={publicationId}
                        publicationName={publicationName}
                        requestType={sourceRequestType}
                        setPublicationId={setPublicationId}
                        setPublicationName={setPublicationName}
                      />
                    </>
                  )}
                  {canShareWork === false && (
                    <div className="col-lg-8 mb-3 bg-white p-3 rounded rounded-large border border-primary shadow">
                      <h4 className="fw-bold">Thanks for submitting your work!
                        We currently have a share limit of one per day.
                        <br /> Please come back tomorrow to share more.</h4>
                    </div>
                  )}
                </div>
              )}

            {sourceRequestType !== 'story_ideas' && (
              <SectionLogistics
                autosave={autosave}
                equipmentNotes={equipmentNotes}
                links={links}
                logisticsDetails={logisticsDetails || []}
                setEquipmentNotes={setEquipmentNotes}
                setLinks={setLinks}
                setLogisticsDetails={setLogisticsDetails}
                subType={sourceRequestSubType}
                open={isOpen}
              />
            )}

            {createdByAdmin && (
              <SectionAdmin
                hideQwotedBrandFromReporter={hideQwotedBrandFromReporter}
                setHideQwotedBrandFromReporter={setHideQwotedBrandFromReporter}
              />
            )}

            {sourceRequestType != 'exposure' && (
              <hr style={{ marginTop: '0' }} />
            )}
            <div className="pb-2 px-md-5">
              {sourceRequestType === 'exposure' && !canShareWork ? (
                <a className="btn btn-large btn-primary" href="/">Dashboard</a>
              ) : (
                <>
                  <div className={`${sourceRequestType === 'exposure' ? 'float-start' : 'float-md-end'}`}>
                    {renderButtons()}
                  </div>
                  <div className="float-md-start">
                    {renderAutosaving()}
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

Requests.propTypes = {
  backPath: PropTypes.string,
  createdByUserId: PropTypes.number,
  createdByAdmin: PropTypes.bool,
  newDetails: PropTypes.string,
  newName: PropTypes.string,
  newType: PropTypes.string,
  requestId: PropTypes.number,
  canShareWork: PropTypes.bool,
};

Requests.defaultProps = {
  backPath: undefined,
  createdByUserId: undefined,
  createdByAdmin: false,
  newDetails: undefined,
  newName: undefined,
  newType: undefined,
  requestId: undefined,
  canShareWork: true,
};

export default Requests;
