/* eslint-disable react/prop-types */
import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';
import opportunityHelpers from '../../../javascript/frontend/helpers/opportunity_helpers';
import helper from '../../../javascript/frontend/helpers/helper';
import dateHelpers from '../../../javascript/frontend/helpers/date_helpers';
import vahoy from '../../../javascript/vahoy';

class Item extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
    };
  }

  componentDidUpdate() {
    const { popoverOpen } = this.state;
    if (popoverOpen) {
      $('body').on('click', this.toggle);
    } else {
      $('body').off('click', this.toggle);
    }
  }

  popover = (elementId) => {
    const { item } = this.props;
    const {
      popoverOpen,
    } = this.state;
    const o = item.data.opportunity;

    if (jQuery(`#${elementId}`).length === 0) {
      return;
    }

    let detailsSanitized;
    detailsSanitized = opportunityHelpers.queryCompressAndHighlight(o.attributes.details);
    detailsSanitized = helper.sanitize(_.truncate(
      detailsSanitized,
      { length: window.TRUNCATE_TEXT },
    ));

    /* eslint-disable react/no-danger */
    return (
      <Popover
        className="fc"
        id={`opportunity-popover-${o.id}`}
        isOpen={popoverOpen}
        target={elementId}
      >
        <PopoverHeader className="text-dark">
          <span className="closeLink float-end">
            <i className="closeLink fa-solid fa-xmark" aria-hidden="true" />
          </span>

          <p className="mb-0">{o.attributes.name}</p>

          <div className="clearfix" />
        </PopoverHeader>
        <PopoverBody>

          <div className="float-end text-end">
            <a className="closeLink ms-1" onClick={this.toggle} href={o.links.frontend}>
              View&nbsp;
              <i className="fa-solid fa-up-right-from-square" />
            </a>
          </div>

          {this.conditionalFlag()}<span dangerouslySetInnerHTML={{ __html: detailsSanitized }} />

          <div className="clearfix mb-3" />

          <table className="table table-sm table-borderless">
            <tbody>
              {this.opportunityType()}
              {this.submitByDate()}
              {this.awardDate()}
              {this.specialReportPublicationDate()}
              {this.calendarItemStartDate()}
              {this.calendarItemEndDate()}
              {this.eventStartDate()}
              {this.eventEndDate()}
              {this.opportunityDate()}
              {this.updatedAt()}
              {this.optionalRegion()}
            </tbody>
          </table>
        </PopoverBody>
      </Popover>
    );
    /* eslint-enable react/no-danger */
  };

  toggle = (e = null) => {
    const { item } = this.props;
    const { popoverOpen } = this.state;
    if (!e || $(e.target).hasClass('closeLink')
      || !$(e.target).closest(`#opportunity-popover-${item.data.opportunity.id}`).length) {
      this.setState({
        popoverOpen: !popoverOpen,
      });
      if (!popoverOpen) {
        vahoy.track('opportunity/calendar/item#toggle');
      }
      if (e) {
        e.stopPropagation();
      }
    }
  };

  conditionalFlag = () => {
    const { item } = this.props;
    const o = item.data.opportunity;

    if (window.CURRENT_USER_INTERNAL && o.attributes.flagged == 'true') {
      return (
        <span>
          <i className="fa-solid fa-lg fa-flag text-danger" title="Flagged for review" />&nbsp;
        </span>
      );
    }
  };

  optionalRegion = () => {
    const { item } = this.props;
    const o = item.data.opportunity;

    if (helper.urlParamPresent('region')) {
      return (
        <tr>
          <td>Region</td>
          <td>{o.attributes.region}</td>
        </tr>
      );
    }
  };

  opportunityType = () => {
    const { item } = this.props;
    const o = item.data.opportunity;

    return (
      <tr>
        <td>Opportunity type</td>
        <td>
          <i className={opportunityHelpers.opportunityTypeIconClasses(o.attributes.opportunity_type)} />
        </td>
      </tr>
    );
  };

  awardDate = () => {
    const { item } = this.props;
    const oa = item.data.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.award_date);
    const cols = opportunityHelpers.sharedDateColumnsForOpportunityArray();

    if (_.includes(cols, 'award_date')) {
      return (
        <tr>
          <td>Awarded</td>
          <td>{fd}</td>
        </tr>
      );
    }
  };

  specialReportPublicationDate = () => {
    const { item } = this.props;
    const oa = item.data.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.special_report_publication_date);
    const cols = opportunityHelpers.sharedDateColumnsForOpportunityArray();

    if (_.includes(cols, 'special_report_publication_date')) {
      return (
        <tr>
          <td>Published</td>
          <td>{fd}</td>
        </tr>
      );
    }
  };

  calendarItemStartDate = () => {
    const { item } = this.props;
    const oa = item.data.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.calendar_item_start_date);
    const cols = opportunityHelpers.sharedDateColumnsForOpportunityArray();

    if (_.includes(cols, 'calendar_item_start_date')) {
      return (
        <tr>
          <td>Starts</td>
          <td>{fd}</td>
        </tr>
      );
    }
  };

  calendarItemEndDate = () => {
    const { item } = this.props;
    const oa = item.data.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.calendar_item_end_date);
    const cols = opportunityHelpers.sharedDateColumnsForOpportunityArray();

    if (_.includes(cols, 'calendar_item_end_date')) {
      return (
        <tr>
          <td>Ends</td>
          <td>{fd}</td>
        </tr>
      );
    }
  };

  eventStartDate = () => {
    const { item } = this.props;
    const oa = item.data.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.event_start_date);
    const cols = opportunityHelpers.sharedDateColumnsForOpportunityArray();

    if (_.includes(cols, 'event_start_date')) {
      return (
        <tr>
          <td>Starts</td>
          <td>{fd}</td>
        </tr>
      );
    }
  };

  eventEndDate = () => {
    const { item } = this.props;
    const oa = item.data.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.event_end_date);
    const cols = opportunityHelpers.sharedDateColumnsForOpportunityArray();

    if (_.includes(cols, 'event_end_date')) {
      return (
        <tr>
          <td>Ends</td>
          <td>{fd}</td>
        </tr>
      );
    }
  };

  submitByDate = () => {
    const { item } = this.props;
    const oa = item.data.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.submit_by_date);
    const cols = opportunityHelpers.sharedDateColumnsForOpportunityArray();

    if (_.includes(cols, 'submit_by_date') || _.includes(
      cols,
      'special_report_submit_date',
    ) || _.includes(cols, 'award_submit_date')) {
      return (
        <tr>
          <td>Submit by</td>
          <td>{fd}</td>
        </tr>
      );
    }
  };

  opportunityDate = () => {
    const { item } = this.props;
    const oa = item.data.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.opportunity_date);
    const cols = opportunityHelpers.sharedDateColumnsForOpportunityArray();

    if (_.includes(cols, 'opportunity_date')) {
      return (
        <tr>
          <td>Date</td>
          <td>{fd}</td>
        </tr>
      );
    }
  };

  updatedAt = () => {
    const { item } = this.props;
    const oa = item.data.opportunity.attributes;
    const fd = dateHelpers.formattedDate(oa.updated_at, 1);
    return (
      <tr className="text-muted">
        <td>Updated</td>
        <td>{fd}</td>
      </tr>
    );
  };

  render() {
    const { item } = this.props;
    const opp = item.data.opportunity;

    let nameSanitized;
    nameSanitized = helper.sanitize(opp.attributes.name);
    nameSanitized = opportunityHelpers.queryHighlight(nameSanitized);
    const elementId = `opportunity-label-${opp.id}`;

    return (
      <span
        className="popover-width-fixer"
        id={elementId}
        onClick={this.toggle}
        onKeyDown={() => {
        }}
        role="button"
        tabIndex="0"
      >
        <span>
          <i className={opportunityHelpers.opportunityTypeIconClasses(opp.attributes.opportunity_type)} />
          {nameSanitized}
        </span>
        {this.popover(elementId)}
      </span>
    );
  }
}

Item.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

export default Item;
