import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from './edit_icon';
import vahoy from '../../javascript/vahoy';

class ContactInfos extends React.Component {
  onClickEditIcon = (infoType, model) => () => {
    const { isEditable, toggleAddOrEditAddress, toggleAddOrEditContactInfo } = this.props;

    if (!isEditable) {
      return;
    }

    if (infoType == 'address') {
      toggleAddOrEditAddress();
    } else {
      toggleAddOrEditContactInfo(infoType, model);
    }
  };

  trackClicks = (clickType) => {
    vahoy.track(`Reporter/ContactInfos#clickContactInfoLink_${clickType}`);
  };

  constructUrl(urlType, value) {
    if (urlType == 'facebook') {
      if (/^http(s?:?)\/\//i.test(value)) {
        return value;
      }
      if (/fb.com|facebook.com/i.test(value)) {
        return `https://${value}`.replace(/\/{3,}/, '//');
      }
      return `https://facebook.com/${value}`;
    }
    if (urlType == 'linkedin') {
      if (/^http(s?:?)\/\//i.test(value)) {
        return value;
      }
      if (/linkedin.com/i.test(value)) {
        return `https://${value}`.replace(/\/{3,}/, '//');
      }
      return `https://linkedin.com/${value}`;
    }
    return value;
  }

  renderEditIcon(onClickFn, additionalClasses, optionalText = null) {
    const { isEditable } = this.props;

    return (
      <EditIcon
        isEditable={isEditable}
        optionalText={optionalText}
        onClickFn={onClickFn}
        className={additionalClasses}
      />
    );
  }

  render() {
    const { contactInfos = [], addressValue, userId } = this.props;

    let twitter = contactInfos.find((ci) => {
      const r = ci.attributes.info_type == 'twitter' && ci.attributes.primary == 'true';
      return r;
    });
    twitter = twitter || contactInfos.find((ci) => ci.attributes.info_type == 'twitter');
    let email = contactInfos.find((ci) => {
      const r = ci.attributes.info_type == 'email' && ci.attributes.primary == 'true';
      return r;
    });
    email = email || contactInfos.find((ci) => ci.attributes.info_type == 'email');
    let phone = contactInfos.find((ci) => {
      const r = ci.attributes.info_type == 'phone' && ci.attributes.primary == 'true';
      return r;
    });
    phone = phone || contactInfos.find((ci) => ci.attributes.info_type == 'phone');
    let facebook = contactInfos.find((ci) => {
      const r = ci.attributes.info_type == 'facebook' && ci.attributes.primary == 'true';
      return r;
    });
    facebook = facebook || contactInfos.find((ci) => ci.attributes.info_type == 'facebook');
    let linkedin = contactInfos.find((ci) => {
      const r = ci.attributes.info_type == 'linkedin' && ci.attributes.primary == 'true';
      return r;
    });
    linkedin = linkedin || contactInfos.find((ci) => ci.attributes.info_type == 'linkedin');

    const addressInfo = addressValue && (
      <li>
        <address className="mb-0">
          <i className="fa-solid fa-location-dot me-2" />
          {addressValue} {this.renderEditIcon(this.onClickEditIcon('address'), 'ms-2 p-0 edit-location-btn')}
        </address>
      </li>
    );

    const twitterInfo = twitter && (
      <li className="mt-1">
        <i className="me-2 fa-brands fa-x-twitter" />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://twitter.com/${twitter.attributes.value}`}
          className="text-primary"
          onClick={() => this.trackClicks('twitter')}
        >
          {twitter.attributes.value}
        </a>
        {this.renderEditIcon(this.onClickEditIcon('twitter', twitter), 'ms-2 p-0 edit-twitter-btn')}
      </li>
    );

    const emailInfo = userId && email && (
      <li className="mt-1">
        <i className="me-2 fa-solid fa-envelope" />
        <a
          href={`mailto:${email.attributes.value}`}
          className="text-primary"
          onClick={() => this.trackClicks('email')}
        >
          {email.attributes.value}
        </a>
        {this.renderEditIcon(this.onClickEditIcon('email', email), 'ms-2 p-0 edit-email-btn')}
      </li>
    );

    const phoneInfo = userId && phone && (
      <li className="mt-2">
        <i className="me-2 fa-solid fa-phone" />
        <span className="text-primary">{phone.attributes.value}</span>
        {this.renderEditIcon(this.onClickEditIcon('phone', phone), 'ms-2 p-0 edit-phone-btn')}
      </li>
    );

    const facebookInfo = facebook && (
      <li className="mt-2">
        <i className="me-2 fa-brands fa-facebook-f" />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={this.constructUrl('facebook', facebook.attributes.value)}
          className="text-primary"
          onClick={() => this.trackClicks('facebook')}
        >
          {facebook.attributes.value.replace(/^[a-z0-9:/.]+facebook.com\//i, '')}
        </a>
        {this.renderEditIcon(this.onClickEditIcon('facebook', facebook), 'ms-2 p-0 edit-facebook-btn')}
      </li>
    );

    const linkedinInfo = linkedin && (
      <li className="mt-1">
        <i className="me-2 fa-brands fa-linkedin-in" />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={this.constructUrl('linkedin', linkedin.attributes.value)}
          className="text-primary"
          onClick={() => this.trackClicks('linkedin')}
        >
          {linkedin.attributes.value.replace(/^[a-z0-9:/.]+linkedin.com\//i, '')}
        </a>
        {this.renderEditIcon(this.onClickEditIcon('linkedin', linkedin), 'ms-2 p-0 edit-linkedin-btn')}
      </li>
    );

    return (
      <ul className="list-unstyled mt-3">
        {addressInfo}
        {twitterInfo}
        {linkedinInfo}
        {emailInfo}
        {phoneInfo}
        {facebookInfo}
      </ul>
    );
  }
}

ContactInfos.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditable: PropTypes.bool,
  addressValue: PropTypes.string,
  contactInfos: PropTypes.arrayOf(Object).isRequired,
  toggleAddOrEditContactInfo: PropTypes.func,
  toggleAddOrEditAddress: PropTypes.func,
};

ContactInfos.defaultProps = {
  userId: undefined,
  isEditable: undefined,
  addressValue: '',
  toggleAddOrEditContactInfo: undefined,
  toggleAddOrEditAddress: undefined,
};

export default ContactInfos;
