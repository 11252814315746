import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import useLocalStorageState from '../../hooks/use_local_storage_state';
import MenuBar from '../../directory/MenuBar';
import SourceCard from '../card/source_card';
import SourceSkeletonPage from './source_skeleton_page';
import ToggleSwitch from '../../toggle_switch';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import SearchLaunchForm from '../../algolia_search/search_launch_form';

function SourcesIndex({ view }) {
  const perPage = 24;
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [refreshCount, setRefreshCount] = useState(0);
  const [smallMode, setSmallMode] = useLocalStorageState(false, 'smallModeSourceCards');
  const [sources, setSources] = useState([]);
  const [asyncError, setAsyncError] = useState();

  const forceReload = () => {
    setRefreshCount((prevCount) => prevCount + 1);
    setIsLoading(true);
    setSources([]);
    setPage(1);
  };

  const handleSmallModeToggle = () => {
    setSmallMode((currentMode) => !currentMode);
  };

  // Ignore scrollContainerRef since we want to use root as the scrolling container
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });

  // Effect to track infinite scrolling intersection observer event, which increments page
  useEffect(() => {
    if (lastTriggeredAt > 0) setPage((prevPage) => prevPage + 1);
  }, [lastTriggeredAt]);

  // Effect to track the page number and trigger fetch from API
  useEffect(() => {
    const fetchRecords = async () => {
      try {
        if (page === 1) setIsLoading(true);
        vahoy.track(`InfiniteSources#loadPage_${page}`);

        const params = {
          page,
          per_page: perPage,
          favorited: 'y',
        };

        const response = await vapi.getSources(params);
        if (response.status === 200) {
          const results = response.data;
          if (results.data && results.data.length > 0) {
            setSources((prevRecords) => [...prevRecords, ...results.data]);
          }
          setHasMore(results.data.length >= perPage);
        }
        setIsLoading(false);
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchRecords();
  }, [page, perPage, refreshCount]);

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  return (
    <div>
      <MenuBar view={view} onNavChange={forceReload}>
        {{
          searchForm: <SearchLaunchForm searchUrl="/sources" className="d-flex me-3" />,
          toggleSwitch: <ToggleSwitch label="Condensed View" value={smallMode} onChange={handleSmallModeToggle} />,
        }}
      </MenuBar>

      <h1 className="pt-2 mb-3">Saved Experts</h1>

      {!isLoading && sources.length === 0 && (
        <div className="text-center p-4 fw-bold">
          <span>You haven't saved any favorites yet...</span>
          <br />
          <a href="/sources">
            Try searching for sources
          </a>
        </div>
      )}
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 px-1">
        {isLoading && <SourceSkeletonPage numberOfCards={12} smallMode={smallMode} />}
        {!isLoading
          && sources.map((source) => (
            <div className="col px-2 pb-3 pt-0" key={source.id}>
              <SourceCard source={source.attributes} smallMode={smallMode} />
            </div>
          ))}
      </div>
      {hasMore && (
        <div
          ref={scrollTriggerRef}
          className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 px-1"
        >
          <SourceSkeletonPage numberOfCards={4} singleRow smallMode={smallMode} />
        </div>
      )}
    </div>
  );
}

SourcesIndex.propTypes = {
  view: PropTypes.string.isRequired,
};

export default SourcesIndex;
