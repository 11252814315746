document.addEventListener('turbolinks:load', () => {
  jQuery('.js-post-signup-capture-interests').on('submit', (evt) => {
    const hashtags = jQuery("#select-hashtags [name='hashtags[]']").val() || [];

    if (hashtags.length < 5) {
      jQuery('#select-hashtags .js-error-description').show();
      evt.preventDefault();
    } else {
      jQuery('#select-hashtags .js-error-description').hide();
    }
  });

  jQuery('.js-post-signup-capture-interests #select-hashtags').on('click', (evt) => {
    if (evt.target.tagName == 'DIV') {
      jQuery('.js-post-signup-capture-interests #select-hashtags .select2-search__field').last().focus();
    }
  });
});
