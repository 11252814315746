import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer, Text,
} from 'recharts';
import { connectRefinementList } from 'react-instantsearch-dom';

/* eslint-disable no-bitwise */

const COLORS = ['#D8D8FF', '#D1F0F7', '#FFF5CC', '#FFD4B2', '#D6F5E1', '#E8F6A7', '#E4F6C4', '#FFCCCC', '#FFFFDD', '#FFDFE6'];

const clamp = (value) => {
  if (value < 1) return 0;
  if (value > 255) return 255;
  return value;
};

const getDarkerColor = (color) => {
  const num = parseInt(color.slice(1), 16);
  const amt = -80;
  const R = clamp((num >> 16) + amt);
  const G = clamp(((num >> 8) & 0x00FF) + amt);
  const B = clamp((num & 0x0000FF) + amt);
  return `#${(0x1000000 + R * 0x10000 + G * 0x100 + B).toString(16).slice(1).toUpperCase()}`;
};

function renderCustomBarLabel(props) {
  const {
    x, y, width, height, value, fill, index, items,
  } = props;
  const publication = items[index] ? items[index].label : '';
  return (
    <Text
      x={x + width / 2}
      y={y + height / 2}
      fill={getDarkerColor(fill)}
      textAnchor="start"
      verticalAnchor="middle"
      fontSize="14px"
      fontWeight="bold"
    >
      {`${publication} (${value})`}
    </Text>
  );
}

renderCustomBarLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fill: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    }),
  ).isRequired,
};

const handleCellClick = (value, refine) => {
  refine(value);
};

function PublicationBarChart({ items, refine }) {
  return (
    <div style={{ width: '100%', height: '25vh' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={items}
          layout="vertical"
          margin={{
            top: 5, right: 5, left: 0, bottom: 5,
          }}
        >
          <XAxis type="number" hide />
          <YAxis type="category" dataKey="label" hide />
          <Tooltip />
          <Bar dataKey="count" label={(props) => renderCustomBarLabel({ ...props, items })}>
            {items.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.isRefined ? '#745ff1' : COLORS[index % COLORS.length]}
                onClick={() => handleCellClick(entry.value, refine)}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

PublicationBarChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    }),
  ).isRequired,
  refine: PropTypes.func.isRequired,
};

const ConnectedPublicationBarChart = connectRefinementList(PublicationBarChart);

export default ConnectedPublicationBarChart;
