import React from 'react';
import PropTypes from 'prop-types';

function FollowsTable({
                        follows, handleUnfollow, handleBlock, handleFollow,
                      }) {
  const getTrafficLabel = (usageCount) => {
    if (usageCount >= 1000) return <span className="badge bg-danger">High</span>; // Above ceiling 4
    if (usageCount >= 342) return <span className="badge bg-warning text-dark">Medium High</span>; // Between Ceiling 3 and 4
    if (usageCount >= 100) return <span className="badge bg-dark text-light">Medium</span>; // Between Ceiling 2 and 3
    if (usageCount >= 26) return <span className="badge bg-dark text-light">Low</span>; // Between 1 & 2
    return <span className="badge bg-secondary">Very Low</span>; // Below Ceiling 1
  };

  return (
    <table className="table table-hover">
      <thead>
      <tr>
        <th>Hashtag</th>
        <th>
          Traffic <i className="fa-solid fa-sort-down" />
        </th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      {follows.map((follow) => (
        <tr key={follow.hashtag_id} className={follow.isTooNoisy ? 'table-secondary' : ''}>
          <td>{follow.hashtag_name}</td>
          <td>
            {getTrafficLabel(follow.hashtag_usage_count)}
            &nbsp;
            <small className="text-secondary">({follow.hashtag_usage_count})</small>
          </td>
          <td>
            {follow.isTooNoisy ? (
              <small className="text-secondary">
                Traffic exceeds your Notification Frequency. Increase to be notified for this tag.
              </small>
            ) : (
              <>
                {handleFollow && (
                  <button
                    className="btn btn-outline-secondary me-2"
                    onClick={() => handleFollow('MatchFeed::Hashtag', follow.hashtag_id)}
                    type="button"
                  >
                    Follow
                  </button>
                )}
                {handleUnfollow && (
                  <button
                    className="btn btn-outline-secondary me-2"
                    onClick={() => handleUnfollow('MatchFeed::Hashtag', follow.hashtag_id)}
                    type="button"
                  >
                    Unfollow
                  </button>
                )}
                {handleBlock && (
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => handleBlock('MatchFeed::Hashtag', follow.hashtag_id)}
                    type="button"
                  >
                    Block
                  </button>
                )}
              </>
            )}
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  );
}

FollowsTable.propTypes = {
  follows: PropTypes.arrayOf(PropTypes.shape({
    hashtag_id: PropTypes.number.isRequired,
    hashtag_name: PropTypes.string.isRequired,
    hashtag_usage_count: PropTypes.number.isRequired,
  })).isRequired,
  handleUnfollow: PropTypes.func,
  handleBlock: PropTypes.func,
  handleFollow: PropTypes.func,
};

FollowsTable.defaultProps = {
  handleUnfollow: null,
  handleBlock: null,
  handleFollow: null,
};

export default FollowsTable;
