import React from 'react';
import PropTypes from 'prop-types';

function TabbedNavigation({ tabs, activeTab, onTabChange }) {
  const isActive = (tab) => {
    if (activeTab === tab.id) return true;
    if (tab.submenu && tab.submenu.some((subtab) => subtab.id === activeTab)) return true;

    return false;
  };

  const classListFor = (tab) => {
    const baseClasses = 'nav-link border';
    if (isActive(tab)) return `${baseClasses} active`;

    return baseClasses;
  };

  const iconColorClass = (tab) => {
    if (isActive(tab)) return 'text-white';

    return tab.color ? `text-${tab.color}` : '';
  };

  const isLoggedIn = !!window.CURRENT_USER_CLIENT_SESSION;

  const renderLink = (data, extraClasses = '') => (
    <a
      className={`${classListFor(data)} ${extraClasses}`}
      href={data.href}
    >
      {data.icon && (
        <i className={`fa-solid fa-${data.icon} me-2 ${iconColorClass(data)} align-middle`} title={data.display} />
      )}
      {data.display}
    </a>
  );

  return (
    <>
      <ul className="nav text-left flex-column flex-sm-row nav-pills font-size-14px">
        {tabs.map((tab) => (
          <li className="nav-item me-sm-2 mb-1" key={tab.id}>
            {tab.href && (!tab.loggedInOnly || (tab.loggedInOnly && isLoggedIn)) && (
              <>
                { !tab.submenu && renderLink(tab) }
                { tab.submenu && (
                  <div className="dropdown">
                    <button className={`btn btn-secondary w-100 dropdown-toggle ${classListFor(tab)}`} type="button" id={`dropdownMenu-${tab.id}`} style={{ textAlign: 'left' }} data-bs-toggle="dropdown" aria-expanded="false">
                      {tab.icon && (
                        <i className={`fa-solid fa-${tab.icon} me-2 ${iconColorClass(tab)} align-middle`} title={tab.display} />
                      )}
                      {tab.display}
                    </button>

                    <ul className="dropdown-menu" aria-labelledby={`dropdownMenu-${tab.id}`}>
                      {tab.submenu.map((subtab) => (
                        <li key={subtab.id}>{renderLink(subtab, 'dropdown-item')}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}
            {!tab.href && (!tab.loggedInOnly || (tab.loggedInOnly && isLoggedIn)) && (
              <button
                className={classListFor(tab)}
                onClick={() => onTabChange(tab.id)}
                type="button"
              >
                {tab.icon && (
                  <i className={`fa-solid fa-${tab.icon} me-2 ${iconColorClass(tab)} align-middle`} title={tab.display} />
                )}
                {tab.display}
              </button>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}

TabbedNavigation.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    display: PropTypes.string,
    href: PropTypes.string,
  })).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func,
};

TabbedNavigation.defaultProps = {
  onTabChange: undefined,
};

export default TabbedNavigation;
