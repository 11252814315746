import React from 'react';
import PropTypes from 'prop-types';

function ToggleSwitch({ label, value, onChange }) {
  if (value === undefined) { return; }

  return (
    <div className="form-check form-switch test-nav-switch d-inline-block ms-auto me-2 mt-2">
      <input
        type="checkbox"
        className="form-check-input"
        checked={value}
        onChange={onChange}
        id="nav-switch"
        style={{ cursor: 'pointer' }}
      />
      <label
        className="form-check-label"
        htmlFor="nav-switch"
        style={{ cursor: 'pointer' }}
      >
        {label}
      </label>
    </div>
  );
}

ToggleSwitch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

ToggleSwitch.defaultProps = {
  label: undefined,
  value: undefined,
  onChange: undefined,
};

export default ToggleSwitch;
