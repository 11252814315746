import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { CompanyContext } from '../data/company_context_management';

function CompanyTabHeader({
                            activeTab,
                            toggle,
                          }) {
  const {
    company,
    topLevelIsLoading,
  } = useContext(CompanyContext);

  return (
    <Nav tabs className="company-show-nav mb-4">
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === 'experts' }, 'company-show-nav-link')}
          onClick={() => {
            toggle('experts');
          }}
        >
          Our Experts
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === 'products' }, 'company-show-nav-link')}
          onClick={() => {
            toggle('products');
          }}
        >
          Products
        </NavLink>
      </NavItem>

      {!topLevelIsLoading && company.display_as_pr_firm && company.show_brands && (
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'brands' }, 'company-show-nav-link')}
            onClick={() => {
              toggle('brands');
            }}
          >
            Our Brands
          </NavLink>
        </NavItem>
      )}

      {!topLevelIsLoading && company.display_as_pr_firm && company.show_pr_users && (
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'team' }, 'company-show-nav-link')}
            onClick={() => {
              toggle('team');
            }}
          >
            Our PR Team
          </NavLink>
        </NavItem>
      )}

      {company.about && (
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'about' }, 'company-show-nav-link')}
            onClick={() => {
              toggle('about');
            }}
          >
            About Us
          </NavLink>
        </NavItem>
      )}
    </Nav>
  );
}

CompanyTabHeader.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default CompanyTabHeader;
