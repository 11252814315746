import React from 'react';
import PropTypes from 'prop-types';

function JobPostingHeader({
                            jobPosting,
                          }) {
  const currentDate = new Date();
  const createdAtDate = new Date(jobPosting.created_at);
  const msPerDay = 86_400_000;
  const justPosted = currentDate - createdAtDate <= msPerDay * 2; // created within past 2 days

  const topTier = jobPosting?.publication?.top_tier;

  const headerClasses = 'card-header text-uppercase text-white pt-2';
  if (jobPosting.audience === 'reporters') {
    if (topTier) {
      return (
        <div className={`${headerClasses} bg-primary`}>
          Top Tier
        </div>
      );
    }
    if (justPosted) {
      return (
        <div className={`${headerClasses} bg-success`}>
          Just Posted
        </div>
      );
    }
    return (
      <div className={`${headerClasses} bg-secondary`}>
        Media Jobs
      </div>
    );
  }
  if (jobPosting.audience === 'pr_users') {
    return (
      <div className={`${headerClasses} bg-secondary`}>
        PR Jobs
      </div>
    );
  }
  throw new Error('Error!');
}

JobPostingHeader.propTypes = {
  jobPosting: PropTypes.instanceOf(Object),
};

JobPostingHeader.defaultProps = {
  jobPosting: undefined,
};

export default JobPostingHeader;
