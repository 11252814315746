import React from 'react';
import PropTypes from 'prop-types';
import { connectRefinementList } from 'react-instantsearch-core';
import helper from '../javascript/frontend/helpers/helper';

function RefinementListWithoutCountBuilder({ items, refine, titleCase }) {
  return (
    <ul className="ais-RefinementList-list">
      {items.map((item) => (
        <li
          key={item.label}
          className={item.isRefined ? 'ais-RefinementList-item--selected' : 'ais-RefinementList-item'}
        >
          <label className="ais-RefinementList-label">
            <input
              className="ais-RefinementList-checkbox"
              type="checkbox"
              checked={item.isRefined}
              onChange={() => {
                refine(item.value);
              }}
            />
            {titleCase === true
              && (
                <span className="ais-RefinementList-labelText">{helper.toTitleCase(item.label)}</span>
              )}
            {titleCase === false
              && (
                <span className="ais-RefinementList-labelText">{item.label}</span>
              )}
          </label>
        </li>
      ))}
    </ul>
  );
}

RefinementListWithoutCountBuilder.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  refine: PropTypes.func,
  titleCase: PropTypes.bool,
};

RefinementListWithoutCountBuilder.defaultProps = {
  items: undefined,
  refine: undefined,
  titleCase: true,
};

const RefinementListWithoutCount = connectRefinementList(RefinementListWithoutCountBuilder);

export default RefinementListWithoutCount;
