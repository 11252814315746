import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  muteUser(params) {
    vlog.info('api#muteUser');

    api.configure();
    axios.defaults.headers.common.Accept = 'application/json';

    const url = '/api/internal/reporter_mutes';

    return axios.post(url, { reporter_mute: params }, api.axiosFix);
  },
});
