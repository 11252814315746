import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ClipboardJS from 'clipboard';
import { Tooltip } from 'bootstrap';
import vahoy from '../javascript/vahoy';

function ShareLink({ ahoyObjectGid, displayType = 'input-with-copy', link }) {
  const clipboardRef = useRef(null);

  useEffect(() => {
    clipboardRef.current = new ClipboardJS('#share-link-copy');

    clipboardRef.current.on('success', () => {
      vahoy.track('ShareLink#click', { object_gid: ahoyObjectGid });
      const outerEl = document.getElementById('share-link-copy');
      const outerTooltip = Tooltip.getOrCreateInstance(outerEl);
      outerTooltip.show();
      setTimeout(() => {
        const el = document.getElementById('share-link-copy');
        const tooltip = Tooltip.getOrCreateInstance(el);
        if (tooltip) {
          tooltip.hide();
        }
      }, 2500);
    });

    clipboardRef.current.on('error', () => {
    });

    return function cleanup() {
      clipboardRef.current && clipboardRef.current.destroy();
    };
  }, [ahoyObjectGid]);

  if (displayType === 'input-with-copy') {
    return (
      <div className="font-size-14px mb-2">
        Link:
        <div className="input-group">
          <input
            id="share-link"
            className="form-control"
            value={link}
            readOnly
            style={{
              flex: 1,
              minWidth: 0,
            }}
          />
          <button
            type="button"
            id="share-link-copy"
            className="btn btn-secondary pb-0 pt-0"
            data-clipboard-target="#share-link"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="manual"
            title="Copied!"
          >
            <i className="fa-solid fa-paste" alt="Copy to clipboard" />
          </button>
        </div>
      </div>
    );
  }
  if (displayType == 'link-icon') {
    return (
      <button
        type="button"
        id="share-link-copy"
        className="d-inline btn btn-link"
        data-clipboard-text={link}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="manual"
        title="Copied!"
      >
        <i className="fa-solid fa-link" alt="Copy to clipboard" />
      </button>
    );
  }
  throw new Error('Unsupported displayType!');
}

ShareLink.propTypes = {
  ahoyObjectGid: PropTypes.string.isRequired,
  displayType: PropTypes.string,
  link: PropTypes.string.isRequired,
};

ShareLink.defaultProps = {
  displayType: 'input-with-copy',
};

export default ShareLink;
