import React from 'react';
import PropTypes from 'prop-types';
import AutocompletePlace from './autocomplete_place';

class LocationFormFields extends React.Component {
  constructor(props) {
    super(props);

    const states = {
      address: {
        lat: props.location_latitude,
        lng: props.location_longitude,
        fullAddress: props.location_string,
      },
    };

    this.state = states;
  }

  setAddress = (address) => {
    this.setState({ address });
  };

  render() {
    const { address = {} } = this.state;
    const { formFor } = this.props;
    let locationGeocodedAt;
    let
      clientsideGeocoding;

    locationGeocodedAt = null;
    clientsideGeocoding = false;

    if (address.geocodedJson) {
      locationGeocodedAt = Date.now();
      clientsideGeocoding = true;
    }

    return (
      <div ref={(instance) => {
        // eslint-disable-next-line
        this.rootRef = instance;
      }}
      >
        <div className="row mt-1">
          <div className="col-10">
            <AutocompletePlace
              defaultValue={address.fullAddress || ''}
              onChange={this.setAddress}
              className="form-control"
            />
            <input
              type="hidden"
              value={address.fullAddress || ''}
              name={`${formFor}[location_string]`}
            />
            <input
              type="hidden"
              value={address.lat || ''}
              name={`${formFor}[location_latitude]`}
            />
            <input
              type="hidden"
              value={address.lng || ''}
              name={`${formFor}[location_longitude]`}
            />
            <input
              type="hidden"
              value={locationGeocodedAt}
              name={`${formFor}[location_geocoded_at]`}
            />
            <input
              type="hidden"
              value={clientsideGeocoding}
              name={`${formFor}[skip_geocoding]`}
            />
          </div>
        </div>
      </div>
    );
  }
}

LocationFormFields.propTypes = {
  formFor: PropTypes.string.isRequired,
  location_latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  location_longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  location_string: PropTypes.string,
};

LocationFormFields.defaultProps = {
  location_latitude: undefined,
  location_longitude: undefined,
  location_string: undefined,
};

export default LocationFormFields;
