import React from 'react';
import Skeleton from 'react-loading-skeleton';

function SkeletonTable() {
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Hashtag</th>
          <th>Traffic</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {[...Array(5)].map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={index}>
            <td>
              <Skeleton width={150} />
            </td>
            <td>
              <Skeleton width={100} />
            </td>
            <td>
              <Skeleton width={200} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SkeletonTable;
