import helper from '../../helpers/helper';

document.addEventListener('turbolinks:load', () => {
  const $mediaItemsNew = $('.media_items.new');
  const $mediaItemsEdit = $('.media_items.edit');

  if (
    helper.notPresent($mediaItemsNew) && helper.notPresent($mediaItemsEdit)
  ) {
    return;
  }

  // checks presence of attachment
  function smiFormAttachmentFilledIn() {
    if ($('#source_media_item_remove_attachment_cloudinary').is(':checked')) {
      return false;
    }
    return ($('#source_media_item_attachment_attributes_file_cloudinary').val().length > 0
      || $('#source_media_item_attachment_attributes_file_cloudinary_cache').val().length > 0
      || $('span.persisted-attachment').text().length > 0);
  }

  // checks presence of url
  function smiFormItemUrlFilledIn() {
    return ($('#source_media_item_item_url').val().length > 0);
  }

  // checks presence of attachment
  function pmiFormAttachmentFilledIn() {
    if ($('#product_media_item_remove_attachment_cloudinary').is(':checked')) {
      return false;
    }
    return ($('#product_media_item_attachment_attributes_file_cloudinary').val().length > 0
      || $('#product_media_item_attachment_attributes_file_cloudinary_cache').val().length > 0
      || $('span.persisted-attachment').text().length > 0);
  }

  // checks presence of url
  function pmiFormItemUrlFilledIn() {
    return ($('#product_media_item_item_url').val().length > 0);
  }

  ['source', 'product'].forEach((mediaItemType) => {
    $(`#${mediaItemType}_media_item_item_url`).on('change', (e) => {
      const key = window.LINKPREVIEW_API_KEY;
      const url = $(e.target).val();

      if (url.length === 0) {
        return;
      }

      const $imageField = $(`#${mediaItemType}_media_item_item_image_url`);
      const $titleField = $(`#${mediaItemType}_media_item_item_title`);
      const $descriptionField = $(`#${mediaItemType}_media_item_item_description`);
      const $linkPreviewedAtField = $(`#${mediaItemType}_media_item_link_previewed_at`);

      $('.thumb-url-tab').click();

      $.ajax({
        url: `https://api.linkpreview.net?key=${key}&q=${url}`,
        success(result) {
          $imageField.val(result.image);
          $titleField.val(result.title);
          $descriptionField.val(result.description);
          $linkPreviewedAtField.val(new Date());
        },
      });
    });
  });

  $('.thumb-file-tab').click((e) => {
    e.preventDefault();
    $('#source_media_item_item_image_url').val('');
    $('#product_media_item_item_image_url').val('');
    $('.thumb-file-tab').addClass('active');
    $('.thumb-url-tab').removeClass('active');
    $('.thumb-file-panel').removeClass('d-none');
    $('.thumb-url-panel').addClass('d-none');
  });

  $('.thumb-url-tab').click((e) => {
    e.preventDefault();
    $('#source_media_item_thumbnail_attributes_file_cloudinary').val('');
    $('#product_media_item_thumbnail_attributes_file_cloudinary').val('');
    $('.thumb-file-tab').removeClass('active');
    $('.thumb-url-tab').addClass('active');
    $('.thumb-file-panel').addClass('d-none');
    $('.thumb-url-panel').removeClass('d-none');
  });

  // tab toggles and visibility
  $('.tab_1').click(() => {
    // reset form field
    $('#source_media_item_item_url').val('');
    $('#product_media_item_item_url').val('');
    // toggle visibility
    $('.tab_1').addClass('active');
    $('.tab_2').removeClass('active');
    $('.row.attachment_cloudinary').removeClass('d-none');
    $('.row.item_url').addClass('d-none');
  });

  // tab toggles and visibility
  $('.tab_2').click(() => {
    // reset form field
    $('#source_media_item_attachment_attributes_file_cloudinary').val('');
    $('#product_media_item_attachment_attributes_file_cloudinary').val('');
    // toggle visibility
    $('.tab_1').removeClass('active');
    $('.tab_2').addClass('active');
    $('.row.attachment_cloudinary').addClass('d-none');
    $('.row.item_url').removeClass('d-none');
  });

  // some form validations
  $('form.new_source_media_item, form.edit_source_media_item').submit(() => {
    if (!smiFormAttachmentFilledIn() && !smiFormItemUrlFilledIn()) {
      alert('One of either "Attachment" or "Item url" must be filled in');
      return false;
    }
  });
  $('form.new_product_media_item, form.edit_product_media_item').submit(() => {
    if (!pmiFormAttachmentFilledIn() && !pmiFormItemUrlFilledIn()) {
      alert('One of either "Attachment" or "Item url" must be filled in');
      return false;
    }
  });
});
