/* eslint-disable no-use-before-define */

import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import vlog from '../../vlog';
import globals from '../../globals';

window.initializeSupabaseClient = () => {
  if (!window.V2_SUPABASE_API_EXTERNAL_URL || !window.SUPABASE_ANON_KEY) {
    vlog.error('Supabase configuration is missing.');
    throw new Error('Supabase configuration is missing.');
  }

  if (!window.supabaseClient) {
    window.supabaseClient = _createSupabaseClient(
      window.V2_SUPABASE_API_EXTERNAL_URL,
      window.SUPABASE_ANON_KEY,
    );
    vlog.debug('Supabase client initialized.');
  } else {
    vlog.debug('Supabase client already initialized.');
  }
};

window.supabaseLogin = async function supabaseLogin(email, password) {
  try {
    window.initializeSupabaseClient(); // Ensure client is initialized before login

    // Check if the user is already signed in
    const currentSession = await window.supabaseClient.auth.getSession();
    if (currentSession.data.session) {
      vlog.debug('User already signed in. Signing out...');
      await window.supabaseClient.auth.signOut();
    }

    // Log in using Supabase
    const { data, error } = await window.supabaseClient.auth.signInWithPassword({ email, password });
    if (error) {
      vlog.error('Supabase login failed:', error);
      throw error;
    }

    vlog.debug('Supabase login successful:', data);
    return { success: true, data };
  } catch (err) {
    vlog.error('Unexpected error during Supabase login:', err);
    throw err;
  }
};

window.supabaseLogout = async function supabaseLogout() {
  try {
    window.initializeSupabaseClient(); // Ensure client is initialized before logout

    const { error } = await window.supabaseClient.auth.signOut();
    if (error) {
      vlog.error('Supabase sign-out failed:', error);
      throw error;
    }

    vlog.debug('Supabase sign-out successful.');
    return { success: true };
  } catch (err) {
    vlog.error('Unexpected error during Supabase sign-out:', err);
    throw err;
  }
};

window.supabaseToken = async function supabaseToken() {
  try {
    window.initializeSupabaseClient();

    const session = await window.supabaseClient.auth.getSession();

    if (session.data.session) {
      const token = session.data.session.access_token;
      vlog.debug('Supabase token fetched:', token);
      return token;
    }
    vlog.error('Supabase session not found.');
    return null;
  } catch (error) {
    vlog.error('Error fetching Supabase token:', error);
    throw error;
  }
};

window.v2HealthCheck = async function v2HealthCheck() {
  if (window.RAILS_ENV === 'test') return true;
  try {
    const response = await fetch(`${window.V2_URL}/health?exclude=kafka_feed_lag,sidekiq_queue_latency`, { method: 'GET' });
    return response.ok;
  } catch (e) {
    return false;
  }
};

window.v2ProfileId = async function V2ProfileId() {
  if (window.V2_PROFILE_ID) {
    return window.V2_PROFILE_ID;
  }

  // If not cached, fetch from the API
  const v2Ids = await _fetchV2Ids(window.CURRENT_USER_JSON.id);
  if (v2Ids) {
    return v2Ids.profileId;
  }

  vlog.error('Error fetching V2 profile ID.');
  return null; // or handle the error as needed
};

window.v2UserId = async function V2UserId() {
  if (window.V2_USER_ID) {
    return window.V2_USER_ID;
  }

  // If not cached, fetch from the API
  const v2Ids = await _fetchV2Ids(window.CURRENT_USER_JSON.id);
  if (v2Ids) {
    return v2Ids.accountUserId;
  }

  vlog.error('Error fetching V2 user ID.');
  return null; // or handle the error as needed
};

window.v2UserProfileSlug = async function V2UserId() {
  if (window.V2_USER_PROFILE_SLUG) {
    return window.V2_USER_PROFILE_SLUG;
  }

  // If not cached, fetch from the API
  const v2Ids = await _fetchV2Ids(window.CURRENT_USER_JSON.id);
  if (v2Ids) {
    return v2Ids.slug;
  }

  vlog.error('Error fetching V2 user ID.');
  return null; // or handle the error as needed
};

// Private below

// Custom implementation of "SupportedStorage" to store supabase.js session in cookies, instead of localStorage
// https://github.com/orgs/supabase/discussions/11100
// https://supabase.com/docs/guides/auth/sessions/pkce-flow
const cookieStorageAdapter = {
  getItem: (key) => {
    const value = Cookies.get(key);
    vlog.trace(`Cookies.get(key): ${key}, ${value}`);
    return value;
  },
  setItem: (key, value) => {
    const cookieOptions = {
      expires: 90, // 3 months in days
    };

    if (globals.TWO_LEVEL_DOMAIN) {
      cookieOptions.domain = `.${globals.TWO_LEVEL_DOMAIN}`;
      cookieOptions.sameSite = 'lax';
      cookieOptions.secure = true;
    }

    vlog.trace(`Cookies.set(key, value, cookieOptions): ${key}, ${value}, ${cookieOptions}`);
    Cookies.set(key, value, cookieOptions);
  },
  removeItem: (key) => {
    const removeOptions = {};

    if (globals.TWO_LEVEL_DOMAIN) {
      removeOptions.domain = `.${globals.TWO_LEVEL_DOMAIN}`;
    }

    vlog.trace(`Cookies.remove(key, removeOptions): ${key}, ${removeOptions}`);
    Cookies.remove(key, removeOptions);
  },
};

const _createSupabaseClient = (apiUrl, anonKey) => {
  if (apiUrl && anonKey) {
    return createClient(apiUrl, anonKey, {
      auth: {
        storage: cookieStorageAdapter,
      },
    });
  }
  vlog.error('Missing apiUrl or anonKey for Supabase client.');
  throw new Error('Supabase client could not be created due to missing parameters.');
};

const _fetchV2Ids = async function _fetchV2Ids(userId) {
  try {
    window.initializeSupabaseClient(); // Ensure client is initialized

    const { data, error: fetchError } = await window.supabaseClient
      .from('user_profiles')
      .select('profile_id, account_user_id, slug')
      .eq('user_migrate_id', userId)
      .single(); // We expect only one profile per user

    if (fetchError) {
      vlog.error('Error fetching user profile:', fetchError);
      return null;
    }

    if (data?.profile_id && data?.account_user_id) {
      // Cache the values on the window object
      window.V2_PROFILE_ID = data.profile_id;
      window.V2_USER_ID = data.account_user_id;
      window.V2_USER_PROFILE_SLUG = data.slug;
      return { profileId: data.profile_id, accountUserId: data.account_user_id, slug: data.slug };
    }

    vlog.error('V2 IDs not found!');
    return null;
  } catch (fetchError) {
    vlog.error('Error fetching V2 IDs', fetchError);
    return null;
  }
};
