import React from 'react';
import {
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';

function HashtagFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by hashtag">
          <RefinementList
            attribute="hashtags.hashtag"
            limit={6}
            operator="and"
            showMore={true}
            showMoreLimit={15}
            searchable={true}
          />
        </Panel>
      </div>
    </div>
  );
}

export default HashtagFilterPanel;
