import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/avatar';
import vahoy from '../../../javascript/vahoy';

function Reporter({ sourceRequest, smallMode }) {
  const { reporter } = sourceRequest;
  const sharedArticle = sourceRequest.shared_article;
  const exposure = sourceRequest.request_type_text === 'exposure';

  const trackClicks = () => {
    vahoy.track('SourceRequests/SourceRequestCard#clickSourceRequestLink');
  };

  let sizeClass;

  if (exposure) {
    sizeClass = 'avatar';
  } else if (smallMode) {
    sizeClass = 'avatar';
  } else {
    sizeClass = 'avatar-sm';
  }

  if (!sourceRequest.show_reporter || !reporter) return null;

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div className="pe-2 mt-2">
          <Avatar
            avatarUrl={reporter.avatar_url}
            sizeClass={sizeClass}
            avatarLetters={reporter.initials}
          />
        </div>
        <div>
          <h6 className={`${exposure ? 'mt-2 font-size-18px' : ''} mb-0 fw-bold text-primary`}>
            <a
              href={reporter.profile_path}
              onClick={trackClicks}
              className="text-primary"
            >
              {reporter.name}
            </a>
          </h6>
            { !exposure && (
              <div className="card-grid-subtitle fw-bold font-size-12px">
                {reporter.employment_string}
              </div>
            )}
            { exposure && (
              <div className="card-grid-subtitle font-size-18px text-dark mt-0">
                { sharedArticle && sharedArticle.publication_name && (
                  <>
                    <span className="font-size-16px">Shared work written for</span>
                    <span className="fw-bold ms-1 text-primary">
                      {sharedArticle.publication_name}
                    </span>
                  </>
                )}
              </div>
            )}
        </div>
      </div>
      { !sharedArticle && (
        <div className={`clearfix ${smallMode ? 'mb-2' : 'mb-4'}`} />
      )}
    </>
  );
}

Reporter.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object).isRequired,
  smallMode: PropTypes.bool,
};

Reporter.defaultProps = {
  smallMode: false,
};

export default Reporter;
