import React from 'react';
import PropTypes from 'prop-types';
import vahoy from '../javascript/vahoy';

class TwitterFollowButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twitterHandle: props.screenName,
      twitterUsername: props.screenName.replace('@', ''),
      followed: false,
    };
  }

  handleFollowClick = () => {
    this.setState({ followed: true });
    this.popupCenter(
      `https://twitter.com/intent/follow?screen_name=${this.state.twitterUsername}`,
      `Follow ${this.state.twitterHandle}`,
      500,
      650,
    );

    vahoy.track('TwitterFollowButton#click', { object_gid: this.props.ahoyObjectGid });
  };

  popupCenter(url, title, w, h) {
    const left = (window.screen.width / 2) - (w / 2);
    const top = (window.screen.height / 2) - (h / 2);
    return window.open(url, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`);
  }

  render() {
    let followedCheck = '';
    const { followed, twitterHandle } = this.state;

    if (followed) {
      followedCheck = (
        <i className="fa-solid fa-circle-check text-green ms-2 animated zoomIn" />
      );
    }

    return (
      <div className="inline-block">
        <button
          type="button"
          className="twitter-follow-button btn btn-sm"
          style={{ borderRadius: '1.5em', backgroundColor: '#009EF6', color: 'white' }}
          onClick={this.handleFollowClick}
        >
          <i className="fa-brands fa-x-twitter me-1" />
          Follow {twitterHandle}
          {followedCheck}
        </button>
      </div>
    );
  }
}

TwitterFollowButton.propTypes = {
  screenName: PropTypes.string.isRequired, // Twitter username for user being followed
  ahoyObjectGid: PropTypes.string.isRequired, // Global id for user being followed
};

export default TwitterFollowButton;
