import React, { useMemo, useState } from 'react';
import { Node, Editor, createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';

import PropTypes from 'prop-types';

import Header from './_header';
import ImageUploader from './_image_uploader';
import Preview from './_preview';
import vahoy from '../../javascript/vahoy';

function MdEditor(props) {
  const editor = useMemo(() => withReact(createEditor()), []);

  const { name, preview_class: previewClass } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const text = props.text || '';

  const [value, setValue] = useState([
    {
      type: 'paragraph',
      children: [{ text }],
    },
  ]);

  const [mode, setMode] = useState('writing');

  const [renderedMd, setRenderedMd] = useState(null);

  const renderMd = (markdown) => {
    $.ajax({
      url: '/markdown_renders',
      method: 'POST',
      data: { markdown },
      success: (result) => {
        setRenderedMd(result.rendered_markdown);
      },
    });
  };

  const showWriting = (e) => {
    e.preventDefault();
    setMode('writing');
  };

  const rawMarkdown = editor.children.map((n) => Node.string(n))
    .join('\n');

  const showPreview = (e) => {
    e.preventDefault();
    renderMd(rawMarkdown);
    setMode('preview');
  };

  const insertTextToCursor = (t) => {
    Editor.insertText(editor, t);
  };

  const insertImage = (data) => {
    const imageTag = `![${data.original_filename}](${data.url})`;
    insertTextToCursor(imageTag);
  };

  const uploadImage = (e) => {
    const { files } = e.target;
    const cloudName = window.CLOUDINARY_CLOUD_NAME;
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;

    const fd = new FormData();
    fd.append('upload_preset', window.CLOUDINARY_DEFAULT_UNSIGNED_UPLOAD_PREFIX);
    fd.append('tags', 'md_image');
    fd.append('file', files[0]);

    $.ajax({
      context: this,
      url,
      data: fd,
      processData: false,
      contentType: false,
      type: 'POST',
      success(data) {
        insertImage(data);
      },
    });

    vahoy.track('MdEditor#uploadImage');
  };

  return (
    <div className="md-editor">
      <Header
        mode={mode}
        showWriting={showWriting}
        showPreview={showPreview}
      />
      {mode === 'writing'
        && (
          <div>
            <div className="form-control md-editor-textarea">
              <Slate editor={editor} value={value} onChange={(newValue) => setValue(newValue)}>
                <Editable placeholder="Write some markdown..." />
              </Slate>
            </div>
            <ImageUploader
              uploadImage={uploadImage}
            />
          </div>
        )}
      {mode === 'preview'
        && <Preview value={renderedMd} previewClass={previewClass} />}
      <input
        type="hidden"
        value={rawMarkdown}
        name={name}
      />
    </div>
  );
}

MdEditor.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  preview_class: PropTypes.string,
};

MdEditor.defaultProps = {
  name: undefined,
  text: undefined,
  preview_class: undefined,
};

export default MdEditor;
