import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../avatar/avatar';
import LoadingImage from '../loading_image';

import vapi from '../../javascript/frontend/api/vapi';
import helper from '../../javascript/frontend/helpers/helper';
import vahoy from '../../javascript/vahoy';

class PeopleAlsoViewedReporters extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState(props);
  }

  // eslint-disable-next-line no-unused-vars
  getInitialState(props) {
    return {};
  }

  componentDidMount() {
    const { reporters } = this.props;

    if (!reporters) {
      this.loadReporters();
    }
  }

  // eslint-disable-next-line react/sort-comp
  loadReporters(params) {
    // eslint-disable-next-line no-param-reassign
    params = params || {};

    // eslint-disable-next-line no-param-reassign, react/destructuring-assignment
    params['filter[related_to_reporter_id]'] = this.props.reporterId;

    // eslint-disable-next-line no-param-reassign
    params['page[limit]'] = 3;
    this.setState({ isLoadingReporters: true });

    vapi.loadReporters(params).then((response) => {
      const responseData = response.data;
      const states = { isLoadingReporters: false };
      const reporters = responseData.data;

      states.reporters = reporters;
      this.setState(states);
    }).catch(() => {
      const states = { isLoadingReporters: false };

      states.loadingReportersError = true;
      this.setState(states);
    });
  }

  trackClicks = () => {
    vahoy.track('Reporter/PeopleAlsoViewedReporters#clickProfileLink');
  };

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    const reporters = this.props.reporters || this.state.reporters || [];
    const { isLoadingReporters } = this.state;
    let mainContent;

    if (isLoadingReporters) {
      mainContent = (
        <div className="bg-white shadow-sm p-3 text-center" style={{ marginTop: '1px' }}>
          <LoadingImage />
        </div>
      );
    } else if (reporters.length > 0) {
      mainContent = (
        <ul className="list-unstyled m-0">
          {reporters.map((reporter) => {
            const title = reporter.attributes.reporter_employments_string;
            const avatarUrl = reporter.attributes.avatar_cloudinary_url;
            const initials = reporter.attributes.initials
              || helper.getInitialLettersFromString(reporter.attributes.identifier_humanized);
            const avatarComp = (
              <Avatar
                avatarUrl={avatarUrl}
                sizeClass="avatar-sm"
                avatarLetters={initials}
              />
            );

            return (
              <li
                className="bg-white shadow-sm p-3 list-item text-black-50"
                key={reporter.id}
                style={{ fontSize: '1.2em', fontWeight: '500', marginTop: '1px' }}
              >
                <div className="d-flex">
                  <div className="pe-3">
                    {avatarComp}
                  </div>
                  <div className="" style={{ flex: 1 }}>
                    <div className="text-dark font-size-14px">
                      <a
                        href={`/reporters/${reporter.attributes.slug}`}
                        onClick={this.trackClicks}
                      >{reporter.attributes.identifier_humanized}
                      </a>
                    </div>
                    <div>
                      <span className="font-size-10px d-inline-block">{title}</span>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (<></>);
    }

    return (
      // eslint-disable-next-line react/destructuring-assignment
      <div className={`people-also-viewed ${this.props.className || ''}`}>
        <h4 className="bg-white shadow-sm p-3 fw-bold m-0">People Also Viewed</h4>
        {mainContent}
      </div>
    );
  }
}

PeopleAlsoViewedReporters.propTypes = {
  // reporterId is required if reporters is missing
  reporterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  // reporters is required if reporterId is missing
  reporters: PropTypes.arrayOf(Object),
  className: PropTypes.string,
};

PeopleAlsoViewedReporters.defaultProps = {
  reporterId: undefined,
  reporters: undefined,
  className: '',
};

export default PeopleAlsoViewedReporters;
