import React, { useContext } from 'react';
import { CompanyContext } from '../../data/company_context_management';
import ClaimThisPageModal from './claim_this_page_modal';

function ClaimThisPageBox() {
  const { company } = useContext(CompanyContext);
  const [showedClaimThisPageModal, toggleClaimThisPageModal] = React.useState(false);

  const showClaimThisPageModal = () => {
    toggleClaimThisPageModal(true);
  };

  const closeClaimThisPageModal = () => {
    toggleClaimThisPageModal(false);
  };

  return (
    <div className="mb-3">
      <div className="bg-white pb-4 shadow-sm claim-this-page-box">
        <div className="ps-4 pe-4 pt-3 pb-3 text-uppercase text-center text-white box-header">Is this your
          company?
        </div>
        <div className="ps-4 pe-4 font-size-36px mt-4 pt-2 pb-1 fw-bold">Claim this page</div>
        <div className="ps-4 pe-4">
          <ul className="ps-3">
            <li className="list-item mb-1">Control your media voice</li>
            <li className="list-item mb-1">Get discovered by relevant media</li>
            <li className="list-item mb-1">Raise the profile of your executives</li>
            <li className="list-item">Drive incremental SEO</li>
          </ul>
        </div>
        <div className="ps-4 pe-4 text-center d-grid">
          <button type="button" className="btn btn-lg btn-brand" onClick={showClaimThisPageModal}>Learn More
          </button>
        </div>
      </div>
      <ClaimThisPageModal
        isOpen={showedClaimThisPageModal}
        toggle={closeClaimThisPageModal}
        companyId={company.objectID}
      />
    </div>
  );
}

export default ClaimThisPageBox;
