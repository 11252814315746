import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';
// import { useDebounce } from 'use-lodash-debounce';
// import vapi from '../../../javascript/frontend/api/vapi';
// import vahoy from '../../../javascript/vahoy';
import NoResultsMessage from '../../algolia_search/no_results_message';
import SearchLoadingCards from './search_loading_cards';
import CompanyCard from '../card';
import CompanySkeletonPage from '../company_skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';

function InfiniteHits({
                        hasMore, refine, hits,
                      }) {
  // Ignore scrollContainerRef since we want to use root as the scrolling container
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });
  // const [qwotedRecords, setQwotedRecords] = useState({});
  // const [idsToLookup, setIdsToLookup] = useState([]);
  // const [asyncError, setAsyncError] = useState();

  // Effect to refine results when infinite scroll triggers
  useEffect(() => {
    if (lastTriggeredAt > 0) refine();
  }, [lastTriggeredAt, refine]);

  // TODO: Implement this part if we end up needing it - right now we don't
  // // Effect to find new Algolia hit records that haven't yet been updated from Qwoted DB
  // // Using the debounced list makes search-while-typing more responsive and limits the number
  // // of queries hitting the Qwoted API.
  // const debouncedHits = useDebounce(hits, 700);
  //
  // useEffect(() => {
  //   const missingIds = debouncedHits.filter((h) => qwotedRecords[h.objectID] === undefined).map((h) => h.objectID);
  //   setIdsToLookup((prevList) => [...prevList, ...missingIds]);
  // }, [debouncedHits, qwotedRecords]);

  // // Effect to perform lookup of yet-to-be Qwoted-filled records and populate state with results
  // useEffect(() => {
  //   const fetchRecords = async () => {
  //     try {
  //       if (idsToLookup.length > 0) {
  //         vahoy.track('CompaniesSearch#getAlgoliaAttribs', { companyIds: idsToLookup });
  //         const response = await vapi.getAlgoliaAttribsForCompanyIds(idsToLookup);
  //         if (response.status === 200) {
  //           const companyResults = response.data;
  //           if (companyResults.data && companyResults.data.length > 0) {
  //             const recordsToAdd = {};
  //             companyResults.data.forEach((company) => {
  //               recordsToAdd[company.id] = company;
  //             });
  //             setIdsToLookup([]);
  //             setQwotedRecords((prevList) => Object.assign(recordsToAdd, prevList));
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       setAsyncError(error);
  //     }
  //   };
  //   fetchRecords();
  // }, [idsToLookup]);

  // Let top-level ErrorBoundary see any async errors
  // if (asyncError) throw asyncError;

  return (
    <>
      <NoResultsMessage />
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-xl-3 row-cols-xxxl-4 px-1">
        <SearchLoadingCards />
        {hits.map((company) => (
          <div className="col px-2 pb-3 pt-0" key={company.objectID}>
            <CompanyCard
              company={company}
            />
          </div>
        ))}
      </div>
      {hasMore && (
        <div
          ref={scrollTriggerRef}
          className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-xl-3 row-cols-xxxl-4 px-1"
        >
          <CompanySkeletonPage numberOfCards={3} singleRow />
        </div>
      )}
    </>
  );
}

InfiniteHits.propTypes = {
  hasMore: PropTypes.bool,
  refine: PropTypes.func,
  hits: PropTypes.arrayOf(Object),
};

InfiniteHits.defaultProps = {
  hasMore: false,
  refine: undefined,
  hits: [],
};

export default connectInfiniteHits(InfiniteHits);
