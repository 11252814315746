import React from 'react';
import { Panel, RefinementList } from 'react-instantsearch-dom';
import { orderBy } from 'lodash';

function ProductCategoriesPanel() {
  return (
    <div className="card mb-3 product-categories-filter-panel">
      <div className="card-body">
        <Panel header="Filter by category">
          <RefinementList
            attribute="product_categories.name"
            facetOrdering={false}
            operator="or"
            limit={13}
            transformItems={(items) => orderBy(items, 'label', 'asc')}
          />
        </Panel>
      </div>
    </div>
  );
}

export default ProductCategoriesPanel;
