import React from 'react';
import PropTypes from 'prop-types';

class TagsInput extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  componentDidMount() {
    const { tags = true } = this.props;
    const {
      onOpen, onClose, onSelect, onUnselect, onChange,
    } = this.props;
    const { options = {} } = this.props;

    if (Object.keys(options).indexOf('tags') == -1) {
      options.tags = tags;
    }

    options.tokenSeparators = options.tokenSeparators || [',', ' '];
    options.language = options.language || {
      // eslint-disable-next-line func-names
      inputTooShort: () => '',
      noResults: () => 'Use comma (,) or space or enter keys to separate values',
    };

    const $select2 = jQuery(this.root).select2(options);
    $select2.on('select2:open', onOpen);
    $select2.on('select2:close', onClose);
    $select2.on('select2:select', onSelect);
    $select2.on('select2:unselect', onUnselect);
    $select2.on('change', onChange);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    const { tags } = this.props;
    const realTags = jQuery(this.root).val();

    if (!_.isEqual(realTags, tags)) {
      jQuery(this.root).val(tags);
    }
  }

  render() {
    const { elementName, className = '' } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <select
        name={elementName}
        ref={(instance) => {
          this.root = instance;
        }}
        className={`${className} tags-input post-signup-tags-input`}
        multiple
      />
    );
  }
}

TagsInput.propTypes = {
  elementName: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  tags: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(String)]),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  onUnselect: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

TagsInput.defaultProps = {
  elementName: undefined,
  options: undefined,
  tags: undefined,
  onOpen: undefined,
  onClose: undefined,
  onSelect: undefined,
  onUnselect: undefined,
  onChange: undefined,
  className: undefined,
};

export default TagsInput;
