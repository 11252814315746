import React from 'react';
import Skeleton from 'react-loading-skeleton';
import SidebarSkeletonList from './sidebar_skeleton_list';

function SideBarSkeleton() {
  return (
    <div className="h-100 col-md-3 col-sm-9 ms-auto">
      <div className="card mb-3 animated faster fadeIn">
        <div className="card-body pb-2">
          <div className="fw-bold mb-3 pb-2 text-uppercase small border-bottom">
            <Skeleton height={10} width="60%" />
          </div>
          <SidebarSkeletonList numberOfLines={3} />
        </div>
      </div>
      <div className="card mb-3 animated faster fadeIn">
        <div className="card-body pb-2">
          <div className="fw-bold mb-3 pb-2 text-uppercase small border-bottom">
            <Skeleton height={10} width="60%" />
          </div>
          <SidebarSkeletonList numberOfLines={3} />
        </div>
      </div>
      <div className="card mb-3 animated faster fadeIn">
        <div className="card-body pb-2">
          <div className="fw-bold mb-3 pb-2 text-uppercase small border-bottom">
            <Skeleton height={10} width="60%" />
          </div>
          <SidebarSkeletonList numberOfLines={1} textOnly={true} />
        </div>
      </div>
    </div>
  );
}

export default SideBarSkeleton;
