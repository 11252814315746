import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

class AutocompletePlace extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { value: props.defaultValue };
  }

  componentDidMount() {
    const { onChange } = this.props;
    const autocomplete = new window.google.maps.places.Autocomplete(
      this.inputRef,
      { types: ['(cities)'] },
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      if (lat && lng) {
        this.setState({ value: this.inputRef.value });
        onChange && onChange({
          geocodedJson: place,
          fullAddress: place.formatted_address,
          lat,
          lng,
        });
      } else {
        onChange && onChange();
      }
    });
  }

  onBlur = () => {
    const { onChange } = this.props;
    const inputValue = _.trim(this.inputRef.value || '');
    const originalValue = _.trim(this.state.value || '');

    if (inputValue != originalValue || (!inputValue && !originalValue)) {
      onChange && onChange();
    }
  };

  render() {
    const { className = '', defaultValue = '' } = this.props;

    return (
      <input
        type="text"
        onBlur={this.onBlur}
        className={`autocomplete-place-input ${className}`}
        defaultValue={defaultValue}
        ref={(instance) => {
          this.inputRef = instance;
        }}
      />
    );
  }
}

AutocompletePlace.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

AutocompletePlace.defaultProps = {
  defaultValue: undefined,
  onChange: undefined,
  className: undefined,
};

export default AutocompletePlace;
