// From Tiffany: "Can the dropdown appear when the mouse hovers over the links?"
document.addEventListener('turbolinks:load', () => {
  // eslint-disable-next-line func-names
  $('ul.navbar-nav li.dropdown').hover(function () {
    $(this).find('.dropdown-menu').stop(true, true).delay(100)
      .fadeIn(200);
    // eslint-disable-next-line func-names
  }, function () {
    $(this).find('.dropdown-menu').stop(true, true).delay(100)
      .fadeOut(200);
  });

  $('.dropdown-menu a.dropdown-toggle').on('click', function menu() {
    const $subMenu = $(this).next('.dropdown-menu');
    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show')
        .removeClass('show');
    }
    $subMenu.toggleClass('show');

    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', () => {
      $('.dropdown-submenu .show').removeClass('show');
    });

    return false;
  });
});
