import React from 'react';
import PropTypes from 'prop-types';

function SimplePagination(props) {
  const {
    onClickPrev,
    onClickNext,
  } = props;

  if (onClickPrev || onClickNext) {
    return (
      <ul className="pagination mb-0 me-2">
        {onClickPrev && (
          <li className="page-item me-2">
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={onClickPrev}
              type="button"
            >
              Previous Page
            </button>
          </li>
        )}
        {onClickNext && (
          <li className="page-item">
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={onClickNext}
              type="button"
            >
              Next Page
            </button>
          </li>
        )}
      </ul>
    );
  }

  return (
    <></>
  );
}

SimplePagination.propTypes = {
  onClickPrev: PropTypes.func,
  onClickNext: PropTypes.func,
};

SimplePagination.defaultProps = {
  onClickPrev: undefined,
  onClickNext: undefined,
};

export default SimplePagination;
