import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import SourceRequestsIndex from './index/source_requests_index';

function TopLevelIndex({ view, showAds, reporterUser }) {
  return (
    <ErrorBoundary>
      <SourceRequestsIndex
        view={view}
        showAds={showAds}
        reporterUser={reporterUser}
      />
    </ErrorBoundary>
  );
}

TopLevelIndex.propTypes = {
  view: PropTypes.string,
  showAds: PropTypes.bool,
  reporterUser: PropTypes.number,
};

TopLevelIndex.defaultProps = {
  view: 'all',
  showAds: false,
  reporterUser: undefined,
};

export default TopLevelIndex;
