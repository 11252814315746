import React, { useEffect, useState, useContext } from 'react';
import vahoy from '../../../javascript/vahoy';
import vapi from '../../../javascript/frontend/api/vapi';
import CompanyExpertsSkeletonPage from './company_experts_skeleton_page';
import PrUserCard from '../../pr_users/card/pr_user_card';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import { CompanyContext } from '../data/company_context_management';

function CompanyPrTeam() {
  const perPage = 12;
  const { companyId, topLevelIsLoading } = useContext(CompanyContext);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [prUsers, setPrUsers] = useState([]);
  const [asyncError, setAsyncError] = useState();

  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });

  // Effect to track infinite scrolling intersection observer event, which increments page
  useEffect(() => {
    if (lastTriggeredAt > 0) setPage((prevPage) => prevPage + 1);
  }, [lastTriggeredAt]);

  // Retrieve a company's PR team from the API and track the page number
  useEffect(() => {
    let componentIsMounted = true;
    const fetchPrUsers = async () => {
      try {
        if (companyId) {
          if (page === 1) setIsLoading(true);
          vahoy.track('Companies#getPrUsers');

          const params = {
            company_id: companyId,
            page,
            per_page: perPage,
          };

          const response = await vapi.getPrUsers(params);
          if (componentIsMounted && response.status === 200) {
            const prUserResults = response.data;
            if (prUserResults.data && prUserResults.data.length > 0) {
              setPrUsers((prevRecords) => [...prevRecords, ...prUserResults.data]);
            }
            setHasMore(prUserResults.data.length >= perPage);
          }
          if (componentIsMounted) setIsLoading(false);
        }
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchPrUsers();

    // Cleanup function to avoid the "Can't perform a React state update on an unmounted component"/memory leak problem
    return () => {
      componentIsMounted = false;
    };
  }, [companyId, page, perPage, topLevelIsLoading]);

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  return (
    <div className="col px-0">
      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3">
        {isLoading && (
          <CompanyExpertsSkeletonPage numberOfCards={6} />
        )}
        {!isLoading && prUsers.map((prUser) => (
          <div className="col mb-3" key={prUser.id}>
            <PrUserCard prUser={prUser.attributes} />
          </div>
        ))}
      </div>
      {hasMore && (
        <div
          ref={scrollTriggerRef}
          className="row row-cols-1 row-cols-md-2 row-cols-xl-3"
        >
          <CompanyExpertsSkeletonPage numberOfCards={3} singleRow />
        </div>
      )}
    </div>
  );
}

export default CompanyPrTeam;
