import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import vlog from '../../javascript/vlog';

function ContentWithHighlight({ content }) {
  /* eslint-disable react/no-danger */
  return (
    <div
      className="w-full text-start"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
    />
  );
  /* eslint-enable react/no-danger */
}

ContentWithHighlight.propTypes = {
  content: PropTypes.string.isRequired,
};

function SearchResult({
  result, v2UserProfileId, formatDateTime,
}) {
  const [pitchSender, setPitchSender] = useState(null);

  // Get conversation origin
  const getConversationOrigin = async (conversationId) => {
    const { data, error } = await window.supabaseClient
      .from('conversations')
      .select('origin_id, origin_type')
      .limit(1)
      .eq('conversation_id', conversationId)
      .single();

    if (error) {
      vlog.error('Error fetching conversation origin:', error);
      return null;
    }
    return data;
  };

  // Get pitch sender's profile name using pitch ID
  const getPitchSenderName = async (pitchId) => {
    const { data, error } = await window.supabaseClient
      .from('pitches')
      .select('profile_name')
      .eq('id', pitchId)
      .single();

    if (error) {
      vlog.error('Error fetching pitch sender name:', error);
      return null;
    }
    return data?.profile_name;
  };

  // Fetch the conversation origin (pitch ID) and then fetch pitch sender's profile
  const fetchPitchSender = useCallback(async (conversationId) => {
    try {
      const origin = await getConversationOrigin(conversationId);
      if (origin?.origin_type === 'Interact::Pitch') {
        const pitchSenderName = await getPitchSenderName(origin.origin_id);
        if (pitchSenderName) {
          setPitchSender(pitchSenderName);
        }
      }
    } catch (error) {
      vlog.error('Error fetching pitch sender:', error);
    }
  }, []);

  // Extract conversation senders, excluding the current user
  const convoSenders = JSON.parse(result.attributes.conversation_senders)
    .filter((s) => s.id !== v2UserProfileId)
    .map((s) => s.name)
    .join(', ');

  // Check if convoSenders is empty and fetch pitch sender if needed
  useEffect(() => {
    if (!convoSenders && result.id) {
      fetchPitchSender(result.id);
    }
  }, [convoSenders, result.id, fetchPitchSender]);

  const displaySenders = convoSenders || pitchSender;

  if (!result.attributes.conversation_migrate_id) {
    return null;
  }

  const conversationPath = `/users/${window.CURRENT_USER_JSON.id}/conversations/${result.attributes.conversation_migrate_id}`;
  const hitContent = result.attributes.hits.map((h) => h.content).join(' ... ');
  const sentAt = formatDateTime(result.attributes.last_sent_at);

  return (
    <li key={result.id} className="results list-group-item">
      <a
        href={conversationPath}
        className="text-black d-flex flex-column flex-md-row align-items-center"
        style={{ textDecoration: 'none' }}
      >
        <h5
          className="m-0 col-12 col-md-3"
          style={{
            color: '#1F2326',
            lineHeight: '24px',
            fontSize: '16px',
          }}
        >
          {displaySenders}
        </h5>
        <div
          style={{
            color: '#1F2326',
            lineHeight: '24px',
            fontSize: '16px',
          }}
          className="col-12 col-md-6"
        >
          <ContentWithHighlight content={hitContent} />
        </div>
        <div
          className="col-12 col-md-3"
          style={{
            color: '#8F9192',
            lineHeight: '24px',
            fontSize: '16px',
            textAlign: 'right',
          }}
        >
          {`Sent: ${sentAt}`}
        </div>
      </a>
    </li>
  );
}

SearchResult.propTypes = {
  result: PropTypes.object.isRequired,
  v2UserProfileId: PropTypes.number.isRequired,
  formatDateTime: PropTypes.func.isRequired,
};

export default SearchResult;
