import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../avatar/avatar';
import EditIcon from './edit_icon';
import helper from '../../javascript/frontend/helpers/helper';

class Publications extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  toggleShowAllPubs = () => {
    this.setState((prevState) => ({
      showAllPubs: !prevState.showAllPubs,
    }));
  };

  renderEditIcon(onClickFn, additionalClasses, optionalText = null) {
    const { isEditable } = this.props;

    return (
      <EditIcon
        isEditable={isEditable}
        optionalText={optionalText}
        onClickFn={onClickFn}
        className={additionalClasses}
      />
    );
  }

  render() {
    const {
      currentEmployments = [],
      publications = [],
    } = this.props;
    const {
      isEditable,
      maxNumberOfDisplayedPublications = 1000,
      addReporterEmployment,
    } = this.props;
    const {
      toggleEditReporterEmployment,
      toggleConfirmRemovingReporterEmployment,
    } = this.props;
    const { showAllPubs } = this.state;

    let displayedEmployments = currentEmployments;
    let viewMoreLink;
    const primaryButton = isEditable && (
      <button
        type="button"
        className="btn btn-primary ms-1 btn-add-publication"
        onClick={() => addReporterEmployment(true)}
      >Add
      </button>
    );
    const remainingCount = displayedEmployments.length - maxNumberOfDisplayedPublications;

    if (remainingCount > 0) {
      if (showAllPubs) {
        viewMoreLink = (
          <li className="bg-white shadow-sm p-3 list-item" style={{ marginTop: '1px' }}>
            <button className="btn btn-link text-muted" type="button" onClick={this.toggleShowAllPubs}>
              Show fewer
            </button>
          </li>
        );
      } else {
        displayedEmployments = displayedEmployments.slice(0, maxNumberOfDisplayedPublications);
        viewMoreLink = (
          <li className="bg-white shadow-sm p-3 list-item" style={{ marginTop: '1px' }}>
            <button className="btn btn-link text-muted" onClick={this.toggleShowAllPubs} type="button">...
              and {remainingCount} other {remainingCount === 1 ? 'publication' : 'publications'} (view)
            </button>
          </li>
        );
      }
    }

    return (
      <div className="mb-3 publications">
        <h4 className="bg-white fw-bold shadow-sm p-3 m-0">
          <span className="mb-1" style={{ flex: 1 }}>Publications</span>
          {primaryButton}
        </h4>
        <ul className="list-unstyled m-0">
          {displayedEmployments.map((employment) => {
            const publication = publications.find((pub) => pub.id === employment.attributes.publication_id);
            const trashIcon = isEditable && (
              <i
                className="fa fa-trash-can cursor-pointer font-size-12px"
                onClick={() => toggleConfirmRemovingReporterEmployment(employment)}
                role="button"
                tabIndex="-1"
                onKeyUp={(evt) => {
                  evt.keyCode === 13 && toggleConfirmRemovingReporterEmployment(employment);
                }}
                aria-label="Remove"
              />
            );
            const avatarUrl = (publication.attributes || {}).cloudinary_url;
            const reporterEmploymentErbEditUrl = (employment.links || {}).erb_edit_url;
            const initials = publication.attributes.initials
              || helper.getInitialLettersFromString(publication.attributes.name);
            const avatarComp = (
              <Avatar
                avatarUrl={avatarUrl}
                sizeClass="avatar-sm"
                avatarLetters={initials}
              />
            );
            const articlesCount = employment.attributes.articles_count > 0 && (
              <span
                className="font-size-10px"
              >
                {employment.attributes.articles_count} {employment.attributes.articles_count === '1' ? 'article' : 'articles'}
              </span>
            );

            return (
              <li
                className="bg-white shadow-sm p-3 list-item text-black-50"
                key={publication.id}
                style={{
                  fontSize: '1.2em',
                  fontWeight: '500',
                  marginTop: '1px',
                }}
              >
                <div className="d-flex">
                  {avatarUrl && (
                    <div className="pe-3">
                      {avatarComp}
                    </div>
                  )}
                  <div className="d-flex" style={{ flex: 1 }}>
                    <div style={{ flex: 1 }}>
                      <div className="text-dark font-size-14px text-break">
                        {publication.attributes.name || publication.attributes.root_domain}
                      </div>
                      <div>
                        {articlesCount}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      {isEditable && helper.currentUserInternal() && reporterEmploymentErbEditUrl && (
                        <a
                          href={reporterEmploymentErbEditUrl}
                          className="btn btn-staff btn-sm me-1"
                          type="button"
                        >
                          Edit
                        </a>
                      )}
                      {isEditable && this.renderEditIcon(() => toggleEditReporterEmployment(employment), 'p-0 me-2 btn-edit-publication')}
                      {trashIcon}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
          {displayedEmployments.length === 0 && isEditable && (
            <li className="bg-white shadow-sm p-3 list-item text-black-50">
              <span>Where do you write? Add your publications.</span>
            </li>
          )}
          {viewMoreLink}
        </ul>
      </div>
    );
  }
}

Publications.propTypes = {
  isEditable: PropTypes.bool,
  maxNumberOfDisplayedPublications: PropTypes.number,
  publications: PropTypes.arrayOf(Object).isRequired,
  currentEmployments: PropTypes.arrayOf(Object).isRequired,
  addReporterEmployment: PropTypes.func,
  toggleConfirmRemovingReporterEmployment: PropTypes.func,
  toggleEditReporterEmployment: PropTypes.func,
};

Publications.defaultProps = {
  isEditable: undefined,
  maxNumberOfDisplayedPublications: undefined,
  addReporterEmployment: undefined,
  toggleConfirmRemovingReporterEmployment: undefined,
  toggleEditReporterEmployment: undefined,
};

export default Publications;
