import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import _ from 'lodash';
import vapi from '../../../../javascript/frontend/api/vapi';
import Hashtags from '../../../hashtags';
import HashtagsEditableForm from '../../../hashtag/_form';
import { CompanyContext } from '../../data/company_context_management';

function HashtagList() {
  const {
    company, openEditModal, setOpenEditModal, setDoTopLevelFetch,
  } = useContext(CompanyContext);
  const [hashtagTags, setHashtagTags] = useState([]);
  const [madeChanges, setMadeChanges] = useState(false);
  const [taggedJson, setTaggedJson] = useState(); // used by HashtagForm to update hts

  // Create a memoized version of this async function to avoid infinite loops
  // when it gets included in the effect's dependency array.
  const loadHashtagTags = useCallback(async () => {
    if (_.isEmpty(company)) return null;
    const url = `/api/internal/jsonapi/companies/${company.objectID}/derived-hashtag-tags`;

    const response = await vapi.getHashtags(url);
    if (response.status === 200) {
      const hashtagTagsResponse = response.data.data;
      setHashtagTags(hashtagTagsResponse);
    }
  }, [company]);

  useEffect(() => {
    if (company) {
      loadHashtagTags();
      setTaggedJson({
        id: company.objectID,
        type: 'companies',
      });
    }
  }, [company, loadHashtagTags]);

  const closeEditingModal = (e) => {
    e && e.preventDefault();
    setOpenEditModal(null);
    if (madeChanges) setDoTopLevelFetch(true);
  };

  const openHashtagModal = () => {
    setOpenEditModal('HASHTAGS');
  };

  const isEditingHashtagTags = () => (openEditModal === 'HASHTAGS');

  return (
    <>
      {isEditingHashtagTags() && (
        <HashtagsEditableForm
          refreshParent={() => setMadeChanges(true)}
          hashtagType="derived_hashtag_tags"
          closeEditingModal={closeEditingModal}
          hashtagTags={hashtagTags}
          tagged_json={taggedJson}
          modalTitle={`Editing ${company.name} - Expertise`}
        />
      )}
      {company.hashtags && company.hashtags.length > 0 && (
        <div className="card mb-3 animated faster fadeIn">
          <div className="card-body pb-2">
            <div className="fw-bold mb-3 pb-2 text-uppercase small border-bottom d-flex align-items-center">
              Experts on
              &nbsp;
              {company.editable && (
                <button
                  className="btn btn-primary btn-sm ms-auto"
                  style={{ maxHeight: '2rem', maxWidth: '2rem' }}
                  onClick={openHashtagModal}
                  type="button"
                >
                  <i className="fa-solid fa-pencil cursor-pointer" />
                </button>
              )}
            </div>

            <div className="row animated faster fadeIn ms-0 mb-3">
              <Hashtags
                hashtagTarget={company}
                maxHashtags={5}
                title={null}
                clickEventName="Companies/CompanyShow#clickHashtag"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HashtagList;
