import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch, SearchBox,
} from 'react-instantsearch-dom';
import ApprovedFilterPanel from './approved_filter_panel';
import AudienceFilterPanel from './audience_filter_panel';
import ClearRefinementsButton from '../../algolia_search/clear_refinements_button';
import CompanyFilterPanel from './company_filter_panel';
import GeoFilterPanel from './geo_filter_panel';
import InfiniteHits from './infinite_hits';
import LocationFilterPanel from './location_filter_panel';
import MostRecentFilterPanel from './most_recent_filter_panel';
import PublicationFilterPanel from './publication_filter_panel';

// import HashtagFilterPanel from './hashtag_filter_panel';

function JobPostingsSearch({
                             query, audience, mostRecent, approval, location, publication, company,
                             algoliaAppId, algoliaSearchKey, indexName,
                             userInternal, userType,
                           }) {
  const [clearGeoSearch, setClearGeoSearch] = useState(false);

  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

  const handleClearAllSearch = () => {
    setClearGeoSearch(true);
  };

  const handleClearGeoSearch = () => {
    setClearGeoSearch(false);
  };

  let defaultAudienceRefinement;
  let defaultApprovalRefinement;
  let defaultLocationRefinement;
  let defaultPublicationRefinement;
  let defaultCompanyRefinement;

  if (!userInternal) {
    defaultApprovalRefinement = ['approved'];
    if (userType === 'source') {
      defaultAudienceRefinement = ['pr_users'];
    } else {
      defaultAudienceRefinement = ['reporters'];
    }
  }

  if (audience) {
    defaultAudienceRefinement = [audience];
  }

  if (approval) {
    defaultApprovalRefinement = [approval];
  }

  if (location) {
    defaultLocationRefinement = [location];
  }

  if (publication) {
    defaultPublicationRefinement = [publication];
  }

  if (company) {
    defaultCompanyRefinement = [company];
  }

  return (
    <div className="ais-InstantSearch mt-3">
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
      >
        <div className="row">
          <div className="col-12 col-md-3 pr-0">
            {/* <MostRecentFilterPanel customClass={userInternal ? '' : 'd-none'} /> */}
            <AudienceFilterPanel
              customClass={userInternal ? '' : 'd-none'}
              defaultRefinement={defaultAudienceRefinement}
              audience={audience}
            />
            <MostRecentFilterPanel mostRecent={mostRecent} />
            <ApprovedFilterPanel
              customClass={userInternal ? '' : 'd-none'}
              defaultRefinement={defaultApprovalRefinement}
            />
            <GeoFilterPanel clearGeoSearch={clearGeoSearch} onGeoSearchCleared={handleClearGeoSearch} />
            <LocationFilterPanel defaultRefinement={defaultLocationRefinement} />
            <PublicationFilterPanel defaultRefinement={defaultPublicationRefinement} customClass={(userInternal || userType === 'reporter') ? '' : 'd-none'} />
            <CompanyFilterPanel defaultRefinement={defaultCompanyRefinement} customClass={(userInternal || userType === 'source') ? '' : 'd-none'} />
            {/* <HashtagFilterPanel /> */}
          </div>
          <div className="col">
            <div className="d-flex flex-row pl-3">
              <SearchBox
                autoFocus
                className="flex-grow-1 me-2"
                defaultRefinement={query}
                searchAsYouType={true}
                translations={{ placeholder: 'Search' }}
              />
              <ClearRefinementsButton
                buttonText="Clear"
                clearsQuery={true}
                onClear={handleClearAllSearch}
              />
            </div>
            <div className="row pl-3 mt-4">
              <InfiniteHits userInternal={userInternal} userType={userType} />
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

JobPostingsSearch.propTypes = {
  query: PropTypes.string,
  audience: PropTypes.string,
  mostRecent: PropTypes.bool,
  approval: PropTypes.string,
  location: PropTypes.string,
  publication: PropTypes.string,
  company: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  userInternal: PropTypes.bool,
  userType: PropTypes.string,
};

JobPostingsSearch.defaultProps = {
  query: '',
  audience: '',
  mostRecent: false,
  approval: '',
  location: '',
  publication: '',
  company: '',
  userInternal: undefined,
  userType: undefined,
};

export default JobPostingsSearch;
