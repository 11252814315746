// https://stackoverflow.com/questions/2112106/use-jquery-to-detect-container-overflow?noredirect=1&lq=1

// eslint-disable-next-line func-names
$.fn.hasVerticalOverflow = function () {
  const $this = $(this);
  const $children = $this.find('*');
  const len = $children.length;

  if (len) {
    let innerHeight = 0;
    // eslint-disable-next-line array-callback-return
    $children.map(() => {
      innerHeight += $(this).innerHeight();
    });

    return innerHeight > $this.height();
  }

  return false;
};
