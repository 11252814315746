import React from 'react';
import PropTypes from 'prop-types';

function OpportunityTwitterFeed(props) {
  const { screenName } = props;

  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5>Twitter Feed</h5>
      </div>
      <div className="card-body">
        { /* eslint-disable-next-line */}
        <a
          className="twitter-timeline"
          href={`https://twitter.com/${screenName}`}
          data-chrome="nofooter noheader noborders transparent"
        />
      </div>
    </div>
  );
}

OpportunityTwitterFeed.propTypes = {
  screenName: PropTypes.string.isRequired,
};

export default OpportunityTwitterFeed;
