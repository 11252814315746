import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  createContactInfo(contactInfo, contactableId, contactableType) {
    vlog.info('api#createContactInfo');

    api.configure();

    const url = '/api/internal/jsonapi/contact-infos';
    const contactable = { contactable_id: contactableId, contactable_type: contactableType };

    const data = {
      type: 'contact_infos',
      attributes: _.extend(contactInfo.attributes, contactable),
    };

    return axios.post(url, { data }, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },

  updateContactInfo(contactInfo) {
    vlog.info('api#updateContactInfo');

    api.configure();

    const url = contactInfo.links.self;

    const data = {
      id: contactInfo.id,
      type: 'contact_infos',
      attributes: contactInfo.attributes,
    };

    return axios.put(url, { data }, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },

  deleteContactInfo(contactInfo) {
    vlog.info('api#deleteContactInfo');

    api.configure();

    const url = contactInfo.links.self;

    return axios.delete(url, { data: contactInfo }, api.axiosFix);
  },

});
