import React from 'react';
import PropTypes from 'prop-types';

class FileInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      existingFiles: props.existingFiles || [],
      multiple: props.multiple,
    };

    this.baseInput = React.createRef();
  }

  handleUpload = (e) => {
    const input = e.target;
    const { files } = input;

    this.setState({ files }, this.handleChange);
  };

  handleChange = () => {
    // There can be two types of things uploaded:
    // * first, to create new attachments, a FileList
    // * second, to destroy existing attachments, a hash of this form: {id: 123, _destroy: true}
    //   see https://api.rubyonrails.org/classes/ActiveRecord/NestedAttributes/ClassMethods.html

    const existingFiles = this.state.existingFiles || [];
    const deleteExistingFiles = existingFiles.filter((i) => i._destroy);
    const railsAcceptNestedAttributesForDeleteFormat = deleteExistingFiles.map((i) => ({
      id: i.id,
      _destroy: true,
    }));

    const filesForUpload = this.state.files || [];

    let ary = [];
    if (filesForUpload.length > 0) {
      ary = [{ file_cloudinary: filesForUpload }];
    }

    const filteredFilesField = ary.concat(railsAcceptNestedAttributesForDeleteFormat);

    this.props.onChange && this.props.onChange(filteredFilesField);
  };

  handleFakeInputClick = () => {
    this.baseInput.current.click();
  };

  renderFakeInput = () => {
    const { files } = this.state;

    let btnText;
    if (files.length > 0) {
      btnText = 'Change attachments';
    } else {
      btnText = 'Attach file(s)';
    }

    return (
      <button
        type="button"
        className="btn btn-secondary btn-sm"
        onClick={this.handleFakeInputClick}
      >
        <i className="fa-solid fa-paperclip" /> {btnText}
      </button>
    );
  };

  renderBaseInput = () => {
    const { multiple } = this.state;
    const { name } = this.props;

    return (
      <input
        ref={this.baseInput}
        type="file"
        style={{ display: 'none' }}
        name={name}
        multiple={multiple}
        onChange={this.handleUpload}
      />
    );
  };

  renderFilePreupload = (file, index) => (
    <span className="ps-2 pe-1" key={index}>
      {file.name}
    </span>
  );

  handleExistingFileToggleDelete = (e) => {
    e.preventDefault();
    const attachmentId = parseInt(e.target.dataset.fileId, 10);
    const { existingFiles } = this.state;
    const newAry = Object.assign([], existingFiles);
    const index = newAry.findIndex((a) => a.id == attachmentId);
    const existingFileObj = newAry[index];
    existingFileObj._destroy = existingFileObj._destroy ? !existingFileObj._destroy : true;
    newAry[index] = existingFileObj;
    this.setState({ existingFiles: newAry }, this.handleChange);
  };

  renderFileExisting = (file, index) => {
    let btnClass = 'text-secondary';
    if (file._destroy) {
      btnClass = 'text-danger';
    }

    return (
      <button
        type="button"
        className={`ms-1 ps-2 pe-1 btn btn-link shadow-none ${btnClass}`}
        key={index}
        data-file-id={file.id}
        onClick={this.handleExistingFileToggleDelete}
      >
        {file.name}
      </button>
    );
  };

  renderClearButton = () => (
    <span
      className="pe-3 cursor-pointer"
      role="button"
      tabIndex="-1"
      onClick={this.clearInput}
      onKeyUp={(evt) => evt.keyCode === 13 && this.clearInput()}
    >
      <i className="fa-solid fa-xmark" />
    </span>
  );

  clearInput = () => {
    if (window.confirm('Clear files?')) {
      this.baseInput.current.value = '';
      this.setState({ files: [] });
    }
  };

  render() {
    const {
      existingFiles,
      files,
    } = this.state;

    return (
      <div>
        {this.renderBaseInput()}
        <div className="mb-1">
          {/* eslint-disable-next-line max-len */}
          {this.renderFakeInput()}{Array.from(files)
          .map((file, index) => this.renderFilePreupload(file, index))}{files.length > 0
          && this.renderClearButton()}
        </div>
        {existingFiles.length > 0
          && (
            <div>
              Existing <em>(click to toggle deletion):</em>
              {Array.from(existingFiles)
                .map((file, index) => this.renderFileExisting(file, index))}
            </div>
          )}
      </div>
    );
  }
}

FileInput.propTypes = {
  existingFiles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  multiple: PropTypes.bool,
  // NOTE: If name is blank, onChange is required, and vice versa.
  name: PropTypes.string,
  onChange: PropTypes.func,
};

FileInput.defaultProps = {
  existingFiles: undefined,
  multiple: undefined,
  name: undefined,
  onChange: undefined,
};

export default FileInput;
