// https://github.com/jquense/yup
// Dead simple Object schema validation

import * as yup from 'yup';

const companyYupSchema = yup.object()
  .shape({
    name: yup.string()
      .required('Name is required.'),
    overview: yup.string()
      .max(200, 'Headline must fewer than 200 characters. (Use About Us section for long text.)'),
    primary_website_url: yup
      .string()
      .url('Website must be a valid URL. (Including http:// or https://)'),
    primary_twitter_url: yup
      .string()
      .url('Twitter URL must be a valid URL. (Including http:// or https://)')
      .test(
        'valid-twitter-url',
        'Must be a valid Twitter profile URL',
        (value) => !value || /(www\.|)twitter.com\/.+/i.test(value),
      ),
    primary_linkedin_url: yup
      .string()
      .url('LinkedIn URL must be a valid URL. (Including http:// or https://)')
      .test(
        'valid-linkedin-url',
        'Must be a valid LinkedIn profile URL',
        (value) => !value || /(www\.|)linkedin.com\/.+/i.test(value),
      ),
    primary_facebook_url: yup
      .string()
      .url('Facebook URL must be a valid URL. (Including http:// or https://)')
      .test(
        'valid-facebook-url',
        'Must be a valid Facebook profile URL',
        (value) => !value || /(www\.|)facebook.com\/.+/i.test(value),
      ),
    show_twitter_feed: yup.boolean(),
  });

export default companyYupSchema;
