import React from 'react';
import { Panel, RefinementList } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

function PublicationFilterByNamePanel({ customClass }) {
  return (
    <div className={`card bg-transparent border-0 mb-3 ${customClass}`}>
      <div className="card-body ais-hide-counts">
        <Panel header="Appeared in Publication (Name)">
          <RefinementList
            attribute="publications.name"
            limit={6}
            showMore={true}
            showMoreLimit={15}
            searchable={true}
          />
        </Panel>
      </div>
    </div>
  );
}

PublicationFilterByNamePanel.propTypes = {
  customClass: PropTypes.instanceOf(Object),
};

PublicationFilterByNamePanel.defaultProps = {
  customClass: undefined,
};

export default PublicationFilterByNamePanel;
