import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import MenuBar from '../../directory/MenuBar';
import ProductCard from '../card/product_card';
import ProductSkeletonPage from './product_skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import SearchLaunchForm from '../../algolia_search/search_launch_form';

function ProductsIndex({ view }) {
  const perPage = 24;
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [refreshCount, setRefreshCount] = useState(0);
  const [products, setProducts] = useState([]);
  const [asyncError, setAsyncError] = useState();

  const forceReload = () => {
    setRefreshCount((prevCount) => prevCount + 1);
    setIsLoading(true);
    setProducts([]);
    setPage(1);
  };

  // Ignore scrollContainerRef since we want to use root as the scrolling container
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });

  // Effect to track infinite scrolling intersection observer event, which increments page
  useEffect(() => {
    if (lastTriggeredAt > 0) setPage((prevPage) => prevPage + 1);
  }, [lastTriggeredAt]);

  // Effect to track the page number and trigger fetch from API
  useEffect(() => {
    const fetchRecords = async () => {
      try {
        if (page === 1) setIsLoading(true);
        vahoy.track(`InfiniteProducts#loadPage_${page}`);

        const params = { page, per_page: perPage, favorited: 'y' };

        const response = await vapi.getProducts(params);
        if (response.status === 200) {
          const results = response.data;
          if (results.data && results.data.length > 0) {
            setProducts((prevRecords) => [...prevRecords, ...results.data]);
          }
          setHasMore(results.data.length >= perPage);
        }
        setIsLoading(false);
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchRecords();
  }, [page, perPage, refreshCount]);

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  return (
    <>
      <MenuBar view={view}>
        {{ searchForm: <SearchLaunchForm searchUrl="/products" className="d-flex me-3" /> }}
      </MenuBar>

      <h1 className="pt-2 mb-3">Saved Products</h1>

      <div className="mt-3">
        {!isLoading && products.length === 0 && (
          <div className="text-center p-4 fw-bold">
            <span>You haven't saved any products yet...</span>
            <br />
            <a href="/products">
              Try searching for products
            </a>
          </div>
        )}

        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 px-1">
          {isLoading && <ProductSkeletonPage numberOfCards={12} />}
          {!isLoading
            && products.map((product) => (
              <div className="col px-2 pb-3 pt-0" key={product.id}>
                <ProductCard
                  product={product.attributes}
                  displayContactAndInfo={true}
                  handleSaveProduct={forceReload}
                />
              </div>
            ))}
        </div>

        {hasMore && (
          <div
            ref={scrollTriggerRef}
            className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 px-1"
          >
            <ProductSkeletonPage numberOfCards={4} singleRow />
          </div>
        )}
      </div>
    </>
  );
}

ProductsIndex.propTypes = {
  view: PropTypes.string.isRequired,
};

export default ProductsIndex;
