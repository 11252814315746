import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch-dom';
import debounce from 'lodash.debounce';
import MenuBar from '../directory/MenuBar';
import useCombinedHits from './hooks/use_combined_hits';
import CombinedHits from './search_components/combined_hits';

function MultiIndexSearch({
  algoliaAppId,
  algoliaSearchKey,
  query,
  mainIndexName,
  expertsIndexName,
  pitchesIndexName,
  productsIndexName,
  pressReleasesIndexName,
}) {
  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);
  const [currentQuery, setCurrentQuery] = useState(query);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetCurrentQuery = useCallback(debounce(setCurrentQuery, 900), []);

  const { hits, hasMore, refineNext } = useCombinedHits(
    expertsIndexName,
    pitchesIndexName,
    productsIndexName,
    pressReleasesIndexName,
    searchClient,
    currentQuery,
  );

  useEffect(() => {
    setCurrentQuery(query || '');
  }, [query]);

  const handleSearchChange = useCallback((event) => {
    debouncedSetCurrentQuery(event.currentTarget.value);
  }, [debouncedSetCurrentQuery]);

  return (
    <div className="ais-InstantSearch unified-search mt-3">
      <InstantSearch searchClient={searchClient} indexName={mainIndexName}>
        <MenuBar view="storybuilder">
          {{
            searchForm: (
              <>
                <SearchBox
                  autoFocus
                  translations={{ placeholder: 'Search' }}
                  searchAsYouType={true}
                  defaultRefinement={currentQuery}
                  onChange={handleSearchChange}
                  onReset={() => setCurrentQuery('')}
                  className="d-flex w-100"
                />
              </>
            ),
          }}
        </MenuBar>
        <div className="content">
          <div className="results">
            <Configure query={currentQuery} />
            <CombinedHits hits={hits} hasMore={hasMore} refineNext={refineNext} />
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

MultiIndexSearch.propTypes = {
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  query: PropTypes.string,
  mainIndexName: PropTypes.string.isRequired,
  expertsIndexName: PropTypes.string.isRequired,
  pitchesIndexName: PropTypes.string.isRequired,
  productsIndexName: PropTypes.string.isRequired,
  pressReleasesIndexName: PropTypes.string.isRequired,
};

MultiIndexSearch.defaultProps = {
  query: '',
};

export default MultiIndexSearch;
