import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from '../error_boundary';
import PressReleasesIndex from './index';

function TopLevelIndex({
                         query, algoliaAppId, algoliaSearchKey, indexName,
                       }) {
  return (
    <ErrorBoundary>
      <PressReleasesIndex
        query={query}
        algoliaAppId={algoliaAppId}
        algoliaSearchKey={algoliaSearchKey}
        indexName={indexName}
      />
    </ErrorBoundary>
  );
}

TopLevelIndex.propTypes = {
  query: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
};

TopLevelIndex.defaultProps = {
  query: '',
};

export default TopLevelIndex;
