import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { useDebounce } from 'use-lodash-debounce';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';
import NoResultsMessage from '../../algolia_search/no_results_message';
import SearchLoadingCards from '../../source_requests/search/search_loading_cards';
import MediaMatchCard from './media_match_card';
import SourceRequestSkeletonPage from '../../source_requests/index/source_request_skeleton_page';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import FullPagePaywall from '../../source_requests/full_page_paywall';

function InfiniteHits({
  hasMore,
  refine,
  hits,
  hiddenSearchString,
  query,
  canPitchMediaMatch,
}) {
  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });
  const [qwotedRecords, setQwotedRecords] = useState({});
  const [idsToLookup, setIdsToLookup] = useState([]);
  const [asyncError, setAsyncError] = useState();
  const debouncedHits = useDebounce(hits, 700);
  const [showPaywall, setShowPaywall] = useState(false);

  useEffect(() => {
    if (lastTriggeredAt > 0) refine();
  }, [lastTriggeredAt, refine]);

  useEffect(() => {
    const missingIds = debouncedHits
      .filter((h) => qwotedRecords[h.objectID] === undefined)
      .map((h) => h.objectID);
    setIdsToLookup((prevList) => [...prevList, ...missingIds]);
  }, [debouncedHits, qwotedRecords]);

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        if (idsToLookup.length > 0) {
          vahoy.track('SourceRequestsSearch#getAlgoliaAttribs', { sourceRequestids: idsToLookup });
          const response = await vapi.getAlgoliaAttribsForIds(idsToLookup);
          if (response.status === 200) {
            const sourceRequestResults = response.data;
            if (sourceRequestResults.data && sourceRequestResults.data.length > 0) {
              const recordsToAdd = {};
              sourceRequestResults.data.forEach((sourceRequest) => {
                recordsToAdd[sourceRequest.id] = sourceRequest;
              });
              setIdsToLookup([]);
              setQwotedRecords((prevList) => Object.assign(recordsToAdd, prevList));
            }
          }
        }
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchRecords();
  }, [idsToLookup]);

  const onCardClick = (e) => {
    if (!canPitchMediaMatch) {
      e.preventDefault();
      setShowPaywall(true);
    }
  };

  if (asyncError) throw asyncError;

  return (
    <>
      {showPaywall && (
        <FullPagePaywall
          onClose={() => setShowPaywall(false)}
          isOpen={showPaywall}
        />
      )}
      <NoResultsMessage />
      {hits && hits.length > 0 && (
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 px-1">
          <SearchLoadingCards />
          {hits.map((hit) => (
            <div key={hit.objectID} className="col px-2 pb-3 pt-0">
              <MediaMatchCard
                match={hit}
                updates={qwotedRecords[hit.objectID]}
                hiddenSearchString={hiddenSearchString}
                query={query}
                onCardClick={onCardClick}
                canPitchMediaMatch={canPitchMediaMatch}
              />
            </div>
          ))}
        </div>
      )}

      {hasMore && (
        <div
          ref={scrollTriggerRef}
          className="row row-cols-1 row-cols-sm-1"
        >
          <SourceRequestSkeletonPage numberOfCards={12} singleRow />
        </div>
      )}
    </>
  );
}

InfiniteHits.propTypes = {
  hasMore: PropTypes.bool,
  refine: PropTypes.func,
  hits: PropTypes.arrayOf(Object),
  query: PropTypes.string,
  hiddenSearchString: PropTypes.string,
  canPitchMediaMatch: PropTypes.bool,
};

InfiniteHits.defaultProps = {
  hasMore: false,
  refine: undefined,
  hits: [],
  query: '',
  hiddenSearchString: '',
  canPitchMediaMatch: true,
};

export default connectInfiniteHits(InfiniteHits);
