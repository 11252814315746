import {
 useEffect, useState, useCallback, useRef,
} from 'react';
import vapi from '../../../javascript/frontend/api/vapi';
import vahoy from '../../../javascript/vahoy';

// eslint-disable-next-line
const useCombinedHits = (expertsIndexName, pitchesIndexName, productsIndexName, pressReleasesIndexName, searchClient, query) => {
  const [combinedHits, setCombinedHits] = useState([]);
  const [asyncError, setAsyncError] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const isFetchingRef = useRef(false);
  const mountedRef = useRef(true);
  const prevQueryRef = useRef(query);

  const fetchAugmentedHits = useCallback(async (hits, fetchFn, trackName) => {
    const ids = hits.map((hit) => hit.objectID);
    if (ids.length > 0) {
      try {
        vahoy.track(trackName, { requestids: ids });
        const response = await fetchFn(ids);
        if (response.status === 200) {
          const results = response.data.data;
          return hits.map((hit) => {
            const additionalData = results.find((data) => data.id === hit.objectID);
            return additionalData ? { ...hit, ...additionalData.attributes } : hit;
          });
        }
      } catch (error) {
        setAsyncError(error);
      }
    }
    return hits.map((hit) => ({ ...hit }));
  }, []);

  const fetchHits = useCallback(async (currentPage, currentQuery) => {
    if (isFetchingRef.current) return;
    isFetchingRef.current = true;

    try {
      const { results } = await searchClient.search([
        { indexName: expertsIndexName, query: currentQuery, page: currentPage },
        { indexName: pitchesIndexName, query: currentQuery, page: currentPage },
        { indexName: productsIndexName, query: currentQuery, page: currentPage },
        { indexName: pressReleasesIndexName, query: currentQuery, page: currentPage },
      ]);

      const experts = await fetchAugmentedHits(results[0].hits, vapi.getAlgoliaAttribsForSourceIds, 'SourcesSearch#getAlgoliaAttribs');
      const pitches = await fetchAugmentedHits(results[1].hits, async () => ({ status: 200, data: { data: [] } }), 'PitchesSearch#getAlgoliaAttribs');
      const products = await fetchAugmentedHits(results[2].hits, vapi.getAlgoliaAttribsForProductIds, 'ProductsSearch#getAlgoliaAttribs');
      const pressReleases = await fetchAugmentedHits(results[3].hits, async () => ({ status: 200, data: { data: [] } }), 'PressReleasesSearch#getAlgoliaAttribs');
      const newHits = [{ type: 'query', query: currentQuery }];
      const maxLength = Math.max(experts.length, pitches.length, products.length);

      Array.from({ length: maxLength }).forEach((_, i) => {
        if (experts[i]) newHits.push({ ...experts[i], type: 'expert' });
        if (pitches[i]) newHits.push({ ...pitches[i], type: 'pitch' });
        if (products[i]) newHits.push({ ...products[i], type: 'product' });
        if (pressReleases[i]) newHits.push({ ...pressReleases[i], type: 'press_release' });
      });

      if (mountedRef.current) {
        setCombinedHits((prevHits) => (currentPage === 0 ? newHits : [...prevHits, ...newHits]));
        setHasMore(experts.length > 0 || pitches.length > 0 || products.length > 0);
      }
    } catch (error) {
      if (mountedRef.current) setAsyncError(error);
    } finally {
      isFetchingRef.current = false;
    }
  }, [expertsIndexName, pitchesIndexName, productsIndexName, pressReleasesIndexName, searchClient, fetchAugmentedHits]);

  useEffect(() => {
    if (prevQueryRef.current !== query) {
      prevQueryRef.current = query;
      setPage(0);
      setCombinedHits([]); // Reset hits when query changes
      fetchHits(0, query);
    }
  }, [fetchHits, query]);

  useEffect(() => {
    if (page > 0) {
      fetchHits(page, query);
    }
  }, [fetchHits, page, query]);

  useEffect(() => () => {
      mountedRef.current = false;
    }, []);

  if (asyncError) throw asyncError;
  return { hits: combinedHits, hasMore, refineNext: () => setPage((prevPage) => prevPage + 1) };
};

export default useCombinedHits;
