import React from 'react';
import PropTypes from 'prop-types';
import Select2Ajax from './select2_ajax';
import Select2AddCompanyModal from './select2_add_company_modal';
import vahoy from '../javascript/vahoy';
import gaTracking from '../javascript/ga_tracking';

class Select2Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  processSelect2Result = (response) => {
    const results = (response.data || []).map((result) => {
      const r = { ...result };
      r.text = result.attributes.name;
      return r;
    });

    return {
      results,
      pagination: {
        more: !!(response.links || {}).next,
      },
    };
  };

  handleOnChange = (e) => {
    const {
      companyIdSelector,
      companyNameSelector,
      onChange,
      pageName,
    } = this.props;
    const companyId = jQuery(e.target).val();
    const companyName = jQuery(e.target).find('option:selected').text();

    jQuery(companyIdSelector || '#company_id').val(companyId);

    if (companyId === 'new') {
      jQuery(companyNameSelector || '#company_name').val(companyName);
    } else if (!companyId) {
      jQuery(companyNameSelector || '#company_name').val('');
    }

    onChange && onChange(companyId, companyName);

    gaTracking.trackSignUp(pageName, 'selectCompanyFromCompanySelect2', companyName, companyId);
    vahoy.track('Select2Company#selectCompanyFromCompanySelect2');
  };

  selectCompany = (id, name, company) => {
    if (name) {
      const { $select2 } = this;
      const {
        companyIdSelector,
        companyNameSelector,
        onChange,
        pageName,
      } = this.props;

      if ($select2) {
        const option = new Option(name, id, true, true);

        if (company) {
          // NOTE: Any better way?
          option.attributes.image_cloudinary_url = company.attributes.image_cloudinary_url;
        }

        $select2.append(option).trigger('change');
      }

      onChange && onChange(id, name);

      jQuery(companyIdSelector || '#company_id').val(id);
      jQuery(companyNameSelector || '#company_name').val(name);

      gaTracking.trackSignUp(pageName, 'selectCompanyFromCompanyModal', id, name);
      vahoy.track('Select2Company#selectCompanyFromCompanyModal');
    }

    this.toggleAddCompanyModal();
  };

  onAppendedBtnClick = ($select2) => {
    this.toggleAddCompanyModal();
    this.$select2 = $select2;
  };

  onSearching = (params) => {
    this.setState({ searchingTerm: params.term });

    return 'Searching…';
  };

  templateResult = (option) => {
    const imageUrl = (option.attributes || {}).image_cloudinary_url;
    const term = this.state.searchingTerm;
    let { text } = option;

    if (term && text) {
      const regexp = new RegExp(term, 'gi');
      text = text.replace(regexp, (match) => `<span class="matched-text">${match}</span>`);
    }

    if (!imageUrl) {
      return `<span class="option"><span class="option-image"></span><span class="option-name">${text}</span></span>`;
    }

    const ob = `<span class="option"><span class="option-image"><img src="${imageUrl}" alt="" /></span><span class="option-name">${text}</span></span>`;

    return ob;
  };

  templateSelection = (option) => {
    let imageUrl = (option.attributes || {}).image_cloudinary_url;

    // This is for option added via modal.
    if (!imageUrl && option.element && option.element.attributes) {
      imageUrl = option.element.attributes.image_cloudinary_url;
    }

    if (imageUrl) {
      return `<span class="selected-option"><span class="option-image"><img src="${imageUrl}" alt="" /></span><span class="option-name">${option.text}</span></span>`;
    }
    return `<span class="selected-option"><span class="option-name">${option.text}</span></span>`;
  };

  toggleAddCompanyModal = () => {
    this.setState((prevState) => ({ showAddCompanyModal: !prevState.showAddCompanyModal }));
  };

  render() {
    const {
      placeholderText, className, selectedCompany,
    } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    const ajaxUrl = this.props.ajaxUrl || '/api/internal/jsonapi/companies';
    const { showAddCompanyModal, searchingTerm } = this.state;

    // Select2Ajax expects it in this form
    const selectedOption = selectedCompany && {
      value: selectedCompany?.id,
      label: selectedCompany?.name,
    };

    return (
      <div>
        <Select2Ajax
          ajaxProcessResults={this.processSelect2Result}
          ajaxUrl={ajaxUrl}
          appendedBtnLabel="Don't see your company?"
          className={className || 'form-select'}
          existingOnly={true}
          onAppendedBtnClick={this.onAppendedBtnClick}
          onChange={this.handleOnChange}
          onSearching={this.onSearching}
          placeholderText={placeholderText || 'Company represented? Type to search ...'}
          selectedOption={selectedOption}
          templateResult={this.templateResult}
          templateSelection={this.templateSelection}
        />
        <Select2AddCompanyModal
          labelText="Please enter the name of the new company"
          modalTitle="Add new company"
          onHide={this.toggleAddCompanyModal}
          onSelect={this.selectCompany}
          placeHolderText="New company"
          searchingTerm={searchingTerm}
          showModal={showAddCompanyModal}
        />
      </div>
    );
  }
}

Select2Company.propTypes = {
  ajaxUrl: PropTypes.string,
  className: PropTypes.string,
  companyIdSelector: PropTypes.string,
  companyNameSelector: PropTypes.string,
  onChange: PropTypes.func,
  pageName: PropTypes.string,
  placeholderText: PropTypes.string,
  selectedCompany: PropTypes.instanceOf(Object),
};

Select2Company.defaultProps = {
  ajaxUrl: undefined,
  className: undefined,
  companyIdSelector: undefined,
  companyNameSelector: undefined,
  onChange: undefined,
  pageName: undefined,
  placeholderText: undefined,
  selectedCompany: undefined,
};

export default Select2Company;
