import React from 'react';
import PropTypes from 'prop-types';
import Hashtags from '../../hashtags';
import SmartHighlight from '../../algolia_search/smart_highlight';
import Logo from '../../logo';
import OverviewQuoteOrExpertList from './overview_quote_or_expert_list';

function CompanyCard({ company }) {
  const handleClick = (e) => {
    e.preventDefault();
    window.location.assign(company.company_path);
  };

  return (
    <div className="card company-card h-100 animated faster fadeIn" onClick={handleClick} aria-hidden="true">
      <div className="card-body position-relative">
        <div className="d-flex">
          <div className="flex-shrink-0">
            <Logo
              orgName={company.name}
              logoUrl={company.logo_url}
              style={{
                maxHeight: '80px',
                maxWidth: '120px',
              }}
            />
          </div>

          <div className="flex-grow-1 ms-3">
            <div>
              <h6 className="mb-2 fw-bold">
                <a href={company.company_path}>
                  <SmartHighlight attribute="name" hit={company} />
                </a>
              </h6>
            </div>
            <Hashtags
              hashtagTarget={company}
              maxHashtags={4}
              clickEventName="Companies/CompanyCard#clickHashtag"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <OverviewQuoteOrExpertList company={company} />
          </div>
        </div>
      </div>
    </div>
  );
}

CompanyCard.propTypes = {
  company: PropTypes.object.isRequired,
};

export default CompanyCard;
