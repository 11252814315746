import React from 'react';
import PropTypes from 'prop-types';
import InitialAttachEntity from './InitialAttachEntity';
import InitialAttachEntityType from './InitialAttachEntityType';

/**
 * Component: InitialAttachPrompt
 * Purpose:
 * - Presents different initial attachment options to users based on their profile type.
 * - For self-representing experts ('type_promoter_expert'), a single pre-existing profile option is displayed along
 *   with 'Add' and 'Unspecified' buttons to maintain simplicity and avoid inadvertent conversion to PRs.
 * - For PR users, a choice between 'Add Product' and 'Add Source' (Expert) is presented to encourage the addition of the
 *   appropriate entity type (Product pages) while minimizing confusion for self-representing experts.
 * - This approach aims to encourage PRs to add products while ensuring self-representing experts can add products or
 *   experts without unintentionally changing their user type.
 */
function InitialAttachPrompt({
 profileType, addExistingEntityToPitch, addNewEntityToPitch, setPromptInitialAttach,
}) {
  return profileType === 'self_representing_source' ? (
    <InitialAttachEntity
      addExistingEntityToPitch={addExistingEntityToPitch}
      addNewEntityToPitch={addNewEntityToPitch}
      onUnspecifiedButtonClick={() => setPromptInitialAttach(false)}
    />
  ) : (
    <InitialAttachEntityType
      addExistingEntityToPitch={addExistingEntityToPitch}
      addNewEntityToPitch={addNewEntityToPitch}
      onUnspecifiedButtonClick={() => setPromptInitialAttach(false)}
    />
  );
}

InitialAttachPrompt.propTypes = {
  profileType: PropTypes.string.isRequired,
  addExistingEntityToPitch: PropTypes.func.isRequired,
  addNewEntityToPitch: PropTypes.func.isRequired,
  setPromptInitialAttach: PropTypes.func.isRequired,
};

export default InitialAttachPrompt;
