document.addEventListener('turbolinks:load', () => {
  $(document).ready(() => {
    $('.add_field_button').click((e) => {
      e.preventDefault();
      $('.domains_wrapper').append('<div class="client_firm_domains mt-2"><input class="form-control" placeholder="example.com" type="text" name="client_firm[domains][]" id="client_firm_domains[]" /><a href="#" class="remove_field mt-1">Remove</a></div>');
    });

    // eslint-disable-next-line func-names
    $('.domains_wrapper').on('click', '.remove_field', function (e) {
      e.preventDefault();
      $(this).parent('div').remove();
    });
  });
});
