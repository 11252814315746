/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalBody, ModalFooter } from 'reactstrap';
import { CompanyContext } from '../data/company_context_management';
import useCompanyUpdateApi from '../data/use_company_update_api';
import SaveSpinnerButton from '../../save_spinner_button';
import companySchema from '../../../javascript/frontend/yup_schemas/company_schema';

function HeaderForm() {
  const context = useContext(CompanyContext);

  const {
    company,
    isUpdating,
    setOpenEditModal,
    setGeneralError,
  } = context;

  const { updateCompany } = useCompanyUpdateApi({ ...context });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(companySchema),
  });

  const closeModal = () => {
    setOpenEditModal(null);
    setGeneralError(null);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    updateCompany(data);
  };

  return (
    <>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3 row">
            <label htmlFor="name" className="col-sm-3 col-form-label">
              Company Name
            </label>
            <div className="col-sm-9">
              <input
                id="name"
                type="text"
                className="form-control"
                {...register('name')}
                defaultValue={company.name}
              />
              {errors?.name && (
                <span className="text-danger">{errors?.name?.message}</span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="visibility" className="col-sm-3 col-form-label">
              Profile Visibility
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                id="visibility"
                {...register('visibility')}
                defaultValue={company.visibility}
              >
                <option value="hidden">Hidden from all</option>
                <option value="reporters">Visible to reporters</option>
                <option value="public">Visible to public</option>
              </select>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="display_preference" className="col-sm-3 col-form-label">
              Display Style Preference
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                id="display_preference"
                {...register('display_preference')}
                defaultValue={company.display_preference}
              >
                <option value="none">Automatically selected by Qwoted algorithms</option>
                <option value="company">Standard: Showcase your own company's experts</option>
                <option value="pr_firm">PR Agency: Showcase your clients' experts</option>
              </select>
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="overview" className="col-sm-3 col-form-label">
              Headline
            </label>
            <div className="col-sm-9">
              <input
                id="overview"
                type="text"
                className="form-control"
                {...register('overview')}
                defaultValue={company.overview}
                placeholder="Catchy headline to display under company name."
              />
              {errors?.overview && (
                <span className="text-danger">{errors?.overview?.message}</span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="primary_website_url"
              className="col-sm-3 col-form-label"
            >
              Website
            </label>
            <div className="col-sm-9">
              <input
                id="primary_website_url"
                type="url"
                className="form-control"
                {...register('primary_website_url')}
                defaultValue={company.primary_website_url}
                placeholder="https://www.example.com"
              />
              {errors?.primary_website_url && (
                <span className="text-danger">
                  {errors?.primary_website_url?.message}
                </span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="primary_twitter_url"
              className="col-sm-3 col-form-label"
            >
              Twitter URL
            </label>
            <div className="col-sm-9">
              <input
                id="primary_twitter_url"
                type="text"
                className="form-control"
                {...register('primary_twitter_url')}
                defaultValue={company.primary_twitter_url}
                placeholder="https://www.twitter.com/example"
              />
              {errors?.primary_twitter_url && (
                <span className="text-danger">
                  {errors?.primary_twitter_url?.message}
                </span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="primary_linkedin_url"
              className="col-sm-3 col-form-label"
            >
              LinkedIn URL
            </label>
            <div className="col-sm-9">
              <input
                id="primary_linkedin_url"
                type="text"
                className="form-control"
                {...register('primary_linkedin_url')}
                defaultValue={company.primary_linkedin_url}
                placeholder="https://linkedin.com/companies/example"
              />
              {errors?.primary_linkedin_url && (
                <span className="text-danger">
                  {errors?.primary_linkedin_url?.message}
                </span>
              )}
            </div>
          </div>

          <div className="mb-3 row">
            <label
              htmlFor="primary_facebook_url"
              className="col-sm-3 col-form-label"
            >
              Facebook URL
            </label>
            <div className="col-sm-9">
              <input
                id="primary_facebook_url"
                type="text"
                className="form-control"
                {...register('primary_facebook_url')}
                defaultValue={company.primary_facebook_url}
                placeholder="https://www.facebook.com/example"
              />
              {errors?.primary_facebook_url && (
                <span className="text-danger">
                  {errors?.primary_facebook_url?.message}
                </span>
              )}
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <SaveSpinnerButton
          inactiveText="Save Changes"
          activeText="Saving..."
          isSaving={isUpdating}
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        />
        <button type="button" className="btn text-danger" onClick={closeModal}>
          Cancel
        </button>
      </ModalFooter>
    </>
  );
}

export default HeaderForm;
