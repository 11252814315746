// There are two forms: default_form, password_form. When viewing the /users/sign_up path, you initially interact with
// the default form.  If you click "or create an account" (below the "Sign Up with Twitter" button), then the
// password_form slides down. The two forms have some of the same components (the password_form has more).
// Certain changes made to the default_form, need to be simultaneously synced over to the password_form, so that if you
// click to switch to the password_form, you'll find it's got the same content as the form you just filled out.

// Swap out the default_form for the password_form
jQuery(document)
  .on('click', '.js-or-create-an-account', (event) => {
    event.preventDefault();
    jQuery('.js-default-signup-form')
      .slideUp();
    jQuery('.js-password-signup-form')
      .slideDown();
  });

// Keep the "I agree" checkbox in sync between the two forms
jQuery(document)
  .on('change', '.js-default-signup-form .js-i-agreed', (event) => {
    const checked = jQuery(event.currentTarget)
      .is(':checked');
    jQuery('.js-password-signup-form .js-i-agreed')
      .prop('checked', checked);
  });
