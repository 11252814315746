/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Logo from '../../logo';
import JobPostingHeader from './job_posting_header';

function JobPostingCard({
                          jobPosting,
                          userType,
                        }) {
  const hiringOrg = jobPosting.company || jobPosting.publication;

  let hiringOrgPath;
  if (jobPosting.company) {
    hiringOrgPath = hiringOrg.path;
  } else if (jobPosting.publication) {
    // eslint-disable-next-line no-script-url
    hiringOrgPath = 'javascript:;';
  }

  return (
    <div className="card h-100 pr-user-card animated faster fadeIn">
      <JobPostingHeader jobPosting={jobPosting} />
      <div className="card-body d-flex flex-column">

        <div className="mb-2" />

        <div>
          <div className="text-uppercase small mb-0">
            {jobPosting.location_string}
          </div>
          <a
            href={jobPosting.job_posting_path}
            className="font-weight-bold cursor-pointer fs-4"
          >
            {jobPosting.title}
          </a>
        </div>

        <div className="mt-auto pt-4 d-flex flex-row align-items-center">
          <a href={hiringOrgPath}>
            <Logo
              orgName={hiringOrg.name}
              logoUrl={hiringOrg.logo}
              style={{
                maxHeight: '55px',
                maxWidth: '80px',
              }}
            />
          </a>
          <div className="ps-2">
            {jobPosting.company && (
              <a href={jobPosting.company.path}>
                {jobPosting.company.name}
              </a>
            )}
            {jobPosting.publication && jobPosting.publication.name}
          </div>
        </div>
      </div>

      <div className="card-footer border-0 bg-transparent text-uppercase d-flex align-items-center pb-3">
        <div className="flex-grow-1 text-secondary font-size-12px">
          Posted&nbsp;{moment(jobPosting.created_at).format('MMMM D, YYYY')}
        </div>
        {(userType != null) && (
          <a href={jobPosting.job_posting_path} className="btn btn-primary font-weight-bold px-3">
            Apply
          </a>
        )}
      </div>
    </div>
  );
}

JobPostingCard.propTypes = {
  jobPosting: PropTypes.instanceOf(Object),
  userType: PropTypes.string,
};

JobPostingCard.defaultProps = {
  jobPosting: undefined,
  userType: undefined,
};

export default JobPostingCard;
