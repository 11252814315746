import React from 'react';
import PropTypes from 'prop-types';
import vapi from '../../../javascript/frontend/api/vapi';

import OrganizationSearch from './_search';
import OrganizationButton from './_button';
import OrganizationForm from './_form';
import OrganizationInfo from './_info';
import vahoy from '../../../javascript/vahoy';

class Organization extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organization: props.organization,
      showFormModal: false,
      showSearchModal: false,
    };

    this.removeOrganization = this.removeOrganization.bind(this);
    this.setOrganization = this.setOrganization.bind(this);
    this.raiseSearchModal = this.raiseSearchModal.bind(this);
    this.closeSearchModal = this.closeSearchModal.bind(this);
    this.raiseFormModal = this.raiseFormModal.bind(this);
    this.closeFormModal = this.closeFormModal.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleRemoval = this.handleRemoval.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.renderOrganizationSearch = this.renderOrganizationSearch.bind(this);
    this.renderOrganizationForm = this.renderOrganizationForm.bind(this);
  }

  handleSearchSubmit(contactId) {
    this.setOrganization(contactId, () => {
      this.setState({ showFormModal: false, showSearchModal: false });

      // eslint-disable-next-line react/destructuring-assignment
      this.props.refreshOpportunityContactEmployment();
    });

    this.closeSearchModal();
    vahoy.track('Opportunity/Organization/Organization#setOrganization');
  }

  handleRemoval() {
    this.setState({ showFormModal: false });
    this.removeOrganization();
  }

  handleImageUpload(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const { organization: updatedOrganization, refreshOpportunityContactEmployment } = this.props;
      updatedOrganization.attributes.image_cloudinary = reader.result;
      vapi.updateOrganization(updatedOrganization).then(() => {
        refreshOpportunityContactEmployment();
      });

      vahoy.track('Opportunity/Organization/Organization#organizationImageUpload');
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  setOrganization(organizationId, callback) {
    const { opportunity, refreshOpportunityContactEmployment } = this.props;
    vapi.setOpportunityOrganization(opportunity.id, organizationId)
      .then(() => {
        if (callback) callback();
        refreshOpportunityContactEmployment();
      });

    vahoy.track('Opportunity/Organization/Organization#setOpportunityOrganization');
  }

  // eslint-disable-next-line react/sort-comp
  removeOrganization() {
    const { opportunity, refreshOpportunityContactEmployment } = this.props;

    vapi.setOpportunityOrganization(opportunity.id, null)
      .then(() => {
        refreshOpportunityContactEmployment();
      });
  }

  raiseSearchModal() {
    this.closeFormModal();
    this.setState({ showSearchModal: true });
    vahoy.track('Opportunity/Organization/Organization#raiseSearchModal');
  }

  closeSearchModal() {
    this.setState({ showSearchModal: false });
  }

  raiseFormModal() {
    this.closeSearchModal();
    this.setState({ showFormModal: true });
    vahoy.track('Opportunity/Organization/Organization#raiseFormModal');
  }

  closeFormModal() {
    this.setState({ showFormModal: false });
  }

  renderOrganizationSearch() {
    const { contact, opportunity, organization } = this.props;
    const { showSearchModal } = this.state;

    if (window.CURRENT_USER_INTERNAL && showSearchModal) {
      return (
        <OrganizationSearch
          contact={contact}
          onHide={this.closeSearchModal}
          onNew={this.raiseFormModal}
          onSubmit={this.handleSearchSubmit}
          opportunity={opportunity}
          organization={organization}
          showModal={showSearchModal}
        />
      );
    }
  }

  renderOrganizationForm() {
    const { opportunity, contact_infos: contactInfos, refreshOpportunityContactEmployment } = this.props;
    const { showFormModal, organization } = this.state;

    if (window.CURRENT_USER_INTERNAL && showFormModal) {
      return (
        <OrganizationForm
          onHide={this.closeFormModal}
          onRemove={this.handleRemoval}
          setOrganization={this.setOrganization}
          organization={organization}
          opportunity={opportunity}
          contact_infos={contactInfos}
          refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
          showModal={showFormModal}
          toSearch={this.raiseSearchModal}
        />
      );
    }
  }

  render() {
    const { organization, contacts } = this.props;

    if (window.CURRENT_USER_INTERNAL || (organization || {}).id) {
      return (
        <div className="card mb-3">
          <div className="card-body">
            <div>
              {this.renderOrganizationSearch()}
              {this.renderOrganizationForm()}
              <OrganizationInfo
                contacts={contacts}
                organization={organization}
              />
              <div className="clearfix" />
              {window.CURRENT_USER_INTERNAL && (organization || {}).id
                && (
                  <div className="text-staff mb-2">
                    <hr className="hr-staff mt-1 mb-1" />
                    <small>
                      <label htmlFor="image_cloudinary">Add or replace Image</label>
                      <input type="file" name="image_cloudinary" onChange={this.handleImageUpload} />
                    </small>
                  </div>
                )}
              {window.CURRENT_USER_INTERNAL
                && (
                  <div>
                    <OrganizationButton
                      onAdd={this.raiseSearchModal}
                      onEdit={this.raiseFormModal}
                      organization={organization}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

Organization.propTypes = {
  contact: PropTypes.instanceOf(Object),
  organization: PropTypes.instanceOf(Object),
  opportunity: PropTypes.instanceOf(Object),
  refreshOpportunityContactEmployment: PropTypes.func,
  contacts: PropTypes.arrayOf(Object),
  contact_infos: PropTypes.arrayOf(Object),
};

Organization.defaultProps = {
  contact: undefined,
  organization: undefined,
  opportunity: undefined,
  refreshOpportunityContactEmployment: undefined,
  contacts: undefined,
  contact_infos: undefined,
};

export default Organization;
