import React from 'react';
import { Panel } from 'react-instantsearch-dom';
import RefinementListWithoutCount from '../../refinement_list_without_count';

function TypeFilterPanel() {
  const desiredOrder = ['Expert Requests', 'Product Requests', 'Media Shares'];

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <Panel header="Filter by type">
            <RefinementListWithoutCount
              attribute="request_type_for_algolia_faceting"
              operator="or"
              transformItems={// Algolia 4.14.2 doesn't support sortBy function so we have to use transformItems
                (items) => items.sort((a, b) => desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label))
              }
            />
          </Panel>
        </div>
      </div>
    </>
  );
}

export default TypeFilterPanel;
