import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import AttachableRepresentedEntity from './attach_entity/AttachableRepresentedEntity';
import AddNewButton from './attach_entity/AddNewButton';
import NewAttachedEntityForm from './attach_entity/NewAttachedEntityForm';
import UnspecifiedButton from './attach_entity/UnspecifiedButton';
import EntityTypeSelector from './attach_entity/EntityTypeSelector';
import { PitchWizardContext } from '../provider/pitch_wizard_provider';

function InitialAttachEntity({ addNewEntityToPitch, addExistingEntityToPitch, onUnspecifiedButtonClick }) {
  const [entityType, setEntityType] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const { promoEntities } = useContext(PitchWizardContext);

  const cancelAddNew = () => {
    setShowForm(false);
    setEntityType(null);
  };

  return (
    <div>
      <h3>Which to pitch?</h3>
      {!showForm
        && promoEntities
        && promoEntities.map((entity) => (
          <React.Fragment key={Math.random()}>
            <AttachableRepresentedEntity
              key={entity.promoted_entity_id}
              entity={entity}
              onClickCallback={addExistingEntityToPitch}
            />
          </React.Fragment>
        ))}
      <AddNewButton onClick={() => setShowForm(true)} onCancel={() => cancelAddNew()} active={showForm} />
      {showForm && (
        <>
          <br />
          {!entityType && (
            <EntityTypeSelector
              onClick={() => setEntityType('Source')}
              entityType="Source"
              active={entityType === 'Source'}
              onCancel={() => setEntityType(null)}
            />
          )}
          {!entityType && (
            <EntityTypeSelector
              onClick={() => setEntityType('Product')}
              entityType="Product"
              active={entityType === 'Product'}
              onCancel={() => setEntityType(null)}
            />
          )}
        </>
      )}
      {showForm && entityType && (
        <div style={{ maxWidth: '800px' }}>
          <NewAttachedEntityForm
            addNewEntityToPitch={addNewEntityToPitch}
            onCancel={() => cancelAddNew()}
            type={entityType}
          />
        </div>
      )}
      {!showForm && <UnspecifiedButton onClick={() => onUnspecifiedButtonClick(false)} />}
    </div>
  );
}

InitialAttachEntity.propTypes = {
  addNewEntityToPitch: PropTypes.func.isRequired,
  addExistingEntityToPitch: PropTypes.func.isRequired,
  onUnspecifiedButtonClick: PropTypes.func.isRequired,
};

export default InitialAttachEntity;
