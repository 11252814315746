import React, { useContext } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import HeaderForm from './header_form';
import MainContentForm from './main_content_form';
import { CompanyContext } from '../data/company_context_management';
import ContactInfoForm from './contact_info_form';
import PrUsersForm from './pr_users_form';
import CompanyTwitterFeedForm from './company_twitter_feed_form';
import PrAgenciesForm from './pr_agencies_form';
import ExpertsForm from './experts_form';
import ProductsForm from './products_form';

function EditModal() {
  const { company, openEditModal, setOpenEditModal } = useContext(CompanyContext);
  const closeModal = () => {
    setOpenEditModal(null);
  };

  const headerSectionLabel = () => {
    switch (openEditModal) {
      case 'HEADER':
        return ' - Header Details';
      case 'MAIN':
        return ' - Main Content';
      case 'CONTACT_INFO':
        return ' - Contact Info';
      case 'TWITTER_FEED':
        return ' - Twitter Feed';
      case 'PR_USERS':
        return ' - Our PR Team';
      case 'PR_AGENCIES':
        return ' - Our PR Firms';
      case 'EXPERTS':
        return ' - Company Experts';
      case 'PRODUCTS':
        return ' - Company Products';
      default:
        return null;
    }
  };

  return (
    // Hack to make the legacy hashtags editing modal work here... eventually
    // we'll rework that form to be like the others.
    <Modal isOpen={!!openEditModal && openEditModal !== 'HASHTAGS'} size="lg">
      <ModalHeader toggle={closeModal}>
        Editing {company.name} {headerSectionLabel()}
      </ModalHeader>
      {openEditModal === 'HEADER' && <HeaderForm />}
      {openEditModal === 'MAIN' && <MainContentForm />}
      {openEditModal === 'CONTACT_INFO' && <ContactInfoForm />}
      {openEditModal === 'TWITTER_FEED' && <CompanyTwitterFeedForm />}
      {openEditModal === 'PR_USERS' && <PrUsersForm />}
      {openEditModal === 'PR_AGENCIES' && <PrAgenciesForm />}
      {openEditModal === 'EXPERTS' && <ExpertsForm />}
      {openEditModal === 'PRODUCTS' && <ProductsForm />}
    </Modal>
  );
}

export default EditModal;
