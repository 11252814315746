import React, { useEffect, useState, useContext } from 'react';
import vahoy from '../../../javascript/vahoy';
import vapi from '../../../javascript/frontend/api/vapi';
import CompanySkeletonPage from '../company_skeleton_page';
import CompanyCard from '../card';
import useInfiniteScroll from '../../hooks/use_infinite_scroll';
import { CompanyContext } from '../data/company_context_management';

function CompanyBrands() {
  const perPage = 12;
  const { companyId, topLevelIsLoading } = useContext(CompanyContext);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [brands, setBrands] = useState([]);
  const [asyncError, setAsyncError] = useState();

  const [lastTriggeredAt, scrollTriggerRef] = useInfiniteScroll({ hasMore });

  // Effect to track infinite scrolling intersection observer event, which increments page
  useEffect(() => {
    if (lastTriggeredAt > 0) setPage((prevPage) => prevPage + 1);
  }, [lastTriggeredAt]);

  // Retrieve a PR agencies list of represented brands (client companies) from the API and track the page number
  useEffect(() => {
    let componentIsMounted = true;
    const fetchBrands = async () => {
      try {
        if (companyId) {
          if (page === 1) setIsLoading(true);
          vahoy.track('Companies#getBrands');

          const params = {
            company_id: companyId,
            page,
            per_page: perPage,
            clients_view: 'y',
          };

          const response = await vapi.getCompanies(params);
          if (componentIsMounted && response.status === 200) {
            const brandResults = response.data;
            if (brandResults.data && brandResults.data.length > 0) {
              setBrands((prevRecords) => [...prevRecords, ...brandResults.data]);
            }
            setHasMore(brandResults.data.length >= perPage);
          }
          if (componentIsMounted) setIsLoading(false);
        }
      } catch (error) {
        setAsyncError(error);
      }
    };
    fetchBrands();

    // Cleanup function to avoid the "Can't perform a React state update on an unmounted component"/memory leak problem
    return () => {
      componentIsMounted = false;
    };
  }, [companyId, page, perPage, topLevelIsLoading]);

  // Let top-level ErrorBoundary see any async errors
  if (asyncError) throw asyncError;

  return (
    <div className="col px-0">
      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3">
        {isLoading && (
          <CompanySkeletonPage numberOfCards={12} />
        )}
        {!isLoading && brands.map((brand) => (
          <div className="col mb-3" key={brand.id}>
            <CompanyCard company={brand.attributes} />
          </div>
        ))}
      </div>
      {hasMore && (
        <div
          ref={scrollTriggerRef}
          className="row row-cols-1 row-cols-md-2 row-cols-xl-3"
        >
          <CompanySkeletonPage numberOfCards={3} singleRow />
        </div>
      )}
    </div>
  );
}

export default CompanyBrands;
