import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';
import helper from '../helpers/helper';

_.extend(api, {
  loadReporters(params) {
    vlog.info('api#loadReporters');

    api.configure();

    const url = '/api/internal/jsonapi/reporters';

    return axios.get(url, { params }, api.axiosFix);
  },

  loadReporter(reporterId, params) {
    vlog.info('api#loadReporter');

    api.configure();

    const url = `/api/internal/jsonapi/reporters/${reporterId}`;

    return axios.get(url, { params }, api.axiosFix);
  },

  loadManagingReporterUser(reporterId, params) {
    vlog.info('api#loadManagingReporterUser');

    api.configure();

    const url = `/api/internal/jsonapi/reporters/${reporterId}/managing-reporter-user`;

    return axios.get(url, { params }, api.axiosFix);
  },

  // Updates reporter non-file attributes
  // For updating files like images, use updateReporterUsingFormData below
  updateReporter(reporter) {
    vlog.info('api#updateReporter');

    api.configure();

    const url = reporter.links.self;

    const data = {
      id: reporter.id,
      type: 'reporters',
      attributes: reporter.attributes,
    };

    return axios
      .put(url, { data }, api.axiosFix)
      .then((result) => result)
      .catch((errorResponse) => errorResponse);
  },

  // Updates files on Reporter object like profile image
  updateReporterUsingFormData(reporterId, reporter) {
    vlog.info('api#updateReporterUsingFormData');

    api.configure();

    const url = `/api/internal/reporters/${reporterId}`;
    const params = { reporter };
    const formData = helper.objectToFormData(params);

    return axios.put(url, formData, api.axiosFix);
  },

  createReporter(reporter) {
    vlog.info('api#createReporter');

    api.configure();

    const url = '/api/internal/jsonapi/reporters';

    const data = {
      type: 'reporters',
      attributes: reporter.attributes,
    };

    return axios
      .post(url, { data }, api.axiosFix)
      .then((result) => result)
      .catch((errorResponse) => errorResponse);
  },

  saveReporterHashtagOrders(reporterId, orders) {
    vlog.info('api#saveReporterHashtagOrders');

    api.configure();

    const url = `/api/internal/reporters/${reporterId}/save_hashtag_orders`;

    return axios.post(url, { orders }, api.axiosFix);
  },

  loadReporterContactInfos(reporterId, params) {
    vlog.info('api#loadReporterContactInfos');

    api.configure();

    const url = `/api/internal/jsonapi/reporters/${reporterId}/contact-infos`;

    return axios.get(url, { params }, api.axiosFix);
  },

  approveNewMedia(reporterId) {
    vlog.info('api#approveNewMedia');
    api.configure();

    const url = `/api/internal/reporters/${reporterId}/toggle_new_media_approval`;

    return axios.post(url, {}, api.axiosFix);
  },

  unapproveNewMedia(reporterId) {
    vlog.info('api#unapproveNewMedia');
    api.configure();

    const url = `/api/internal/reporters/${reporterId}/toggle_new_media_approval`;

    return axios.post(url, {}, api.axiosFix);
  },

  getAlgoliaAttribsForReporterIds(ids) {
    api.configure();
    const idStr = ids.join('&ids[]=');

    return axios.get(`/api/internal/reporters/algolia_attribs?ids[]=${idStr}`, {}, api.axiosFix);
  },

  getAlgoliaAttribsForNewMedia(ids) {
    api.configure();
    const idStr = ids.join('&ids[]=');

    return axios.get(`/api/internal/reporters/new_media_algolia_attribs?ids[]=${idStr}`, {}, api.axiosFix);
  },

});
