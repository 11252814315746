import React from 'react';
import PropTypes from 'prop-types';
import useFavorite from '../../hooks/use_favorite';

function FavoriteButton({
  favorited: initiallyFavorited,
  favoriteId: initialFavoriteId,
  favoriteableId,
  favoriteableType,
  ahoyObjectGid,
}) {
  const { isFavorited, toggleFavoritedStatus } = useFavorite({
    favoriteableId,
    favoriteableType,
    initiallyFavorited,
    initialFavoriteId,
    ahoyObjectGid,
  });

  const buttonIcon = isFavorited ? 'fa-minus' : 'fa-plus';
  const buttonColor = isFavorited ? 'danger' : 'primary';
  const buttonAction = isFavorited ? 'Remove from' : 'Add to';
  const buttonText = `${buttonAction} favorites`;

  return (
    <button
      type="button"
      onClick={toggleFavoritedStatus}
      className={`btn btn-outline-${buttonColor} fw-bold text-smallcaps animated faster fadeIn`}
      style={{ whiteSpace: 'normal', fontSize: '1em' }}
    >
      <span style={{ margin: 'auto', width: '100em' }}>
        <i className={`fa-solid ${buttonIcon} me-2`} title={buttonText} /> {buttonText}
      </span>
    </button>
  );
}

FavoriteButton.propTypes = {
  favoriteableId: PropTypes.number.isRequired, // Id of favoriting target
  favoriteableType: PropTypes.string.isRequired, // Object type of favoriting target (ex: 'PrUser')
  favoriteId: PropTypes.number, // Primary key of favorite record in the favorites table
  favorited: PropTypes.bool.isRequired, // Whether or not the target is currently favorited
  ahoyObjectGid: PropTypes.string.isRequired, // Global id for user being favorited
};

FavoriteButton.defaultProps = {
  favoriteId: undefined,
};

export default FavoriteButton;
