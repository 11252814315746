/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

class UpgradeSuccessModal extends React.Component {
  onCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  render() {
    const { showPopup, zeroPayment, currentUserId } = this.props;
    const actionTaken = currentUserId ? 'upgraded' : 'created';

    const successContent = (
      <div className="mt-3 ms-3">
        <h1 className="mb-3"><strong>Thanks!</strong></h1>
        <p>
          We've
          successfully {zeroPayment ? 'saved your credit card*' : 'processed your payment'} and {actionTaken} your
          account.
        </p>
        <p className="mb-4">
          You can start using the features of your {actionTaken} account immediately.
          {zeroPayment && (
            <div className="text-muted mt-2">
              <em>
                * We'll automatically begin charging your card on file once your free promotion ends.
              </em>
            </div>
          )}
        </p>
      </div>
    );

    return (
      <Modal isOpen={showPopup} toggle={this.onCancel}>
        <ModalBody>
          <div className="container-fluid">
            <div className="row">
              <div className="mb-4 col-lg-12">
                <button
                  type="button"
                  id="test-close-tweet-modal"
                  className="btn-close float-end"
                  style={{ position: 'relative', right: '-10px' }}
                  onClick={this.onCancel}
                />
                <div className="text-center pt-5">
                  <i
                    className={`fa-regular fa-circle-check text-success ${showPopup ? 'animated bounceInDown' : ''}`}
                    style={{ fontSize: '7rem' }}
                  />
                </div>
                {successContent}
              </div>
            </div>
            <div className="d-grid">
              <button
                type="button"
                onClick={this.onCancel}
                className={`btn btn-success btn-lg mb-3 ${showPopup ? 'animated fadeIn delay-1s' : ''}`}
              >
                Continue
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

UpgradeSuccessModal.propTypes = {
  showPopup: PropTypes.bool.isRequired, // Whether or not to show the modal popup
  onCancel: PropTypes.func.isRequired, // Function to call upon dismissal of the modal
  zeroPayment: PropTypes.bool.isRequired, // Indicates whether or not we're processing a zero payment
  currentUserId: PropTypes.number,
};

UpgradeSuccessModal.defaultProps = {
  currentUserId: undefined,
};

export default UpgradeSuccessModal;
