import React from 'react';
import { Panel, RefinementList } from 'react-instantsearch-dom';

function CompanyFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body ais-hide-counts">
        <Panel header="Filter by company">
          <RefinementList
            attribute="company_name"
            limit={6}
            operator="and"
            showMore={true}
            showMoreLimit={15}
            searchable={true}
          />
        </Panel>
      </div>
    </div>
  );
}

export default CompanyFilterPanel;
