import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ResponsiveAvatar from '../../responsive_avatar';
import { CompanyContext } from '../data/company_context_management';
import vapi from '../../../javascript/frontend/api/vapi';
import vlog from '../../../javascript/vlog';

function ProductForm({
                      product,
                      products,
                      setProducts,
                      setDoTopLevelFetch,
                    }) {
  const context = useContext(CompanyContext);
  const { company } = context;

  if (!products.length) return null;

  const companyProduct = product.attributes.company_products.find(
    (p) => p.company_id === company.objectID,
  );

  const handleProductDisplayChange = async (e) => {
    const target = e.target.id;
    let toggledHideValue;
    let toggledFeaturedValue;

    if (target.includes('hide')) {
      toggledHideValue = !companyProduct.company_page_hide;
      // Turn off Featured if Hide is turned on
      toggledFeaturedValue = toggledHideValue
        ? false
        : companyProduct.company_page_featured;
    } else if (target.includes('featured')) {
      // Turn off Hide if Featured is turned on
      toggledFeaturedValue = !companyProduct.company_page_featured;
      toggledHideValue = toggledFeaturedValue
        ? false
        : companyProduct.company_page_hide;
    } else {
      toggledHideValue = companyProduct.company_page_hide;
      toggledFeaturedValue = companyProduct.company_page_featured;
    }

    const params = {
      company_page_hide: toggledHideValue,
      company_page_featured: toggledFeaturedValue,
    };

    try {
      const response = await vapi.updateCompanyProduct(
        companyProduct,
        product.id,
        params,
      );

      const companyProductResponse = response.data.company_product;
      const productResponse = response.data.product.data;

      const productToUpdate = products.find((p) => p.id == productResponse.id);

      const companyProductToUpdate = productToUpdate.attributes.company_products.find(
        (se) => se.id == companyProductResponse.id,
      );

      companyProductToUpdate.company_page_hide = companyProductResponse.company_page_hide;
      companyProductToUpdate.company_page_featured = companyProductResponse.company_page_featured;

      setProducts([...products]);
      setDoTopLevelFetch(true);
    } catch (err) {
      alert('Sorry, something went wrong. Please try again.');
      vlog.warn('Error when attempting to update Product: ', err);
    }
  };

  return (
    <div className="row gx-0 w-100 mb-4">
      <div className="col-1 me-3">
        <ResponsiveAvatar
          avatarUrl={product.attributes.avatar_url}
          avatarLetters={product.attributes.name.substring(0, 2)}
          className="rounded"
        />
      </div>

      <div className="col-2 me-2">
        {product.attributes.name}
      </div>

      <div className="col ps-2">
        <div className="fw-bold font-size-14px">
          {product.attributes.full_name}
        </div>

        <div className="text-secondary font-size-14px">{product.attributes.title}</div>

        <div className="row pt-1">
          <div className="col-3">
            <div className="form-check form-switch">
              <input
                id={`hide-${product.attributes.objectID}`}
                type="checkbox"
                className="form-check-input"
                checked={companyProduct.company_page_hide}
                onChange={(e) => handleProductDisplayChange(e)}
              />
              <label
                className="form-check-label"
                htmlFor={`hide-${product.attributes.objectID}`}
              >
                Hide
              </label>
            </div>
          </div>

          <div className="col-3">
            <div className="form-check form-switch">
              <input
                id={`featured-${product.attributes.objectID}`}
                type="checkbox"
                className="form-check-input"
                checked={companyProduct.company_page_featured}
                onChange={(e) => handleProductDisplayChange(e)}
              />
              <label
                className="form-check-label"
                htmlFor={`featured-${product.attributes.objectID}`}
              >
                Feature
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductForm.propTypes = {
  setDoTopLevelFetch: PropTypes.func.isRequired,
  setProducts: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProductForm;
