import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import vapi from '../../javascript/frontend/api/vapi';

function IpAddress({ ip_addresses: ipAddresses }) {
  const [locations, setLocations] = useState([]);
  const [asyncError, setAsyncError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        vapi.geolocateIpAddress(ipAddresses)
          .then((response) => {
            const result = response.data;
            setLocations(result);
          });
      } catch (error) {
        setAsyncError(error);
      }
    };

    fetchData();
  }, [ipAddresses]);

  if (asyncError) throw asyncError;

  return (
    <ul>
      {locations.map((location) => (
        location.ip && location.ip !== '127.0.0.1' && (
          <li key={location.ip}>
            <a href={`https://ipinfo.io/${encodeURIComponent(location.ip)}`} target="_blank" rel="noopener noreferrer">{location.ip}</a>
            &nbsp;-&nbsp;
            {location.city && location.city.names && location.city.names.en && (
              `${location.city.names.en}, ${location.subdivisions[0].iso_code || ''}, ${location.country.iso_code || ''}`
            )}
          </li>
        )
      ))}
    </ul>
  );
}

IpAddress.propTypes = {
  ip_addresses: PropTypes.arrayOf(String).isRequired,
};

export default IpAddress;
