import React from 'react';
import PropTypes from 'prop-types';

import PressReleaseSkeletonCard from './skeleton_card';

function PressReleasesSkeletonPage({ numberOfCards, singleRow }) {
  const hideClass = (index) => {
    // Do some fancy footwork here to hide or display the proper number
    // of single-row skeleton cards based on number rendered and breakpoint.
    if (!singleRow) return '';

    switch (index) {
      case 1:
        return `d-none ${numberOfCards === 4 ? 'd-sm-block' : 'd-md-block'}`;
      case 2:
        return `d-none ${numberOfCards === 4 ? 'd-md-block' : 'd-xl-block'}`;
      case 3:
        return 'd-none d-xl-block';
      default:
        return '';
    }
  };

  return (
    <>
      {Array(numberOfCards).fill().map((_item, index) => (
        <div className={`col px-2 pb-3 pt-0 ${hideClass(index)}`} key={index}>
          <PressReleaseSkeletonCard />
        </div>
      ))}
    </>
  );
}

PressReleasesSkeletonPage.propTypes = {
  numberOfCards: PropTypes.number.isRequired,
  singleRow: PropTypes.bool,
};

PressReleasesSkeletonPage.defaultProps = {
  singleRow: false,
};

export default PressReleasesSkeletonPage;
