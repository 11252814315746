import React from 'react';
import Skeleton from 'react-loading-skeleton';

function PublicPitchSkeleton() {
  return (
    <div className="card m-3 d-flex public-pitch-card">
      <div className="card-header p-3 flex-grow-1 d-flex flex-column justify-content-between">
        <div className="d-flex">
          <div className="me-2">
            <Skeleton height={50} width={50} circle />
          </div>
          <div className="me-2 font-size-12px">
            <div className="fw-bold">
              <Skeleton height={16} width="60%" />
            </div>
            <div className="mt-1 public-pitch-author-footer">
              <Skeleton height={14} width={80} className="p-1 mb-1 me-1" />
              <Skeleton height={14} width={100} className="p-1 mb-1 me-1" />
            </div>
          </div>
        </div>
      </div>

      <div className="card-body p-3 d-flex flex-column justify-content-center flex-grow-0">
        <h5 className="fw-bold pb-2">
          <Skeleton height={18} width="60%" />
        </h5>
        <div className="small">
          <Skeleton height={14} width={80} className="p-1 mb-1 me-1" />
          <Skeleton height={14} width={120} className="p-1 mb-1 me-1" />
          <Skeleton height={14} width={100} className="p-1 mb-1 me-1" />
          <Skeleton height={14} width={100} className="p-1 mb-1 me-1" />
          <Skeleton height={14} width={80} className="p-1 mb-1 me-1" />
          <Skeleton height={14} width={120} className="p-1 mb-1 me-1" />
        </div>
      </div>
    </div>
  );
}

export default PublicPitchSkeleton;
