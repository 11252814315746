import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import SourceRequestsSearch from './search/source_requests_search';

function TopLevelSearch({
                          query,
                          featuresToken,
                          algoliaAppId,
                          algoliaSearchKey,
                          indexName,
                          reporterIndexName,
                          canPitchConnectionOpps,
                          showAds,
                          view,
                          delayedFeed,
                          feedDelayMinutes,
                          canViewInsights,
                        }) {
  return (
    <ErrorBoundary>
      <SourceRequestsSearch
        query={query}
        featuresToken={featuresToken}
        algoliaAppId={algoliaAppId}
        algoliaSearchKey={algoliaSearchKey}
        indexName={indexName}
        reporterIndexName={reporterIndexName}
        canPitchConnectionOpps={canPitchConnectionOpps}
        showAds={showAds}
        view={view}
        delayedFeed={delayedFeed}
        feedDelayMinutes={feedDelayMinutes}
        canViewInsights={canViewInsights}
      />
    </ErrorBoundary>
  );
}

TopLevelSearch.propTypes = {
  query: PropTypes.string,
  featuresToken: PropTypes.string.isRequired,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  reporterIndexName: PropTypes.string,
  canPitchConnectionOpps: PropTypes.bool,
  showAds: PropTypes.bool,
  view: PropTypes.string,
  delayedFeed: PropTypes.bool,
  feedDelayMinutes: PropTypes.number,
  canViewInsights: PropTypes.bool,
};

TopLevelSearch.defaultProps = {
  query: '',
  canPitchConnectionOpps: true,
  showAds: false,
  view: '',
  reporterIndexName: '',
  delayedFeed: false,
  feedDelayMinutes: 90,
  canViewInsights: false,
};

export default TopLevelSearch;
