import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Col, CardBody, CardHeader,
} from 'reactstrap';
import vlog from '../../javascript/vlog';

function MediaProfile({
                        mediaProfileId,
                        previousRequestTitle,
                        previousRequestId,
                        mediaOutletName,
                        mediaOutletProfileImageUrl,
                        trackClicks,
                      }) {
  const [v2MediaProfile, setV2MediaProfile] = useState(null);

  useEffect(() => {
    const fetchMediaProfile = async () => {
      try {
        // Fetch media profile data using Supabase
        const { data, error } = await window.supabaseClient
          .from('media_profiles')
          .select('*')
          .eq('profile_id', mediaProfileId);

        if (error) {
          vlog.error('Error fetching media profile:', error.message);
          return;
        }

        if (data.length > 0) {
          setV2MediaProfile(data[0]);
        } else {
          vlog.warn('No media profile found for ID:', mediaProfileId);
        }
      } catch (err) {
        vlog.error('Unexpected error fetching media profile:', err);
      }
    };

    fetchMediaProfile();
  }, [mediaProfileId]);

  if (!v2MediaProfile) {
    return null;
  }

  if (!v2MediaProfile.profile_image_url) {
    return null;
  }

  // Insert "/c_thumb,h_500,w_500" into the profile_image_url
  const modifiedProfileImageUrl = v2MediaProfile.profile_image_url.replace(
    '/image/upload/',
    '/image/upload/c_thumb,h_500,w_500/',
  );

  return (
    <Col md={4} className="d-flex mb-4">
      <div className="flex-fill d-flex flex-column">
        <Card className="mb-2 flex-fill text-start">
          <CardHeader className="bg-light text-primary text-uppercase text-center small">
            <h6 className="mb-0">Meet the Media</h6>
          </CardHeader>
          <CardBody className="d-flex flex-column align-items-start mt-2">
            <div className="d-flex align-items-center mb-3">
              {modifiedProfileImageUrl && (
                <img
                  src={modifiedProfileImageUrl}
                  alt={v2MediaProfile.name}
                  style={{
                    height: '60px',
                    width: '60px',
                    borderRadius: '50%',
                    marginRight: '15px',
                  }}
                />
              )}
              <div>
                <a
                  href={`/source_requests/${previousRequestId}`}
                  onClick={trackClicks}
                >
                  <h5 className="mb-0 fw-bold">{v2MediaProfile.name}</h5>
                </a>
                <h6>has written on your topics in the past. Introduce yourself!</h6>
              </div>
            </div>
            <div>
              <h6 className="mb-0 text-uppercase text-secondary font-size-12px">Previous Request</h6>
              <p>
                <a href={`/source_requests/${previousRequestId}`}>
                  {previousRequestTitle}
                </a>
              </p>
              <div className="clearfix" style={{ minHeight: '2.75rem' }} />
              {mediaOutletProfileImageUrl && (
                <div
                  className="small mb-3 d-flex align-items-center w-100 px-3"
                  style={{ position: 'absolute', bottom: '0', left: '0' }}
                >
                  Writes for publications like:
                  {mediaOutletProfileImageUrl.includes('dicebear.com') ? (
                    <span style={{ marginLeft: '10px' }}>{mediaOutletName}</span>
                  ) : (
                    <div className="flex-grow-1 text-center">
                      <img
                        src={mediaOutletProfileImageUrl}
                        alt={mediaOutletName}
                        style={{ maxHeight: '2rem', maxWidth: '60px' }}
                      />
                      <br />
                      <strong>{mediaOutletName}</strong>
                    </div>
                  )}
                </div>
              )}
            </div>
          </CardBody>
        </Card>
        <a
          href={`/source_requests/${previousRequestId}`}
          className="btn btn-outline-primary w-100"
          onClick={trackClicks}
        >
          Introduce yourself
        </a>
      </div>
    </Col>
  );
}

MediaProfile.propTypes = {
  mediaProfileId: PropTypes.number.isRequired,
  previousRequestId: PropTypes.number.isRequired,
  previousRequestTitle: PropTypes.string.isRequired,
  mediaOutletName: PropTypes.string,
  mediaOutletProfileImageUrl: PropTypes.string,
  trackClicks: PropTypes.func.isRequired,
};

MediaProfile.defaultProps = {
  mediaOutletName: undefined,
  mediaOutletProfileImageUrl: undefined,
};

export default MediaProfile;
