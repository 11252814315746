import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch, SearchBox,
} from 'react-instantsearch-dom';
import { orderBy } from 'lodash';
import MenuBar from '../../directory/MenuBar';
import InfiniteHits from './infinite_hits';
import BeatsFilterPanel from './beats_filter_panel';
import ClearRefinementsButton from '../../algolia_search/clear_refinements_button';
import HashtagFilterPanel from '../../source_requests/search/hashtag_filter_panel';
import SmartStats from '../../algolia_search/smart_stats';
import ExpertsFilterPanel from './experts_filter_panel';
import TitleFilterPanel from './title_filter_panel';
import PrAgencyFilterPanel from './pr_agency_filter_panel';
import CompanyTypeFilterPanel from './company_type_filter_panel';

function CompaniesSearch({
                           query, algoliaAppId, algoliaSearchKey, indexName,
                         }) {
  const [clearMenuToggle, setClearMenuToggle] = useState(false);
  const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

  const handleClearAllSearch = () => {
    setClearMenuToggle(true);
  };

  const handleClearMenuToggle = () => {
    setClearMenuToggle(false);
  };

  return (
    <div className="ais-InstantSearch mt-3">
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <MenuBar view="companies">
          {{
            searchForm: (
              <>
                <SearchBox
                  autoFocus
                  translations={{
                    placeholder: 'Search',
                  }}
                  searchAsYouType={true}
                  defaultRefinement={query}
                  className="d-flex me-3"
                />
                <ClearRefinementsButton
                  buttonText="Clear"
                  clearsQuery={true}
                  onClear={handleClearAllSearch}
                  bootstrapClasses="px-3 btn btn-sm btn-outline-info text-nowrap"
                />
              </>
            ),
          }}
        </MenuBar>

        <div className="row mb-2">
          <div className="col pe-4 text-end">
            <SmartStats />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-3 pe-0">
            <BeatsFilterPanel
              attribute="_tags"
              transformItems={(items) => orderBy(items, 'label', 'asc')}
              clearMenuToggle={clearMenuToggle}
              onMenuToggleCleared={handleClearMenuToggle}
            />
            <CompanyTypeFilterPanel />
            <ExpertsFilterPanel />
            <TitleFilterPanel />
            <PrAgencyFilterPanel />
            <HashtagFilterPanel />
          </div>
          <div className="col">
            <InfiniteHits />
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

CompaniesSearch.propTypes = {
  query: PropTypes.string,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
};

CompaniesSearch.defaultProps = {
  query: '',
};

export default CompaniesSearch;
