import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import InitialAttachPrompt from './InitialAttachPrompt';
import PitchComposer from './PitchComposer';
import { persistNewPromoEntity, deletePromoEntity, newPromoEntity } from '../services/promo_entity';
import completePitch from '../services/pitch';

function RootUnlocked({
                        cancelPath, conversationOnly, isEditing, pitch, redirectPath, requestId, profileType,
                      }) {
  const [attachedEntities, setAttachedEntities] = useState([]);
  const [attachedExistingFiles, setAttachedExistingFiles] = useState([]);
  const [attachedFileUploads, setAttachedFileUploads] = useState([]);
  const [compositionStarted, setCompositionStarted] = useState(false);
  const [promptInitialAttach, setPromptInitialAttach] = useState(true);
  const [pitchPublicizable, setPitchPublicizable] = useState(true);

  const isMounted = useRef(true);

  useEffect(() => {
    // Set the ref to true when the component mounts
    isMounted.current = true;

    // Cleanup function sets it to false when the component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Parse already attached promo entities from pitch
  useEffect(() => {
    if (pitch.pitched_entities) {
      setAttachedEntities(pitch.pitched_entities);
    }
    if (pitch.existing_files) {
      setAttachedExistingFiles(pitch.existing_files);
    }
    if (pitch.pitch) {
      setCompositionStarted(true);
    }
    setPitchPublicizable(pitch.pitch_publicizable === 'true');
  }, [pitch]);

  /**
   * Effect Hook: Controls the display of the initial attachment prompt.
   * - Sets `promptInitialAttach` to true when there are no attached entities and the composition has not started.
   *   We really want to encourage the creation and attachment of entities (profile pages).
   * - Once composition has started, `promptInitialAttach` is set to false.
   */
  useEffect(() => {
    if (attachedEntities.length === 0 && !compositionStarted) {
      setPromptInitialAttach(true);
    } else {
      setPromptInitialAttach(false);
    }
  }, [attachedEntities, compositionStarted]);

  const addExistingEntityToPitch = (promoEntity) => {
    setAttachedEntities([...attachedEntities, { existing: promoEntity }]);
  };

  const addNewEntityToPitch = (type, entity) => {
    const promoEntity = newPromoEntity(type, entity);
    setAttachedEntities([...attachedEntities, { new: promoEntity }]);
  };

  const deleteExistingEntityFromPitch = (id) => {
    const updatedEntities = attachedEntities.filter((entity) => entity.existing.id !== id);
    setAttachedEntities(updatedEntities);
  };

  const deleteNewEntity = (localId) => {
    const updatedEntities = attachedEntities.filter((entity) => entity.new?.localId !== localId);
    setAttachedEntities(updatedEntities);
  };

  const updateAndSendPitch = async (pitchText) => {
    if (isEditing) {
      // Determine difference between attachedEntities and pitch.pitched_entities. If any are in
      // pitch.pitched_entities, but not in attachedEntities.existing, their promo_entities need to be deleted.

      // Create a Set of IDs from attachedEntities for easier comparison
      const attachedIds = new Set(attachedEntities.map((item) => item.existing?.pitchedEntityId));

      // Find entities in pitchPitchedEntities that are not in attachedEntities
      const entitiesToDelete = pitch.pitched_entities?.filter(
        (item) => !attachedIds.has(item.existing?.pitchedEntityId),
      );
      const pitchedEntityIdsToDelete = new Set(entitiesToDelete.map((item) => item.existing.pitchedEntityId));

      const deletePromises = Array.from(pitchedEntityIdsToDelete).map(deletePromoEntity);
      await Promise.all(deletePromises);
    }

    const promoEntitiesWithNewEntitiesPersisted = await Promise.all(attachedEntities.map(persistNewPromoEntity));
    setAttachedEntities(promoEntitiesWithNewEntitiesPersisted);

    try {
      await completePitch(
        requestId,
        pitch.id,
        pitchText,
        pitchPublicizable,
        promoEntitiesWithNewEntitiesPersisted,
        attachedFileUploads,
      );
      window.location.href = redirectPath || '/';
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorKey = Object.keys(error.response.data.errors)[0];
        const errorMessage = error.response.data.errors[errorKey][0];
        alert(`Error: ${errorKey} ${errorMessage}`);
      } else {
        alert('Error!');
      }
    }
  };

  const handleDiscard = () => {
    if (window.confirm('Discard your edits?')) {
      window.location.href = cancelPath || '/source_requests';
    }
  };

  return (
    <div>
      {promptInitialAttach ? (
        <InitialAttachPrompt
          addExistingEntityToPitch={addExistingEntityToPitch}
          addNewEntityToPitch={addNewEntityToPitch}
          profileType={profileType}
          setPromptInitialAttach={setPromptInitialAttach}
        />
      ) : (
        <PitchComposer
          addExistingEntityToPitch={addExistingEntityToPitch}
          addNewEntityToPitch={addNewEntityToPitch}
          attachedEntities={attachedEntities}
          attachedExistingFiles={attachedExistingFiles}
          conversationOnly={conversationOnly}
          deleteExistingEntityFromPitch={deleteExistingEntityFromPitch}
          deleteNewEntity={deleteNewEntity}
          onDiscard={handleDiscard}
          pitchPublicizable={pitchPublicizable}
          pitchText={pitch.pitch}
          setAttachedFileUploads={setAttachedFileUploads}
          setCompositionStarted={setCompositionStarted}
          setPitchPublicizable={setPitchPublicizable}
          updateAndSendPitch={updateAndSendPitch}
          requestId={requestId}
        />
      )}
    </div>
  );
}

RootUnlocked.propTypes = {
  cancelPath: PropTypes.string.isRequired,
  conversationOnly: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  pitch: PropTypes.shape({
    existing_files: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string.isRequired,
    pitch: PropTypes.string.isRequired,
    pitch_publicizable: PropTypes.string.isRequired,
    pitched_entities: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  profileType: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
  requestId: PropTypes.number.isRequired,
};

export default RootUnlocked;
