import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import helper from '../helpers/helper';

function checkForIntercom(masonryGrid) {
  // The appearance of the Intercom happy face button sometimes causes a problem
  // with the masonry grid layout. We need to call masonryGrid.layout() one more
  // time to solve it. Problem: There isn't an Intercom event that triggers after
  // the loading of the button. This method is a workaround to let us detect when the
  // button loads and call masonryGrid.layout() explicitly.
  // The code is based on this discussion:
  // https://community.intercom.com/t/is-there-a-way-to-detect-if-intercom-messenger-is-available/940
  let retries = 0;
  const launcherExist = setInterval(() => {
    if ($('.intercom-launcher-frame').length) {
      clearInterval(launcherExist);
      masonryGrid.layout();
    }
    retries += 1;
    if (retries >= 4) {
      clearInterval(launcherExist);
      // Just in case
      masonryGrid.layout();
    }
  }, 500);
}

document.addEventListener('turbolinks:load', () => {
  if (helper.notPresent($('.masonry-grid'))) {
    return;
  }

  const grid = document.querySelector('.masonry-grid');

  const masonryGrid = new Masonry('.masonry-grid', {
    itemSelector: '.masonry-grid-item',
    columnWidth: '.masonry-grid-item',
  });

  imagesLoaded(grid)
    .on('always', () => {
      masonryGrid.layout();
    })
    .on('progress', () => {
      masonryGrid.layout();
    });
  checkForIntercom(masonryGrid);
});
