import React from 'react';
import { FormGroup, Button, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import ContactInfosReactFields from '../contact_info/contact_infos_react_fields';
import vahoy from '../../../javascript/vahoy';

class OrganizationContactInfoFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactInfos: props.contactInfos,
      selectedNewInfoType: 'facebook',
    };
  }

  changeSelectedNewInfoType = (e) => {
    this.setState({ selectedNewInfoType: e.target.value.toLowerCase() });
    vahoy.track('Opportunity/Organization/ContactInfoFields#changeSelectedNewInfoType');
  };

  addContactInfo = () => {
    const { contactInfos, selectedNewInfoType } = this.state;
    contactInfos.push({
      __private_id: Date.now(),
      attributes: { info_type: selectedNewInfoType, primary: false },
    });

    this.setState({ contactInfos });
    vahoy.track('Opportunity/Organization/ContactInfoFields#addContactInfo');
  };

  render() {
    const { contactInfos } = this.state;
    const { organization, refreshOpportunityContactEmployment } = this.props;

    return (
      <div>
        <FormGroup>
          <ContactInfosReactFields
            contact_infos={contactInfos.filter((info) => info.attributes.info_type == 'facebook')}
            refreshOpportunityContactEmployment={refreshOpportunityContactEmployment}
            contactable={organization}
            contactableType="Organization"
            placeholder="http://www.facebook.com/organization-facebook-page"
            info_type="facebook"
          />
        </FormGroup>
        <FormGroup>
          <div className="row">
            <div className="col-4">
              <Input
                defaultValue="facebook"
                onChange={this.changeSelectedNewInfoType}
                type="select"
              >
                <option name="facebook" id="info-type-facebook">Facebook</option>
              </Input>
            </div>
            <div className="col-4">
              <Button
                type="button"
                onClick={this.addContactInfo}
                onKeyUp={(evt) => evt.keyCode == 13 && this.addContactInfo(evt)}
              ><i className="fa-solid fa-plus me-2" />Add
                new
              </Button>
            </div>
          </div>
        </FormGroup>
      </div>
    );
  }
}

OrganizationContactInfoFields.propTypes = {
  organization: PropTypes.instanceOf(Object),
  contactInfos: PropTypes.arrayOf(Object).isRequired,
  refreshOpportunityContactEmployment: PropTypes.func.isRequired,
};

OrganizationContactInfoFields.defaultProps = {
  organization: undefined,
};

export default OrganizationContactInfoFields;
