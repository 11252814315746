import React from 'react';
import PropTypes from 'prop-types';
import { connectStateResults } from 'react-instantsearch-dom';

import PressReleasesSkeletonPage from './skeleton_page';

function SearchLoadingCards({ searchResults }) {
  const isLoading = !searchResults;

  if (!isLoading) return null;

  return (
    <PressReleasesSkeletonPage numberOfCards={12} />
  );
}

SearchLoadingCards.propTypes = {
  searchResults: PropTypes.instanceOf(Object),
};

SearchLoadingCards.defaultProps = {
  searchResults: undefined,
};

export default connectStateResults(SearchLoadingCards);
