import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../avatar/avatar';

function ExpertCard({
                      clickMode = 'add', source, sourceCardMode = 'card', onClick,
                    }) {
  const s = source.attributes;
  const sourceLink = source.links.frontend_source_profile;
  const companyLink = source.links.frontend_company_profile;

  const stopPropagationClick = (e) => {
    e.stopPropagation();
  };

  const renderCard = () => (
    <button type="button" className="card animated faster fadeIn p-0" onClick={onClick} onKeyDown={onClick}>
      <div className="card-body d-flex flex-row justify-content-start align-items-center">
        <div className="btn btn-link px-1 me-2 font-size-22px">
          {clickMode === 'add' && <i className="fa-solid fa-plus" />}
          {clickMode === 'remove' && <i className="fa-solid fa-xmark" />}
        </div>
        <Avatar
          avatarUrl={s.avatar_cloudinary_url}
          avatarLetters={s.initials}
          className="me-2 rounded-circle avatar-sm"
        />
        <div style={{ maxWidth: '170px', textAlign: 'left' }}>
          <h6 className="mb-1 fw-bold">
            <a href={sourceLink} target="_blank" onClick={stopPropagationClick} rel="noreferrer">
              {s.full_name}
            </a>
          </h6>
          {companyLink && (
            <div className="font-size-12px">
              {s.employments_string_without_company}
              &nbsp;at&nbsp;
              <a
                href={companyLink}
                target="_blank"
                onClick={stopPropagationClick}
                rel="noreferrer"
              >
                {s.company_name}
              </a>
            </div>
          )}
        </div>
      </div>
    </button>
  );

  const renderNameChip = () => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span className="badge bg-gray-300 text-dark" onClick={onClick} onKeyDown={onClick}>
      <button type="button" className="btn btn-link px-1 me-2">
        {clickMode === 'add' && <i className="fa-solid fa-plus" />}
        {clickMode === 'remove' && <i className="fa-solid fa-xmark" />}
      </button>
      <a href={sourceLink} target="_blank" onClick={stopPropagationClick} rel="noreferrer">
        {s.full_name}
      </a>
    </span>
  );

  if (sourceCardMode === 'nameChip') {
    return renderNameChip();
  }
  return renderCard();
}

ExpertCard.propTypes = {
  clickMode: PropTypes.string,
  source: PropTypes.object,
  sourceCardMode: PropTypes.string,
  onClick: PropTypes.func,
};

ExpertCard.defaultProps = {
  clickMode: 'add',
  source: undefined,
  sourceCardMode: 'card',
  onClick: undefined,
};

export default ExpertCard;
