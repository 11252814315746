import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  updatePublication(publication) {
    vlog.info('api#updatePublication');

    api.configure();

    const url = `/api/internal/jsonapi/publications/${publication.id}`;

    const data = {
      type: 'publications',
      id: publication.id,
      attributes: publication.attributes,
    };

    return axios.patch(url, { data }, api.axiosFix)
      .then((result) => result)
      .catch((errorResponse) => errorResponse);
  },

  searchPublications(params) {
    api.configure();
    const options = { params };

    return axios.get('/api/internal/jsonapi/publications', options, api.axiosFix);
  },
});
