import React from 'react';
import PropTypes from 'prop-types';

function SourceSubtypeSelect({
                               listSourceSubtypes,
                               setSourceSubtype,
                               sourceSubtype,
                             }) {
  return (
    <>
      <div className="col-sm-12 mb-2 mt-2">
        <h2 className="text-center h2-subtypes">Select One</h2>
      </div>

      {Object.entries(listSourceSubtypes).map(([key, value]) => {
        const activeClass = sourceSubtype === key ? 'active' : '';
        return (
          <div className="col-sm-12 mb-3" key={key}>
            <div className="d-grid">
              <button
                className={`btn btn-usertype btn-lg btn-rounded btn-outline-primary shadow-none ${activeClass}`}
                key={key}
                onClick={() => setSourceSubtype(key)}
                type="button"
              >
                {value}
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
}

SourceSubtypeSelect.propTypes = {
  listSourceSubtypes: PropTypes.object,
  setSourceSubtype: PropTypes.func,
  sourceSubtype: PropTypes.string,
};

SourceSubtypeSelect.defaultProps = {
  listSourceSubtypes: undefined,
  setSourceSubtype: undefined,
  sourceSubtype: undefined,
};

export default SourceSubtypeSelect;
