import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import placeholderImageSrc from '../assets/images/public-relations-professional-placeholder.png';
import vahoy from '../javascript/vahoy';
import vlog from '../javascript/vlog';

class ActuallyImAPrModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createPrProfilePath: props.create_pr_profile_path,
      formAuthenticityToken: props.form_authenticity_token,
      showPopup: false,
      sourceName: props.source_full_name,
    };
  }

  close = () => {
    this.setState({
      showPopup: false,
    });
  };

  showPopup = () => {
    this.setState({
      showPopup: true,
    });
    vahoy.track('ActuallyImAPrModal#sshowPopup');
  };

  createPrProfile = () => {
    try {
      vahoy.track('ActuallyImAPrModal#createProfile');
    } catch (error) {
      vlog.debug('Error with vahoy', error);
    }

    const { createPrProfilePath, formAuthenticityToken } = this.state;

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', createPrProfilePath);

    const input = document.createElement('input');
    input.setAttribute('name', 'authenticity_token');
    input.setAttribute('value', formAuthenticityToken);
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
  };

  render() {
    const {
      sourceName,
      showPopup,
    } = this.state;
    return (
      <div>
        <button
          type="button"
          className="btn btn-link btn-sm p-0"
          onClick={this.showPopup}
        >
          <small>
            Not {sourceName}?
          </small>
        </button>
        <Modal isOpen={showPopup} toggle={this.close} className="modal-has-title-bar">
          <div
            className="title-bar bg-light pt-2 pb-3"
            style={{ borderTopLeftRadius: '4.8px', borderTopRightRadius: '4.8px' }}
          />
          <div className="modal-header rounded-0 d-block position-relative">
            <div className="text-center pt-4 pb-4">
              <img src={placeholderImageSrc} alt="" className="img-fluid" />
            </div>
            <button
              type="button"
              className="btn-close position-absolute"
              aria-label="Close"
              onClick={this.close}
              style={{ top: '5px', right: '10px' }}
            />
          </div>

          <ModalBody className="">
            <div className="text-center pt-2 pb-2 ps-3 pe-3">
              <h3 className="mb-2">Are you a Public Relations professional?</h3>
              <p>
                Do you represent multiple sources to the media? If so, create your PR profile!
              </p>
              <p>
                This will allow you to create a profile page for yourself, as well as for all the
                sources you represent.
              </p>
            </div>
          </ModalBody>

          <ModalFooter className="text-center">
            <div className="pt-3 pb-3 w-100">
              <div>
                <Button color="primary" onClick={this.createPrProfile}>
                  Yes: I am a PR person
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

ActuallyImAPrModal.propTypes = {
  create_pr_profile_path: PropTypes.string.isRequired,
  form_authenticity_token: PropTypes.string.isRequired,
  source_full_name: PropTypes.string.isRequired,
};

export default ActuallyImAPrModal;
