import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
  useQuery,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import api from '../../../javascript/frontend/api/vapi';

const GET_REQUESTS = gql`
  query getRequestsCreatedByUserId($user_id: Int!) {
    sourceRequests(createdByUserId: $user_id, includeDraft: false) {
      nodes {
        id
        name
        shareWithSourcesUrl
        publishedAt
      }
    }
  }
`;

// This allows us to pass up the CSRF token, which from research, we do need.
// See: https://github.com/rmosolgo/graphql-ruby/pull/2524
// https://www.apollographql.com/docs/react/networking/authentication/
const httpLink = createHttpLink({
  uri: '/api_graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = api.getCSRFToken();

  return {
    headers: {
      ...headers,
      'X-CSRF-Token': token,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(), // caching allows the client to serve data that hasn't changed without doing another fetch
});

function AttachRequest({ request, setRequest, userId }) {
  const [requests, setRequests] = useState([]);

  useQuery(GET_REQUESTS, {
    variables: { user_id: userId },
    client,
    onCompleted: (data) => {
      const sourceRequests = data.sourceRequests.nodes;
      const requestsWithNullOption = [{ id: '', name: '', shareWithSourcesUrl: '' }].concat(sourceRequests);
      setRequests(requestsWithNullOption);
    },
  });

  const setRequestData = (id) => {
    let selectedRequest;

    requests.forEach((r) => {
      if (r.id === id) {
        selectedRequest = r;
      }
    });

    setRequest(selectedRequest);
  };

  return (
    <div className="mb-3">
      <label htmlFor="request" className="fw-bold">
        Attach a Request
      </label>

      <select
        className="form-control request"
        id="select_request"
        value={request?.id || ''}
        onChange={(e) => setRequestData(e.target.value)}
      >
        {requests.map((r) => <option value={r.id} key={r.id}>{r.name}</option>)}
      </select>
    </div>
  );
}

AttachRequest.propTypes = {
  request: PropTypes.object,
  setRequest: PropTypes.func,
  userId: PropTypes.number,
};

AttachRequest.defaultProps = {
  request: undefined,
  setRequest: undefined,
  userId: undefined,
};

export default AttachRequest;
