import React from 'react';
import PropTypes from 'prop-types';
import SaveButton from './save_button';
import RequestSampleButton from '../request_sample/button';

function ActionButtons({ product, handleSaveProduct }) {
  // This space between the icon and text is important because it creates the gap between the icon and text
  // when they are next to each other, but ensures the icon is not offset when they are stacked vertically
  // at certain screen sizes.
  const horizontalSpacer = ' ';
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const currentUser = window.CURRENT_USER_JSON ? window.CURRENT_USER_JSON : null;

  return (
    <>
      <a
        role="button"
        href={product.product_path}
        className="btn btn-primary fw-bold cursor-pointer w-100 mb-3"
      >
        View Product
      </a>
      <div className="d-flex">
        {product.messaging_url && (
          <a
            role="button"
            href={`${product.messaging_url}&back_path=${window.location.pathname}`}
            className="me-2 btn btn-sm qwoted-btn-xs btn-outline-primary animated faster fadeIn"
            title="Contact"
          >
            <i className="fa-regular fa-envelope align-middle" />
            {horizontalSpacer}
            <span className="align-middle d-none">Contact</span>
          </a>
        )}
        {product.favoriteable_id && (
          <SaveButton
            favoriteableId={product.favoriteable_id}
            favoriteableType="Product"
            favoriteId={product.favorite_id}
            favorited={product.favorited}
            ahoyObjectGid={product.object_gid}
            handleSaveProduct={handleSaveProduct}
          />
        )}

        {product.samples_available && currentUser?.attributes.user_type_humanized === 'reporter' && (
          <RequestSampleButton
            productName={product.name}
            productPrice={product.price ? USDollar.format(product.price) : undefined}
            productCompanyName={product?.company?.name}
            productImageUrl={product.image_url}
            productSgid={product.messaging_sgid}
          />
        )}
      </div>
    </>
  );
}

ActionButtons.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  handleSaveProduct: PropTypes.func,
};

ActionButtons.defaultProps = {
  handleSaveProduct: () => {},
};

export default ActionButtons;
