import React from 'react';
import PropTypes from 'prop-types';

function SectionSubtype({
  subtype, setSubtype, requestType, errorText, handleLogistics,
}) {
  if (requestType === 'story_ideas') {
    return null;
  }

  const updateSubType = (selectedSubType) => {
    // Reset logisticsDetails if user clicks on new subtype
    // setLogisticsDetails([]); // TODO
    setSubtype(selectedSubType);
    handleLogistics(true, false);
  };

  const humanizeString = (str) => str.replace('_', '/');

  const renderSubTypeButtons = () => {
    let options;
    switch (requestType) {
      case 'speakers':
        options = [
          { text: 'webinar', icon: <i className="fa-solid fa-circle-video" /> },
          { text: 'lecture', icon: <i className="fa-solid fa-podium" /> },
          { text: 'conference', icon: <i className="fa-solid fa-screen-users" /> },
        ];
        break;
      case 'guests':
        options = [
          { text: 'radio_podcast', icon: <i className="fa-solid fa-microphone" /> },
          { text: 'video_broadcast', icon: <i className="fa-solid fa-video" /> },
        ];
        break;
      case 'man_on_the_street':
        options = [
          { text: 'online_print', icon: <i className="fa-solid fa-newspaper" /> },
          { text: 'radio_podcast', icon: <i className="fa-solid fa-microphone" /> },
          { text: 'video_broadcast', icon: <i className="fa-solid fa-video" /> },
        ];
        break;
      default: // also 'experts'
        options = [
          { text: 'online_print', icon: <i className="fa-solid fa-newspaper" /> },
          { text: 'radio_podcast', icon: <i className="fa-solid fa-microphone" /> },
          { text: 'video_broadcast', icon: <i className="fa-solid fa-video" /> },
          { text: 'event', icon: <i className="fa-solid fa-calendar" /> },
        ];
    }

    return options.map((option) => (
      <button
        key={option.text}
        type="button"
        className={`btn btn-outline-primary text-uppercase me-2 mb-2 ${
          subtype === option.text ? ' active ' : null
        }`}
        style={{
          borderWidth: '2px',
          letterSpacing: '2px',
        }}
        onClick={() => updateSubType(option.text)}
      >
        {option.icon}
        <span style={{ marginLeft: '8px' }}>{humanizeString(option.text)}</span>
      </button>
    ));
  };

  return (
    <div className="section-subtype">
      {renderSubTypeButtons()}
      {errorText && <p className="form-text d-block text-danger">{errorText}</p>}
    </div>
  );
}

SectionSubtype.propTypes = {
  subtype: PropTypes.string,
  setSubtype: PropTypes.func.isRequired,
  requestType: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  handleLogistics: PropTypes.func.isRequired,
};

SectionSubtype.defaultProps = {
  subtype: 'experts',
  errorText: null,
};

export default SectionSubtype;
