import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  getOpportunityContact(opportunityId) {
    api.configure();

    const url = `/api/internal/jsonapi/opportunities/${opportunityId}/contact?include=contact_employments,organizations`;

    vlog.info(`api#getOpportunityContact: ${url}`);
    return axios.get(url, api.axiosFix);
  },

  createOpportunityContact(contact, opportunityId) {
    vlog.info('api#createOpportunityContact');

    if (!contact || !opportunityId) {
      throw new Error('api#createOpportunityContact missing contactAttrs or opportunityId');
    }

    api.configure();

    const url = '/api/internal/jsonapi/contacts';

    const data = {
      type: 'contacts',
      attributes: contact.attributes,
      relationships: {
        opportunities: {
          data: [{
            type: 'opportunities',
            id: opportunityId,
          }],
        },
      },
    };

    return axios.post(url, { data }, api.axiosFix);
  },

  setOpportunityContact(opportunityId, contactIdOrNull) {
    vlog.info('api#setOpportunityContact');

    api.configure();

    const url = `/api/internal/jsonapi/opportunities/${opportunityId}/relationships/contact`;

    let data;
    if (contactIdOrNull) {
      data = {
        type: 'contacts',
        id: contactIdOrNull,
      };
    } else {
      data = null;
    }

    return axios.patch(url, { data }, api.axiosFix);
  },
});
