import React from 'react';
import { Panel, RangeInput } from 'react-instantsearch-dom';

function PriceFilterDropdown() {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by price">
          <RangeInput attribute="price" />
        </Panel>
      </div>
    </div>
  );
}

export default PriceFilterDropdown;
