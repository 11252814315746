import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  updateContact(contact) {
    vlog.info('api#updateContact');

    api.configure();

    const url = contact.links.self;

    const data = {
      id: contact.id,
      type: 'contacts',
      attributes: contact.attributes,
    };

    return axios.put(url, { data }, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },

  createContact(contact) {
    vlog.info('api#createContact');

    api.configure();

    const url = '/api/internal/jsonapi/contacts';

    const data = {
      type: 'contacts',
      attributes: contact.attributes,
    };

    return axios.post(url, { data }, api.axiosFix).then((result) => result).catch((errorResponse) => errorResponse);
  },

  getOrganizationContacts(organizationId) {
    vlog.info('api#getOrganizationContacts');

    api.configure();

    const url = `/api/internal/jsonapi/organizations/${organizationId}/contacts`;

    return axios.get(url, api.axiosFix);
  },
});
