import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import vapi from '../../../javascript/frontend/api/vapi';

function Comments({ sourceRequest }) {
  const [asyncError, setAsyncError] = useState();
  const [text, setText] = useState('');
  const [sourceRequestComments, setSourceRequestComments] = useState([]);
  const [editedCommentId, setEditedCommentId] = useState(null);

  useEffect(() => {
    const params = {
      source_request_id: sourceRequest.id,
    };

    const getSourceRequestComments = async () => {
      try {
        const response = await vapi.getSourceRequestComments(params);
        setSourceRequestComments(response.data);
      } catch (error) {
        setAsyncError(error);
      }
    };
    getSourceRequestComments();
  }, [sourceRequest.id]);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleEdit = (commentId, commentText) => {
    setEditedCommentId(commentId);
    setText(commentText);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!text.trim()) {
      return;
    }

    if (editedCommentId) {
      const params = {
        comment: text,
      };

      vapi.updateSourceRequestComment(editedCommentId, params)
      .then((response) => {
        setSourceRequestComments(response.data);
      })
      .catch((error) => {
        alert('An error occurred while submitting the comment. Please try again later.');
        throw error;
      });
    } else {
      const params = {
        source_request_id: sourceRequest.id,
        comment: text,
      };

      vapi.createSourceRequestComment(params)
      .then((response) => {
        setSourceRequestComments(response.data);
      })
      .catch((error) => {
        alert('An error occurred while submitting the comment. Please try again later.');
        throw error;
      });
    }

    setText('');
    setEditedCommentId(null);
  };

  const handleDelete = async (commentId) => {
    try {
      await vapi.deleteSourceRequestComment(commentId);
      const updatedComments = sourceRequestComments.filter((comment) => comment.id !== commentId);
      setSourceRequestComments(updatedComments);
    } catch (error) {
      alert('An error occurred while deleting the comment. Please try again later.');
      throw error;
    }
  };

  const handleClear = () => {
    setText('');
    setEditedCommentId(null);
  };

  if (asyncError) throw asyncError;

  return (
    <div className="card-body">
      <h3>History</h3>
      <ul className="list-unstyled">
        {sourceRequestComments.slice(1).map((comment) => ( // starts from the 2nd record
          <li key={comment.id} className="list-group-item mb-1">
            <div className="row">
              <div className="col-3">
                {comment.user}<br />{moment(comment.created_at).format('MMM D - h:mma')}
              </div>
              <div className="col-7">
                {comment.comment}
              </div>
              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => handleEdit(comment.id, comment.comment)}
                >
                  <i className="fa-solid fa-pen-to-square" />
                </button>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => handleDelete(comment.id)}
                >
                  <i className="fa-solid fa-trash-can" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <h3>Comments</h3>
      <ul className="list-unstyled">
        {sourceRequestComments.length > 0 && ( // only render if there's at least 1 comment
          <li key={sourceRequestComments[0].id} className="list-group-item mb-1">
            <div className="row">
              <div className="col-3">
                {sourceRequestComments[0].user}<br />{moment(sourceRequestComments[0].created_at).format('MMM D - h:mma')}
              </div>
              <div className="col-7">
                {sourceRequestComments[0].comment}
              </div>
              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => handleEdit(sourceRequestComments[0].id, sourceRequestComments[0].comment)}
                >
                  <i className="fa-solid fa-pen-to-square" />
                </button>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => handleDelete(sourceRequestComments[0].id)}
                >
                  <i className="fa-solid fa-trash-can" />
                </button>
              </div>
            </div>
          </li>
        )}
      </ul>

      <form onSubmit={handleSubmit}>
        <div className="mb-1x">
          <div className="ps-1 my-1 my-lg-1">
            <div className="input-group">
              <input
                name="comment"
                type="text"
                className="form-control"
                value={text}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary mx-1 mb-2 my-2 my-lg-1" type="submit">
              Submit
            </button>
            <button
              type="button"
              className="btn btn-light mx-1 mb-2 my-2 my-lg-1"
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

Comments.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object).isRequired,
};

export default Comments;
