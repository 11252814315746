import axios from 'axios';
import _ from 'lodash';
import api from './api';

_.extend(api, {
  createSourceRepresentation(sourceId, userId) {
    api.configure();
    const url = '/api/internal/jsonapi/represented-sources';

    const data = {
      type: 'represented_sources',
      attributes: {
        source_id: sourceId,
        user_id: userId,
      },
    };

    return axios.post(url, { data }, api.axiosFix);
  },

  deleteSourceRepresentation(representationId) {
    api.configure();
    const url = `/api/internal/jsonapi/represented-sources/${representationId}`;
    return axios.delete(url, api.axiosFix);
  },

  getSourceRepresentingUsers(id) {
    api.configure();
    const url = `/api/internal/jsonapi/sources/${id}/represented-sources?include=user`;
    return axios.get(url, {}, api.axiosFix);
  },
});
