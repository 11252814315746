import React from 'react';
import PropTypes from 'prop-types';
import { connectStateResults, Stats } from 'react-instantsearch-dom';

function SmartStats({ searchState }) {
  const searchIsInProgress = () => {
    const queryExists = searchState.query && searchState.query.length > 0;
    let toggleExists = false;
    let refinementListExists = false;
    let menuSelectionExists = false;
    let geoSearchExists = false;
    let multiRangeExists = false;

    if (searchState.toggle) {
      toggleExists = Object.values(searchState.toggle).some((v) => v);
    }

    if (searchState.refinementList) {
      refinementListExists = Object.values(searchState.refinementList).some((v) => v);
    }

    if (searchState.menu) {
      menuSelectionExists = Object.values(searchState.menu).some((v) => v);
    }

    if (searchState.aroundLatLng) {
      geoSearchExists = Object.values(searchState.aroundLatLng).length > 0;
    }

    if (searchState.multiRange) {
      multiRangeExists = Object.values(searchState.multiRange).some((v) => v);
    }

    return queryExists || toggleExists || refinementListExists
      || menuSelectionExists || geoSearchExists || multiRangeExists;
  };

  return (
    <>
      {searchIsInProgress() && (
        <Stats />
      )}
      {!searchIsInProgress() && (
        <span>&nbsp;</span>
      )}
    </>
  );
}

SmartStats.propTypes = {
  searchState: PropTypes.instanceOf(Object).isRequired,
};

export default connectStateResults(SmartStats);
