import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';
import helper from '../helpers/helper';

_.extend(api, {
  updateOrganizationLogo(orgID, orgType, params) {
    vlog.info('api#updateOrganizationLogo');

    const pluralizedOrgType = {
      company: 'companies',
      organization: 'organizations',
      publication: 'publications',
    };

    api.configure();

    const url = `/api/internal/${pluralizedOrgType[orgType]}/${orgID}`;
    const conditionedParams = { [orgType]: params };
    const formData = helper.objectToFormData(conditionedParams);

    return axios.put(url, formData, api.axiosFix);
  },
});
