import helper from '../../helpers/helper';
import select2Helpers from '../../helpers/select2_helpers';

document.addEventListener('turbolinks:load', () => {
  const $jobPostingHashtagIds = $('#job_posting_hashtag_ids:visible');
  if (helper.present($jobPostingHashtagIds)) {
    select2Helpers.setupSelect2($jobPostingHashtagIds, '/api/internal/jsonapi/hashtags', (hashtag) => {
      const attrs = hashtag.attributes;

      if (hashtag.loading) {
        return hashtag.text;
      }

      return attrs ? attrs.hashtag : hashtag.text;
    });
  }
});
