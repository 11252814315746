import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error_boundary';
import CompanyShow from './show';
import { CompanyContextProvider } from './data/company_context_management';

function TopLevelShow({ companyId, userId }) {
  return (
    <CompanyContextProvider>
      <ErrorBoundary>
        <CompanyShow companyId={companyId} userId={userId} />
      </ErrorBoundary>
    </CompanyContextProvider>
  );
}

TopLevelShow.propTypes = {
  companyId: PropTypes.string.isRequired,
  userId: PropTypes.string,
};

TopLevelShow.defaultProps = {
  userId: undefined,
};

export default TopLevelShow;
