import React from 'react';
import PropTypes from 'prop-types';
import SmartSnippet from '../../algolia_search/smart_snippet';

function OverviewQuoteOrExpertList({ company }) {
  let attributeToDisplay;
  if (!company.overview && !company.about && !company.latest_quote && !company.expert_title_summary) return null;

  // Determine what to display based on whether we're displaying a search results and,
  // if so, where the match is.
  if (company._highlightResult) {
    // This is a search result, so look for any match highlights
    if (company._highlightResult.overview
      && company._highlightResult.overview.matchLevel !== 'none') {
      attributeToDisplay = 'overview';
    } else if (company._highlightResult.about
      && company._highlightResult.about.matchLevel !== 'none') {
      attributeToDisplay = 'about';
    } else if (company._highlightResult.latest_quote
      && company._highlightResult.latest_quote.matchLevel !== 'none') {
      attributeToDisplay = 'latest_quote';
    } else if (company._highlightResult.source_quotes) {
      const i = company._highlightResult.source_quotes.findIndex((q) => q.quote && q.quote.matchLevel !== 'none');
      if (i > -1) attributeToDisplay = `source_quotes[${i}].quote`;
    }
  }

  // Fall back to regular, non-search-result logic if we don't have an attribute yet
  if (!attributeToDisplay) {
    if (company.overview) {
      attributeToDisplay = 'overview';
    } else if (company.about) {
      attributeToDisplay = 'about';
    } else if (company.latest_quote) {
      attributeToDisplay = 'latest_quote';
    } else {
      attributeToDisplay = 'expert_title_summary';
    }
  }

  return (
    <div className="font-size-12px mt-2">
      {(attributeToDisplay === 'latest_quote') && (<span className="fw-bold">LATEST: </span>)}
      <em className="text-secondary">
        <SmartSnippet attribute={attributeToDisplay} hit={company} />
      </em>
    </div>
  );
}

OverviewQuoteOrExpertList.propTypes = {
  company: PropTypes.instanceOf(Object).isRequired,
};

export default OverviewQuoteOrExpertList;
