import React from 'react';
import PropTypes from 'prop-types';

function EditIcon(props) {
  const {
    isEditable, className = '', onClickFn, optionalText = '',
  } = props;

  if (isEditable) {
    return (
      <button
        className={`btn ${className}`}
        onClick={onClickFn}
        onKeyUp={(evt) => {
          evt.keyCode == 13 && onClickFn && onClickFn();
        }}
        tabIndex="-1"
        type="button"
      >
        <i
          className="fa-solid fa-pencil cursor-pointer"
        />
        {optionalText}
      </button>
    );
  }

  return (<></>);
}

EditIcon.propTypes = {
  className: PropTypes.string,
  isEditable: PropTypes.bool,
  onClickFn: PropTypes.func,
  optionalText: PropTypes.string,
};

EditIcon.defaultProps = {
  className: '',
  isEditable: undefined,
  onClickFn: undefined,
  optionalText: '',
};

export default EditIcon;
