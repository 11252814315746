import React from 'react';
import {
  Panel,
  ToggleRefinement,
} from 'react-instantsearch-dom';
import helper from '../../../javascript/frontend/helpers/helper';

function StatusFilterPanel() {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Panel header="Filter by status">
          {helper.currentUserInternal()
            && (
              <ToggleRefinement
                attribute="paid"
                label="Qwoted Client (admin only)"
                value={true}
                defaultRefinement={false}
              />
            )}
          <ToggleRefinement
            attribute="quick_to_respond"
            label="Quick to Respond"
            value={true}
            defaultRefinement={false}
          />
          <ToggleRefinement
            attribute="frequent_source"
            label="Frequent Source"
            value={true}
            defaultRefinement={false}
          />
          <ToggleRefinement
            attribute="off_the_record"
            label="On the Record"
            value={false}
            defaultRefinement={false}
          />
          <ToggleRefinement
            attribute="is_self_represented"
            label="Represents self (no PR)"
            value={true}
            defaultRefinement={false}
          />
        </Panel>
      </div>
    </div>
  );
}

export default StatusFilterPanel;
