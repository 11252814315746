import React, { useContext } from 'react';
import URI from 'urijs';
import TwitterFeed from '../../../twitter_feed';
import { CompanyContext } from '../../data/company_context_management';

function CompanyTwitterFeed() {
  const {
    company,
    setOpenEditModal,
  } = useContext(CompanyContext);

  if (company && company.primary_twitter_url && company.show_twitter_feed) {
    const uri = URI(company.primary_twitter_url);
    const twitter = uri.path()
      .replace('/', '');

    return (
      <div className="card mb-3 animated faster fadeIn">
        <div className="card-body pb-2">
          <div className="fw-bold mb-3 pb-2 text-uppercase small border-bottom d-flex align-items-center">
            Twitter Feed
            {company.editable && (
              <button
                className="btn btn-primary btn-sm ms-auto"
                style={{
                  maxHeight: '2rem',
                  maxWidth: '2rem',
                }}
                onClick={() => setOpenEditModal('TWITTER_FEED')}
                type="button"
              >
                <i className="fa-solid fa-pencil cursor-pointer" />
              </button>
            )}
          </div>
          <TwitterFeed screenName={twitter} />
        </div>
      </div>
    );
  }

  return null;
}

export default CompanyTwitterFeed;
