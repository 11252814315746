import React from 'react';
import Skeleton from 'react-loading-skeleton';

function CompanyHeaderSkeleton() {
  return (
    <div className="card animated faster fadeIn">
      <div className="card-body row" style={{ marginTop: '100px' }}>
        <div style={{ width: 'auto', marginTop: '-50px' }} className="py-0 px-4">
          <Skeleton height={159} width={159} />
        </div>
        <div className="col mt-3 p-0">
          <Skeleton height={30} width="50%" />
          <div className="mt-3">
            <Skeleton height={10} width="95%" />
            <Skeleton height={10} width="75%" />
          </div>
          <div className="mt-2">
            <Skeleton height={32} width={83} className="me-3" />
            <Skeleton height={30} width={30} circle className="me-2" />
            <Skeleton height={30} width={30} circle className="me-2" />
            <Skeleton height={30} width={30} circle />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyHeaderSkeleton;
