import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

function SectionDistribution({
                               dontPromoteOutside,
                               requestType,
                               setDontPromoteOutside,
                             }) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (requestType === 'story_ideas') {
    return null;
  }

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const handleChange = (e) => {
    const value = e.target.checked;
    setDontPromoteOutside(value);
  };

  return (
    <div className="mb-3">
      <div className="fw-bold">Distribution</div>
      <label className="form-check ps-0" style={{ display: 'inline' }}>
        <input
          id="dontPromote"
          type="checkbox"
          className="me-2"
          defaultChecked={dontPromoteOutside}
          onInput={(e) => handleChange(e)}
        />
        <span htmlFor="dontPromote">
          Do not promote this request on Qwoted's Twitter
        </span>
        <small>
          <button
            id="dontPromotePopover"
            type="button"
            className="btn btn-link ps-0 pt-0 ms-1"
          >
            <i className="fa-solid fa-circle-question" />
          </button>

          <Popover
            placement="auto"
            isOpen={popoverOpen}
            target="dontPromotePopover"
            toggle={togglePopover}
          >
            <PopoverHeader>What does this mean?</PopoverHeader>
            <PopoverBody>
              We may post an anonymized version of your source request on
              our Twitter feed, which widens the net considerably! Many
              reporters are finding success in this way. However, if you
              do not want your story idea posted on Twitter, check this
              box.
            </PopoverBody>
          </Popover>
        </small>
      </label>
    </div>
  );
}

SectionDistribution.propTypes = {
  dontPromoteOutside: PropTypes.bool,
  requestType: PropTypes.string,
  setDontPromoteOutside: PropTypes.func,
};

SectionDistribution.defaultProps = {
  dontPromoteOutside: undefined,
  requestType: undefined,
  setDontPromoteOutside: undefined,
};

export default SectionDistribution;
