import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

class Counter extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return { countTimeMoment: moment() };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { countTime } = this.props;

      const countTimeMoment = moment(countTime);

      this.setState({
        countTimeMoment,
      });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { countTimeMoment } = this.state;

    let duration;
    if (countTimeMoment.isAfter()) {
      duration = moment.duration(countTimeMoment - moment());
    } else {
      duration = moment.duration(moment() - countTimeMoment);
    }
    let formattedDuration = '';

    const days = duration.days();
    if (days) {
      const daysPluralized = days === 1 ? 'day' : 'days';
      formattedDuration = `${days} ${daysPluralized} and`;
    }

    const formattedHoursMinsSecs = moment.utc(duration.as('milliseconds'))
      .format('HH:mm:ss');
    formattedDuration = `${formattedDuration} ${formattedHoursMinsSecs}`;

    return (
      <span>{formattedDuration}</span>
    );
  }
}

Counter.propTypes = {
  countTime: PropTypes.string,
};

Counter.defaultProps = {
  countTime: undefined,
};

export default Counter;
