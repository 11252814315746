import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveAvatarUsingAspectRatio from '../../responsive_avatar_using_aspect_ratio';

function ProductHit({
  hit, currentQuery,
}) {
  // Late arriving attributes to be merged in, if any
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const maxDescriptionLength = 100;

  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    animation: 'fadeIn faster',
  };

  const avatarContainerStyle = {
    width: '100%',
    height: '100px', // Adjust the height as needed
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div className={`card h-100 product-card product-card-${hit.objectID} animated faster fadeIn`} style={cardStyle}>
      <div style={{ background: '#c1bce4' }} className="card-header text-black text-center fw-bold">
        <a className="text-black" title="Products" href="/products">Product Profile</a>
      </div>
      <div className="card-body">
        <div style={avatarContainerStyle}>
          <ResponsiveAvatarUsingAspectRatio
            avatarUrl={hit.image_url}
            emptyIcon="tag"
            altText={hit.name}
            linkHref={hit.product_path}
          />
        </div>
        <div className="d-flex flex-row justify-content-between">
          {hit.company && (
            <span className="d-block fs-5 fw-bold mt-3 mb-0">
              {hit.company.name}
            </span>
          )}
          {hit.price && <span className="mt-3 fs-3 text-secondary fw-bold">{USDollar.format(hit.price)}</span>}
        </div>

        <p className="mb-3" style={{ fontSize: '1.1em' }}>
          <a className="text-decoration-underline text-primary fw-bold" href={hit.product_path} alt={hit.name}>
            {hit.name}
          </a>
        </p>

        {hit.description && (
          <p className="m-0 lh-sm" style={{ fontSize: '.9em' }}>
            {hit.description.length > maxDescriptionLength ? `${hit.description.substring(0, maxDescriptionLength - 3)}...` : hit.description}
          </p>
        )}
      </div>
      <div className="clearfix" style={{ minHeight: '3.0rem' }} />
      <div className="mt-4 mb-2 row w-100" style={{ position: 'absolute', bottom: '40px', left: '15px' }}>
        <div className="col">
          <a
            href={hit.product_path}
            className="btn btn-primary btn-large"
          >
           <small> View Product </small>
          </a>
        </div>
      </div>
      <a className="mx-3 my-2 text-decoration-underline font-size-14px fw-bold pb-2" href={`/products?query=${currentQuery}`}>{`View ${currentQuery === '' ? 'all' : 'similar'} Products`}</a>
    </div>
  );
}

ProductHit.propTypes = {
  hit: PropTypes.instanceOf(Object).isRequired,
  currentQuery: PropTypes.string,
};

ProductHit.defaultProps = {
  currentQuery: '',
};

export default ProductHit;
