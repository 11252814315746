import React from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, Cell, Tooltip, ResponsiveContainer,
} from 'recharts';
import { connectRefinementList } from 'react-instantsearch-dom';

function TopicPieChart({ items }) {
  const COLORS = ['#8884d8', '#8dd1e1', '#ffc658', '#ff8042', '#82ca9d', '#d0ed57', '#a4de6c', '#d84d4d', '#d8d888', '#d88ed1'];

  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.14;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const color = items[index].isRefined ? '#745ff1' : COLORS[index % COLORS.length];
    const textAnchor = x > cx ? 'start' : 'end';
    const text = `${items[index].label}`;

    const textWidth = text.length * 9;
    const textHeight = 30;

    return (
      <>
        <rect
          x={textAnchor === 'start' ? x - 1 : x - (textWidth - 1)}
          y={y - textHeight / 2}
          width={textWidth}
          height={textHeight}
          fill="white"
          rx="10"
          ry="10"

        />
        <text
          x={x}
          y={y}
          fill={color}
          fontWeight="bold"
          textAnchor={textAnchor}
          dominantBaseline="central"
          fontSize="12px"
        >
          {text}
        </text>
      </>
    );
  };

  const handleBarClick = (value) => {
    const url = new URL(window.location.href);
    const cleanedValue = value.replace('#', '');
    url.searchParams.set('query', cleanedValue);
    window.location.href = url.toString();
  };

  return (
    <div style={{ width: '100%', height: '25vh', position: 'relative' }}>
      <ResponsiveContainer width="100%" height="100%" minHeight="200px" minWidth="200px">
        <PieChart width={400} height={400}>
          <Pie
            data={items}
            cx="50%"
            cy="50%"
            innerRadius={10}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="count"
            nameKey="label"
            label={renderCustomizedLabel}
          >
            {items.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.isRefined ? '#745ff1' : COLORS[index % COLORS.length]} onClick={() => handleBarClick(entry.value[0])} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
       </ResponsiveContainer>
    </div>
  );
}

TopicPieChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
};

const ConnectedTopicPieChart = connectRefinementList(TopicPieChart);

export default ConnectedTopicPieChart;
