import React from 'react';
import PropTypes from 'prop-types';

function HiddenToggle({ showHidden, setShowHidden }) {
  return (
<label className="text-staff text-nowrap">
    <input
      checked={showHidden}
      onChange={(event) => setShowHidden(event.currentTarget.checked)}
      type="checkbox"
    />
    &nbsp;
    Hidden
  </label>
);
}

HiddenToggle.propTypes = {
  showHidden: PropTypes.bool.isRequired,
  setShowHidden: PropTypes.func.isRequired,
};

export default HiddenToggle;
