import React, { useState } from 'react';
import PropTypes from 'prop-types';
import vahoy from '../../javascript/vahoy';
import FlagModal from './flag_modal';
import vapi from '../../javascript/frontend/api/vapi';

function FlagButton(props) {
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState('');
  const [behaviorTypes, setBehaviorTypes] = useState();

  const closeModal = () => {
    setShowModal(false);
  };

  const handleClick = () => {
    setShowModal(true);
    vahoy.track('FlagButton#showModal');
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const submitReport = () => {
    const reportedBehaviorsKeyArray = behaviorTypes || [];

    const params = {
      // alphabetized
      report_description: description,
      reported_behavior_types: reportedBehaviorsKeyArray,
      origin_ujswidget: 'spam_flag',
      user: {
        spam_flagged_at: props.spam_flagged_at,
        spam_flagged_count: props.spam_flagged_count,
      },
    };

    vapi.flagUser(props.flagged_user_id, params)
      .then(() => {
        closeModal();
        window.location.reload();
      }).catch((error) => {
      alert('An error occurred while submitting the report. Please try again later.');
      throw error; // Rethrow the error
    });

    vahoy.track('FlagModal#reportAbuse');
  };

  const renderButton = () => (
    <>
      <button
        className="btn btn-outline-secondary"
        onClick={handleClick}
        type="button"
      >
        Spam flag
      </button>
      <FlagModal
        abusive_behavior_types={props.abusive_behavior_types}
        behavior_types={behaviorTypes}
        close_modal={closeModal}
        handle_description_change={handleDescriptionChange}
        set_behavior_types={setBehaviorTypes}
        show_modal={showModal}
        submit_report={submitReport}
      />
    </>
  );

  return renderButton();
}

FlagButton.propTypes = {
  spam_flagged_at: PropTypes.string,
  spam_flagged_count: PropTypes.number,
  flagged_user_id: PropTypes.number,
};

FlagButton.defaultProps = {
  spam_flagged_at: undefined,
  spam_flagged_count: undefined,
  flagged_user_id: undefined,
};

export default FlagButton;
