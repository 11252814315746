import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import vahoy from '../javascript/vahoy';
import vlog from '../javascript/vlog';

function WebPushSubscribe({ message }) {
  const [showModal, setShowModal] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [optedIn, setOptedIn] = useState(null);
  const [permission, setPermission] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [refusalData, setRefusalData] = useState({
    refusalCount: 0,
    lastRefusedAt: null,
  });
  const [oneSignalLoaded, setOneSignalLoaded] = useState(false);

  useEffect(() => {
    const v2HealthCheck = async () => {
      const passed = await window.v2HealthCheck();
      if (!passed) {
        vlog.debug('Component not ready: V2 health check did not pass.');
        setLoading(false);
        setError(true);
      }
    };

    v2HealthCheck();
  }, []);

  const REFUSAL_STORAGE_KEY = 'webPushRefusal';
  const coolDownPeriodSeconds = 14 * 24 * 60 * 60; // Base cool-down period: 14 days in seconds
  // const coolDownPeriodSeconds = 1; // Use for debugging

  vlog.debug('WebPushSubscribe component initialized');

  // Function to update the status (used in the `onLogin` logic)
  const updateStatus = async () => {
    if (!window.OneSignal) return;

    const isOptedIn = await window.OneSignal.User.PushSubscription.optedIn;
    const perm = await window.OneSignal.Notifications.permission;
    vlog.debug(`Updating subscription status; isOptedIn: ${isOptedIn}, perm: ${perm}`);

    setOptedIn(isOptedIn);
    setPermission(perm);
  };

  // Function to calculate if the user should be prompted based on exponential backoff
  const shouldPrompt = useCallback(() => {
    const { refusalCount, lastRefusedAt } = refusalData;
    vlog.trace('Evaluating shouldPrompt', { refusalCount, lastRefusedAt });

    if (!lastRefusedAt) {
      vlog.debug('No prior refusals, prompting allowed');
      return true;
    }

    // Calculate the backoff time in seconds based on the refusal count.
    // coolDownPeriodSeconds is the base cool-down period, representing one week in seconds.
    // For refusalCount = 0, backoffSeconds = 1 week (initial prompt after no refusals).
    // For refusalCount = 1, backoffSeconds = 2 weeks (double the initial period).
    // For refusalCount = 2, backoffSeconds = 4 weeks (double the previous period).
    // For refusalCount = 3, backoffSeconds = 8 weeks, and so on, doubling each time.
    // This creates an exponential backoff for prompting, ensuring users are asked less frequently over time.
    const backoffSeconds = coolDownPeriodSeconds * 2 ** refusalCount;
    const nextPromptTime = new Date(new Date(lastRefusedAt).getTime() + backoffSeconds * 1000);
    const canPrompt = new Date() >= nextPromptTime;

    vlog.debug('Calculated next prompt time', {
      backoffSeconds,
      nextPromptTime,
      canPrompt,
    });

    return canPrompt;
  }, [coolDownPeriodSeconds, refusalData]);

  // Effect to listen for the `qwotedOneSignalLoggedIn` event
  useEffect(() => {
    vlog.debug('Setting up OneSignal loaded event listener');

    const onLogin = () => {
      vlog.trace('OneSignal logged in event triggered');
      window.OneSignal.User.PushSubscription.addEventListener('change', () => {
        vlog.debug('PushSubscription change detected');
        updateStatus();
      });
      updateStatus();
    };

    const handleOneSignalLoaded = () => {
      vlog.info('OneSignal loaded');
      setOneSignalLoaded(true);
      window.removeEventListener('qwotedOneSignalLoggedIn', onLogin); // Cleanup to avoid duplicate listeners
    };

    window.addEventListener('qwotedOneSignalLoggedIn', handleOneSignalLoaded);
    if (window.OneSignal) {
      setOneSignalLoaded(true);
      onLogin();
    }

    return () => {
      vlog.debug('Cleaning up OneSignal loaded event listener');
      window.removeEventListener('qwotedOneSignalLoggedIn', handleOneSignalLoaded);
    };
  }, []);

  // Fetch refusal data from localStorage and initialize state
  useEffect(() => {
    if (!oneSignalLoaded) {
      vlog.trace('Skipping initialization, OneSignal not loaded');
      return;
    }

    vlog.debug('Fetching refusal data from localStorage');
    const storedRefusalData = JSON.parse(localStorage.getItem(REFUSAL_STORAGE_KEY)) || {
      refusalCount: 0,
      lastRefusedAt: null,
    };
    vlog.trace('Loaded refusal data', storedRefusalData);

    setRefusalData(storedRefusalData);

    const fetchPermissionStatus = async () => {
      vlog.debug('Fetching OneSignal permission status');
      try {
        const isOptedIn = await window.OneSignal.User.PushSubscription.optedIn;
        const perm = await window.OneSignal.Notifications.permission;
        const permStatus = await window.OneSignal.context.permissionManager.getPermissionStatus();

        vlog.trace('Fetched permission status', { isOptedIn, perm, permStatus });

        setOptedIn(isOptedIn);
        setPermission(perm);
        setPermissionStatus(permStatus);
        setLoading(false);
      } catch (err) {
        vlog.error('Error fetching push subscription status', err);
        setLoading(false);
      }
    };

    fetchPermissionStatus();
  }, [oneSignalLoaded]);

  // Decide whether to show the modal based on various states
  useEffect(() => {
    if (!oneSignalLoaded) {
      vlog.trace('Skipping modal logic, OneSignal not loaded');
      return;
    }

    vlog.debug('Evaluating whether to show modal');
    if (!loading && !optedIn && permissionStatus !== 'denied' && shouldPrompt()) {
      vlog.info('Conditions met, showing modal');
      setShowModal(true);
    }
  }, [oneSignalLoaded, loading, permissionStatus, optedIn, permission, refusalData, shouldPrompt]);

  // Handle user opting in to notifications
  const handleOptIn = async () => {
    vlog.info('User opted in to notifications');
    vahoy.track('WebPushSubscribe#handleOptIn');
    try {
      let perm = permission;
      if (!permission) {
        vlog.debug('Requesting notification permission');
        await window.OneSignal.Notifications.requestPermission();
        perm = await window.OneSignal.Notifications.permission;
      }

      if (perm) {
        vlog.debug('Opting in user with OneSignal');
        await window.OneSignal.User.PushSubscription.optIn();
      }
      setShowModal(false); // Close modal on success
    } catch (err) {
      vlog.error('Error opting in to notifications', err);
    }
  };

  // Handle user refusal to opt-in
  const handleCancel = () => {
    vlog.info('User refused notifications');
    vahoy.track('WebPushSubscribe#handleCancel');
    const updatedRefusalData = {
      refusalCount: refusalData.refusalCount + 1,
      lastRefusedAt: new Date().toISOString(),
    };

    vlog.debug('Updating refusal data', updatedRefusalData);

    setRefusalData(updatedRefusalData);
    localStorage.setItem(REFUSAL_STORAGE_KEY, JSON.stringify(updatedRefusalData));
    setShowModal(false); // Close modal
  };

  if (!showModal) return null;

  if (loading) {
    vlog.debug('Component is loading. Returning null.');
    return null;
  }

  if (error) {
    vlog.debug('Component has error. Returning null.');
    return null;
  }

  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      role="dialog"
      style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Stay informed with browser notifications</h5>
          </div>
          <div className="modal-body">
            <p>{message || 'Get real-time notifications delivered directly to your browser. Click "Allow" when prompted.'}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleOptIn}>
              Allow
            </button>
            <button type="button" className="btn btn-secondary" onClick={handleCancel}>
              No thanks
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

WebPushSubscribe.propTypes = {
  message: PropTypes.string,
};

WebPushSubscribe.defaultProps = {
  message: undefined,
};
export default WebPushSubscribe;
