import axios from 'axios';
import _ from 'lodash';
import api from './api';
import vlog from '../../vlog';

_.extend(api, {
  createMessage(userId, email, token, toSguid, subject, messageHtml) {
    vlog.info('api#sendMessage');

    api.configure();

    const url = '/api/internal/messaging/messages';

    const headers = {
      'X-User-Email': email,
      'X-User-Token': token,
    };

    const data = {
      to: toSguid,
      user_id: userId,
      message: { body: messageHtml, subject },
    };

    return axios.post(url, data, { headers });
  },

  createMessageV2(userId, toSguidArray, subject, messageHtml, fileArray) {
    vlog.info('api#sendMessage');

    api.configure();

    const url = '/api/internal/messaging/messages';

    const data = {
      to: toSguidArray,
      user_id: userId,
      message: {
        body: messageHtml,
        subject,
        attachments_attributes: fileArray,
      },
    };

    return axios.post(url, data);
  },

  getMessagingInboxPresent() {
    vlog.info('api#getMessagingInbox');

    api.configure();
    const url = '/api/internal/messaging/users/messaging_inbox';

    return axios.get(url, {}, api.axiosFix);
  },
});
