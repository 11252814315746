import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Col, CardBody, CardTitle,
} from 'reactstrap';
import Datestamps from '../source_requests/card/datestamps';

function Request({ request, trackClicks }) {
  const humanizedType = `${request.request_type_text} request`.replace('story_idea', 'story idea');
  const sourceRequest = {
    request_type_text: request.request_type_text,
    want_pitches: request.wants_pitches,
    source_request_submit_date: request.deadline_at,
    published_at: request.filed_at,
    current_user_pitch_status: null,
  };

  return (
    <Col key={request.id} md={4} className="d-flex mb-4">
      <div className="flex-fill d-flex flex-column">
        <Card className="mb-2 flex-fill text-start">
          <CardBody>
            <div className="d-flex flex-column align-items-start">
              {request.media_outlet_profile_image_url && (
                <img
                  src={request.media_outlet_profile_image_url}
                  alt={request.media_outlet_name}
                  style={{ height: '60px', maxWidth: '100%' }}
                />
              )}
              <h5 className="w-75 fw-bold my-2">{request.media_outlet_name}</h5>
            </div>
            <h6 className="mb-0 text-uppercase text-secondary font-size-12px">{humanizedType}</h6>
            <CardTitle tag="h5">
              <a href={`/source_requests/${request.migrate_id}`}>{request.title}</a>
            </CardTitle>
            {request.details && (
              <p className="small">
                {request.details.substring(0, 100)}
                {request.details.length > 100 && '…'}
              </p>
            )}
            <Datestamps sourceRequest={sourceRequest} smallMode />
          </CardBody>
        </Card>
        <a
          href={`/source_requests/${request.migrate_id}`}
          className="btn btn-outline-primary w-100"
          onClick={trackClicks}
        >
          Pitch
        </a>
      </div>
    </Col>
  );
}

Request.propTypes = {
  request: PropTypes.object.isRequired,
  trackClicks: PropTypes.func.isRequired,
};

export default Request;
