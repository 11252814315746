import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

// PublicPitchBody component renders the main content of a public pitch.
// It displays the public pitch title, text, and admin-specific information
// if the current user is an admin.
function PublicPitchBody({
  canSendMessages,
  contactPath,
  currentUserAdmin,
  currentUserSlug,
  defaultConversationStartPath,
  isAdminModalOpen,
  publicPitch,
  toggleAdmin,
}) {
  const [areDetailsVisible, setDetailsVisible] = useState(false);

  // Toggle the visibility of the details section
  const handleToggleDetails = () => {
    setDetailsVisible((prevState) => !prevState);
  };

  const shouldRenderAdminControls = currentUserAdmin;

  let startConversationPath = null; // No visible button by default
  if (!currentUserSlug) {
    // Lead to the sign up page for non-logged in users
    startConversationPath = defaultConversationStartPath;
  }
  if (
    ((canSendMessages && currentUserSlug) // identified and can send
      || shouldRenderAdminControls) // or an admin
    && contactPath // and have the contact path defined
  ) {
    // Lead to the messaging component
    startConversationPath = contactPath
      .replace(':current_user', currentUserSlug)
      .replace('%3Aback_path', window.location.pathname);
  }

  return (
    <>
      <div className="d-flex flex-column h-100">
        <h5 className="fw-bold font-size-18px">{publicPitch.publicPitchTitle}</h5>
        <div className="small fw-semibold mb-1 font-size-14px">{publicPitch.publicPitchLede}</div>
        <hr className="my-2" />
        <div className="small mt-1 mb-2 flex-grow-1 font-size-14px">{publicPitch.publicPitchPreamble}</div>

        <div className="d-flex flex-row flex-wrap justify-content-between">
          <button
            type="button"
            onClick={handleToggleDetails}
            className={classNames('btn btn-link p-0 font-size-12px public-pitch-details-link', {
              'public-pitch-details-link-toggled': areDetailsVisible,
            })}
          >
            {areDetailsVisible ? 'Collapse' : 'View'} full summary
          </button>

          {shouldRenderAdminControls && (
            <button
              type="button"
              onClick={toggleAdmin}
              className={classNames('btn btn-link p-0 font-size-12px text-staff public-pitch-admin-link', {
                'public-pitch-admin-link-toggled': isAdminModalOpen,
              })}
            >
              Admin
            </button>
          )}
        </div>

        {areDetailsVisible && (
          <div className="public-pitch-full-summary">
            <div className="py-2 small">{publicPitch.publicPitchText}</div>
          </div>
        )}
      </div>
      {startConversationPath && (
        <div className="mt-2 d-grid">
          <a className="btn btn-outline-secondary" href={startConversationPath}>
            <i className="public-pitch-contact-link-icon fa-solid fa-comment-dots align-middle" /> Start a conversation
          </a>
        </div>
      )}
    </>
  );
}

PublicPitchBody.propTypes = {
  canSendMessages: PropTypes.bool.isRequired,
  contactPath: PropTypes.string,
  currentUserAdmin: PropTypes.bool.isRequired,
  currentUserSlug: PropTypes.string,
  defaultConversationStartPath: PropTypes.string,
  isAdminModalOpen: PropTypes.bool.isRequired,
  publicPitch: PropTypes.shape({
    publicPitchLede: PropTypes.string,
    publicPitchPreamble: PropTypes.string,
    publicPitchText: PropTypes.string.isRequired,
    publicPitchTitle: PropTypes.string.isRequired,
    schemaVersion: PropTypes.number.isRequired,
  }).isRequired,
  toggleAdmin: PropTypes.func.isRequired,
};

PublicPitchBody.defaultProps = {
  contactPath: null,
  currentUserSlug: null,
  defaultConversationStartPath: null,
};

export default PublicPitchBody;
