import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ResponsiveAvatar from '../../responsive_avatar';
import ActionButtons from '../../products/card/action_buttons';
import Hashtags from '../../hashtags';

function CompanyFeaturedProduct({ product }) {
  const [seeMoreDescription, setSeeMoreDescription] = useState(false);

  const renderDescription = () => {
    const descriptionLength = product.attributes.description?.length;
    if (!descriptionLength) return null;

    const maxDescriptionLength = 350;

    if (seeMoreDescription) {
      return (
        <>
          {product.attributes.description}
          <br />
          <button
            onClick={() => setSeeMoreDescription(!seeMoreDescription)}
            type="button"
            className="btn btn-link btn-outline-light"
          >
            Hide
          </button>
        </>
      );
    }

    if (descriptionLength < maxDescriptionLength) {
      return <>{product.attributes.description}</>;
    }

    return (
      <>
        {`${product.attributes.description.slice(0, maxDescriptionLength)}...`}
        <button
          onClick={() => setSeeMoreDescription(!seeMoreDescription)}
          type="button"
          className="btn btn-link"
        >
          See more
        </button>
      </>
    );
  };

  return (
    <div className="card py-3 w-100 h-100 mx-3 mb-3" key={product.id}>
      <div className="card-body h-100 row align-items-stretch gx-0">
        <div className="col-md-3 pe-4">
          <ResponsiveAvatar
            avatarUrl={product.attributes.avatar_url}
            avatarLetters={product.attributes.name.substring(0, 2)}
          />
        </div>

        <div className="col">
          <h3 className="mb-1">
            <a
              href={product.attributes.product_path}
              className="fw-bold cursor-pointer"
            >
              {product.attributes.name}
            </a>
          </h3>

          <span className="text-secondary">${product.attributes.price}</span>

          <p className="mt-2">{renderDescription()}</p>

          <Hashtags
            hashtagTarget={product.attributes}
            clickEventName="Products/ProductCard#clickHashtag"
          />
          <ActionButtons product={product.attributes} />
        </div>
      </div>
    </div>
  );
}

CompanyFeaturedProduct.propTypes = {
  product: PropTypes.object.isRequired,
};

export default CompanyFeaturedProduct;
