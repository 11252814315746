import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  RadialBarChart, RadialBar, ResponsiveContainer, Tooltip, Cell,
} from 'recharts';
import { connectRefinementList } from 'react-instantsearch-dom';

function RefinementsRadialBarChart({ items, refine }) {
  const COLORS = ['#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#ffc658'];
  const [activeIndex, setActiveIndex] = useState(null);

  const data = items.map((item, index) => ({
    ...item,
    fill: item.isRefined ? 'green' : COLORS[index % COLORS.length],
  }));

  const getFontSize = (value) => {
    const minFontSize = 15;
    const maxFontSize = 40;
    const maxValue = Math.max(...items.map((item) => item.count));
    return ((value / maxValue) * (maxFontSize - minFontSize)) + minFontSize;
  };

  const customizedLegend = () => (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {items.map((entry, index) => (
        <li className="my-3">
        <button
          key={`item-${index}`}
          type="button"
          style={{
            color: entry.isRefined ? 'green' : COLORS[index % COLORS.length],
            fontSize: `${getFontSize(entry.count)}px`,
            opacity: activeIndex !== null && activeIndex !== index ? 0.3 : 1,
            cursor: 'pointer',
            transition: 'opacity 0.3s, font-weight 0.3s',
            margin: '0px',
            textWrap: 'nowrap',
            textAlign: 'right',
            background: 'none',
            border: 'none',
            padding: 0,
          }}
          onMouseEnter={() => setActiveIndex(index)}
          onMouseLeave={() => setActiveIndex(null)}
          onClick={() => refine(entry.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              refine(entry.value);
            }
          }}
        >
          {entry.label} ({entry.count})
        </button>
          </li>
      ))}
    </ul>
  );

  // eslint-disable-next-line react/prop-types
  const tooltipFormatter = (value, name, props) => [`${value}`, `${props.payload.label}`];

  return (
    <div style={{
      width: '100%', height: '80vh', display: 'flex', position: 'relative',
    }}
    >
      <div style={{ flex: 3 }}>
        <ResponsiveContainer width="100%" height="100%">
          <RadialBarChart
            cx="50%"
            cy="300"
            innerRadius="10%"
            outerRadius="90%"
            barSize={10}
            data={data}
            style={{ background: 'none' }}
          >
            <RadialBar
              minAngle={15}
              background
              clockWise
              dataKey="count"
              onMouseEnter={(entries, index) => setActiveIndex(index)}
              onMouseLeave={() => setActiveIndex(null)}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.fill}
                  opacity={activeIndex !== null && activeIndex !== index ? 0.3 : 1}
                />
              ))}
            </RadialBar>
            <Tooltip
              formatter={tooltipFormatter}
            />
          </RadialBarChart>
        </ResponsiveContainer>
      </div>
      <div style={{
        flex: 1, padding: '10px', overflow: 'visible', marginRight: '5em',
      }}
      >
        {customizedLegend()}
      </div>
    </div>
  );
}

RefinementsRadialBarChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      isRefined: PropTypes.bool.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  refine: PropTypes.func.isRequired,
};

const ConnectedRefinementsRadialBarChart = connectRefinementList(RefinementsRadialBarChart);

export default ConnectedRefinementsRadialBarChart;
