import React from 'react';
import PropTypes from 'prop-types';

function UnspecifiedButton({ onClick }) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  };

  return (
    <>
      <div
        className="btn position-relative d-inline-flex flex-row cursor-pointer mb-2 me-2 btn-outline-secondary"
        id="btn_unspecified"
        onClick={onClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <div className="d-inline-block me-2 align-self-center">
          <i className="fa-solid fa-circle-question fa-2x" />
        </div>
        <span className="fs-4 pt-1">Unspecified *</span>
      </div>
      <small className="d-flex">
        <em>* Reporters want to quote someone or something</em>
      </small>
    </>
  );
}

UnspecifiedButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
export default UnspecifiedButton;
