import URI from 'urijs';

const opportunityHelpers = {

  opportunityTypeIconClasses(type, bordered = false) {
    switch (type) {
      case 'Opportunity::Award':
        return `fa-solid fa-trophy colored-award ${bordered && 'colored-award-border'}`;
      case 'Opportunity::SpecialReport':
        return `fa-regular fa-newspaper colored-special-report ${bordered && 'colored-special-report-border'}`;
      case 'Opportunity::Event':
        return `fa-solid fa-glass-martini-empty colored-event ${bordered && 'colored-event-border'}`;
      case 'Opportunity::CalendarItem':
        return `fa-solid fa-calendar-days colored-calendar-item ${bordered && 'colored-calendar-item-border'}`;
      default:
        break;
    }
  },

  // NB: this only actually highlight IF params[:highlight] == true
  queryHighlight(string) {
    const uri = new URI(window.location.href);
    const { query, highlight } = uri.query(true);

    if (query && highlight) {
      const regex = new RegExp(`(${query})`, 'i');
      return string.replace(regex, '<mark>$1</mark>');
    }

    return string;
  },

  // NB: this only actually highlight IF params[:highlight] == true
  queryCompressAndHighlight(string) {
    const uri = new URI(window.location.href);
    const { query, highlight } = uri.query(true);

    if (query && highlight) {
      const regex = new RegExp(`(\\S*.{0,20})?(${query})(.{0,20}\\S*)?`, 'ig');

      const results = [];
      string.replace(regex, (match, $1, $2, $3) => {
        results.push(`${$1 ? `…${$1}` : ''}<mark>${$2}</mark>${$3 ? `${$3}…` : ''}`);
      });

      if (results.length > 0) {
        return results.join(' ');
      }
    }

    return string;
  },

  sharedDateColumnsForOpportunityArray() {
    let cols;

    const uri = new URI(window.location.href);
    const opportunityTypes = uri.query(true).opportunity_types;

    // NB: Despite the pluralization, this only works with a single opportunity type for now ...
    switch (opportunityTypes) {
      case 'awards':
        cols = ['award_submit_date', 'award_date'];
        break;
      case 'special-reports':
        cols = ['special_report_submit_date', 'special_report_publication_date'];
        break;
      case 'events':
        cols = ['event_start_date', 'event_end_date'];
        break;
      case 'calendar-items':
        cols = ['calendar_item_start_date', 'calendar_item_end_date'];
        break;
      default:
        cols = ['opportunity_date'];
        break;
    }

    cols.push('updated_at');

    return cols;
  },

  defaultDateSortColumnForOpportunityArray() {
    const uri = new URI(window.location.href);
    const { opportunity_types: opportunityTypes } = uri.query(true);

    // NB: Despite the pluralization, this only works with a single opportunity type for now ...
    switch (opportunityTypes) {
      // see: https://github.com/moment/moment/issues/1048 to make this more sophisticated
      case 'Opportunity::Award':
        return window.DEFAULT_SORT_COL_AWARD;
      case 'Opportunity::SpecialReport':
        return window.DEFAULT_SORT_COL_SPECIAL_REPORT;
      case 'Opportunity::Event':
        return window.DEFAULT_SORT_COL_EVENT;
      case 'Opportunity::CalendarItem':
        return window.DEFAULT_SORT_COL_CALENDAR_ITEM;
      default:
        return 'default_sort_col';
    }
  },

  firstAndLastDateInOpportunitiesArray(array) {
    const ary = _.compact(array);

    if (_.compact(ary).length === 0) {
      return { firstDate: null, lastDate: null };
    }

    const filteredArray = _.filter(ary, (opp) => opp.attributes.opportunity_date);
    const sortedArray = _.sortBy(filteredArray, (opp) => opp.attributes.opportunity_date);

    const firstOpp = _.head(sortedArray);
    const lastOpp = _.last(sortedArray);
    return {
      firstDate: moment(firstOpp.attributes.opportunity_date),
      lastDate: moment(lastOpp.attributes.opportunity_date),
    };
  },

  mapApiOpportunitiesToFullcalendarEvents(apiOpportunities) {
    const events = [];

    _.map(apiOpportunities, (opp) => {
      const type = opp.attributes.type_dasherized;

      const opportunityDate = moment(opp.attributes.opportunity_date);
      const endDate = moment(opp.attributes.event_end_date);

      const colorCodedClass = `colored-${type} colored-${type}-bg`;

      const uniqueClass = `vested-${type}-${opp.id}`;

      events.push({
        start: opportunityDate,
        end: endDate,

        className: `${colorCodedClass} ${uniqueClass}`,

        data: {
          opportunity: opp,
        },
      });
    });
    return events;
  },

};

export default opportunityHelpers;
