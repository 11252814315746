import React from 'react';
import PropTypes from 'prop-types';
import vapi from '../../javascript/frontend/api/vapi';

import HashtagsEditableList from './_list';
import HashtagsEditableForm from './_form';
import vahoy from '../../javascript/vahoy';

class HashtagsEditable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      loading: true,
      hashtagTags: [],
    };

    this.maxHashtags = 50;
  }

  componentDidMount() {
    this.loadHashtags();
  }

  closeEditingModal = () => {
    this.setState({ editing: false });
    this.loadHashtags();
  };

  raiseEditingModal = () => {
    this.setState({ editing: true });
    vahoy.track('Hashtag/HashtagsEditable#raiseEditingModal');
  };

  loadHashtags = () => {
    const { tagged_json: taggedObj, hashtag_type: hashtagType } = this.props;
    const urlObjectName = taggedObj.type.replace(/_/g, '-');
    const urlHashtagType = hashtagType.replace(/_/g, '-');
    const url = `/api/internal/jsonapi/${urlObjectName}/${taggedObj.id}/${urlHashtagType}?page[limit]=${this.maxHashtags}`;

    vapi.getHashtags(url)
      .then((response) => {
        this.setState({
          loading: false,
          hashtagTags: response.data.data,
        });
      });
  };

  renderHashtagsForm = () => {
    const { can_edit: canEdit, tagged_json: taggedJson, hashtag_type: hashtagType } = this.props;
    const { editing, hashtagTags } = this.state;
    if (canEdit && editing) {
      return (
        <HashtagsEditableForm
          can_edit={canEdit}
          closeEditingModal={this.closeEditingModal}
          refreshParent={this.loadHashtags}
          tagged_json={taggedJson}
          hashtagTags={hashtagTags}
          max_hashtags={this.maxHashtags}
          hashtagType={hashtagType}
        />
      );
    }
  };

  renderButtons = () => {
    const { hashtagTags } = this.state;
    const { hashtags_empty_prompt: hashtagsEmptyPrompt } = this.props;
    const visibleHashtags = hashtagTags.filter((h) => h.attributes.is_visible === 'true');
    const hashtagsLength = visibleHashtags ? visibleHashtags.length : 0;

    let classes = 'btn btn-light btn-sm';
    if (hashtagsLength === 0) {
      classes += ' btn-outline-primary';
    }

    return (
      <div>
        {hashtagsLength === 0
          && <div className="alert alert-warning mb-2">{hashtagsEmptyPrompt}</div>}
        <button
          type="button"
          className={classes}
          onClick={this.raiseEditingModal}
        >
          {hashtagsLength === 0 && 'Add tags'}
          {hashtagsLength > 0 && <i className="fa-solid fa-pen-to-square" />}
        </button>
        {
          hashtagsLength === 0
          && <div className="mb-4" />
        }
      </div>
    );
  };

  render() {
    const { can_edit: canEdit } = this.props;
    const { loading, hashtagTags } = this.state;
    const hashtagsLength = hashtagTags ? hashtagTags.length : 0;
    const visibleHashtags = hashtagTags.filter((h) => h.attributes.is_visible === 'true');
    return (
      <div>
        {this.renderHashtagsForm()}

        <HashtagsEditableList
          hashtags={visibleHashtags}
          max_hashtags={this.maxHashtags}
        />

        {canEdit && !loading && this.renderButtons()}

        {(hashtagsLength > 0 || canEdit)
          && <div className="mb-2" />}
      </div>
    );
  }
}

HashtagsEditable.propTypes = {
  tagged_json: PropTypes.instanceOf(Object),
  can_edit: PropTypes.bool,
  hashtags_empty_prompt: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  hashtag_type: PropTypes.string.isRequired,
};

HashtagsEditable.defaultProps = {
  tagged_json: undefined,
  can_edit: undefined,
  hashtags_empty_prompt: undefined,
};

export default HashtagsEditable;
