import PropTypes from 'prop-types';
import React from 'react';

import Avatar from '../../avatar/avatar';

// Check if we're dealing with a string that actually has non-space chars in it
function nonEmptyString(s) {
  return typeof s === 'string' && s.trim().length > 0;
}

// Render employment details skipping the data we don't have in a visually
// appealing way
function renderEmploymentDetails({ companyName, companyPath, employmentsString }) {
  const output = [];

  if (nonEmptyString(employmentsString)) {
    output.push(employmentsString);
  }

  if (nonEmptyString(companyName)) {
    if (output.length > 0) {
      output.push(' at ');
    }
    output.push(nonEmptyString(companyPath) ? <a href={companyPath}>{companyName}</a> : companyName);
  }

  // create a proper fragment instead of an array, which would have forced us
  // to invent a key for every item
  return React.createElement(...[React.Fragment, {}, ...output]);
}

// AuthorHeader component displays author details such as avatar, name and employment.
function AuthorHeader({ publicPitch }) {
  const {
 avatarLetters, avatarUrl, companyName, companyPath, employmentsString, fullName, sourcePath,
} = publicPitch;

  return (
    <div className="d-flex">
      <div>
        <Avatar avatarLetters={avatarLetters} avatarUrl={avatarUrl} sizeClass="avatar-sm" />
      </div>
      <div className="me-2">
        <div className="fw-bold font-size-18px">
          <a href={sourcePath}>{fullName}</a>
        </div>
        <div className="mt-1 font-size-14px public-pitch-author-footer">
          {renderEmploymentDetails({ employmentsString, companyPath, companyName })}
        </div>
      </div>
    </div>
  );
}

AuthorHeader.propTypes = {
  publicPitch: PropTypes.shape({
    avatarLetters: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
    companyName: PropTypes.string,
    companyPath: PropTypes.string,
    employmentsString: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    sourcePath: PropTypes.string,
  }).isRequired,
};

export default AuthorHeader;
