import React from 'react';
import PropTypes from 'prop-types';
import avatarHelpers from '../../javascript/frontend/helpers/avatar_helpers';

function Avatar({
  avatarUrl, avatarLetters, sizeClass, className = '',
}) {
  const colorClass = avatarHelpers.colorClassFromInitials(avatarLetters);

  return (
    <div
      className={`me-2 avatar ${colorClass} ${sizeClass} ${className}`}
      style={{ flexShrink: 0 }}
    >
      {avatarUrl && (<img alt={avatarLetters} src={avatarUrl} />)}
      {!avatarUrl && (<span className="initials">{avatarLetters}</span>)}
    </div>
  );
}

Avatar.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  avatarUrl: PropTypes.string,

  // eslint-disable-next-line react/no-unused-prop-types
  sizeClass: PropTypes.string,

  // eslint-disable-next-line react/no-unused-prop-types
  avatarLetters: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  avatarUrl: undefined,
  sizeClass: undefined,
  className: undefined,
};

export default Avatar;
