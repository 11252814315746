import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TextareaAutosize from 'react-autosize-textarea';
import PropTypes from 'prop-types';
import LoadingImage from '../loading_image';

function BioModal(props) {
  const {
    onCancel, isOpening, onSave, isSaving, value, onRemove,
  } = props;
  let inputInstance;
  let saveButton;
  let cancelButton;
  let removeButton;
  const headerContent = value ? 'Edit bio' : 'Add bio';

  if (isSaving) {
    saveButton = (
      <LoadingImage height="30" />
    );
  } else {
    saveButton = (<button type="submit" className="btn btn-primary">Save</button>);
    cancelButton = (
      <button
        type="button"
        className="btn text-danger ms-2 shadow-none ps-5 pe-5"
        onClick={onCancel}
      >Cancel
      </button>
    );

    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    removeButton = (
      <button type="button" className="btn btn-danger btn-remove ms-2" onClick={onRemove}><i
        className="fa fa-trash-can"
      />
      </button>
    );
  }

  /* eslint-disable no-return-assign */
  return (
    <Modal isOpen={isOpening} className="bio-modal">
      <ModalHeader toggle={() => onCancel && onCancel()}>{headerContent}</ModalHeader>
      <ModalBody>
        <form
          className=""
          onSubmit={(evt) => {
            evt.preventDefault();
            onSave(inputInstance.value);
          }}
        >
          <div className="mb-2">
            <TextareaAutosize
              ref={(instance) => {
                inputInstance = instance;
              }}
              className="form-control"
              id="bio"
              rows={8}
              autoFocus
              defaultValue={value || ''}
            />
          </div>
          <div className="mb-3 text-end">
            {saveButton}
            {removeButton}
            {cancelButton}
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
  /* eslint-enable no-return-assign */
}

BioModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isOpening: PropTypes.bool,
  isSaving: PropTypes.bool,
  value: PropTypes.string,
};

BioModal.defaultProps = {
  isOpening: undefined,
  isSaving: undefined,
  value: undefined,
};

export default BioModal;
