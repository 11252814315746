import React from 'react';
import PropTypes from 'prop-types';
import Hashtags from '../../hashtags';
import ResponsiveAvatar from '../../responsive_avatar';
import FavoriteButton from '../../favorite_button';

function PrUserCard({
                      prUser,
                      updates,
                    }) {
  // Late arriving attributes to be merged in, if any
  if (updates) Object.assign(prUser, updates.attributes);
  const maxHashtags = 5;

  return (
    <div className="card h-100 pr-user-card animated faster fadeIn">
      <div className="card-body position-relative">
        <ResponsiveAvatar
          avatarUrl={prUser.avatar_url}
          avatarLetters={prUser.initials}
          className="mb-2"
        />

        <a href={prUser.pr_user_path} className="fw-bold cursor-pointer">
          {prUser.full_name}
        </a>

        <div className="mb-2 small">
          {prUser.employments_string}
        </div>

        <div className="row m-0">
          <Hashtags
            hashtagTarget={prUser}
            maxHashtags={maxHashtags}
            title={null}
            clickEventName="PrUsers/PrUserCard#clickHashtag"
          />
        </div>
        <div className="clearfix" style={{ minHeight: '3.0rem' }} />
        <div className="mt-4 mb-2 row w-100" style={{ position: 'absolute', bottom: '0' }}>
          <div className="col" />
          {prUser.favoriteable_id && (
            <div className="col-1 me-4">
              <FavoriteButton
                favoriteableId={prUser.favoriteable_id}
                favoriteableType="PrUser"
                favoriteId={prUser.favorite_id}
                favorited={prUser.favorited}
                ahoyObjectGid={prUser.object_gid}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

PrUserCard.propTypes = {
  prUser: PropTypes.instanceOf(Object),
  updates: PropTypes.instanceOf(Object),
};

PrUserCard.defaultProps = {
  prUser: undefined,
  updates: undefined,
};

export default PrUserCard;
