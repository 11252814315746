import React from 'react';
import PropTypes from 'prop-types';

function StatusFooter({ sourceRequest, smallMode }) {
  let userPitchStatus;

  const userPitchClass = smallMode ? 'font-size-12px mt-1' : 'font-size-14px mt-1';
  switch (sourceRequest.current_user_pitch_status) {
    case 'draft':
      userPitchStatus = (
        <div className={userPitchClass}>
          <strong>Your Pitch:</strong>&nbsp;
          <span className="badge bg-warning text-white" style={{ borderRadius: '3px' }}>Draft-Not Submitted</span>
        </div>
      );
      break;
    case 'submitted':
      userPitchStatus = (
        <div className={userPitchClass}>
          <strong>Your Pitch:</strong>&nbsp;
          <span className="badge bg-info" style={{ borderRadius: '3px' }}>Submitted</span>
        </div>
      );
      break;
    case 'read':
      userPitchStatus = (
        <div className={userPitchClass}>
          <strong>Your Pitch:</strong>&nbsp;
          <span className="badge bg-success" style={{ borderRadius: '3px' }}>Read by Reporter</span>
        </div>
      );
      break;
    default:
      userPitchStatus = null;
  }

  if (!userPitchStatus
    && !sourceRequest.is_new
    && !sourceRequest.easy_win
    && !sourceRequest.deadline_approaching
  ) return null;

  const baseBadgeStyle = `badge ${smallMode ? 'font-size-9px' : 'font-size-10px'} fw-bold mt-1`;

  return (
    <div className={`card-footer ${smallMode ? 'pt-0 pb-1' : 'pt-1 pb-2'} bg-transparent border-1`}>
<>
        {userPitchStatus}
        {!userPitchStatus && sourceRequest.is_new && (
          <span
            className={`${baseBadgeStyle} bg-success me-1 status-badge-new`}
            style={{ borderRadius: '3px' }}
          >
            NEW
          </span>
        )}

        {!userPitchStatus && sourceRequest.easy_win && (
          <span
            className={`${baseBadgeStyle} bg-warning me-1 status-badge-easy_win`}
            style={{ borderRadius: '3px' }}
          >
            Easy Win
          </span>
        )}

        {!userPitchStatus && sourceRequest.deadline_approaching && (
          <span
            className={`${baseBadgeStyle} bg-danger me-1 status-badge-deadline`}
            style={{ borderRadius: '3px' }}
          >
            Deadline Approaching
          </span>
        )}
      </>
    </div>
  );
}

StatusFooter.propTypes = {
  sourceRequest: PropTypes.instanceOf(Object).isRequired,
  smallMode: PropTypes.bool,
};

StatusFooter.defaultProps = {
  smallMode: false,
};

export default StatusFooter;
